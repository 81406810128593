/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo ,useState} from "react";
import { Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/proformas/proformasActions";
import { useProformasUIContext } from "./ProformasUIContext";

export function ProformaDeleteDialog() {
  // Proformas UI Context
  const specsUIContext = useProformasUIContext();
  const specsUIProps = useMemo(() => {
    return {
      id: specsUIContext.selectedId,
      productId: specsUIContext.productId,
      show: specsUIContext.showDeleteProformaDialog,
      onHide: specsUIContext.closeDeleteProformaDialog,
      queryParams: specsUIContext.queryParams,
      setIds: specsUIContext.setIds,
      onHideEdit: specsUIContext.closeEditProformaDialog,
      carId:specsUIContext.carId
    };
  }, [specsUIContext]);

  // Specs Redux state
  const [validation,setValidation] = useState()
  const dispatch = useDispatch();
  const { isLoading, salesInProforma } = useSelector(
    (state) => ({ 
      isLoading: state.proformas.actionsLoading, 
      salesInProforma: state.sales.salesInProforma
    }),
    shallowEqual
  );

  // if !id we should close modal
  //useEffect(() => {
    // if (!specsUIProps.id && specsUIProps.id!=0) {
    //   specsUIProps.onHide();
    // }
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [specsUIProps.id]);

  // looking for loading/dispatch
  useEffect(() => { 
    dispatch(actions.deleteValidation(specsUIProps.id,setValidation));
  }, [isLoading, dispatch,specsUIProps.proformaId, salesInProforma]);

  const deleteProforma = () => {
    // server request for deleting spec by id
    dispatch(actions.deleteProforma(specsUIProps.id)).then(() => {
      // refresh list after deletion
      if(specsUIProps.carId) {
        dispatch(
          actions.fetchProformasInCar(specsUIContext.carId)
        );
      }
      else {
        dispatch(
          actions.fetchProformas()
        );
      }
      specsUIProps.setIds([]);
      specsUIProps.onHide();
      specsUIProps.onHideEdit();
    });
  };

  return (
    <Modal
      show={specsUIProps.show}
      // onHide={specsUIProps.onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Proforma Delete
        </Modal.Title>
        <Nav className="ml-auto" onClick={() => specsUIProps.onHide()}>
        <Nav.Link className="p-0 text-dark">
          <span className="nav-icon">
            <span className="svg-icon svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                  </g>
                </g>
              </svg>
            </span>
          </span>
        </Nav.Link>
      </Nav>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && validation &&  (
          <span>Are you sure to permanently delete this Proforma?</span>
        )}
        {!validation && <span>You can't delete this proforma. There are payment made with proforma.</span>}
        {isLoading && <span>Proforma is deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div className="mr-auto">
          <button
            type="button"
            onClick={specsUIProps.onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          {validation && <button
            type="button"
            onClick={deleteProforma}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
