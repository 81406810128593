import * as requestFromServer from "./inspectionsCrud";
import {inspectionsSlice, callTypes} from "./inspectionsSlice";

const {actions} = inspectionsSlice;

export const getAllInspectionItems = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
  .getAllInspectionItems()
  .then(response => {
    return dispatch(
      actions.inspectionItemsFetched({inspectionItems: response.data})
    );
  })
}

export const fetchInspections = (queryParams, inspectionId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  if (!inspectionId) {
    return dispatch(
      actions.inspectionsFetched({ totalCount: 0, entities: null })
    );
  }

  return requestFromServer
    .findInspections(queryParams, inspectionId)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.inspectionsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find inspections";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchInspection = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.inspectionFetched({ inspectionForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getInspectionById(id)
    .then(response => {
      let inspection = response.data;
      inspection.inspection_date = new Date(inspection?.inspection_date)
      dispatch(
        actions.inspectionFetched({ inspectionForEdit: inspection })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find inspection";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteInspection = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteInspection(id)
    .then(response => {
      dispatch(actions.inspectionDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete inspection";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createInspection = inspectionForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createInspection(inspectionForCreation)
    .then(response => {
      const { inspection } = response.data;
      dispatch(actions.inspectionCreated({ inspection }));
    })
    .catch(error => {
      error.clientMessage = "Can't create inspection";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createInspectionInCar = (inspectionForCreation) => dispatch =>{
  // dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
  .createInspection(inspectionForCreation)
  .then(response => {
    const { inspection } = response.data;
    // dispatch(actions.inspectionInCarCreated({inspection: inspection}));
  })
  .catch(error => {
    error.clientMessage = "Can't create inspection";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  });
};

export const updateInspectionInCar = inspectionForUpdate => dispatch => {
  // dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
  .updateInspectionInCar(inspectionForUpdate)
  .then(response => {
    const { inspection } = response.data;
    // dispatch(actions.inspectionInCarUpdated({inspection: inspection}));
  })
  .catch(error => {
    error.clientMessage = "Can't create inspection";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  });
}


export const updateBulkInspection = inspections => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBulkInspection(inspections)
    .then(() => {
      dispatch(actions.inspectionBulkUpdated({ inspections }));
    })
    .catch(error => {
      error.clientMessage = "Can't update inspection";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchInspectionsInCar = id => dispatch => {
  if(!id){
    return dispatch(actions.inspectionsInCarFetched({inspections: undefined}))
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
  .findInspectionsInCar(id)
  .then(response => {
    const entities  = response.data;
    const totalCount = response.data.length;
    dispatch(actions.inspectionsInCarFetched({ inspections: entities, inspectionsTotalCount: totalCount }));
  })
  .catch(error => {
    error.clientMessage = "Can't delete car";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  });
}

export const updateInspection = inspection => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateInspection(inspection)
    .then(() => {
      dispatch(actions.inspectionUpdated({ inspection }));
    })
    .catch(error => {
      error.clientMessage = "Can't update inspection";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteInspections = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteInspections(ids)
    .then(() => {
      dispatch(actions.inspectionsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete inspections";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
