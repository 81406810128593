// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "./../../../../../../_metronic/_helpers";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openProformaPrintDialog, openNewProformaDialog, openEditCarPage, openDeleteCarDialog, openNewSaleDialog, /*openContinueSaleDialog,*/ selectProduct, selectTopTab, dispatch,roles }
  
  ) {

  
  return (
    row.saleStatus &&(
      <>
      {/* {row.saleStatus.toLowerCase() !== 'sold' && ( */}
        
        <span>
          {roles && roles.includes("Proforma") && 
          <OverlayTrigger
            overlay={
              <Tooltip id="spec-edit-tooltip">Proforma</Tooltip>
            }
          >
            <a
              className="btn btn-icon btn-light btn-sm mr-2 proforma-icon"
              onClick={async () => {
                // dispatch(selectTopTab('Proforma'))
                // openEditCarPage(row.id)
                await dispatch(selectProduct(row.id))

                row.active_proforma_id ? (
                  openProformaPrintDialog(row.active_proforma_id)
                ) : (
                  openNewProformaDialog()
                )
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} title=" " /> */}
                {/* <img src="/media/icons/proforma_icon.ico" alt="" width={14} height={14} /> */}
              </span>
            </a>
          </OverlayTrigger>
          }
          <> </>
          {/* {roles && roles.includes("Proforma") && roles.includes("Sales") && 
          <OverlayTrigger
            overlay={
              <Tooltip id="spec-sale-tooltip">Sale</Tooltip>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
              onClick={() => {
                dispatch(selectProduct(row.id))
                openNewSaleDialog(row.id)
                // openContinueSaleDialog(row.id)
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} title=" " />
              </span>
            </a>
          </OverlayTrigger>}
          <> </> */}
          {row.saleStatus.toLowerCase() === 'free' ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="spec-delete-tooltip">Delete Car</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm"
                onClick={() => openDeleteCarDialog(row.id)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} title=" " />
                </span>
              </a>
            </OverlayTrigger>
          ) : (
            <a className="btn mx-3" style={{cursor: "default"}}>
              <span className="svg-icon svg-icon-md"></span>
            </a>
          )}
        </span>
      {/* )} */}
      </>
    )
  );
}
