import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./CarModelsUIHelpers";

const CarModelsUIContext = createContext();

export function useCarModelsUIContext() {
  return useContext(CarModelsUIContext);
}

export const CarModelsUIConsumer = CarModelsUIContext.Consumer;

export function CarModelsUIProvider({carModelsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initCarModel = {
    id: null,
    make: "",
    model: "",
    year: new Date(),
    cc: "",
    body_style_id: "",
    remark: "",
    picture: null,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initCarModel,
    newCarModelButtonClick: carModelsUIEvents.newCarModelButtonClick,
    openEditCarModelDialog: carModelsUIEvents.openEditCarModelDialog,
    openDeleteCarModelDialog: carModelsUIEvents.openDeleteCarModelDialog,
    openDeleteCarModelsDialog: carModelsUIEvents.openDeleteCarModelsDialog,
    openFetchCarModelsDialog: carModelsUIEvents.openFetchCarModelsDialog,
    openUpdateCarModelsStatusDialog: carModelsUIEvents.openUpdateCarModelsStatusDialog
  };

  return <CarModelsUIContext.Provider value={value}>{children}</CarModelsUIContext.Provider>;
}