import * as requestFromServer from "./carModelsCrud";
import {carModelsSlice, callTypes} from "./carModelsSlice";

const {actions} = carModelsSlice;

export const fetchCarModels = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCarModels(queryParams)
      .then(response => {
        const  entities = response.data.content;
        const totalCount = response.data.totalElements;
        dispatch(actions.carModelsFetched({ totalCount, entities }));
      })
      .catch(error => {
        error.clientMessage = "Can't find carModels";
        dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
    // .findCarModels()
    // .then(response => {
    //   const totalCount = response.data.length;
    //   const  entities = response.data;
    //   dispatch(actions.carModelsFetched({ totalCount, entities }));
    // })
    // .catch(error => {
    //   error.clientMessage = "Can't find carModels";
    //   dispatch(actions.catchError({ error, callType: callTypes.list }));
    // });
};

export const loadImageFile = file => dispatch =>{
  dispatch(actions.startCall({ callType: callTypes.action }));
  return dispatch(actions.imageFileLoaded({file: file}))
}

export const fetchCarModel = id => dispatch => {
  if (!id) {
    return dispatch(actions.carModelFetched({ carModelForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCarModelById(id)
    .then(response => {
      const carModel = response.data;
      dispatch(actions.carModelFetched({ carModelForEdit: carModel }));
    })
    .catch(error => {
      error.clientMessage = "Can't find carModel";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCarModel = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCarModel(id)
    .then(response => {
      dispatch(actions.carModelDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete carModel";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCarModel = carModelForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCarModel(carModelForCreation)
    .then(response => {
      const { carModel } = response.data;
      dispatch(actions.carModelCreated({ carModel }));
    })
    .catch(error => {
      error.clientMessage = "Can't create carModel";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCarModel = carModel => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCarModel(carModel)
    .then(() => {
      dispatch(actions.carModelUpdated({ carModel }));
    })
    .catch(error => {
      error.clientMessage = "Can't update carModel";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCarModelsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCarModels(ids, status)
    .then(() => {
      dispatch(actions.carModelsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update carModels status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCarModels = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCarModels(ids)
    .then(() => {
      dispatch(actions.carModelsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete carModels";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const checkCarModelReferences = id => dispatch => {
  dispatch(actions.carModelReferenced(undefined))
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .carModelIsUsed(id)
    .then((response) => {
      dispatch(actions.carModelReferenced(response.data));
    })
    .catch(error => {
      error.clientMessage = "Can't get references";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const getCarModelImage = id => dispatch => {
  return requestFromServer.getCarModelImage(id)
  .then((res) => {
    const data = res.data;
    if(data.size) {
      dispatch(actions.startCall({ callType: callTypes.action }));
      dispatch(actions.imageFileLoaded({file: data}))
    }
  })
  .catch(error => {
    error.clientMessage = "Can't get image";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  })
}
