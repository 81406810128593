import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import {utils}  from 'react-color-tools';

export const column_url = process.env.REACT_APP_AUTO_DEALER_API+'home/dashboard/columnColor'
const jwtToken = localStorage.getItem("currentUser")

class Column extends Component {

  constructor(props) {
    super(props);
    const originalCategories=this.props.categories
    const originalData=this.props.data
    this.state = {
      options: {
        responsive: [
          {
            breakpoint: 550,
            options: {
              chart: {
                height:400
              },
              
            }
          }
        ],
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        tooltip: {
          
          y: {
            formatter: function(val) {
              return val
            },
            title: {
              formatter: function (seriesName) {
                return ''
              }
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        xaxis: {
          categories: originalCategories
          
        },
        colors: [
          function ({ value, seriesIndex, dataPointIndex, w }) {
              if(originalCategories.includes(w.config.xaxis.categories[dataPointIndex]))
                return '#008ffbd9';
              else
              return w.config.xaxis.categories[dataPointIndex]=='White' ? '#fdfcfc':utils.toRGB(w.config.xaxis.categories[dataPointIndex]) ;
            }
        ],
        
        
        chart: {
          id: "bar-active-cars",
          events: {
            click: function(event, chartContext, config) {
            if(event.type !='touchend'&& event.target.className.baseVal === 'apexcharts-bar-area'){
                if (config.dataPointIndex===-1){
                  config.dataPointIndex=event.target.getAttribute("j")
                } 
                if(originalCategories.includes(config.config.xaxis.categories[config.dataPointIndex])){
                  fetch(`${column_url}/${config.config.xaxis.categories[config.dataPointIndex]}/${localStorage.currentUserEmail}`,{
                    headers: {Authorization: jwtToken}
                  })
                  .then(res => res.json())
                  .then(json => {
                    chartContext.updateOptions({
                      yaxis:{
                        labels:{
                          formatter:function(val){
                            return val.toFixed(0)
                          }
                        }
                      },
                      xaxis: {
                        categories:json.map((items)=>{return items.categories})
                      },
                      series: [{
                        name:config.config.xaxis.categories[config.dataPointIndex],
                        data: json.map((items)=>{return items.data})
                      }],
                    
                    })
                  });
                }else if(config.dataPointIndex>=0){
                  chartContext.updateOptions({
                    xaxis: {
                      categories:originalCategories
                    },
                    series: [{
                      data: originalData
                    }],
                    
                  })
                }
              
          }
              // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
            }
          }
        }
      },
      series: [{
        data: originalData
      }],
    }
  }
  loadColor = async (name) => {
    const response = await fetch(
      `${column_url}/${name}`, {
        headers: {Authorization: jwtToken}
      }
    );
    const responseJSON = await response.json();
    return responseJSON
  }


  render() {

    return (
      <div className="column">
        <Chart options={this.state.options} series={this.state.series} type="bar" />
      </div>
    );
  }
}

export default Column;
