import React from "react";

export const AccountTypeFormatter = (cellContent, row) => {
   
    
  return (
    < >
      {!cellContent ? <label className="label label-lg label-light-primary label-inline font-weight-bold py-4">Company Account</label>: 
      <label className="label label-lg label-light-warning label-inline font-weight-bold py-4">Non Company Account</label> }
    </>
  );
}