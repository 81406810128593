import axios from "axios";

export const SPECIFICATIONS_URL = "api/batches";
export const URL = "http://localhost:8080/api/batches";
export const CUSTOMERS_URL = process.env.REACT_APP_AUTO_DEALER_API+'batches';



export function getAllBatch(){
  return axios.get(CUSTOMERS_URL)
}
// CREATE =>  POST: add a new batch to the server
export function createBatch(batch) {
  return axios.post(CUSTOMERS_URL,batch);
}

// READ
// Server should return filtered batch by batchId
export function getAllProductBatchByBatchId(batchId) {
  return axios.get(`${CUSTOMERS_URL}?batchId=${batchId}`);
}

export function getBatchById(batchId) {
  return axios.get(`${CUSTOMERS_URL}/${batchId}`);
}

// Server should return sorted/filtered batch and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/batchfind/{batchId}'. Should be 'api/batch/find/{batchId}'!!!
export function findBatch(queryParams, batchId) {
  return axios.post(`${CUSTOMERS_URL}find/${batchId}`, { queryParams });
}

// UPDATE => PUT: update the batch
export function updateBatch(batch) {
  return axios.put(`${CUSTOMERS_URL}/${batch.id}`, 
    batch
  );
}

// DELETE => delete the batch
export function deleteBatch(id) {
  return axios.delete(`${CUSTOMERS_URL}/${id}`);
}
                     

export function getDeleteValidationById(id){
  return axios.get(`${CUSTOMERS_URL}/${id}/delete_validate`);
}