// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo,useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone, 
  PaginationTotalStandalone
} from "react-bootstrap-table2-paginator";
import { ActionsColumnFormatter } from "./column-formatters/ActionsColumnFormatter";
import { ProformaStatusColumnFormatter } from "./column-formatters/proformaStatusFormatter";
import * as uiHelpers from "./ProformasUIHelper";
import { PaginationLinks } from "../../../../../_metronic/_partials/controls";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../_metronic/_helpers";
import { useProformasUIContext } from "./ProformasUIContext";
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment'
import NumberFormat from "react-number-format";

export function ProformasTable({ proformasTotalCount, proformaEntities, listLoading}) {
  // Specs UI Context
  const specsUIContext = useProformasUIContext();
  const specsUIProps = useMemo(() => {
    return {
      queryParams: specsUIContext.queryParams,
      setQueryParams: specsUIContext.setQueryParams,
      openEditProformaDialog: specsUIContext.openEditProformaDialog,
      openDeleteProformaDialog:
        specsUIContext.openDeleteProformaDialog,
      openProformaPrintDialog: specsUIContext.openProformaPrintDialog,
      ids: specsUIContext.ids,
      setIds: specsUIContext.setIds,
      productId: specsUIContext.productId,
      openContractOfSale:specsUIContext.openProformaConstractOfSale,
      openBankReleaseLetter:specsUIContext.openProformaBankReleaseLetter,
      openCashContractOfSale:specsUIContext.openProformaCashConstractOfSale,
      openPrintMenu: specsUIContext.openPrintMenuDialog,
    };
  }, [specsUIContext]);

  function formaterCustomer(value, row) {
    return value== undefined && value == "" ? row.customerName:value;
}
  const [tableData,setTableDate] = useState(null)
  
  useEffect(()=>{
    if(proformaEntities == null)
      return; // Close modal from proforma modal that opened from notification will make proformaEntities null making the table empty.
    setTableDate(cloneDeep(proformaEntities) || [])
  },[proformaEntities])

  const columns = [
    {
      dataField: "customer.name",
      text: "Customer",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => (row.customer == undefined ) ? row.customerName: row.customer.name,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          specsUIProps.openProformaPrintDialog(row.id)
        }
      }

    },
    {
      dataField: "status_id",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: ProformaStatusColumnFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          specsUIProps.openProformaPrintDialog(row.id)
        }
      }
    },

    {
      dataField: "proforma_date",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      classes: "text-nowrap",
      footerClasses: "bottomNone",
      formatter: (cellContent, row) => (
        <span>
          {moment(row.proforma_date).format("DD/MM/yyyy")}
          {(row.status_id != uiHelpers.ProformaStatuses.find(p => p.name === "Processed")?.id && row.expire) && (
            <>
              <br></br>
              <span className="font-size-sm">(Expires after {(moment(row.proforma_date).add(row.expire+1, 'days').diff(moment(), 'days') <=0 ? moment(row.proforma_date).add(row.expire-1, 'days').diff(moment(), 'days') : moment(row.proforma_date).add(row.expire+1, 'days').diff(moment(), 'days'))} day/s)</span>
            </>
          )}
        </span>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          specsUIProps.openProformaPrintDialog(row.id)
        }
      }
      
    },

    // {
    //   dataField: "expire",
    //   text: " Expires in (days)",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerClasses: "text-right",
    //   classes: "text-right pr-10",
    //   formatter: (cellContent, row) => (
    //     <span>
    //       {moment(row.proforma_date).add(row.expire+1, 'days').diff(moment(), 'days')}
    //     </span>
    //   ),
    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       specsUIProps.openProformaPrintDialog(row.id)
    //     }
    //   }
    // },
    {
      dataField: "amount",
      text: " Amount",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell)=>{return <NumberFormat thousandSeparator={true} value={cell} displayType='text' ></NumberFormat>},
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          specsUIProps.openProformaPrintDialog(row.id)
        }
      }
    },
    
    {
      dataField: "user_name",
      text: "User",
      sort: true,
      sortCaret: sortCaret,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          specsUIProps.openProformaPrintDialog(row.id)
        }
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditProformaDialog: specsUIProps.openEditProformaDialog,
        openDeleteProformaDialog: specsUIProps.openDeleteProformaDialog,
        // openContractOfSale: specsUIProps.openContractOfSale,
        // openBankReleaseLetter: specsUIProps.openBankReleaseLetter,
        // openCashContractOfSale: specsUIProps.openCashContractOfSale
        openPrintMenu: specsUIProps.openPrintMenu,
      },
      style: {
        minWidth: "150px",
      },
    },
  ];

  function filter(e){
    //console.log(e)
    const filter = proformaEntities?.filter(item => item.date?.indexOf(e) > -1 || item.customer?.name.toLowerCase().indexOf(e) > -1 || item.telephone?.indexOf(e) > -1  )
    setTableDate(filter)
  }

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: proformasTotalCount != undefined ? proformasTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: specsUIProps.queryParams.pageSize,
    page: (specsUIProps.queryParams.pageNumber - 1) < (tableData?.length/specsUIProps.queryParams.pageSize) ? specsUIProps.queryParams.pageNumber : 
    specsUIProps.queryParams.pageNumber == 1 ?  specsUIProps.queryParams.pageNumber:specsUIProps.queryParams.pageNumber - 1  ,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bordered={false}
                bootstrap4
                keyField="id"
                // data={proformaEntities === null || proformaEntities == undefined || proformaEntities.length == 0 ? [] : proformaEntities}
                data={tableData === null ? [] : tableData}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  specsUIProps.setQueryParams
                )}
                rowStyle={{ cursor: 'pointer' }}
                /* selectRow={getSelectRow({
                  proformaEntities,
                  ids: specsUIProps.ids,
                  setIds: specsUIProps.setIds,
                })} */
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={tableData} />
                <NoRecordsFoundMessage entities={tableData} />
              </BootstrapTable>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <PaginationLinks paginationProps={paginationProps} />
                <div className="ml-auto">
                <SizePerPageDropdownStandalone
                    { ...paginationProps }
                  />
                  <PaginationTotalStandalone
                    { ...paginationProps }
                  />
                </div>
              </div>
            </>
          );
        }}
      </PaginationProvider>
    </>
  );
}
