/* eslint-disable no-restricted-imports */
import React, { useMemo } from "react";
import { Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "./../../../../../../_metronic/_partials/controls";
import * as proformaActions from "../../../../_redux/proformas/proformasActions"
import * as carActions from "../../../../_redux/cars/carsActions"
import { useSalesUIContext } from "./../SalesUIContext";
import { useProformasUIContext } from "../../car-proformas/ProformasUIContext";
import { useCarsUIContext } from "../../CarsUIContext"
import { ProformaStatusTitles } from "../SalesUIHelper";

export function SaleCompleteDialog() {
  // Sales UI Context
  const specsUIContext = useSalesUIContext();
  const specsUIProps = useMemo(() => {
    return {
      proformaId: specsUIContext.proformaId,
      show: specsUIContext.showCompleteSaleDialog,
      onHide: specsUIContext.closeCompleteSaleDialog, 
      saleFromOutside: specsUIContext.saleFromOutside,
      productId:specsUIContext.productId,
      finishSale: specsUIContext.finishSale
    };
  }, [specsUIContext]);

  const proformasUIContext = useProformasUIContext();
  const proformasUIProps = useMemo(() => {
    return {
      onHideProformaEdit: proformasUIContext.closeEditProformaDialog,
    }
  }, [proformasUIContext])

  const carsUIContext = useCarsUIContext();
  const carsUIProps = useMemo(() => {
    return {
      queryParams: carsUIContext.queryParams,
    }
  }, [carsUIContext])


  // Specs Redux state
  const dispatch = useDispatch();
  const { isLoading, proformaForEdit } = useSelector(
    (state) => ({ 
      isLoading: state.sales.actionsLoading, 
      proformaForEdit: state.proformas.proformaForEdit
     }),
    shallowEqual
  );

  const completeSale = () => {
    // change proforma status to "Processed"
    // dispatch(proformaActions.changeProformaStatus(specsUIProps.proformaId, ProformaStatusTitles[2].id)).then(() => {
      dispatch(proformaActions.changeProformaStatus(proformaForEdit?.id, ProformaStatusTitles[2].id)).then(() => {
      // refresh list after deletion
      specsUIProps.saleFromOutside && dispatch(carActions.fetchCars(carsUIProps.queryParams));
      dispatch(proformaActions.fetchProformasInCar(specsUIProps.productId))
      specsUIProps.onHide();
      proformasUIProps.onHideProformaEdit();
    });
  };

  return (
    <Modal
      show={specsUIProps.show}
      // onHide={specsUIProps.onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Sale Complete
        </Modal.Title>
        <Nav className="ml-auto" onClick={() => specsUIProps.onHide()}>
        <Nav.Link className="p-0 text-dark">
          <span className="nav-icon">
            <span className="svg-icon svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                  </g>
                </g>
              </svg>
            </span>
          </span>
        </Nav.Link>
      </Nav>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <p>Are you sure you want to complete this sale?</p>
        )}
        {isLoading && <span>Sale is completing...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div className="mr-auto">
          <button
            type="button"
            onClick={() => {
              // specsUIProps.onHide(false)
              specsUIProps.onHide(specsUIProps.finishSale)
              specsUIProps.finishSale && proformasUIProps.onHideProformaEdit();
              // specsUIProps.finishSale && proformasUIProps.onHideEditProforma()
            }}
            className="btn btn-light btn-elevate"
          >
            No
          </button>
          <> </>
          <button
            type="button"
            onClick={completeSale}
            className="btn btn-primary btn-elevate"
          >
            Yes
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
