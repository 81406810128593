import React, {useEffect} from "react";
import { Route } from "react-router-dom";
import { UsersLoadingDialog } from "./users-loading-dialog/UsersLoadingDialog";
import { UserEditDialog } from "./user-edit-dialog/UserEditDialog";
import { UserDeleteDialog } from "./user-delete-dialog/UserDeleteDialog";
import { UsersFetchDialog } from "./users-fetch-dialog/UsersFetchDialog";
import { UsersUIProvider } from "./UsersUIContext";
import { UsersCard } from "./UsersCard";
import { UserChangePasswordDialog } from "./user-change-password-dialog/UserChangePasswordDialog";
import { shallowEqual, useSelector } from "react-redux";

export function UsersPage({ history }) {
  const usersUIEvents = {
    newUserButtonClick: () => {
      history.push("/users/new");
    },
    openEditUserDialog: (id) => {
      history.push(`/users/${id}/edit`);
    },
    openDeleteUserDialog: (id,status) => {
      history.push(`/users/${id}/${status}/delete`);
    },
    openFetchUsersDialog: () => {
      history.push(`/users/fetch`);
    },
    openChangePasswordDialog: (id) => {
      history.push(`/users/${id}/changePassword`);
    }
  }

  const { loggedUser} = useSelector(
    (state) => ({
      loggedUser: state.users.loggedUser,
    }),
    shallowEqual
  );

  useEffect(() => {
    if(loggedUser?.change_pwd) {
      usersUIEvents.openChangePasswordDialog(loggedUser?.id)
    }
  }, [])

  return (
    <UsersUIProvider usersUIEvents={usersUIEvents}>
      <UsersLoadingDialog />
      <Route path="/users/new">
        {({ history, match }) => (
          <UserEditDialog
            show={match != null}
            id={0}
            onHide={() => {
              history.push("/users");
            }}
          />
        )}
      </Route>
      <Route path="/users/:id/edit">
        {({ history, match }) => (
          <UserEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/users");
            }}
          />
        )}
      </Route>
      
      <Route path="/users/:id/:status/delete">
        {({ history, match }) => (
          <UserDeleteDialog
            show={match != null}
            id={match && match.params.id}
            status={match && match.params.status === "true" ? true : false}
            onHide={() => {
              history.push("/users");
            }}
          />
        )}
      </Route>
      <Route path="/users/fetch">
        {({ history, match }) => (
          <UsersFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/users");
            }}
          />
        )}
      </Route>
      <Route path="/users/:id/changePassword">
        {({ history, match }) => (
          <UserChangePasswordDialog
            show={match != null || (loggedUser.change_pwd && loggedUser?.id != null)}
            id={match && match.params.id || (loggedUser.change_pwd && loggedUser?.id)}
            onHide={() => {
              history.push("/users");///users/:id/edit
            }}
          />
        )}
      </Route>
      
      <UsersCard />
    </UsersUIProvider>
  );
}
