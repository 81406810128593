import React, {
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./OrdersUIHelper";

const OrdersUIContext = createContext();

export function useOrdersUIContext() {
  return useContext(OrdersUIContext);
}

export const OrdersUIConsumer = OrdersUIContext.Consumer;

export function OrdersUIProvider({ children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParams
  };

  return (
    <OrdersUIContext.Provider value={value}>
      {children}
    </OrdersUIContext.Provider>
  );
}
