import React,{useEffect,useState} from 'react'
import NavLink from 'react-bootstrap/esm/NavLink'
// const Logs = () => {
  
//   const [fileList,setFL] = useState([])
//   const [linkStack,setLS] = useState([]);

//   const [counter, setCounter] = useState(0);
 
//   //increase counter
//   const increase = () => {
//     setCounter(count => count + 1);
//   };
 
//   //decrease counter
//   const decrease = () => {
//     setCounter(count => count - 1);
//   };
 
//   //reset counter 
//   const push = (link) =>{
//     setLS(linkStack.push(link))
//   }
//   const pop = () =>{
//     return setLS(linkStack.pop())
//   }
  
//   useEffect(()=>{
// 		async function fetchList(){
			
// 				const url = `${process.env.REACT_APP_AUTO_DEALER_API}logger/list`
// 				//const token = localStorage.currentUser
// 				let response = await fetch(url)
// 				response = await response.json()
				
// 				setFL(response)
		
// 		}
// 		fetchList() 
// 	},[])

//   async function downloadFile(file){
//     //let response = await axios.get(`${process.env.REACT_APP_AUTO_DEALER_API}logger/download/${fileName}`)
//         if(!file.folder){

//         const urls = `${process.env.REACT_APP_AUTO_DEALER_API}logger/download`
//         try {
          
//           const res = await fetch(urls, {
//             method: 'POST',
//             headers: {
//               'Accept': 'application/json',
//               'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(file)
//           });
      
//           const blob = await res.blob();;
//           const newBlob = new Blob([blob]);
      
//           const blobUrl = window.URL.createObjectURL(newBlob);
      
//           const link = document.createElement('a');
//           link.href = blobUrl;
//           link.setAttribute('download', file.fileName);
//           document.body.appendChild(link);
//           link.click();
//           link.parentNode.removeChild(link);
      
//           // clean up Url
//           window.URL.revokeObjectURL(blobUrl);
//           }catch (ex) {
//             console.log(ex);
//         }
//       }else{
//         const url = `${process.env.REACT_APP_AUTO_DEALER_API}logger/list`
       
//         const res = await axios({
//           method: 'post',
//           url: url,
//           data: file
//         });
// 				//const token = localStorage.currentUser
//         counter > 0 && push(file.prevPath);
// 				const reponse = await res.data
//         setFL(reponse) 
//         increase();
			
//       }
        
//   }
//   async function back(count){
    
//     if(count === 1){
//       const url = `${process.env.REACT_APP_AUTO_DEALER_API}logger/list`
// 				//const token = localStorage.currentUser
// 				let response = await fetch(url)
// 				response = await response.json()
				
// 				setFL(response)
//     }else{
//       const file = pop();
//       const url = `${process.env.REACT_APP_AUTO_DEALER_API}logger/list`
       
//         const res = await axios({
//           method: 'post',
//           url: url,
//           data: file
//         });
//         const reponse = await res.data
//         setFL(reponse) 
//     }
//     decrease();
//   }

//   return (
//     <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
//     <div className="d-flex flex-column-fluid">
            
//             <div className="container">
//             {counter > 0 && <NavLink to="#" onClick={()=>{back(counter)}} className="font-weight-bolder col-md-3">
//                       <i class="icon-1x text-dark-50 flaticon2-file-1"></i>...
// 															</NavLink>}
//                 { fileList && fileList.map(file=>(
//                     <div>
//                      <span className="menu-text">
//                       <NavLink to="#" onClick={()=>{downloadFile(file)}} className="font-weight-bolder col-md-3">
//                       {file.folder ? <><i class="icon-1x text-dark-50 flaticon-folder-1"></i>{file.fileName}</>:
//                       <>--<i class="icon-1x text-dark-50 flaticon2-file-1"></i>{file.fileName}</>}
// 															</NavLink>
//                       </span>
//                     </div>
//                   ))

//                 }
//     </div>
//     </div>
//     </div>
//   )
// }
const Logs = () => {
  const [fileList,setFL] = useState([])
  useEffect(()=>{
		async function fetchList(){
			
				const url = `${process.env.REACT_APP_AUTO_DEALER_API}logger/list`
				//const token = localStorage.currentUser
				let response = await fetch(url)
				response = await response.json()
				
				setFL(response)
		
		}
		fetchList() 
	},[])

  async function downloadFile(fileName){
    //let response = await axios.get(`${process.env.REACT_APP_AUTO_DEALER_API}logger/download/${fileName}`)
    
        const urls = `${process.env.REACT_APP_AUTO_DEALER_API}logger/download/${fileName}`
        try {
          const res = await fetch(urls, {
            method: 'GET',
            
          });
      
          const blob = await res.blob();
          const newBlob = new Blob([blob]);
      
          const blobUrl = window.URL.createObjectURL(newBlob);
      
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
      
          // clean up Url
          window.URL.revokeObjectURL(blobUrl);
          }catch (ex) {
            console.log(ex);
        }

        
  }

  return (
    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
    <div className="d-flex flex-column-fluid">
            
            <div className="container">
                { fileList && fileList.map(file=>(
                    <div>
                     
                      <NavLink to="#" onClick={()=>{downloadFile(file)}} className="font-weight-bolder">
																<span className="menu-text">{file}</span>
															</NavLink>
                    </div>
                  ))

                }
    </div>
    </div>
    </div>
  )
}

export default Logs