import React from 'react'

const Tutorial = () => {
  return (

    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
    <div className="d-flex flex-column-fluid">
      <div className="container">  

    <div className="card-header">
    <div className="card-title">
      <h5 className="card-label">Tutorials</h5>
    </div>
  </div>
</div>
</div >
</div>
   
           
  )
}

export default Tutorial


