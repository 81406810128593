import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../_metronic/_partials/controls";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

// Validation schema
const AccountEditSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"), 
  currency: Yup.string().required("Currency is required"), 
});

export function AccountEditForm({
  saveAccount,
  account,
  actionsLoading,
  onHide,
  openDeleteAccountDialog
}) {

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={account}
        validationSchema={AccountEditSchema}
        onSubmit={(values) => {
          const valuesToSend = {
            ...values, 
            currency: values.currency.trim().toUpperCase(),
          }
          saveAccount(valuesToSend);
        }}
      >
        {({ handleSubmit,values,isSubmitting, setFieldValue, errors, touched, setFieldTouched }) => (
          <BlockUi blocking={isSubmitting}>
            <Modal.Body className="overlay  cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-10">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      withFeedbackLabel= {true}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-10">
                    <Field
                      name="currency"
                      component={Input}
                      placeholder="Currency"
                      label="Currency"
                      withFeedbackLabel= {true}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
            <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate mr-auto"
              >
                Cancel
              </button>
              { values.id != undefined &&<button
                type="button"
                onClick={ () => openDeleteAccountDialog(values.id) }
                className="btn btn-danger btn-elevate ml-auto"
              >
                Delete
              </button>}
            </Modal.Footer>
          </BlockUi>
        )}
      </Formik>
    </>
  );
}
