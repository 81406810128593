import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as categoryActions from "../../_redux/categories/categoriesActions";
import { Formik } from "formik";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "./../../../../_metronic/_partials/controls";
import { CarModelsFilter } from "./car-models-filter/CarModelsFilter";
import { CarModelsTable } from "./car-models-table/CarModelsTable";
import { CarModelsGrouping } from "./car-models-grouping/CarModelsGrouping";
import { useCarModelsUIContext } from "./CarModelsUIContext";
import { isEqual } from "lodash";

const prepareFilter = (queryParams, values) => {
	const { searchText, make, model, body } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};
	// Filter by make
	filter.make = make !== "" ? make : undefined;
	// Filter by model
	filter.model = model !== "" ? model : undefined;
	// Filter by body
	filter.body = body !== "" ? body : undefined;
	// Filter by all fields
	if (searchText) {
		filter.searchText = searchText.trim();
	}
	newQueryParams.filter = filter;
	return newQueryParams;
};

export function CarModelsCard() {
  const carModelsUIContext = useCarModelsUIContext();
  const carModelsUIProps = useMemo(() => {
    return {
      ids: carModelsUIContext.ids,
	  queryParams: carModelsUIContext.queryParams,
	  setQueryParams: carModelsUIContext.setQueryParams,
      newCarModelButtonClick: carModelsUIContext.newCarModelButtonClick,
    };
  }, [carModelsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(carModelsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, carModelsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      carModelsUIProps.setQueryParams(newQueryParams);
    }
  };

  const dispatch = useDispatch();

	const { currentState } = useSelector(
		(state) => ({ currentState: state }),
		shallowEqual
	);

	const { categoryEntities } = currentState.categories;
	const makeOptions = categoryEntities && [... new Set(categoryEntities.map(({make}) => make))];
	const modelOptions = categoryEntities && [... new Set(categoryEntities.map(({model}) => model))];
	const bodyOptions = categoryEntities && [... new Set(categoryEntities.map(({body}) => body))];

	const [make, setMake] = useState(null);
  	const [models, setModels] = useState([]);

	const jwtToken = localStorage.getItem("currentUser")
	const url = `${process.env.REACT_APP_AUTO_DEALER_API}carmodels/searchModels`

	useEffect(() => {
		dispatch(categoryActions.fetchCategories());
    }, [dispatch]);

	// useEffect(async () => {
	// 	if(make != null) {
	// 	  const res = await fetch(
	// 		`${url}?make=${make}`, {
	// 		  headers: {Authorization: jwtToken}
	// 	  });
	// 	  const modelEntities = await res.json()
	// 	  setModels(modelEntities?.map(m => m?.model));
	// 	} 
	// }, [make])

  return (    
    <div className="content d-flex flex-column-fluid">
		<div className="container">
			<div className="d-flex">
				<div className="flex-column offcanvas-mobile w-300px w-xl-325px" id="kt_profile_aside">
					<div className="card card-custom gutter-b">
						<div className="card-header border-0 pt-5">
							<h4 className="card-title align-items-start flex-column mb-3">
								<span className="card-label font-size-h3 font-weight-bolder text-dark">Filter</span>
							</h4>
						</div>
						<div className="card-body pt-0">
							<Formik
							initialValues={{
							searchText: "",
							make: "",
							model: "",
							body: "",
							}}
							onSubmit={(values) => {
							  applyFilter(values);
							}}
							>
							{({
							values,
							handleSubmit,
							handleBlur,
							handleChange,
							setFieldValue,
							resetForm
							}) => (
								<form onSubmit={handleSubmit} className="form form-label-right">
									<div>
										<div className="form-group mb-8">
											<label className="font-weight-bolder">Search</label>
											<input
												type="text"
												className="form-control form-control-solid form-control-lg"
												name="searchText"
												placeholder=""
												onBlur={handleBlur}
												value={values.searchText}
												onChange={(e) => {
													setFieldValue("searchText", e.target.value);
													handleSubmit();
												}}
											/>
										</div>
										<div className="form-group mb-8">
											<label className="font-weight-bolder">Make</label>
											<select
												className="form-control form-control-solid form-control-lg"
												name="make"
												onBlur={handleBlur}
												onChange={async (e) => {
													// if(!models.includes(values.model)) setFieldValue("model", "");
													// setMake(e.target.value);
													const m = e.target.value;
													setMake(m);
													setFieldValue("make", m);
													if(m != null) {
														const res = await fetch(
														  `${url}?make=${m}`, {
															headers: {Authorization: jwtToken}
														});
														const modelEntities = await res.json()
														setModels(modelEntities?.map(mE => mE?.model));
														if(!modelEntities?.map(mE => mE?.model).includes(values.model)) setFieldValue("model", "");
													  } 
													handleSubmit();
												}}
												value={values.make}
											>
												<option value=""></option>
												{makeOptions && makeOptions.map((element, i) => (
													<option key={i} value={element}>
														{element}
													</option>
												))}
											</select>
										</div>
										<div className="form-group mb-8">
											<label className="font-weight-bolder">Model</label>
											<select
												className="form-control form-control-solid form-control-lg"
												name="model"
												onBlur={handleBlur}
												onChange={(e) => {
													setFieldValue("model", e.target.value);
													handleSubmit();
												}}
												value={values.model}
												>
												<option value=""></option>
												{!make ? (
													modelOptions && modelOptions.map((e, i) => (
														<option key={i} value={e}>
															{e}
														</option>
													))): (
													models && models.map((element, i) => (
														<option key={i} value={element}>
															{element}
														</option>
													)))
												}
											</select>
										</div>
										<div className="form-group">
											<label className="font-weight-bolder">Body Style</label>
											<select
												className="form-control form-control-solid form-control-lg"
												name="body"
												onChange={(e) => {
													setFieldValue("body", e.target.value);
													handleSubmit();
												}}
												onBlur={handleBlur}
												value={values.body}
												>
												<option value=""></option>
												{bodyOptions && bodyOptions.map((element, i) => (
													<option key={i} value={element}>
														{element}
													</option>
												))}
											</select>
										</div>
										<button 
											type="reset" 
											className="btn btn-primary font-weight-bolder mr-2 px-8" 
											onClick={()=>{
												setMake("");
												setModels([]);
												resetForm();
												handleSubmit();
											}}>
												Reset
										</button>
									</div>
								</form>
							)}
							</Formik>
						</div>
						
					</div>
				</div>
				<div className="flex-row-fluid ml-lg-8">
					<Card>
						<CardHeader title="Brand List">
							<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={carModelsUIProps.newCarModelButtonClick}
							>
								New Brand
							</button>
							</CardHeaderToolbar>
						</CardHeader>
						<CardBody>
							<CarModelsFilter />
							{carModelsUIProps.ids.length > 0 && <CarModelsGrouping />}
							<CarModelsTable />
						</CardBody>
					</Card>
				</div>
			</div>
    	</div>
    </div>
  );
}
