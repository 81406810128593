import { searchSlice } from "./searchSlice";

const{actions} = searchSlice;

export const createSearch = (searchEntities,page) => dispatch => {
 
        dispatch(actions.searchFetched({ searchEntities,page }));
     
  };

export const resetSearch = () => dispatch => {
 
      dispatch(actions.searchReset());
   
};