export const BatchStatusCssClasses = ["danger", "success", "info", ""];
export const BatchStatusTitles = ["Suspended", "Active", "Pending", ""];
export const BatchTypeCssClasses = ["success", "primary", ""];
export const BatchTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
  { text: "100", value: 100 }
];
export const initialFilter = {
  filter: {
    name: "",
    telephone: ""
    
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};
