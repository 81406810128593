import React from "react";
import { SalesLoadingDialog } from "./sales-loading-dialog/SalesLoadingDialog";
import { SalesUIProvider } from "./SalesUIContext";
import { SalesCard } from "./SalesCard";

export function SalesPage() {
  return (
    <SalesUIProvider>
      <SalesLoadingDialog />
      <SalesCard />
    </SalesUIProvider>
  );
}
