import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/accounts/accountsActions";
import { AccountEditDialogHeader } from "./AccountEditDialogHeader";
import { AccountEditForm } from "./AccountEditForm";
import { useAccountsUIContext } from "../AccountsUIContext";
import { initAccount } from "../AccountUIHelpers";

export function AccountEditDialog({ id, show, onHide }) {
  // Account UI Context
  const accountsUIContext = useAccountsUIContext();
  const accountsUIProps = useMemo(() => {
    return {
      deleteAccountButtonClick: accountsUIContext.deleteAccountButtonClick,
    };
  }, [accountsUIContext]);

  // Account Redux state
  const dispatch = useDispatch();
  const { actionsLoading, accountForEdit, } = useSelector(
    (state) => ({
      actionsLoading: state.accounts.accountLoading,
      accountForEdit: state.accounts.accountForEdit,
    }),
    shallowEqual
  );

  const hideModal = () => {
    dispatch(actions.clearOption('account'));
    onHide();
  }

  useEffect(() => {
    // server call for getting Account by id
    dispatch(actions.fetchAccount(id));
  }, [id, dispatch]);

  // server request for saving account
  const saveAccount = (account) => {
    if (!id) {
      // server request for creating account
      dispatch(actions.createAccount(account)).then(() => onHide());
    } else {
      // server request for updating account
      dispatch(actions.updateAccount(account)).then(() => hideModal());
    }
  };

  return (
    <Modal
      size="md"
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <AccountEditDialogHeader id={id} onHide={hideModal} />
      <AccountEditForm
        saveAccount={saveAccount}
        actionsLoadingAccount={actionsLoading}
        account={accountForEdit?.id ? accountForEdit : initAccount}
        onHide={hideModal}
        openDeleteAccountDialog={accountsUIProps.deleteAccountButtonClick}
      />
    </Modal>
  );
}
