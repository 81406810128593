import React ,{ Component }from 'react';
import axios from 'axios';

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      //log the error to an error reporting service
      const url = `${process.env.REACT_APP_AUTO_DEALER_API}logger`
      const data ={
        username:localStorage.currentUserEmail,
        currentUrl:window.location.href,
        error:error?.stack,
        errorInfo:errorInfo?.componentStack
      }
      axios.post(url,
        data
      ).catch(function () {
        console.log('CAN NOT LOG ERROR')
    });
      console.log({ error, errorInfo });
    }
  
    render() {
      if (this.state.hasError) {
        return (<div className="d-flex flex-column flex-root">
       
        <div className="d-flex flex-row-fluid bgi-size-cover bgi-position-center" style={{backgroundImage: "url('/media/error/bg5.jpg')"}}>
          
        <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
					<h1 className="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12">Oops!</h1>
					<p className="font-weight-boldest display-4">Something went wrong here.</p>
					<p className="font-size-h3">We're working on it and we'll get it fixed as soon possible</p>
          
				</div>
         
        </div>
       
      </div>);
      }
      return this.props.children; 
    }
  };

  export default ErrorBoundary;