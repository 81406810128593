import React, { useEffect, useMemo } from "react";
import { Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "./../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/car-models/carModelsActions";
import * as categoryActions from "../../../_redux/categories/categoriesActions"; 
import {useCarModelsUIContext} from "../CarModelsUIContext";

export function CarModelDeleteDialog({ id, show, onHide }) {
  // CarModels UI Context
  const carModelsUIContext = useCarModelsUIContext();
  const carModelsUIProps = useMemo(() => {
    return {
      setIds: carModelsUIContext.setIds,
      queryParams: carModelsUIContext.queryParams
    };
  }, [carModelsUIContext]);

  // CarModels Redux state
  const dispatch = useDispatch();
  const { carModelISReferenced, isLoading } = useSelector(
    (state) => ({ 
      carModelISReferenced: state.carModels.carModelISReferenced,
      isLoading: state.carModels.actionsLoading 
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    } else {
      dispatch(actions.checkCarModelReferences(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteCarModel = () => {
    // server request for deleting customer by id
    dispatch(actions.deleteCarModel(id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchCarModels(carModelsUIProps.queryParams));
      dispatch(categoryActions.fetchCategories());
      // clear selections list
      carModelsUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      // onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Brand Delete
        </Modal.Title>
        <Nav className="ml-auto" onClick={onHide}>
          <Nav.Link className="p-0 text-dark">
            <span className="nav-icon">
              <span className="svg-icon svg-icon-2x">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                        <rect x="0" y="7" width="16" height="2" rx="1"/>
                        <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </span>
          </Nav.Link>
        </Nav>
      </Modal.Header>
      {carModelISReferenced !== undefined && (
        <>
          <Modal.Body>
            {carModelISReferenced ? (
                <span>Brand is being used and cannot be deleted!</span>
            ) : (
              !isLoading ? (
                <span>Are you sure to permanently delete this Brand?</span>
              ) : (
                <span>Brand is deleting...</span>
              )
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="mr-auto">
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              {!carModelISReferenced && (
                <button
                  type="button"
                  onClick={deleteCarModel}
                  className="btn btn-primary btn-elevate"
                >
                  Delete
                </button>
              )}
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}
