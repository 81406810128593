import React from "react";

export const SaleCustomerFormatter = (cellContent, row) => {
    let customerName = row.customerName
    if(row.customerName!=null){
    let spaceIndex = row.customerName.indexOf(' ') > 0 ? row.customerName.indexOf(' ')+1 : row.customerName.length;
    
    if(row.customerName.length > 11){
        customerName = row.customerName.substring(0,spaceIndex+1);
        }
    }
  return (
    <span >
        <div className=" mb-0">{cellContent}</div>
        <div className="text-muted font-weight-bold">{customerName}</div>
    </span>
  );
}
