import axios from "axios";

export const SPECIFICATIONS_URL = "api/inspections";
export const CUSTOMERS_URL = process.env.REACT_APP_AUTO_DEALER_API+'inspections'

export function getAllInspectionItems() {
  return axios.get(CUSTOMERS_URL);
}

// CREATE =>  POST: add a new inspections to the server
export function createInspection(inspection) {
  return axios.post(CUSTOMERS_URL, inspection);
}

// READ
// Server should return filtered inspections by inspectionId
export function getAllProductInspectionsByInspectionId(inspectionId) {
  return axios.get(`${SPECIFICATIONS_URL}?inspectionId=${inspectionId}`);
}

export function getInspectionById(inspectionId) {
  let url = `${CUSTOMERS_URL}/${inspectionId}`;
  return axios.get(url);
}

export function findInspectionsInCar(carId){
  let url = process.env.REACT_APP_AUTO_DEALER_API+'product/' +carId+"/inspection/"+localStorage.currentUserEmail
  return axios.get(url);
}

export function updateInspectionInCar(inspection) {
  return axios.put(`${CUSTOMERS_URL}/${inspection?.id}`, inspection);
}

// Server should return sorted/filtered inspections and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/inspectionsfind/{inspectionId}'. Should be 'api/inspections/find/{inspectionId}'!!!
export function findInspections(queryParams, inspectionId) {
  return axios.post(`${SPECIFICATIONS_URL}find/${inspectionId}`, { queryParams });
}

// UPDATE => PUT: update the inspection
export function updateInspection(inspection) {
  return axios.put(`${CUSTOMERS_URL}/${inspection.id}`, {
    inspection
  });
}

export function updateBulkInspection(inspections) {
  return axios.post(`${CUSTOMERS_URL}/bulk`, 
    inspections
  );
}

// DELETE => delete the inspection
export function deleteInspection(inspectionId) {
  return axios.delete(`${CUSTOMERS_URL}/${inspectionId}`);
}

// DELETE inspections by ids
export function deleteInspections(ids) {
  return axios.post(`${SPECIFICATIONS_URL}/deleteInspections`, { ids });
}
