import {createSlice} from "@reduxjs/toolkit";

const initialInspectionsState = {
  inspectionListLoading: false,
  inspectionLoading: false,
  inspectionTotalCount: 0,
  inspectionEntities: null,
  inspectionItems: null,
  inspectionForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const inspectionsSlice = createSlice({
  name: "inspections",
  initialState: initialInspectionsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.inspectionListLoading = false;
      } else {
        state.inspectionLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.inspectionListLoading = true;
      } else {
        state.inspectionLoading = true;
      }
    },
    // getInspectionById
    inspectionFetched: (state, action) => {
      state.inspectionLoading = false;
      state.inspectionForEdit = action.payload.inspectionForEdit;
      state.productInspectionItems = action.payload.inspectionForEdit?.productInspectionItems;
      state.error = null;
    },
    // findInspections
    inspectionsFetched: (state, action) => {
      const { inspectionTotalCount, inspectionEntities } = action.payload;
      state.inspectionListLoading = false;
      state.error = null;
      state.inspectionEntities = inspectionEntities;
      state.inspectionTotalCount = inspectionTotalCount;
    },
    // getInspectionItems
    inspectionItemsFetched: (state, action) => { 
      const { inspectionItems } = action.payload;
      state.inspectionListLoading = false;
      state.error = null;
      state.inspectionItems = inspectionItems;
      state.productInspectionItems = null;
    },
    // createInspection
    inspectionCreated: (state, action) => {
      state.inspectionLoading = false;
      state.error = null;
      state.inspectionEntities.push(action.payload.inspection);
    },
    // updateInspection
    inspectionUpdated: (state, action) => {
      state.error = null;
      state.inspectionLoading = false;
      state.inspectionEntities = state.inspectionEntities.map(entity => {
        if (entity.id === action.payload.inspection.id) {
          return action.payload.inspection;
        }
        return entity;
      });
    },


    // bulk Inspection update and create 
    inspectionBulkUpdated: (state, action) => {
      state.error = null;
      state.inspectionLoading = false;
      state.inspectionEntities = state.inspectionEntities.map(entity => {
        if (entity.id === action.payload.inspection.id) {
          return action.payload.inspection;
        }
        return entity;
      });
    },

    inspectionsInCarFetched: (state, action) => {
      const { inspections, inspectionsTotalCount } = action.payload;
      state.inspectionEntities = inspections;
      state.inspectionsTotalCount = inspectionsTotalCount;
      state.inspectionListLoading = false;
    },
    //createInspectionInCar

    inspectionInCarCreated:(state, action) =>{
      state.error = null;
      state.inspectionLoading = false;
      if(state.inspectionEntities == undefined || state.inspectionEntities == null){
        state.inspectionEntities =  [];
        state.inspectionEntities.push(action.payload.inspection);
      }else{
        state.inspectionEntities.push(action.payload.inspection);
      }
      
    },

    inspectionInCarUpdated:(state, action) => {
      state.error = null;
      state.inspectionLoading = false;
      state.carForEdit.inspections = state.carForEdit.inspections.map(element => {
        if(element.id == action.payload.inspection.id){
          return action.payload.inspection;
        }else{
          return element;
        }
      })
    },
    // deleteInspection
    inspectionDeleted: (state, action) => {
      state.error = null;
      state.inspectionLoading = false;
      state.inspectionEntities = state.inspectionEntities.filter(el => el.id !== action.payload.id);
    },
    // deleteInspections
    inspectionsDeleted: (state, action) => {
      state.error = null;
      state.inspectionLoading = false;
      state.inspectionEntities = state.inspectionEntities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
