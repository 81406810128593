import React from "react";
import { Route } from "react-router-dom";
import { CarModelsLoadingDialog } from "./car-models-loading-dialog/CarModelsLoadingDialog";
import { CarModelEditDialog } from "./car-model-edit-dialog/CarModelEditDialog";
import { CarModelDeleteDialog } from "./car-model-delete-dialog/CarModelDeleteDialog";
import { CarModelsDeleteDialog } from "./car-models-delete-dialog/CarModelsDeleteDialog";
import { CarModelsFetchDialog } from "./car-models-fetch-dialog/CarModelsFetchDialog";
import { CarModelsUpdateStateDialog } from "./car-models-update-status-dialog/CarModelsUpdateStateDialog";
import { CarModelsUIProvider } from "./CarModelsUIContext";
import { CarModelsCard } from "./CarModelsCard";

export function CarModelsPage({ history }) {
  const carModelsUIEvents = {
    newCarModelButtonClick: () => {
      history.push("/categories/new");
    },
    openEditCarModelDialog: (id) => {
      history.push(`/categories/${id}/edit`);
    },
    openDeleteCarModelDialog: (id) => {
      history.push(`/categories/${id}/delete`);
    },
    openDeleteCarModelsDialog: () => {
      history.push(`/categories/deleteCarModels`);
    },
    openFetchCarModelsDialog: () => {
      history.push(`/categories/fetch`);
    },
    openUpdateCarModelsStatusDialog: () => {
      history.push("/categories/updateStatus");
    }
  }

  return (
    <CarModelsUIProvider carModelsUIEvents={carModelsUIEvents}>
      <CarModelsLoadingDialog />
      <Route path="/categories/new">
        {({ history, match }) => (
          <CarModelEditDialog
            show={match != null}
            onHide={() => {
              history.push("/categories");
            }}
          />
        )}
      </Route>
      <Route path="/categories/:id/edit">
        {({ history, match }) => (
          <CarModelEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/categories");
            }}
          />
        )}
      </Route>
      <Route path="/categories/deleteCarModels">
        {({ history, match }) => (
          <CarModelsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/categories");
            }}
          />
        )}
      </Route>
      <Route path="/categories/:id/delete">
        {({ history, match }) => (
          <CarModelDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/categories");
            }}
          />
        )}
      </Route>
      <Route path="/categories/fetch">
        {({ history, match }) => (
          <CarModelsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/categories");
            }}
          />
        )}
      </Route>
      <Route path="/categories/updateStatus">
        {({ history, match }) => (
          <CarModelsUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/categories");
            }}
          />
        )}
      </Route>
      <CarModelsCard />
    </CarModelsUIProvider>
  );
}
