import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { LocationsTable } from "./locations-table/LocationsTable";
import { LocationsGrouping } from "./locations-grouping/LocationsGrouping";
import { useLocationsUIContext } from "./LocationsUIContext";

export function LocationsCard() {
  const locationsUIContext = useLocationsUIContext();
  const locationsUIProps = useMemo(() => {
    return {
      ids: locationsUIContext.ids,
      newLocationButtonClick: locationsUIContext.newLocationButtonClick,
    };
  }, [locationsUIContext]);

  return (
    <div>
           
    <div className="content d-flex flex-column-fluid" id="kt_content">
      
      <div className="container">
     
     
                    {/* <div className="flex-row-fluid ml-lg-8">	
							
		</div> */}
    <Card>
      <CardHeader title="Locations list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={locationsUIProps.newLocationButtonClick}
          >
            New Location
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {locationsUIProps.ids.length > 0 && <LocationsGrouping />}
        <LocationsTable />
      </CardBody>
    </Card>
    
    </div>
    </div>
    </div>
   
  );
}
