import React, { useMemo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as proformaActions from "../../../_redux/proformas/proformasActions";
import { isEqual } from "lodash";
import { useSalesUIContext } from "./SalesUIContext";
import { useProformasUIContext } from "../car-proformas/ProformasUIContext";

const prepareFilter = (queryParams, values) => {
  const { searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.value = searchText;
  if (searchText) {
    filter.name = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function SalesFilter() {
  // Specs UI Context
  const specsUIContext = useSalesUIContext();
  const specsUIProps = useMemo(() => {
    return {
      openNewSaleDialog: specsUIContext.openNewSaleDialog,
      setQueryParams: specsUIContext.setQueryParams,
      queryParams: specsUIContext.queryParams,
      productId: specsUIContext.productId,
      onHide: specsUIContext.closeViewSalesDialog
    };
  }, [specsUIContext]);

  const proformasUIContext = useProformasUIContext();
  const proformasUIProps = useMemo(() => {
    return {
      openProformaPrintDialog: proformasUIContext.openProformaPrintDialog
    };
  }, [proformasUIContext]);

  const dispatch = useDispatch();
  const { saleEntities, salesInProforma, carForEdit, proforma, proformaForEdit, validProforma, proformaEntities } = useSelector(
    (state) => ({
      carForEdit: state.cars.carForEdit,
      saleEntities: state.sales.saleEntities,
      salesInProforma: state.sales.salesInProforma,
      proformaEntities: state.proformas.entities,
      // proforma: state.proformas.entities && state.proformas.entities.find(p => p.status_id === 1), 
      proformaForEdit: state.proformas.proformaForEdit,
      validProforma: state.proformas.validProforma, 
    }),
    shallowEqual
  );

  // const salesAmount = (proformaForEdit && (proformaForEdit.proformaItems && proformaForEdit?.proformaItems.find(p => p.product_id == specsUIProps.productId)?.sales_amount)) || 0
  // const gpsAmount = (proformaForEdit && proformaForEdit.proformaItems && proformaForEdit?.proformaItems.find(p => p.product_id == specsUIProps.productId)?.gps_amount) || 0

  

  const salesAmount = validProforma && validProforma?.sales_amount
  const gpsAmount = validProforma && validProforma?.gps_amount

  const expectedAmount = (Number(salesAmount) + Number(gpsAmount)) || carForEdit?.price
  // const collectedAmount = saleEntities && saleEntities.reduce((accumulator, current) => accumulator + current.amount, 0) || 0
  const collectedAmount = salesInProforma && salesInProforma.reduce((accumulator, current) => accumulator + current.amount, 0) || 0
  const remainingAmount = expectedAmount - collectedAmount
  const excess = collectedAmount != 0 && collectedAmount >= expectedAmount

  // useEffect(() => {
  //     proforma ?
  //     dispatch(proformaActions.fetchProforma(proforma.id,specsUIProps.productId)) : 
  //     dispatch(proformaActions.fetchProforma(0, specsUIProps.productId))
  //   }, [dispatch, proforma]);
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    dispatch(proformaActions.fetchValidProforma(specsUIProps.productId))
  }, [dispatch, proformaEntities])

  useEffect(() => {
    validProforma && dispatch(proformaActions.fetchProforma(validProforma?.proforma_id))
  }, [dispatch, validProforma])

  useEffect(() => {
    setPercentage((expectedAmount <= collectedAmount) ? 100 : collectedAmount < 1 ? 0 : ((collectedAmount / expectedAmount) * 100).toFixed(2))
  }, [expectedAmount, collectedAmount])

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(specsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, specsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      specsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (

    <>
      {carForEdit != null && Object.keys(carForEdit).length === 0 ? <></> :
      <>

										<div className="d-flex">
                      <div className="flex-grow-1">
                      <div className="d-flex align-items-center">
                      <div className="d-flex flex-wrap my-2">
                        <a className="text-dark font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
															<span className="svg-icon svg-icon-md svg-icon-gray-500 mr-3">
															<i className="flaticon-avatar"></i>
															</span> 
                              <span className="font-weight-bolder ">
                              {validProforma?.customer_name}</span> </a>
                          </div>
												<div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                             <div className="d-flex flex-wrap my-2">
                             <a className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
															<span className="svg-icon svg-icon-md svg-icon-gray-500 mr-3">
															<i className="flaticon2-phone"></i>
															</span> 
                              <span className="font-weight-bolder ">
                              {validProforma?.customer_telephone}</span> </a>

                              <a className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
															<span className="svg-icon svg-icon-md svg-icon-gray-500 mr-4">
															<i className="flaticon-book"></i>
															</span><span className="font-weight-bolder ">
                              {validProforma?.customer_tin}</span> </a>
                      </div>
                      </div>
                      {/* <div className="row pl-2">
                      <span className="font-weight-bold mr-4">Progress</span>
              <div  className="progress progress-xs mt-2 mb-2 flex-shrink-0 w-100px w-xl-200px"><div role="progressbar" className="progress-bar" aria-valuenow="28.86" aria-valuemin="0" aria-valuemax="100" style={{width: percentage+"%"}}></div></div>     
              <span className="font-weight-bolder text-dark ml-2">{percentage}%</span>
											
                      </div> */}
												</div>
                        <div className="separator separator-solid my-5"></div>
                      <div className="d-flex align-items-center flex-wrap pb-5">
          <>
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <i className="flaticon-confetti icon-2x text-muted font-weight-bold"></i>
              </span>
            
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-sm">Sales Amount</span>
                <span className="font-weight-bolder font-size-h5">
                {expectedAmount?.toLocaleString()}</span>
              </div>
            </div>
            
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <i className="flaticon-piggy-bank icon-2x text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-sm">Paid:</span>
                <span className="font-weight-bolder font-size-h5">
                {collectedAmount?.toLocaleString()}</span>
              </div>
            </div>
          
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <i className="flaticon-pie-chart icon-2x text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-sm">Remaining</span>
                <span className="font-weight-bolder font-size-h5">
                {remainingAmount >= 0 ? <b>{remainingAmount?.toLocaleString()}</b> : 0}</span>
              </div>
            </div>

            { remainingAmount < 0 &&  
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1 px-6 px-lg-0 ">
              <span className="mr-4">
                <i className="flaticon-coins icon-2x text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-sm">Overpaid:</span>
                <span className="font-weight-bolder font-size-h5">
                {(-remainingAmount).toLocaleString()}</span>
              </div>
            </div> }
          </>
        </div>
                      </div>
                      </div>
                    
        <div className="col-md-3 offset-md-9 text-right margin-bottom-10-mobile mb-3 mt-5 pr-0">
          {proformaForEdit && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                specsUIProps.onHide();
                proformasUIProps.openProformaPrintDialog(validProforma?.proforma_id)
              }}
            >
              View Proforma
            </button>
          )}
        </div>
      </>
      }

    </>
  );
}
