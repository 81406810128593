import React from "react";
import { Route } from "react-router-dom";
import { TenantsLoadingDialog } from "./tenants-loading-dialog/TenantsLoadingDialog";
import { TenantEditDialog } from "./tenant-edit-dialog/TenantEditDialog";
import { TenantDeleteDialog } from "./tenant-delete-dialog/TenantDeleteDialog";
import { TenantsFetchDialog } from "./tenants-fetch-dialog/TenantsFetchDialog";
import { TenantsUIProvider } from "./TenantsUIContext";
import { TenantsCard } from "./TenantsCard";

export function TenantsPage({ history }) {
  const tenantsUIEvents = {
    newTenantButtonClick: () => {
      history.push("/clients/new");
    },
    openEditTenantDialog: (id) => {
      history.push(`/clients/${id}/edit`);
    },
    openDeleteTenantDialog: (id,clientId) => {
      history.push(`/clients/${id}/${clientId}/delete`);
    },
    openDeleteTenantsDialog: () => {
      history.push(`/clients/deleteTenants`);
    },
    openFetchTenantsDialog: () => {
      history.push(`/clients/fetch`);
    },
    openUpdateTenantsStatusDialog: () => {
      history.push("/clients/updateStatus");
    }
  }

  return (
    <TenantsUIProvider tenantsUIEvents={tenantsUIEvents}>
      <TenantsLoadingDialog />
      <Route path="/clients/new">
        {({ history, match }) => (
          <TenantEditDialog
            show={match != null}
            onHide={() => {
              history.push("/tenants");
            }}
          />
        )}
      </Route>
      <Route path="/clients/:id/edit">
        {({ history, match }) => (
          <TenantEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/clients");
            }}
          />
        )}
      </Route>
      
      <Route path="/clients/:id/:clientId/delete">
        {({ history, match }) => (
          <TenantDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={(id) => {
              const url = `/clients/${id}/edit`
              history.push(url);
            }}
            clientId={match && match.params.clientId}
          />
        )}
      </Route>
      <Route path="/clients/fetch">
        {({ history, match }) => (
          <TenantsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/clients");
            }}
          />
        )}
      </Route>
      
      <TenantsCard />
    </TenantsUIProvider>
  );
}
