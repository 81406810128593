import React from "react";
import { LoanSalesLoadingDialog } from "./loan-sales-loading-dialog/LoanSalesLoadingDialog";
import { LoanSalesUIProvider } from "./LoanSalesUIContext";
import { LoanSalesCard } from "./LoanSalesCard";

export function LoanSalesPage() {
  return (
    <LoanSalesUIProvider>
      <LoanSalesLoadingDialog />
      <LoanSalesCard />
    </LoanSalesUIProvider>
  );
}
