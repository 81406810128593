import React, { useEffect, useMemo } from "react";
import { Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { useSalesUIContext } from "./SalesUIContext";
import { SalesTable } from "./SalesTable";
import { SalesFilter } from "./SalesFilter";
import * as actions from "../../../_redux/sales/salesActions"
import * as carActions from "../../../_redux/cars/carsActions"
import { useProformasUIContext } from "../car-proformas/ProformasUIContext";

export const SalesView = () => {
    const specsUIContext = useSalesUIContext();
    const specsUIProps = useMemo(() => {
        return {
            // id: specsUIContext.selectedId,
            productId: specsUIContext.productId,
            show: specsUIContext.showViewSalesDialog,
            onHide: specsUIContext.closeViewSalesDialog
        };
    }, [specsUIContext]);

    const proformasUIContext = useProformasUIContext();
    const proformasUIProps = useMemo(() => {
        return {
            currentProforma: proformasUIContext.currentProforma
        };
    }, [proformasUIContext]);

    const dispatch = useDispatch();

    useEffect(() => {
        specsUIProps.productId && dispatch(actions.fetchSalesInCar(specsUIProps.productId))
        dispatch(carActions.fetchCar(specsUIProps.productId))
        proformasUIProps.currentProforma && dispatch(actions.fetchSalesInProforma(proformasUIProps.currentProforma.id))
    }, [specsUIProps.productId, proformasUIProps.currentProforma, dispatch]);

    const { actionsLoading, listLoading, saleEntities, salesInProforma, salesTotalCount, carForEdit } = useSelector(
        (state) => ({
            actionsLoading: state.sales.actionsLoading,
            listLoading: state.sales.listLoading,
            saleEntities: state.sales.saleEntities, 
            salesInProforma: state.sales.salesInProforma,
            salesTotalCount: state.sales.salesTotalCount,
            carForEdit: state.cars.carForEdit, 
        }),
        shallowEqual
    );

    const headerInfo = carForEdit && (carForEdit?.id == specsUIProps.productId) && `${carForEdit?.model}, ${carForEdit?.color ? carForEdit?.color + ", " : ""} ${carForEdit?.vin}`

    return (
        (specsUIProps.productId && carForEdit) ? (
            <Modal
            size="lg"
            show={specsUIProps.show}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            {actionsLoading && <ModalProgressBar />}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Sales List :<span className="h6 ml-4">{headerInfo}</span> </Modal.Title>
                <Nav className="ml-auto" onClick={() => specsUIProps.onHide()}>
                    <Nav.Link className="p-0 text-dark">
                        <span className="nav-icon">
                            <span className="svg-icon svg-icon-2x">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                        <rect x="0" y="7" width="16" height="2" rx="1"/>
                                        <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                                    </g>
                                </g>
                                </svg>
                            </span>
                        </span>
                    </Nav.Link>
                </Nav>
            </Modal.Header>
            <Modal.Body>
                <SalesFilter  />
                {/* <SalesTable salesTotalCount={salesTotalCount} saleEntities={saleEntities} listLoading={listLoading} /> */}
                <SalesTable salesTotalCount={salesTotalCount} saleEntities={salesInProforma} listLoading={listLoading} />
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    onClick={specsUIProps.onHide}
                    className="btn btn-light btn-elevate mr-auto "
                >
                    Cancel
                </button>
            </Modal.Footer>
        </Modal>
        ) : (<></>)
    );
}
