import {createSlice} from "@reduxjs/toolkit";

const initialGroupsState = {
  groupListLoading: false,
  groupActionsLoading: false,
  groupTotalCount: 0,
  groupEntities: null,
  groupForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState: initialGroupsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.groupListLoading = false;
      } else {
        state.groupActionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.groupListLoading = true;
      } else {
        state.groupActionsLoading = true;
      }
    },
    // getColorById
    groupFetched: (state, action) => {
      state.groupActionsLoading = false;
      state.groupForEdit = action.payload.groupForEdit;
      state.error = null;
    },//findRoles
    rolesFetched: (state, action) => {
      state.groupActionsLoading = false;
      state.roles = action.payload.roles;
      state.error = null;
    },
    // findGroups
    groupsFetched: (state, action) => {
      const { totalCount, groupEntities } = action.payload;
      state.groupListLoading = false;
      state.error = null;
      state.groupEntities = groupEntities;
      state.totalCount = totalCount;
    },
    // createGroup
    groupCreated: (state, action) => {
      state.groupActionsLoading = false;
      state.error = null;
      state.groupEntities.push(action.payload.group);
    },
    // updateGroup
    groupUpdated: (state, action) => {
      state.error = null;
      state.groupActionsLoading = false;
      state.groupEntities = state.groupEntities.map(entity => {
        if (entity.id === action.payload.group.id) {
          return action.payload.group;
        }
        return entity;
      });
    },
    // deleteGroup
    groupDeleted: (state, action) => {
      state.error = null;
      state.groupActionsLoading = false;
      state.groupEntities = state.groupEntities.filter(el => el.id !== action.payload.id);
    },
    // deleteGroups
    groupsDeleted: (state, action) => {
      state.error = null;
      state.groupActionsLoading = false;
      state.groupEntities = state.groupEntities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }

  }
});
