import React, { useEffect, useMemo, useState, useRef, forwardRef,useLayoutEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../_redux/sales/salesActions";
import * as batchActions from "../../../../../_redux/batch/batchActions"
import { useSalesUIContext } from "../../SalesUIContext";
import cloneDeep from 'lodash/cloneDeep';
import SalesSummaryAGGrid from "./Ag_grid_for_sales_summary";
import TableDataRowFormatter from "./TableDataRowFormatter";
import $ from 'jquery';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import { Loader} from 'react-loaders';

const SalesSummaryTable = forwardRef((props, ref)  => {
  const { ref1, ref2 } = ref;
  const exportInsideCSV = useRef();
  const exportInsidePrint = useRef();
  const salesUIContext = useSalesUIContext();
  const salesUIProps = useMemo(() => {
    return {
      ids: salesUIContext.ids,
      setIds: salesUIContext.setIds,
      queryParams: salesUIContext.queryParams,
      setQueryParams: salesUIContext.setQueryParams,
    };
  }, [salesUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state }),
    shallowEqual
  );
  let { salesForReport: entities,error:salesError,apiErrorData:apiErrorDataSales,saleListLoading } = currentState.sales;
  const {clientEntities} = currentState.client;
  const {batchEntities,error:batchError,apiErrorData:apiErrorDataBatch} = currentState.batch;

  const [tableData,setTableData] = useState([])

  useLayoutEffect(()=>{
    if(salesError && apiErrorDataSales === undefined){
      throw new Error(salesError);
    }else{
        if(salesError 
          && apiErrorDataSales?.errorCode!="NOT_FOUND"
          && apiErrorDataSales?.errorCode!="CONFLICT"){
          throw new Error(salesError);
        }
    }
    if(batchError && apiErrorDataBatch === undefined){
      throw new Error(batchError);
    }else{
        if(batchError 
          && apiErrorDataBatch?.errorCode!="NOT_FOUND"
          && apiErrorDataBatch?.errorCode!="CONFLICT"){
          throw new Error(batchError);
        }
    }
    
  },[salesError,batchError])

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchSalesForReport(salesUIProps.queryParams))
    //dispatch(clientActions.fetchClientbyUserEmail(localStorage.currentUserEmail.substring(localStorage.currentUserEmail.indexOf('@')+1)))
    dispatch(batchActions.fetchBatch())
  }, [salesUIProps.queryParams, dispatch]);
  
  useEffect(()=>{
    entities = entities.map(e => ({...e, 
      amount: e.amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }), 
      collected: e.collected.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      uncollected: e.uncollected.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      totalCategoryAmount: e.totalCategoryAmount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }), 
      totalCategoryCollected: e.totalCategoryCollected.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      totalCategoryUncollected: e.totalCategoryUncollected.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }))
    setTableData(cloneDeep(entities))
  },[entities])

  const columns = [
    {
      headerName: "MAKE/BRAND",
      field: 'car.category',
      cellClassRules: {
        'cell-span': ({ data }) => data && data.span > 0,
        'cell-notSpan':({ data }) => data && data.span === -1, 
      },
      cellRenderer: ({ data }) => {
        if (data?.car?.category=== undefined ||data.span === 0) {
          return null;
        }
        if (data.span === -1) {
          return `${data.car.category}`;
        }
        return `${data.car.category}`;
      },
      rowSpan: ({ data }) => data.span > 1 ? data.span+1:data.span,
      minWidth: 192,
      Width: 150,
      flex: 3,
    },
    {
      headerName: 'YEAR', 
      field: 'detail.year',
      hide: salesUIProps.queryParams.hidden.year,
      minWidth: 85,
      Width: 105,
      flex: 2,
    },
    {
      headerName: 'COLOR', 
      field: 'detail.color', 
      hide: salesUIProps.queryParams.hidden.color,
      minWidth: 100,
      width: 105,
      flex: 2,
    },
    {
      headerName: 'BATCH', 
      field: 'detail.batch', 
      hide: salesUIProps.queryParams.hidden.batch,
      minWidth: 95,
      width: 105,
      flex: 2,
    },
    {
      headerName: 'QTY', 
      field: 'detail.quantity', 
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      cellClassRules: {
        'cell-totalSpan': ({ data }) => data && data.span === -2
      }, 
      minWidth: 80,
      Width: 80,
      flex: 1,
    },
    {
      headerName: 'AMOUNT', 
      field: 'detail.amount', 
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      cellClassRules: {
        'cell-totalSpan': ({ data }) => data && data.span === -2
      }, 
      minWidth: 150,
      width: 187,
      flex: 3,
    },
    {
      headerName: 'COLLECTED', 
      field: 'detail.collected', 
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      cellClassRules: {
        'cell-totalSpan': ({ data }) => data && data.span === -2
      }, 
      minWidth: 150,
      width: 187,
      flex: 3,
    },
    {
      headerName: 'UNCOLLECTED  ', 
      field: 'detail.uncollected', 
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      cellClassRules: {
        'cell-totalSpan': ({ data }) => data && data.span === -2
      }, 
      minWidth: 150,
      width: 187,
      flex: 3,
    },
  ]

  const [columnDefs, setColumnDefs] = useState(columns);

  useEffect(() => {
    setColumnDefs(columns)
  }, [salesUIProps.queryParams])

  const handlerCsv = () => {
    exportInsideCSV.current.click();
  };

  const handlerPrint = () => {
    $('#bankContainer').css('visibility','hidden');
    $('#blockConstiner').show()
    exportInsidePrint.current.click();
  };

  const componentRef = useRef(null);

  return (
    <>
      <div className="d-flex justify-content-end">
        <OverlayTrigger
          overlay={
            <Tooltip id="spec-edit-tooltip">Export to Excel</Tooltip>
          }
        >
          <input
            ref={ref1}
            hidden
            className="btn btn-success"
            onClick={handlerCsv}
          />
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip id="spec-edit-tooltip">Export to PDF</Tooltip>
          }
        >
          <input
          ref={ref2}
          hidden
          className="btn btn-success"
          onClick={handlerPrint}
        ></input>    
        </OverlayTrigger>
      </div>
      <BlockUi  blocking={saleListLoading} loader={<Loader active type={'ball-triangle-path'} color="#9d9dad" />} tag="div" message="" >
      <div className="report-content" ref={componentRef} style={{height: '100%'}}>
        <div id="blockConstiner" style={{display:'none',height: '100%',width:'100%' }} ><BlockUi style={{height:'50px'}} blocking={true} loader={<Loader active type={'ball-triangle-path'} color="#9d9dad" />} tag="div" message="Loading" ></BlockUi></div>
        <div id="bankContainer">
          <div style={{ height: '100%',width:'100%' }}>
            <SalesSummaryAGGrid 
              ref={{ref1:exportInsideCSV,ref2:exportInsidePrint}} 
              tableData={TableDataRowFormatter(tableData)} 
              columnDefs={columnDefs} 
              setColumnDefs={setColumnDefs}
              salesUIProps={salesUIProps}
              clientEntities={clientEntities}
              batchEntities={batchEntities}
              entities={entities}
            />
          </div>
        </div>
      </div>
      </BlockUi>
    </>
  );
});
export default SalesSummaryTable;
