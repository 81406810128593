import {createSlice} from "@reduxjs/toolkit";

const initialCategoriesState = {
  categoryListLoading: false,
  categoryLoading: false,
  categoryTotalCount: 0,
  categoryEntities: null,
  categoryForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: initialCategoriesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.categoryListLoading = false;
      } else {
        state.categoryLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.categoryListLoading = true;
      } else {
        state.categoryLoading = true;
      }
    },
    // getCategoryById
    categoryFetched: (state, action) => {
      state.categoryLoading = false;
      state.categoryForEdit = action.payload.categoryForEdit;
      state.error = null;
    },
    // findCategories
    categoriesFetched: (state, action) => {
      const { categoryTotalCount, categoryEntities } = action.payload;
      state.categoryListLoading = false;
      state.error = null;
      state.categoryEntities = categoryEntities;
      state.categoryTotalCount = categoryTotalCount;
    },
    // createCategory
    categoryCreated: (state, action) => {
      state.categoryLoading = false;
      state.error = null;
      state.categoryEntities.push(action.payload.category);
    },
    // updateCategory
    categoryUpdated: (state, action) => {
      state.error = null;
      state.categoryLoading = false;
      state.categoryEntities = state.categoryEntities.map(entity => {
        if (entity.id === action.payload.category.id) {
          return action.payload.category;
        }
        return entity;
      });
    },
    // deleteCategory
    categoryDeleted: (state, action) => {
      state.error = null;
      state.categoryLoading = false;
      state.categoryEntities = state.categoryEntities.filter(el => el.id !== action.payload.id);
    },
    // deleteCategories
    categoriesDeleted: (state, action) => {
      state.error = null;
      state.categoryLoading = false;
      state.categoryEntities = state.categoryEntities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
