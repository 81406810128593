import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/car-models/carModelsActions";
import * as categoryActions from "../../../_redux/categories/categoriesActions"; 
import { CarModelEditDialogHeader } from "./CarModelEditDialogHeader";
import { CarModelEditForm } from "./CarModelEditForm";
import { useCarModelsUIContext } from "../CarModelsUIContext";

export function CarModelEditDialog({ id, show, onHide }) {
  // CarModels UI Context
  const carModelsUIContext = useCarModelsUIContext();
  const carModelsUIProps = useMemo(() => {
    return {
      initCarModel: carModelsUIContext.initCarModel,
      queryParams: carModelsUIContext.queryParams,
      setQueryParams: carModelsUIContext.setQueryParams,
    };
  }, [carModelsUIContext]);

  // CarModels Redux state
  const dispatch = useDispatch();
  const { actionsLoading, carModelForEdit, categoryEntities } = useSelector(
    (state) => ({
      categoryEntities: state.categories.categoryEntities,
      actionsLoading: state.carModels.actionsLoading,
      carModelForEdit: state.carModels.carModelForEdit,
    }),
    shallowEqual
  );

  let carModel = carModelForEdit || carModelsUIProps.initCarModel;

  useEffect(() => {
    // server call for getting CarModel by id
    dispatch(actions.fetchCarModel(id));
  }, [id, dispatch]);

  // server request for saving carModel
  const saveCarModel = (values) => {
    values = {...values, make: values.make.trim(), model: values.model.trim()};

    if (!values.picture) {
      values = {...values, picture: carModel.picture}
    }

    const d = new Date(values.year);
		values = {...values, year: ((d instanceof Date) ? d.getFullYear() : d)};

    let formData = new FormData();
    for (const field in values) {
      formData.append(field, values[field])
    }

    if (!id) {
      // server request for creating carModel
      dispatch(actions.createCarModel(formData)).then(() => { 
        dispatch(actions.fetchCarModels(carModelsUIProps.queryParams));
        dispatch(categoryActions.fetchCategories());
        onHide();
      });
    } else {
      // server request for updating carModel
      dispatch(actions.updateCarModel(formData)).then(() => {
        dispatch(actions.fetchCarModels(carModelsUIProps.queryParams));
        dispatch(categoryActions.fetchCategories());
        onHide();
      })
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      // onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <CarModelEditDialogHeader id={id} onHide={onHide} />
      <CarModelEditForm
        saveCarModel={saveCarModel}
        actionsLoading={actionsLoading}
        carModel={carModel}
        categoryEntities={categoryEntities}
        onHide={onHide}
      />
    </Modal>
  );
}
