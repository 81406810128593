import styled from '@emotion/styled';

export const CarStatusCssClasses = ["danger", "success", "info", ""];
// export const CarStatusTitles = ["New", "Used"];
export const CarStatusTitles = [{id: 'New', name: 'New'}, {id: 'Used', name: 'Used'}];
export const CarTypeCssClasses = ["success", "primary", ""];
export const CarTypeTitles = ["Business", "Individual", ""];
export const CarSaleStatusTitles = {sold: "Sold", free: "Free",inprocess:"In-Process"};
export const CarSaleStatusCssClasses = {free: "success", sold: "danger",inprocess:"default"};
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 }, 
  { text: "25", value: 10 }, 
  { text: "50", value: 50 }, 
  { text: "100", value: 100 }
];
export const initialFilter = {
  filter: {
    initialval:true
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};

export const styledColorPickerElements = {
  Label: styled.label`
    input:checked ~ span {
      border-color: ${({bg}) => `${bg} !important`};
    };
    input:checked ~ span:after {
      background-color: ${({bg}) => `${bg} !important`};
    };
  `,
  Span: styled.span`
    background-color: ${({bg}) => bg !== undefined && bg.toLowerCase() === 'white' ? 'black !important' : 'transparent !important'};
    &:after {
      background-color: ${({bg}) => `${bg} !important`};
    };
  `
}
