import * as proformaUIHelpers from "../cars/car-proformas/ProformasUIHelper";
import * as salesUIHelpers from "../cars/car-sales/SalesUIHelper";

export const defaultSorted = proformaUIHelpers.defaultSorted
export const sizePerPageList = proformaUIHelpers.sizePerPageList
export const initialFilter = proformaUIHelpers.initialFilter
export const ProformaStatusCssClasses = proformaUIHelpers.ProformaStatusCssClasses
export const ProformaStatusTitles = proformaUIHelpers.ProformaStatusTitles
export const ProformaStatuses = proformaUIHelpers.ProformaStatuses

export const PaymentTypes = salesUIHelpers.PaymentTypes
export const initProforma = salesUIHelpers.initProforma
export const ProformaStatusTitlesInSale = salesUIHelpers.ProformaStatusTitles
