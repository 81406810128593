import axios from "axios";

export const SPECIFICATIONS_URL = "api/banks";
export const URL = "http://localhost:8080/api/banks";
export const CUSTOMERS_URL = process.env.REACT_APP_AUTO_DEALER_API+'banks';



export function getAllBanks(){
  return axios.get(CUSTOMERS_URL)
}
// CREATE =>  POST: add a new banks to the server
export function createBank(bank) {
  return axios.post(CUSTOMERS_URL,bank);
}

// READ
// Server should return filtered banks by bankId
export function getAllProductBanksByBankId(bankId) {
  return axios.get(`${CUSTOMERS_URL}?bankId=${bankId}`);
}

export function getBankById(bankId) {
  return axios.get(`${CUSTOMERS_URL}/${bankId}`);
}

// Server should return sorted/filtered banks and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/banksfind/{bankId}'. Should be 'api/banks/find/{bankId}'!!!
export function findBanks(queryParams, bankId) {
  return axios.post(`${CUSTOMERS_URL}find/${bankId}`, { queryParams });
}

// UPDATE => PUT: update the bank
export function updateBank(bank) {
  return axios.put(`${CUSTOMERS_URL}/${bank.id}`, 
    bank
  );
}

// DELETE => delete the bank
export function deleteBank(bankId) {
  return axios.delete(`${CUSTOMERS_URL}/${bankId}`);
}

// DELETE banks by ids
export function deleteBanks(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteBanks`, { ids });
}

export function getDeleteValidationById(id){
  return axios.get(`${CUSTOMERS_URL}/${id}/delete_validate`);
}