import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { LoadingDialog } from "../../../../../../../_metronic/_partials/controls";

export function SubscriptionsLoadingDialog() {
  // Sales Redux state
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.tenants.tenantListLoading }),
    shallowEqual
  );
  // looking for loading/dispatch
  useEffect(() => {}, [isLoading]);
  return <LoadingDialog isLoading={isLoading} text="Loading ..." />;
}
