/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/inspections/inspectionsActions";
import { useInspectionsUIContext } from "./InspectionsUIContext";

export function InspectionDeleteDialog() {
  // Inspections UI Context
  const specsUIContext = useInspectionsUIContext();
  const specsUIProps = useMemo(() => {
    return {
      id: specsUIContext.selectedId,
      productId: specsUIContext.productId,
      show: specsUIContext.showDeleteInspectionDialog,
      onHide: specsUIContext.closeDeleteInspectionDialog,
      onHideEdit: specsUIContext.closeEditInspectionDialog,
      queryParams: specsUIContext.queryParams,
      setIds: specsUIContext.setIds,
    };
  }, [specsUIContext]);

  // Specs Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.inspections.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!specsUIProps.id) {
      specsUIProps.onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specsUIProps.id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteInspection = () => {
    // server request for deleting spec by id
    dispatch(actions.deleteInspection(specsUIProps.id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchInspectionsInCar(specsUIProps.productId))
      specsUIProps.setIds([]);
      specsUIProps.onHide();
      specsUIProps.onHideEdit();
    });
  };

  return (
    <Modal
      show={specsUIProps.show}
      // onHide={specsUIProps.onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Inspection Delete
        </Modal.Title>
        <Nav className="ml-auto" onClick={() => specsUIProps.onHide()}>
        <Nav.Link className="p-0 text-dark">
          <span className="nav-icon">
            <span className="svg-icon svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                  </g>
                </g>
              </svg>
            </span>
          </span>
        </Nav.Link>
      </Nav>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete this inspection?</span>
        )}
        {isLoading && <span>Inspection is deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div className="mr-auto">
          <button
            type="button"
            onClick={specsUIProps.onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteInspection}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
