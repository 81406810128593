import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "./../../../../_metronic/_partials/controls";
import { OrdersTable } from "./orders-table/OrdersTable";
import { useProformasUIContext } from "../cars/car-proformas/ProformasUIContext";
import { useSalesUIContext } from "../cars/car-sales/SalesUIContext";
import * as employeeActions from "../../_redux/employees/employeesActions";
import {openNewOrderModal} from "../../_redux/cars/carsActions";
import { OrdersFilter } from "./orders-filter/OrdersFilter";

export function OrdersCard(props) {

const { currentState } = useSelector(
	(state) => ({ currentState: state }),
	shallowEqual
);
const { newOrder } = currentState.cars;

  const proformasUIContext = useProformasUIContext();

  const proformasUIProps = useMemo(() => {
    return {
      openEditProformaDialog: proformasUIContext.openNewProformaDialog,
    };
  }, [proformasUIContext]);

  const salesUIContext = useSalesUIContext();
  const salesUIProps = useMemo(() => {
    return {
      openNewSaleDialog: salesUIContext.openNewSaleDialog,
    };
  }, [salesUIContext]);

  const dispatch = useDispatch();
  useEffect(() => {
	dispatch(employeeActions.getAllEmployees());
  }, [dispatch]);
	
if(newOrder){
	proformasUIProps.openEditProformaDialog();
	dispatch(openNewOrderModal({newOrder:false}));
}

  return ( 
    <div className="content d-flex flex-column-fluid" id="kt_content">
		<div className="container">
			<div className="d-flex">
				<div className="flex-column offcanvas-mobile w-300px w-xl-325px" id="kt_profile_aside">
					<div className="card card-custom gutter-b">
						<div className="card-header border-0 pt-5">
							<h4 className="card-title align-items-start flex-column mb-3">
								<span className="card-label font-size-h3 font-weight-bolder text-dark">Filter</span>
							</h4>
						</div>
						<div className="card-body pt-0">
							<OrdersFilter />
						</div>
					</div>
				</div>
				<div className="flex-row-fluid ml-lg-8">
					<Card>
						<CardHeader title="Orders List">
							<CardHeaderToolbar>
								<button
									type="button"
									className="btn btn-primary"
									onClick={proformasUIProps.openEditProformaDialog}
								>
									New Order
								</button>
                                {/* &nbsp;&nbsp;
                                <button
									type="button"
									className="btn btn-primary"
									onClick={salesUIProps.openNewSaleDialog}
								>
									Direct Sale
								</button> */}
							</CardHeaderToolbar>
						</CardHeader>
						<CardBody>
							{/* Orders filter for mobile view */}
							<div className="d-block d-lg-none">
								<OrdersFilter />
							</div>
							<OrdersTable />
						</CardBody>
					</Card>	
				</div>
			</div>
		</div>
	</div>
  );
}
