// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo,useState,useLayoutEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
}  from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux_admin/clients_adm/clientsActions";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../TenantsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { PaginationLinks } from "../../../../../../_metronic/_partials/controls";
import { useTenantsUIContext } from "../TenantsUIContext";
import cloneDeep from 'lodash/cloneDeep';
import NotFoundPage from "../../../shared/NotFoundPage";
import $ from 'jquery';

export function TenantsTable() {
  // Tenants UI Context
  const tenantsUIContext = useTenantsUIContext();
  const tenantsUIProps = useMemo(() => {
    return {
      ids: tenantsUIContext.ids,
      setIds: tenantsUIContext.setIds,
      queryParams: tenantsUIContext.queryParams,
      setQueryParams: tenantsUIContext.setQueryParams,
      openEditTenantDialog: tenantsUIContext.openEditTenantDialog,
      openDeleteTenantDialog: tenantsUIContext.openDeleteTenantDialog,
      showTenantStatusDialog:tenantsUIContext.showTenantStatusDialog
    };
  }, [tenantsUIContext]);

  // Getting curret state of tenants list from store (Redux)
  const { currentState,subscriptionPlans } = useSelector(
    (state) => ({ currentState: state.tenants
    ,subscriptionPlans:state.settingsAdmin.subscriptionPlan.entities, }),
    shallowEqual
  );
  const { tenantTotalCount:totalCount, tenantEntities:entities, tenantListLoading:listLoading,error,apiErrorData } = currentState;
  const [tableData,setTableDate] = useState([]);
  const [notFound,setNotFound] = useState(false);
  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }else if(error 
          && apiErrorData?.errorCode==="NOT_FOUND"){
            setNotFound(true);
        }
    }
  },[error])
  useEffect(()=>{
    if(notFound){
      $("#kt_content > div > div > div.card-header > div.card-toolbar > button").hide();
    }
  },[notFound])
  
  // Tenants Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    tenantsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchTenants());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    
  }, [ dispatch]);
  
  useEffect(()=>{
    setTableDate(cloneDeep(entities))
  },[entities])

  // Table columns
  const columns = [
    
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          tenantsUIProps.openEditTenantDialog(row.tenantClientId);
        }
      }
    },
    
    {
      dataField: "shortName",
      text: "Short Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          tenantsUIProps.openEditTenantDialog(row.tenantClientId);
        }
      }
    },
    
    {
      dataField: "subscriptions",
      text: "Subscription Type",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter:(cell)=>{
       debugger;
       
       return subscriptionPlans && subscriptionPlans.filter(item=>item.id === cell.filter(item=>item.active)[0]?.sub_plan_id)[0]?.name;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          tenantsUIProps.openEditTenantDialog(row.tenantClientId);
        }
      }
    }
    ,
    
    {
      dataField: "active",
      text: "active",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter:(cell)=>(<div>{cell ? <span className="label label-lg label-light-success label-inline">Active</span>:<span className="label label-lg label-light-danger label-inline">In-Active</span>}</div>),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          tenantsUIProps.openEditTenantDialog(row.tenantClientId);
        }
      }
    }
    ,
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditTenantDialog: tenantsUIProps.openEditTenantDialog,
        showTenantStatusDialog: tenantsUIProps.showTenantStatusDialog,
      },
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: tableData?.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: tenantsUIProps.queryParams.pageSize,
    page: (tenantsUIProps.queryParams.pageNumber - 1) < (tableData?.length/tenantsUIProps.queryParams.pageSize) ? tenantsUIProps.queryParams.pageNumber : 
    tenantsUIProps.queryParams.pageNumber == 1 ?  tenantsUIProps.queryParams.pageNumber:tenantsUIProps.queryParams.pageNumber - 1  ,
  };

  function filter(e){
    const filter = entities?.filter(item => item.name.toLowerCase().indexOf(e.toLowerCase()) > -1 || item.telephone?.indexOf(e.toLowerCase()) > -1 || item.tin?.indexOf(e.toLowerCase()) > -1  )
    setTableDate(filter)
  }

  function pageHandler (paginationProps){
    
      const pagenumber = paginationProps?.page || 1
      const sizePerPage = paginationProps?.sizePerPage
      tenantsUIProps.setQueryParams(
        (prev) =>
        "pagination" === "pagination"
          ? { ...prev, pageNumber:pagenumber, pageSize: sizePerPage }
          : prev
      )
      
  }

  return (
    <>
    {!notFound ?
    <>
    <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                   onChange={(e) => {
                    filter(e.target.value.toLocaleLowerCase().trim());
                   }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          
          return (
            <>
              
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                keyField="id"
                data={tableData === null ? [] : tableData}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={pageHandler(paginationProps)
                 }
                rowStyle={{ cursor: 'pointer' }}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={tableData} />
                <NoRecordsFoundMessage entities={tableData} />
              </BootstrapTable>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
              <PaginationLinks paginationProps={paginationProps} />
              <div className="ml-lg-auto">
              <SizePerPageDropdownStandalone
                  { ...paginationProps }
                />
                <PaginationTotalStandalone
                  { ...paginationProps }
                />
              </div>
              </div>
              
              </>
          );
        }}
      </PaginationProvider>
    </>
     :<NotFoundPage/>}
    </>
  );
}
