import React, { useEffect, useMemo, useState, useRef,forwardRef,useLayoutEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../_redux_admin/clients_adm/clientsActions";
import { useSubscriptionsUIContext } from "../SubscriptionsUIContext";
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment'
import SubscriptionsAGGrid from "./Ag_grid_for_subscriptions";
import $ from 'jquery';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import { Loader} from 'react-loaders';

const SubscriptionsTable = forwardRef((props, ref)  => {
    const { ref1, ref2 } = ref;
  const exportInsideCSV = useRef();
  const exportInsidePrint = useRef();
  const subscriptionsUIContext = useSubscriptionsUIContext();
  const subscriptionsUIProps = useMemo(() => {
    return {
      ids: subscriptionsUIContext.ids,
      setIds: subscriptionsUIContext.setIds,
      queryParams: subscriptionsUIContext.queryParams,
      setQueryParams: subscriptionsUIContext.setQueryParams,
    };
  }, [subscriptionsUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state }),
    shallowEqual
  );
  let { subscriptionsForReport: entities,error,apiErrorData,saleListLoading } = currentState.tenants;
  const {clientEntities} = currentState.client;
  
  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }
    }
  },[error])

  const [tableData,setTableData] = useState(null)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchSubscriptionsForReport(subscriptionsUIProps.queryParams))
    
  }, [subscriptionsUIProps.queryParams, dispatch]);
  
  useEffect(()=>{
    
    entities = entities && entities.map(e => ({...e, 
      amount: e.amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      date: moment(e.date).format('DD/MM/YYYY'), 
      valid_until: moment(e.valid_until).format('DD/MM/YYYY'), 
    }))
    setTableData(cloneDeep(entities))
  },[entities])


  
  const [columnDefs, setColumnDefs] = useState([
    
    {
      headerName: 'CLIENT',
      field: 'client_name',
     
    },
    
    {
      headerName: 'Sub Type',
      field: 'sub_type',
      
    },
    {
      headerName: 'DATE',
      field: 'date',
       
    },
    {
      headerName: 'VALID UNTIL',
      field: 'valid_until',
      
    },
    {
      headerName: 'PERIOD',
      field: 'period',
      minWidth: 65,
      Width: 70,
      flex: 3,
    },
    {
      headerName: 'AMT/SUB',
      field: 'amount',
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      
    },
    
  ]);

  const handlerCsv = () => {
    
    exportInsideCSV.current.click();
  };
  const handlerPrint = () => {
    $('#SubContainer').css('visibility','hidden');
    $('#blockConstiner').show()
    exportInsidePrint.current.click();
  };

  const componentRef = useRef(null);
  
  return (
    
          <>
            
              <div className="d-flex justify-content-end">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="spec-edit-tooltip">Export to Excel</Tooltip>
                  }
                >
                
                <input
                  ref={ref1}
                  hidden
                  className="btn btn-success"
                  onClick={handlerCsv}
                ></input>

                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="spec-edit-tooltip">Export to PDF</Tooltip>
                  }
                >
                
                  <input
                  ref={ref2}
                  hidden
                  className="btn btn-success"
                  onClick={handlerPrint}
                  
                ></input> 
                </OverlayTrigger>
              </div>
          <BlockUi blocking={saleListLoading} loader={<Loader active type={'ball-triangle-path'} color="#9d9dad" />} tag="div" message="" >
            <div className="report-content" ref={componentRef} style={{ height: '100%' }}>
            
              <div id="blockConstiner" style={{display:'none',height: '100%',width:'100%' }} ><BlockUi style={{height:'50px'}} blocking={true} loader={<Loader active type={'ball-triangle-path'} color="#9d9dad" />} tag="div" message="Loading" ></BlockUi></div>
              <div id="SubContainer" >
              <div style={{ height: '100%',width:'100%' }}>
               {tableData  && <SubscriptionsAGGrid 
                  
                  ref={{ref1:exportInsideCSV,ref2:exportInsidePrint}} 
                  tableData= {tableData} 
                  columnDefs={columnDefs}
                  
                  entities={entities} ></SubscriptionsAGGrid>}
              </div>
              </div>
            </div>
            </BlockUi>
          </>
    
  );
});

export default SubscriptionsTable;





