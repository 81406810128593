import React, { useEffect, useMemo, useState } from "react";
import { useProformasUIContext } from "../cars/car-proformas/ProformasUIContext";
import { useSalesUIContext } from "../cars/car-sales/SalesUIContext";
import { useCarsUIContext } from "../cars/CarsUIContext";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/home/homeActions";
import * as carActions from "../../_redux/cars/carsActions";
import { ProformaEditDialog } from "../cars/car-proformas/Proforma-edit-dialog/ProformaEditDialog";
import NumberFormat from "react-number-format";
import { ProformaPrintModal } from "../cars/car-proformas/print-proforma/ProformaPrintModal";
import { SalesView } from "../cars/car-sales/SalesView";
import { SaleDeleteDialog } from "../cars/car-sales/SaleDeleteDialog";
import { SaleContinueDialog } from "../cars/car-sales/sale-edit-dialog/SaleContinueDialog";
import { SaleCompleteDialog } from "../cars/car-sales/sale-edit-dialog/SaleCompleteDialog";
import { ProformaDeleteSales } from "../cars/car-proformas/Proforma-edit-dialog/ProformaDeleteSalesModal";
import moment from "moment";
import { dateDiff } from "../shared/util";
import { ProformaMenuModal } from "../cars/car-proformas/print-proforma/ProformaMenuModal";

export default function Notification() {
  const specsUIContext = useProformasUIContext();
  const specsUIProps = useMemo(() => {
    return {
      openProformaPrintDialog: specsUIContext.openProformaPrintDialog,
    };
  }, [specsUIContext]);

  const salesUIContext = useSalesUIContext();
  const salesUIProps = useMemo(() => {
    return {
      openViewSalesDialog: salesUIContext.openViewSalesDialog,
    };
  }, [salesUIContext]);

  const carsUIContext = useCarsUIContext();
  const carsUIProps = useMemo(() => {
    return {
      openEditCarPage: carsUIContext.openEditCarPage,
    };
  }, [carsUIContext]);

  //const [notification,setNotification] = useState([])

  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({
      currentState: state,
    }),
    shallowEqual
  );

  const { notifications, entities: proformaEntities } = currentState.proformas;
  const { roles } = currentState.auth;
  const { warehouse } = currentState.cars;

  useEffect(() => {
    dispatch(actions.getNotification());
    dispatch(carActions.getWarehouse());
  }, [proformaEntities, dispatch]);

  const [toggle, setToggle] = useState("overdue");

  //   const Notification = ({tab="Overdue"}) => {
  //     switch(tab) {
  //         case "Overdue":
  //             return <Proforma/>
  //         case "Uncollected":
  //             return <Uncollected/>
  //     }
  // }

  return (
    <div>
      <ul
        className="dashboard-tabs nav nav-pills nav-pills-sm nav-dark-75 position-sticky bg-white"
        style={{ cursor: "pointer", top: 0 }}
      >
        <div>
          <div className="d-none d-lg-block">
            <h3
              className="card-title font-weight-bolder text-dark mt-7 mb-3"
              style={{ paddingTop: "20px", fontWeight: "500", fontSize: "1.275rem" }}
            >
              Notification
            </h3>
          </div>
          <div className="d-lg-none">
            <h3
              className="card-title font-weight-bolder text-dark mt-7 mb-3"
              style={{ paddingTop: "20px", fontWeight: "500", fontSize: "1.275rem" }}
            >
              Notice
            </h3>
          </div>
          <div className="d-flex mb-5">
            <li className="nav-item">
              <a
                className={toggle === "overdue" ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"}
                data-toggle="tab"
                onClick={() => {
                  setToggle("overdue");
                }}
              >
                Overdue
              </a>
            </li>
            <li className="nav-item1">
              <a
                className={toggle === "uncollected" ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"}
                data-toggle="tab"
                onClick={() => {
                  setToggle("uncollected");
                }}
              >
                Uncollected{" "}
              </a>
            </li>
            <li className="nav-item2">
              <a
                className={toggle === "warehouse" ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"}
                data-toggle="tab"
                onClick={() => {
                  setToggle("warehouse");
                }}
              >
                Warehouse{" "}
              </a>
            </li>
          </div>
        </div>
      </ul>

      <>
        <ProformaPrintModal />
        {/* <ContractOfSaleModal/>
			<ProformaBankReleaseModal/>
			<CashContractOfSaleModal/> */}
        <ProformaMenuModal />
        <ProformaEditDialog newButton={false} />
        <ProformaDeleteSales />
      </>
      <>
        <SalesView />
        {/* <SaleEditDialog /> */}
        <SaleDeleteDialog />
        <SaleContinueDialog />
        <SaleCompleteDialog />
      </>

      {toggle === "overdue" &&
        notifications &&
        notifications
          ?.filter((item) => {
            return item.page_name === "proforma";
          })
          .map((items) => (
            //items.page_name === 'proforma' &&
            <div
              key={items.id}
              className={items.expired_date < 0 ? "d-flex align-items-center bg-light-danger rounded p-5 mb-5" : "d-flex align-items-center bg-light-warning rounded p-5 mb-5"}
            >
              <span className={items.expired_date < 0 ? "svg-icon svg-icon-danger mr-5" : "svg-icon svg-icon-warning mr-5 "}>
                <span className="svg-icon svg-icon-lg">
                  {items.page_name === "proforma" ? (
                    <span className="">
                      <i className="icon-2x  flaticon-coins"></i>
                    </span>
                  ) : items.expired_date < 0 ? (
                    <i className="icon-2x text-danger flaticon-chat-1"></i>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        ></rect>
                        <path
                          d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z"
                          fill="#000000"
                        ></path>
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)"
                          x="16.3255682"
                          y="2.94551858"
                          width="3"
                          height="18"
                          rx="1"
                        ></rect>
                      </g>
                    </svg>
                  )}
                </span>
              </span>

              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                  className="font-weight-bold text-dark-75 text-hover-danger font-size-lg mb-1"
                  onClick={async () => {
                    if (roles && roles.includes("Proforma")) {
                      await dispatch(carActions.selectCurrentCarId(items?.product_id));
                      specsUIProps.openProformaPrintDialog(items.id);
                    }
                  }}
                >
                  {items.title}
                </a>
                {items.page_name === "Uncollected" ? (
                  <span className="text-muted ">
                    {" "}
                    Uncollected Amount:{" "}
                    <NumberFormat
                      value={items.detail}
                      thousandSeparator=","
                      displayType="text"
                    ></NumberFormat>
                  </span>
                ) : (
                  <span className="text-muted ">{items.detail} </span>
                )}
              </div>
              <span
                className={items.page_name === "Uncollected" ? "font-weight-bolder text-info py-1 font-size-sm text-right" : items.expired_date < 0 ? "font-weight-bolder text-danger py-1 font-size-sm text-right" : "font-weight-bolder text-warning py-1 font-size-sm text-right"}
                onClick={async () => {
                  if (roles && roles.includes("Proforma")) {
                    await dispatch(carActions.selectCurrentCarId(items?.product_id));
                    specsUIProps.openProformaPrintDialog(items.id);
                  }
                }}
              >
                {items.val}
              </span>
            </div>
            //items.page_name === 'proforma' && ()
          ))}

      {toggle === "uncollected" &&
        notifications &&
        notifications
          ?.filter((item) => {
            return item.page_name === "Uncollected";
          })
          .map((items) => (
            //items.page_name === 'proforma' &&
            <div
              key={items.id}
              className={items.page_name === "Uncollected" ? "d-flex align-items-center bg-light-info rounded p-5 mb-5" : items.expired_date < 0 ? "d-flex align-items-center bg-light-danger rounded p-5 mb-5" : "d-flex align-items-center bg-light-warning rounded p-5 mb-5"}
            >
              <span className={items.page_name === "Uncollected" ? "svg-icon svg-icon-info mr-5 " : items.expired_date < 0 ? "svg-icon svg-icon-danger mr-5" : "svg-icon svg-icon-warning mr-5 "}>
                <span className="svg-icon svg-icon-lg">
                  {items.page_name === "Uncollected" ? (
                    <span className="">
                      <i className="icon-2x  flaticon-coins"></i>
                    </span>
                  ) : items.expired_date < 0 ? (
                    <i className="icon-2x text-danger flaticon-chat-1"></i>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        ></rect>
                        <path
                          d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z"
                          fill="#000000"
                        ></path>
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)"
                          x="16.3255682"
                          y="2.94551858"
                          width="3"
                          height="18"
                          rx="1"
                        ></rect>
                      </g>
                    </svg>
                  )}
                </span>
              </span>

              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                  className="font-weight-bold text-dark-75 text-hover-info font-size-lg mb-1"
                  onClick={() => {
                    if (roles && roles.includes("Proforma") && roles.includes("Sales")) {
                      dispatch(carActions.selectCurrentCarId(items.product_id));
                      // salesUIProps.openViewSalesDialog()
                      specsUIProps.openProformaPrintDialog(items.id);
                    }
                  }}
                >
                  {items.title}
                </a>
                {items.page_name === "Uncollected" ? (
                  <span className="text-muted ">
                    {" "}
                    Uncollected Amount:{" "}
                    <NumberFormat
                      value={items.detail}
                      thousandSeparator=","
                      displayType="text"
                    ></NumberFormat>
                  </span>
                ) : (
                  <span className="text-muted ">{items.detail} </span>
                )}
              </div>

              <span
                className={items.page_name === "Uncollected" ? "font-weight-bolder text-info py-1 font-size-sm text-right" : items.expired_date < 0 ? "font-weight-bolder text-danger py-1 font-size-sm text-right" : "font-weight-bolder text-warning py-1 font-size-sm text-right"}
                onClick={() => {
                  if (roles && roles.includes("Proforma") && roles.includes("Sales")) {
                    dispatch(carActions.selectCurrentCarId(items.product_id));
                    // salesUIProps.openViewSalesDialog()
                    specsUIProps.openProformaPrintDialog(items.id);
                  }
                }}
              >
                {items.val}
              </span>
            </div>
            //items.page_name === 'proforma' && ()
          ))}

      {toggle === "warehouse" &&
        warehouse &&
        warehouse.map((items) => {
          const monthDifference = moment(items.warehouse_expiration_date).diff(moment().format("YYYY-MM-DD"), "M", true);
          const expiresIn = dateDiff(items.warehouse_expiration_date, moment().format("YYYY-MM-DD"));
          // Show warehouses that will expire in two months
          if (monthDifference <= 2) {
            return (
              <div
                key={items.id}
                className={`d-flex align-items-center bg-light-${items.warehouse_expires_in > 0 ? "warning" : "danger"} rounded p-5 mb-5`}
                onClick={() => {
                  carsUIProps.openEditCarPage(items.id);
                }}
              >
                <span className="svg-icon svg-icon-warning mr-5 ">
                  <span className="svg-icon svg-icon-lg">
                    <span className="">
                      <i className="icon-2x  flaticon-coins"></i>
                    </span>
                  </span>
                </span>
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <a className={`font-weight-bold text-dark-75 text-hover-${items.warehouse_expires_in > 0 ? "warning" : "danger"} font-size-lg mb-1`}>
                    {items.make} {items.model}
                  </a>
                  <span className="text-muted ">{items.vin}</span>
                  <span className="text-muted ">
                    {items.warehouse_expires_in > 0 ? `Expires in ${items.warehouse_expires_in}` : `Overdue by ${(items.warehouse_expires_in - 1) * -1}`} days {expiresIn && <span>({expiresIn})</span>}
                    {/* Expires In: {items.warehouse_expires_in > 0 ? items.warehouse_expires_in : items.warehouse_expires_in-1} days {expiresIn && <span>({expiresIn})</span>} */}
                  </span>
                </div>
                <span className={`font-weight-bolder text-${items.warehouse_expires_in > 0 ? "warning" : "danger"} py-1 font-size-sm text-right`}>{items.year}</span>
              </div>
            );
          }
        })}
    </div>
  );
}
