import moment from 'moment'

export default function TableDataRowFormatter(data){
    if(data != undefined &&data!=null && data.length > 0) {
        var objectTest = [];
        for(var i=0;i<data.length;i++) {
            var att = {
                'detail':{
                    "id": data[i].id,
                    "date": moment(data[i].date).format("DD/MM/YYYY"),
                    'category': data[i].category,
                    'make': data[i].make,
                    'model': data[i].model,
                    'year': data[i].year,
                    'vin': data[i].vin,
                    'color': data[i].color,
                    'batch': data[i].batch, 
                    'customer': data[i].customer,
                    'amount': data[i].amount,
                    'price': data[i].price,
                    'status': data[i].sale_status,
                    'collected': data[i].collected,
                    'uncollected': data[i].uncollected,
                    'location': data[i].location, 
                    'employee': data[i].employee,
                    'issuedDay':data[i].issuedDay,
                    'overDueDay':data[i].overDueDay
                },
            }
            objectTest.push(att)
        }
        return objectTest;
    }
}
