import React, {Component} from 'react'
import { NavLink } from "react-router-dom";

class SupportIcon extends Component {
    render() {
        return(
            <span className="svg-icon svg-icon-md">
                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fillRule="nonzero" />
                        <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
                    </g>
                </svg>
            </span>
        )
    }
}

const Faq = () => {
  return (
	<div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">          
	<div className="content pt-0 d-flex flex-column flex-column-fluid" id="kt_content">
       <div className="container py-8">
                <div className="row">
                <div className="col-lg-6">
                        <div className="card card-custom wave wave-animate-slow wave-primary mb-8 mb-lg-0">
                            <div className="card-body">
                                <div className="d-flex align-items-center p-5">
                                    <div className="mr-6">
                                        <span className="svg-icon svg-icon-primary svg-icon-4x">   
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path d="M13,17.0484323 L13,18 L14,18 C15.1045695,18 16,18.8954305 16,20 L8,20 C8,18.8954305 8.8954305,18 10,18 L11,18 L11,17.0482312 C6.89844817,16.5925472 3.58685702,13.3691811 3.07555009,9.22038742 C3.00799634,8.67224972 3.3975866,8.17313318 3.94572429,8.10557943 C4.49386199,8.03802567 4.99297853,8.42761593 5.06053229,8.97575363 C5.4896663,12.4577884 8.46049164,15.1035129 12.0008191,15.1035129 C15.577644,15.1035129 18.5681939,12.4043008 18.9524872,8.87772126 C19.0123158,8.32868667 19.505897,7.93210686 20.0549316,7.99193546 C20.6039661,8.05176407 21.000546,8.54534521 20.9407173,9.09437981 C20.4824216,13.3000638 17.1471597,16.5885839 13,17.0484323 Z" fill="#000000" fillRule="nonzero" />
                                                    <path d="M12,14 C8.6862915,14 6,11.3137085 6,8 C6,4.6862915 8.6862915,2 12,2 C15.3137085,2 18,4.6862915 18,8 C18,11.3137085 15.3137085,14 12,14 Z M8.81595773,7.80077353 C8.79067542,7.43921955 8.47708263,7.16661749 8.11552864,7.19189981 C7.75397465,7.21718213 7.4813726,7.53077492 7.50665492,7.89232891 C7.62279197,9.55316612 8.39667037,10.8635466 9.79502238,11.7671393 C10.099435,11.9638458 10.5056723,11.8765328 10.7023788,11.5721203 C10.8990854,11.2677077 10.8117724,10.8614704 10.5073598,10.6647638 C9.4559885,9.98538454 8.90327706,9.04949813 8.81595773,7.80077353 Z" fill="#000000" opacity="0.3" />
                                                </g>
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="d-flex flex-column">
									<NavLink exact to='/support'   className="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3">User Guide
												</NavLink>
												 <div className="text-dark-75">Learn to use the system</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">  
                        <div className="card card-custom wave wave-animate-slow wave-danger mb-8 mb-lg-0">
                            <div className="card-body">
                                <div className="d-flex align-items-center p-5">
                                    
                                    <div className="mr-6">
                                        <span className="svg-icon svg-icon-danger svg-icon-4x">
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path d="M16.3740377,19.9389434 L22.2226499,11.1660251 C22.4524142,10.8213786 22.3592838,10.3557266 22.0146373,10.1259623 C21.8914367,10.0438285 21.7466809,10 21.5986122,10 L17,10 L17,4.47708173 C17,4.06286817 16.6642136,3.72708173 16.25,3.72708173 C15.9992351,3.72708173 15.7650616,3.85240758 15.6259623,4.06105658 L9.7773501,12.8339749 C9.54758575,13.1786214 9.64071616,13.6442734 9.98536267,13.8740377 C10.1085633,13.9561715 10.2533191,14 10.4013878,14 L15,14 L15,19.5229183 C15,19.9371318 15.3357864,20.2729183 15.75,20.2729183 C16.0007649,20.2729183 16.2349384,20.1475924 16.3740377,19.9389434 Z" fill="#000000" />
                                                    <path d="M4.5,5 L9.5,5 C10.3284271,5 11,5.67157288 11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L4.5,8 C3.67157288,8 3,7.32842712 3,6.5 C3,5.67157288 3.67157288,5 4.5,5 Z M4.5,17 L9.5,17 C10.3284271,17 11,17.6715729 11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L4.5,20 C3.67157288,20 3,19.3284271 3,18.5 C3,17.6715729 3.67157288,17 4.5,17 Z M2.5,11 L6.5,11 C7.32842712,11 8,11.6715729 8,12.5 C8,13.3284271 7.32842712,14 6.5,14 L2.5,14 C1.67157288,14 1,13.3284271 1,12.5 C1,11.6715729 1.67157288,11 2.5,11 Z" fill="#000000" opacity="0.3" />
                                                </g>
                                            </svg>   
                                        </span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <a href="#" className="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3">Tutorials</a>
                                        <div className="text-dark-75">Books, Articles &amp; Videos </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>  
                    </div>
                    </div>
                    <div class="container mb-8">
							<div class="card card-custom p-6">
								<div class="card-body">
									<div class="row">
										<div class="col-lg-9">
											<div class="tab-content">
												<div class="accordion accordion-light accordion-light-borderless accordion-svg-toggle" id="faq">
                        <div class="card border-top-0">
														<div class="card-header" id="faqHeading1">
															<a class="card-title collapsed text-dark" data-toggle="collapse" href="#faq1" aria-expanded="false" aria-controls="faq1" role="button">
																<span class="svg-icon svg-icon-primary">
																<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>														
																</span>
																<div class="card-label text-dark pl-4">How can I prepare Proforma/Order? </div>
															</a>
														</div>
														<div id="faq1" class="collapse" aria-labelledby="faqHeading1" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">You can prepare proforma in 2 ways.<br></br><br></br>
                              1.	Click Cars from the top menu. This will open the Cars List page. Using the Filter pane on the left side, search for the car you want to prepare proforma for. Click on the car and go to the Proforma tab. Add New Proforma button. <br></br>
                               <br></br>
                               <img src="/media/demos/images/newproforma.png"  alt="" style={{width: "40vmax", paddingTop: "10px"}} /> <br></br><br></br>
                               2.	From the top menu, click on Orders then click on New Order button then fill out the forms. <br></br>
                                </div>
														</div>
													</div>
													<div class="card border-top-0">
														<div class="card-header" id="faqHeading2">
															<a class="card-title collapsed text-dark" data-toggle="collapse" href="#faq2" aria-expanded="false" aria-controls="faq2" role="button">
																<span class="svg-icon svg-icon-primary">
																<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>														
																</span>
																<div class="card-label text-dark pl-4">	How can I add payment or sales for proforma?</div>
															</a>
														</div>
														<div id="faq2" class="collapse" aria-labelledby="faqHeading2" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">You can make payments in 2 ways.<br></br><br></br>
                            	Click Cars from the top menu. This will open the Cars List page. Using the Filter pane on the left side, search for the car you want to make payments for. Go to the Proforma tab, fill out the proforma form then click on next to fill the sales form. <br></br>
                               <br></br>
                               <img src="/media/demos/images/salesfaq2.png"  alt="" style={{width: "40vmax", paddingTop: "15px"}} /> <br></br><br></br>
                                </div>
														</div>
													</div>
													<div class="card border-top-0">
														<div class="card-header" id="faqHeading21">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq21" aria-expanded="false" aria-controls="faq21" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4"> How can I add new Account for cash transfers? </div>
															</div>
														</div>
														<div id="faq21" class="collapse" aria-labelledby="faqHeading21" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Click on Transactions form the top menu, click on Cash Transfers and click on New Account. 
                              </div>
                              <img src="/media/demos/images/newaccount.png"  alt="" style={{width: "20vmax", paddingTop: "10px"}} /> <br></br><br></br>
														</div>
													</div>	
													<div class="card border-top-0">
														<div class="card-header" id="faqHeading22">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq22" aria-expanded="false" aria-controls="faq22" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4"> How can I add new Transaction inside an account? </div>
															</div>
														</div>
														<div id="faq22" class="collapse" aria-labelledby="faqHeading22" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Click on Transactions form the top menu, click on Cash Transfers, select view transactions on an account and click on New Transaction. 
                              </div>
                              <img src="/media/demos/images/newtransaction.png"  alt="" style={{width: "20vmax", paddingTop: "10px"}} /> <br></br><br></br>
														</div>
													</div>
													<div class="card border-top-0">
														<div class="card-header" id="faqHeading24">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq24" aria-expanded="false" aria-controls="faq24" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4"> How do I add a car into a warehouse?</div>
															</div>
														</div>
														<div id="faq24" class="collapse" aria-labelledby="faqHeading24" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">
																Click on Cars from the top menu and click on the New Car button. The General Information tab opens. Select 'Warehouse' from the location dropdown field. Fill out the others fields and save.
                              								</div>
                              								<img src="/media/demos/images/warehouse_select.png"  alt="" style={{width: "20vmax", paddingTop: "10px"}} /> <br></br><br></br>
														</div>
													</div>	
													<div class="card border-top-0">
														<div class="card-header" id="faqHeading25">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq25" aria-expanded="false" aria-controls="faq25" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4"> How do I see cars in warehouse? </div>
															</div>
														</div>
														<div id="faq25" class="collapse" aria-labelledby="faqHeading25" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">
																There are 2 ways to view cars in warehouse. <br /> <br />
																<h6>1. Dashboard</h6>
																&nbsp;&nbsp;&nbsp; - Click on Dashboard from the top menu and go to the Notificaton section and click on the Warehouse tab. <br />
																<img src="/media/demos/images/warehouse_faq.png"  alt="" style={{width: "20vmax", paddingTop: "10px"}} /> <br /><br />
																<h6>2. Header Notification</h6>
																&nbsp;&nbsp;&nbsp; - Click on the bell icon on the header and a dropdown will appear with a Warehouse menu. Click on the Warehouse menu. <br />
																<img src="/media/demos/images/warehouse_header.png"  alt="" style={{width: "20vmax", paddingTop: "10px"}} /> <br /><br />
																- Warehouse cars are sorted by expiration dates in ascending order (Warehouse cars that will expire the soonest will be on the top). You can click on the items to go to the car edit page.
                              								</div>
														</div>
													</div>
													<div class="card border-top-0">
														<div class="card-header" id="faqHeading26">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq26" aria-expanded="false" aria-controls="faq26" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4"> How do I see warehouse cars about to be expired? </div>
															</div>
														</div>
														<div id="faq26" class="collapse" aria-labelledby="faqHeading26" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">
																Warehouse cars that are about to be expired within the next 2 months or that have already expired are shown on the dashboard notification section and the header notification. <br />
																<img src="/media/demos/images/warehouse_faq.png"  alt="" style={{width: "20vmax", paddingTop: "10px"}} /> <br /><br />
                              								</div>
														</div>
													</div>	
													<div class="card border-top-0">
														<div class="card-header" id="faqHeading3">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq3" aria-expanded="false" aria-controls="faq3" role="button">
																<span class="svg-icon svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4">How can I cancel a Proforma?</div>
															</div>
														</div>
														<div id="faq3" class="collapse" aria-labelledby="faqHeading3" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">From the top menu, go to Orders and find the Proforma you want to cancel. Click on the edit icon. On the form, select Cancelled from the Status drop-down and save.</div>
														<br></br>
                            <img src="/media/demos/images/cancleprfaq.png"  alt="" style={{width: "30vmax", paddingTop: "10px"}} /> <br></br><br></br>
                            </div>
													</div>
													<div class="card border-top-0">
														<div class="card-header" id="faqHeading4">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq4" aria-expanded="false" aria-controls="faq4" role="button">
																<span class="svg-icon svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4">How can I find overdue Proformas?</div>
															</div>
														</div>
														<div id="faq4" class="collapse" aria-labelledby="faqHeading4" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Go to the dashboard's Notifications sections. Scroll down to the Proforma overdue part. </div>
													  <img src="/media/demos/images/overdue.png"  alt="" style={{width: "15vmax", paddingTop: "10px"}} /> <br></br><br></br>
                            </div>
													</div>
													<div class="card border-top-0">
														<div class="card-header" id="faqHeading5">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq5" aria-expanded="false" aria-controls="faq5" role="button">
																<span class="svg-icon svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4">How can I see my sales?</div>
															</div>
														</div>
														<div id="faq5" class="collapse" aria-labelledby="faqHeading5" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">From the top menu, go to Reports and open Sales. You can see a summarized report of your sales. You can also see the details by clicking on the Detail button. </div>
														  <img src="/media/demos/images/salesrfaq.png"  alt="" style={{width: "40vmax", paddingTop: "10px"}} /> <br></br><br></br>
                            </div>
													</div>
													<div class="card border-top-0">
														<div class="card-header" id="faqHeading6">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq6" aria-expanded="false" aria-controls="faq6" role="button">
																<span class="svg-icon svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4">	How can I find uncollected payments from sold cars?</div>
															</div>
														</div>
														<div id="faq6" class="collapse" aria-labelledby="faqHeading6" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">From the top menu, go to Reports and open Uncollected Payments. You can see a summarized report of your sales. </div>
                              <img src="/media/demos/images/uncollectedfaq.png"  alt="" style={{width: "40vmax", paddingTop: "10px"}} /> <br></br><br></br>
                          	</div>
													</div>
													<div class="card border-top-0">
														<div class="card-header" id="faqHeading7">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq7" aria-expanded="false" aria-controls="faq7" role="button">
																<span class="svg-icon svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4">How do I know my Stock? How many free cars do I have left?</div>
															</div>
														</div>
														<div id="faq7" class="collapse" aria-labelledby="faqHeading7" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Go to Reports from the top menu and click on Stock. This shows you detailed information on the cars you have in your stock. Alternatively, for a quick view, you can go to the dashboard and look at the Stock Report or the In Stock in Basic Stats' section.</div>
                              <table style={{width: "100%"}}>
                                                    <tr>
                                                        <td>  Uncollected Amount <br></br><img src="/media/demos/images/stockfaq.png"  alt="" style={{width: "30vmax"}} /></td>or 
                                                        <td> Overdue Proformas<br></br> <img src="/media/demos/images/stockfaq1.png"  alt="" style={{width: "18vmax", paddingLeft: "10px"}} /></td>
                                                    </tr>
                                                    </table>
                            </div>
													</div>
													<div class="card border-top-0">
														<div class="card-header" id="faqHeading8">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq8" aria-expanded="false" aria-controls="faq8" role="button">
																<span class="svg-icon svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4">	How can I make refunds?</div>
															</div>
														</div>
														<div id="faq8" class="collapse" aria-labelledby="faqHeading8" data-parent="#faq">
                            <div class="card-body text-dark-50 font-size-lg pl-12">You can make payments in 2 ways.
                              <br></br><br></br>
                              1. Click Cars from the top menu. This will open the Cars List page. Using the Filter pane on the left side, search for the car you want to make refunds for. Click on the $ icon from the Actions column. On the form, there a Payment Type drop-down. Select Refund, enter the amount and save.
                              <br></br><br></br>
                              2. Click Cars from the top menu. This will open the Cars List page. Using the Filter pane on the left side, search for the car you want to make refunds for. Go to the Sales tab and click on the Sales button. On the form, there a Payment Type drop-down. Select Refund, enter the amount and save.
                              <img src="/media/demos/images/refundfaq.png"  alt="" style={{width: "30vmax", paddingTop: "10px"}} /> <br></br><br></br>
                              </div>
														</div>
													</div>
													<div class="card border-top-0">
														<div class="card-header" id="faqHeading9">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq9" aria-expanded="false" aria-controls="faq9" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4">How can I add new car?</div>
															</div>
														</div>
														<div id="faq9" class="collapse" aria-labelledby="faqHeading9" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">To register or add a new car, click Cars from the top menu and click on the New Car button. The General Information tab opens. Fill out the form and save.
                              </div>
                              <img src="/media/demos/images/newcarfaq.png"  alt="" style={{width: "40vmax", paddingTop: "10px"}} /> <br></br><br></br>
														</div>
													</div>			
                          <div class="card border-top-0">
														<div class="card-header" id="faqHeading10">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq10" aria-expanded="false" aria-controls="faq10" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4"> How can I edit Car info?</div>
															</div>
														</div>
														<div id="faq10" class="collapse" aria-labelledby="faqHeading10" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Click Cars from the top menu. This will open the Cars List page. Using the Filter pane on the left side, search for the car you want to edit. You can search by Chassis No, Year, Make/Brand name, Location, etc.
                              Click on the car name. This will open the edit form. Make the necessary changes and click on Save.
                              </div>
														</div>
													</div>	
                          <div class="card border-top-0">
														<div class="card-header" id="faqHeading11">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq11" aria-expanded="false" aria-controls="faq11" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4"> How can I add new Make/Model?</div>
															</div>
														</div>
														<div id="faq11" class="collapse" aria-labelledby="faqHeading11" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Hover on Settings from the top menu and go to the Setup option. From the list, click on Brand. Fill out the required info and save.
                              </div>
                              <img src="/media/demos/images/newcatfaq.png"  alt="" style={{width: "40vmax", paddingTop: "10px"}} /> <br></br><br></br>
														</div>
													</div>	
                          <div class="card border-top-0">
														<div class="card-header" id="faqHeading12">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq12" aria-expanded="false" aria-controls="faq12" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4">How can I add Car Inspection?</div>
															</div>
														</div>
														<div id="faq12" class="collapse" aria-labelledby="faqHeading12" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Click Cars from the top menu. This will open the Cars List page. Using the Filter pane on the left side, search for the car you want to inspect. Now go to the Inspection tab and add the required information.
                              </div>
                              <img src="/media/demos/images/newinspfaq.png"  alt="" style={{width: "40vmax", paddingTop: "10px"}} /> <br></br><br></br>
														</div>
													</div>	
                          <div class="card border-top-0">
														<div class="card-header" id="faqHeading13">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq13" aria-expanded="false" aria-controls="faq13" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4">How can I add new Sales Person?</div>
															</div>
														</div>
														<div id="faq13" class="collapse" aria-labelledby="faqHeading13" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Click Settings from the top menu, click on Setup and go to Sales Person. Click on New Sales Person button. 
                              </div>
                              <img src="/media/demos/images/salespfaq.png"  alt="" style={{width: "40vmax", paddingTop: "10px"}} /> <br></br><br></br>
														</div>
													</div>
                          <div class="card border-top-0">
														<div class="card-header" id="faqHeading14">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq14" aria-expanded="false" aria-controls="faq14" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4">How can I add new Batch?</div>
															</div>
														</div>
														<div id="faq14" class="collapse" aria-labelledby="faqHeading14" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Click Settings from the top menu, click on Setup and go to Batch. Click on New Batch button. 
                              </div>
                              <img src="/media/demos/images/newbacthfaq.png"  alt="" style={{width: "40vmax", paddingTop: "10px"}} /> <br></br><br></br>
														</div>
													</div>	
                          <div class="card border-top-0">
														<div class="card-header" id="faqHeading15">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq15" aria-expanded="false" aria-controls="faq15" role="button">
																<span class="svg-icon svg-icon-primary">
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4">How can I add new Bank?</div>
															</div>
														</div>
														<div id="faq15" class="collapse" aria-labelledby="faqHeading15" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Click Settings from the top menu, click on Setup and go to Bank. Click on New Bank button. 
                              </div>
                              <img src="/media/demos/images/newbankfaq.png"  alt="" style={{width: "40vmax", paddingTop: "10px"}} /> <br></br><br></br>
														</div>
													</div>		
                          <div class="card border-top-0">
														<div class="card-header" id="faqHeading17">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq17" aria-expanded="false" aria-controls="faq17" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4"> How can I add new Color?</div>
															</div>
														</div>
														<div id="faq17" class="collapse" aria-labelledby="faqHeading17" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Click Settings from the top menu, click on Setup and go to Color. Click on New Color button. 
                              </div>
                              <img src="/media/demos/images/newcolorfaq.png"  alt="" style={{width: "40vmax", paddingTop: "10px"}} /> <br></br><br></br>
														</div>
													</div>	
                          <div class="card border-top-0">
														<div class="card-header" id="faqHeading18">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq18" aria-expanded="false" aria-controls="faq18" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4"> How can I add new Customers? </div>
															</div>
														</div>
														<div id="faq18" class="collapse" aria-labelledby="faqHeading18" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Click Settings from the top menu, click on Setup and go to Customers. Click on New Customer button. 
                              </div>
                              <img src="/media/demos/images/newcustomerfaq.png"  alt="" style={{width: "40vmax", paddingTop: "10px"}} /> <br></br><br></br>
														</div>
													</div>		
                          <div class="card border-top-0">
														<div class="card-header" id="faqHeading19">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq19" aria-expanded="false" aria-controls="faq19" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4"> How can I add new Locations? </div>
															</div>
														</div>
														<div id="faq19" class="collapse" aria-labelledby="faqHeading19" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Click Settings from the top menu, click on Setup and go to Locations. Click on New Location button. 
                              </div>
                              <img src="/media/demos/images/newlocationfaq.png"  alt="" style={{width: "40vmax", paddingTop: "10px"}} /> <br></br><br></br>
														</div>
													</div>
                          <div class="card border-top-0">
														<div class="card-header" id="faqHeading20">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq20" aria-expanded="false" aria-controls="faq20" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4"> How can I add new Suppliers? </div>
															</div>
														</div>
														<div id="faq20" class="collapse" aria-labelledby="faqHeading20" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Click Settings from the top menu, click on Setup and go to Suppliers. Click on New Supplier button. 
                              </div>
                              <img src="/media/demos/images/newsupplierfaq.png"  alt="" style={{width: "40vmax", paddingTop: "10px"}} /> <br></br><br></br>
														</div>
													</div>	

													{/* <div class="card border-top-0">
														<div class="card-header" id="faqHeading23">
															<div class="card-title collapsed text-dark" data-toggle="collapse" data-target="#faq23" aria-expanded="false" aria-controls="faq23" role="button">
																<span class="svg-icon svg-icon-primary">
																
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
																			<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)" />
																		</g>
																	</svg>
																</span>
																<div class="card-label text-dark pl-4"> How can I add Orders/Sales? </div>
															</div>
														</div>
														<div id="faq23" class="collapse" aria-labelledby="faqHeading23" data-parent="#faq">
															<div class="card-body text-dark-50 font-size-lg pl-12">Click on Transactions form the top menu, click on Orders/Sales from the drop-down, click on New Order button. After filling out order fields you can either finish or proceed to make payments for that order. 
                              </div>
                              <div><img src="/media/demos/images/orders_new.png"  alt="" style={{width: "20vmax", paddingTop: "10px"}} /> </div>
							  <div><img src="/media/demos/images/orders_order_wizard.png"  alt="" style={{width: "20vmax", paddingTop: "10px"}} /></div>
							  <div><img src="/media/demos/images/orders_payment_wizard.png"  alt="" style={{width: "20vmax", paddingTop: "10px"}} /></div>
							  <br></br><br></br>
														</div>
													</div> */}

												</div>												
											</div>								
										</div>
									</div>								
								</div>							
							</div>				
						</div>
            <div className="container">
    <div className="row">
        <div className="col-lg-12">
            <div className="card card-custom p-6 mb-8 mb-lg-0">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-7">
                            <h2 className="text-dark mb-4">Get in Touch</h2>
                            <p className="text-dark-50 line-height-lg">If any questions or comment click on the <a style={{color: "#0BB783"}}>Submit A Request</a> button and get in touch.</p>
                        </div>
                        <div className="col-sm-5 d-flex align-items-center justify-content-sm-end">
                        <a href="/ContactPage" className="btn font-weight-bolder text-uppercase font-size-lg btn-primary py-3 px-6">Submit a Request</a>
                        </div>   	
                    </div>
                </div>
            </div>
            </div>
            </div>
            </div>
					</div>
    </div>
  )
}

export default Faq