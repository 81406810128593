/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { ProformaStatuses } from "../ProformasUIHelper"

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditProformaDialog, openDeleteProformaDialog, openPrintMenu, openContractOfSale,openBankReleaseLetter,openCashContractOfSale }
) {

  function deleteDialog(row){
    if(row.status_id != ProformaStatuses.find(p => p.name === "Processed")?.id){
      openDeleteProformaDialog(row.id)
    }
  }

  return (
    <>
    <span>
      <OverlayTrigger
        overlay={<Tooltip id="specs-edit-tooltip">Edit Proforma</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={() => openEditProformaDialog(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} title=" " />
          </span>
        </a>
      </OverlayTrigger>

      <> </>
      <OverlayTrigger
        overlay={
          <Tooltip id="spec-delete-tooltip">{row.status_id === ProformaStatuses.find(p => p.name === "Processed")?.id ? 'Processed proformas can not be deleted' : 'Delete Proforma'}</Tooltip>
        }
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm mr-1"
          onClick={() => deleteDialog(row)}
          
        >
          <span className={row.status_id === ProformaStatuses.find(p => p.name === "Processed")?.id ? "svg-icon svg-icon-md svg-icon-default":"svg-icon svg-icon-md svg-icon-primary"}>
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} title=" " />
          </span>
        </a>
      </OverlayTrigger>
      <OverlayTrigger
        overlay={<Tooltip id="specs-edit-tooltip">Document Download</Tooltip>}
      >
        <a 
          onClick={() => {
            openPrintMenu(row.id);
          }}
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} title=" " />
          </span>
        </a>
      </OverlayTrigger>
        {/* <div className="dropdown  "> */}
        
                        
                        {/* <div className="dropdown-menu dropdown-menu-md dropdown-menu-right" >
												
                        <ul className="navi flex-column navi-hover py-2">
                          <li className="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">Choose an option:</li>
                          
                          
                          <li className="navi-item">
                          <a className="navi-link"
                          onClick={()=>openContractOfSale(row.id)}
                          ><span className="navi-icon">
                              <i className="flaticon2-contract"></i>
                            </span>
                            <span className="navi-text">Contract Of Sale (Bank)</span></a>
                          
                          </li>
                          <li className="navi-item">
                          <a className="navi-link"
                          onClick={()=>openCashContractOfSale(row.id)}
                          ><span className="navi-icon">
                              <i className="flaticon2-contract"></i>
                            </span>
                            <span className="navi-text">Contract Of Sale (Cash)</span></a>
                          </li>
                          
                          <li className="navi-item">
                          <a className="navi-link"
                           onClick={()=>openBankReleaseLetter(row.id)}
                          ><span className="navi-icon">
                              <i className="flaticon2-hospital"></i>
                            </span>
                            <span className="navi-text">Bank Release Letter</span></a>
                          </li>

                          

                        </ul>
                        
                      </div> */}
              {/* </div>  */}
      


      </span>
    </>
  );
}
