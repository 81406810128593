import React,{useState,useEffect} from 'react'
import { Link, NavLink } from 'react-router-dom'
import { authenticationService } from '../../modules/Auth_LocalStorage/_services/authentication.service';
import { useSelector,shallowEqual } from "react-redux";

const Footer = () => {
	const {auth, users} = useSelector((state) => ({ 
		auth: state.auth, 
		users: state.users,
	}), shallowEqual)
	const {roles} = auth;	
	const {loggedUser} = users

	const [version,setVersion] = useState({
		app:'',
		db:''
	})
	const [versionWarning,setVersionWarning]=useState(false);
	const reactVersion = `${process.env.REACT_APP_VERSION}`
	useEffect(()=>{
		async function fetchVersion(){
			if(authenticationService.currentUserValue){
				const url = `${process.env.REACT_APP_AUTO_DEALER_API}home/version`
				const token = localStorage.currentUser
				let response = await fetch(url,{
					headers: {Authorization: token}
				  })
				response = await response.json()
				
				if(!(response[0]===response[1] && response[1]===reactVersion))
				setVersionWarning(true)

				setVersion({app:response[0],db:response[1]})
			}
			
		}
		fetchVersion() 
	},[authenticationService.currentUserValue])
    return (
		<>
		{authenticationService.currentUserValue &&
		<div className="modal fade" id="exampleModalCenter1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true" data-backdrop="static">
			<div className="modal-dialog modal-lg modal-dialog-centered" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h6 className="modal-title">Release Notes</h6>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<i aria-hidden="true" className="ki ki-close"></i>
						</button>
					</div>
					<div className="modal-body" >
						<div className="scrollable" id="autoDealerVersionSection" style={{maxHeight: '500px'}}>
							<div>
								<h4>Version 2.4 - Jan 18, 2023</h4>
								<h6>Features</h6>
								<ul className='pl-0' style={{listStylePosition: 'inside'}}>
									<li>Added contact phone number</li>
								</ul>
								<h6>Bug Fixes</h6>
								<ul className='pl-0' style={{listStylePosition: 'inside'}}>
									<li>Fixed issue in selecting Groups</li>
									<li>Made dashboard Sales Percentage tooltip to show Others as instead of a long list</li>
									<li>Fixed bug where system gets stuck when adding new user</li>
								</ul>
							</div>
							<br />
							<div>
								<h4>Version 2.3 - Dec 30, 2022</h4>
								<h6>Features</h6>
								<ul className='pl-0' style={{listStylePosition: 'inside'}}>
									<li>Redesigned proforma print page</li>
									<li>Made proforma item descriptions to be optional</li>
									<li>Added Sales Person filter to the Sales report</li>
								</ul>
							</div>
							<br />
							<div>
								<h4>Version 2.2 - Dec 22, 2022</h4>
								<h6>Features</h6>
								<ul className='pl-0' style={{listStylePosition: 'inside'}}>
									<li>Added release notes</li>
									<li>Added yearly sales trend on dashboard</li>
									<li>Updated user guide and FAQ</li>
									<li>Cleaned up car brands and models list</li>
								</ul>
							</div>
							<br />
							<div>
								<h4>Version 2.1 - Dec 08, 2022</h4>
								<h6>Features</h6>
								<ul className='pl-0' style={{listStylePosition: 'inside'}}>
									<li>View closed transaction groups</li>
									<li>Header notification</li>
								</ul>
								<h6>Bug Fixes</h6>
								<ul className='pl-0' style={{listStylePosition: 'inside'}}>
									<li>Fixed header search to filter by vehicle chassis number</li>
								</ul>
							</div>
							<br />
							<div>
								<h4>Version 2.0 - Nov 30, 2022</h4>
								<h6>Features</h6>
								<ul className='pl-0' style={{listStylePosition: 'inside'}}>
									<li>Supplier filters on reports</li>
									<li>Header search</li>
									<li>Trial and subscription versions for clients</li>
									<li>Content change on proforma print footer</li>
									<li>Order menu page</li>
									<li>Contact us form is working</li>
									<li>Payment records deleted when proforma is canceled</li>
									<li>Client registration page</li>
									<li>More options for proforma print</li>
									<li>Users, roles, and groups in settings</li>
									<li>User login page</li>
									<li>Error handling page</li>
									<li>Total sales on recent sales dashboard</li>
									<li>Link to proforma from uncollected dashboard notification</li>
									<li>Link to car detail page from warehouse dashboard notification</li>
									<li>Terms of service</li>
									<li>New date picker theme</li>
									<li>Filters on cars list</li>
									<li>Collapsible report filters</li>
									<li>Multiple tabs on dashaboard notification</li>
									<li>Link to proforma on car list page</li>
									<li>AutoDealer version</li>
									<li>New dropdown select theme for cars list filters</li>
									<li>Formatting changes on sales detail report</li>
								</ul>
								<h6>Bug Fixes</h6>
								<ul className='pl-0' style={{listStylePosition: 'inside'}}>
									<li>Fixed missing filters on uncollected payments report</li>
									<li>Fixed untimely logout</li>
									<li>Cleaned up console errors on browser</li>
									<li>Fixed wrong validation error on car create/edit page</li>
									<li>Prompt to change proforma status to active when payment is deleted on a processed proforma</li>
									<li>Prevented standard users from viewing orders from all location</li>
									<li>Fixed wrong data on basic stats dashboard for standard users</li>
								</ul>
							</div>
							<br />
							<div>
								<h4>Version 1 - Nov 30, 2022</h4>
								<h6>Features</h6>
								<ul className='pl-0' style={{listStylePosition: 'inside'}}>
									<li>Autofill proforma item information when new car is added</li>
									<li>Modifications to bank deposit report</li>
									<li>Links to reports page from dashboard basic stats</li>
									<li>Client's name on PDF reports header</li>
									<li>Clicking on AutoDealer icon redirects to dashboard on mobile devices</li>
									<li>Renamed category to brand</li>
									<li>Expected amount on bank deposit report</li>
									<li>Price formatting on sales detail report</li>
									<li>Clickable row in proformas list that redirects to proforma</li>
									<li>User guide and FAQ pages</li>
									<li>Bank report grouped by car and bank account type</li>
									<li>Vertically and horizontally scrollable report pages</li>
									<li>Removed month tab from recent sales dashboard</li>
									<li>Changed employee labels to sales person</li>
									<li>Consistent date format throughout the system</li>
									<li>Overdue proformas notification on dashboard</li>
									<li>Layout changes to stock and sales report</li>
									<li>Stock detail on dashboard</li>
									<li>Cars list filter using customer, employee, and year</li>
									<li>Sale status icons on cars list page</li>
									<li>Changed proforma overdue to notification</li>
									<li>Recent sales section in dashboard</li>
									<li>Decimal formatting in reports</li>
									<li>Sales detail tab in sales report</li>
									<li>Highlighted menu items on hover</li>
									<li>Cancel status for proforma</li>
									<li>Warning when changing sale status to processed</li>
									<li>New TIN number field in customer form</li>
									<li>Hidden filter/search on mobile view</li>
									<li>Changed loan sales report to uncollected payments report</li>
									<li>New user column in sales list to show who registerd the data</li>
									<li>Label vehicle changed to car in all instances</li>
									<li>Confirmation for exceeding sales amount</li>
									<li>Cash options for bank account dropdown</li>
									<li>Graph and charts in the dashboard</li>
									<li>"Is non company account?" checkbox in bank form to identify company and non company bank accounts</li>
									<li>Modifications to car filter filelds</li>
								</ul>
								<h6>Bug Fixes</h6>
								<ul className='pl-0' style={{listStylePosition: 'inside'}}>
									<li>Fixed mis-match between dashboard stats and report</li>
									<li>Fixed to proforma amount disappearing after refund</li>
									<li>Fixed login button disappearing after click</li>
									<li>Fixed date-time issues</li>
									<li>Fixed error caused when hitting escape key on proforma edit page</li>
									<li>Fixed mis-match between sales detail and sales summary</li>
									<li>Fixed missing columns on report PDFs</li>
									<li>Fixed wrong filtering on car search</li>
									<li>Fixed wrong remaining amount value on sales</li>
									<li>Fixed wrong supplier information on car edit page</li>
									<li>Enabled standard users to create batch</li>
									<li>Fixed uncollected notification section in dashaboard</li>
									<li>Fixed wrong warning when making full payment</li>
									<li>Fixed broken customer field on new proforma form</li>
									<li>Fixed disappearing account number after editing</li>
									<li>Enabled new customer creation for cars without proforma</li>
									<li>Fixed broken proforma creation</li>
									<li>Fixed broken sale creation</li>
									<li>Prevented standard user from accessing the user maintenance</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="modal-footer p-2">
						<button type="button" className="btn btn-light-primary font-weight-bold btn-sm" data-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>}
		{authenticationService.currentUserValue &&
			<div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h6 className="modal-title" id="exampleModalLabel">AutoDealer</h6>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<i aria-hidden="true" className="ki ki-close"></i>
							</button>
						</div>
						<div className="modal-body text-center" >
							<p>
								<a href="" data-toggle="modal" data-target="#exampleModalCenter1" data-dismiss="modal">
								Version {process.env.REACT_APP_VERSION} {versionWarning ? 
								<span title={"Version mismatch. Backend: "+version.app+", Frontend: "+reactVersion+", & DB: "+version.db } className="svg-icon svg-icon-danger svg-icon-1x">
									<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"   viewBox="0 0 24 24" version="1.1">
										<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
											<rect x="0" y="0" width="24" height="24"/>
											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
											<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1"/>
											<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1"/>
										</g>
									</svg></span> : ''}</a><br />
								&copy; 2022 Dulcian Consulting <br />
								All rights reserved. <br/>
								<a href="http://www.autodll.com">www.autodll.com</a>
							</p>
						</div>
						<div className="modal-footer p-2">
							<button type="button" className="btn btn-light-primary font-weight-bold btn-sm" data-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>}
			<div className="mt-auto">
				<div className={`${(authenticationService.currentUserValue && roles && !roles.includes("ADMINUSERROLE")) ? 'footer' : ''} bg-white py-4 d-flex flex-lg-column`} id="kt_footer">		
					<div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
						<div className="nav nav-dark order-1 order-md-2">
							{authenticationService.currentUserValue && 
								<div className="d-flex align-items-start">
									<Link to="/dashboard" className="nav-link pr-3 pl-0 d-none d-lg-block">Home</Link>
									<a href="" className="nav-link px-3" data-toggle="modal" data-target="#exampleModalCenter">About</a> 
								</div>
							} 
							<Link to="/contact" className="nav-link pl-3 pr-0 d-none d-lg-block">Contact</Link>

							<Link to="/contact" className="nav-link pl-8 pr-0 d-lg-none">Contact</Link>

							<div className="nav-link pl-6 pr-0 d-none d-lg-block" data-toggle="modal" data-target="#exampleModalCustomScrollable"style={{cursor:"pointer"}}>Terms of Use & Privacy</div>

							<div className="nav-link pl-6 pr-0 d-lg-none" data-toggle="modal" data-target="#exampleModalCustomScrollable"style={{cursor:"pointer"}}>Terms of Use & Privacy</div>
							
							<div className="modal fade" id="exampleModalCustomScrollable" tabIndex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
								<div className="modal-dialog modal-dialog-scrollable" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title" id="exampleModalLabel">Terms of Use & Privacy</h5>
											<button type="button" className="close" data-dismiss="modal" aria-label="Close">
												<i aria-hidden="true" className="ki ki-close"></i>
											</button>
										</div>
										<div className="modal-body" style={{fontSize:"1.1rem"}}>
										<b>Introduction</b><br></br><br></br>
										These Terms of Service govern your use of our service at <b style={{color:"#0bb783"}}>autodll.com</b> operated by Dulcian Consulting.<br></br><br></br>
										Our Privacy Policy also governs your use of our Service and explains how we collect and safeguard information that results from your use of our Service.<br></br><br></br>
										Your agreement with us includes these Terms and you acknowledge that you have read and understood them, and agree to be bound of them.<br></br>	<br></br>	
										If you do not agree with (or cannot comply with) these Terms, then you may not use the Service, but please let us know by emailing at  <b style={{color:"#0bb783"}}>support@autdll.com</b> so we can try to find a solution. 
										<br></br><br></br>
										These Terms apply to all clients, users and others who wish to access or use Service.
										<br></br><br></br>
										<b>Subscription</b>		<br></br><br></br>
										Auto Dealer services are billed on a subscription basis. You will be billed on a recurring and periodic basis, billing cycles. Billing cycles will be set depending on the type of subscription plan you select when purchasing a Subscription.
											<br></br><br></br>
										At the end of each Billing Cycle, your will be asked to renew your subscription under the exact same conditions unless you cancel it or we cancel it. 
											<br></br><br></br>
										You may cancel your subscription renewal either through your online account management page or by contacting our customer support team.<br></br><br></br>
										A valid payment method is required to process the payment for your subscription. Valid methods are:<br></br>
										* Bank Transfer <br></br>
										* Telebirr<br></br>
										* Cash<br></br><br></br>
										You shall provide Dulcian Consulting with accurate and complete billing information that may include but not limited to full name, address sub-city, telephone number, and valid payment method information. 
										<br></br><br></br>
										
										<b>Accounts</b><br></br><br></br>
										You must not allow any other person to use your account to access the website. If you do so, you will take the responsibility for any data loss or alteration.<br></br><br></br>
										You must notify us in email or call immediately if you become aware of any unauthorized use of your account.<br></br><br></br>
										You must not use any other person's account to access the website, unless you have that person's express permission to do so.<br></br><br></br>
										You are responsible for any activity on our Service arising out of any failure to keep your password confidential, and maybe held liable for any losses arising out of such a failure.<br></br>
												<br></br>
										<b>Free Trial</b><br></br><br></br>
										Dulcian Consulting may, at its sole discretion, offer a Subscription with a free trial for a limited period of time.<br></br><br></br>
										You may be required to enter your billing information in order to sign up for Free Trial.<br></br><br></br>
										On the last day of Free Trial period, unless you renew your subscription, you will not be able to access our Service.<br></br><br></br>
										At any time and without notice, Dulcian Consulting reserves the right to <br></br><br></br>
										- modify Terms of Service of Free Trial offer, or <br></br>
										- cancel such Free Trial offer.<br></br>
												<br></br>
										<b>Fee Changes</b><br></br><br></br>
										Dulcian Consulting, in its sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.<br></br><br></br>
										Dulcian Consulting will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.
												<br></br><br></br>
												<b>Content and Privacy</b><br></br><br></br>
												Security and privacy are at the core of our business practices and we care deeply about protection.<br></br><br></br>
												Our Service allows you to maintain personal and financial data. Your data remains protected at all times and is only available to you.<br></br><br></br>
												You are responsible for the content or data that you create on our Service including its legality, reliability, and appropriateness.<br></br>
												<br></br>
												<b>Cancellation and suspension</b><br></br><br></br>
												We may:<br></br>
												Temporarily suspend your account; and/or<br></br><br></br>
												Cancel your account, at any time in our sole discretion, providing that if we cancel any services you have paid for and you have not breached these terms and conditions, you will be entitled to a refund of any amounts paid to us in respect of those services that were to be provided by us to you after the date of such cancellation; we will give you reasonable written notice of any cancellation.<br></br><br></br>
												You may cancel your account on our website [using your account control panel on the website]. You will not be entitled to any refund if you cancel your account.
												<br></br>
												<br></br>
												<b>Copyright Notice</b><br></br><br></br>
											
											Copyright <i className="icon-sm far fa-copyright"></i> 2022 Dulcian Consulting<br></br><br></br>
											Subject to the express provisions of these terms and conditions:<br></br>
											&nbsp;	&nbsp; * we, own and control all the copyright and other intellectual property rights in our website and the material on our website; and<br></br>
											&nbsp; 	&nbsp; * all the copyright and other intellectual property rights in our website and the material on our website are reserved.
										</div>
										<div className="modal-footer">
											<button type="button" className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{(authenticationService.currentUserValue && roles && !roles.includes("ADMINUSERROLE")) &&
				<nav className="navbar fixed-bottom navbar-expand navbar-dark bg-primary d-lg-none" id="footer-menu">
					<div className="collapse navbar-collapse" id="navbarCollapse">
						<ul className="navbar-nav justify-content-around align-items-center w-100">
							<li className="nav-item">
								<NavLink exact to="/dashboard" className="nav-link dashboard-icon">
									<i className="fa fa-house fa-2x"></i>
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink exact to="/cars" className="nav-link car-icon">
									<i className="fa fa-car fa-2x"></i>
								</NavLink>
							</li>
							{/* {roles && roles.includes("Sales") && roles.includes("Proforma") && 
							<>
								<li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="hover" aria-haspopup="true">
									<NavLink exact to='/orders' className="nav-link order-icon">
										<i className="fa fa-cart-shopping fa-2x"></i>
									</NavLink>
								</li>
							</>
							} */}
							{roles && ((roles.includes("Sales") && roles.includes("Proforma")) || roles.includes("TransactionReport")) &&
							<li className="menu-item menu-item-submenu  menu-item-rel menu-item-open menu-parent dropup" data-menu-toggle="hover" aria-haspopup="true">
								<NavLink exact to="#" activeClassName='' className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="dropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i className="fa-solid fa-exchange fa-2x"></i>
								</NavLink>
								<div className="sub-menu dropdown-menu" aria-labelledby="dropdown1">
									<ul>
										{roles.includes("Sales") && roles.includes("Proforma") &&
										<li className="menu-item">
											<NavLink exact to="/orders" id="stock" className="menu-link menu-child submenu-item">
												<span className="menu-text">Orders/Sales</span>
											</NavLink>
										</li>}
										{roles.includes("TransactionReport") &&
										<li className="menu-item">
											<NavLink exact to="/account" id="accounts" className="menu-link menu-child submenu-item">
												<span className="menu-text">Cash Transfers</span>														
											</NavLink>
										</li>}
									</ul>
								</div>
							</li>}
							{roles && (roles.includes("StockReport") || roles.includes("SaleReport") || roles.includes("UncollectedReport")) && 
							<>
								<li className="menu-item menu-item-submenu  menu-item-rel menu-item-open menu-parent dropup" data-menu-toggle="hover" aria-haspopup="true">
									<NavLink exact to="#" activeClassName='' className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="dropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<i className="fa-solid fa-file-lines fa-2x"></i>
									</NavLink>
									<div className="sub-menu dropdown-menu" aria-labelledby="dropdown1">
										<ul>
											<li className="menu-item">
												<NavLink exact to="/reports/stock" id="stock" className="menu-link menu-child submenu-item">
													<span className="menu-text">Stock</span>
												</NavLink>
											</li>
											<li className="menu-item">
												<NavLink exact to="/reports/sales" id="sales" className="menu-link menu-child submenu-item">
													<span className="menu-text">Sales</span>
												</NavLink>
											</li>
											<li className="menu-item">
												<NavLink exact to="/reports/uncollected_payments" id="loan-sales" className="menu-link menu-child submenu-item">
													<span className="menu-text text-wrap">Uncollected Payments</span>
												</NavLink>
											</li>
											<li className="menu-item">
												<NavLink exact to="/reports/bank_deposit" id="bank-deposit" className="menu-link menu-child submenu-item ">
													<span className="menu-text">Bank Deposits</span>
												</NavLink>
											</li>
										</ul>
									</div>
								</li>
							</>}
							<li className="menu-item menu-item-submenu menu-item-rel menu-item-open menu-parent dropup more-menus" data-menu-toggle="hover" aria-haspopup="true">
								<NavLink exact to="#" activeClassName='' className="nav-link dropdown-toggle d-flex align-items-center" id="dropdown2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i className="fa fa-bars fa-2x"></i>
								</NavLink>
								<div className="sub-menu dropdown-menu setting-menu" aria-labelledby="dropdown2">
									<ul style={{'padding': 0, 'listStyleType': 'none'}} className="text-center">
										<li className="menu-item pt-0">
											<div className="setting-items d-none text-left border-bottom pb-2 mb-2" aria-labelledby="dropdown2">
												<ul style={{'padding': 0, 'listStyleType': 'none'}}>
													<li className="menu-item menu-item-open menu-item-here menu-item-submenu p-0" data-menu-toggle="hover" aria-haspopup="true">
														{roles && roles.includes("AdminRole")&& 
														<div>
															<span className="dropdown-item px-0 pb-0" onClick={e => e.stopPropagation()}>
																<span className="menu-text">Admin Functions</span>
															</span>
															<div className="nested-menu sub-menu menu-submenu menu-submenu-classic menu-submenu-right" data-hor-direction="menu-submenu-right">
																<ul className="menu-subnav">
																	<li className='menu-item' aria-haspopup="true">
																		<NavLink exact to="/groups" id="groups" className="menu-link menu-child submenu-item">
																			<span className="menu-text">Groups</span>																
																		</NavLink>
																	</li>
																	<li className='menu-item' aria-haspopup="true">
																		<NavLink exact to="/users" id="users" className="menu-link menu-child submenu-item">
																			<span className="menu-text">Users</span>																
																		</NavLink>
																	</li>
																	<li className='menu-item' aria-haspopup="true">
																		<NavLink exact to="/profile" id="profile" className="menu-link menu-child submenu-item">
																			<span className="menu-text">Profile</span>																
																		</NavLink>
																	</li>
																</ul>
															</div>
														</div>}
														<div>
															<span className="dropdown-item px-0 pb-0" onClick={e => e.stopPropagation()}>
																<span className="menu-text">Setup</span>
															</span>
															<div className="nested-menu sub-menu menu-submenu menu-submenu-classic menu-submenu-right" data-hor-direction="menu-submenu-right">
																<ul className="menu-subnav">
																	<li className="menu-item"  aria-haspopup="true">
																		<NavLink exact to="/banks" id="banks" className="menu-link menu-child submenu-item">
																			<span className="menu-text">Banks</span>
																		</NavLink>
																	</li>
																	<li className="menu-item" aria-haspopup="true">
																		<NavLink exact to="/batch" id="batch" className="menu-link menu-child submenu-item">
																			<span className="menu-text">Batch</span>
																		</NavLink>
																	</li>
																	<li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
																		<NavLink exact to="/categories"  id="carmodels" className="menu-link menu-child submenu-item">
																			<span className="menu-text">Car Brand</span>
																		</NavLink>
																	</li>
																	<li className="menu-item" aria-haspopup="true">
																		<NavLink exact to="/colors"  id="colors" className="menu-link menu-child submenu-item">
																			<span className="menu-text">Colors</span>
																		</NavLink>
																	</li>
																	<li className="menu-item" aria-haspopup="true">
																		<NavLink exact to="/customers"  id="customers" className="menu-link menu-child submenu-item">
																			<span className="menu-text">Customers</span>
																		</NavLink>
																	</li>
																	<li className="menu-item" aria-haspopup="true">
																		<NavLink exact to="/employees"  id="employees" className="menu-link menu-child submenu-item">
																			<span className="menu-text">Sales Person</span>
																		</NavLink>
																	</li>
																	<li className="menu-item" aria-haspopup="true">
																		<NavLink exact to="/locations"  id="locations" className="menu-link menu-child submenu-item">
																			<span className="menu-text">Locations</span>
																		</NavLink>
																	</li>
																	<li className="menu-item" aria-haspopup="true">
																		<NavLink exact to="/suppliers"  id="suppliers" className="menu-link menu-child submenu-item">
																			<span className="menu-text">Suppliers</span>
																		</NavLink>
																	</li>
																</ul>
															</div>
														</div>
													</li>
												</ul>
											</div>
											<NavLink to="#" id="banks" className="menu-link menu-child submenu-item settings-icon align-items-center" onClick={e => e.stopPropagation()}>
												<i className='fa fa-sign invisible'></i>
												<i className="fa fa-gear fa-2x"></i>
												<i className="fa fa-angle-up fa-sm ml-2 direction-icon"></i>
											</NavLink>
										</li>
										{/* <li className='menu-item' aria-haspopup="true">
											<NavLink exact to={`/users/${loggedUser?.id}/changePassword`} id="changePwd" className="menu-link menu-child submenu-item">
												<i className="fa fa-key fa-2x"></i>														
											</NavLink>
										</li> */}
										<li className="menu-item" aria-haspopup="true">
											<NavLink exact to="/support"  id="support" className="menu-link menu-child submenu-item">
												<i className="fa fa-circle-question fa-2x"></i>
											</NavLink>
										</li>
										{/* <li className="menu-item" aria-haspopup="true">
											<NavLink exact to="#" onClick={()=>{authenticationService.logout()}}  className="menu-link menu-child submenu-item">
												<i className="fa fa-solid fa-arrow-right-from-bracket fa-2x"></i>
											</NavLink>
										</li> */}
									</ul>
								</div>
							</li>
						</ul>
					</div>
				</nav>
				}
			</div>
		
			
		</>
    )
}

export default Footer
