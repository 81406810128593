import {createSlice} from "@reduxjs/toolkit";

const initialEmployeesState = {
  employeeListLoading: false,
  employeeLoading: false,
  employeeTotalCount: 0,
  employeeEntities: null,
  employeeForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const employeesSlice = createSlice({
  name: "employees",
  initialState: initialEmployeesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.employeeListLoading = false;
      } else {
        state.employeeLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.employeeListLoading = true;
      } else {
        state.employeeLoading = true;
      }
    },
    // getEmployeeById
    employeeFetched: (state, action) => {
      state.employeeLoading = false;
      state.employeeForEdit = action.payload.employeeForEdit;
      state.error = null;
    },
    // findEmployees
    employeesFetched: (state, action) => {
      const { employeeTotalCount, employeeEntities } = action.payload;
      state.employeeListLoading = false;
      state.error = null;
      state.employeeEntities = employeeEntities;
      state.employeeTotalCount = employeeTotalCount;
    },
    // createEmployee
    employeeCreated: (state, action) => {
      state.employeeLoading = false;
      state.error = null;
      state.employeeEntities.push(action.payload.employee);
    },
    // updateEmployee
    employeeUpdated: (state, action) => {
      state.error = null;
      state.employeeLoading = false;
      state.employeeEntities = state.employeeEntities.map(entity => {
        if (entity.id === action.payload.employee.id) {
          return action.payload.employee;
        }
        return entity;
      });
    },


    // bulk Employee update and create 
    employeeBulkUpdated: (state, action) => {
      state.error = null;
      state.employeeLoading = false;
      state.employeeEntities = state.employeeEntities.map(entity => {
        if (entity.id === action.payload.employee.id) {
          return action.payload.employee;
        }
        return entity;
      });
    },

    employeesInCarFetched: (state, action) => {
      const { employees, employeesTotalCount } = action.payload;
      state.employeeEntities = employees;
      state.employeesTotalCount = employeesTotalCount;
      state.employeeListLoading = false;
    },
    //createEmployeeInCar

    employeeInCarCreated:(state, action) =>{
      state.error = null;
      state.employeeLoading = false;
      if(state.employeeEntities == undefined || state.employeeEntities == null){
        state.employeeEntities =  [];
        state.employeeEntities.push(action.payload.employee);
      }else{
        state.employeeEntities.push(action.payload.employee);
      }
      
    },

    employeeInCarUpdated:(state, action) => {
      state.error = null;
      state.employeeLoading = false;
      state.carForEdit.employees = state.carForEdit.employees.map(element => {
        if(element.id == action.payload.employee.id){
          return action.payload.employee;
        }else{
          return element;
        }
      })
    },
    // deleteEmployee
    employeeDeleted: (state, action) => {
      state.error = null;
      state.employeeLoading = false;
      state.employeeEntities = state.employeeEntities.filter(
        el => el.id !== action.payload.id);
    },
    // deleteEmployees
    employeesDeleted: (state, action) => {
      state.error = null;
      state.employeeLoading = false;
      state.employeeEntities = state.employeeEntities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
