import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import NumberFormat from "react-number-format";
import moment from "moment/moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/accounts/accountsActions";
import cloneDeep from 'lodash/cloneDeep';

export function ClosedTransactionsList({ account_id, onHide }) {
  // Getting curret state of products list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.accounts }),
    shallowEqual
  );
  const { 
    closedTransactionEntities_grouped: entities
  } = currentState;
  const [tableData, setTableData] = useState([]);

  // Products Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    if(account_id) {
      dispatch(actions.fetchClosedTransactionGroupsInAccount(account_id));
      dispatch(actions.fetchAccount(account_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account_id, dispatch]);

  useEffect(()=>{
    setTableData(cloneDeep(entities))
  },[entities])
  
  // Table columns
  const columns = [
    {
      dataField: "closed_date",
      text: "Closed Date",
      formatter: (cellContent, row) => (
        <span>
          {moment(row.closed_date).format("DD/MM/yyyy")}
        </span>
      ),
      // events: {
      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     accountsUIProps.openEditTransactionDialog(account_id, row.id);
      //   }
      // }
    },
    {
      dataField: "balance",
      text: "Balance",
      classes: "text-right",
      headerClasses: "text-right",
      formatter: (cellContent, row) => (
        <span>
          <NumberFormat 
            thousandSeparator={true} 
            decimalScale={2} 
            fixedDecimalScale
            value={row?.balance} 
            displayType='text' >
          </NumberFormat>
        </span>
      )
    },
  ];

  const rowEvents={
    onClick: async (e, row, rowIndex) => {
      await dispatch(actions.fetchClosedTransactionsInAccountByClosedDate(account_id, row.closed_date, row));
      onHide();
    }
  }

  return (
    <BootstrapTable
      bordered={false}
      wrapperClasses="closed-transactions-table table-responsive scrollable"
      classes="table table-head-custom table-vertical-center overflow-scroll"
      bootstrap4
      keyField="closed_date"
      data={tableData === null ? [] : tableData}
      columns={columns}
      rowClasses="text-hover-primary cursor-pointer"
      headerClasses="position-sticky top-0 bg-white"
      rowEvents={rowEvents}
      noDataIndication={() => <div>No records found</div>}
    />
  );
}
