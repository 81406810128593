import React, { useEffect,useMemo } from 'react'
import { useDispatch,useSelector,shallowEqual } from 'react-redux'
import * as actions from '../../_redux_admin/clients_adm/clientsActions';
import { useHistory } from 'react-router-dom';
import SVG from "react-inlinesvg";
import moment from 'moment';
import { useTenantsUIContext } from '../admin-pages/tenants/TenantsUIContext';
import { TenantEditDialog } from '../admin-pages/tenants/tenant-edit-dialog/TenantEditDialog';
import {TenantDeleteDialog} from '../admin-pages/tenants/tenant-delete-dialog/TenantDeleteDialog';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
export const AdminSubscription = (param) => {

    const tenantsUIContext = useTenantsUIContext();
    const tenantsUIProps = useMemo(() => {
      return {
        showEdit:tenantsUIContext.showEdit,
        showDelete:tenantsUIContext.showDelete,
        tenantId:tenantsUIContext.tenantId,
        subId:tenantsUIContext.subId,
        openEdit:tenantsUIContext.openEdit,
        hideEdit:tenantsUIContext.hideEdit,
        hideDelete:tenantsUIContext.hideDelete,
      };
    }, [tenantsUIContext]);
    const history = useHistory();
    const {subTypeId} = param;
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(actions.fetchSubscriptions())
    },[])
    const {currentState} = useSelector((state)=>({
		currentState:state
	}),shallowEqual)
    const {subscriptionsEntities} = currentState.tenants

  return (
    <>
    <TenantEditDialog
            show={tenantsUIProps.showEdit}
            id={tenantsUIProps.tenantId}
            onHide={() => {
              tenantsUIProps.hideEdit()
            }}
            fromDashboard = {true}
          />
    <TenantDeleteDialog
            show={tenantsUIProps.showDelete}
            id={tenantsUIProps.subId}
            onHide={() => {
              tenantsUIProps.hideDelete()
              tenantsUIProps.hideEdit()
            //   const url = `/dashboard`
            //   history.push(url);
            }}
            clientId={tenantsUIProps.tenantId}
            
          />
        {
            subscriptionsEntities && 
            <>
            <ul className="dashboard-tabs nav nav-pills nav-pills-sm nav-dark-75 position-sticky bg-white" style={{cursor:"pointer", top: 0}} >
              <div>
                <h3 className="card-title font-weight-bolder text-dark mt-7 " style={{paddingTop: "20px", fontWeight: "500", fontSize: "1.275rem"}}>{subTypeId} Subs Expiration</h3>
              </div>
            </ul>
            
            {subscriptionsEntities.filter((item)=>{
                return item.sub_type === subTypeId && item.expires <= 5
            }).map((item)=>(
                <div onClick={()=>tenantsUIProps.openEdit(item.client_id,item.id)} className={item.expires < 0 ? "d-flex align-items-center bg-light-danger rounded p-5 mb-5" : "d-flex align-items-center bg-light-warning rounded p-5 mb-5"}>
                    <span className="svg-icon svg-icon-danger mr-5">
                        <span className="svg-icon svg-icon-lg">
                           
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")} title=" " />
                            </span>
                        </span>
                    </span>
                <div className="d-flex flex-column flex-grow-1 mr-2">
                    <a className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">{item.client_name}</a>
                    <span className="text-muted "> {item.expires} Days </span></div>
                    <span className="font-weight-bolder text-danger py-1 font-size-sm text-right">{moment(item.valid_until).format("DD/MM/YYYY")}</span></div>
            ))
        }
        </>
        }
    </>
  )
}
