import { useState, useEffect,useLayoutEffect,useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
// import * as carActions from "../../../_redux/cars/carsActions";
import * as saleActions from "../../../_redux/sales/salesActions"
import { useSalesUIContext } from "./SalesUIContext";
import NumberFormat from 'react-number-format';


let initialFilter = {
  filter: {
      category: "",
      year: "",
      color: "",
      batch: "",
      location: "",
  }
};

const ChildTable = ({data}) => {
  const salesUIContext = useSalesUIContext();
  const salesUIProps = useMemo(() => {
    return {
      queryParams: salesUIContext.queryParams,
    };
  }, [salesUIContext]);
 
  const childTableColumns = [
    {
      dataField: 'id', 
      text: 'ID', 
      hidden: true, 
      classes: "border-0 py-3",
      headerClasses: "border-0 py-3"
    },
    {
      dataField: 'year',
      text: 'Year',
      classes: "border-0 py-3",
      hidden: salesUIProps.queryParams.hidden.year,
      headerClasses: "border-0 py-3"
    },
    {
      dataField: 'color',
      text: 'Color', 
      classes: "border-0 py-3",
      hidden: salesUIProps.queryParams.hidden.color,
      headerClasses: "border-0 py-3", 
      formatter: (cellContent, row) => (
        <span>{ row.color ? row.color : '(No color)'}</span>
      ),
    }, 
    // {
    //   dataField: 'batch',
    //   text: 'Batch', 
    //   classes: "border-0 py-3",
    //   headerClasses: "border-0 py-3", 
    //   formatter: (cellContent, row) => (
    //     <span>{ row.batch ? row.batch: '(No batch)'}</span>
    //   ),
    // },
    // {
    //   dataField: 'quantity',
    //   text: 'Quantity',
    //   classes: "text-right border-0",
    //   headerClasses: "text-right border-0",
    // }, 
    // {
    //   dataField: 'amount',
    //   text: 'Amount',
    //   classes: "text-right border-0",
    //   headerClasses: "text-right border-0",
    // }, 
    {
      dataField: 'collected',
      text: 'Collected',
      classes: "text-right border-0 py-3",
      headerClasses: "text-right border-0 py-3",
      formatter: (cellContent, row) => (
        <span><NumberFormat thousandSeparator={true} value={row?.collected} displayType='text' /></span>
      ),
    },
    {
      dataField: 'uncollected',
      text: 'Uncollected',
      classes: "text-right border-0 py-3",
      headerClasses: "text-right border-0 py-3",
      formatter: (cellContent, row) => (
        <span><NumberFormat thousandSeparator={true} value={row?.uncollected} displayType='text' /></span>
      ),
    },
    
  ];

  return data ? (
    <BootstrapTable 
        classes="report-child table table-head-custom table-vertical-center"
        headerClasses='nested'
        rowStyle={{ cursor: 'pointer' }}
        bordered={false}
        bootstrap4
        keyField='id'
        data={ data }
        columns={ childTableColumns }
    /> ) : ( <></>)
}

const SalesReportMobile = () => {
  const [tableData, setTableData] = useState([]);
  const [footerData, setFooterData] = useState([]);
  const salesUIContext = useSalesUIContext();
  const salesUIProps = useMemo(() => {
    return {
      queryParams: salesUIContext.queryParams,
    };
  }, [salesUIContext]);
  const columns = [
    {
      dataField: 'id', 
      text: 'ID', 
      hidden: true, 
      classes: "border-0",
      headerClasses: "border-0"
    },
    {
      dataField: 'category',
      text: 'Make/Brand', 
      classes: "border-0", 
      headerClasses: "border-0"
    }, 
    {
      dataField: 'quantity',
      text: 'Qty', 
      classes: "text-right border-0",
      headerClasses: "text-right border-0",
    }, 
    {
      dataField: 'amount',
      text: 'AMT', 
      classes: "border-0 text-right", 
      headerClasses: "border-0 text-right", 
      formatter: (cellContent, row) => (
        <span><NumberFormat thousandSeparator={true} value={row?.amount} displayType='text' /></span>
      ),
    },
  ];

  const footerColumns = [
    {
      dataField: 'id', 
      text: 'ID', 
      hidden: true, 
      classes: "border-0",
      headerClasses: "border-0"
    },
    {
      dataField: 'title',
      text: 'Total', 
      classes: "border-0", 
      headerClasses: "border-0 d-none"
    }, 
    {
      dataField: 'val',
      text: 'Val', 
      classes: "border-0 text-right", 
      headerClasses: "border-0 d-none", 
      formatter: (cellContent, row) => (
        <span><NumberFormat thousandSeparator={true} value={row?.val} displayType='text' ></NumberFormat></span>
      ),
  }]

  const { dashboardStockReport: entities, dashboardStockReportDetail: detailEntities ,error,apiErrorData, salesForReport} = useSelector(
      (state) => ({ 
        dashboardStockReport: state.cars.dashboardStockReport,
        dashboardStockReportDetail: state.cars.dashboardStockReportDetail,
        error:state.cars.error,
        apiErrorData:state.cars.apiErrorData, 
        salesForReport: state.sales.salesForReport
      }),
      shallowEqual
  );

  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }
    }
  },[error])

  const dispatch = useDispatch();
  
  useEffect(() => {
      // dispatch(carActions.fetchDashboardStockReport(initialFilter))
      // dispatch(carActions.fetchDashboardStockReportDetail({...initialFilter, filter: {...initialFilter.filter, category: "", year: "1", color: "1", amount:"1", batch: "1"}}))
      dispatch(saleActions.fetchSalesForReport(salesUIProps.queryParams))
      //dispatch(saleActions.fetchSalesDetailForReport(salesUIProps.queryParams))
  }, [dispatch]);
  
  useEffect(() => {
      // if(entities.length > 0 && detailEntities.length > 0) {
      if(salesForReport) {
          const salesList = []
          salesForReport.forEach(i => {
            salesForReport.forEach(j => {
              if(i.category === j.category) {
                i = {...i, expand: [...(i?.expand || []), {id: j.id, color: j.color, year: j.year, quantity: j.quantity, amount: j.amount, batch: j.batch, collected: j.collected, uncollected: j.uncollected}]}
              }
            })
            salesList.push(i)
          });
          setTableData(salesList)
          setFooterData([
            {id: 1, title: "Total Quantity", val: salesForReport.reduce((acc, s) => acc+s.quantity, 0)}, 
            {id: 2, title: "Total AMT", val: salesForReport.reduce((acc, s) => acc+s.amount, 0)}, 
            {id: 3, title: "Total Collected", val: salesForReport.reduce((acc, s) => acc+s.collected, 0)},
            {id: 4, title: "Total Uncollected", val: salesForReport.reduce((acc, s) => acc+s.uncollected, 0)}
          ])
      }
  }, [salesForReport])

  const [expanded, setExpanded] = useState([])

  const handleExpand = (row, isExpand, rowIndex, e) => {
    isExpand ? setExpanded([...expanded, row.id]) : setExpanded(expanded.filter(ex => ex !== row.id))
  }

  const getNonExpandables = (rows) => {
    const unExpandables = []
    rows.forEach((row, i) => {
      if(row?.expand?.length === 0 || row?.expand === undefined) {
        unExpandables.push(i)
      }
    })
    return unExpandables
  }

  const expandRow = {
    renderer: row => (
        <ChildTable data={row.expand} />
    ),
    expanded: expanded,
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => <></>,
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      return (    
          expandable && (
            expanded ? (
                <i className="fa fa-caret-down" style={{width: "16px", height: "16px", color: "#0BB783"}}></i>
              ) : (
                <i className="fa fa-caret-right" style={{width: "16px", height: "16px", color: "#0BB783"}}></i>
              )
          )
      )
    },
    expandByColumnOnly: true, 
    onExpand: handleExpand,
    nonExpandable: getNonExpandables(tableData)
  };

  const rowStyle = (row, rowIndex) => {
    const style = {cursor: 'pointer'};
    if (expanded.find(id => row.id === id)) {
      style.backgroundColor = '#F3F6F9';
    }
    return style;
  };

  return (
    <>
      <BootstrapTable
        wrapperClasses="report-table table-responsive"
        classes="table table-head-custom table-vertical-center"
        rowStyle = {rowStyle}
        bordered={false}
        bootstrap4
        keyField='id'
        data={ tableData }
        columns={ columns }
        expandRow={ expandRow }
      />
      <BootstrapTable
        wrapperClasses="table-responsive border-top font-weight-bolder"
        classes="table table-head-custom table-vertical-center"
        bordered={false}
        bootstrap4
        keyField='id'
        data={ footerData }
        columns={ footerColumns }
      />
    </>
  )
}

export default SalesReportMobile

