/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal, Nav  } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

export function ProformaEditDialogHeader({ id,onhide }) {
  const [title, setTitle] = useState("");
  // Specs Redux state
  const { specificationForEdit, actionsLoading } = useSelector(
    (state) => ({
      specificationForEdit: state.proformas.specificationForEdit,
      actionsLoading: state.proformas.actionsLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    let _title = id ? "Edit Proforma" : "New Proforma";
    // if (specificationForEdit && id) {
    //   _title = "Edit Proforma";
    // }

    setTitle(_title);
    // eslint-disable-next-line
  }, [specificationForEdit, actionsLoading]);
  return (
    <>
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        <Nav className="ml-auto" onClick={onhide}>
          <Nav.Link className="p-0 text-dark">
            <span className="nav-icon">
              <span className="svg-icon svg-icon-2x">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                        <rect x="0" y="7" width="16" height="2" rx="1"/>
                        <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </span>
          </Nav.Link>
        </Nav>
      </Modal.Header>
    </>
  );
}
