import {createSlice} from "@reduxjs/toolkit";

const initialaccountsState = {
  accountListLoading: false,
  accountLoading: false,
  accountTotalCount: 0,
  accountEntities: null,
  accountForEdit: undefined,
  transactionListLoading: false,
  transactionLoading: false,
  transactionTotalCount: 0,
  transactionEntities: null, 
  closedTransactionEntities: null,
  closedTransactionEntities_grouped: null,
  closedTransactionForEdit: undefined,
  transactionForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const options = {
  account: "account",
  transaction: "transaction"
}

export const accountsSlice = createSlice({
  name: "accounts",
  initialState: initialaccountsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.accountListLoading = false;
      } else {
        state.accountLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        if(action.payload.option !== options.transaction) state.accountListLoading = true;
        else state.transactionListLoading = true;
      } else {
        if(action.payload.option !== options.transaction) state.accountLoading = true;
        else state.transactionLoading = true;
      }
    },
    // find accounts
    accountsFetched: (state, action) => {
      const { accountTotalCount, accountEntities } = action.payload;
      state.accountListLoading = false;
      state.error = null;
      state.accountEntities = accountEntities;
      state.accountTotalCount = accountTotalCount;
      // state.accountForEdit = undefined;
      // state.transactionEntities = null;
      // state.transactionTotalCount = 0;
      // state.transactionForEdit = undefined;
    },
    // create account
    accountCreated: (state, action) => {
      state.accountLoading = false;
      state.error = null;
      state.accountEntities.push(action.payload.account);
    },
    // update account
    accountUpdated: (state, action) => {
      state.accountLoading = false;
      state.error = null;
      state.accountEntities = state.accountEntities.map(entity => {
        if (entity.id === action.payload.account.id) {
          return action.payload.account;
        }
        return entity;
      });
    },
    // delete account
    accountDeleted: (state, action) => {
      state.error = null;
      state.accountLoading = false;
      state.accountEntities = state.accountEntities.filter(el => el.id !== action.payload.id);
    },
    // find account
    accountFetched: (state, action) => {
      const { accountForEdit } = action.payload;
      state.accountLoading = false;
      state.error = null;
      state.accountForEdit = accountForEdit;
    },
    // find transactions in account
    transactionsInAccountFetched: (state, action) => {
      const { transactionTotalCount, transactionEntities } = action.payload;
      state.transactionListLoading = false;
      state.error = null;
      state.transactionEntities = transactionEntities;
      state.transactionTotalCount = transactionTotalCount;
    },
    // create transaction
    transactionCreated: (state, action) => {
      state.transactionLoading = false;
      state.error = null;
      state.transactionEntities.push(action.payload.transaction);
    },
    // updae transaction
    transactionUpdated: (state, action) => {
      state.transactionLoading = false;
      state.error = null;
      state.transactionEntities = state.transactionEntities.map(entity => {
        if (entity.id === action.payload.transaction.id) {
          return action.payload.transaction;
        }
        return entity;
      });
    },
    // find transaction
    transactionFetched: (state, action) => {
      const { transactionForEdit } = action.payload;
      state.transactionLoading = false;
      state.error = null;
      state.transactionForEdit = transactionForEdit;
    },
    // delete transaction
    transactionDeleted: (state, action) => {
      state.error = null;
      state.transactionLoading = false;
      state.transactionEntities = state.transactionEntities.filter(el => el.id !== action.payload.id);
    },
    // close transactions
    transactionsClosed: (state, action) => {
      state.error = null;
      state.accountLoading = false;
      state.transactionEntities = null;
    },
    // find closed transaction groups in account
    closedTransactionGroupsInAccountFetched: (state, action) => {
      state.transactionListLoading = false;
      state.error = null;
      state.closedTransactionEntities_grouped = action.payload.closedTransactionEntities_grouped
    },
    // find closed transaction group in account
    closedTransactionGroupInAccountFetched: (state, action) => {
      state.closedTransactionForEdit = action.payload.closedTransactionForEdit
    },
    // find closed transactions in account by closed date
    closedTransactionsInAccountByClosedDateFetched: (state, action) => {
      state.transactionListLoading = false;
      state.error = null;
      state.closedTransactionEntities = action.payload.closedTransactionEntities;
      state.transactionTotalCount = action.payload.transactionTotalCount;
    },
    // clear closed transactions
    closedTransactionsCleared: (state, action) => {
      state.closedTransactionEntities = null;
      state.closedTransactionEntities_grouped = null;
      state.closedTransactionForEdit = undefined;
    },
    // clear closed transactions
    transactionsCleared: (state, action) => {
      state.closedTransactionEntities = null;
      state.closedTransactionEntities_grouped = null;
      state.closedTransactionForEdit = undefined;
      state.transactionEntities = null;
      state.transactionTotalCount = 0;
      state.accountForEdit = null;
    }
  }
});
