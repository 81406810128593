import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from "formik";
import { NavLink, useLocation } from "react-router-dom";
import * as Yup from "yup";
import axios from 'axios';
import { Input, TextArea } from "../../../_metronic/_partials/controls";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector,shallowEqual } from "react-redux";

const ContactPage = () => {
  const {loggedUser} = useSelector((state) => ({ loggedUser: state.users.loggedUser}), shallowEqual);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const validation = Yup.object().shape({
    senderAddress: Yup.string().email('Invalid email format').trim().required("Email is required"),
    senderName: Yup.string().trim().required("Name is required"), 
    message: Yup.string().trim().required('Message cannot be empty')
  });

  const [loading,setLoading] = useState(true);
  async function sendEmail (values) {
    try {
      const {message, senderName, senderAddress,subject} = values
      if(!message || !senderAddress) throw new Error("Enquiries Failed")
          
      const response = await axios.post(`${process.env.REACT_APP_EMAIL_SENDER_API}send_contact_message`, {
        senderName: senderName,
        senderAddress: senderAddress,
        subject:subject,
        message: message
      })

      if(response.status === 200){
        toast.success("Email send successfully!");
        setTimeout(() => {
          window.history.go(-1);
        }, "1000")
      } else{
        toast.error("Sending failed!");
      }     
    } 
    catch(error) {
      setLoading(false);
      toast.error("Sending failed due to network error!");
      const url = `${process.env.REACT_APP_AUTO_DEALER_API}logger`
      const data ={
          username:localStorage.currentUserEmail,
          currentUrl:window.location.href,
          error:error?.stack,
          errorInfo:error?.message || error?.response?.data?.message || error
      }
      axios.post(url,
          data
      ).catch(function () {
          console.log('CAN NOT LOG ERROR')
      });
    }
  };

  return (
    
    <>
    <ToastContainer autoClose={1000}/>
    {!loggedUser && (
      <div className="header header-fixed headerLoggedOut">	
        <div className="header-top d-lg-flex fixed-top">
          <div className="container">
            <div className="d-none d-lg-flex align-items-center">
              <NavLink exact to="/" className="mr-20">
                <img alt="Logo" src="/media/logos/logo-letter-9.png" className="max-h-35px" />
              </NavLink>
            </div>
            <div className="topbar d-flex justify-content-between" style={{marginBottom: '3rem'}}>
              <div className="topbar-item d-block d-lg-none">
                <div className="btn btn-icon btn-hover-transparent-white w-auto d-flex align-items-center btn-lg px-2">
                  <div className="d-flex flex-column text-right pr-3">
                    <NavLink exact to="/" className="mr-20">
                      <img alt="Logo" src="/media/logos/logo-letter-9.png" className="max-h-20px" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
      <div className="d-flex flex-column-fluid">
        <div className="container">      
          <div className="card card-custom gutter-b">
            <div className="card-header">
              <div className="card-title">
                <h5 className="card-label">Send us your enquiries</h5>
              </div>
            </div>
            <Formik
        enableReinitialize={true}
        initialValues={{senderName : "", senderAddress:"",subject:"",message:""}}
        validationSchema={validation}
        onSubmit={(values) => {
          setLoading(true);
          sendEmail(values)
        }}
      >
        {({ handleSubmit,isSubmitting}) => (
          <BlockUi blocking={(isSubmitting && loading)}>
            <Form className="form form-label-right">
              <div className="card-body">
                <div className="row">
                <div className="order-2 order-lg-1 col-lg-6">
                  <div className="form-group">
                    <Field
                      name="senderName"
                      component={Input}
                      placeholder="Enter Your Name"
                      label="Name"
                      withFeedbackLabel= {true}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="senderAddress"
                      component={Input}
                      placeholder="Enter Your Email"
                      label="Email"
                      withFeedbackLabel= {true}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="subject"
                      component={Input}
                      placeholder="Enter The Subject"
                      label="Subject"
                      withFeedbackLabel= {true}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="message"
                      component={TextArea}
                      label="Your Message"
                      height='100px'
                      col = 'col-md-6'
                      key="remark"
                    />
                  <span className="form-text text-muted">We'll never share your email with anyone else.</span>
                  </div>
                </div>
                <div className="order-1 order-lg-2 offset-lg-2 col-lg-4 offset-xl-3 col-xl-3 mb-5">
                  <div className="card bg-light">
                    <div className="card-body">
                      <h6>Call Support</h6>
                      <div className="d-none d-lg-block">
                        <span className='mt-1'>+251 116 662 057 /58 /59</span>
                      </div>
                      <div className="d-sm-block d-lg-none">
                        <div className='mt-5 mb-3'>
                        <a href="tel:+251116662057"><b>+251 116 662 057</b></a>
                        </div>
                        <div className='mb-3'>
                        <a href="tel:+251116662058"><b>+251 116 662 058</b></a>
                        </div>
                       <div>
                       <a href="tel:+251116662059"><b>+251 116 662 059</b></a>
                       </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </Form>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-6">
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate mr-1"
                  >
                    Send
                  </button>
                  <a className="btn btn-secondary"
                    onClick={() => {
                      window.history.go(-1);
                    }}
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          </BlockUi>
        )}
      </Formik>
          </div>   
        </div>
      </div>
    </div>
    </>
  )
}

export default ContactPage;
