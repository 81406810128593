import React from "react";
import { useSelector,shallowEqual } from "react-redux";
import { Switch } from "react-router-dom";
import { ReportsUIProvider } from "./ReportsUIContext";
import { StockPage } from "./reports-stock/StockPage"
import { SalesPage } from "./reports-sales/SalesPage"
import { LoanSalesPage } from "./reports-loan-sales/LoanSalesPage"
import { BankDepositPage } from "./reports-bank-deposit/BankDepositPage"
import { ContentRoute } from "../../../../_metronic/layout";

export function ReportsPage({ history }) {

  const {auth} = useSelector((state) => ({ auth: state.auth }),
    shallowEqual)
    
  const {roles} = auth;

  const reportsUIEvents = {
    openStockReportPage: () => {
      history.push("/reports/stock");
    },
    openSalesReportPage: () => {
      history.push("/reports/sales");
    }, 
    openLoanSalesReportPage: () => {
      history.push("/reports/uncollected_payments");
    }, 
    openBankDepositReportPage: () => {
      history.push("/reports/bank_deposit");
    }
  }

  return (
    <ReportsUIProvider reportsUIEvents={reportsUIEvents}>
      <Switch>
      {roles && roles.includes("StockReport") && 
        <ContentRoute path="/reports/stock" component={StockPage} />}
      {roles && roles.includes("SaleReport") && 
        <ContentRoute path="/reports/sales" component={SalesPage} />}
      {roles && roles.includes("UncollectedReport") && 
        <ContentRoute path="/reports/uncollected_payments" component={LoanSalesPage} />}
      {roles && roles.includes("BankReport") && 
        <ContentRoute path="/reports/bank_deposit" component={BankDepositPage} />}
      </Switch>
    </ReportsUIProvider>
  );
}
