import axios from "axios";

export const SPECIFICATIONS_URL = "api/clients";
export const URL = "http://localhost:8080/api/client";
export const CUSTOMERS_URL = process.env.REACT_APP_AUTO_DEALER_API+'client';


export function getAllClients(){
  return axios.get(CUSTOMERS_URL)
}
// CREATE =>  POST: add a new clients to the server
export function createClient(client) {
  return axios.post(CUSTOMERS_URL, { client });
}

// READ
// Server should return filtered clients by clientId
export function getAllProductClientsByClientId(clientId) {
  return axios.get(`${CUSTOMERS_URL}?clientId=${clientId}`);
}

export function getClientById(clientId) {
  return axios.get(`${CUSTOMERS_URL}/${clientId}`);
}

export function getClientByUserEmail(userEmail) {
  return axios.get(`${CUSTOMERS_URL}/findByUserEmail/${userEmail}`);
}
// Server should return sorted/filtered clients and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/clientsfind/{clientId}'. Should be 'api/clients/find/{clientId}'!!!
export function findClients(queryParams, clientId) {
  return axios.post(`${CUSTOMERS_URL}find/${clientId}`, { queryParams });
}

// UPDATE => PUT: update the client
export function updateClient(client) {
  return axios.put(`${CUSTOMERS_URL}/update_client`, 
    client
  );
}

// DELETE => delete the client
export function deleteClient(clientId) {
  return axios.delete(`${CUSTOMERS_URL}/${clientId}`);
}

// DELETE clients by ids
export function deleteClients(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteClients`, { ids });
}
