import {createSlice} from "@reduxjs/toolkit";

const initialTabsState = {
  stock_params: null, 
  sales_params: null, 
  loan_sales_params: null, 
  bank_deposit_params: null
};

export const callTypes = {
    action: "action"
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState: initialTabsState,
  reducers: {
    // set stock params
    setStockParams: (state, action) => {
      state.stock_params = action.payload.params
    },
    // set sales params
    setSalesParams: (state, action) => {
        state.sales_params = action.payload.params
    },
    // set loan sales params
    setLoanSalesParams: (state, action) => {
        state.loan_sales_params = action.payload.params
    },
    // set bank deposit params
    setBankDepositParams: (state, action) => {
        state.bank_deposit_params = action.payload.params
    },
  }
});
