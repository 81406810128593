import axios from "axios";

export const TENANTS_URL = process.env.REACT_APP_AUTO_DEALER_API+'client';


export function getAllTenants(){
  return axios.get(TENANTS_URL)
}
// CREATE =>  POST: add a new tenants to the server
export function createTenant(tenant) {
  return axios.post(TENANTS_URL,  tenant );
}

// READ
// Server should return filtered tenants by tenantId
export function getAllProductTenantsByTenantId(tenantId) {
  return axios.get(`${TENANTS_URL}?tenantId=${tenantId}`);
}

export function getTenantById(tenantId) {
  return axios.get(`${TENANTS_URL}/${tenantId}`);
}

export function getTenantByUserEmail(userEmail) {
  return axios.get(`${TENANTS_URL}/findByUserEmail/${userEmail}`);
}
// Server should return sorted/filtered tenants and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/tenantsfind/{tenantId}'. Should be 'api/tenants/find/{tenantId}'!!!
export function findTenants(queryParams, tenantId) {
  return axios.post(`${TENANTS_URL}find/${tenantId}`, { queryParams });
}

// UPDATE => PUT: update the tenant
export function updateTenant(tenant) {
  return axios.put(`${TENANTS_URL}`, 
    tenant
  );
}

// DELETE => delete the tenant
export function deleteTenant(tenantId) {
  return axios.delete(`${TENANTS_URL}/${tenantId}`);
}

// DELETE tenants by ids
export function activateDeactivateTenant(id) {
  return axios.post(`${TENANTS_URL}/activateDeactivate/${id}`);
}

export function fetchSubscriptions() {
  return axios.get(`${TENANTS_URL}/subscriptions` );
}

export function fetchSubscriptionsForReport(queryParams) {
  return axios.post(`${TENANTS_URL}/find/subscriptionsForReport`, { ...queryParams });
}