import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";

const ProfileUIContext = createContext();

export function useProfileUIContext() {
  return useContext(ProfileUIContext);
}

export const ProfileUIConsumer = ProfileUIContext.Consumer;

export function ProfileUIProvider({usersUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(null);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initUser = {
    id: undefined,
    fullName: "",
    status: 1,
    userName:"",
    password:"",
    locations:[],
    group:{}
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initUser,
    
  };

  return <ProfileUIContext.Provider value={value}>{children}</ProfileUIContext.Provider>;
}