import * as requestFromServer from "./locationsCrud";
import {locationsSlice, callTypes} from "./locationsSlice";

const {actions} = locationsSlice;

export const fetchLocations = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  
  return requestFromServer
    .getAllLocations()
    .then(response => {
      const locationEntities  = response.data;
      const locationTotalCount = response.data.length;
      dispatch(actions.locationsFetched({ locationTotalCount, locationEntities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find locations";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLocation = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.locationFetched({ locationForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLocationById(id)
    .then(response => {
      const location = response.data;
      dispatch(
        actions.locationFetched({ locationForEdit: location })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find location";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteLocation = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteLocation(id)
    .then(response => {
      dispatch(actions.locationDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete location";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createLocation = locationForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createLocation(locationForCreation)
    .then(response => {
      const { data:location } = response;
      dispatch(actions.locationCreated({ location }));
    })
    .catch(error => {
      error.clientMessage = "Can't create location";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateLocation = location => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateLocation(location)
    .then(() => {
      dispatch(actions.locationUpdated({ location }));
    })
    .catch(error => {
      error.clientMessage = "Can't update location";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteLocations = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteLocations(ids)
    .then(() => {
      dispatch(actions.locationsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete locations";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteValidation = (id,setValidation) => dispatch => {
  if (!id) {
    return false;
    
  }
  return requestFromServer
    .getDeleteValidationById(id)
    .then(response => {
      setValidation(response.data)
    })
    .catch(error => {
      error.clientMessage = "Can't request validation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
  };