import React, { useEffect, useMemo, useState } from "react";
import { Formik, Field } from "formik";
import { isEqual } from "lodash";
import { useBankDepositUIContext } from "../BankDepositUIContext";
import AsyncSearchBar from "../../../shared/AsyncSelectBar";
import { Input } from "./../../../../../../_metronic/_partials/controls";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { setBankDepositParams } from "../../../../_redux/reports/reportsActions";
import * as bankActions from "../../../../_redux/banks/banksActions";
import { DatePicker } from '@mantine/dates';

export const AccountTypes = [{id: 2, name: 'All'}, {id: 0, name: 'Company Account'}, {id: 1, name: 'Non Company Account'}];

export function BankDepositFilter({ collapsed, setCollapsed, listLoading }) {
  // Bank deposit UI Context
  const bankDepositUIContext = useBankDepositUIContext();
  const bankDepositUIProps = useMemo(() => {
    return {
      queryParams: bankDepositUIContext.queryParams,
      setQueryParams: bankDepositUIContext.setQueryParams,
    };
  }, [bankDepositUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(bankDepositUIProps.queryParams, values);
    if (!isEqual(newQueryParams, bankDepositUIProps.queryParams)) {
      bankDepositUIProps.setQueryParams(newQueryParams);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
		dispatch(bankActions.fetchBanks())
  }, [dispatch]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state }),
    shallowEqual
  );
  let { bankDeposits: entities } = currentState.sales;
  const { bankEntities } = currentState.banks
  const { supplierEntities } = currentState.suppliers;

  const [initDate, setInitDate] = useState()

  useEffect(() => {
    var date = new Date();
    setInitDate([
      new Date(date.getFullYear(), date.getMonth(), 1),
      new Date(), 
    ])
    
  }, [entities])

  // useEffect(() => {
  //   if (initDate) {
  //     initialFilter.filter.start_date = moment(initDate[0], "DD/MM/YYYY")
  //     initialFilter.filter.end_date = moment(initDate[1], "DD/MM/YYYY")
  //   }
  // }, [initDate])

  useEffect(() => {
    entities.length > 0 && (
      setInitDate([
        bankDepositUIProps.queryParams.filter.start_date ? bankDepositUIProps.queryParams.filter.start_date : new Date(Math.min(...entities.map(e => new Date(e.date)))),
        bankDepositUIProps.queryParams.filter.end_date ? bankDepositUIProps.queryParams.filter.end_date :  new Date(Math.max(...entities.map(e => new Date(e.date)))) 
      ])
    )
  }, [entities])

  const [toggleSearch,setToggleSearch] = useState(window.screen.width > 500 ? false:false);

  const prepareFilter = (queryParams, values) => {
    const { bank_acc, start_date, end_date, supplier_id, invoice_no,accountType,minimize } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter.supplier = (!supplier_id || supplier_id == "-- Select --") ? "" : `${supplier_id}`;
    filter.bank_acc = (!bank_acc || bank_acc == "-- Select --") ? "" : `${bank_acc}`;
    filter.start_date = start_date ? moment(start_date).format("YYYY-MM-DD") : ""
    filter.end_date = end_date ? moment(end_date).format("YYYY-MM-DD") : ""
    filter.invoice_no = invoice_no;
    filter.accountType = accountType
    filter.minimize = minimize ? "1" : "0";
    dispatch(setBankDepositParams({...queryParams, filter}))
    newQueryParams.filter = filter;
    return newQueryParams;
  };

  return (
    <div className={`${collapsed ? `d-lg-none` : `d-block`} w-100 w-lg-275px w-xl-300px`}>
        {initDate && (
          <>
            <div className="d-flex justify-content-between card-header border-0 pb-0">
              <h4 className="card-title align-items-start flex-column">
                <span className="card-label text-dark">Filter</span>
              </h4>
              <div className={"d-lg-none"}>
                <div className="row px-5">
                  <a className="ml-auto" onClick={()=>{setToggleSearch(!toggleSearch)}}>
                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                    <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24"/>
                            <path d="M8.2928955,3.20710089 C7.90237121,2.8165766 7.90237121,2.18341162 8.2928955,1.79288733 C8.6834198,1.40236304 9.31658478,1.40236304 9.70710907,1.79288733 L15.7071091,7.79288733 C16.085688,8.17146626 16.0989336,8.7810527 15.7371564,9.17571874 L10.2371564,15.1757187 C9.86396402,15.5828377 9.23139665,15.6103407 8.82427766,15.2371482 C8.41715867,14.8639558 8.38965574,14.2313885 8.76284815,13.8242695 L13.6158645,8.53006986 L8.2928955,3.20710089 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 8.499997) scale(-1, -1) rotate(-90.000000) translate(-12.000003, -8.499997) "/>
                            <path d="M6.70710678,19.2071045 C6.31658249,19.5976288 5.68341751,19.5976288 5.29289322,19.2071045 C4.90236893,18.8165802 4.90236893,18.1834152 5.29289322,17.7928909 L11.2928932,11.7928909 C11.6714722,11.414312 12.2810586,11.4010664 12.6757246,11.7628436 L18.6757246,17.2628436 C19.0828436,17.636036 19.1103465,18.2686034 18.7371541,18.6757223 C18.3639617,19.0828413 17.7313944,19.1103443 17.3242754,18.7371519 L12.0300757,13.8841355 L6.70710678,19.2071045 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 15.499997) scale(-1, -1) rotate(-360.000000) translate(-12.000003, -15.499997) "/>
                        </g>
                    </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="card-body pt-0 pr-lg-0" style={{ display: !toggleSearch && window.screen.width <= 990 ? 'none' : '' }}>
              <Formik
                initialValues={{
                  ...bankDepositUIProps.queryParams.filter, 
                  'start_date' : moment(initDate[0], "DD/MM/YYYY")?._d,
                  'end_date' : moment(initDate[1], "DD/MM/YYYY")?._d
                }}
                onSubmit={(values) => {
                  applyFilter(values);
                }}
              >
                {({
                initialValues, 
                values,
                touched,
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
                setFieldTouched,
                resetForm
                }) => (
                  <form onSubmit={handleSubmit} className="form form-label-right">
                    <div className={`${toggleSearch ? `` : `d-none`} d-lg-block`}>
                      <div className="form-group mb-4">
                        <div className="d-flex flex-column">
                          {/* <DatePickerField 
                            name="start_date" 
                            label="Start Date"
                            //maxDate={new Date()} 
                            dateFormat="dd/MM/yyyy" 
                            customFeedbackLabel=" "
                            className="form-control"
                            autoComplete="off"
                          /> */}


                                    <DatePicker 
                                      
																		  name="start_date" 
                                      label="Start Date"
																		//  maxDate={new Date()} 
																		 /*disableClose={false}*/
																		 //closeCalendarOnChange={false}
																		 inputFormat="DD/MM/YYYY"
																     	// icon={<Calendar size={25} /> }
                                    zIndex={10000}
																		// className="form-control"
                                      autoComplete="off"
                                      onChange={(val) => {
                                        setFieldValue("start_date", val);
                                        }}
                                        value={values.start_date}
																		  />
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <div className="d-flex flex-column">
                          {/* <DatePickerField 
                            name="end_date" 
                            label="End Date"
                            // minDate={values.start_date}
                            // maxDate={new Date()} 
                            dateFormat="dd/MM/yyyy" 
                            customFeedbackLabel=" "
                            className="form-control"
                            autoComplete="off"
                          /> */}

                                    <DatePicker 
																		  name="end_date" 
                                      label="End Date"
																		//  maxDate={new Date()} 
																		 /*disableClose={false}*/
																		 //closeCalendarOnChange={false}
																		 inputFormat="DD/MM/YYYY"
																     	// icon={<Calendar size={25} /> }
                                    zIndex={10000}
																		// className="form-control"
                                      autoComplete="off"
                                      onChange={(val) => {
                                        setFieldValue("end_date", val);
                                        }}
                                        value={values.end_date}
																		  />
                        </div>
                      </div>
                      <div className="form-group mb-4">
                      <label>Supplier</label> 
                      <AsyncSearchBar 
                        selectedItem={{id: values.supplier_id}} 
                        setSelectedItem={setFieldValue}
                        setTouchedItem={setFieldTouched}
                        defaultItem={supplierEntities?.find(s => bankDepositUIProps.queryParams.filter.supplier_id == s.id)?.name}
                        touched={touched.supplier_id}
                        item_id="supplier_id"
                        item_name="name"
                        url={`${process.env.REACT_APP_AUTO_DEALER_API}suppliers/search`}
                      />
                    </div>
                      <div className="form-group mb-4">
                        <label>Bank Account</label>
                        <AsyncSearchBar 
                          selectedItem={{id: values.bank_acc}} 
                          setSelectedItem={setFieldValue}
                          setTouchedItem={setFieldTouched}
                          defaultItem={bankEntities?.find(b => bankDepositUIProps.queryParams.filter.bank_acc == b.id)?.name}
                          item_id="bank_acc"
                          item_name="bankName"
                          url={`${process.env.REACT_APP_AUTO_DEALER_API}proforma/searchBanks`}
                        />
                        {/* <div className="feedback text-muted">Bank name - Account number</div> */}
                      </div>
                      <div className="form-group mb-4">
                        <Field
                          name="invoice_no"
                          component={Input}
                          label="Order number"
                          className="form-control"
                          maxLength={5}
                        />
                      </div>
                      <div className="form-group mb-6">
                        <label htmlFor="accountType"> Account Type</label>
                        
                        <AsyncSearchBar
                          selectedItem={{id: values.accountType, name: AccountTypes.find(a => a.id == values.accountType)?.name}} 
                          setSelectedItem={setFieldValue}
                          setTouchedItem={setFieldTouched}
                          defaultItem={AccountTypes.find(a => a.id === initialValues.accountType)?.name}
                          item_id="accountType"
                          item_name="name"
                          options={AccountTypes}
                          isSearchable={false}
                          noSelectOption={true}
                      />
                      </div>
                      <div className="form-group mb-4">
                      <div className="d-flex">
                        <span className="mr-5">
                          <Field 
                            type="checkbox" 
                            name="minimize" 
                            onChange={(e) => {
                              setFieldValue("minimize", e.target.checked ? '1' : '');
                            }}
                          />
                          <label className="ml-1">Show Detail</label>
                        </span>
                        
                      </div>
                    </div>
                      <div className="d-flex justify-content-between">
                        <button 
                          type="submit" 
                          className="btn btn-primary px-8" 
                          onClick={()=>{
                            handleSubmit();
                            setCollapsed(false)
                            setToggleSearch(false);
                          }}>
                            Apply
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </>
        )}		
    </div>
  );
}
