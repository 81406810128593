import React, { useEffect, useMemo, useState, useRef, forwardRef,useLayoutEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../_redux/sales/salesActions";
import * as clientActions from '../../../../../_redux/client/clientsActions';
import * as batchActions from "../../../../../_redux/batch/batchActions"
import { useSalesUIContext } from "../../SalesUIContext";
import SalesDetailAGGrid from "./Ag_grid_for_sales_detail";
import TableDataRowFormatter from "./TableDataRowFormatter";
import $ from 'jquery';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import { Loader} from 'react-loaders';
import * as ColumnFmt from './columnFormatter/index'
const SalesDetailTable = forwardRef((props, ref)  => {
  const { ref1, ref2 } = ref;
  const exportInsideCSV = useRef();
  const exportInsidePrint = useRef();
  const salesUIContext = useSalesUIContext();
  const salesUIProps = useMemo(() => {
    return {
      ids: salesUIContext.ids,
      setIds: salesUIContext.setIds,
      queryParams: salesUIContext.queryParams,
      setQueryParams: salesUIContext.setQueryParams,
    };
  }, [salesUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state }),
    shallowEqual
  );
  let { salesDetailForReport: entities,error,apiErrorData,saleListLoading } = currentState.sales;
  const {clientEntities} = currentState.client;
  const {batchEntities} = currentState.batch;

  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }
    }
  },[error])

  const [tableData,setTableData] = useState(null)

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchSalesDetailForReport(salesUIProps.queryParams))
    dispatch(clientActions.fetchClientbyUserEmail(localStorage.currentUserEmail.substring(localStorage.currentUserEmail.indexOf('@')+1)))
    dispatch(batchActions.fetchBatch())
  }, [dispatch,salesUIProps.queryParams]);
  
  useEffect(()=>{
    entities = entities.map(e => ({...e, 
      amount: e.amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }), 
      price: e.price.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }), 
      collected: e.collected.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      uncollected: e.uncollected.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      issuedDay: e.sale_status === 'Active' && e.issuedDay ? e.issuedDay : "",
      // issuedDay: e.issuedDay
      // issuedDay: `${(e.issuedDay && e.sale_status === 'Active') ? e.issuedDay : ''} ${(e.overDueDay && e.sale_status === 'Active') ? '('+e.overDueDay+')' : ''}`
    }))
    //setTableData(cloneDeep(entities))
  },[entities])

  const columns = [
    {
      headerName: 'DATE', 
      field: 'detail.date',
      width: 150,
      cellClassRules: {
        'cell-underline': (() => true),
        'cell-notSpan': ({ data }) => data, 
        'cell-totalSpan': ({data}) => !data.detail.id
      }, 
      // cellRenderer: (params) => (
      //   <div>{params.data.detail.id ? moment(params.data.detail.date).format('DD/MM/YY') : 'GRANT TOT.'}</div>
      // ),
      minWidth: 130,
      maxWidth: 130,
      flex: 2,
    },
    {
      headerName: 'MODEL', 
      field: 'detail.model',
      cellClassRules: {
        'cell-notSpan': ({ data }) => data, 
        'cell-totalSpan': ({data}) => !data.detail.id
      }, 
      cellStyle: {'whiteSpace': 'normal'},
      cellRenderer: (params) => (
        <div>{params.data.detail.model} {params.data.detail.year}</div>
      ),
      minWidth: 170,
      maxWidth: 180,
      flex: 2,
    },
    {
      headerName: "VIN",
      field: 'detail.vin',
      cellStyle: {'whiteSpace': 'normal'},
      cellRenderer: (params) => (
        <div>{params.data.detail.vin.slice(-6)}</div>
      ),
      minWidth: 105,
      maxWidth: 115,
      flex: 2,
    },
    {
      headerName: 'COLOR', 
      field: 'detail.color', 
      hide: salesUIProps.queryParams.hidden.color,
      minWidth: 100,
      maxWidth: 105,
      flex: 2,
    },
    {
      headerName: 'BATCH', 
      field: 'detail.batch', 
      hide: salesUIProps.queryParams.hidden.batch,
      minWidth: 95,
      maxWidth: 100,
      flex: 2,
    },
    {
      headerName: 'CUSTOMER', 
      field: 'detail.customer', 
      cellRenderer: (params) => (
        <div>
          {params.data.detail.id && (
            params.data.detail.customer.split(" ")[0] + " " + (params.data.detail.customer.split(" ").length > 1 ? (params.data.detail.customer.split(" ")[1]?.charAt(0) + ".") : "")
          )}
        </div>
      ),
      minWidth: 130,
      maxWidth: 140,
      flex: 2,
    },
    {
      headerName: 'INVOICE AMT', 
      field: 'detail.amount', 
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      cellClassRules: {
        'cell-totalSpan': ({data}) => !data.detail.id
      }, 
      minWidth: 140,
      maxWidth: 150,
      flex: 2,
    },
    {
      headerName: 'PRICE', 
      field: 'detail.price', 
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      cellClassRules: {
        'cell-totalSpan': ({data}) => !data.detail.id
      }, 
      minWidth: 135,
      maxWidth: 145,
      flex: 2,
    },
    {
      headerName: 'STATUS', 
      field: 'detail.status', 
      cellClassRules: {
        'cell-totalSpan': ({data}) => !data.detail.id
      }, 
      minWidth: 120,
      maxWidth: 120,
      flex: 2,
    },
    {
      headerName: 'COLLECTED', 
      field: 'detail.collected', 
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      cellClassRules: {
        'cell-totalSpan': ({data}) => !data.detail.id
      }, 
      minWidth: 135,
      maxWidth: 145,
      flex: 2,
    },
    {
      headerName: 'UNCOLLECTED', 
      field: 'detail.uncollected', 
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header", 
      cellClassRules: {
        'cell-totalSpan': ({data}) => !data.detail.id
      }, 
      minWidth: 145,
      maxWidth: 155,
      flex: 2,
    },
    {
      headerName: 'LOCATION', 
      field: 'detail.location', 
      minWidth: 120,
      maxWidth: 130,
      flex: 2,
    },
    {
      headerName: 'SALES PERSON', 
      field: 'detail.employee', 
      cellRenderer: (params) => (
        <div>{params.data.detail.employee.split(" ")[0]}</div>
      ),
      minWidth: 150,
      maxWidth: 155,
      flex: 2,
    },
    {
      headerName: 'Num of days', 
      field: 'detail.issuedDay', 
      cellRenderer: ColumnFmt.NumOfDaysColumnFormatter,
      minWidth: 150,
      maxWidth: 155,
      flex: 2,
    },
  ]

  const [columnDefs, setColumnDefs] = useState(columns);

  useEffect(() => {
    setColumnDefs(columns)
  }, [salesUIProps.queryParams])

  const handlerCsv = () => {
    exportInsideCSV.current.click();
  };

  const handlerPrint = () => {
    $('#bankContainer').css('visibility','hidden');
    $('#blockConstiner').show()
    exportInsidePrint.current.click();
  };

  const componentRef = useRef(null);

  return (
    <>
      <div className="d-flex justify-content-end">
        <OverlayTrigger
          overlay={
            <Tooltip id="spec-edit-tooltip">Export to Excel</Tooltip>
          }
        >
          <input
            ref={ref1}
            hidden
            className="btn btn-success"
            onClick={handlerCsv}
          />
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip id="spec-edit-tooltip">Export to PDF</Tooltip>
          }
        >
          <input
          ref={ref2}
          hidden
          className="btn btn-success"
          onClick={handlerPrint}
        ></input>    
        </OverlayTrigger>
      </div>
      <BlockUi  blocking={saleListLoading} loader={<Loader active type={'ball-triangle-path'} color="#9d9dad" />} tag="div" message="" >
      <div className="report-content sales-detail-report" ref={componentRef} style={{height: '100%'}}>
        <div id="blockConstiner" style={{display:'none',height: '100%',width:'100%' }} ><BlockUi style={{height:'50px'}} blocking={true} loader={<Loader active type={'ball-triangle-path'} color="#9d9dad" />} tag="div" message="Loading" ></BlockUi></div>
        <div id="bankContainer">
          <div style={{ height: '100%',width:'100%' }}>
            {entities != null &&
            <SalesDetailAGGrid 
              ref={{ref1:exportInsideCSV,ref2:exportInsidePrint}} 
              tableData={TableDataRowFormatter(entities)} 
              columnDefs={columnDefs} 
              setColumnDefs={setColumnDefs}
              salesUIProps={salesUIProps}
              clientEntities={clientEntities}
              batchEntities={batchEntities}
              entities={entities}
            />
            }
          </div>
        </div>
      </div>
      </BlockUi>
    </>
  );
});
export default SalesDetailTable;
