import {tabsSlice} from "./tabsSlice";

const {actions} = tabsSlice;

export const selectTopTab = topTab => dispatch => {
    dispatch(actions.setTopTab({topTab: topTab}))
};

export const resetTopTab = () => dispatch => {
    dispatch(actions.resetTopTab())
};
