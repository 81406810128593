import * as requestFromServer from "./groupsCrud";
import {groupsSlice, callTypes} from "./groupsSlice";

const {actions} = groupsSlice;

export const fetchGroups = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  
  return requestFromServer
    .getAllGroups()
    .then(response => {
      const groupEntities  = response.data;
      const groupTotalCount = response.data.length;
      dispatch(actions.groupsFetched({ groupTotalCount, groupEntities }));
    })
    .catch(error => {
      error.groupMessage = "Can't find groups";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGroup = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.groupFetched({ groupForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGroupById(id)
    .then(response => {
      const group = response.data;
      dispatch(
        actions.groupFetched({ groupForEdit: group })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchRoles = id => dispatch => {
  

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllRoles(id)
    .then(response => {
      const roles = response.data;
      dispatch(
        actions.rolesFetched({ roles: roles })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find Roles";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGroup = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGroup(id)
    .then(response => {
      dispatch(actions.groupDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGroup = groupForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createGroup(groupForCreation)
    .then(response => {
      const { data:group } = response;
      dispatch(actions.groupCreated({ group }));
    })
    .catch(error => {
      error.clientMessage = "Can't create group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGroup = group => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGroup(group)
    .then(() => {
      dispatch(actions.groupUpdated({ group }));
    })
    .catch(error => {
      error.clientMessage = "Can't update group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGroups = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGroups(ids)
    .then(() => {
      dispatch(actions.groupsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete groups";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteValidation = (id,setValidation) => dispatch => {
  if (!id) {
    return false;
    
  }
  return requestFromServer
    .getDeleteValidationById(id)
    .then(response => {
      setValidation(response.data)
    })
    .catch(error => {
      error.clientMessage = "Can't request validation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
  };

