import React, { useCallback, useMemo, useRef, useState ,useEffect,forwardRef} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { useReactToPrint } from "react-to-print";
import moment from 'moment'
import $ from 'jquery';

const SubscriptionsAGGrid = forwardRef((props, ref)  => {
  const gridRef = useRef();
  const {ref1,ref2} = ref;
  const {tableData,columnDefs} = props;
  let length = 0;
  const containerStyle = useMemo(() => ({height: (window.screen.height * 49/100) }), []);
  const gridStyle = useMemo(() => ({ height: '100%' }), []);
  const [rowData, setRowData] = useState(null);
 
  const defaultColDef = useMemo(() => {
    return {
      //wrapText: true,
      //autoHeight: true,
      resizable: true,
      //flex: 1,

    };
  }, []);
  useEffect(()=>{
    setRowData(tableData);
    // if(tableData!=undefined && tableData[0]?.showDetail==='0'){
    //   gridRef?.current?.columnApi?.setColumnsVisible(['detail.date', 'detail.bank_acc'], false);
    // }else{
    //   gridRef?.current?.columnApi?.setColumnsVisible(['detail.date', 'detail.bank_acc'], true);
    // }
    //autoSizeAll(true);
  },[tableData])

  // useEffect(()=>{
  //   setTimeout(function () {
  //   $('#autoSizeId').trigger("click")
  //   },10);
  // },[tableData])

  


  
  const onBtnExport = useCallback(() => {
    
    gridRef.current.api.exportDataAsCsv({
      processCellCallback: function(cell) {
        // Manipulate the value however you need.
        // if(cell.accumulatedRowIndex === 1){
        //   return cell.value===undefined ? null : cell.value?.id === -1 ? null : 
        //          cell.value?.id === 0 ? cell.value?.detailName+cell.value?.detailValue :
        //          cell.value?.id === "" ? cell.value?.name === "GRAND TOTAL" ? cell.value?.name: "":cell.value?.name+' - '+cell.value?.vin + ' - ' + cell.value?.year 
        // }
        return cell.value;
      },
      fileName: 'Subs List.csv'
    });
  }, []);

  const componentRef = useRef(null);
  const today = moment().format("DD-MM-YYYY")
  const handlePrint = useReactToPrint({
    documentTitle: `Bank Deposit Report _ ${today}`,
    content: () => {
      const contentToPrint = componentRef.current.cloneNode(true)
      contentToPrint.querySelectorAll('span.svg-icon').forEach(icon => icon.remove())
      return contentToPrint
    },
  });
  const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    const width = document.querySelector('#kt_content > div > div.flex-row-fluid.ml-lg-8 > div > div.card-body').offsetWidth;
    eGridDiv.style.height = '';
    eGridDiv.style.width = (width+600)+'px'
    api.setDomLayout('print');
  };
  
  const setNormal = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.height = '100%';
    eGridDiv.style.width = '100%';
    api.setDomLayout();
  };
  const onBtPrint = useCallback(() => {
    const api = gridRef.current.api;
    setPrinterFriendly(api);
    setTimeout(function () {
      handlePrint();
      setNormal(api);
      $('#SubContainer').removeAttr("style");
      $('#blockConstiner').hide();
    },3000);
  }, []);

  const autoSizeAll = (skipHeader) => {
    if(rowData!=undefined && rowData.length >0 ){
    const allColumnIds = [];
    gridRef?.current?.columnApi?.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef?.current?.columnApi?.autoSizeColumns(allColumnIds, skipHeader);
    }
  }
  function rowHeight (params) {
    //length =  params.data.car?.name.length ? params.data.car?.name.length:length;
    //length = params.data?.span === -1 ? 30:length;
    // assuming  characters per line, working how how many lines we need
    
    return 36;//18 * (Math.floor(length / 6) + 1);//henok yemertew
}

  return (
    
    <div style={containerStyle} ref={componentRef}>
      <div >
          <input
          ref={ref1}
          hidden
          className="btn btn-success"
          onClick={onBtnExport}
        ></input>
          <button ref ={ref2} hidden onClick={onBtPrint}>Print</button>
          <button id="autoSizeId" hidden onClick={() => autoSizeAll(true,rowData)}></button>
        </div>
        <div className="header mb-5">
                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="flex-grow-1 text-center">Subs List Report</h1>
                  <div className="d-flex justify-content-between">
               
                </div> 
                </div>  
                <div>
                  
                  </div>
                <div className="d-flex justify-content-between " style={{position:'fixed',bottom:0,width:'100%',color:'#8d8c8c'}}>                                     
                            <span className="col-md-6 font-size-h4" style={{color:'#8d8c8c',paddingLeft:'38%'}}>Powered by AutoDealer <br></br>
                            <span className="col-md-6 font-size-h5" style={{color:'#8d8c8c'}}>&nbsp;&nbsp;&nbsp; www.autodll.com </span></span>
                            </div>
              </div>

      <div id="myGrid" style={gridStyle}  className="ag-theme-material ">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          
          //onGridReady={onGridReady}
          //rowHeight={60}
          ref={gridRef}
          //domLayout="autoHeight"
         
          skipHeaderOnAutoSize={true}
          getRowHeight= {rowHeight}
        ></AgGridReact>
      </div>
    </div>
  );

});


export default SubscriptionsAGGrid;