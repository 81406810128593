import axios from "axios";

export const CUSTOMERS_URL = "api/customers";
export const URL = process.env.REACT_APP_AUTO_DEALER_API+"customers";
// CREATE =>  POST: add a new customer to the server
export function createCustomer(customer) {
  return axios.post(URL, customer );
}

// READ
export function getAllCustomers() {
  return axios.get(URL);
}

export function getCustomerById(customerId) {
  return axios.get(`${URL}/${customerId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomers(queryParams) {
  return axios.post(`${URL}/find`, { queryParams });
}

// UPDATE => PUT: update the customer on the server
export function updateCustomer(customer) {
  return axios.put(`${URL}/${customer.id}`,  customer );
}

// UPDATE Status
export function updateStatusForCustomers(ids, status) {
  return axios.post(`${URL}/updateStatusForCustomers`, {
    ids,
    status
  });
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
  return axios.delete(`${URL}/${customerId}`);
}

// DELETE Customers by ids
export function deleteCustomers(ids) {
  return axios.post(`${URL}/deleteCustomers`, { ids });
}

export function getDeleteValidationById(id){
  return axios.get(`${URL}/${id}/delete_validate`);
}
