import * as requestFromServer from "./employeesCrud";
import {employeesSlice, callTypes} from "./employeesSlice";

const {actions} = employeesSlice;



export const getAllEmployees = () => dispatch => {
  if(localStorage.group != 'ADMIN'){
  return requestFromServer
    .getAllEmployees()
    .then(response => {
      const employeeEntities  = response.data;
      const employeeTotalCount = response.data.length;
      dispatch(actions.employeesFetched({ employeeTotalCount, employeeEntities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find employees";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}}

export const fetchEmployees = (queryParams, employeeId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  if (!employeeId) {
    return dispatch(
      actions.employeesFetched({ totalCount: 0, entities: null })
    );
  }

  return requestFromServer
    .findEmployees(queryParams, employeeId)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.employeesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find employees";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchEmployee = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.employeeFetched({ employeeForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getEmployeeById(id)
    .then(response => {
      const employee = response.data;
      dispatch(
        actions.employeeFetched({ employeeForEdit: employee })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find employee";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteEmployee = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteEmployee(id)
    .then(response => {
      dispatch(actions.employeeDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete employee";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createEmployee = employeeForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createEmployee(employeeForCreation)
    .then(response => {
      const { data:employee } = response;
      dispatch(actions.employeeCreated({ employee }));
    })
    .catch(error => {
      error.clientMessage = "Can't create employee";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createEmployeeInCar = employeeForCreation => dispatch =>{
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.employeeInCarCreated({employee: employeeForCreation}));

};

export const updateEmployeeInCar = employeeForUpdate => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.employeeInCarUpdated({employee: employeeForUpdate}))
}


export const updateBulkEmployee = employees => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBulkEmployee(employees)
    .then(() => {
      dispatch(actions.employeeBulkUpdated({ employees }));
    })
    .catch(error => {
      error.clientMessage = "Can't update employee";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchEmployeesInCar = id => dispatch => {
  if(!id){
    return dispatch(actions.employeesInCarFetched({employees: undefined}))
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
  .findEmployeesInCar(id)
  .then(response => {
    const entities  = response.data;
    const totalCount = response.data.length;
    dispatch(actions.employeesInCarFetched({ employees: entities, employeesTotalCount: totalCount }));
  })
  .catch(error => {
    error.clientMessage = "Can't delete car";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  });
}

export const updateEmployee = employee => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateEmployee(employee)
    .then(() => {
      dispatch(actions.employeeUpdated({ employee }));
    })
    .catch(error => {
      error.clientMessage = "Can't update employee";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteEmployees = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteEmployees(ids)
    .then(() => {
      dispatch(actions.employeesDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete employees";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
    
};

export const deleteValidation = (id,setValidation) => dispatch => {
  if (!id) {
    return false;
    
  }
  return requestFromServer
    .getDeleteValidationById(id)
    .then(response => {
      setValidation(response.data)
    })
    .catch(error => {
      error.clientMessage = "Can't request validation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
  };
