import { useState, useEffect,useLayoutEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import * as carActions from "../../../_redux/cars/carsActions";
import { useStockUIContext } from "./StockUIContext";
import NumberFormat from 'react-number-format';

let initialFilter = {
  filter: {
      category: "",
      year: "",
      color: "",
      batch: "",
      location: "",
  }
};

const ChildTable = ({data}) => {
  const stockUIContext = useStockUIContext();
  const stockUIProps = useMemo(() => {
    return {
      queryParams: stockUIContext.queryParams,
    };
  }, [stockUIContext]);

  const childTableColumns = [
    {
      dataField: 'id', 
      text: 'ID', 
      hidden: true, 
      classes: "border-0",
      headerClasses: "border-0"
    },
    {
      dataField: 'year',
      text: 'Year',
      // hidden: stockUIProps.queryParams.hidden.year,
      classes: "border-0",
      headerClasses: "border-0 d-none"
    },
    {
      dataField: 'color',
      text: 'Color', 
      hidden: stockUIProps.queryParams.hidden.color,
      classes: "border-0",
      headerClasses: "border-0", 
      formatter: (cellContent, row) => (
        <span>{ row.color ? row.color : '(No color)'}</span>
      ),
    }, 
    {
      dataField: 'quantity',
      text: 'QTY', 
      classes: "text-left border-0",
      headerClasses: "text-left border-0 d-none",
    }, 
    {
      dataField: 'value', 
      text: 'Value', 
      classes: "border-0",
      headerClasses: "border-0 d-none", 
      formatter: (cellContent, row) => (
        <span><NumberFormat thousandSeparator={true} value={row?.value} displayType='text' ></NumberFormat></span>
      ),
    },
    // {
    //   dataField: 'batch',
    //   text: 'Batch', 
    //   hidden: stockUIProps.queryParams.hidden.batch,
    //   classes: "border-0",
    //   headerClasses: "border-0 d-none", 
    //   formatter: (cellContent, row) => (
    //     <span>{ row.batch ? row.batch: '(No batch)'}</span>
    //   ),
    // },
    // {
    //   dataField: 'location',
    //   text: 'Location', 
    //   classes: "text-right border-0",
    //   headerClasses: "text-right border-0 d-none",
    //   formatter: (cellContent, row) => (
    //     <span>{ row.location ? row.location: '(No Location)'}</span>
    //   ),
    // },    
  ];

  return data ? (
    <BootstrapTable 
        classes="table table-head-custom table-vertical-center mt-3"
        headerClasses='nested'
        rowStyle={{ cursor: 'pointer' }}
        bordered={false}
        bootstrap4
        keyField='id'
        data={ data }
        columns={ childTableColumns }
    /> ) : ( <></>)
}

const StockReportMobile = () => {
  const stockUIContext = useStockUIContext();
  const stockUIProps = useMemo(() => {
    return {
      queryParams: stockUIContext.queryParams,
    };
  }, [stockUIContext]);

  const [tableData, setTableData] = useState([]);
  const [footerData, setFooterData] = useState([]);

  const columns = [
    {
      dataField: 'id', 
      text: 'ID', 
      hidden: true, 
      classes: "border-0",
      headerClasses: "border-0"
    },
    {
      dataField: 'category',
      text: 'Make/Brand', 
      classes: "border-0", 
      headerClasses: "border-0"
    }, 
    {
      dataField: 'quantity',
      text: 'QTY', 
      classes: "text-right border-0",
      headerClasses: "text-right border-0",
    }, 
    {
      dataField: 'value', 
      text: 'Value', 
      classes: "border-0 text-right",
      headerClasses: "border-0 text-right", 
      formatter: (cellContent, row) => (
        <span><NumberFormat thousandSeparator={true} value={row?.value} displayType='text' ></NumberFormat></span>
      ),
    },
  ];

  const footerColumns = [
    {
      dataField: 'id', 
      text: 'ID', 
      hidden: true, 
      classes: "border-0",
      headerClasses: "border-0"
    },
    {
      dataField: 'title',
      text: 'Total', 
      classes: "border-0", 
      headerClasses: "border-0 d-none"
    }, 
    {
      dataField: 'val',
      text: 'Val', 
      classes: "border-0 text-right", 
      headerClasses: "border-0 d-none", 
      formatter: (cellContent, row) => (
        <span><NumberFormat thousandSeparator={true} value={row?.val} displayType='text' ></NumberFormat></span>
      ),
    }]

  const { dashboardStockReport: entities, dashboardStockReportDetail: detailEntities ,error,apiErrorData, stockMobile, stockMobile1} = useSelector(
      (state) => ({ 
        dashboardStockReport: state.cars.dashboardStockReport,
        dashboardStockReportDetail: state.cars.dashboardStockReportDetail,
        error:state.cars.error,
        apiErrorData:state.cars.apiErrorData, 
        stockMobile: state.cars.stockMobile,
        stockMobile1: state.cars.stockMobile1,
      }),
      shallowEqual
  );

  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }
    }
  },[error])

  const dispatch = useDispatch();

  const [child, setChild] = useState('')
  
  useEffect(() => {
      // dispatch(carActions.fetchDashboardStockReport(initialFilter))
      // dispatch(carActions.fetchDashboardStockReportDetail({...initialFilter, filter: {...initialFilter.filter, category: "", year: "1", color: "1", batch: "1", quantity: "1"}}))
      dispatch(carActions.fetchStockMobile(
        {...stockUIProps.queryParams, 
          filter: {...stockUIProps.queryParams.filter, group_batch: '', year: '', color: ''},
          hidden: {...stockUIProps.queryParams.hidden, year: false, color: false}, 
        }
      ))

      dispatch(carActions.fetchStockMobile(
        {...stockUIProps.queryParams, 
          filter: {...stockUIProps.queryParams.filter, group_batch: '', year: child==='year'?'1':'', color: child==='color'?'1':''},
          hidden: {...stockUIProps.queryParams.hidden, year: false, color: false}, 
          child: child
        }
      ))
  }, [stockUIProps.queryParams, child, dispatch]);
  
  useEffect(() => {
      if(stockMobile) {
          const stockList = []
          stockMobile.forEach(i => {
            stockMobile1.forEach(j => {
              if(i.category === j.category) {
                i = {...i, expand: [...(i?.expand || []), {id: j.id, color: j.color, year: j.year, quantity: j.quantity, batch: j.batch, location: j.location, value: j.value}]}
              }
            })
            stockList.push(i)
          });
          setFooterData([{id: 1, title: "Total Quantity", val: stockMobile.reduce((acc, s) => acc+s.quantity, 0)}, {id: 2, title: "Total Value", val: stockMobile.reduce((acc, s) => acc+s.value, 0)}])
          setTableData(stockList)
      }
  }, [stockMobile, stockMobile1])

  const [expanded, setExpanded] = useState([])

  const handleExpand = (row, isExpand, rowIndex, e) => {
    setChild('year')
    isExpand ? setExpanded([...expanded, row.id]) : setExpanded(expanded.filter(ex => ex !== row.id))
  }

  const getNonExpandables = (rows) => {
    const unExpandables = []
    rows.forEach((row, i) => {
      if(row?.expand?.length === 0 || row?.expand === undefined) {
        unExpandables.push(i)
      }
    })
    return unExpandables
  }

  const expandRow = {
    renderer: row => (
        <ChildTable data={row.expand} />
    ),
    expanded: expanded,
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => <></>,
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      return (    
          expandable && (
            expanded ? (
                <i className="fa fa-caret-down" style={{width: "16px", height: "16px", color: "#0BB783"}}></i>
              ) : (
                <i className="fa fa-caret-right" style={{width: "16px", height: "16px", color: "#0BB783"}}></i>
              )
          )
      )
    },
    expandByColumnOnly: true, 
    onExpand: handleExpand,
    // nonExpandable: getNonExpandables(tableData)
  };

  const rowStyle = (row, rowIndex) => {
    const style = {cursor: 'pointer'};
    if (expanded.find(id => row.id === id)) {
      style.backgroundColor = '#F3F6F9';
    }
    return style;
  };

  return (
    <>
      <BootstrapTable
        wrapperClasses="report-table table-responsive"
        classes="table table-head-custom table-vertical-center"
        rowStyle = {rowStyle}
        bordered={false}
        bootstrap4
        keyField='id'
        data={ tableData }
        columns={ columns }
        expandRow={ expandRow }
      />
      <BootstrapTable
        wrapperClasses="table-responsive border-top font-weight-bolder"
        classes="table table-head-custom table-vertical-center"
        // rowStyle = {(row, rowIndex) => ({backgroundColor: '#F3F6F9', borderBottom: "1px solid #fff", fontWeight: "bold"})}
        bordered={false}
        bootstrap4
        keyField='id'
        data={ footerData }
        columns={ footerColumns }
      />
    </>
  )
}

export default StockReportMobile
