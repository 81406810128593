import {createSlice} from "@reduxjs/toolkit";

const initialCarModelsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  carModelForEdit: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const carModelsSlice = createSlice({
  name: "carModels",
  initialState: initialCarModelsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    //image file loaded
    imageFileLoaded:(state, action) =>{
      state.actionsLoading = false;
      state.carModelForEdit.picture = action.payload.file;
      state.error = null;
    },

    // getCarModelById
    carModelFetched: (state, action) => {
      state.actionsLoading = false;
      state.carModelForEdit = action.payload.carModelForEdit;
      state.error = null;
    },
    // findCarModels
    carModelsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      // state.carModelForEdit = {};
      state.carModelForEdit = null;
    },
    // createCarModel
    carModelCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.carModel);
    },
    // updateCarModel
    carModelUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.carModel.id) {
          return action.payload.carModel;
        }
        return entity;
      });
    },
    // carModelIsReferenced
    carModelReferenced: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.carModelISReferenced = action.payload
    },
    // deleteCarModel
    carModelDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteCarModels
    carModelsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // carModelsUpdateState
    carModelsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
