import React, {
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import { isEqual, isFunction } from "lodash";
import {initialFilter} from "./AccountUIHelpers";

const AccountsUIContext = createContext();

export function useAccountsUIContext() {
  return useContext(AccountsUIContext);
}

export const AccountsUIConsumer = AccountsUIContext.Consumer;

export function AccountsUIProvider({ accountsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParams, 
    newAccountButtonClick: accountsUIEvents.newAccountButtonClick,
    editAccountButtonClick: accountsUIEvents.editAccountButtonClick,
    deleteAccountButtonClick: accountsUIEvents.deleteAccountButtonClick,
    newTransactionButtonClick: accountsUIEvents.newTransactionButtonClick,
    editTransactionButtonClick: accountsUIEvents.editTransactionButtonClick, 
    deleteTransactionButtonClick: accountsUIEvents.deleteTransactionButtonClick, 
    closeTransactonsButtonClick: accountsUIEvents.closeTransactionsButtonClick,
    showClosedTransactionsButtonClick: accountsUIEvents.showClosedTransactionsButtonClick,
  };

  return (
    <AccountsUIContext.Provider value={value}>
      {children}
    </AccountsUIContext.Provider>
  );
}
