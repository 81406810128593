import axios from "axios";

export const SPECIFICATIONS_URL = "api/proformas";
export const URL = process.env.REACT_APP_AUTO_DEALER_API+"proforma";
export const ProformaItem_URL = process.env.REACT_APP_AUTO_DEALER_API+"proforma_items";

export function getAllProformas(queryParams, user_name){
  return axios.post(URL, queryParams, { params: { user_name: user_name } })
}
// CREATE =>  POST: add a new proformas to the server
export function createProforma(proforma) {
  return axios.post(URL+`/${localStorage.currentUserEmail}`, proforma );
}

// READ
// Server should return filtered proformas by proformaId
export function getAllProductProformasByProformaId(proformaId) {
  return axios.get(`${URL}?proformaId=${proformaId}`);
}

export function getProformaById(proformaId) {
  return axios.get(`${URL}/${proformaId}`);
}

export function getNewProforma(){
  return axios.get(`${URL}/newProforma`)
}

export function getNewProformaByCarId(carId){
  return axios.get(`${URL}/${carId}/newProformaByCar`)
}

export function getProformaItemsById(proformaId){
  return axios.get(`${URL}/${proformaId}/proformaItem`);
}

export function getProformaItemsByCarId(carId){
  return axios.get(`${URL}/${carId}/proformaItemByCar`);
}

export function getDeleteValidationProformaById(proformaId){
  return axios.get(`${URL}/validation/${proformaId}`);
}
export function getDeleteValidationProformaItemById(proformaId){
  return axios.get(`${URL}/validation/proforma_item/${proformaId}`);
}
// Server should return sorted/filtered proformas and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/proformasfind/{proformaId}'. Should be 'api/proformas/find/{proformaId}'!!!
export function findProformas(queryParams, proformaId) {
  return axios.post(`${URL}find/${proformaId}`, { queryParams });
}

// UPDATE => PUT: update the proforma
export function updateProforma(proforma,id) {
  return axios.put(`${URL}/${id}/${localStorage.currentUserEmail}`, 
    proforma
  );
}

// DELETE => delete the proforma
export function deleteProforma(proformaId) {
  return axios.delete(`${URL}/${proformaId}`);
}

export function deleteSales(proformaId) {
  return axios.delete(`${URL}/sales/${proformaId}`);
}

// DELETE proformas by ids
export function deleteProformas(ids) {
  return axios.post(`${URL}/deleteProformas`, { ids });
}

// find proformas by carsids
export function findProformasInCar(id) {
  let url = process.env.REACT_APP_AUTO_DEALER_API+'product/' +id+"/proforma/"+localStorage.currentUserEmail
  
  return axios.get(url);
}
export function getBanks() {
  return axios.get(`${URL}/banks`);
}

export function findValidProformaItem(productId) {
  return axios.get(`${URL}/product/${productId}`)
}

export function changeStatus(proformaId, statusId) {
  return axios.put(`${URL}/${proformaId}/changeStatus/${statusId}`)
}

/* === Proforma Items === */
// CREATE
export function createProformaItem(proformaItem) {
  return axios.post(ProformaItem_URL, proformaItem );
}

// UPDATE
export function updateProformaItem(proformaItem) {
  return axios.put(`${ProformaItem_URL}`, proformaItem);
}
