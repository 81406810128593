import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./BanksUIHelpers";

const BanksUIContext = createContext();

export function useBanksUIContext() {
  return useContext(BanksUIContext);
}

export const BanksUIConsumer = BanksUIContext.Consumer;

export function BanksUIProvider({banksUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initBank = {
    id: undefined,
    name: "",
    nonCompany:false,
    account_no: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initBank,
    newBankButtonClick: banksUIEvents.newBankButtonClick,
    openEditBankDialog: banksUIEvents.openEditBankDialog,
    openDeleteBankDialog: banksUIEvents.openDeleteBankDialog,
    openFetchBanksDialog: banksUIEvents.openFetchBanksDialog,
  };

  return <BanksUIContext.Provider value={value}>{children}</BanksUIContext.Provider>;
}