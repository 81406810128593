import React from 'react';
import ReactToPrint from 'react-to-print';

import { ProformaPrint } from './ProformaPrint';

export default class PrintTrigger extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dueDateCal = this.dueDateCal.bind(this);
  }
  dueDateCal(profrormaDate,expireDays){
    let dueDate = new Date(profrormaDate);
    dueDate.setDate(dueDate.getDate()+parseInt(expireDays))
    let year = dueDate.getFullYear();
    let month = (dueDate.getMonth() + 1) < 10 ? '0'+(dueDate.getMonth() + 1) : (dueDate.getMonth() + 1);
    let day = (dueDate.getDate() < 10) ? '0'+ dueDate.getDate() :  dueDate.getDate()
    return  day + '/'+ month + '/'+ year  ;
  }
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
          //   return <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
          //   <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
          //   <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
          // </svg></a>;
          return <a className="navi-link"><span className="navi-icon">
          <i className="la la-print"></i>
        </span>
        <span className="navi-text">{this.props.header ? 'Print':'Print Without Header'}</span></a>
          }}
          content={() => this.componentRef}
        />
        <div style={{ display: "none" }}>
          <ProformaPrint 
            values = {this.props.values} 
            proformaItems ={this.props.proformaItems}
            bankName={this.props.bankName}
            proformaItemTotalAmount={this.props.proformaItemTotalAmount}
            dueDate={this.dueDateCal(this.props.values.proforma_date,this.props.values.expire)}
            header={this.props.header}
            client = {this.props.client}
            ref={el => (this.componentRef = el)} 
            printOptions={this.props.printOptions}
          />
        </div>
      </div>
    );
  }
}