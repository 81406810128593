import React, { useEffect, useMemo,useState } from "react";
import { Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../../_redux_admin/clients_adm/clientsActions";
import {useTenantsUIContext} from "../TenantsUIContext";

export function TenantDeleteDialog({ id, show, onHide,clientId,fromDashboard=false }) {
  // Tenants UI Context
  const tenantsUIContext = useTenantsUIContext();
  const tenantsUIProps = useMemo(() => {
    return {
      setIds: tenantsUIContext.setIds,
      queryParams: tenantsUIContext.queryParams
    };
  }, [tenantsUIContext]);
  const [validation,setValidation] = useState()
  // Tenants Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.tenants.tenantActionsLoading }),
    shallowEqual
  );

  // // if !id we should close modal
  // useEffect(() => {
  //   if (!id) {
  //     onHide(clientId);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id]);

  // useEffect(() => { 
  //   dispatch(actions.deleteValidation(id,setValidation));
  // }, [isLoading, dispatch,id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteTenant = () => {
    // server request for deleting tenant by id
    dispatch(actions.deleteTenant(id)).then(()=>{
      dispatch(actions.fetchSubscriptions())
      onHide(clientId)
    })
  };

  return (
    <Modal
      show={show}
      // onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Tenant Delete
        </Modal.Title>
        <Nav className="ml-auto" onClick={(e) => onHide(clientId)}>
        <Nav.Link className="p-0 text-dark">
          <span className="nav-icon">
            <span className="svg-icon svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                  </g>
                </g>
              </svg>
            </span>
          </span>
        </Nav.Link>
      </Nav>
      </Modal.Header>
      <Modal.Body>
      
        {!isLoading && (
          <span>Are you sure to permanently delete this subscription?</span>
        )}
        {/* {!validation && <span>Can not delete tenant of which proforma is registered.</span>} */}
        {isLoading && <span>Subscription is deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div className="mr-auto">
          <button
            type="button"
            onClick={()=>onHide(clientId)}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
           <button
            type="button"
            onClick={deleteTenant}
            className="btn btn-primary btn-elevate mr-auto"
          >
            Delete
          </button> 
        </div>
      </Modal.Footer>
    </Modal>
  );
}
