import React, { useEffect, useMemo, useState, useRef,forwardRef,useLayoutEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/sales/salesActions";
import { useBankDepositUIContext } from "../BankDepositUIContext";
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment'
import BankDepositAGGrid from "./Ag_grid_for_bank_dep";
import TableDataRowFormatter from "./TableDataRowFormatter";
import * as ColumnFormatter from "./column-Formatter"
import $ from 'jquery';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import { Loader} from 'react-loaders';

const BankDepositTable = forwardRef((props, ref)  => {
    const { ref1, ref2 } = ref;
  const exportInsideCSV = useRef();
  const exportInsidePrint = useRef();
  const bankDepositUIContext = useBankDepositUIContext();
  const bankDepositUIProps = useMemo(() => {
    return {
      ids: bankDepositUIContext.ids,
      setIds: bankDepositUIContext.setIds,
      queryParams: bankDepositUIContext.queryParams,
      setQueryParams: bankDepositUIContext.setQueryParams,
    };
  }, [bankDepositUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state }),
    shallowEqual
  );
  let { bankDeposits: entities,error,apiErrorData,saleListLoading } = currentState.sales;
  const {clientEntities} = currentState.client;
  
  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }
    }
  },[error])

  const [grandTotal,setGT] = useState('0.00')
  const [priceTotal,setPGT]= useState('0.00')
  const [invoiceTotal,setIGT]= useState('0.00')
  const [companyTotal,setCGT] = useState('0.00')
  const [nonCompanyTotal,setNGT]= useState('0.00')
  const [tableData,setTableData] = useState(null)
  
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchBankDeposits(bankDepositUIProps.queryParams))
    
  }, [bankDepositUIProps.queryParams, dispatch]);
  
  useEffect(()=>{
    setGT(entities.reduce((accumulator, current) => accumulator + (current.company_amount + current.non_company_amount) , 0).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }))
    setCGT(entities.reduce((accumulator, current) => accumulator + (current.company_amount) , 0).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }))
    setNGT(entities.reduce((accumulator, current) => accumulator + (current.non_company_amount) , 0).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }))
    setIGT(entities && entities[0]?.totalIA.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }))
    setPGT(entities && entities[0]?.totalUP.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }))
    entities = entities.map(e => ({...e, 
      company_amount: e.company_amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      non_company_amount: e.non_company_amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }), 
      totalAmount: e.totalAmount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      totalAmountByComBank: (e.totalAmount - e.totalAmountByNonBank).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      totalAmountByNonBank: e.totalAmountByNonBank.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      unit_price: e.unit_price.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      invoice_amount: e.invoice_amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      invoice_amountNum:e.invoice_amount,
      unit_priceNum:e.unit_price,
      totalAmountByComBankNum: e.totalAmount - e.totalAmountByNonBank,
      totalAmountNum:e.totalAmount,
      date: moment(e.date).format('DD/MM/YYYY'), 
    }))
    setTableData(cloneDeep(entities))
  },[entities])


  
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "#",
      field: 'car.id',
      cellClassRules: {
        'cell-totalSpan':({ data }) => data && data.span === -2
      }, 
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      minWidth: 65,
      Width: 70,
      flex: 3,
    }
    ,
    {
      headerName: 'CAR',
      field: 'car',
      cellClassRules: {
        'cell-totalSpan':({ data }) => data && data.span === -2
      },
      cellRenderer:ColumnFormatter.CarColumnFormatter,
     
      minWidth: 192,
      Width: 350,
      flex: 3,
      
    },
    
    {
      headerName: 'BANK ACC',
      field: 'detail.bank_acc',
      cellClassRules: {
        'cell-totalSpan':({ data }) => data && data.span === -2
      },
      minWidth: 125,
      maxWidth: 177,
      flex: 3,
      hide:bankDepositUIProps.queryParams?.filter.minimize === '0' ? true : false,
    },
    {
      headerName: 'DATE',
      field: 'detail.date',
       cellClassRules: {
      
       },
      width:125,
      hide:bankDepositUIProps.queryParams?.filter.minimize === '0' ? true : false,
    },
    {
      headerName: 'PRICE',
      field: 'car.unitPrice',
      type: 'rightAligned',
      cellClassRules: {
        'cell-totalSpan':({ data }) => data && data.span === -2
      },
      minWidth: 150,
      Width: 177,
      flex: 3,
    },
    {
      headerName: 'INVOICE',
      field: 'car.invoiceAmount',
      type: 'rightAligned',
       cellClassRules: {
        'cell-totalSpan':({ data }) => data && data.span === -2
      },
      minWidth: 150,
      Width: 177,
      flex: 3,
    },
    {
      headerName: 'COMPANY AMT',
      field: 'detail.company_amount',
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      cellClassRules: {
        'cell-totalSpan':({ data }) => data && data.span === -2,
        'redFont':({ data }) => {
          return data && data.car.invoice_amountNum < data.car.totalAmountByComBankNum},
      },
      cellRenderer: (params) => (
          <div className={params.value < 0 ? `text-danger` : ''}>{params.value}</div>
      ),
      minWidth: 130,
      Width: 177,
      flex: 3,
    },
    {
      headerName: 'NON-COMPANY AMT',
      field: 'detail.non_company_amount',
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      cellClassRules: {
        'cell-totalSpan':({ data }) => data && data.span === -2
      },
      cellRenderer: (params) => (
        <div className={params.value < 0 ? `text-danger` : ""}>{params.value}</div>
      ),
      minWidth: 130,
      Width: 177,
      flex: 3,
    },
    {
      headerName: 'Total',
      field: 'detail.totalAmount',
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      cellClassRules: {
        'cell-totalSpan':({ data }) => data && data.span === -2,
        'redFont':({ data }) => data && data.car.unit_priceNum > data.car.totalAmountNum
      },
      minWidth: 150,
      Width: 177,
      flex: 2,
    }
  ]);

  const handlerCsv = () => {
    
    exportInsideCSV.current.click();
  };
  const handlerPrint = () => {
    $('#bankContainer').css('visibility','hidden');
    $('#blockConstiner').show()
    exportInsidePrint.current.click();
  };

  const componentRef = useRef(null);
  
  return (
    
          <>
            
              <div className="d-flex justify-content-end">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="spec-edit-tooltip">Export to Excel</Tooltip>
                  }
                >
                
                <input
                  ref={ref1}
                  hidden
                  className="btn btn-success"
                  onClick={handlerCsv}
                ></input>

                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="spec-edit-tooltip">Export to PDF</Tooltip>
                  }
                >
                
                  <input
                  ref={ref2}
                  hidden
                  className="btn btn-success"
                  onClick={handlerPrint}
                  
                ></input> 
                </OverlayTrigger>
              </div>
          <BlockUi blocking={saleListLoading} loader={<Loader active type={'ball-triangle-path'} color="#9d9dad" />} tag="div" message="" >
            <div className="report-content" ref={componentRef} style={{ height: '100%' }}>
            
              <div id="blockConstiner" style={{display:'none',height: '100%',width:'100%' }} ><BlockUi style={{height:'50px'}} blocking={true} loader={<Loader active type={'ball-triangle-path'} color="#9d9dad" />} tag="div" message="Loading" ></BlockUi></div>
              <div id="bankContainer" >
              <div style={{ height: '100%',width:'100%' }}>
               {tableData  && <BankDepositAGGrid 
                  totals={
                    {"grandTotal":grandTotal,
                      "nonCompanyTotal":nonCompanyTotal,
                      "companyTotal":companyTotal,
                      "invoiceTotal":invoiceTotal,
                      "priceTotal":priceTotal
                  }} 
                  ref={{ref1:exportInsideCSV,ref2:exportInsidePrint}} 
                  tableData= {TableDataRowFormatter(tableData)} 
                  columnDefs={columnDefs}
                  bankDepositUIProps={bankDepositUIProps}
                  clientEntities={clientEntities}
                  entities={entities} ></BankDepositAGGrid>}
              </div>
              </div>
            </div>
            </BlockUi>
          </>
    
  );
});

export default BankDepositTable;





