import React from "react";
import { ProfileLoadingDialog } from "./profile-loading-dialog/ProfileLoadingDialog";

import { ProfileUIProvider } from "./ProfileUIContext";
import { ProfileCard } from "./ProfileCard";

export function ProfilePage({ history }) {
  
  const profileUIEvents = ()=>{}

  return (
    <ProfileUIProvider profileUIEvents={profileUIEvents}>
      <ProfileLoadingDialog />
      <ProfileCard />
    </ProfileUIProvider>
  );
}
