import * as requestFromServer from "./clientsCrud";
import {tenantsSlice, callTypes} from "./clientsSlice";
import moment from "moment";
const {actions} = tenantsSlice;

export const fetchTenants = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  
  return requestFromServer
    .getAllTenants()
    .then(response => {
      const tenantEntities  = response.data;
      const tenantTotalCount = response.data.length;
      dispatch(actions.tenantsFetched({ tenantTotalCount, tenantEntities }));
    })
    .catch(error => {
      error.tenantMessage = "Can't find tenants";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchTenant = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.tenantFetched({ tenantForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTenantById(id)
    .then(response => {
      const tenant = response.data;
      dispatch(
        actions.tenantFetched({ tenantForEdit: tenant })
      );
    })
    .catch(error => {
      error.tenantMessage = "Can't find tenant";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchTenantbyUserEmail = email => dispatch => {
  if (!email) {
    return dispatch(
      actions.tenantsFetched({ tenantEntities: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTenantByUserEmail(email)
    .then(response => {
      const tenant = response.data;
      dispatch(
        actions.tenantsFetched({ tenantEntities: tenant })
      );
    })
    .catch(error => {
      error.tenantMessage = "Can't find tenant";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteTenant = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTenant(id)
    // .then(response => {
    //   dispatch(actions.tenantDeleted({ id }));
    // })
    .catch(error => {
      error.tenantMessage = "Can't delete tenant";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createTenant = tenantForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createTenant(tenantForCreation)
    // .then(response => {
    //   const { tenant } = response.data;
    //   dispatch(actions.tenantCreated({ tenant }));
    // })
    .catch(error => {
      error.tenantMessage = "Can't create tenant";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateTenant = tenant => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateTenant(tenant)
    // .then(() => {
    //   dispatch(actions.tenantUpdated({ subscribtion:tenant,tenantClientId:tenant?.client_id }));
    // })
    .catch(error => {
      error.tenantMessage = "Can't update tenant";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const activateDeactivateTenant = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .activateDeactivateTenant(id)
    .then(() => {
     dispatch(actions.endCall({ callType: callTypes.action }));
    })
    .catch(error => {
      error.tenantMessage = "Can't activate deactivate";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchSubscriptions = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchSubscriptions()
    .then((response) => {
      const subscriptions = response.data;
     dispatch(actions.fetchedSubscription({ subscriptions }));
    })
    .catch(error => {
      error.tenantMessage = "Can't fetch subscriptions";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchSubscriptionsForReport = queryParams => dispatch => {
  queryParams = {
    ...queryParams, 
    filter: {
      ...queryParams.filter, 
      start_date: moment(queryParams.filter.start_date).format("YYYY-MM-DD"),
      end_date: moment(queryParams.filter.end_date).format("YYYY-MM-DD")
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchSubscriptionsForReport(queryParams)
    .then(response => {
      dispatch(actions.fetchedSubscriptionForReport({ subscriptionsForReport: response.data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find subs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}


