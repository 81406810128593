import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,

} from "../../../../../_metronic/_partials/controls";
import { TenantsTable } from "./tenants-table/TenantsTable";
import { useTenantsUIContext } from "./TenantsUIContext";
import { TenantStatusModal } from "./TenantStatusModal";
export function TenantsCard() {
  const tenantsUIContext = useTenantsUIContext();
  const tenantsUIProps = useMemo(() => {
    return {
      ids: tenantsUIContext.ids,
      newTenantButtonClick: tenantsUIContext.newTenantButtonClick,
    };
  }, [tenantsUIContext]);

  return (
    <div>
           
    <div className="content d-flex flex-column-fluid" id="kt_content">
      
      <div className="container">
     
    <Card>
      <CardHeader title="Clients list">
        
      </CardHeader>
      <CardBody>
        <TenantsTable />
        <TenantStatusModal></TenantStatusModal>
      </CardBody>
    </Card>
    
    </div>
    </div>
    </div>
   
  );
}
