import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { BanksTable } from "./banks-table/BanksTable";
import { BanksGrouping } from "./banks-grouping/BanksGrouping";
import { useBanksUIContext } from "./BanksUIContext";

export function BanksCard() {
  const banksUIContext = useBanksUIContext();
  const banksUIProps = useMemo(() => {
    return {
      ids: banksUIContext.ids,
      newBankButtonClick: banksUIContext.newBankButtonClick,
    };
  }, [banksUIContext]);

  return (
    <div>
           
    <div className="content d-flex flex-column-fluid" id="kt_content">
      
      <div className="container">
     
     
                    {/* <div className="flex-row-fluid ml-lg-8">	
							
		</div> */}
    <Card>
      <CardHeader title="Banks list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={banksUIProps.newBankButtonClick}
          >
            New Bank
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {banksUIProps.ids.length > 0 && <BanksGrouping />}
        <BanksTable />
      </CardBody>
    </Card>
    
    </div>
    </div>
    </div>
   
  );
}
