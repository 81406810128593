import { createSlice } from "@reduxjs/toolkit";

const initialSettingState = {
    ethiopianBanksEntities:null,
    locationTypeEntities: null,
    lastError:null
}

export const callTypes = {
    list: "list",
    action: "action"
  };

export const settingSlice = createSlice({
    name:"settings",
    initialState:initialSettingState,
    reducers:{
        catchError:(state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if(state.apiErrorData === undefined){
              state.apiErrorData = {}
               state.apiErrorData = action.payload.error?.response?.data;
            }else{
              state.apiErrorData = action.payload.error?.response?.data;
            }
            if (action.payload.callType === callTypes.list) {
              state.groupListLoading = false;
            } else {
              state.groupActionsLoading = false;
            }
        },
        ethiopianBanksFetched:(state, action) => {
          state.ethiopianBanksEntities = action.payload.ethiopianBanksEntities;
          state.error = null;
        },
        locationTypesFetched:(state, action) => {
          state.locationTypeEntities = action.payload.locationTypeEntities;
          state.error = null;
        },
    }
})
