import axios from "axios";

export const TRANSACTION_URL = process.env.REACT_APP_AUTO_DEALER_API+'transactions';
export const ACCOUNT_URL = process.env.REACT_APP_AUTO_DEALER_API+'accounts';

export function getAllAccounts(){
  return axios.get(ACCOUNT_URL)
}

export function createAccount(account) {
    return axios.post(ACCOUNT_URL, account);
}

export function updateAccount(account) {
    return axios.put(`${ACCOUNT_URL}/${account?.id}`, account);
}

export function getDeleteValidationById(id){
    return axios.get(`${ACCOUNT_URL}/${id}/delete_validate`);
}

export function deleteAccount(id) {
    return axios.delete(`${ACCOUNT_URL}/${id}`);
}

export function getAccount(id){
    return axios.get(`${ACCOUNT_URL}/${id}`)
}

export function getAllTransactionsInAccount(id){
    return axios.get(`${ACCOUNT_URL}/${id}/transactions/active`)
}

export function createTransaction(transaction) {
    return axios.post(TRANSACTION_URL, transaction);
}

export function updateTransaction(transaction) {
    return axios.put(`${TRANSACTION_URL}/${transaction?.id}`, transaction);
}

export function deleteTransaction(id) {
    return axios.delete(`${TRANSACTION_URL}/${id}`);
}

export function getTransaction(id){
    return axios.get(`${TRANSACTION_URL}/${id}`)
}

export function closeTransactions(id){
    return axios.get(`${ACCOUNT_URL}/${id}/transactions/close`)
}

export function getAllClosedTransactionGroupsInAccount(id){
    return axios.get(`${ACCOUNT_URL}/${id}/transactions/closed`)
}

export function getAllClosedTransactionsInAccountByClosedDate(id, closed_date){
    return axios.get(`${ACCOUNT_URL}/${id}/transactions/closed/${closed_date}`)
}
