import * as requestFromServer from "./salesCrud";
import {salesSlice, callTypes} from "./salesSlice";
import moment from "moment";

const {actions} = salesSlice;

export const fetchSales = (queryParams, saleId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  if (!saleId) {
    return dispatch(
      actions.salesFetched({ totalCount: 0, entities: null })
    );
  }

  return requestFromServer
    .findSales(queryParams, saleId)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.salesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find sales";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSalesForReport = queryParams => dispatch => {
  // queryParams.filter.user_name = localStorage.currentUserEmail;
  queryParams = {
    ...queryParams,
    filter: {
      ...queryParams.filter,
      user_name: localStorage.currentUserEmail,
      start_date: moment(queryParams.filter.start_date).format("YYYY-MM-DD"),
      end_date: moment(queryParams.filter.end_date).format("YYYY-MM-DD")
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSalesForReport(queryParams)
    .then(response => {
      dispatch(actions.salesForReportFetched({ salesForReport: response.data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find sales";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const fetchSalesDetailForReport = queryParams => dispatch => {
  queryParams = {
    ...queryParams,
    filter: {
      ...queryParams.filter,
      user_name: localStorage.currentUserEmail,
      start_date: moment(queryParams.filter.start_date).format("YYYY-MM-DD"),
      end_date: moment(queryParams.filter.end_date).format("YYYY-MM-DD")
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSalesDetailForReport(queryParams)
    .then(response => {
      dispatch(actions.salesDetailForReportFetched({ salesDetailForReport: response.data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find sales";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const fetchLoanSalesForReport = queryParams => dispatch => {
  // queryParams = {...queryParams, filter: {...queryParams.filter, user_name: localStorage.currentUserEmail}}
  var date = new Date();
  queryParams = {
    ...queryParams,
    filter: {
      ...queryParams.filter,
      user_name: localStorage.currentUserEmail,
      start_date: moment(queryParams?.filter?.start_date || new Date(date.getFullYear(), date.getMonth(), 1)).format("YYYY-MM-DD"),
      end_date: queryParams?.filter?.end_date ? moment(queryParams.filter.end_date).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD')
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findLoanSalesForReport(queryParams)
    .then(response => {
      dispatch(actions.loanSalesForReportFetched({ loanSalesForReport: response.data}));
    })
    .catch(error => {
      error.clientMessage = "Can't find loan sales";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const fetchBankDeposits = queryParams => dispatch => {
  queryParams = {
    ...queryParams,
    filter: {
      ...queryParams.filter,
      user_name: localStorage.currentUserEmail,
      start_date: moment(queryParams.filter.start_date).format("YYYY-MM-DD"),
      end_date: moment(queryParams.filter.end_date).format("YYYY-MM-DD")
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findBankDeposits(queryParams)
    .then(response => {
      dispatch(actions.bankDepositsFetched({ bankDeposits: response.data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find bank deposits";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const fetchSale = (id,amount= null) => dispatch => {
  if (!id) {
    return dispatch(
      actions.saleFetched({ saleForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSaleById(id)
    .then(response => {
      let sale = response.data;
      sale.payment_date = new Date(sale?.payment_date)
      if(amount){
        sale.amount = amount
      }
      dispatch(
        actions.saleFetched({ saleForEdit: sale })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find sale";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSale = (id,uuid=null) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSale(id,uuid)
    .then(response => {
      dispatch(actions.saleDeleted({ id,uuid }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete sale";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSale = saleForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSale(saleForCreation)
    .then(response => {
      const { sale } = response.data;
      dispatch(actions.saleCreated({ sale }));
    })
    .catch(error => {
      error.clientMessage = "Can't create sale";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSaleInCar = saleForCreation => dispatch =>{
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSale(saleForCreation)
    .then(response => {
      const sale = response.data;
      dispatch(actions.saleCreated({ sale }));
    })
    .catch(error => {
      error.clientMessage = "Can't create sale";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
  // dispatch(actions.saleInCarCreated({sale: saleForCreation}));

};
export const createListSaleInCar = (saleForCreation,oldPaymentUUID) => dispatch =>{
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .createListSale(saleForCreation,oldPaymentUUID)
      .then(response => {
        const sale = response.data;
        dispatch(actions.saleCreated({ sale }));
      })
      .catch(error => {
        error.clientMessage = "Can't create sale";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  // dispatch(actions.saleInCarCreated({sale: saleForCreation}));

};
export const updateSaleInCar = saleForUpdate => dispatch => {
  // dispatch(actions.startCall({ callType: callTypes.action }));
  // dispatch(actions.saleInCarUpdated({sale: saleForUpdate}))
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSale(saleForUpdate)
    .then(response => {
      const sale = response.data;
      dispatch(actions.saleUpdated({ sale }));
    })
    .catch(error => {
      error.clientMessage = "Can't create sale";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}


export const updateBulkSale = sales => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBulkSale(sales)
    .then(() => {
      dispatch(actions.saleBulkUpdated({ sales }));
    })
    .catch(error => {
      error.clientMessage = "Can't update sale";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchSalesInCar = id => dispatch => {
  if(!id){
    return dispatch(actions.salesInCarFetched({sales: undefined}))
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
  .findSalesInCar(id)
  .then(response => {
    const entities  = response.data;
    const totalCount = response.data.length;
    dispatch(actions.salesInCarFetched({ sales: entities, salesTotalCount: totalCount }));
  })
  .catch(error => {
    error.clientMessage = "Can't delete car";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  });
}

// Fetch all sales for a proforma
export const fetchSalesInProforma = proformaId => dispatch => {
  if(!proformaId){
    // return dispatch(actions.salesInCarProforma({salesInProforma: undefined}))
    return dispatch(actions.salesInProformaFetched({salesInProforma: undefined}))
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
  .findSalesInProforma(proformaId)
  .then(response => {
    dispatch(actions.salesInProformaFetched({ salesInProforma: response.data }));
  })
  .catch(error => {
    error.clientMessage = "Can't find sale";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  });
}

export const updateSale = sale => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSale(sale)
    .then(() => {
      dispatch(actions.saleUpdated({ sale }));
    })
    .catch(error => {
      error.clientMessage = "Can't update sale";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSales = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSales(ids)
    .then(() => {
      dispatch(actions.salesDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete sales";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const resetSalesSlice = () => dispatch => {
  dispatch(actions.saleEntitiesReset())
}

export const clearSalesInProforma = () => dispatch => {
  dispatch(actions.clearedSalesInProforma())
}

export const clearSalesForm = () => dispatch => {
  dispatch(actions.saleFormReset())
}

export const updateCustomer = (customer,values) => dispatch => {
  dispatch(actions.updateCustomer({ customer,values }));
};
