import {createSlice} from "@reduxjs/toolkit";

const initialSalesState = {
  saleListLoading: false,
  saleLoading: false,
  saleTotalCount: 0,
  saleEntities: null,
  salesInProforma: null,
  saleForEdit: undefined,
  lastError: null,
  salesForReport: [],
  salesDetailForReport: [],
  loanSalesForReport: [],
  bankDeposits: []
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const salesSlice = createSlice({
  name: "sales",
  initialState: initialSalesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.saleListLoading = false;
      } else {
        state.saleLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.saleListLoading = true;
      } else {
        state.saleLoading = true;
      }
    },
    // getSaleById
    saleFetched: (state, action) => {
      state.saleForEdit = action.payload.saleForEdit;
      state.error = null;
      state.saleLoading = false;
    },
    // findSales
    salesFetched: (state, action) => {
      const { saleTotalCount, saleEntities } = action.payload;
      state.saleListLoading = false;
      state.error = null;
      state.saleEntities = saleEntities;
      state.saleTotalCount = saleTotalCount;
    },
    // find sales for report
    salesForReportFetched: (state, action) => {
      const { salesForReport } = action.payload;
      state.saleListLoading = false;
      state.error = null;
      state.salesForReport = salesForReport;
    },
    // find sales detail for report
    salesDetailForReportFetched: (state, action) => {
      const { salesDetailForReport } = action.payload;
      state.saleListLoading = false;
      state.error = null;
      state.salesDetailForReport = salesDetailForReport;
    },
    // find loan sales for report
    loanSalesForReportFetched: (state, action) => {
      const { loanSalesForReport } = action.payload;
      state.saleListLoading = false;
      state.error = null;
      state.loanSalesForReport = loanSalesForReport;
    },
    // find bank deposits
    bankDepositsFetched: (state, action) => {
      const { bankDeposits } = action.payload;
      state.saleListLoading = false;
      state.error = null;
      state.bankDeposits = bankDeposits;
    },
    // createSale
    saleCreated: (state, action) => {
      state.saleLoading = false;
      state.error = null;
      if(state.saleEntities == undefined || state.saleEntities == null){
        state.saleEntities =  [];
        state.saleEntities.push(action.payload.sale);
      }else{
        state.saleEntities.push(action.payload.sale);
      }
      // state.saleEntities.push(action.payload.sale);
      if(state.salesInProforma == undefined || state.salesInProforma == null){
        state.salesInProforma =  [];
        state.salesInProforma.push(action.payload.sale);
      }else{
        state.salesInProforma.push(action.payload.sale);
      }
      state.saleForEdit = null;
    },
    // updateSale
    saleUpdated: (state, action) => {
      state.error = null;
      state.saleLoading = false;
      state.saleEntities = state.saleEntities.map(entity => {
        if (entity.id === action.payload.sale.id) {
          return action.payload.sale;
        }
        return entity;
      });
      state.salesInProforma = state.salesInProforma.map(entity => {
        if (entity.id === action.payload.sale.id) {
          return action.payload.sale;
        }
        return entity;
      });
      state.saleForEdit = null;
    },


    // bulk Sale update and create
    saleBulkUpdated: (state, action) => {
      state.error = null;
      state.saleLoading = false;
      state.saleEntities = state.saleEntities.map(entity => {
        if (entity.id === action.payload.sale.id) {
          return action.payload.sale;
        }
        return entity;
      });
    },

    salesInCarFetched: (state, action) => {
      const { sales, salesTotalCount } = action.payload;
      state.saleEntities = sales;
      state.salesTotalCount = salesTotalCount;
      state.saleListLoading = false;
    },

    // fetch sales for a proforma
    salesInProformaFetched: (state, action) => {
      const { salesInProforma,  } = action.payload;
      state.salesInProforma = salesInProforma;
      state.saleListLoading = false;
    },
    updateCustomer:(state,action) => {


        let editValue = {...action.payload.values}
        state.saleForEdit=editValue

    },
    //createSaleInCar
    saleInCarCreated:(state, action) =>{
      state.error = null;
      state.saleLoading = false;
      if(state.saleEntities == undefined || state.saleEntities == null){
        state.saleEntities =  [];
        state.saleEntities.push(action.payload.sale);
      }else{
        state.saleEntities.push(action.payload.sale);
      }

    },

    saleInCarUpdated:(state, action) => {
      state.error = null;
      state.saleLoading = false;
      // state.carForEdit.sales = state.carForEdit.sales.map(element => {
      //   if(element.id == action.payload.sale.id){
      //     return action.payload.sale;
      //   }else{
      //     return element;
      //   }
      // })
      if(state.saleEntities == undefined || state.saleEntities == null){
        state.saleEntities =  [];
        state.saleEntities.push(action.payload.sale);
      }else{
        state.saleEntities.push(action.payload.sale);
      }
    },
    // deleteSale
    saleDeleted: (state, action) => {
      state.error = null;
      state.saleLoading = false;
      if(action.payload.uuid){
        state.saleEntities = state.saleEntities && state.saleEntities.filter(el => el.id !== action.payload.id);
        state.salesInProforma = state.salesInProforma.filter(el => el.id !== action.payload.id && el?.common_payment !== action.payload?.uuid );
      }else{
      state.saleEntities = state.saleEntities && state.saleEntities.filter(el => el.id !== action.payload.id);
      state.salesInProforma = state.salesInProforma.filter(el => el.id !== action.payload.id);
      }
      state.saleForEdit = null;
    },
    // deleteSales
    salesDeleted: (state, action) => {
      state.error = null;
      state.saleLoading = false;
      state.saleEntities = state.saleEntities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // clear sale entities
    saleEntitiesReset: (state, action) => {
      state.saleEntities = null
      state.salesInProforma = null
      state.saleForEdit = null
    },
    clearedSalesInProforma: (state, action) => {
      state.salesInProforma = null;
    },
    // clear sale form
    saleFormReset: (state, action) => {
      state.saleForEdit = null
    }
  }
});
