import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal, Nav } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../_metronic/_partials/controls";

export function AccountEditDialogHeader({ id, onHide }) {
  // Batch Redux state
  const { accountForEdit, actionsLoading } = useSelector(
    (state) => ({
      accountForEdit: state.accounts.accountForEdit,
      actionsLoading: state.accounts.accountLoading,
    }),
    shallowEqual
  ); 

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = id ? "Edit Account" : "New Account";
    setTitle(_title);
    // eslint-disable-next-line
  }, [accountForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        <Nav className="ml-auto" onClick={() => onHide()}>
          <Nav.Link className="p-0 text-dark">
            <span className="nav-icon">
              <span className="svg-icon svg-icon-2x">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                        <rect x="0" y="7" width="16" height="2" rx="1"/>
                        <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </span>
          </Nav.Link>
        </Nav>
      </Modal.Header>
    </>
  );
}
