// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo,useLayoutEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/car-models/carModelsActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "./../../../../../_metronic/_helpers";
import * as uiHelpers from "../CarModelsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "./../../../../../_metronic/_partials/controls";
import { useCarModelsUIContext } from "../CarModelsUIContext";
import NotFoundPage from "../../shared/NotFoundPage";
import $ from 'jquery';

export function CarModelsTable() {
  // CarModels UI Context
  const carModelsUIContext = useCarModelsUIContext();
  const carModelsUIProps = useMemo(() => {
    return {
      ids: carModelsUIContext.ids,
      setIds: carModelsUIContext.setIds,
      queryParams: carModelsUIContext.queryParams,
      setQueryParams: carModelsUIContext.setQueryParams,
      openEditCarModelDialog: carModelsUIContext.openEditCarModelDialog,
      openDeleteCarModelDialog: carModelsUIContext.openDeleteCarModelDialog,
    };
  }, [carModelsUIContext]);

  // Getting curret state of carModels list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.carModels }),
    shallowEqual
  );
  const { totalCount, entities, listLoading,error,apiErrorData } = currentState;
  const [notFound,setNotFound] = useState(false);

  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }else if(error 
          && apiErrorData?.errorCode==="NOT_FOUND"){
            setNotFound(true);
        }
    }
  },[error])
  useEffect(()=>{
    if(notFound){
      $("#root > div > div > div.content.d-flex.flex-column-fluid > div > div > div.flex-row-fluid.ml-lg-8 > div > div.card-header > div.card-toolbar > button").hide();
    }
  },[notFound])
  // CarModels Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    carModelsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchCarModels(carModelsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carModelsUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    {
      dataField: "make",
      text: "Brand/Make",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-dark-75 font-size-lg",
      style: {
        minWidth: "100px",
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          carModelsUIContext.openEditCarModelDialog(row.id);
        }
      },
    },
    {
      dataField: "model",
      text: "Model",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-dark-75 font-size-lg",
      style: {
        minWidth: "100px",
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          carModelsUIContext.openEditCarModelDialog(row.id);
        }
      },
    },
    {
      dataField: "year",
      text: "Year",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      // classes: "font-weight-bolder text-primary mb-0",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          carModelsUIContext.openEditCarModelDialog(row.id);
        }
      },
    },

    {
      dataField: "body",
      text: "Body",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          carModelsUIContext.openEditCarModelDialog(row.id);
        }
      },
    },
    
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCarModelDialog: carModelsUIProps.openEditCarModelDialog,
        openDeleteCarModelDialog: carModelsUIProps.openDeleteCarModelDialog,
      },
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: carModelsUIProps.queryParams.pageSize,
    page: carModelsUIProps.queryParams.pageNumber,
  };
  return (
    <>
    {!notFound ?
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  carModelsUIProps.setQueryParams
                )}
                rowStyle={{ cursor: 'pointer' }}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: carModelsUIProps.ids,
                //   setIds: carModelsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
     :<NotFoundPage/>}
    </>
  );
}
