import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Line extends Component {

  constructor(props) {
    super(props);
    let d = new Date();

    this.state = {
      options: {
        xaxis: {
          //range:100,
          type: 'datetime',
          min: d.setMonth(d.getMonth()-3), // start date
          //max: new Date(new Date().setHours(24, 0, 0, 0)).getTime(), // end date
          labels: {
            datetimeUTC: false
        }
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy',
            title: {
              formatter: function (seriesName) {
                return ""
              }
            }
          },
          y: {
            formatter: function(val) {
              return  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            title: {
              formatter: function (seriesName) {
                return ''
              }
            }
          }
        },
        stroke: {
          curve: 'smooth'
        },
        markers: {
          size: 0
        },
        
      },
      series: [{
        data: this.props.data
      }],
    }
  }

  render() {

    return (
      <div className="line">
        <Chart options={this.state.options} series={this.state.series} type="line"  />
      </div>
    );
  }
}

export default Line;
