import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCarsUIContext } from "../CarsUIContext";
import AsyncSearchBar from "../../shared/AsyncSelectBar";
import * as categoryActions from "../../../_redux/categories/categoriesActions";
import * as searchActions from "../../../_redux/search/searchAction";
import AsyncMultiSearchBar from "../../shared/AsyncMultiSelectBar";

const saleStatuses = [{id: 1, name: 'In-Process', value: "in-Process"}, {id: 2, name: 'Sold', value: 'sold'}, {id: 3, name: 'Free', value: 'free'}];

const prepareFilter = (queryParams, values) => {
  const { batch_id, supplier_id, status_id, type, searchText,location_id ,make} = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  filter.make = (!make || make == "-- Select --") ? "" : make;
  filter.supplier = supplier_id !== "" ? `${supplier_id}` : undefined;
  filter.batch = batch_id !== "" ? `${batch_id}` : undefined;
  // Filter by status
  // filter.status = filter.status = status_id !== "" ? saleStatuses.find(s => s.id == status_id)?.value : undefined;
  filter.status = status_id?.length > 0 ? saleStatuses?.filter(({id}) => status_id?.some(s => id === s))?.map(({value}) => value)?.toString() : undefined
  // Filter by type
  filter.type = type !== "" ? +type : undefined;
  // Filter by all fields
  if (searchText) {
    filter.searchText = searchText
    // filter.vin = searchText;
    // filter.make = searchText;
    // filter.model = searchText;
  }
  // filter.location = location_id !== "" ? `${location_id}` : undefined;
  filter.location = location_id?.length > 0 ? location_id?.toString() : undefined;

  newQueryParams.filter = filter;
  return newQueryParams;
};

export function CarsFilter({ listLoading }) {
  // Cars UI Context
  const carsUIContext = useCarsUIContext();
  const carsUIProps = useMemo(() => {
    return {
      queryParams: carsUIContext.queryParams,
      setQueryParams: carsUIContext.setQueryParams,
    };
  }, [carsUIContext]);

  const dispatch = useDispatch();

	const { currentState } = useSelector(
		(state) => ({ currentState: state }),
		shallowEqual
	);

  const {searchEntities:searchValues} = currentState.search?.pageName==="car" ? currentState.search : {searchEntities:{searchText:"",
                                                              supplier_id: "",
																															batch_id:"",
																															make: "",
																															model: "",
																															status_id: [1, 3],
																															batch: "", 
																															color: "",
                                                              location_id:"",
                                                              status_id_name:[
																																{id: 1, name: "In-Process", value: "in-Process"}, 
																																{id: 3, name: "Free", value: "free"}
																															],
																															location_id_name:{},
																															batch_id_name:{}, 
                                                              supplier_id_name:{}}}

  
	const { categoryEntities } = currentState.categories;
  const {locationEntities} = currentState.locations;
  const[toggleSearch,setToggleSearch] = useState(false)

	useEffect(() => {
		!categoryEntities && dispatch(categoryActions.fetchCategories());
  }, [dispatch]);
  
  useEffect(()=>{
		applyFilter(searchValues);
	},[])
  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(carsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, carsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      carsUIProps.setQueryParams(newQueryParams);
    }
  };

  function setSearchSlice(value){
		dispatch(searchActions.createSearch(value,"car"))
	}
 
  return (
    <>
      <Formik
        initialValues={searchValues}
        onSubmit={(values) => {
          setSearchSlice(values);
          applyFilter(values);
        }}
      >
        {({
          values,
          touched,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          setFieldTouched
        }) => (
          <>
          <div className="d-lg-none">
          <div className="row px-5">
            <a className="ml-auto" onClick={()=>{setToggleSearch(!toggleSearch)}}>
            <span className="svg-icon svg-icon-primary svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24"/>
                      <path d="M8.2928955,3.20710089 C7.90237121,2.8165766 7.90237121,2.18341162 8.2928955,1.79288733 C8.6834198,1.40236304 9.31658478,1.40236304 9.70710907,1.79288733 L15.7071091,7.79288733 C16.085688,8.17146626 16.0989336,8.7810527 15.7371564,9.17571874 L10.2371564,15.1757187 C9.86396402,15.5828377 9.23139665,15.6103407 8.82427766,15.2371482 C8.41715867,14.8639558 8.38965574,14.2313885 8.76284815,13.8242695 L13.6158645,8.53006986 L8.2928955,3.20710089 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 8.499997) scale(-1, -1) rotate(-90.000000) translate(-12.000003, -8.499997) "/>
                      <path d="M6.70710678,19.2071045 C6.31658249,19.5976288 5.68341751,19.5976288 5.29289322,19.2071045 C4.90236893,18.8165802 4.90236893,18.1834152 5.29289322,17.7928909 L11.2928932,11.7928909 C11.6714722,11.414312 12.2810586,11.4010664 12.6757246,11.7628436 L18.6757246,17.2628436 C19.0828436,17.636036 19.1103465,18.2686034 18.7371541,18.6757223 C18.3639617,19.0828413 17.7313944,19.1103443 17.3242754,18.7371519 L12.0300757,13.8841355 L6.70710678,19.2071045 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 15.499997) scale(-1, -1) rotate(-360.000000) translate(-12.000003, -15.499997) "/>
                  </g>
              </svg>
              </span>
            </a></div>
          
            <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-3">
              <small className="form-text text-muted">
                  <b>Search</b>
                </small>
                <div className="input-icon">
                  <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />
                  <span>
                    <i className="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              
              </div>
              
              {toggleSearch && 
              <> 
              <div className="col-lg-3">
                <small className="form-text text-muted">
                  <b>Make</b> 
                </small>
                <AsyncSearchBar 
                  selectedItem={{id: values.make}} 
                  setSelectedItem={setFieldValue}
                  setTouchedItem={setFieldTouched}
                  defaultItem={searchValues?.make}
                  touched={touched.make}
                  item_id="make"
                  item_name="make"
                  url={`${process.env.REACT_APP_AUTO_DEALER_API}carmodels/searchMakes`}
                  pk="make"
                  submit={handleSubmit}
                  clear={values.make == ""}
                />
              </div>
              <div className="col-lg-2">
                <small className="form-text text-muted">
                  <b>Sale Status</b> 
                </small>
                <AsyncMultiSearchBar
                  isMulti={true}
                  selectedItem={{id: values.status_id}} 
                  setSelectedItem={setFieldValue}
                  setTouchedItem={setFieldTouched}
                  touched={touched.status_id}
                  defaultItem={searchValues?.status_id_name?.name}
                  item_id="status_id"
                  item_name="name"
                  options={saleStatuses}
                  isSearchable={false}
                  submit={handleSubmit}
                  clear={values.status_id == ""}
                  catchDefaultName={true}
                  val={values?.status_id_name}
                />
              </div>
              <div className="col-lg-3">
                <small className="form-text text-muted">
                  <b>Batch</b> 
                </small>
                <AsyncSearchBar 
                  selectedItem={{id: values.batch_id}} 
                  setSelectedItem={setFieldValue}
                  setTouchedItem={setFieldTouched}
                  defaultItem={searchValues?.batch_id_name?.name}
                  touched={touched.batch_id}
                  item_id="batch_id"
                  item_name="name"
                  url={`${process.env.REACT_APP_AUTO_DEALER_API}batches/search`}
                  submit={handleSubmit}
                  catchDefaultName={true}
                />               
              </div>
              <div className="col-lg-3">
                <small className="form-text text-muted">
                  <b>Location</b> 
                </small> 
                <AsyncMultiSearchBar
                  isMulti={true}
                  selectedItem={{id: values.location_id}} 
                  setSelectedItem={setFieldValue}
                  setTouchedItem={setFieldTouched}
                  defaultItem={searchValues?.location_id_name?.name}
                  touched={touched.location_id}
                  item_id="location_id"
                  item_name="name"
                  url={`${process.env.REACT_APP_AUTO_DEALER_API}locations/search`}
                  submit={handleSubmit}
                  userName={true}
                  clear={values.location_id == ""}
                  catchDefaultName={true}
                  val={values?.location_id_name}
                />                  
              </div>
               </>}
              <div className="col-lg-3 text-right" style={{paddingTop: "10px"}}>
              <button 
                        type="reset" 
                        className="btn btn-primary font-weight-bolder" 
                        onClick={()=>{
                          //resetForm();
                          // setPriceRange(initialPriceRange);
                          setFieldValue('supplier_id', '')
                          setFieldValue('batch_id', '')
                          setFieldValue('color', '')
                          setFieldValue('make', '')
                          setFieldValue('model', '')
                          setFieldValue('status_id', [1, 3])
                          setFieldValue('status_id_name', [
                            {id: 1, name: "In-Process", value: "in-Process"}, 
                            {id: 3, name: "Free", value: "free"}
                          ])
                          setFieldValue('searchText', '')
                          setFieldValue('type', '')
                          setFieldValue('location', '')
                          setToggleSearch(false)
                          handleSubmit();
                        }}>
                          Reset
                      </button>
                </div>
            </div>
            
          </form>
          </div>
          </>
        )}
      </Formik>
    </>
  );
}
