/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "./../../../../../../_metronic/_partials/controls";
import * as proformaActions from "../../../../_redux/proformas/proformasActions"
import * as actions from "../../../../_redux/sales/salesActions"
import { useSalesUIContext } from "./../SalesUIContext";
import { useCarsUIContext } from "../../CarsUIContext"
import { useProformasUIContext } from "../../car-proformas/ProformasUIContext";
import { ProformaStatusTitles } from "../SalesUIHelper";

export function SaleContinueDialog() {
  // Sales UI Context
  const specsUIContext = useSalesUIContext();
  const specsUIProps = useMemo(() => {
    return {
      values: specsUIContext.values,
      productId: specsUIContext.productId,
      proformaId: specsUIContext.proformaId,
      openNewSaleDialog: specsUIContext.openNewSaleDialog,
      openCompleteSaleDialog: specsUIContext.openCompleteSaleDialog,
      show: specsUIContext.showContinueSaleDialog,
      onHide: specsUIContext.closeContinueSaleDialog, 
      saleFromOutside: specsUIContext.saleFromOutside,
      showContinueSaleDialog: specsUIContext.showContinueSaleDialog, 
      setIsLoading: specsUIContext.setIsLoading,
      setCurrentSale: specsUIContext.setCurrentSale, 
      finishSale: specsUIContext.finishSale
    };
  }, [specsUIContext]);

  const proformasUIContext = useProformasUIContext();
  const proformasUIProps = useMemo(() => {
    return {
      onHideEditProforma: proformasUIContext.closeEditProformaDialog, 
    };
  }, [proformasUIContext]);

  const carsUIContext = useCarsUIContext();
  const carsUIProps = useMemo(() => {
    return {
      queryParams: carsUIContext.queryParams,
    }
  }, [carsUIContext])


  // Specs Redux state
  const dispatch = useDispatch();
  const { isLoading, saleEntities, carForEdit, validProforma, salesInProforma, proformaForEdit, proforma } = useSelector(
    (state) => ({
      carForEdit: state.cars.carForEdit, 
      isLoading: state.sales.actionsLoading,
      saleEntities: state.sales.saleEntities,
      validProforma: state.proformas.validProforma, 
      salesInProforma: state.sales.salesInProforma, 
      proformaForEdit: state.proformas.proformaForEdit,
      proforma: (state.proformas.entities && state.proformas.entities.find(p => (p.status_id != ProformaStatusTitles[1].id && p.status_id != ProformaStatusTitles[3].id ))),  
    }),
    shallowEqual
  );

  const salesAmount = validProforma && validProforma?.sales_amount
  const gpsAmount = validProforma && validProforma?.gps_amount
  const expectedAmount = (Number(salesAmount) + Number(gpsAmount)) || carForEdit?.price
  const collectedAmount = saleEntities?.reduce((accumulator, current) => accumulator + current.amount, 0)
  const remainingAmount = expectedAmount - collectedAmount
  let excess = undefined
  if(collectedAmount !== undefined && expectedAmount !== undefined) {
    excess = collectedAmount >= expectedAmount
  }

  const continueSale = async () => {
    // specsUIProps.openNewSaleDialog()
    // const d = new Date(specsUIProps.values.sale.payment_date);
    specsUIProps.values.sale = {
      ...specsUIProps.values.sale,
      // payment_date: (d instanceof Date) ? `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}` : d, 
      amount: (specsUIProps.values.sale.payment_type_id === 3) ? -specsUIProps.values.sale.amount : specsUIProps.values.sale.amount
    }

    // const proforma_date = specsUIProps.values.proforma.proforma_date ? new Date(specsUIProps.values.proforma.proforma_date) : new Date();
    specsUIProps.values.proforma = {
      ...specsUIProps.values.proforma, 
      // proforma_date: (proforma_date instanceof Date) ? `${proforma_date.getFullYear()}-${proforma_date.getMonth() + 1}-${proforma_date.getDate()}` : proforma_date, 
      // status_id: ProformaStatusTitles[0].id
    };

    if(specsUIProps.values.proforma.id == 0) {
      specsUIProps.values.proforma = {...specsUIProps.values.proforma, expire: 99}
      specsUIProps.setIsLoading(true)
      dispatch(proformaActions.createProforma(specsUIProps.values.proforma)).then((res) => {
        const currentProforma = res
        specsUIProps.setCurrentSale(specsUIProps.values.sale)
        specsUIProps.setIsLoading(false)
        const proformaItemId = currentProforma.proformaItems?.find(p => p.product_id == specsUIProps.productId)?.id
        const newSale = {...specsUIProps.values.sale, proforma_id: currentProforma.id, proforma_item_id: proformaItemId}
        dispatch(actions.createSaleInCar(newSale)).then(() => {
            dispatch(actions.fetchSalesInProforma(currentProforma.id))
            dispatch(actions.fetchSalesInCar(specsUIProps.productId))
        })
      })
    } else {
      specsUIProps.setIsLoading(true)
      dispatch(proformaActions.updateProforma(specsUIProps.values.proforma, specsUIProps.values.proforma.id)).then(async (res) => {
        const currentProforma = res
        specsUIProps.setCurrentSale(specsUIProps.values.sale)
        specsUIProps.setIsLoading(false)
        const proformaItemId = currentProforma.proformaItems?.find(p => p.product_id == specsUIProps.productId)?.id
        const newSale = await {...specsUIProps.values.sale, proforma_id: currentProforma.id, proforma_item_id: proformaItemId}
        await dispatch(actions.createSaleInCar(newSale)).then(async () => {
            await dispatch(actions.fetchSalesInProforma(currentProforma.id))
            await dispatch(actions.fetchSalesInCar(specsUIProps.productId))
        })

        specsUIProps.onHide(true)
        const s = [...saleEntities, newSale]
        const totalPaid = s && s.reduce((accumulator, current) => accumulator + current.amount, 0)
        const totalSaleAmount = proformaForEdit && proformaForEdit.proformaItems.reduce((accumulator, current) => accumulator + current.sales_amount + current.gps_amount, 0)
        if(proforma?.status_id != ProformaStatusTitles[2].id) {
          if (totalPaid >= totalSaleAmount) {
            proforma && specsUIProps.openCompleteSaleDialog(proforma.id)
          }
        }
        else {
          specsUIProps.finishSale && proformasUIProps.onHideEditProforma()
        }
      })
    }
  };

  useEffect(() => {
    if(specsUIProps.showContinueSaleDialog === true && excess === false) {
      continueSale()
    }
  }, [excess])

  useEffect(() => {
    specsUIProps?.productId && dispatch(actions.fetchSalesInCar(specsUIProps.productId))
  }, [dispatch, specsUIProps.productId]);

  return (
    (excess !== undefined) ? (
      <Modal
      show={specsUIProps.show}
      aria-labelledby="example-modal-sizes-title-lg"
      >
        {isLoading && <ModalProgressBar variant="query" />}
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Sale Continue
          </Modal.Title>
          <Nav className="ml-auto" onClick={() => specsUIProps.onHide(false)}>
          <Nav.Link className="p-0 text-dark">
            <span className="nav-icon">
              <span className="svg-icon svg-icon-2x">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                        <rect x="0" y="7" width="16" height="2" rx="1"/>
                        <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </span>
          </Nav.Link>
        </Nav>
        </Modal.Header>
        <Modal.Body>
          {!isLoading && (
            <p>Collected Amount {remainingAmount < 0 ? "has exceeded" : "will exceed"} the Sales Amount. <br/>Are you sure you want to continue?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="mr-auto">
          <button
              type="button"
              onClick={continueSale}
              className="btn btn-primary btn-elevate"
            >
              Yes
            </button>
            <> </>
            <button
              type="button"
              onClick={() => {
                specsUIProps.onHide(!specsUIProps.finishSale)
                specsUIProps.finishSale && proformasUIProps.onHideEditProforma()
              }}
              className="btn btn-light btn-elevate"
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    ) : (
      <></>
    )
  );
}
