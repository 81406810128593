import styles from './landing/css/Landing.module.css';
import React , {useState} from 'react';
import { Field, Form, Formik } from 'formik/dist';
import * as Yup from 'yup';
import { useHistory, useLocation } from "react-router-dom"
import { Input, TextArea } from "../../../../_metronic/_partials/controls";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import BlockUi from 'react-block-ui';
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import localStorage from 'redux-persist/es/storage';

const Landing = () => {

  const [showLists, setShowLists] = useState('');
  const [buttonState, setButtonState] = useState('collapsed');
  const [ariaExpanded, setAriaExpanded] = useState(false);
  
  const showOrCollapse = () =>{ 
    if(showLists === "show")
    {
      setAriaExpanded(false);
      setShowLists('');
      setButtonState('collapsed');
    }
    else {
      setAriaExpanded(true);
      setButtonState('');
      setShowLists('show'); 
    }
    
  };

const collapseNav = () =>{
  if(showLists === "show")
  {
    setAriaExpanded(false);
    setShowLists('');
    setButtonState('collapsed');
  }
}


const history = useHistory();




  useEffect(() => {
    document.addEventListener('click', collapseNav);
    return () => {
      document.removeEventListener('click', collapseNav);
    };
  });
  
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  const validation = Yup.object().shape({
    senderAddress: Yup.string().email('Invalid email format').trim().required("Email is required"),
    senderName: Yup.string().trim().required("Name is required"), 
    message: Yup.string().trim().required('Message cannot be empty')
  });

  const [loading,setLoading] = useState(true);
  async function sendEmail (values) {
    try {
      const {message, senderName, senderAddress,subject} = values
      if(!message || !senderAddress) throw new Error("Enquiries Failed")
          
      const response = await axios.post(`${process.env.REACT_APP_EMAIL_SENDER_API}send_contact_message`, {
        senderName: senderName,
        senderAddress: senderAddress,
        subject:subject,
        message: message
      })

      if(response.status === 200){
        toast.success("Email sent successfully!");
        setTimeout(() => {
          window.history.go(-1);
        }, "1000");
        setLoading(false);
      } else{
        toast.error("Sending failed!");
        setLoading(false);
      }     
    } 
    catch(error) {
      setLoading(false);
      toast.error("Sending failed due to network error!");
      const url = `${process.env.REACT_APP_AUTO_DEALER_API}logger`
      const data ={
          username:localStorage.currentUserEmail,
          currentUrl:window.location.href,
          error:error?.stack,
          errorInfo:error?.message || error?.response?.data?.message || error
      }
      axios.post(url,
          data
      ).catch(function () {
          console.log('CAN NOT LOG ERROR')
      });
    }
  };



    return (
        <div>
          <ToastContainer autoClose={1000}/>
             <>

             
  <meta name="viewport" content="width=device-width" />
  <meta charSet="UTF-8" />
  <title>Autodealer - Car Dealership Management Software</title>
  <meta name="keywords" content="autodealer, car dealership management software, stock management, sales management, inspection management" />
  <meta name="description" content="Autodealer is a comprehensive car dealership management software that streamlines stock management, sales, proforma, and inspection processes. Sign up for a free trial today and take your dealership to the next level." />
  <meta name="author" content="http://dulcons.com" />
  <link rel="shortcut icon" href="./favi.ico" />
  <meta name="next-head-count" content={7} />

  

  <div id={[styles['__next']].join(' ')}>
    {/* <div id={[styles.loading].join(' ')}>
      <div id={[styles['loading-center']].join(' ')}>
        <div className={[styles.loader].join(' ')}>
          <div className={[styles.cube].join(' ')}>
            <div className={[styles.sides].join(' ')}>
              <div className={[styles.top].join(' ')} />
              <div className={[styles.right].join(' ')} />
              <div className={[styles.bottom].join(' ')} />
              <div className={[styles.left].join(' ')} />
              <div className={[styles.front].join(' ')} />
              <div className={[styles.back].join(' ')} />
            </div>
          </div> 
        </div>
      </div>
    </div> */}
    <header id={[styles['main-header']].join(' ')} className={[styles['p-0']].join(' ')}>
      <div
        className={[styles['container-fluid'],styles['pr-5']].join(' ')}
        style={{ 
          backgroundColor: "rgb(11,183,131)",
          animationName: "fade",
          animationDuration: "2s",
          animationTimingFunction: "ease-in-out"
      }}>

        <div className={[styles.row].join(' ')} >
          <div className={[styles['col-sm-12']].join(' ')}>
            <nav className={[styles.navbar, styles['navbar-expand-lg'],styles['mt-1'],styles['mb-1'], styles['navbar-light']].join(' ')}>

              <a className={[styles['pt-1'],styles['pb-1']].join(' ')} href="/">
                <img
                  className={[styles['img-fluid']].join(' ')}
                  id={[styles['new_image']].join(' ')}
                  src="./media/logos/logo-letter-9-resized.png"
                  alt="#"
                />
              </a>
              <button
                className={[styles['navbar-toggler'],styles[`${buttonState}`]].join(' ')}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded={ariaExpanded}
                aria-label="Toggle navigation"
                onClick={showOrCollapse}
                id="collapseButton"
              >
                <span className={[styles['ion-navicon']].join(' ')} />
              </button>
              <div
                className={[styles['navbar-collapse'],styles['collapse'],styles[`${showLists}`]].join(' ')}
                id={[styles.navbarSupportedContent].join(' ')}
              >
                <ul className={[styles['navbar-nav'], styles['mr-auto'],styles['mb-1'],styles['mt-1'], styles['w-100'], styles['justify-content-end'], styles.menu].join(' ')}>
                  <li className={[styles['nav-item'],styles['p-0'], styles['menu-item']].join(' ')}>
                    <a className={[ styles['nav-link']].join(' ')} href="#iq-home">
                      Home
                    </a>
                  </li>
                  <li className={[styles['nav-item'],styles['p-0'], styles['menu-item']].join(' ')}>
                    <a className={[styles['nav-link']].join(' ')} href="#how-it-works">
                      About
                    </a>
                  </li>
                  <li className={[styles['nav-item'],styles['p-0'], styles['menu-item']].join(' ')}>
                    <a className={[styles['nav-link']].join(' ')} href="#software-features">
                      Services
                    </a>
                  </li>
                  <li className={[styles['nav-item'],styles['p-0'], styles['menu-item']].join(' ')}> 
                    <a className={[styles['nav-link']].join(' ')} href="#pricing"> 
                      Pricing
                    </a>
                  </li>
                  <li className={[styles['nav-item'],styles['p-0'], styles['menu-item']].join(' ')}>
                    <a className={[styles['nav-link']].join(' ')} href="#contact">
                      Contact
                    </a>
                  </li>
                  <li className={[styles['d-flex'],styles['flex-direction-row'],styles['p-0'], styles['menu-item']].join(' ')}>
                  <a className={[styles['nav-link'],styles['text-align-center'],styles['pl-3'],styles['pr-3']].join(' ')} href="/login">
                       <span className={[styles['btn'],styles['btn-light'],styles['text-dark'],styles['']].join(' ')}>Login</span>
                    </a>
                    <a className={[styles['nav-link'],styles['text-align-center'],styles['pl-3'],styles['pr-3']].join(' ')} href="/register">
                       <span className={[styles['btn'],styles['btn-warning'],styles['text-dark'],styles['']].join(' ')}>Register</span>
                    </a>
                  </li>

                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <div
      id="iq-home"
      data-parallax="true"
      className={[styles['iq-banner'], styles['overview-block-pt'], styles['vh-100'],styles['iq-bg-over'], styles['iq-over-blue-90'], styles['iq-parallax'], styles.jarallax].join(' ')}
      style={{ backgroundImage: "url(/assets/images/bg/01.jpg)" }}
    >
      <div className={[styles['container-fluid']].join(' ')}>
        <div className={[styles['banner-text']].join(' ')} style={{marginTop: '6%',marginBottom: '5%'}}>
          <div className={[styles.row].join(' ')}>
            <div className={[styles['col-lg-6']].join(' ')}>
              <h2 className={[styles['text-uppercase'], styles['iq-font-dark'], styles['banner-text']].join(' ')} style={{fontSize: "4rem"}}>
              Revolutionize your car dealership management with  <b className={[styles['iq-tw-7']].join(' ')}>Autodealer</b>
              </h2> 
              <h6 className={[styles['iq-font-dark'], styles['iq-pt-15'], styles['iq-mb-40'],styles['lead']].join(' ')}>
              Autodealer streamlines car dealership management, with easy stock and sales management from anywhere.
              </h6>
              {/* Youtube play button start */}

              {/* Youtube play button end */}

              <a href="/register" className={[styles.button, styles['bt-black'], styles['iq-mt-5'], styles['iq-ml-40']].join(' ')}>
                Get Started
              </a>
            </div>
            <div className={[styles['col-lg-6']].join(' ')}>  
              <img
                className={[styles['banner-img']].join(' ')}
                src="./landing/images/banner-01-auto.png"
                alt="drive"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={[styles['banner-objects']].join(' ')}>
        <span
          className={[styles['banner-objects-01']].join(' ')}
          data-bottom="transform:translatey(50px)"
          data-top="transform:translatey(-50px);"
        >
          <img src="./landing/images/drive-03.png" alt="drive02" />
        </span>
        <span className={[styles['banner-objects-02'], styles['iq-fadebounce']].join(' ')}>
          <span className={[styles['iq-round']].join(' ')} />
        </span>
        <span className={[styles['banner-objects-03'], styles['iq-fadebounce']].join(' ')}>
          <span className={[styles['iq-round']].join(' ')} />
        </span>
      </div>
    </div>
    <div className={[styles['main-content']].join(' ')}>

      <section className={[styles['how-works'], styles['overview-block-ptb']].join(' ')}>
        <div className={[styles.container].join(' ')}>
          <div className={[styles.row, styles['flex-row-reverse']].join(' ')}>
            <div className={[styles['col-lg-6'], styles['align-self-center']].join(' ')}>
              <img
                className={[styles['iq-works-img'], styles['pull-right'], styles['img-fluid']].join(' ')}
                src="./landing/images/drive-01-auto.png"
                alt="drive01"
              />
            </div>
            <div className={[styles['col-lg-6']].join(' ')}>

          <div className={[styles.row].join(' ')}>
            <div className={[styles['col-sm-12']].join(' ')}>
              <div className={[styles['heading-title'], styles.undefined].join(' ')}>
                <h3 className={[styles.title, styles['iq-tw-7']].join(' ')}>What is Autodealer ?</h3>
                <p>
                  Autodealer is your Stock Management, Sales, Proforma & Inspection Solution.
                </p>
              </div>
            </div>
          </div>
          <div className={[styles.row].join(' ')}>
            <div className={[styles['col-md-12'], styles['col-lg-6']].join(' ')}>
              <div className={[styles['iq-works-box'], styles['text-left']].join(' ')}>
                <div className={[styles.step].join(' ')}>1</div>
                <div className={[styles['icon-bg'], styles['text-center']].join(' ')}>
                  <img
                    src="./landing/images/services-icon1.png"
                    className={[styles['img-fluid']].join(' ')}
                    alt="#"
                  />
                </div>
                <h5 className={[styles['iq-tw-7'], styles['iq-mt-25'], styles['iq-mb-15']].join(' ')}>Stock management</h5>
                <p>
                With Autodealer, you can easily manage all your cars in different warehouses.
                </p>
              </div>
            </div>
            <div className={[styles['col-md-12'], styles['col-lg-6'], styles['r-mt-30']].join(' ')}>
              <div className={[styles['iq-works-box'], styles['text-left']].join(' ')}>
                <div className={[styles.step].join(' ')}>2</div>
                <div className={[styles['icon-bg'], styles['text-center']].join(' ')}>
                  <img
                    src="./landing/images/services-icon2.png"
                    className={[styles['img-fluid']].join(' ')}
                    alt="#"
                  />
                </div>
                <h5 className={[styles['iq-tw-7'], styles['iq-mt-25'], styles['iq-mb-15']].join(' ')}>
                Sales management
                </h5>
                <p>
                Autodealer helps you manage your transactions and keep track of recent sales.
                </p>
              </div>
            </div>
            <div className={[styles['col-md-12'], styles['col-lg-8'], styles['r-mt-30']].join(' ')}>
              <div className={[styles['iq-works-box'], styles['text-left']].join(' ')}>
                <div className={[styles.step].join(' ')}>3</div>
                <div className={[styles['icon-bg'], styles['text-center']].join(' ')}>
                  <img
                    src="./landing/images/services-icon3.png"
                    className={[styles['img-fluid']].join(' ')}
                    alt="#"
                  />
                </div>
                <h5 className={[styles['iq-tw-7'], styles['iq-mt-25'], styles['iq-mb-15']].join(' ')}>Proformas and Insightful Reports</h5>
                <p>
                Autodealer generates proformas and notifies you of overdues and uncollected payments. 
                It also allows you to generate reports of your stock, sales, bank deposits, and uncollected payments.
                </p>
              </div>
            </div>
          </div>

            </div>
            <div className={[styles['iq-objectsnew']].join(' ')}>
              <span
                className={[styles['iq-objects-02']].join(' ')}
                data-bottom="transform:translatey(50px)"
                data-top="transform:translatey(-100px);"
              >
                <img
                  src="./landing/images/drive-03.png"
                  className={[styles['img-fluid']].join(' ')}
                  alt="drive02"
                />
              </span>
              <span
                className={[styles['iq-objects-03']].join(' ')}
                data-bottom="transform:translatex(50px)"
                data-top="transform:translatex(-100px);"
              >
                <img
                  src="./landing/images/drive-04.png"
                  className={[styles['img-fluid']].join(' ')}
                  alt="drive02"
                />
              </span>
              <span className={[styles['iq-objects-04'], styles['iq-fadebounce']].join(' ')}>
                <span className={[styles['iq-round']].join(' ')} />
              </span>
            </div>
          </div>
        </div>
      </section>
      <section id="software-features" className={[styles['overview-block-ptb'], styles['how-works']].join(' ')}>
        <div className={[styles.container].join(' ')}>
          <div className={[styles.row].join(' ')}>
            <div className={[styles['iq-software-demo']].join(' ')}>
              <img
                className={[styles['img-fluid']].join(' ')}
                src="./landing/images/drive-05-auto.png"
                alt="drive05"
              />
            </div>
            <div className={[styles['col-lg-6'], styles['offset-lg-6']].join(' ')}>
              <div className={[styles['heading-title'], styles.left, styles['text-left']].join(' ')}>
                <h3 className={[styles['iq-tw-7'], styles['iq-mb-25'], styles.title].join(' ')}>Services</h3>
              </div>
              <p>
                Autodealer provides you with many features that will help you to
                keep track and keep records of your business.
              </p>
              <ul className={[styles['iq-mt-40'], styles['iq-list']].join(' ')}>
                <li className={[styles['iq-tw-6'], styles['iq-mb-15']].join(' ')}>
                  <i className={[styles['ion-android-done-all'], styles['iq-mr-10'], styles['iq-font-blue'], styles['iq-font-30']].join(' ')} />
                  <span className={[styles['iq-font-black']].join(' ')}>
                    Automatically stay on top of stock levels by receiving automatic stock level updates when orders are made. 
                  </span>
                </li>
                <li className={[styles['iq-tw-6'], styles['iq-mb-15']].join(' ')}>
                  <i className={[styles['ion-android-done-all'], styles['iq-mr-10'], styles['iq-font-blue'], styles['iq-font-30']].join(' ')} />
                  <span className={[styles['iq-font-black']].join(' ')}>
                    Effortlessly create Proformas and get notification on overdue proformas.
                  </span>
                </li>
                <li className={[styles['iq-tw-6'], styles['iq-mb-15']].join(' ')}>
                  <i className={[styles['ion-android-done-all'], styles['iq-mr-10'], styles['iq-font-blue'], styles['iq-font-30']].join(' ')} />
                  <span className={[styles['iq-font-black']].join(' ')}>
                    Generate reports to understand, dissect and optimize your business with insightful reports.
                  </span>
                </li>
                <li className={[styles['iq-tw-6'], styles['iq-mb-15']].join(' ')}>
                  <i className={[styles['ion-android-done-all'], styles['iq-mr-10'], styles['iq-font-blue'], styles['iq-font-30']].join(' ')} />
                  <span className={[styles['iq-font-black']].join(' ')}>
                    Stay updated anytime, anywhere. You can take your business with you no matter where you go.
                  </span>
                </li>
                <li className={[styles['iq-tw-6'], styles['iq-mb-15']].join(' ')}>
                  <i className={[styles['ion-android-done-all'], styles['iq-mr-10'], styles['iq-font-blue'], styles['iq-font-30']].join(' ')} />
                  <span className={[styles['iq-font-black']].join(' ')}>
                    Provides Smart invertory features to help you know what customers want and stock the right inventory to drive sales.
                  </span>
                </li>
                <li className={[styles['iq-tw-6'], styles['iq-mb-15']].join(' ')}>
                  <i className={[styles['ion-android-done-all'], styles['iq-mr-10'], styles['iq-font-blue'], styles['iq-font-30']].join(' ')} />
                  <span className={[styles['iq-font-black']].join(' ')}>
                    Data is stored in the cloud through your Autodealer account and is secured using current industry standards.
                  </span>
                </li>
                <li className={[styles['iq-tw-6'], styles['iq-mb-15']].join(' ')}>
                  <i className={[styles['ion-android-done-all'], styles['iq-mr-10'], styles['iq-font-blue'], styles['iq-font-30']].join(' ')} />
                  <span className={[styles['iq-font-black']].join(' ')}>
                    Manage your business on any computer, tablet, iOS or Android device.
                  </span>
                </li>
              </ul>
            </div>
            <div className={[styles['iq-objects-software']].join(' ')}>
              <span
                className={[styles['iq-objects-01']].join(' ')}
                data-bottom="transform:translatey(50px)"
                data-top="transform:translatey(-100px);"
              >
                <img src="./landing/images/drive-03.png" alt="drive02" />
              </span>
              <span
                className={[styles['iq-objects-02']].join(' ')}
                data-bottom="transform:translatex(50px)"
                data-top="transform:translatex(-100px);"
              >
                <img src="./landing/images/drive-04.png" alt="drive02" />
              </span>
              <span className={[styles['iq-objects-03'], styles['iq-fadebounce']].join(' ')}>
                <span className={[styles['iq-round']].join(' ')} />
              </span>
            </div>
          </div>
        </div>
      </section>
      {/* Counter section begin */}
          {/* If app download , app rate and active user is needed use from the file reduced_sections.js */}
      {/* Counter section end */}
      <section
        id="pricing"
        className={[styles['overview-block-ptb'], styles['grey-bg'], styles['iq-price-table']].join(' ')}
      >
        <div className={[styles.container].join(' ')}>
          <div className={[styles.row].join(' ')}>
            <div className={[styles['col-sm-12']].join(' ')}>
              <div className={[styles['heading-title']].join(' ')}>
                <h3 className={[styles.title, styles['iq-tw-7']].join(' ')}>Affordable Price</h3>
                <p>Choose the best option for your business</p>
              </div>
            </div>
          </div>
          <div className={[styles.row].join(' ')}>
            <div className={[styles['col-md-4'], styles['col-lg-4']].join(' ')}>
              <div className={[styles['iq-pricing'], styles['text-center']].join(' ')}>
                <div className={[styles['iq-pricing'], styles['text-center']].join(' ')}>
                  <div
                    data-parallax="true"
                    className={[styles['price-title'], styles['iq-parallax'], styles['iq-over-blue-80'], styles.jarallax].join(' ')}
                    style={{ backgroundImage: "url(/assets/images/bg/08.jpg)" }}
                  >
                    <h2 className={[styles['iq-font-dark'], styles['iq-tw-7']].join(' ')}>
                      <small className="text-dark">Birr</small>499<small className="text-dark">/Month</small>
                    </h2>
                    <span className={[styles['text-uppercase'], styles['iq-tw-4'], styles['iq-font-dark']].join(' ')}>
                      BASIC
                    </span>
                  </div>
                  <ul>
                    <li>
                      Unlimited Disk Space
                    </li>

                    <li>
                      Customer Support 24/7
                    </li>
                  </ul>
                  <div className={[styles['price-footer']].join(' ')}>
                    <a className={[styles.button].join(' ')} href="/login">
                      Purchase
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={[styles['col-md-4'], styles['col-lg-4'], styles.wow, styles.flipInY, styles['r4-mt-30']].join(' ')}
              data-wow-duration="1s"
            >
              <div className={[styles['iq-pricing'], styles['text-center']].join(' ')}>

                <div
                  data-parallax="true"
                  className={[styles['price-title'], styles['iq-parallax'], styles['iq-over-blue-80'], styles.jarallax].join(' ')}
                  style={{ backgroundImage: "url(/assets/images/bg/08.jpg)" }}
                >
                  <h2 className={[styles['iq-font-dark'], styles['iq-tw-7']].join(' ')}>
                    <small className="text-dark">Birr</small>799<small className="text-dark">/Month</small>
                  </h2>
                  <span className={[styles['text-uppercase'], styles['iq-tw-4'], styles['iq-font-dark']].join(' ')}>
                    STANDARD
                  </span>
                </div>
                <ul>
                    <li>
                      Unlimited Disk Space
                    </li>
                    <li>
                      Customer Support 24/7
                    </li>
                  </ul>
                <div className={[styles['price-footer']].join(' ')}>
                  <a className={[styles.button].join(' ')} href="/login">
                    Purchase
                  </a>
                </div>
              </div>
            </div>
            <div className={[styles['col-md-4'], styles['col-lg-4']].join(' ')}>
              <div className={[styles['iq-pricing'], styles['text-center']].join(' ')}>
                <div
                  data-parallax="true"
                  className={[styles['price-title'], styles['iq-parallax'], styles['iq-over-blue-80'], styles.jarallax].join(' ')}
                  style={{ backgroundImage: "url(/assets/images/bg/08.jpg)" }}
                >
                  <h2 className={[styles['iq-font-dark'], styles['iq-tw-7']].join(' ')}>
                    <small className="text-dark">Birr</small>999<small className="text-dark">/Month</small>
                  </h2>
                  <span className={[styles['text-uppercase'], styles['iq-tw-4'], styles['iq-font-dark']].join(' ')}>
                    BUSINESS PLUS
                  </span>
                </div>
                <ul>
                    <li>
                      Unlimited Disk Space
                    </li>
                    <li>
                      Customer Support 24/7
                    </li>
                  </ul>
                <div className={[styles['price-footer']].join(' ')}>
                  <a className={[styles.button].join(' ')} href="/login">
                    Purchase
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
{/* Compare services start */}
{/* If app download , app rate and active user is needed use from the file reduced_sections.js */}
{/*  Compare services end */}

      <section className={[styles['overview-block-ptb'], styles['white-bg'], styles['iq-asked']].join(' ')}>
        <div className={[styles.container].join(' ')}>
          <div className={[styles.row].join(' ')}>
            <div className={[styles['col-sm-12']].join(' ')}>
              <div className={[styles['heading-title']].join(' ')}>
                <h3 className={[styles.title, styles['iq-tw-7']].join(' ')}>Frequently Asked Questions</h3>
                <p>
                  Here you can find solution to your questions or queries.
                </p>
              </div>
            </div>
          </div>
          <div className={[styles.row, styles['align-items-center']].join(' ')}>
            <div className={[styles['col-md-12'], styles['col-lg-6']].join(' ')}>
              <img
                className={[styles['img-fluid'], styles['center-block']].join(' ')}
                src="./landing/images/drive-10-auto.png"
                alt="drive10"
                style={{ zIndex: 9, position: "relative" }}
              />
            </div>
            <div className={[styles['col-md-12'], styles['col-lg-6']].join(' ')}>
              <div className={[styles['iq-accordion']].join(' ')}>
                <div className={[styles['iq-accordion'], styles['accordion-active']].join(' ')}>
                  <p  className={[styles['accordion-title'], styles['iq-tw-7'], styles['iq-font-grey']].join(' ')}>
                  How secure is my data with Autodealer?
                  </p>
                  <div className={[styles['accordion-details']].join(' ')} style={{display :'block'}}>
                  We take data security seriously. All data is stored on secure cloud servers, and we use industry-standard encryption to protect your data.
                  </div>
                </div>
                <div className={[styles['iq-accordion'],styles['accordion-active']].join(' ')}>
                  <p  className={[styles['accordion-title'], styles['iq-tw-7'], styles['iq-font-grey']].join(' ')}>
                  How long does it take to set up Autodealer?
                  </p>
                  <div className={[styles['accordion-details']].join(' ')} style={{display :'block'}}>
                  Setting up Autodealer is quick and easy. Most customers are up and running within few minutes.
                  </div>
                </div>
                <div className={[styles['iq-accordion'],styles['accordion-active']].join(' ')}>
                  <p  className={[styles['accordion-title'], styles['iq-tw-7'], styles['iq-font-grey']].join(' ')}>
                  Can I access Autodealer from my phone or tablet?
                  </p>
                  <div className={[styles['accordion-details']].join(' ')} style={{display :'block'}}>
                  Yes, Autodealer is fully responsive, which means you can access it from any device with an internet connection.
                  </div>
                </div>
                <div className={[styles['iq-accordion'],styles['accordion-active']].join(' ')}>
                  <p className={[styles['accordion-title'], styles['iq-tw-7'], styles['iq-font-grey']].join(' ')}>
                  What happens after the 1-month trial period?  
                  </p>
                  <div className={[styles['accordion-details']].join(' ')} style={{display :'block'}}>
                  You can continue using Autodealer by subscribing to one of our paid plans.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={[styles['iq-objects-asked']].join(' ')}>
          <span className={[styles['iq-objects-01']].join(' ')}>
            <img src="./landing/images/drive-02.png" alt="drive02" />
          </span>
          <span
            className={[styles['iq-objects-02']].join(' ')}
            data-bottom="transform:translatex(50px)"
            data-top="transform:translatex(-100px);"
          >
            <img src="./landing/images/drive-04.png" alt="drive02" />
          </span>
          <span className={[styles['iq-objects-03'], styles['iq-fadebounce']].join(' ')}>
            <span className={[styles['iq-round']].join(' ')} />
          </span>
        </div>
      </section>
   
      <div className={[styles['iq-our-clients'], styles['white-bg'], styles['iq-ptb-50']].join(' ')}>
        <div className={[styles.container].join(' ')}>
          <div className={[styles.row].join(' ')}>
            <div className={[styles['col-lg-12'], styles['col-md-12']].join(' ')}>
              <div className={[styles['owl-carousel'], styles['owl-theme']].join(' ')}>
                <div className={[styles.item].join(' ')}>
                  <img
                    className={[styles['img-fluid'], styles['center-block']].join(' ')}
                    src="./landing/images/clients-01.png"
                    alt="#"
                  />
                </div>
                <div className={[styles.item].join(' ')}>
                  <img
                    className={[styles['img-fluid'], styles['center-block']].join(' ')}
                    src="./landing/images/clients-02.png"
                    alt="#"
                  />
                </div>
                <div className={[styles.item].join(' ')}>
                  <img
                    className={[styles['img-fluid'], styles['center-block']].join(' ')}
                    src="./landing/images/clients-03.png"
                    alt="#"
                  />
                </div>
                <div className={[styles.item].join(' ')}>
                  <img
                    className={[styles['img-fluid'], styles['center-block']].join(' ')}
                    src="./landing/images/clients-04.png"
                    alt="#"
                  />
                </div>
                <div className={[styles.item].join(' ')}>
                  <img
                    className={[styles['img-fluid'], styles['center-block']].join(' ')}
                    src="./landing/images/clients-05.png"
                    alt="#"
                  />
                </div>
                <div className={[styles.item].join(' ')}>
                  <img
                    className={[styles['img-fluid'], styles['center-block']].join(' ')}
                    src="./landing/images/clients-06.png"
                    alt="#"
                  />
                </div>
                <div className={[styles.item].join(' ')}>
                  <img
                    className={[styles['img-fluid'], styles['center-block']].join(' ')}
                    src="./landing/images/clients-07.png"
                    alt="#"
                  />
                </div>
                <div className={[styles.item].join(' ')}>
                  <img
                    className={[styles['img-fluid'], styles['center-block']].join(' ')}
                    src="./landing/images/clients-09.png"
                    alt="#"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
   {/*  Newsletter START */}
{/* If app download , app rate and active user is needed use from the file reduced_sections.js */}
   {/* Newsletter END */}
      <section id="contact" className={[styles['footer-info'], styles['white-bg']].join(' ')}>
        <div className={[styles.container].join(' ')}>
          <div className={[styles.row, styles['justify-content-end']].join(' ')}>
            <div className={[styles['col-md-6'], styles['col-lg-4']].join(' ')}>
              <div className={[styles['iq-get-in'],styles['pb-0'],styles['pt-4']].join(' ')}>
                <h4 className={[styles['iq-tw-7'], styles['iq-mb-20']].join(' ')}>Get in Touch</h4>
                <Formik
        enableReinitialize={true}
        initialValues={{senderName : "", senderAddress:"",subject:"",message:""}}
        validationSchema={validation}
        onSubmit={(values) => {
          setLoading(true);
          sendEmail(values)
        }}
      >
        {({ handleSubmit,isSubmitting}) => (
          <BlockUi blocking={(isSubmitting && loading)}>
            <Form className="form form-label-right">
              <div className="card-body p-0">
                <div className="row">
                <div className="order-2 order-lg-1  ">
                  <div className="form-group">
                    <Field
                      name="senderName"
                      component={Input}
                      placeholder="Enter Your Name"
                      label="Name"
                      withFeedbackLabel= {true}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="senderAddress"
                      component={Input}
                      placeholder="Enter Your Email"
                      label="Email"
                      withFeedbackLabel= {true}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="subject"
                      component={Input}
                      placeholder="Enter The Subject"
                      label="Subject"
                      withFeedbackLabel= {true}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="message"
                      component={TextArea}
                      label="Your Message"
                      height='100px'
                      col = 'col-md-6'
                      key="remark"
                    />
                  <span className="form-text text-muted">We'll never share your email with anyone else.</span>
                  </div>
                </div>
          
                </div>
              </div>
            </Form>
            <div className="card-footer pb-4 pt-1">
              <div className="row">
                <div className="">
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate mr-1"
                  >
                    Send
                  </button>
                  <a className="btn btn-secondary"
                    onClick={() => {
                      window.history.go(-1);
                    }}
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          </BlockUi>
        )}
      </Formik>
              </div>
            </div>
          </div>
        </div>
        <iframe
          className={[styles.map].join(' ')}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.5876212580297!2d38.778387417443845!3d9.010038600000009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b850a39eb5b3f%3A0x65997316c3ca1898!2zTkIgQnVzaW5lc3MgQ2VudGVyIHwgSGF5YWh1bGV0IENoaWNoaW55YSB8IOGKpOGKlSDhiaIg4Yuo4YyI4Ymg4YurIOGIm-GLleGKqOGIjSB8IOGIg-GLq-GIgeGIiOGJtSDhibrhibrhipXhi6s!5e0!3m2!1sen!2sus!4v1681304950349!5m2!1sen!2sus"  loading="lazy" 
          style={{ border: 0 }}
          allowFullScreen=""
        />
      </section>
      <section className={[styles['footer-info'], styles['iq-pt-100']].join(' ')}>
        <div className={[styles.container].join(' ')}>
          <div className={[styles.row].join(' ')}>
            <div className={[styles['col-md-6'], styles['col-lg-3']].join(' ')}>
              <div className={[styles['iq-footer-box'], styles['text-left']].join(' ')}>
                <div className={[styles['iq-icon']].join(' ')}>
                  <i aria-hidden="true" className={[styles['ion-ios-location-outline']].join(' ')} />
                </div>
                <div className={[styles['footer-content']].join(' ')}>
                  <h4 className={[styles['iq-tw-7'], styles['iq-pb-10']].join(' ')}>Address</h4>
                  <p>Mickey Leland Street, Addis Ababa, Ethiopia</p>
                </div>
              </div>
            </div>
            <div className={[styles['col-md-6'], styles['col-lg-3'], styles['r4-mt-30']].join(' ')}>
              <div className={[styles['iq-footer-box'], styles['text-left']].join(' ')}>
                <div className={[styles['iq-icon']].join(' ')}>
                  <i aria-hidden="true" className={[styles['ion-ios-telephone-outline']].join(' ')} />
                </div>
                <div className={[styles['footer-content']].join(' ')}>
                  <h4 className={[styles['iq-tw-7'], styles['iq-pb-10']].join(' ')}>Phone</h4>
                  <p>
                    +251-11-666-3237
                    <br />
                    Mon-Fri 8:00am - 5:00pm
                  </p>
                </div>
              </div>
            </div>
            <div className={[styles['col-md-6'], styles['col-lg-3'], styles['r-mt-30']].join(' ')}>
              <div className={[styles['iq-footer-box'], styles['text-left']].join(' ')}>
                <div className={[styles['iq-icon']].join(' ')}>
                  <i aria-hidden="true" className={[styles['ion-ios-email-outline']].join(' ')} />
                </div>
                <div className={[styles['footer-content']].join(' ')}>
                  <h4 className={[styles['iq-tw-7'], styles['iq-pb-10']].join(' ')}>Mail</h4>
                  <p>
                    support@autdll.com 
                    <br />
                    Online support
                  </p>
                </div>
              </div>
            </div>
            <div className={[styles['col-md-6'], styles['col-lg-3'], styles['r-mt-30']].join(' ')}>
              <ul className={[styles['info-share']].join(' ')}>
                <li>
                  <a href="#">
                    <i className={[styles.fab, styles['fa-twitter']].join(' ')} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className={[styles.fab, styles['fa-facebook']].join(' ')} />
                  </a>
                </li>
                <li>
                  <a href="mailto:support@autdll.com">
                    <i className={[styles.fab, styles['fa-google']].join(' ')} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </footer>
    <div id={[styles['back-to-top']].join(' ')} style={{ display: "none" }}>
      <a className={[styles.top].join(' ')} id={[styles.top].join(' ')} href="#">
        {" "}
        <i className={[styles['ion-ios-upload-outline']].join(' ')} />{" "}
      </a>
    </div>
  </div>
</>

        </div>
    )
}

export default Landing

