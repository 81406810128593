import React, { useEffect, useState,useLayoutEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import * as salesActions from "../../_redux/sales/salesActions";
import * as supplierActions from "../../_redux/suppliers/suppliersActions";
import moment from 'moment'

let initialFilter = {
    filter: {
        category: "",
        batch: "",
        start_date: "",
        end_date: "",
        inProcess: "1",
        processed: "1",
        year: "",
        color: "",
        group_batch: "",
    }
};

const columns = [
    {
        dataField: "date",
        text: "Date",
        classes: "text-nowrap border-0",
        headerClasses: "border-0",
        formatter: (cellContent, row) => (
            <span>
                { moment(row.date).format("MMM DD") }
            </span>
        )
    },
    {
      dataField: "model",
      text: "Brand",
      classes: "border-0", 
      headerClasses: "border-0"
    },
    {
      dataField: "amount",
      text: "Price",
      classes: "text-right border-0",
      headerClasses: "text-right border-0",
      formatter: (cellContent, row) => (
        <span>
          {row.amount
          .toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          })
          }
        </span>
      ),
    },
    {
      dataField: "collected",
      text: "Collected",
      classes: "text-right border-0",
      headerClasses: "text-right border-0",
      formatter: (cellContent, row) => (
        <span>
          {row.collected
          .toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          })
          }
        </span>
      ),
    },
];

const RecentSalesDashboard = ({period="week"}) => {
    switch(period) {
        case "week":
            return <WeeklySales/>
        case "day":
            return <DailySales/>
        case "month":
            return <MonthlySales />
    }
}

const WeeklySales = () => {
    const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
    const endOfWeek   = moment().endOf('week').format('YYYY-MM-DD');
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');

    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const { salesDetailForReport: entities,error ,apiErrorData, mainSupplier} = useSelector(
        (state) => ({ 
            salesDetailForReport: state.sales.salesDetailForReport,
            error:state.sales.error,
            apiErrorData:state.sales.apiErrorData, 
            mainSupplier: state.suppliers.mainSupplier,
        }),
        shallowEqual
    );

    useEffect(() => {
        if(mainSupplier) {
			dispatch(salesActions.fetchSalesDetailForReport({...initialFilter, filter: {...initialFilter.filter, start_date: startOfWeek, end_date: endOfWeek, supplier: `${mainSupplier?.id}`}}))
		}
    }, [dispatch, mainSupplier]);

    useEffect(() => {
		dispatch(supplierActions.getMainSupplier())
	}, [dispatch])

    useLayoutEffect(()=>{
    
        if(error && apiErrorData === undefined){
            throw new Error(error);
        }else{
            if(error 
              && apiErrorData?.errorCode!="NOT_FOUND"
              && apiErrorData?.errorCode!="CONFLICT"){
              throw new Error(error);
            }
        }
      },[error])
    useEffect(() => {
        if(entities) {
            const e = [...entities]
            setTableData(e.sort((a, b) => (moment(a.date).isBefore(moment(b.date))) ? 1 : -1))
        }
    }, [entities])

    

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="dashboard-table table table-head-custom table-vertical-center overflow-hidden text-nowrap"
            bootstrap4
            remote
            keyField="id"
            data={tableData}
            columns={columns}
            rowStyle={{ cursor: 'pointer' }}
        />
    )
}

const DailySales = () => {
    const today = moment().format('YYYY-MM-DD');

    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const { salesDetailForReport: entities,error,apiErrorData, mainSupplier } = useSelector(
        (state) => ({ 
            salesDetailForReport: state.sales.salesDetailForReport,
            error:state.sales.error,
            apiErrorData:state.sales.apiErrorData, 
            mainSupplier: state.suppliers.mainSupplier
        }),
        shallowEqual
    );

    useEffect(() => {
        if(mainSupplier) {
            dispatch(salesActions.fetchSalesDetailForReport({...initialFilter, filter: {...initialFilter.filter, start_date: today, end_date: today, supplier: `${mainSupplier?.id}`}}))
        }
    }, [dispatch, mainSupplier]);

    useEffect(() => {
		dispatch(supplierActions.getMainSupplier())
	}, [dispatch])

    useLayoutEffect(()=>{
    
        if(error && apiErrorData === undefined){
            throw new Error(error);
        }else{
            if(error 
              && apiErrorData?.errorCode!="NOT_FOUND"
              && apiErrorData?.errorCode!="CONFLICT"){
              throw new Error(error);
            }
        }
      },[error])
    useEffect(() => {
        if(entities) {
            const e = [...entities]
            setTableData(e.sort((a, b) => (moment(a.date).isBefore(moment(b.date))) ? 1 : -1))
        }
    }, [entities])

    
    
    

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="dashboard-table table table-head-custom table-vertical-center overflow-hidden text-nowrap"
            bootstrap4
            remote
            keyField="id"
            data={tableData}
            columns={columns}
            rowStyle={{ cursor: 'pointer' }}
        />
    )
}



const MonthlySales = () => {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');

    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const { salesDetailForReport: entities,error ,apiErrorData, mainSupplier} = useSelector(
        (state) => ({ 
            salesDetailForReport: state.sales.salesDetailForReport,
            error:state.sales.error,
            apiErrorData:state.sales.apiErrorData, 
            mainSupplier: state.suppliers.mainSupplier,
        }),
        shallowEqual
    );

    useEffect(() => {
        if(mainSupplier) {
			dispatch(salesActions.fetchSalesDetailForReport({...initialFilter, filter: {...initialFilter.filter, start_date: startOfMonth, end_date: endOfMonth, supplier: `${mainSupplier?.id}`}}))
		}
    }, [dispatch, mainSupplier]);

    useEffect(() => {
		dispatch(supplierActions.getMainSupplier())
	}, [dispatch])

    useLayoutEffect(()=>{
    
        if(error && apiErrorData === undefined){
            throw new Error(error);
        }else{
            if(error 
              && apiErrorData?.errorCode!="NOT_FOUND"
              && apiErrorData?.errorCode!="CONFLICT"){
              throw new Error(error);
            }
        }
      },[error])
    useEffect(() => {
        if(entities) {
            const e = [...entities]
            setTableData(e.sort((a, b) => (moment(a.date).isBefore(moment(b.date))) ? 1 : -1))
        }
    }, [entities])

    

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="dashboard-table table table-head-custom table-vertical-center overflow-hidden text-nowrap"
            bootstrap4
            remote
            keyField="id"
            data={tableData}
            columns={columns}
            rowStyle={{ cursor: 'pointer' }}
        />
    )
}



export default RecentSalesDashboard