import Config from "./Config";
import axios from "axios";

class NotificationsApi {
    constructor() {
        this.Config = new Config();
    }

    async getNotificationCount(area, seen) {
        try {
            await this.Config.checkExpirationOfToken();
            const response = await axios.get(`${this.Config.COUNT}?username=${localStorage.getItem('currentUserEmail')}&area=${area}&seen=${seen}`, this.Config.headersWithAuthorization())
            return response.data;
        } catch (error) {
            return 0;
        }
    }

    async getNotificationsByUsername(includeSeen, area, page, size) {
        try {
            await this.Config.checkExpirationOfToken();
            const response = await axios.get(`${this.Config.BY_USER_NAME}?username=${localStorage.getItem('currentUserEmail')}&includeSeen=${includeSeen}&area=${area}&page=${page}&size=${size}`, this.Config.headersWithAuthorization())
            return response.data;
        } catch (error) {
            return {content:[]}
        }

    }

    async setSeenNotification(id) {
        try {
            await this.Config.checkExpirationOfToken();
            const response = await axios.put(`${this.Config.SEEN}?username=${localStorage.getItem('currentUserEmail')}&notificationId=${id}`, {}, this.Config.headersWithAuthorization())
            return response.data;
        } catch (error) {

        }

    }

    async markAllSeen(area='alert') {
        try {
            await this.Config.checkExpirationOfToken();
            const response = await axios.put(`${this.Config.SEENALL}?username=${localStorage.getItem('currentUserEmail')}&area=${area}`, {}, this.Config.headersWithAuthorization())
            return response.data;
        } catch (error) {

        }

    }

}

export default NotificationsApi;