import axios from "axios";

export const SPECIFICATIONS_URL = "api/locations";
export const URL = "http://localhost:8080/api/locations";
export const lOCATION_URL = process.env.REACT_APP_AUTO_DEALER_API+'locations';

export function getAllLocations(){
  return axios.get(lOCATION_URL)
}
// CREATE =>  POST: add a new locations to the server
export function createLocation(location) {
  return axios.post(lOCATION_URL,  location );
}

// READ
// Server should return filtered locations by locationId
export function getAllProductLocationsByLocationId(locationId) {
  return axios.get(`${lOCATION_URL}?locationId=${locationId}`);
}

export function getLocationById(locationId) {
  return axios.get(`${lOCATION_URL}/${locationId}`);
}

// Server should return sorted/filtered locations and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/locationsfind/{locationId}'. Should be 'api/locations/find/{locationId}'!!!
export function findLocations(queryParams, locationId) {
  return axios.post(`${lOCATION_URL}find/${locationId}`, { queryParams });
}

// UPDATE => PUT: update the location
export function updateLocation(location) {
  return axios.put(`${lOCATION_URL}/${location.id}`, 
    location
  );
}

// DELETE => delete the location
export function deleteLocation(locationId) {
  return axios.delete(`${lOCATION_URL}/${locationId}`);
}

// DELETE locations by ids
export function deleteLocations(ids) {
  return axios.post(`${lOCATION_URL}/deleteLocations`, { ids });
}

export function getDeleteValidationById(id){
  return axios.get(`${lOCATION_URL}/${id}/delete_validate`);
}
