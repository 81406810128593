import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/groups/groupsActions";
import {GroupEditDialogHeader} from "./GroupEditDialogHeader";
import { GroupEditForm } from "./GroupEditForm";
import { useGroupsUIContext } from "../GroupsUIContext";

export function GroupEditDialog({ id, show, onHide }) {
  // Groups UI Context
  const groupsUIContext = useGroupsUIContext();
  const groupsUIProps = useMemo(() => {
    return {
      initGroup: groupsUIContext.initGroup,
      openDeleteGroupDialog: groupsUIContext.openDeleteGroupDialog,
    };
  }, [groupsUIContext]);

  // Groups Redux state
  const dispatch = useDispatch();
  const { actionsLoading, groupForEdit ,groups,roles} = useSelector(
    (state) => ({
      actionsLoading: state.groups.groupLoading,
      groupForEdit: state.groups.groupForEdit,
      groups:state.groups.groupEntities,
      roles:state.groups.roles
    }),
    shallowEqual
  );
  const groupForValidation = groupForEdit==undefined ?  {...groupsUIProps.initGroup,groups}: {...groupForEdit,groups}
  useEffect(() => {
    // server call for getting Group by id
    dispatch(actions.fetchGroup(id));
    dispatch(actions.fetchRoles())
  }, [id, dispatch]);

  // server request for saving group
  const saveGroup = (group) => {
    group.roles = group?.role_id_name
    if (!id) {
      // server request for creating group
      dispatch(actions.createGroup(group)).then(() => onHide());
    } else {
      // server request for updating group
      dispatch(actions.updateGroup(group)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="md"
      show={show}
      // onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <GroupEditDialogHeader id={id} onHide={onHide} />
      <GroupEditForm
        saveGroup={saveGroup}
        actionsLoading={actionsLoading}
        group={groupForValidation}
        roles={roles}
        selectedRoles={groupForValidation?.roles}
        onHide={onHide}
        openDeleteGroupDialog={groupsUIProps.openDeleteGroupDialog}
      />
    </Modal>
  );
}
