import React, {  useState } from 'react';
import { Formik, Form, Field } from "formik";
import {
    Input 
  } from "../../../../../_metronic/_partials/controls";
import * as Yup from "yup"
import { authenticationService } from '../../_services';

export function ChangePasswordForm (props) {

  const ChangePasswordSchema = Yup.object().shape({
    newPassword: Yup
      .string()
      .required('Password  is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})/,
        "Must Contain 7 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      ,
    confirmPassword:Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
  });




  const [clientUsername] = useState(props?.username);



  return (
<>

    <Formik
        initialValues={{password:'',newPassword:'',confirmPassword:''}}
        validationSchema={ChangePasswordSchema}
        onSubmit={({password,newPassword,confirmPassword},{setStatus, setSubmitting }) => {
            setStatus();
            const values = {
                "password" : password,
                "newPassword" : newPassword,
                "confirmPassword": confirmPassword,
                "username" : clientUsername
            }
            authenticationService.changePassword(values)
                .then(
                    (response) => {
                        setSubmitting(false);                      
                        document.getElementById("closeModal").click();
                    },
                    error => {
                        setSubmitting(false);
                        setStatus(error);
                    }
                );
            
        }}
        >

        {({  errors, status, touched, isSubmitting , handleSubmit}) => (
          <>
          
        <Form className="form form-label-right">
        
        <div className="form-group row">
        <div className="col-lg-10">
            <Field
              autoComplete="new-password"
              name="newPassword"
              component={Input}
              placeholder="Password"
              label="New Password"
              type="password"
              withFeedbackLabel= {true}
              
            />
            </div></div>
            <div className="form-group row">
            <div className="col-lg-10">
            <Field
              autoComplete="new-password"
              name="confirmPassword"
              component={Input}
              placeholder="Password"
              label="Confirm Password"
              type="password"
              withFeedbackLabel= {true}
             
            />
             </div></div>
    </Form>  

      <div className="">
        <> </>
        <button
          type="submit"
          onClick={() => handleSubmit()}
          disabled={isSubmitting} className={isSubmitting ? "btn btn-primary spinner spinner-white spinner-right btn-lg" : "btn btn-primary btn-lg"}
        >
          Save
        </button>

      </div>
      {status &&
		<div className={'alert alert-danger'}>{status}</div>
	}
    </>)}
    </Formik>

    </>
  );
};
