import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as carActions from "../../_redux/cars/carsActions";
import * as categoryActions from "../../_redux/categories/categoriesActions";
import * as colorActions from "../../_redux/colors/colorsActions";
import * as searchActions from "../../_redux/search/searchAction";
import { Formik } from "formik";
import { isEqual } from "lodash";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "./../../../../_metronic/_partials/controls";
import AsyncSearchBar from "../shared/AsyncSelectBar";
import AsyncMultiSearchBar from "../shared/AsyncMultiSelectBar";
import { CarsFilter } from "./cars-filter/CarsFilter";
import { CarsTable } from "./cars-table/CarsTable";
import { CarsGrouping } from "./cars-grouping/CarsGrouping";
import { useCarsUIContext } from "./CarsUIContext";
import { CarsRegisterSuccessDialog } from "./car-register-success-dialog/CarsRegisterSuccessDialog";
import { SalesUIProvider } from "./car-sales/SalesUIContext";
import { SaleCompleteDialog } from "./car-sales/sale-edit-dialog/SaleCompleteDialog";
import { SaleContinueDialog } from "./car-sales/sale-edit-dialog/SaleContinueDialog";
import { ProformasUIProvider } from "./car-proformas/ProformasUIContext";
import { ProformaPrintModal } from "./car-proformas/print-proforma/ProformaPrintModal";
import { ProformaEditDialog } from "./car-proformas/Proforma-edit-dialog/ProformaEditDialog";
import * as employeeActions from "../../_redux/employees/employeesActions"
import { ProformaDeleteSales } from "./car-proformas/Proforma-edit-dialog/ProformaDeleteSalesModal";
import { SaleDeleteDialog } from "./car-sales/SaleDeleteDialog";
import { SaleDeleteChangeProforma } from "./car-sales/sale-edit-dialog/SaleDeleteChangeProforma";
import { ProformaMenuModal } from "./car-proformas/print-proforma/ProformaMenuModal";
// import { useHistory } from "react-router-dom";

// const { Label, Span } = styledColorPickerElements;

const formatNumber = (n) => {
	return n && n.toLocaleString();
}

export const saleStatuses = [{id: 1, name: 'In-Process', value: "in-Process"}, {id: 2, name: 'Sold', value: 'sold'}, {id: 3, name: 'Free', value: 'free'}];

const prepareFilter = (queryParams, values) => {
	const { searchText, supplier_id, batch_id, location_id, /*model,*/ status_id, make, /*price,*/ color } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};
	filter.supplier = supplier_id !== "" ? `${supplier_id}` : undefined;
	filter.batch = batch_id !== "" ? `${batch_id}` : undefined;
	// Filter by make
	// filter.make = make !== "" ? make : undefined;
	filter.make = (!make || make == "-- Select --") ? "" : make;
	// Filter by location
	// filter.location = location_id !== "" ? `${location_id}` : undefined;
	filter.location = location_id?.length > 0 ? location_id?.toString() : undefined;
	// Filter by model
	//filter.model = model !== "" ? model : undefined;
	// Filter by status
	// filter.status = status_id !== "" ? saleStatuses.find(s => s.id == status_id)?.value : undefined;
	filter.status = status_id?.length > 0 ? saleStatuses?.filter(({id}) => status_id?.some(s => id === s))?.map(({value}) => value)?.toString() : undefined
	// Filter by price
	// filter.price = price !== "" ? price : undefined;
	// Filter by color
	filter.color = color !== "" ? +color : undefined;
	// Filter by all fields
	if (searchText) {
		filter.searchText = searchText.trim();
	}
	newQueryParams.filter = filter;
	return newQueryParams;
};

export function CarsCard() {
  const carsUIContext = useCarsUIContext();

  const carsUIProps = useMemo(() => {
    return {
      ids: carsUIContext.ids,
      queryParams: carsUIContext.queryParams,
      setQueryParams: carsUIContext.setQueryParams,
      newCarButtonClick: carsUIContext.newCarButtonClick,
      openDeleteCarsDialog: carsUIContext.openDeleteCarsDialog,
      openEditCarPage: carsUIContext.openEditCarPage,
      openUpdateCarsStatusDialog:
        carsUIContext.openUpdateCarsStatusDialog,
      openFetchCarssDialog: carsUIContext.openFetchCarssDialog,
    };
  }, [carsUIContext]);

//   const history = useHistory();

//   const [query, setQuery] = useState("");

//   useEffect(() => {
//     history.push({search: `?${query}`})
//   }, [query, history])

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(carsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, carsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      carsUIProps.setQueryParams(newQueryParams);

	//   let q = newQueryParams?.filter;
	//   q = Object.entries(q).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
	//   q = Object.keys(q).map(key => key + '=' + q[key]).join('&'); 
	//   setQuery(q);
    }
  };

  const dispatch = useDispatch();

	const { currentState } = useSelector(
		(state) => ({ currentState: state }),
		shallowEqual
	);

	const { /*maxCarPrice, */
		success, 
		currentId } = currentState.cars;
	const { categoryEntities } = currentState.categories;
	const modelOptions = categoryEntities && [... new Set(categoryEntities.map(({model}) => model))].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
	const { colorEntities } = currentState.colors;
	// const {customerEntities} = currentState.customer;
	const {searchEntities:searchValues} = currentState.search?.pageName==="car" ? currentState.search : {searchEntities:{searchText:"",
																															supplier_id: "",
																															batch_id:"",
																															make: "",
																															model: "",
																															status_id: [1, 3],
																															color: "",
																															location_id:"",
																															customer:"",
																															status_id_name:[
																																{id: 1, name: "In-Process", value: "in-Process"}, 
																																{id: 3, name: "Free", value: "free"}
																															],
																															location_id_name:{},
																															batch_id_name:{}, 
																															supplier_id_name:{}}}
	
	const [showSuccessDialog, setShowSuccessDialog] = useState(false);

	function setSearchSlice(value){
		dispatch(searchActions.createSearch(value,"car"))
	}

	useEffect(() => {
		// dispatch(carActions.fetchMaxPrice());
		dispatch(employeeActions.getAllEmployees());
		dispatch(categoryActions.fetchCategories());
		dispatch(colorActions.fetchColors());
    }, [dispatch]);
	
	useEffect(()=>{
		applyFilter(searchValues);
	},[])
	
	useEffect(() => {
		// setPriceRange(initialPriceRange);
		setShowSuccessDialog(success?.type !== undefined);

		setTimeout(() => {
			setShowSuccessDialog(false);
		}, 1500);
	}, [
		// maxCarPrice, 
		success])

  return ( 
    <div className="content d-flex flex-column-fluid" id="kt_content">
		<div className="container">
			<CarsRegisterSuccessDialog
				showFlash={showSuccessDialog}
				onHide={() => setShowSuccessDialog(false)}
				type={success?.type}
			/>
			<div className="d-flex">
				<div className="flex-column offcanvas-mobile w-300px w-xl-325px" id="kt_profile_aside">
					<div className="card card-custom gutter-b">
						<div className="card-header border-0 pt-5">
							<h4 className="card-title align-items-start flex-column mb-3">
								<span className="card-label font-size-h3 font-weight-bolder text-dark">Filter</span>
							</h4>
						</div>
						<div className="card-body pt-0">
							<Formik
							initialValues={searchValues}
							onSubmit={(values) => {
							  setSearchSlice(values)
							  applyFilter(values);
							}}
							>
							{({
							initialValues,
							values,
							touched,
							handleSubmit,
							handleBlur,
							setFieldValue,
							setFieldTouched,
							resetForm
							}) => (
								<form onSubmit={handleSubmit} className="form form-label-right">
									<div>
										<div className="form-group mb-8">
											<label className="font-weight-bolder">Search</label>
											<input
												type="text"
												className="form-control form-control-lg search-txt"
												name="searchText"
												placeholder=""
												onBlur={handleBlur}
												value={values.searchText}
												onChange={(e) => {
													setFieldValue("searchText", e.target.value);
													handleSubmit();
												}}
											/>
										</div>
										<div className="form-group row">
											<div className="col-lg-12 solid-bg">
												<label htmlFor="make" className="font-weight-bolder">Make</label>
												<AsyncSearchBar 
													selectedItem={{id: values.make}} 
													setSelectedItem={setFieldValue}
													setTouchedItem={setFieldTouched}
													defaultItem={searchValues?.make}
													touched={touched.make}
													item_id="make"
													item_name="make"
													url={`${process.env.REACT_APP_AUTO_DEALER_API}carmodels/searchMakes`}
													pk="make"
													submit={handleSubmit}
													clear={values.make == ""}
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-lg-12 solid-bg">
												<label htmlFor="sale_status" className="font-weight-bolder">Sale Status</label>
												<AsyncMultiSearchBar
													isMulti={true}
													selectedItem={{id: values.status_id}} 
													setSelectedItem={setFieldValue}
													setTouchedItem={setFieldTouched}
													touched={touched.status_id}
													defaultItem={searchValues?.status_id_name?.name}
													item_id="status_id"
													item_name="name"
													options={saleStatuses}
													isSearchable={false}
													submit={handleSubmit}
													clear={values.status_id == ""}
													catchDefaultName={true}
													val={values?.status_id_name}
												/>
											</div>
										</div>

										<div className="form-group row">
											<div className="col-lg-12 solid-bg">
											<label  htmlFor="supplier_id" className="font-weight-bolder">Supplier</label> 
												<AsyncSearchBar 
													selectedItem={{id: values.supplier_id}} 
													setSelectedItem={setFieldValue}
													setTouchedItem={setFieldTouched}
													// defaultItem={supplierEntities?.find(s => salesUIProps.queryParams.filter.supplier == s.id)?.name}
													defaultItem={searchValues?.supplier_id_name?.name}
													touched={touched.supplier_id}
													item_id="supplier_id"
													item_name="name"
													url={`${process.env.REACT_APP_AUTO_DEALER_API}suppliers/search`}
													submit={handleSubmit}
													clear={values.supplier_id == ""}
													catchDefaultName={true}
												/>
											</div>
										</div>


										<div className="form-group row">
											<div className="col-lg-12 solid-bg">
												<label  htmlFor="bank_id" className="font-weight-bolder">Batch</label>
												<AsyncSearchBar 
													selectedItem={{id: values.batch_id}} 
													setSelectedItem={setFieldValue}
													setTouchedItem={setFieldTouched}
													defaultItem={searchValues?.batch_id_name?.name}
													touched={touched.batch_id}
													item_id="batch_id"
													item_name="name"
													url={`${process.env.REACT_APP_AUTO_DEALER_API}batches/search`}
													submit={handleSubmit}
													clear={values.batch_id == ""}
													catchDefaultName={true}
												/>
											</div>
										</div>
										<div className="form-group row mb-8">
											<div className="col-lg-12 solid-bg">
												<label htmlFor="location_id" className="font-weight-bolder">Location</label>
												<AsyncMultiSearchBar
													isMulti={true}
													selectedItem={{id: values.location_id}} 
													setSelectedItem={setFieldValue}
													setTouchedItem={setFieldTouched}
													defaultItem={searchValues?.location_id_name?.name}
													touched={touched.location_id}
													item_id="location_id"
													item_name="name"
													url={`${process.env.REACT_APP_AUTO_DEALER_API}locations/search`}
													submit={handleSubmit}
													userName={true}
													clear={values.location_id == ""}
													catchDefaultName={true}
													val={values?.location_id_name}
												/>
											</div>
										</div>
									
										
										{/* <div className="form-group mb-8">
											<label className="font-weight-bolder">Model</label>
											<select
												className="form-control form-control-solid form-control-lg"
												name="model"
												onBlur={handleBlur}
												onChange={(e) => {
													setFieldValue("model", e.target.value);
													handleSubmit();
												}}
												value={values.model}
												>
												<option value=""></option>
												{modelOptions && modelOptions.map((element, i) => (
													<option key={i} value={element}>
														{element}
													</option>
												))}
											</select>
										</div> */}
										
										{/* <div className="form-group mb-8">
											<label className="font-weight-bolder">Color</label>
											<select
												className="form-control form-control-solid form-control-lg"
												name="color"
												onBlur={handleBlur}
												onChange={(e) => {
													setFieldValue("color", e.target.value);
													handleSubmit();
												}}
												value={values.color}
											>
												<option value=""></option>
												{colorEntities && colorEntities.map((element, i) => (
													<option key={element.id} value={element.id}>
														{element.name}
													</option>
												))}
											</select>
										</div> */}
										{/* <div className="form-group mb-3">
											<label className="font-weight-bolder">Price</label>
											{initialPriceRange[1] && (
												<Slider 
												name="price"
												min={initialPriceRange[0]}
												max={initialPriceRange[1]}
												step={priceRangeStep}
												marks={priceRangeMark}
												value={priceRange} 
												onChange={(e, data) => {
													setPriceRange(data);
													const val = data.join(',') !== initialPriceRange.join(',') ? data : ''; 
													setFieldValue("price", val);
													handleSubmit();
												}} 
												className="text-success"
												/>
											)}
										</div> */}
										{/* <div className="form-group">
											<label className="font-weight-bolder">Color</label>
											<div className="radio-inline mb-11">
												{colorEntities && colorEntities.map((element) => (
													<OverlayTrigger
														key={element.id}
														placement="bottom"
														overlay={
															<Tooltip>
																{element.name}
															</Tooltip>
														}
													>
														<Label className="radio radio-accent mr-0" bg={element.name} key={element.id}>
															<input 
																type="radio" 
																name="color" 
																value={`${element.id}`} 
																onChange={(e) => {
																	setFieldValue("color", e.target.value);
																	handleSubmit();
																}}
															/>
															<Span bg={element.name}></Span>
														</Label>
													</OverlayTrigger>
												))}
											</div>
										</div> */}
										<button 
											type="reset" 
											className="btn btn-primary font-weight-bolder mr-2 px-8" 
											onClick={()=>{
												//resetForm();
												// setPriceRange(initialPriceRange);
												setFieldValue('supplier_id', '')
												setFieldValue('batch_id', '')
												setFieldValue('color', '')
												setFieldValue('make', '')
												setFieldValue('model', '')
												setFieldValue('status_id', [1, 3])
												setFieldValue('status_id_name', [
													{id: 1, name: "In-Process", value: "in-Process"}, 
													{id: 3, name: "Free", value: "free"}
												],)
												setFieldValue('searchText', '')
												setFieldValue('location_id', '')
												handleSubmit();
											}}>
												Reset
										</button>
									</div>
								</form>
							)}
							</Formik>
						</div>		
					</div>
				</div>

				<div className="flex-row-fluid ml-lg-8">
					<Card>
						<CardHeader title="Cars List">
							<CardHeaderToolbar>
								<button
									type="button"
									className="btn btn-primary"
									onClick={carsUIProps.newCarButtonClick}
								>
									New Car
								</button>
							</CardHeaderToolbar>
						</CardHeader>
						<CardBody>
							<CarsFilter />
							{carsUIProps.ids.length > 0 && <CarsGrouping />}
							<ProformasUIProvider currentProductId={currentId} proformaFromOutside={true}>
								<SalesUIProvider currentProductId={currentId} saleFromOutside={true}>
									<CarsTable selectProduct={carActions.selectCurrentCarId} />
									<>
										<ProformaPrintModal />
										<ProformaEditDialog />
										{/* <ContractOfSaleModal />
										<ProformaBankReleaseModal/>
										<CashContractOfSaleModal/> */}
										<ProformaMenuModal />
										<ProformaDeleteSales />
									</>
									<>
										{/* <SaleEditDialog /> */}
										<SaleCompleteDialog />
										<SaleContinueDialog />
										<SaleDeleteDialog />
										<SaleDeleteChangeProforma />
									</>
								</SalesUIProvider>
							</ProformasUIProvider>
						</CardBody>
					</Card>	
				</div>
			</div>
		</div>
	</div>
  );
}
