import React from "react";
import { Route } from "react-router-dom";
import { CarsLoadingDialog } from "./cars-loading-dialog/CarsLoadingDialog";
import { CarDeleteDialog } from "./car-delete-dialog/CarDeleteDialog";
import { CarsDeleteDialog } from "./cars-delete-dialog/CarsDeleteDialog";
import { CarsFetchDialog } from "./cars-fetch-dialog/CarsFetchDialog";
import { CarsUpdateStateDialog } from "./cars-update-status-dialog/CarsUpdateStateDialog";
import { CarsUIProvider } from "./CarsUIContext";
import { CarsCard } from "./CarsCard";

export function CarsPage({ history }) {
  const carsUIEvents = {
    newCarButtonClick: () => {
      history.push("/cars/new");
    },
    openEditCarPage: (id) => {
      history.push(`/cars/${id}/edit`);
    },
    openDeleteCarDialog: (id) => {
      history.push(`/cars/${id}/delete`);
    },
    openDeleteCarsDialog: () => {
      history.push(`/cars/deleteCars`);
    },
    openFetchCarsDialog: () => {
      history.push(`/cars/fetch`);
    },
    openUpdateCarsStatusDialog: () => {
      history.push("/cars/updateStatus");
    }
  }

  return (
    <CarsUIProvider carsUIEvents={carsUIEvents}>
      <CarsLoadingDialog />
      <Route path="/cars/deleteCars">
        {({ history, match }) => (
          <CarsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/cars");
            }}
          />
        )}
      </Route>
      <Route path="/cars/:id/delete">
        {({ history, match }) => (
          <CarDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cars");
            }}
          />
        )}
      </Route>
      <Route path="/cars/fetch">
        {({ history, match }) => (
          <CarsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/cars");
            }}
          />
        )}
      </Route>
      <Route path="/cars/updateStatus">
        {({ history, match }) => (
          <CarsUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/cars");
            }}
          />
        )}
      </Route>
      <CarsCard />
    </CarsUIProvider>
  );
}
