import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./LoanSalesUIHelpers";
import { shallowEqual, useSelector } from "react-redux";

const LoanSalesUIContext = createContext();

export function useLoanSalesUIContext() {
  return useContext(LoanSalesUIContext);
}

export const LoanSalesUIConsumer = LoanSalesUIContext.Consumer;

export function LoanSalesUIProvider({loanSalesUIEvents, children}) {
  const { loan_sales_params } = useSelector(
    (state) => ({ loan_sales_params: state.reports.loan_sales_params }),
    shallowEqual
  );
  const [queryParams, setQueryParamsBase] = useState(loan_sales_params || initialFilter);

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return <LoanSalesUIContext.Provider value={value}>{children}</LoanSalesUIContext.Provider>;
}