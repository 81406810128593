import axios from "axios";

export const SPECIFICATIONS_URL = "api/home/dashboard/";
export const HOME_URL = process.env.REACT_APP_AUTO_DEALER_API+'home/dashboard/'

export function getDashboardStat(user_name, supplier){
  return axios.get(HOME_URL+'stat', { params: { user_name: user_name,year:localStorage.clientStartYear, supplier: supplier } })
}

export function getDashboardLineQuantity(user_name, supplier){
    return axios.get(HOME_URL+'lineQuantity', { params: { user_name: user_name, supplier } })
  }

export function getDashboardLineAmount(user_name, supplier){
  return axios.get(HOME_URL+'lineAmount', { params: { user_name: user_name, supplier } })
}  

export function getDashboardPie(user_name, supplier){
    return axios.get(HOME_URL+'pie', { params: { user_name: user_name,year:localStorage.clientStartYear, supplier: supplier } })
}

export function getDashboardColumn(user_name, supplier){
    return axios.get(HOME_URL+'column', { params: { user_name: user_name, supplier } })
}

export function getDashboardNotification(user_name){
  return axios.get(HOME_URL+'notification', { params: { user_name: user_name } })
}

export function getDates(user_name){
  return axios.get(HOME_URL+'getDates', { params: { user_name: user_name } })
}

export function getYearlySalesTrend(user_name, supplier){
  return axios.get(HOME_URL+"yearly_sales_trend", { params: { user_name: user_name, supplier: supplier } })
}
