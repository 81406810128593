import React from "react";
import { Route } from "react-router-dom";
import { GroupsLoadingDialog } from "./groups-loading-dialog/GroupsLoadingDialog";
import { GroupEditDialog } from "./group-edit-dialog/GroupEditDialog";
import { GroupDeleteDialog } from "./group-delete-dialog/GroupDeleteDialog";
import { GroupsFetchDialog } from "./groups-fetch-dialog/GroupsFetchDialog";
import { GroupsUIProvider } from "./GroupsUIContext";
import { GroupsCard } from "./GroupsCard";

export function GroupsPage({ history }) {
  const groupsUIEvents = {
    newGroupButtonClick: () => {
      history.push("/groups/new");
    },
    openEditGroupDialog: (id) => {
      history.push(`/groups/${id}/edit`);
    },
    openDeleteGroupDialog: (id) => {
      history.push(`/groups/${id}/delete`);
    },
    openFetchGroupsDialog: () => {
      history.push(`/groups/fetch`);
    }
  }

  return (
    <GroupsUIProvider groupsUIEvents={groupsUIEvents}>
      <GroupsLoadingDialog />
      <Route path="/groups/new">
        {({ history, match }) => (
          <GroupEditDialog
            show={match != null}
            onHide={() => {
              history.push("/groups");
            }}
          />
        )}
      </Route>
      <Route path="/groups/:id/edit">
        {({ history, match }) => (
          <GroupEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/groups");
            }}
          />
        )}
      </Route>
      
      <Route path="/groups/:id/delete">
        {({ history, match }) => (
          <GroupDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/groups");
            }}
          />
        )}
      </Route>
      <Route path="/groups/fetch">
        {({ history, match }) => (
          <GroupsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/groups");
            }}
          />
        )}
      </Route>
      
      <GroupsCard />
    </GroupsUIProvider>
  );
}
