import moment from "moment";

export const defaultSorted = [{ dataField: "id", order: "asc" }];

// const date = new Date();
// const startdate = new Date(date.getFullYear(), date.getMonth(), 1)
const enddate = moment()
const startdate = moment().startOf('month')

export const initialFilter = {
    filter: {
        start_date: startdate,
        end_date: enddate,
        // start_date: '', 
        // end_date: ''
    },
    sortOrder: "asc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 10
};
