
import {authsSlice} from "./authsSlice";

const {actions} = authsSlice;

export const addAuthInfo = (roles,group) => dispatch => {
  
      dispatch(actions.authFetched({ roles, group }));
    
};
