import React, { useLayoutEffect, useState } from 'react';
import { Modal, Nav } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useHistory } from "react-router-dom"
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { authenticationService } from '../_services/index';
import { setClientRegistration, setVerifiedClient } from '../../../components/_redux/client/clientsActions'
import '../../../assets/css/pages/login/login-2.css'
import '../../../assets/plugins/global/plugins.bundle.css'
import '../../../assets/plugins/custom/prismjs/prismjs.bundle.css'
import '../../../assets/css/style.bundle.css'
import ForgotPasswordModal from '../ForgotPasswordModal/ForgotPasswordModal';

const loginValidationSchema = Yup.object().shape({
	username: Yup.string().min(5).nullable().required('Username is required')
		.matches(
			/^[^@]*@[^@]*$/,
			"Username should contain '@' once"
		),
	password: Yup.string().nullable().required('Password is required')
})

const LoginPage = () => {

	const historyUSE = useHistory()
	useLayoutEffect(() => {
		if (authenticationService.currentUserValue) {
			historyUSE.push('/dashboard');
		} else {
			if (!(window.location.href).indexOf('/login'))
				historyUSE.push('/login');
		}
	}, [historyUSE]);

	const { clientRegistered, clientVerified } = useSelector(
		(state) => ({
			clientRegistered: state.client.clientRegistered,
			clientVerified: state.client.clientVerified
		}),
		shallowEqual
	);


	const showForgotPassword = () => {
		setForgotPasswordModal(true);
	}

	const dispatch = useDispatch();

	const [showModal, setShowModal] = useState(clientRegistered !== null || false);

	const [showForgotPasswordModal, setForgotPasswordModal] = useState(false);




  const gotoLogin = ()=>{
	document.cookie = `redirectToLogin=1;max-age=604800;`
  }

	return (
		<>	 

		<div className="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" >
			<div className="login-aside order-2 order-lg-1 d-flex flex-row-auto position-relative overflow-hidden">
				<div className="d-flex flex-column-fluid flex-column justify-content-between py-4 px-7 py-lg-13 px-lg-35">
					<p  className="text-center pt-2">
						<img src="/media/logos/logo.png" className="max-h-75px" alt="" />
					</p>
					<div className="d-flex flex-column-fluid flex-column flex-center">
						<div className="login-form login-signin">
							<div className="d-none d-lg-block">
								<div className="text-center pb-8">
									<h2 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Sign In</h2>
									<span className="text-muted font-weight-bold font-size-h4">or &nbsp;
										<Link to="/register" className="text-primary font-weight-bolder" id="kt_login_signup">Sign Up</Link></span>
								</div>
							</div>

							<div className="d-lg-none">
								<div className="text-center pb-6">
									<h6 className="font-weight-bolder text-dark font-size-h6 font-size-h6-sm">
										Sign In or &nbsp;
										<Link to="/register" className="text-primary font-weight-bolder" id="kt_login_signup">Sign Up</Link>
									</h6>
								</div>
							</div>
							<Formik
								enableReinitialize={true}
								initialValues={{
									username: clientVerified || '',
									password: ''
								}}
								validationSchema={loginValidationSchema}
								onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
									setStatus();
									authenticationService.login(username, password)
										.then(
											user => {
												dispatch(setVerifiedClient(null))
												window.location.reload(true);
											},
											error => {
												setSubmitting(false);
												setStatus(error);
											}
										);
								}}
								render={({ errors, status, touched, isSubmitting }) => (
									<Form>
										<div className="form-group">
											<label className="font-size-h6 font-weight-bolder text-dark">Email</label>
											<Field name="username" className={'form-control form-control-solid h-auto py-7 px-6 rounded-lg' + (errors.username && touched.username ? ' is-invalid' : '')} type="text" autoComplete="off" />
											<ErrorMessage name="username" component="div" className="invalid-feedback" />
										</div>
										<div className="form-group">
											<div className="d-flex justify-content-between mt-n5">
												<label className="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
												<Link to="#" onClick={showForgotPassword}>Forgot Password ?</Link>
											</div>
											<Field name="password" className={'form-control form-control-solid h-auto py-7 px-6 rounded-lg' + (errors.password && touched.password ? ' is-invalid' : '')} type="password" autoComplete="off" />
											<ErrorMessage name="password" component="div" className="invalid-feedback" />
										</div>
										<div className="text-center pt-2">

											<button type="submit" onClick={gotoLogin} disabled={isSubmitting} className={isSubmitting ? "btn btn-primary spinner spinner-white spinner-right btn-lg" : "btn btn-primary btn-lg"}>
												Sign In
											</button>
										</div>
										{status &&
											<div className={'alert alert-danger'}>{status}</div>
										}
									</Form>
								)}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="content order-1 order-lg-2 d-flex flex-column w-100 pb-0" style={{ backgroundColor: '#C9F7F5' }}>
				<div className="d-flex flex-column justify-content-center text-center pt-lg-40 pt-md-5 pt-sm-0 px-lg-0 pt-0 px-0">
					<h3 className="display4 font-weight-bolder my-5 pt-sm-0 text-dark" style={{ color: "#986923" }}>Your Auto Dealership Solution</h3>
					<p className="font-weight-bolder font-size-h2-md font-size-lg text-dark opacity-70"> Stock Management, Sales, Proforma &amp; Inspection.
					</p>
				</div>
				<div className="content-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center" style={{ backgroundImage: 'url(/media/svg/illustrations/login-visual-2.svg)' }}></div>
			</div>



				<Modal
					show={showForgotPasswordModal}
					aria-labelledby="example-modal-sizes-title-lg"
				>
					<Modal.Header closeButton>
						<Modal.Title id="example-modal-sizes-title-lg">Forgot Password?</Modal.Title>
						<Nav className="ml-auto" onClick={() => setForgotPasswordModal(false)}>
							<Nav.Link className="p-0 text-dark">
								<span className="nav-icon">
									<span className="svg-icon svg-icon-2x">
										<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
												<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
													<rect x="0" y="7" width="16" height="2" rx="1" />
													<rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1" />
												</g>
											</g>
										</svg>
									</span>
								</span>
							</Nav.Link>
						</Nav>
					</Modal.Header>
					<Modal.Body>
						<ForgotPasswordModal for />
					</Modal.Body>
					<Modal.Footer>
						<div>
							<button
								type="button"
								onClick={() => {
									setForgotPasswordModal(false)
								}}
								className="btn btn-light btn-elevate"
								id="closeModal"
							>
								Close
							</button>
						</div>
					</Modal.Footer>
				</Modal>
				




			<Modal
				show={showModal}
				aria-labelledby="example-modal-sizes-title-lg"
			>
				<Modal.Header closeButton>
					<Modal.Title id="example-modal-sizes-title-lg">Registration</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>A confirmation link has been sent to your email, {clientRegistered} from Auto Dealer Support.</p>
					Please check your email to complete the registration.
				</Modal.Body>
				<Modal.Footer>
					<div>
						<button
							type="button"
							onClick={() => {
								dispatch(setClientRegistration(null))
								setShowModal(false)
							}}
							className="btn btn-light btn-elevate"
						>
							Close
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
		</>
	)
}

export default LoginPage;