// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo,useState,useLayoutEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
}  from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/user/usersActions";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../UsersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { PaginationLinks } from "../../../../../../_metronic/_partials/controls";
import { useUsersUIContext } from "../UsersUIContext";
import cloneDeep from 'lodash/cloneDeep';
import * as locationActions from "../../../../_redux/locations/locationsActions";
import * as groupActions from "../../../../_redux/groups/groupsActions"
import NotFoundPage from "../../../shared/NotFoundPage";
import $ from 'jquery'

export function UsersTable() {
  // Users UI Context
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      ids: usersUIContext.ids,
      setIds: usersUIContext.setIds,
      queryParams: usersUIContext.queryParams,
      setQueryParams: usersUIContext.setQueryParams,
      openEditUserDialog: usersUIContext.openEditUserDialog,
      openDeleteUserDialog: usersUIContext.openDeleteUserDialog,
    };
  }, [usersUIContext]);

  // Getting curret state of users list from store (Redux)
  const { usersSlice, client } = useSelector(
    (state) => ({ 
      usersSlice: state.users, 
      client: state.client.clientEntities 
    }),
    shallowEqual
  );
  const { userTotalCount:totalCount, userEntities:entities, userListLoading:listLoading,error,apiErrorData } = usersSlice;
  const [tableData,setTableDate] = useState([])
  const [statusFilter,setStatusFilter] = useState(true)
  const [notFound,setNotFound] = useState(false);

  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }else if(error 
          && apiErrorData?.errorCode==="NOT_FOUND"){
            setNotFound(true);
        }
    }
  },[error])
  useEffect(()=>{
    if(notFound){
      $("#kt_content > div > div > div.card-header > div.card-toolbar > button").hide();
    }
  },[notFound])

  // Users Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    if(client) {
      // clear selections list
      usersUIProps.setIds([]);
      // server call by queryParams
      dispatch(actions.fetchUsers({...usersUIProps.queryParams.filter, clientName: client?.shortName}));
      dispatch(locationActions.fetchLocations());
      dispatch(groupActions.fetchGroups())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dispatch, client]);

  useEffect(()=>{
    setTableDate(cloneDeep(statusFilter && entities !=null && entities != undefined ? entities.filter((item)=>{return item.status}):entities ))
  },[entities,statusFilter])

  // Table columns
  const columns = [
    {
      dataField: "fullName",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          usersUIProps.openEditUserDialog(row.id);
        }
      }
    },
    
    {
      dataField: "userName",
      text: "Username",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          usersUIProps.openEditUserDialog(row.id);
        }
      }
    },{
      dataField: "group",
      text: "Group",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell) => {
        return <>{cell?.name}</>
      },
      
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          usersUIProps.openEditUserDialog(row.id);
        }
      }
    },
    
    {
      dataField: "locations",
      text: "Location",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter:columnFormatters.LocationColumnFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          usersUIProps.openEditUserDialog(row.id);
        }
      }
    },
    
    
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditUserDialog: usersUIProps.openEditUserDialog,
        openDeleteUserDialog: usersUIProps.openDeleteUserDialog,
      },
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: tableData?.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: usersUIProps.queryParams.pageSize,
    page: (usersUIProps.queryParams.pageNumber - 1) < (tableData?.length/usersUIProps.queryParams.pageSize) ? usersUIProps.queryParams.pageNumber : 
    usersUIProps.queryParams.pageNumber == 1 ?  usersUIProps.queryParams.pageNumber:usersUIProps.queryParams.pageNumber - 1  ,
  };

  function filter(e){
    console.log(e)
    const filter = entities?.filter(item => (!statusFilter || item.status) && (item.userName.toLowerCase().indexOf(e.toLowerCase()) > -1 || item.fullName?.toLowerCase().indexOf(e.toLowerCase()) > -1 ))
    setTableDate(filter)
  }

  const rowStyle = (row, rowIndex) => {
    const style = {cursor: 'pointer'};
    if (!row.status) {
      style.color = '#c7bcbc';
    }
    return style;
  };
 
  const toogleStatus = ()=>{
    setStatusFilter(!statusFilter)
  }
  
  function pageHandler (paginationProps){
    
    usersUIProps.setQueryParams(
      (prev) =>
      "pagination" === "pagination"
        ? { ...prev, pageNumber:paginationProps?.page || 1, pageSize: paginationProps?.sizePerPage }
        : prev
    )
    
}
  return (
    <>
    {!notFound ?
    <>
    <div className="row">
      <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  id="search"
                  placeholder="Search"
                  // onBlur={handleBlur}
                  // value={values.searchText}
                   onChange={(e) => {
                    filter(e.target.value);
                   }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
                
              </div>
              <div className="col-lg-2">
              <label className="checkbox checkbox-lg  checkbox-inline flex-shrink-0 m-0 mx-4 mt-3">
														<input type="checkbox" onChange={toogleStatus}/>
														<span></span>
													</label> <small className="form-text text-muted">Include Deactivates</small></div></div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                keyField="id"
                data={tableData === null ? [] : tableData}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={pageHandler(paginationProps)}
                rowStyle={rowStyle}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={tableData} />
                <NoRecordsFoundMessage entities={tableData} />
              </BootstrapTable>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
            <PaginationLinks paginationProps={paginationProps} />
            <div className="ml-lg-auto">
            <SizePerPageDropdownStandalone
                { ...paginationProps }
              />
              <PaginationTotalStandalone
                { ...paginationProps }
              />
            </div>
            </div>
            </>
          );
        }}
      </PaginationProvider>
    </>
     :<NotFoundPage/>}
    </>
  );
}
