import './App.css';
import React, {useEffect, useState} from 'react';
import {BatchPage} from "./pages/settings/settings-batch/BatchPage"
import {BanksPage} from './pages/settings/settings-banks/BanksPage'
import {ColorsPage} from './pages/settings/settings-colors/ColorsPage'
import { CustomersPage } from './pages/settings/settings-customers/CustomersPage';
import { EmployeesPage } from './pages/settings/settings-employees/EmployeesPage';
import { SuppliersPage } from './pages/settings/settings-suppliers/SuppliersPage';
import { LocationsPage } from './pages/settings/settings-locations/LocationsPage';
import { UsersPage } from './pages/settings/settings-users/UsersPage';
import { ProfilePage } from './pages/settings/settings-profile/ProfilePage';
import {  ContentRoute } from "../../_metronic/layout";
import { CarsPage } from './pages/cars/CarsPage';
import { OrdersPage } from "./pages/orders/OrdersPage"
import { ReportsPage } from './pages/reports/ReportsPage';
import { AccountsPage } from './pages/accounts-transactions/AccountsPage';
import { ReportsPageAdmin } from './pages/admin-pages/reports/ReportsPage';
import { SupportPage } from "./pages/support/SupportPage"
import { CarEditPage } from './pages/cars/car-edit-page/CarEditPage';
import { CarModelsPage } from './pages/car-models/CarModelsPage';
import {GroupsPage} from './pages/settings/settings-groups/GroupsPage'
import Home from './pages/dashboard/Home';
import HomeAdmin from './pages/dashboard/HomeAdmin';
import Setting from './pages/Setting';
import UserPanel from './Layout/UserPanel';
import {  Switch, Route, useHistory } from "react-router-dom";
import LoginPage from '../modules/Auth_LocalStorage/LoginPage/loginPageNew'
import { authenticationService } from '../modules/Auth_LocalStorage/_services';
import { useDispatch,useSelector,shallowEqual } from "react-redux";
import * as clientActions from './_redux/client/clientsActions'
import * as authActions from './_redux/auth/authsActions'
import * as locationActions from "./_redux/locations/locationsActions"
import Faq from './pages/faq'
import Tutorial from './pages/Tutorial';
import ContactPage from './pages/ContactPage';
import {decode} from 'jsonwebtoken'
import axios from 'axios'
import { setLoggedUser } from "../components/_redux/user/usersActions"
import {UsersAdminPage} from './pages/admin-pages/users/UsersPage';
import {TenantsPage} from './pages/admin-pages/tenants/TenantsPage';
import Logs from './pages/admin-pages/logs/Logs';
import * as adminSettingAction from '../components/_redux_admin/settings_data/settingsActions'
import Landing from './pages/landing/Landing';
// import TrialDialog from './Layout/client-status/TrialDialog';
// import { SubscriptionPage } from './pages/subscription/subscription-edit-dialog/SubscriptionPage';

export const PrivateRoute = () => {
    const dispatch = useDispatch();
    const [redirectToLoginCookie,setRedirectToLoginCookie] = useState('');
    
    // Application wide states dispatch here 
    useEffect(()=>
	{   const token = localStorage.currentUser != undefined && localStorage.currentUser.substring(7);
        const { scopes } = decode(token);
        const roles = scopes && scopes[0].authority.split(',');
        dispatch(authActions.addAuthInfo(roles,localStorage.group));

        if(!roles.includes("ADMINUSERROLE")){
		dispatch(clientActions.fetchClientbyUserEmail(localStorage.currentUserEmail.substring(localStorage.currentUserEmail.indexOf('@')+1)))
        }else{
        dispatch(adminSettingAction.fetchPaymentTypes())
        dispatch(adminSettingAction.fetchSubscriptionPlan())
        }
	},[dispatch])

    const {auth, loggedUser, locations, client} = useSelector((state) => ({ 
        auth: state.auth, 
        loggedUser: state.users.loggedUser,
        locations: state.locations.locationEntities, 
        client: state.client.clientEntities
     }),
    shallowEqual)
    
    const {roles} = auth;

    // const [showTrialDialog, setShowTrialDialog] = useState(false)
    const history = useHistory()

    useEffect(async () => {
        if(localStorage.group != 'ADMIN'){
		try {
			const res = await axios({
				method: 'get',
				url: `${process.env.REACT_APP_AUTO_DEALER_API}users/name/${localStorage.getItem('currentUserEmail')}`,
			});
			const user = res.data
			dispatch(setLoggedUser(user))
			if(user?.change_pwd) {
				history.push('/users')
			}
		} catch (err) {
			console.log(err)
		}}
	}, [dispatch])

    useEffect(async () => {
        if(localStorage.group != 'ADMIN'){
		try {
			if(loggedUser?.change_pwd === false) {
                const res = await axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_AUTO_DEALER_API}locations`,
                });
                if(res.data.length < 2) {
                    history.push('/locations')
                }
            }
		} catch (err) {
			console.log(err)
		}}
	}, [dispatch, loggedUser])

    useEffect(async () => {
        
        if(localStorage.group != 'ADMIN'){
            (locations == null || locations?.length == 0) && dispatch(locationActions.fetchLocations())
		try {
			if(loggedUser && locations?.length > 1 && client?.id) {
                const res = await axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_AUTO_DEALER_API}client/${client?.id}/activeSubscription`,
                });
                if(res.data) {
                    dispatch(clientActions.showSubscriptionMsg(res.data))
                }
            }
		} catch (err) {
			console.log(err)
		}}
	}, [dispatch, locations])

    // useEffect(async () => {
    //     dispatch(locationActions.fetchLocations())
    // }, [dispatch])

    // useEffect(() => {
    //     if(locations?.length > 0) {
    //         setShowTrialDialog(true)
    //     }
    // }, [locations])

    useEffect( () => {
        setRedirectToLoginCookie(document.cookie.split(';').find(cookie => cookie.startsWith('redirectToLogin='))?.split('=')[1]);
    }, [history]);

    return (
    
    <>
    {authenticationService.currentUserValue &&
        <>
        {localStorage.group === 'ADMIN' ?
        <Switch>
            <Route exact path="/dashboard">
               <HomeAdmin />
            </Route>
            <ContentRoute path="/clients" component={TenantsPage} />
            <ContentRoute path="/usersAdmin" component={UsersAdminPage} />
            <ContentRoute path="/logAdmin" component={Logs} />
            <ContentRoute path="/reportsAdmin" component={ReportsPageAdmin} />
            <Route path="/">
                {redirectToLoginCookie == '1' ? (
                    <LoginPage />
                ) : (
                    <Landing />
                )}
            </Route>
            <Route exact path="/login">
                <LoginPage />
            </Route>
        </Switch>
        :
        <Switch>
            <Route exact path="/dashboard">
               <Home />
            </Route>
            
            <ContentRoute path="/batch" component={BatchPage} />
            <ContentRoute path="/banks" component={BanksPage} />
            <ContentRoute path="/colors" component={ColorsPage} />
            <ContentRoute path="/customers" component={CustomersPage} />
            <ContentRoute path="/employees" component={EmployeesPage} />
            <ContentRoute path="/suppliers" component={SuppliersPage} />
            <ContentRoute path="/locations" component={LocationsPage} />
            {/* <Route path="/users/:id/changePassword">
                {({ history, match }) => (
                    <UserChangePasswordDialog
                        show={match != null || (loggedUser.change_pwd && loggedUser?.id != null)}
                        id={match && match.params.id || (loggedUser.change_pwd && loggedUser?.id)}
                        onHide={() => {
                            history.goBack();
                        }}
                    />
                )}
            </Route> */}
            {roles && roles.includes("AdminRole") && 
                <ContentRoute path="/users" component={UsersPage} />}
            {roles && roles.includes("AdminRole") && 
            <ContentRoute path="/groups" component={GroupsPage} />} 
            {roles && roles.includes("AdminRole") &&
            <ContentRoute path="/profile" component={ProfilePage} /> }
            <ContentRoute path="/categories" component={CarModelsPage} />
            <ContentRoute path="/cars/new" component={CarEditPage}></ContentRoute>
            <ContentRoute path="/cars/:id/edit" component={CarEditPage}></ContentRoute>
            <ContentRoute path="/cars" component={CarsPage} />
            {roles && roles.includes("Proforma") && roles.includes("Sales") &&
                <ContentRoute path="/orders" component={OrdersPage} />}
            <ContentRoute path="/reports" component={ReportsPage} />
            <ContentRoute path="/account" component={AccountsPage} />
            
            <ContentRoute path="/faq" component={Faq}/>
            <ContentRoute path="/Tutorial" component={Tutorial} />
            
            <Route exact path="/setting">
                <Setting />
            </Route>
            <Route exact path="/support">
                <SupportPage />
            </Route>
            <Route path="/contact">
                <ContactPage />
            </Route>
            {/* <ContentRoute path="/subscription" component={SubscriptionPage} /> */}
            <Route  path="/">
                <LoginPage />
            </Route>
            <Route exact path="/login">
                <LoginPage />
            </Route>
            <UserPanel/>
        </Switch>}
        {/* <TrialDialog show={showTrialDialog} setShowModal={setShowTrialDialog} /> */}
       </>
        }
        {!authenticationService.currentUserValue &&
            <Route >
                {redirectToLoginCookie == '1' ? (
                    <LoginPage />
                ) : (
                    <Landing />
                )}
            </Route>
        }
    </>
    
)}