import './App.css';
import { Switch, Route, useHistory } from "react-router-dom";
import { Provider } from "react-redux";
import React, {useState, lazy, Suspense, useEffect} from 'react'
// import LoginPage from '../modules/Auth_LocalStorage/LoginPage/loginPageNew'
import { PrivateRoute } from './PrivateRoute';
import Footer from './Layout/Footer';
// import ContactPage from './pages/ContactPage';
import { useIdleTimer } from 'react-idle-timer'
import axios from "axios";
import Header from './Layout/Header'
import {decode} from 'jsonwebtoken'
import ErrorBoundary from "./pages/shared/ErrorBoundary"
// import { UsersUIProvider } from './pages/settings/settings-users/UsersUIContext';
import { QuickUser } from './Layout/QuickUser';
import Landing from './pages/landing/Landing';
import LoginPage from "../modules/Auth_LocalStorage/LoginPage/loginPageNew";
// import RegistrationPage from '../modules/Auth_LocalStorage/RegistrationPage/RegistrationPage';
// import AccountVerificationPage from '../modules/Auth_LocalStorage/AccountVerificationPage/AccountVerificationPage';

const Login = lazy(() => import('../modules/Auth_LocalStorage/LoginPage/loginPageNew'));
const Register = lazy(() => import('../modules/Auth_LocalStorage/RegistrationPage/RegistrationPage'));
const AccountVerify = lazy(() => import('../modules/Auth_LocalStorage/AccountVerificationPage/AccountVerificationPage'));
const Contact = lazy(() => import('./pages/ContactPage'));
const ForgotPassword = lazy(() => import('../modules/Auth_LocalStorage/ForgotPasswordModal'));


export default function App({ store, persistor, basename } ){
  const history = useHistory();
    const [redirectToLoginCookie,setRedirectToLoginCookie] = useState('');

  const evaluateToken = (token) => {
    if(token===undefined) { return false}; 
    const { exp } = decode(token.substring(7));
    if (Date?.now() >= exp * 1000) {
        return false;
      }else{
          return true;
      }
  }
  const [validAuth,setValidAuth] = useState(localStorage.currentUser ? evaluateToken(localStorage.currentUser):false)
  const handleOnIdle = event => {
    console.log('user is idle', event)
    console.log('last active', getLastActiveTime())
    axios.defaults.headers.common['Authorization'] = ''
    localStorage.removeItem('currentUser')
    localStorage.removeItem('currentUserEmail')
    setValidAuth(false)
    window.location.href = window.location.origin+'/login'
    
  }
  
  
  const handleOnActive = event => {
    console.log('user is active', event)
    console.log('time remaining', getRemainingTime())
  }

  const handleOnAction = event => {
    console.log('user did something on'+window.location.href, event)
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500
  })

  // const usersUIEvents = {
  //   openChangePasswordDialog: (id) => {
  //     history.push(`/users/${id}/changePassword`);
  //   }
  // }

    useEffect( () => {
        setRedirectToLoginCookie(document.cookie.split(';').find(cookie => cookie.startsWith('redirectToLogin='))?.split('=')[1]);
    }, [history]);
    return (
      <Provider store={store}>
        <div className={`${validAuth ? "App" : ""}`}>    
          <div className="d-flex flex-column min-vh-100">
          {/* <UsersUIProvider usersUIEvents={usersUIEvents}> */}
            {/* <Router> */}
              <Suspense fallback={<></>}>
              <Switch>
                {/* <Route exact path="/register">
                  <RegistrationPage />
                </Route> */}
                
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/register" component={Register} />

                {/* <Route path="/verify">
                  <AccountVerificationPage />
                </Route> */}
                <Route exact path="/verify" component={AccountVerify} />
                {/* <Route exact path="/login" component={Login} /> */}
                {/* <Route exact path="/contact">
                    <ContactPage />
                </Route> */}
                {!validAuth && <Route path="/contact" component={Contact} />}
                {!validAuth ?
                  <>
                  {/* <Route exact path="/login">
                    <LoginPage />
                  </Route> */}
  
                  
                  {/* <Route  path="/" > 
                    <Redirect to="/login" />
                  </Route> */}
                      <Route path="/">
                          {redirectToLoginCookie == '1' ? (
                              <Login />
                          ) : (
                              <Landing />
                          )}
                      </Route>
              
                  </> :
                  <>
                  <Header/>
                  
                  <ErrorBoundary>
                  <PrivateRoute></PrivateRoute>
                  </ErrorBoundary>

                  </>
                }
              </Switch>
              <Footer/>
              </Suspense>
              <QuickUser />
            {/* </Router>  */}
          {/* </UsersUIProvider> */}
          </div>
        </div>
      </Provider>
    ) 
}
