import axios from "axios";

export const SPECIFICATIONS_URL = "api/users";
export const URL = "http://localhost:8080/api/users";
export const CUSTOMERS_URL = process.env.REACT_APP_AUTO_DEALER_API+'user_admins';


export function getAllUsers(){
  return axios.get(CUSTOMERS_URL)
}
// CREATE =>  POST: add a new users to the server
export function createUser(user) {
  return axios.post(CUSTOMERS_URL, user);
}

// READ
// Server should return filtered users by userId
export function getAllProductUsersByUserId(userId) {
  return axios.get(`${CUSTOMERS_URL}?userId=${userId}`);
}

export function getUserById(userId) {
  return axios.get(`${CUSTOMERS_URL}/${userId}`);
}

// Server should return sorted/filtered users and merge with items from state
export function findUsers(queryParams, userId) {
  return axios.post(`${CUSTOMERS_URL}find/${userId}`, { queryParams });
}

// UPDATE => PUT: update the user
export function updateUser(user) {
  return axios.put(`${CUSTOMERS_URL}/${user.id}`, 
    user
  );
}

// DELETE => delete the user
export function deleteUser(userId) {
  return axios.delete(`${CUSTOMERS_URL}/${userId}`);
}

//Change Password
export function changePassword(userId,value) {
  return axios.post(`${CUSTOMERS_URL}/${userId}/changePassword`,value);
}

export function getDeleteValidationById(id){
  return axios.get(`${CUSTOMERS_URL}/validation/${id}`);
}


