import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { BatchTable } from "./batch-table/BatchTable";
import { BatchGrouping } from "./batch-grouping/BatchGrouping";
import { useBatchUIContext } from "./BatchUIContext";

export function BatchCard() {
  const batchUIContext = useBatchUIContext();
  const batchUIProps = useMemo(() => {
    return {
      ids: batchUIContext.ids,
      newBatchButtonClick: batchUIContext.newBatchButtonClick,
    };
  }, [batchUIContext]);

  return (
    <div>
           
    <div className="content d-flex flex-column-fluid" id="kt_content">
      
      <div className="container">
     
     
                    {/* <div className="flex-row-fluid ml-lg-8">	
							
		</div> */}
    <Card>
      <CardHeader title="Batch list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={batchUIProps.newBatchButtonClick}
          >
            New Batch
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {batchUIProps.ids.length > 0 && <BatchGrouping />}
        <BatchTable />
      </CardBody>
    </Card>
    
    </div>
    </div>
    </div>
   
  );
}
