import React from 'react'
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';

export class ProformaPrint extends React.PureComponent {
  render() {
    return (
        <div className="d-flex flex-column-fluid">
        <div className="container">
            <div className="card card-custom overflow-hidden">
                <div className={this.props.header ? "card-body p-0 px-10" : "card-body p-0 px-10"}>
                  <div className="row justify-content-center py-8 px-7 py-md-27 px-md-0 mb-8">
                       <div className="col-md-9">
                            
                            <div className="d-flex justify-content-between pt-6">

                              {/* <><div className="d-flex flex-column flex-root">
                                <span className="display-1 font-weight-boldest text-uppercase">PROXIMA</span>
                                    <span className="font-weight-boldest font-size-h3 pl-1" >isSubmitting </span>
                                </div>
                                <div className="d-flex flex-column flex-root">
                                </div>
                                <div className="d-flex flex-column flex-root">
                                </div>
                                <div className="d-flex flex-column flex-root font-size-h5" style={{paddingTop:'11px'}}>
                                    <span className="opacity-70">P.O. Box: 27030-1000 Addis Abeba</span>
                                    <span className="opacity-70">Tel: +251-912-50-76-18</span>
                                    <span className="opacity-70">Email: next</span>
                                    <span className="opacity-70">fisdsdsdsdsdx</span>
                                </div></>   */}
                            {this.props.header && <><div className="d-flex flex-column flex-root">
                                <span className="display-1 font-weight-boldest text-uppercase">{this.props.client?.header}</span>
                                    <span className=" font-size-h3 pl-2" >{this.props.client?.name} </span>
                                </div>
                                <div className="d-flex flex-column flex-root">
                                </div>
                                <div className="d-flex flex-column flex-root">
                                </div>
                                <div className="d-flex flex-column  text-right pr-1 font-size-h5" style={{paddingTop:'11px'}}>
                                    {this.props.client?.po_box && <span className="opacity-70">P.O. Box: {this.props.client?.po_box}</span>}
                                    {this.props.client?.telephone && <span className="opacity-70">Tel: {this.props.client?.telephone}</span>}
                                    {this.props.client?.email &&<span className="opacity-70">Email: {this.props.client?.email}</span>}
                                    <span className="opacity-70">{this.props.client?.web_site}</span>
                                </div></>}
                            </div>
                        </div>
                    </div> 
                    {!this.props.header && <>
                      
                            
                            <div className="d-flex justify-content-between pt-30"></div>
                            <div className="d-flex justify-content-between pt-16"></div>
                            <div className="d-flex justify-content-between pt-10"></div>
                            </>}
                    <div className="row justify-content-center py-8 px-8 py-md-27 px-md-0">
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between pt-10 pt-md-10 pb-10 pb-md-20 flex-column flex-md-row">
                                <h1 className="display-4 font-weight-boldest mb-5">PROFORMA INVOICE</h1>
                                
                            </div>
                            {/* <div className="border-bottom w-100"></div> */}
                            <div className="d-flex justify-content-between ">
                                <div className="d-flex flex-column flex-root">
                                    <span className="mb-2  font-size-h4 " >TO : <span className="font-weight-boldest">{this.props.values?.customer?.name}</span></span>
                                    {/* <span className="font-size-h4 ">{this.props.values?.customer?.name}</span> */}
                                </div>
                                
                                
                                
                                
                                <div className="d-flex flex-column ">
                                    <span className=" mb-2 text-right font-size-h4">DATE : <Moment format="DD-MM-YYYY">{this.props.values?.proforma_date}</Moment></span>
                                    {/* <span className="font-size-h4 "><Moment format="DD/MM/YYYY">{this.props.values?.proforma_date}</Moment></span> */}
                                </div>
                                
                            </div>
                            <div className="d-flex justify-content-between pt-3">
                                <div className="d-flex flex-column  flex-root ">
                                        <span className=" mb-2  font-size-h4">TIN NO : {this.props.values?.customer?.tin}</span>
                                        {/* <span className="font-size-h4 ">{this.props.values?.customer?.tin}</span> */}
                                    </div>
                                <div className="d-flex flex-column ">
                                    <span className=" mb-2 text-right font-size-h4 ">ORDER NO : P {this.props.values?.id}</span>
                                    {/* <span className="font-size-h4 ">P {this.props.values?.id}</span> */}
                                </div>
                                
                                
                                
                            </div>
                            
                        </div>
                    </div>
                    <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                        <div className="col-md-8">
                            <div className="table-responsive">
                                <table className="table proformaTable" >
                                    <tbody>
                                        <tr >
                                            <th className="pl-0 font-weight-bold  text-uppercase font-size-h4">Description</th>
                                            <th className=" font-weight-bold  text-uppercase font-size-h4">Qty</th>
                                            <th className="text-right font-weight-bold text-uppercase font-size-h4">Sales Amount</th>
                                            <th className="text-right pr-0 font-weight-bold  text-uppercase font-size-h4">Total Amount</th>
                                        </tr>
                                    
                                   
                                        {/* <tr>
                                            <td>Status</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr> */}
                                        {this.props.proformaItems?.map((items)=>
                                        (<tr key={items.id} className="">
                                            <td className="font-size-h4" style={{paddingLeft:0}}>{items.productName}
                                                <br/>
                                                {items?.chassis && this.props?.printOptions?.chassis && (<>Chassis: {items.chassis} <br/></>)}
                                                {items?.modelYear && this.props?.printOptions?.modelYear && (<>Model Year: {items.modelYear} <br/></>)}
                                                {items?.motorNo && this.props?.printOptions?.engineNo && (<>Engine No: {items.motorNo} <br/></>)}
                                                {items?.newOrUsed && this.props?.printOptions?.condition && (<>Condition: {items.newOrUsed} <br/></>)}
                                                {items?.color && this.props?.printOptions?.color && (<>Color: {items.color} <br/></>)}
                                                {items?.cc && this.props?.printOptions?.cc && (<>CC: {items.cc} <br/></>)}
                                                {items?.country && this.props?.printOptions?.countryOfOrigin && (<>Country of Origin: {items.country} <br/></>)}
                                                <br/>
                                            </td>
                                            <td className=" align-bottom font-size-h4">1</td>
                                            <td className="text-right align-bottom font-size-h4"><NumberFormat
                                                      value={items.proforma_amount+'.00'}
                                                      className="foo"
                                                      displayType={'text'}
                                                      thousandSeparator={true}
                                                      
                                                    /></td>
                                            <td className="align-bottom  text-right font-size-h4"><NumberFormat
                                                      value={items.proforma_amount+'.00'}
                                                      className="foo"
                                                      displayType={'text'}
                                                      thousandSeparator={true}
                                                    /></td>
                                        </tr>
                                        ))}
                                        
                                        {this.props.proformaItemTotalAmount ? <tr className="lastRow">
                                            <td className='bottomNone'></td>
                                            <td className='bottomNone'></td>
                                            <td className="font-size-h3 font-weight-boldest text-right bottomNone">Total Amount</td>
                                            <td className="font-size-h3 font-weight-boldest text-right bottomNone"><NumberFormat value={this.props.proformaItemTotalAmount+'.00'}
                                                displayType={'text'}
                                                thousandSeparator={true}/></td>
                                            
                                        </tr> : <tr></tr>}
                                    </tbody>
                                </table>
                                
                            </div>
                        </div>
                    </div>
                    
                    <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between">
                                <p><span className="font-weight-boldest font-size-h3" >TERMS OF SALE</span><br/>
                                <span className="font-size-h4"> Price includes 15% VAT</span><br/>
                                <span className="font-size-h4">Price Validity : {this.props.values?.expire ? this.props.values?.expire + " Days" : ""}</span><br/>
                                <span className="font-size-h4">Due Date : {this.props.values?.expire ? this.props.dueDate : ""} </span></p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between">
                            <span className="col-md-6 font-size-h4" style={{paddingLeft:'0px'}}>Approved by <input type="text" className="input-lg" style={{border:'none',
                                                        borderBottom:'1px solid rgb(102, 101, 101)',width: '30%'}}/></span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between pr-15" style={{position:'fixed',bottom:0,width:'100%',color:'#8d8c8c'}}>                                     
                            <span className="col-md-6 font-size-h4" style={{color:'#8d8c8c',paddingLeft:'38%'}}>Powered by AutoDealer <br></br>
                            <span className="col-md-6 font-size-h5" style={{color:'#8d8c8c'}}>&nbsp;&nbsp;&nbsp;www.autodll.com  </span></span>
                            </div>
                        </div>
                    
            </div>
        </div>
    </div>
    );
  }
}
