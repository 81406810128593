import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./SalesUIHelpers";
import { shallowEqual, useSelector } from "react-redux";

const SalesUIContext = createContext();

export function useSalesUIContext() {
  return useContext(SalesUIContext);
}

export const SalesUIConsumer = SalesUIContext.Consumer;

export function SalesUIProvider({salesUIEvents, children}) {
  const { sales_params } = useSelector(
    (state) => ({ sales_params: state.reports.sales_params }),
    shallowEqual
  );
  const [queryParams, setQueryParamsBase] = useState(sales_params || initialFilter);

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return <SalesUIContext.Provider value={value}>{children}</SalesUIContext.Provider>;
}