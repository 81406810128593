import React from "react";
import { AccountsUIProvider } from './AccountsUIContext';
import { AccountsList } from "./account-list/AccountsList";
import { AccountTransactionsList } from "./account-transaction-list/AccountTransactionsList";
import { Switch } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import { TransactionEditDialog } from "./transaction-edit-dialog/TransactionEditDialog";
import { AccountEditDialog } from "./account-edit-dialog/AccountEditDialog";
import { AccountDeleteDialog } from "./account-delete-dialog/AccountDeleteDialog";
import { TransactionDeleteDialog } from "./transaction-delete-dialog/TransactionDeleteDialog";
import { TransactionCloseDialog } from "./transaction-close-dialog/TransactionCloseDialog";
import { shallowEqual, useSelector } from "react-redux";
import { ClosedTransactionsDialog } from "./closed-transactions-dialog/ClosedTransactionsDialog";

export function AccountsPage({ history }) {
  const {auth} = useSelector((state) => ({ auth: state.auth }), shallowEqual)
  const {roles} = auth;

  const accountsUIEvents = {
    openListAccountsPage: () => {
      history.push("/account");
    },
    openTransactionsForAccountPage: (account_id) => {
      history.push(`/account/${account_id}/transaction`);
    },
    newTransactionButtonClick: (account_id) => {
      history.push(`/account/${account_id}/transaction/new`);
    },
    editTransactionButtonClick: (account_id, id) => {
      history.push(`/account/${account_id}/transaction/${id}/edit`);
    },
    deleteTransactionButtonClick: (account_id, id) => {
      history.push(`/account/${account_id}/transaction/${id}/delete`);
    },
    newAccountButtonClick: () => {
      history.push('/account/new');
    },
    editAccountButtonClick: (id) => {
      history.push(`/account/${id}/edit`);
    },
    deleteAccountButtonClick: (id) => {
      history.push(`/account/${id}/delete`);
    },
    closeTransactionsButtonClick: (account_id) => {
      history.push(`/account/${account_id}/transaction/close`);
    },
    showClosedTransactionsButtonClick: (account_id) => {
      history.push(`/account/${account_id}/transaction/closed`);
    }
  }

  // const location = useLocation();

  return (
    <div className="content d-flex flex-column-fluid" id="kt_content">
		  <div className="container">
      {roles && roles.includes("TransactionReport") && 
        <AccountsUIProvider accountsUIEvents={accountsUIEvents}>
          <Switch>
            <ContentRoute exact path="/account/new">
              {({ history, match }) => (
                <AccountEditDialog
                  show={match != null}
                  onHide={() => {
                    history.push('/account');
                  }}
                />
              )}
            </ContentRoute>
            <ContentRoute exact path="/account/:id/edit">
              {({ history, match }) => (
                <AccountEditDialog
                  id={match.params.id}
                  show={match != null}
                  onHide={() => {
                    history.push('/account');
                  }}
                />
              )}
            </ContentRoute>
            <ContentRoute exact path="/account/:id/delete">
              {({ history, match }) => (
                <AccountDeleteDialog
                  id={match.params.id}
                  show={match != null}
                  onHide={() => {
                    history.push('/account');
                  }}
                />
              )}
            </ContentRoute>
            <ContentRoute exact path="/account/:account_id/transaction/new">
              {({ history, match }) => (
                <TransactionEditDialog
                  account_id={match.params.account_id}
                  show={match != null}
                  onHide={() => {
                    history.push(`/account/${match.params.account_id}/transaction`);
                  }}
                />
              )}
            </ContentRoute>
            <ContentRoute exact path="/account/:account_id/transaction/:id/edit">
              {({ history, match }) => (
                <TransactionEditDialog
                  id={match.params.id}
                  account_id={match.params.account_id}
                  show={match != null}
                  onHide={() => {
                    history.push(`/account/${match.params.account_id}/transaction`);
                  }}
                />
              )}
            </ContentRoute>
            <ContentRoute exact path="/account/:account_id/transaction/:id/delete">
              {({ history, match }) => (
                <TransactionDeleteDialog
                  id={match.params.id}
                  account_id={match.params.account_id}
                  show={match != null}
                  onHide={() => {
                    history.push(`/account/${match.params.account_id}/transaction`);
                  }}
                />
              )}
            </ContentRoute>
            <ContentRoute exact path="/account/:account_id/transaction/close">
              {({ history, match }) => (
                <TransactionCloseDialog
                  account_id={match.params.account_id}
                  show={match != null}
                  onHide={() => {
                    history.push(`/account/${match.params.account_id}/transaction`);
                  }}
                />
              )}
            </ContentRoute>
            <ContentRoute exact path="/account/:account_id/transaction/closed">
              {({ history, match }) => (
                <ClosedTransactionsDialog
                  account_id={match.params.account_id}
                  show={match != null}
                  onHide={() => {
                    history.push(`/account/${match.params.account_id}/transaction`);
                  }}
                />
              )}
            </ContentRoute>
          </Switch>
          
          <Switch>
            <ContentRoute path="/account/:account_id/transaction" component={AccountTransactionsList} />
            <ContentRoute path="/account" component={AccountsList} />
          </Switch>
        </AccountsUIProvider>}
      </div>
    </div>
  );
}
