import React ,{ useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import {  useDispatch } from "react-redux";
import * as proformaActions from '../../../../_redux/proformas/proformasActions';
import * as saleActions from '../../../../_redux/sales/salesActions'

const DownShiftAutoComplete = ({customer,selectedCustomer,setCustomerName,values,setTouchedItem, focusAuto,setToggle,values1=null}) => {
  const customInputRef = useRef(null);
  // Customers UI Context
  
  //const names = [{name:'Betselot'},{name:'betse'},{name:'betse'}];
  

  useEffect(()=>{
    focusAuto && customInputRef.current.focus();
  }, []);

    //const suggestions = customer; //JSON.parse(JSON.stringify(this.props));
    const suggestions = customer==null ? [] : customer.map(item=>({ id: item.id, name: item.name,tin:item.tin,city:item.city,subcity:item.subcity,telephone:item.telephone }));
      function renderInput(inputProps) {
        const { InputProps, classes, ref, ...other } = inputProps;
        return (
          <TextField
          style={{
            fontFamily: 'Poppins, Helvetica, sans-serif'
          }}
            InputProps={{
              inputRef: customInputRef,
              classes: {
                root: classes.inputRoot,
                input: classes.inputInput,
              },
              ...InputProps,
            }}
            {...other}
          />
        );
      }
      
      function renderSuggestion(suggestionProps) {
        const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
        const isHighlighted = highlightedIndex === index;
        const isSelected = false;
        
        return (
          <MenuItem
            {...itemProps}
            key={suggestion.name}
            selected={isHighlighted}
            component="div"
            style={{
              fontWeight: isSelected ? 500 : 400,
              fontFamily: 'Poppins, Helvetica, sans-serif'
            }}
          >
          {suggestion.id==0 ? 'Create \"'+suggestion.name+'\"':suggestion.name}
          </MenuItem>
        );
      }
      renderSuggestion.propTypes = {
        highlightedIndex: PropTypes.number,
        index: PropTypes.number,
        itemProps: PropTypes.object,
        selectedItem: PropTypes.string,
        suggestion: PropTypes.shape({ name: PropTypes.string }).isRequired,
      };
      
      function getSuggestions(value, { showEmpty = false } = {}) {
        
        const inputValue = value!=undefined ? deburr(value.trim()).toLowerCase() : '';
        const inputLength = inputValue.length;
        let count = 0;
      
        return inputLength === 0 && !showEmpty
          ? []
          : suggestions.filter(suggestion => {
              const keep =
                count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;
      
              if (keep) {
                count += 1;
              }
      
              return keep;
            });
      }
    
    const useStyles = makeStyles(theme => ({
        root: {
          flexGrow: 1,
        },
        container: {
          flexGrow: 1,
          position: 'relative',
        },
        paper: {
          position: 'absolute',
          zIndex: 1,
          marginTop: theme.spacing(1),
          left: 0,
          right: 0,
        },
        chip: {
          margin: theme.spacing(0.5, 0.25),
        },
        inputRoot: {
          flexWrap: 'wrap',
          fontFamily:'Poppins, Helvetica, sans-serif',
        },
        inputInput: {
          width: 'auto',
          flexGrow: 1,
        },
        divider: {
          height: theme.spacing(1.35),
        },
      }));
      
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleCustomerChange = (selection) =>{
      //console.log(values1?.sale);
      dispatch(proformaActions.updateCustomer(selection,values))
      if(values1){
        dispatch(saleActions.updateCustomer(selection,values1?.sale))
      }
        if(selection != null){
            
            setCustomerName(selection)
           
            //dispatchEvent(action.updateCustomer(selection))
        }else{
            setCustomerName({});  
        }
    }
    const customerEditOpen= ()=>{
      setToggle(true)
    }

  return (
    <div className={classes.root}>
     
      
      <div className={classes.divider} />
      <Downshift id="downshift-options" selectedItem={selectedCustomer} itemToString={item => (item ? item.name : '')} onChange={selection => {handleCustomerChange(selection)
      if(selection?.id == 0){
        customerEditOpen()
    }}}>
        {({
          clearSelection,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          openMenu,
          selectedItem,
          
        }) => {
          const { onBlur, onChange,onSelect, onFocus, ...inputProps } = getInputProps({
            onChange: event => {
                if (event.target.value === '') {
                    clearSelection();
                  }else{
                    const label = event.target.value
                    const item = { name: label,id:0,tin:null,city:null,subcity:null,telephone:null}
                    const index = suggestions.findIndex(e=> e.id==0)  
                    if(index>-1){
                        suggestions[index] = item;
                        //customerEditOpen();
                    }else{
                        suggestions.push(item);
                    }
                  }
            },
            onBlur: event => {
              setTouchedItem("customer", true, true,);
            },
            
            placeholder: 'Write a customer',
  
          });

          return (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                InputLabelProps: getLabelProps({ shrink: true }),
                InputProps: { onBlur, onChange, onFocus,onSelect },
                inputProps,
              })}

              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {getSuggestions(inputValue, { showEmpty: true }).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion}),
                        highlightedIndex,
                        selectedItem,

                      }),
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
}

export default DownShiftAutoComplete;
