import axios from "axios";

export const SPECIFICATIONS_URL = "api/sales";
export const CUSTOMERS_URL = process.env.REACT_APP_AUTO_DEALER_API+'payments'

// CREATE =>  POST: add a new sales to the server
export function createSale(sale) {
  return axios.post(CUSTOMERS_URL+`/${localStorage.currentUserEmail}`, sale);
}
export function createListSale(sale,oldPaymentUUID) {
  return axios.post(CUSTOMERS_URL+`/listPayment/${localStorage.currentUserEmail}/${oldPaymentUUID}`, sale);
}

// READ
// Server should return filtered sales by saleId
export function getAllProductSalesBySaleId(saleId) {
  return axios.get(`${SPECIFICATIONS_URL}?saleId=${saleId}`);
}

export function getSaleById(saleId) {
  let url = process.env.REACT_APP_AUTO_DEALER_API+"payments/"+saleId;
  return axios.get(url);
}

export function findSalesInCar(carId){
  let url = process.env.REACT_APP_AUTO_DEALER_API+'product/' +carId+"/sale/"+localStorage.currentUserEmail

  return axios.get(url);
}

// Find all sales for the proforma
export function findSalesInProforma(proformaId){
  let url = `${process.env.REACT_APP_AUTO_DEALER_API}payments/proforma/${proformaId}`
  return axios.get(url);
}

// Server should return sorted/filtered sales and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/salesfind/{saleId}'. Should be 'api/sales/find/{saleId}'!!!
export function findSales(queryParams, saleId) {
  return axios.post(`${SPECIFICATIONS_URL}find/${saleId}`, { queryParams });
}

// fetch sales for report
export function findSalesForReport(queryParams) {
  return axios.post(`${CUSTOMERS_URL}/find/salesForReport`, { ...queryParams });
}

// fetch sales detail for report
export function findSalesDetailForReport(queryParams) {
  return axios.post(`${CUSTOMERS_URL}/find/salesDetailForReport`, { ...queryParams });
}

// fetch loan sales for report
export function findLoanSalesForReport(queryParams) {
  return axios.post(`${CUSTOMERS_URL}/find/loanSalesForReport`, { ...queryParams });
}

// fetch bank deposits for report
export function findBankDeposits(queryParams) {
  return axios.post(`${CUSTOMERS_URL}/find/bankDeposits`, { ...queryParams });
}

// UPDATE => PUT: update the sale
export function updateSale(sale) {
  return axios.put(`${CUSTOMERS_URL}/${sale.id}`,
    sale
  );
}

export function updateBulkSale(sales) {
  return axios.post(`${CUSTOMERS_URL}/bulk`,
    sales
  );
}

// DELETE => delete the sale
export function deleteSale(saleId,uuid) {
  return axios.delete(`${CUSTOMERS_URL}/${saleId}/${uuid}`);
}

// DELETE sales by ids
export function deleteSales(ids) {
  return axios.post(`${SPECIFICATIONS_URL}/deleteSales`, { ids });
}
