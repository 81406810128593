import { useState, useEffect,useLayoutEffect,useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
// import * as carActions from "../../../_redux/cars/carsActions";
import * as saleActions from "../../../_redux/sales/salesActions"
import { useLoanSalesUIContext } from "./LoanSalesUIContext";
import NumberFormat from 'react-number-format';
let initialFilter = { 
  filter: {
      category: "",
      year: "",
      color: "",
      batch: "",
      location: "",
  }
};

const ChildTable = ({data}) => {
  const LoanSalesUIContext = useLoanSalesUIContext();
  const salesUIProps = useMemo(() => {
    return {
      queryParams: LoanSalesUIContext.queryParams,
    };
  }, [LoanSalesUIContext]);
 
  const childTableColumns = [
    {
      dataField: 'id', 
      text: 'ID', 
      hidden: true, 
      classes: "border-0 py-3",
      headerClasses: "border-0 py-3"
    },
    {
      dataField: 'expected',
      text: 'Expected',
      classes: "text-right border-0 py-3",
      headerClasses: "text-right border-0 py-3",
      formatter: (cellContent, row) => (
        <span><NumberFormat thousandSeparator={true} value={row?.expected} displayType='text' /></span>
      ),
    }, 
    {
      dataField: 'collected',
      text: 'Collected',
      classes: "text-right border-0 py-3",
      headerClasses: "text-right border-0 py-3",
      formatter: (cellContent, row) => (
        <span><NumberFormat thousandSeparator={true} value={row?.collected} displayType='text' /></span>
      ),
    }, 

    {
      dataField: 'remaining',
      text: 'Remaining',
      classes: "text-right border-0 py-3",
      headerClasses: "text-right border-0 py-3",
      formatter: (cellContent, row) => (
        <span><NumberFormat thousandSeparator={true} value={row?.remaining} displayType='text' /></span>
      ),
    },
    
  ];

  return data ? (
    <BootstrapTable 
        classes="report-child table table-head-custom table-vertical-center mt-3 "
        headerClasses='nested'
        rowStyle={{ cursor: 'pointer' }}
        bordered={false}
        bootstrap4
        keyField='id'
        data={ data }
        columns={ childTableColumns }
    /> ) : ( <></>)
}

const LoanSalesReportMobile = () => {
  const [tableData, setTableData] = useState([]);
  const [footerData, setFooterData] = useState([]);

  const columns = [
    {
      dataField: 'id', 
      text: 'ID', 
      hidden: true, 
      classes: "border-0", 
      headerClasses: "border-0"
    },
    {
      dataField: 'make',
      text: 'Brand', 
      classes: "border-0", 
      headerClasses: "border-0",
      formatter: (cellContent, row) => (
        <div>{row?.model} {row?.year}</div>
      )
    }, 
    {
      dataField: 'vin',
      text: 'VIN', 
      classes: "text-left border-0",
      headerClasses: "text-left border-0",
      formatter: (cellContent, row) => (
        <div>{row?.vin.slice(-6)}</div>
      )
    }, 
    {
      dataField: 'customer',  
      text: 'Customer', 
      classes: "border-0", 
      headerClasses: "border-0", 
      formatter: (cellContent, row) => (
        <span>{row.customer.split(" ")[0] + " " + (row.customer.split(" ").length > 1 ? (row.customer.split(" ")[1]?.charAt(0) + ".") : "")}</span>
      )
    },
  ];

  const footerColumns = [
    {
      dataField: 'id', 
      text: 'ID', 
      hidden: true, 
      classes: "border-0",
      headerClasses: "border-0"
    },
    {
      dataField: 'title',
      text: 'Total', 
      classes: "border-0", 
      headerClasses: "border-0 d-none"
    }, 
    {
      dataField: 'val',
      text: 'Val', 
      classes: "border-0 text-right", 
      headerClasses: "border-0 d-none", 
      formatter: (cellContent, row) => (
        <span><NumberFormat thousandSeparator={true} value={row?.val} displayType='text' ></NumberFormat></span>
      ),
  }]

  const { dashboardStockReport: entities, dashboardStockReportDetail: detailEntities ,error,apiErrorData, salesForReport, salesDetailForReport,loanSalesForReport} = useSelector(
      (state) => ({ 
        dashboardStockReport: state.cars.dashboardStockReport,
        dashboardStockReportDetail: state.cars.dashboardStockReportDetail,
        error:state.cars.error,
        apiErrorData:state.cars.apiErrorData, 
        salesForReport: state.sales.salesForReport,
        salesDetailForReport: state.sales.salesDetailForReport,
        loanSalesForReport: state.sales.loanSalesForReport
      }),
      shallowEqual
  );

  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }
    }
  },[error])

  const dispatch = useDispatch();
  
  useEffect(() => {
      dispatch(saleActions.fetchLoanSalesForReport(initialFilter))
  }, [dispatch]);
  
  useEffect(() => {
      if(loanSalesForReport) {
          const salesList = []
          loanSalesForReport.forEach(i => {
            loanSalesForReport.forEach(j => {
              if(i.vin === j.vin) {
                i = {...i, expand: [...(i?.expand || []), {id: j.id, collected: j.collected, uncollected: j.uncollected, vin: j.vin, customer: j.customer, make: j.make, expected: j.expected, remaining: j.remaining}]}
              }
            })
            salesList.push(i)
          });
          setTableData(salesList)
          setFooterData([
            {id: 1, title: "Total Expected", val: loanSalesForReport.reduce((acc, s) => acc+s.expected, 0)}, 
            {id: 2, title: "Total Collected", val: loanSalesForReport.reduce((acc, s) => acc+s.collected, 0)}, 
            {id: 3, title: "Total Remaining", val: loanSalesForReport.reduce((acc, s) => acc+s.remaining, 0)},
          ])
      }
  }, [loanSalesForReport])

  const [expanded, setExpanded] = useState([])

  const handleExpand = (row, isExpand, rowIndex, e) => {
    isExpand ? setExpanded([...expanded, row.id]) : setExpanded(expanded.filter(ex => ex !== row.id))
  }

  const getNonExpandables = (rows) => {
    const unExpandables = []
    rows.forEach((row, i) => {
      if(row?.expand?.length === 0 || row?.expand === undefined) {
        unExpandables.push(i)
      }
    })
    return unExpandables
  }

  const expandRow = {
    renderer: row => (
        <ChildTable data={row.expand} />
    ),
    expanded: expanded,
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => <></>,
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      return (    
          expandable && (
            expanded ? (
                <i className="fa fa-caret-down" style={{width: "16px", height: "16px", color: "#0BB783"}}></i>
              ) : (
                <i className="fa fa-caret-right" style={{width: "16px", height: "16px", color: "#0BB783"}}></i>
              )
          )
      )
    },
    expandByColumnOnly: true, 
    onExpand: handleExpand,
    nonExpandable: getNonExpandables(tableData)
  };

  const rowStyle = (row, rowIndex) => {
    const style = {cursor: 'pointer'};
    if (expanded.find(id => row.id === id)) {
      style.backgroundColor = '#F3F6F9';
    }
    return style;
  };

  return (
    <>
      <BootstrapTable
        wrapperClasses="report-table table-responsive"
        classes="table table-head-custom table-vertical-center"
        rowStyle = {rowStyle}
        bordered={false}
        bootstrap4
        keyField='id'
        data={ tableData }
        columns={ columns }
        expandRow={ expandRow }
      />
      <BootstrapTable
        wrapperClasses="table-responsive border-top font-weight-bolder"
        classes="table table-head-custom table-vertical-center"
        bordered={false}
        bootstrap4
        keyField='id'
        data={ footerData }
        columns={ footerColumns }
      />
    </>
  )
}

export default LoanSalesReportMobile
