import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
// import UploadImage from "../../shared/UploadImage";
import * as carModelsActions from "./../../../_redux/car-models/carModelsActions"
import * as bodyStyleActions from "../../../_redux/body-style/bodyStylesActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, DatePickerField } from "./../../../../../_metronic/_partials/controls";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import AsyncSearchBar from "../../shared/AsyncSelectBar";

export function CarModelEditForm({
    saveCarModel,
    carModel,
    categoryEntities,
    actionsLoading,
    onHide
}) {

	const [previewImage, setPreviewImage] = useState();

  const selectFile = (file) => {
    setPreviewImage(URL.createObjectURL(file))
  }

  const dispatch = useDispatch();

	const { currentState } = useSelector(
		(state) => ({ currentState: state }),
		shallowEqual
	);

  const { entities: bodyStyleEntities } = currentState.bodyStyles;

  const duplicateCategory = (category, {make, model, year}) => {
    if (make && model && year) {
      if(category.id !== carModel.id) {
        if(category.make.toLowerCase() === make.toLowerCase() && category.model.toLowerCase() === model.toLowerCase() && category.year === year.getFullYear().toString()) {
          return true;
        }
      }
    }
    return false;
  };

  const CarModelEditSchema = Yup.object().shape({
    make: Yup.string()
      .trim()
      .required("Brand/Make is required"),
    model: Yup.string()
      .trim()
      .required("Model is required"),
    year: Yup.date()
      .nullable()
      .required("Year is required"),
    cc: Yup.string()
      .trim()
      .required("CC is required"),
    body_style_id: Yup.string()
			.required("Body Style is required"),
    picture: Yup.mixed()
      .test('file_type', 'File must be an image', value => {
        let valid = true;
        if (value && !(/image\/*/.test(value.type))) {
          valid = false;
        }
        return valid;
      })
      .test('file_size', 'Image cannot exceed 4MB', value => {
        let valid = true;
        if (value && ((value.size / 1024 / 1024) > 4 )) {
          valid = false;
        }
        return valid
      })
  })
  .test({
    test: function(values) {
      const duplicate_found = categoryEntities.some(entity => duplicateCategory(entity, values))
      if(!duplicate_found) return true;
      return this.createError({
        path: "year",
        message: "A brand already exists by the same Make-Model-Year",
      })
    }
  });

  useEffect(() => {
		dispatch(bodyStyleActions.fetchBodyStyles());
  }, [dispatch]);

	useEffect(() => {
    carModel = { ...carModel, body_style_id: (carModel.body_style_id || (bodyStyleEntities && bodyStyleEntities[0]?.id)) }
		// carModel.body_style_id = carModel.body_style_id || (bodyStyleEntities && bodyStyleEntities[0]?.id);
	}, [bodyStyleEntities]);
    
  useEffect(() => {
    if (carModel.id && !carModel.picture) {
      dispatch(carModelsActions.getCarModelImage(carModel.id))
    }

    if (carModel.picture) {
      let binaryData = [];
      binaryData.push(carModel.picture);
      let imgBlob = URL.createObjectURL(new Blob(binaryData));
      setPreviewImage(imgBlob)
    }
  }, [carModel])

  return (
	<>
    <Formik
      enableReinitialize={true}
      initialValues={
        {...carModel, remark: carModel.remark || ''}
      }
      validationSchema={CarModelEditSchema}
      onSubmit={(values) => {
        saveCarModel(values);
      }}
    >
    {({ handleSubmit, setFieldValue, errors, touched, setFieldTouched, values, initialValues,isSubmitting }) => (
    <BlockUi blocking={isSubmitting}>
      {/* <Modal.Body className="overlay overlay-block cursor-default"> */}
      <Modal.Body className="overlay cursor-default">
        {actionsLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}
        <Form className="form form-label-right">
          <div className="form-group row">
            {/* Make */}
            <div className="col-lg-6">
              <Field
                name="make"
                component={Input}
                placeholder="Brand/Make"
                label="Brand/Make"
                customFeedbackLabel="Car Maker"
                required
                onBlur={() => {
                  setFieldValue("make", values.make);
                  setFieldTouched("make", true, true)
                }}
              />
              {/* <div className="mt-3 mb-3 mb-lg-0">
                <span className="text-white px-3 py-2 rounded" style={{backgroundColor: '#F44723'}}>Eg: Toyota</span>
              </div> */}
              <span className="form-text text-muted">Eg: Toyota</span>
            </div>
            {/* Model */}
            <div className="col-lg-6">
              <Field
                name="model"
                component={Input}
                placeholder="Model"
                label="Model"
                customFeedbackLabel=" "
                required
                onBlur={() => {
                  setFieldValue("model", values.model);
                  setFieldTouched("model", true, true)
                }}
              />
              <span className="form-text text-muted">Eg: Corolla</span>
            </div>
          </div>
          <div className="form-group row">
            {/* Year */}
            <div className="col-lg-6">
              <div className="d-flex flex-column">
                <DatePickerField 
                  name="year" 
                  label="Year of manufacture" 
                  minDate={new Date("01-01-1900")} 
                  maxDate={new Date()}  
                  dateFormat="yyyy" 
                  showYearPicker 
                  autoComplete="off" 
                  customFeedbackLabel=" "
                  required
                />
                             

              </div>
            </div>
            {/* Body Style */}  
            <div className="col-lg-6">
                <label  id="aria-label" htmlFor="aria-example-input">
                  Body Style <span className="text-danger">*</span>
                </label>
                <AsyncSearchBar
                  selectedItem={{id: values.body_style_id, name: values.body}} 
                  setSelectedItem={setFieldValue}
                  setTouchedItem={setFieldTouched}
                  defaultItem={initialValues.body}
                  error={errors.body_style_id}
                  touched={touched.body_style_id}
                  item_id="body_style_id"
                  item_name="name"
                  url={`${process.env.REACT_APP_AUTO_DEALER_API}bodyStyles/search`}
                />
                {errors.body_style_id && touched.body_style_id && (
                  <div className="invalid-feedback d-block">{errors.body_style_id}</div>
                )}	
            </div>
          </div>
          <div className="form-group row">
            {/* CC */}
            <div className="col-lg-6">
              <Field
                name="cc"
                component={Input}
                placeholder="CC"
                label="Engine Capacity (CC)"
                customFeedbackLabel=" "
                required
              />
            </div>
            {/* Remark */}
            <div className="col-lg-6">
              <label>Remark</label>
              <Field
                  name="remark"
                  as="textarea"
                  className="form-control form-control-lg"
                />
            </div>
            {/* Picture */}
            <div className="col-lg-6">
              <label>Picture</label>
              {/* <UploadImage previewImage={previewImage} selectFile={selectFile}/> */}
              <div className="row">
                <div className="col-8">
                  <label className="btn btn-default p-0">
                    <input 
                      type="file" 
                      accept="image/*" 
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          selectFile(file);
                          setFieldValue('picture', file)
                          // setFieldTouched(fieldName, touched, validate)
                          setFieldTouched("picture", true, false)
                          // if file is selected return, so that we can handle cancel press separately
                          return;
                        }
                        // if cancel is pressed
                        if (errors.picture) {
                          setPreviewImage()
                          setFieldValue('picture', null)
                          delete errors.picture
                        }
                      }} 
                    />
                  </label>
                </div>
              </div>
              {!errors.picture && values.picture && previewImage && (
                <div>
                  <img className="preview img-fluid" src={previewImage} alt="" />
                </div>
              )}
              {errors.picture && touched.picture && (
                <div className="invalid-feedback d-block">{errors.picture}</div>
              )}
              <div className="card mt-3"></div>
          </div>
        </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary btn-elevate"
        >
          Save
        </button>
        <> </>
        <button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </button>
      </Modal.Footer>
    </BlockUi>
    )}
    </Formik>
	</>
  )
}
