export const groupAndSumSales = (salesInProforma) => {
    const groupedSales = {};

    salesInProforma && salesInProforma.forEach((sale) => {
        const { common_payment, amount, ...rest } = sale;

        if (common_payment !== null) {
            if (!groupedSales[common_payment]) {
                groupedSales[common_payment] = {
                    ...rest,
                    amount: 0,
                    common_payment
                };
            }

            groupedSales[common_payment].amount += amount;

            Object.keys(rest).forEach((key) => {
                if (rest[key] > groupedSales[common_payment][key]) {
                    groupedSales[common_payment][key] = rest[key];
                }
            });
        } else {
            groupedSales[sale.id] = { ...sale };
        }
    });

    return Object.values(groupedSales);

}

export const  sumProformaAmount = (currentProforma,t)=> {
    if(currentProforma){
        const { proformaItems } = currentProforma;
        let totalAmount = 0;

        for (const item of proformaItems) {
            if(t)
                totalAmount += item.sales_amount;
            else
                totalAmount += item.gps_amount;
        }

        return totalAmount;
    }
    return 0;
}
