import axios from "axios";

export const SPECIFICATIONS_URL = "api/employees";
export const EMPLOYEE_URL = process.env.REACT_APP_AUTO_DEALER_API+'employees'

export function getAllEmployees(){
  return axios.get(EMPLOYEE_URL)
}

// CREATE =>  POST: add a new employees to the server
export function createEmployee(employee) {
  return axios.post(`${EMPLOYEE_URL}`, employee );
}

// READ
// Server should return filtered employees by employeeId
export function getAllProductEmployeesByEmployeeId(employeeId) {
  return axios.get(`${SPECIFICATIONS_URL}?employeeId=${employeeId}`);
}

export function getEmployeeById(employeeId) {
  let url = `${EMPLOYEE_URL}/${employeeId}`;
  return axios.get(url);
}

export function findEmployeesInCar(carId){
  let url = process.env.REACT_APP_AUTO_DEALER_API+'product/' +carId+"/employee"
  
  return axios.get(url);
}

// Server should return sorted/filtered employees and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/employeesfind/{employeeId}'. Should be 'api/employees/find/{employeeId}'!!!
export function findEmployees(queryParams, employeeId) {
  return axios.post(`${SPECIFICATIONS_URL}find/${employeeId}`, { queryParams });
}

// UPDATE => PUT: update the employee
export function updateEmployee(employee) {
  return axios.put(`${EMPLOYEE_URL}/${employee.id}`, 
    employee
  );
}

export function updateBulkEmployee(employees) {
  return axios.post(`${EMPLOYEE_URL}/bulk`, 
    employees
  );
}

// DELETE => delete the employee
export function deleteEmployee(employeeId) {
  return axios.delete(`${EMPLOYEE_URL}/${employeeId}`);
}

// DELETE employees by ids
export function deleteEmployees(ids) {
  return axios.post(`${SPECIFICATIONS_URL}/deleteEmployees`, { ids });
}

export function getDeleteValidationById(id){
  return axios.get(`${EMPLOYEE_URL}/${id}/delete_validate`);
}
