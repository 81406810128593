import * as requestFromServer from './settingsCrud'
import { settingSlice,callTypes } from './settingSlice'

const {actions} = settingSlice;

export const fetchEthioBanks = () => dispatch => {
    
    return requestFromServer
      .fetchAllEthBanks()
      .then(response => {
        const ethiopianBanksEntities  = response.data;
        dispatch(actions.ethiopianBanksFetched({ ethiopianBanksEntities }));
      })
      .catch(error => {
        error.clientMessage = "Can't find ethio banks";
        dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
  };

export const fetchLocationTypes = () => dispatch => {
  return requestFromServer
    .fetchAllLocationTypes()
    .then(res => {
      const locationTypeEntities  = res.data;
      dispatch(actions.locationTypesFetched({ locationTypeEntities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find location types";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
