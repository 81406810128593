import React, { useMemo } from "react";
import { useCarModelsUIContext } from "../CarModelsUIContext";

export function CarModelsGrouping() {
  // CarModels UI Context
  const carModelsUIContext = useCarModelsUIContext();
  const carModelsUIProps = useMemo(() => {
    return {
      ids: carModelsUIContext.ids,
      setIds: carModelsUIContext.setIds,
      openDeleteCarModelsDialog: carModelsUIContext.openDeleteCarModelsDialog,
      openFetchCarModelsDialog: carModelsUIContext.openFetchCarModelsDialog,
      openUpdateCarModelsStatusDialog:
        carModelsUIContext.openUpdateCarModelsStatusDialog,
    };
  }, [carModelsUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  Selected records count: <b>{carModelsUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={carModelsUIProps.openDeleteCarModelsDialog}
              >
                <i className="fa fa-trash"></i> Delete All
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={carModelsUIProps.openFetchCarModelsDialog}
              >
                <i className="fa fa-stream"></i> Fetch Selected
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={carModelsUIProps.openUpdateCarModelsStatusDialog}
              >
                <i className="fa fa-sync-alt"></i> Update Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
