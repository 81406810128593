import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,

} from "../../../../../_metronic/_partials/controls";
import { Profile } from "./profile/Profile";
import { useProfileUIContext } from "./ProfileUIContext";

export function ProfileCard() {
  const profileUIContext = useProfileUIContext();
  const profileUIProps = useMemo(() => {
    return {
      ids: profileUIContext.ids
    };
  }, [profileUIContext]);

  return (
    <div>
           
    <div className="content d-flex flex-column-fluid" id="kt_content">
      
      <div className="container">
     
    <Card>
      <CardHeader title="Profile ">
        
      </CardHeader>
      <CardBody>
        <Profile />
      </CardBody>
    </Card>
    
    </div>
    </div>
    </div>
   
  );
}
