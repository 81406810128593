import React, { useMemo } from "react";
import { useBatchUIContext } from "../BatchUIContext";

export function BatchGrouping() {
  // Batch UI Context
  const batchUIContext = useBatchUIContext();
  const batchUIProps = useMemo(() => {
    return {
      ids: batchUIContext.ids,
      setIds: batchUIContext.setIds,
      openDeleteBatchDialog: batchUIContext.openDeleteBatchDialog,
      openFetchBatchDialog: batchUIContext.openFetchBatchDialog,
      openUpdateBatchStatusDialog:
        batchUIContext.openUpdateBatchStatusDialog,
    };
  }, [batchUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  Selected records count: <b>{batchUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={batchUIProps.openDeleteBatchDialog}
              >
                <i className="fa fa-trash"></i> Delete All
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={batchUIProps.openFetchBatchDialog}
              >
                <i className="fa fa-stream"></i> Fetch Selected
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={batchUIProps.openUpdateBatchStatusDialog}
              >
                <i className="fa fa-sync-alt"></i> Update Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
