/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./InspectionsUIHelper";

const InspectionsUIContext = createContext();

export function useInspectionsUIContext() {
  return useContext(InspectionsUIContext);
}

export const InspectionsUIConsumer = InspectionsUIContext.Consumer;

export function InspectionsUIProvider({ currentInspectionId, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [productId, setProductId] = useState(currentInspectionId);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const initInspection = {
    id: undefined,
    employee_id: '',
    inspection_date: new Date(),
    remark: "",
    productInspectionItems: []
  };
  const initProductInspectionItem = {
    id: null,
    inspection_id: null, 
    value: '', 
    remark: ''
  }
  useEffect(() => {
    initInspection.product_id = currentInspectionId;
    setProductId(currentInspectionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInspectionId]);
  const [
    showEditInspectionDialog,
    setShowEditInspectionDialog,
  ] = useState(false);
  const openNewInspectionDialog = () => {
    setSelectedId(undefined);
    setShowEditInspectionDialog(true);
  };
  const openEditInspectionDialog = (id) => {
    setSelectedId(id);
    setShowEditInspectionDialog(true);
  };
  const closeEditInspectionDialog = () => {
    setSelectedId(undefined);
    setShowEditInspectionDialog(false);
  };
  const [
    showDeleteInspectionDialog,
    setShowDeleteInspectionDialog,
  ] = useState(false);
  const openDeleteInspectionDialog = (id) => {
    setSelectedId(id);
    setShowDeleteInspectionDialog(true);
  };
  const closeDeleteInspectionDialog = () => {
    // setSelectedId(undefined);
    setShowDeleteInspectionDialog(false);
  };
  const [
    showDeleteInspectionsDialog,
    setShowDeleteInspectionsDialog,
  ] = useState(false);
  const openDeleteInspectionsDialog = () => {
    setShowDeleteInspectionsDialog(true);
  };
  const closeDeleteInspectionsDialog = () => {
    setShowDeleteInspectionsDialog(false);
  };
  const [
    showFetchInspectionsDialog,
    setShowFetchInspectionsDialog,
  ] = useState(false);
  const openFetchInspectionsDialog = () => {
    setShowFetchInspectionsDialog(true);
  };
  const closeFetchInspectionsDialog = () => {
    setShowFetchInspectionsDialog(false);
  };
  const value = {
    ids,
    setIds,
    productId,
    setProductId,
    queryParams,
    setQueryParams,
    initInspection,
    initProductInspectionItem,
    selectedId,
    showEditInspectionDialog,
    openEditInspectionDialog,
    openNewInspectionDialog,
    closeEditInspectionDialog,
    showDeleteInspectionDialog,
    openDeleteInspectionDialog,
    closeDeleteInspectionDialog,
    showDeleteInspectionsDialog,
    openDeleteInspectionsDialog,
    closeDeleteInspectionsDialog,
    showFetchInspectionsDialog,
    openFetchInspectionsDialog,
    closeFetchInspectionsDialog,
  };

  return (
    <InspectionsUIContext.Provider value={value}>
      {children}
    </InspectionsUIContext.Provider>
  );
}
