import React, { useMemo } from "react";
import moment from "moment";
// import { dateDiff } from '../../pages/shared/util';
import { useCarsUIContext } from "../../pages/cars/CarsUIContext";

const NotificationItem = ({ subNotification }) => {
  const carsUIContext = useCarsUIContext();
  const carsUIProps = useMemo(() => {
    return {
      openEditCarPage: carsUIContext.openEditCarPage,
    };
  }, [carsUIContext]);

  return subNotification?.map((items) => {
    const monthDifference = moment(items.warehouse_expiration_date).diff(moment().format("YYYY-MM-DD"), "M", true);
    // const expiresIn = dateDiff(items.warehouse_expiration_date, moment().format('YYYY-MM-DD'))
    // Show warehouses that will expire in two months
    if (monthDifference <= 2) {
      return (
        <div
          key={items.id}
          className={`d-flex align-items-center bg-light-${items.warehouse_expires_in > 0 ? "warning" : "danger"} rounded p-5 mb-5`}
          onClick={() => carsUIProps.openEditCarPage(items.id)}
        >
          <span className="svg-icon svg-icon-warning mr-5 ">
            <span className="svg-icon svg-icon-lg">
              <span className="">
                <i className="icon-2x  flaticon-coins"></i>
              </span>
            </span>
          </span>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a className={`sub-notification-item font-weight-bold text-dark-75 text-hover-${items.warehouse_expires_in > 0 ? "warning" : "danger"} cursor-pointer font-size-lg mb-1`}>
              {items.make} {items.model}
            </a>
            <span className="text-muted ">{items.vin}</span>
            <span className="text-muted ">
              {items.warehouse_expires_in > 0 ? `Expires in ${items.warehouse_expires_in}` : `Overdue by ${(items.warehouse_expires_in - 1) * -1}`} days
              {/* {expiresIn && <span>({expiresIn})</span>} */}
            </span>
          </div>
          <span className={`font-weight-bolder py-1 font-size-sm text-right`}>{items.year}</span>
        </div>
      );
    }
  });
};

export default NotificationItem;
