import React, { PureComponent } from 'react'
import { Document
  , Packer
  , Paragraph
  , TextRun
  , HeadingLevel
  , AlignmentType
  , UnderlineType

   } from "docx";
import { saveAs } from "file-saver";
import CurrencyToWords from '../../../../shared/CurrencyToWords/currencyToWords';
import moment from 'moment';
import axios from 'axios';

export default class ConstractOfSalesWord extends PureComponent {
    constructor(props){
        super(props)
        this.state={docCount:1}
        this.generate = this.generate.bind(this);
        
    }

    generate (){
        const doc = new Document({
          creator: "Betselot",
          title: "Contract Of Sales",
          description: "Contract Of Sales docx",
          
          styles: {
            default: {
                heading6: {
                    run: {
                        size: 24,
                        bold: true,
                        font:"Geez Able",
                    },
                    paragraph: {
                        spacing: {
                            after: 120,
                        },
                    },
                },
                heading2: {
                    run: {
                        size: 44,
                        bold: true,
                        font:"Geez Able",
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 120,
                        },
                    },
                },
                listParagraph: {
                    run: {
                        color: "#FF0000",
                    },
                },
            },
            paragraphStyles: [
                {
                    id: "aside",
                    name: "Aside",
                    basedOn: "Normal",
                    next: "Normal",
                    run: {
                        size:24,
                        font:"Geez Able",
                        color: "000000",
                    },
                    paragraph: {
                        // indent: {
                        //     left: convertInchesToTwip(0.5),
                        // },
                        spacing: { line: 400, before: 20 * 72 * 0.1, after: 20 * 72 * 0.1 },
                    },
                },
                
            ],
        },
            sections: [{
                properties: {},
                children: [
                  new Paragraph({
                    text: "ቀን "+localStorage.date,
                    style: "aside",
                    alignment:AlignmentType.RIGHT,
                    spacing: {
                      before: 2350,
                      after: 350,
                  },
                  }),
                  new Paragraph({
                    text: "የተሽከርካሪ ሽያጭ ውል ስምምነት",
                    heading: HeadingLevel.HEADING_2,
                    alignment:AlignmentType.CENTER,
                    spacing: {
                      after: 450,
                  },
                  }),
                  new Paragraph({
                    //text: this.props.content?.customerName+" "+this.props.content?.cars?.productName+"  የሻንሲ ቁጥር "+this.props.content?.cars?.chassis+"፣ ሞዴል "+ this.props.content?.cars?.modelYear +"፣ ቀለም "+this.props.content?.cars?.color+"፣ የተመረተበት ሀገር TODO የሆነውን ተሽከርካሪ ከአስመጪው ከ"+this.props.content?.supplierName+" በብር "+this.props.content?.priceFmt+".00 ("+CurrencyToWords(this.props.content?.price,'ብር')+") ለመግዛት በተስማሙት መሰረት የቅድሚያ ክፍያ ብር "+this.props.content?.advanceFmt+".00 ("+CurrencyToWords(this.props.content?.advance,'ብር')+") በዛሬው እለት የከፈሉ ሲሆን ቀሪውን ክፍያ ደግሞ በድርጅችቱ የባንክ ሂሳብ ገቢ ለማድረግ ተስማምተዋል።",
                    children: [
                    new TextRun({text:this.props.content?.customerName,
                                 underline: {
                                    type: UnderlineType.SINGLE,
                                }})
                    ,new TextRun(" "+this.props.content?.cars?.productName+" የሻንሲ ቁጥር "+this.props.content?.cars?.chassis+"፣ ሞዴል "+ this.props.content?.cars?.modelYear +"፣ ቀለም "+this.props.content?.cars?.color+"፣ የተመረተበት ሀገር "+this.props.content?.cars?.country+" የሆነውን ተሽከርካሪ ከአስመጪው ከ"+this.props.content?.companyName+"/"+this.props.content?.supplierName+" በብር ")
                    ,new TextRun({text:this.props.content?.priceFmt+".00 ("+CurrencyToWords(this.props.content?.price,'ብር')+")",
                      underline: {
                         type: UnderlineType.SINGLE,
                     }}
                      )
                    ,new TextRun(" ለመግዛት በተስማሙት መሰረት የቅድሚያ ክፍያ ብር ")
                    ,new TextRun({text:this.props.content?.advanceFmt+".00 ("+CurrencyToWords(this.props.content?.advance,'ብር')+")",
                      underline: {
                         type: UnderlineType.SINGLE,
                     }}
                      )
                    ,new TextRun(" በዛሬው እለት የከፈሉ ሲሆን ቀሪውን ክፍያ ደግሞ በድርጅችቱ የባንክ ሂሳብ ገቢ ለማድረግ ተስማምተዋል።")
                    ],
                    style: "aside",
                    alignment:AlignmentType.JUSTIFIED,
                    spacing: {
                      after: 350,
                  },
                  }),
                  new Paragraph({
                    //text: this.props.content?.supplierName+" ከላይ ለተገለጸው መኪና ግዢ ቀብድ ክፍያ ብር "+this.props.content?.advanceFmt+".00 ("+CurrencyToWords(this.props.content?.advance,'ብር')+") በዛሬው እለት "+this.props.content?.customerName+" የተቀበሉ ሲሆን ቀሪውን ክፍያ ደግሞ በድርጅታችን ስም በሚገኘው በ"+this.props.bankEntities?.name+" ሂሳብ ቁጥር "+this.props.bankEntities?.account_no+" ገቢ እንዲደረግ ተስማምተናል።",
                    children:[
                        new TextRun({
                            text:this.props.content?.supplierName,
                            underline: {
                                type: UnderlineType.SINGLE,
                            }
                        }),
                        new TextRun(" ከላይ ለተገለጸው መኪና ግዢ ቀብድ ክፍያ ብር "),
                        new TextRun({
                            text:this.props.content?.advanceFmt+".00 ("+CurrencyToWords(this.props.content?.advance,'ብር'),
                            underline: {
                                type: UnderlineType.SINGLE,
                            }
                        }),
                        new TextRun(") በዛሬው እለት "),
                        new TextRun({
                            text:this.props.content?.customerName,
                            underline: {
                                type: UnderlineType.SINGLE,
                            }
                        }),
                        new TextRun(" የተቀበሉ ሲሆን ቀሪውን ክፍያ ደግሞ በድርጅታችን ስም በሚገኘው በ"),
                        new TextRun({
                            text:this.props.content?.banks?.name+" ሂሳብ ቁጥር "+this.props.content?.banks?.account_no,
                            underline: {
                                type: UnderlineType.SINGLE,
                            }
                        }),
                        new TextRun(" ገቢ እንዲደረግ ተስማምተናል።")
                    ],
                    style: "aside",
                    alignment:AlignmentType.JUSTIFIED,
                    spacing: {
                      after: 350,
                  },
                  }),
                  new Paragraph(this.props.proforma?.expire ? {
                    text: "ይህ የሽያጭ ስምምነት በ"+CurrencyToWords(this.props.proforma?.expire,'') +"("+ this.props.proforma?.expire+") ቀናት ውስጥ ካልተጠናቀቀ ውሉ እንደፈርሰ ይቆጠራል ቀብድም ተመላሽ አይሆንም። ",
                    alignment:AlignmentType.JUSTIFIED,
                    style: "aside",
                    spacing: {
                      after: 600,
                  },
                  } : {}),
                  new Paragraph({
                    text: "የሻጭ ስምና ፊርማ",
                    alignment:AlignmentType.RIGHT,
                    heading: HeadingLevel.HEADING_6,
                    spacing: {
                      after: 1000,
                  },
                  }),
                  
                  new Paragraph({
                    text: this.props.content?.supplierName,
                    alignment:AlignmentType.RIGHT,
                    style: "aside",
                    spacing: {
                      after: 1000,
                  },
                  }),
                ],
            }],
        });
          Packer.toBlob(doc).then(blob => {
            //console.log(blob);
            saveAs(blob, this.props.content?.customerName+" bank(Bank Sales - Contract with Advance).docx");
            var body = {
                id:0,
                name:"Bank Contract of Sales",
                date:moment(),
                value:this.state.docCount,
                type:"Docx",
                param:JSON.stringify(this.props.content)
            }
            var url =  process.env.REACT_APP_AUTO_DEALER_API+"logger/proformaLoggerDoc";
            axios.post(url, body ).then((response)=>{
                if(response.data?.value!=undefined && response.data?.value < 9999){
                    this.setState({docCount:response.data?.value+1})
                }else if (response.data?.value >= 9999) {
                    this.setState({docCount:1})
                }
              });
          });
    }



  render() {
    return (
        <button onClick={this.generate} hidden id="docxButton">Generate docx!</button>
    )
  }
}
