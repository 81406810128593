// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,


} from "./../../../../../../_metronic/_partials/controls";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';



export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  openDeleteCustomerDialog

}) {
  
  Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
    return this.test("unique", message, function (list) {
      return list.length === new Set(list.map(mapper)).size
    })
  })
  // Validation schema
  const CustomerEditSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Name is required")
      .test(
        'duplicateName',
        'Duplicate Customer Name',
        function(value){
              return this.parent.customers?.filter(
                (item)=>{
                  return item.name == value && item.id != this.parent.id
                }
              ).length == 0
          }
        )
  });
  
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {

          saveCustomer(values);
        }}
      >
        {({ handleSubmit,values,errors,isSubmitting }) => (
          <BlockUi blocking={isSubmitting}>
            <Modal.Body className="overlay  cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Name */}
                  
                  <div className="col-lg-10">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      withFeedbackLabel= {true}
                      required
                    />
                  </div>
                  </div>
                  {/* Telephone */}
                  <div className="form-group row">
                  <div className="col-lg-10">
                    <Field
                      name="telephone"
                      component={Input}
                      placeholder="Telephone"
                      label="Telephone"
                      withFeedbackLabel= {false}
                    />
                  </div>
                  </div>
                  {/* Tin */}
                  <div className="form-group row">
                  <div className="col-lg-10">
                    <Field
                      name="tin"
                      component={Input}
                      placeholder="Tin"
                      label="Tin"
                      withFeedbackLabel= {false}
                    />
                  </div>
                  </div>
                {/* Subcity */}
                <div className="form-group row">
                  <div className="col-lg-5">
                    <Field
                      
                      name="subcity"
                      component={Input}
                      placeholder="Enter Subcity"
                      label="Subcity"
                      withFeedbackLabel= {false}
                    />
                  </div>
                  
                  {/* City */}
                  <div className="col-lg-5">
                    <Field
                      name="city"
                      component={Input}
                      placeholder="Enter City"
                      label="&nbsp;"
                      withFeedbackLabel= {false}
                    />
                  </div>
                </div>
                
              </Form>
            </Modal.Body>
            <Modal.Footer>
            <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate mr-auto"
              >
                Cancel
              </button>
              { values.id != undefined &&
              <button
                type="button"
                onClick={() => openDeleteCustomerDialog(values.id)}
                className="btn btn-danger btn-elevate ml-auto"
              >
                Delete
              </button>}
              <> </>
            
            </Modal.Footer>
          </BlockUi>
        )}
      </Formik>
    </>
  );
}
