import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Pie extends Component {

  constructor(props) {
    super(props);
    const insideName=this.props.insideName
    this.state = {
      options: {
        labels: this.props.labels,
        tooltip: {
         
          y: {
            formatter: function(val) {
              return '' 
            },
            title: {
              formatter: function (seriesName,{ seriesIndex, dataPointIndex, w }) {
                return insideName[seriesIndex]
              }
            }
          }
        },
        dataLabels: {
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            return value.toFixed(2)  + "% ( " + w.config.series[seriesIndex]+" )" //value + "%" + '=' + 
          },
          
        },
        responsive: [
          {
            breakpoint: 550,
            options: {
              
              legend: {
                position: "bottom"
              }
            }
          }
        ]
        
      },
      series: this.props.series

    }
  }

  render() {

    return (
      <div className="pie">
        <Chart options={this.state.options} series={this.state.series} type="pie" />
      </div>
    );
  }
}

export default Pie;
