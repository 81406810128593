import React, { useCallback, useMemo, useRef, useState ,useEffect,forwardRef} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { useReactToPrint } from "react-to-print";
import moment from 'moment'
import $ from 'jquery';

const StockAGGrid = forwardRef((props, ref)  => {
  const gridRef = useRef();
  const {ref1,ref2} = ref;
  const {tableData,columnDefs,clientEntities,stockUIProps,batchEntities,entities} = props;

  const containerStyle = useMemo(() => ({height: (window.screen.height * 49/100) }), []);
  const gridStyle = useMemo(() => ({ height: '100%' }), []);
  const [rowData, setRowData] = useState();
  const [pinnedRowData,setPinnedRowData] = useState([])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      suppressSizeToFit: true 
    };
  }, []);

  useEffect(()=>{
    setRowData(tableData || []);
  },[tableData])

  useEffect(()=>{
    setPinnedRowData([
      {
        'car':{'id':"", 'category':"GRAND TOTAL"},
        "detail":{
          'year': "", 
          'color': "",
          'batch': "", 
          "value": `${entities.reduce((accumulator, current) => accumulator + current.value, 0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`, 
          "quantity": `${entities.reduce((accumulator, current) => accumulator + current.quantity, 0)}`
        },
      "span":-2
      }
    ]);   
  },[entities])

  const onGridReady = useCallback((params) => {
    setRowData(tableData)
  }, []);
  
  const onBtnExport = useCallback(() => {
    var params = {
      fileName: 'stock_report.csv',
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const gridOptions = {
    rowClassRules: {
      // row style function
      'ag-custom-rowTotal': (params) => {
        const isaTotalRow = (params.data.car?.category === 'Total' || params.data.car?.category.toLowerCase() === 'grand total')
        return isaTotalRow;
      },
    },
  }

  const componentRef = useRef(null);
  const today = moment().format("DD-MM-YYYY")
  const handlePrint = useReactToPrint({
    documentTitle: `Stock Report _ ${today}`,
    content: () => {
      const contentToPrint = componentRef.current.cloneNode(true)
      contentToPrint.querySelectorAll('span.svg-icon').forEach(icon => icon.remove())
      return contentToPrint
    },
  });
  const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.height = '';
    eGridDiv.style.width ='1300px'
    api.setDomLayout('print');
  };
  
  const setNormal = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.height = '100%';
    eGridDiv.style.width = '100%';
    api.setDomLayout();
  };
  const onBtPrint = useCallback(() => {
    const api = gridRef.current.api;
    setPrinterFriendly(api);
    setTimeout(function () {
      handlePrint();
      setNormal(api);
      $('#bankContainer').removeAttr("style");
      $('#blockConstiner').hide()
    },3000);
  }, []);

  const autoSizeAll = (skipHeader) => {
    if(rowData!=undefined && rowData.length >0 ){
    const allColumnIds = [];
    gridRef?.current?.columnApi?.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef?.current?.columnApi?.autoSizeColumns(allColumnIds, skipHeader);
    }
  }
  
  function rowHeight (params) {
    return 36;
  }

  return (
    <div style={containerStyle} ref={componentRef}>
      <div>
        <input
          ref={ref1}
          hidden
          className="btn btn-success"
          onClick={onBtnExport}
        />
        <button ref ={ref2} hidden onClick={onBtPrint}>Print</button>
        <button id="autoSizeId" hidden onClick={() => autoSizeAll(true,rowData)}></button>
      </div>
      <div className="header mb-5">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="flex-grow-1 text-center">Stock Report</h1>
          <div className="d-flex justify-content-between">
        <p className="flex-grow-1 text-right mr-20">{clientEntities?.shortName}</p>
        </div>  
        </div>
        <div>
          {stockUIProps.queryParams.filter.category && <div>Make/Brand: &nbsp; {stockUIProps.queryParams.filter.category}</div>}
          {batchEntities && stockUIProps.queryParams.filter.batch && <div>Batch: &nbsp; {batchEntities.find(b => b.id == stockUIProps.queryParams.filter.batch).name}</div>}
          <div> {stockUIProps.queryParams.filter.free && 'Free'}</div>
          <div> {stockUIProps.queryParams.filter.inProcess && 'In-Process'} </div>
        </div>
        <div className="d-flex justify-content-between pr-15" style={{position:'fixed',bottom:0,width:'100%',color:'#8d8c8c'}}>                                     
          <span className="col-md-6 font-size-h4" style={{color:'#8d8c8c',paddingLeft:'38%'}}>Powered by AutoDealer <br></br>
          <span className="col-md-6 font-size-h5" style={{color:'#8d8c8c'}}>&nbsp;&nbsp;&nbsp;www.autodll.com  </span></span>
        </div>
      </div>
      
      <div id="myGrid" style={gridStyle}  className="ag-theme-material ">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          ref={gridRef}
          pinnedBottomRowData={pinnedRowData}
          gridOptions={gridOptions}
          // getRowHeight={rowHeight}
        />
      </div>
    </div>
  );
});

export default StockAGGrid;
