export const defaultSorted = [{ dataField: "date", order: "desc" }];
export const sizePerPageList = [
  { text: "50", value: 50 },
  { text: "100", value: 100 }
];
export const initialFilter = {
  sortOrder: "desc",
  sortField: "date",
  pageNumber: 1,
  pageSize: 50
};

export const initAccount = {
	id: undefined, 
	name: '', 
    currency: '',
};

export const initTransaction = {
	id: undefined, 
	account_id: '', 
    date: new Date(),
    rate: '', 
    description: "", 
    transfer: null, 
    payment: null, 
    amount: null, 
    isTransfer: true,
    registered_by: localStorage.getItem('currentUserEmail'), 
    closed_date: null
};
