import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import {  useHistory } from "react-router-dom"
import { authenticationService } from '../../_services/index';
import '../../../../assets/css/pages/login/login-2.css'
import '../../../../assets/plugins/global/plugins.bundle.css'
import '../../../../assets/plugins/custom/prismjs/prismjs.bundle.css'
import '../../../../assets/css/style.bundle.css'
import SendOTPForm from '../SendOTPForm/SendOTPForm';
import { ChangePasswordForm } from '../ChangePasswordForm';



const EnterOTPForm = (props) => {

	const [clientUsername, setClientUsername] = useState(props.username);
	const [clientUsernameForDisplay, setClientUsernameForDisplay] = useState(props.username);
	const [clientEmail] = useState(props.email);
	const [clientId, setClientId] = useState('');

	const [minutes, setMinutes] = useState(1);
	const [seconds, setSeconds] = useState(30);


	const historyUSE = useHistory()
	useLayoutEffect(() => {
		if (authenticationService.currentUserValue) {
			historyUSE.push('/dashboard');
		} else {
			if (!(window.location.href).indexOf('/login'))
				historyUSE.push('/login');
		}
	}, []);


	useEffect(() => {
		const interval = setInterval(() => {
		  if (seconds > 0) {
			setSeconds(seconds - 1);
		  }
	  
		  if (seconds === 0) {
			if (minutes === 0) {
			  clearInterval(interval);
			} else {
			  setSeconds(59);
			  setMinutes(minutes - 1);
			}
		  }
		}, 1000);
	  
		return () => {
		  clearInterval(interval);
		};
	  }, [seconds]);


	return (
		<div className="login login-2 login-signin-on  flex-lg-row flex-column-fluid bg-white" >

			<Formik
				enableReinitialize={true}
				initialValues={{
					otp:  '',
				}}
	

				onSubmit={({ otp }, {setStatus, setSubmitting }) => {
					setStatus();
					const validate = {
						"otp" : otp,
						"username" : clientUsername,
					};
					authenticationService.verifyOTP(validate)
						.then(
							(response) => {
								setSubmitting(false);
								setClientId(response.response.data);
								setClientUsername('');
							},
							error => {
								setSubmitting(false);
								setStatus(error);
							}
						);

				}}

				render={({ errors, status, touched, isSubmitting }) => (

					<>
					{ clientUsernameForDisplay !== '' && clientId == '' ?

							<Form>
								<div className="form-group">
									<label className="font-size-h6 font-weight-bolder text-dark">Enter Code here</label>
									<Field name="otp"   maxLength="6"  style={{"fontSize": "35px", "letterSpacing": "10px"}} className={'form-control text-center font-weight-bolder form-control-solid h-auto py-7 px-6 rounded-lg' + (errors.otp && touched.otp ? ' is-invalid' : '')} type="text" autoComplete="off" />
									<ErrorMessage name="otp" component="div" className="invalid-feedback" />
								</div>
								<div className="text-center pt-2">
									<button type="submit" disabled={isSubmitting} className={isSubmitting ? "btn btn-primary spinner spinner-white spinner-right btn-lg" : "btn btn-primary btn-lg"}>
										Validate
									</button>

									 <div className="countdown-text mt-3">
										{seconds > 0 || minutes > 0 ? (
											<p>
											Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
											{seconds < 10 ? `0${seconds}` : seconds}
											</p>
										) : (
											<p>Didn't recieve code?</p>
										)}

										<button className="btn btn-sm"
											disabled={seconds > 0 || minutes > 0}
											style={{
											color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
											}}
											onClick = {() =>{
												setClientUsernameForDisplay('');
											}}
											
										>
											Resend Code
										</button>
										</div>

								</div>
								{status &&
									<div className={'alert alert-danger'}>{status}</div>
								}
							</Form>
							:
							<>
							{ clientId == '' ?
							<SendOTPForm username={clientUsername}  email={clientEmail} />
							:
							<ChangePasswordForm username={clientId} />
						
								}
							</>

							}

					</>
			

				)}
			/>



		</div>
	)
}

export default EnterOTPForm;