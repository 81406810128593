/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "./../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/sales/salesActions";
import { useSalesUIContext } from "./SalesUIContext";

export function SaleDeleteDialog() {
  // Sales UI Context
  const specsUIContext = useSalesUIContext();
  const specsUIProps = useMemo(() => {
    return {
      id: specsUIContext.selectedId,
      productId: specsUIContext.productId,
      show: specsUIContext.showDeleteSaleDialog,
      onHide: specsUIContext.closeDeleteSaleDialog,
      onHideEdit: specsUIContext.closeEditSaleDialog,
      queryParams: specsUIContext.queryParams,
      setIds: specsUIContext.setIds,
      showChangePro:specsUIContext.openDeleteChangeProformaDialog,
      oldUuid:specsUIContext.oldUuid,
    };
  }, [specsUIContext]);

  // Specs Redux state
  const dispatch = useDispatch();
  const { isLoading,carForEdit ,/*saleEntities,*/ salesInProforma, validProforma,proformaItems} = useSelector(
    (state) => ({ isLoading: state.sales.actionsLoading
                ,carForEdit:state.cars.carForEdit,
                // saleEntities: state.sales.saleEntities,
                salesInProforma: state.sales.salesInProforma,
                validProforma: state.proformas.validProforma,
      proformaItems: state.proformas.proformaItems
      }),
    shallowEqual
  );

  const sellInfo = useMemo(()=>{
    return {
       //salesAmount : validProforma && validProforma?.sales_amount,
       gpsAmount : validProforma && validProforma?.gps_amount,
      //  collectedAmount : saleEntities?.reduce((accumulator, current) => accumulator + current.amount, 0)
      collectedAmount : salesInProforma?.reduce((accumulator, current) => accumulator + current.amount, 0),
      salesAmount:proformaItems?.reduce((accumulator,current)=> accumulator + current.sales_amount + current.gps_amount,0),
    };
  },[validProforma,/*saleEntities*/ salesInProforma])

  // if !id we should close modal
  useEffect(() => {
    if (!specsUIProps.id) {
      specsUIProps.onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specsUIProps.id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteSale = () => {
    // server request for deleting spec by id
    dispatch(actions.deleteSale(specsUIProps.id,specsUIProps.oldUuid)).then(() => {
      specsUIProps.setIds([]);
      specsUIProps.onHide();

      // refresh list after deletion
      if(carForEdit?.saleStatus === 'Sold'){
        const expectedAmount = (Number(sellInfo.salesAmount) + Number(sellInfo.gpsAmount)) || carForEdit?.price
        const remainingAmount = expectedAmount - sellInfo.collectedAmount
        // const nowAmount = saleEntities.filter((item)=>item.id === specsUIProps.id)[0].amount || 0;
        const nowAmount = salesInProforma.filter((item) => item.id === specsUIProps.id)[0].amount || 0;
        if(remainingAmount+nowAmount > 0){
          specsUIProps.showChangePro(validProforma?.proforma_id);
        }
      }
      dispatch(actions.fetchSalesInCar(specsUIProps.productId));
      // specsUIProps.setIds([]);
      // specsUIProps.onHide();
      // specsUIProps.onHideEdit();
    });
  };

  return (
    <Modal
      show={specsUIProps.show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Sale Delete
        </Modal.Title>
        <Nav className="ml-auto" onClick={() => specsUIProps.onHide()}>
        <Nav.Link className="p-0 text-dark">
          <span className="nav-icon">
            <span className="svg-icon svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                  </g>
                </g>
              </svg>
            </span>
          </span>
        </Nav.Link>
      </Nav>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete this sale?</span>
        )}
        {isLoading && <span>Sale is deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div className="mr-auto">
          <button
            type="button"
            onClick={specsUIProps.onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteSale}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
