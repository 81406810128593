import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/colors/colorsActions";
import {ColorEditDialogHeader} from "./ColorEditDialogHeader";
import { ColorEditForm } from "./ColorEditForm";
import { useColorsUIContext } from "../ColorsUIContext";

export function ColorEditDialog({ id, show, onHide }) {
  // Colors UI Context
  const colorsUIContext = useColorsUIContext();
  const colorsUIProps = useMemo(() => {
    return {
      initColor: colorsUIContext.initColor,
      openDeleteColorDialog: colorsUIContext.openDeleteColorDialog,
    };
  }, [colorsUIContext]);

  // Colors Redux state
  const dispatch = useDispatch();
  const { actionsLoading, colorForEdit ,colors} = useSelector(
    (state) => ({
      actionsLoading: state.colors.colorLoading,
      colorForEdit: state.colors.colorForEdit,
      colors:state.colors.colorEntities
    }),
    shallowEqual
  );
  const colorForValidation = colorForEdit==undefined ?  {...colorsUIProps.initColor,colors}: {...colorForEdit,colors}
  useEffect(() => {
    // server call for getting Color by id
    dispatch(actions.fetchColor(id));
  }, [id, dispatch]);

  // server request for saving color
  const saveColor = (color) => {
    if (!id) {
      // server request for creating color
      dispatch(actions.createColor(color)).then(() => onHide());
    } else {
      // server request for updating color
      dispatch(actions.updateColor(color)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="md"
      show={show}
      // onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <ColorEditDialogHeader id={id} onHide={onHide} />
      <ColorEditForm
        saveColor={saveColor}
        actionsLoading={actionsLoading}
        color={colorForValidation}
        onHide={onHide}
        openDeleteColorDialog={colorsUIProps.openDeleteColorDialog}
      />
    </Modal>
  );
}
