import React, { useLayoutEffect, useState } from 'react';
import { Formik,  Form, ErrorMessage } from 'formik';
import { Link, useHistory } from "react-router-dom"
import { authenticationService } from '../../_services/index';
import '../../../../assets/css/pages/login/login-2.css'
import '../../../../assets/plugins/global/plugins.bundle.css'
import '../../../../assets/plugins/custom/prismjs/prismjs.bundle.css'
import '../../../../assets/css/style.bundle.css'
import ForgotPasswordModal from '../ForgotPasswordModal';
import EnterOTPForm from '../EnterOTPForm/EnterOTPForm';



const SendOTPForm = (props) => {

	const [clientUsername, setClientUsername] = useState(props.username);
	const [clientEmail] = useState(props.email);
	const [enterOTP, setEnterOTP] = useState(false);


	const historyUSE = useHistory()
	useLayoutEffect(() => {
		if (authenticationService.currentUserValue) {
			historyUSE.push('/dashboard');
		} else {
			if (!(window.location.href).indexOf('/login'))
				historyUSE.push('/login');
		}
	}, [historyUSE]);




	const showForgotPassword = () => {
		setClientUsername('');
	}

	return (
		<div className="login login-2 login-signin-on  flex-lg-row flex-column-fluid bg-white" >

			<Formik
				enableReinitialize={true}
				initialValues={{
					
				}}
				
				onSubmit={({ username }, {setStatus, setSubmitting }) => {
					setStatus();
					authenticationService.sendOtp(clientUsername)
					.then(
							response => {
							setSubmitting(false);
							setEnterOTP(true);
						},
						error => {
							setSubmitting(false);
							setStatus(error);
						}
					);
					
				
				}}

				render={({ errors, status, touched, isSubmitting }) => (

					<>
					{ clientUsername !== ''  && !enterOTP ?
					<Form>
					<div className="form-group">
						<label className="font-size-h3 font-weight-bolder text-dark">Email:<h3>{clientEmail}</h3></label>
						<ErrorMessage name="email" component="div" className="invalid-feedback" />
						<p>Receive a code through the above email to reset password</p>
					</div>
					<div className="text-center pt-2">

					
						<Link to="#"  className="btn btn-light btn-lg mr-3" onClick={showForgotPassword}>Go Back</Link>  

				
						 <button type="submit" 
							name="sendOTP" disabled={isSubmitting} className={isSubmitting ? "btn btn-primary spinner spinner-white spinner-right btn-lg" : "btn btn-primary btn-lg"}>
							Send Code
						</button> 
					</div>
					{status &&
						<div className={'alert alert-danger'}>{status}</div>
					}
				</Form>
				:
				<>
					{ clientUsername == '' ?
						<ForgotPasswordModal />
					:
						<EnterOTPForm username={clientUsername}  email={clientEmail} />
					}
				</>

				}
				
				</>
				
				)}
			/>



		</div>
	)
}

export default SendOTPForm;