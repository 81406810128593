import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone }  from "react-bootstrap-table2-paginator";
import NumberFormat from "react-number-format";
import moment from "moment/moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/accounts/accountsActions";
import * as uiHelpers from "../AccountUIHelpers";
import { useAccountsUIContext } from "../AccountsUIContext";
import { NoRecordsFoundMessage, PleaseWaitMessage, sortCaret, } from "../../../../../_metronic/_helpers";
import { PaginationLinks, Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";
import cloneDeep from 'lodash/cloneDeep';
import { Link } from "react-router-dom";
import * as columnFormatters from "./column-formatters";

export function AccountTransactionsList({  
	history,
    match: {
      params: { account_id },
  }, }) {

  // Accounts UI Context
  const accountsUIContext = useAccountsUIContext();
  const accountsUIProps = useMemo(() => {
    return {
      queryParams: accountsUIContext.queryParams,
      setQueryParams: accountsUIContext.setQueryParams,
      newTransactionButtonClick: accountsUIContext.newTransactionButtonClick,
      openEditTransactionDialog: accountsUIContext.editTransactionButtonClick, 
      openDeleteTransactionDialog: accountsUIContext.deleteTransactionButtonClick,
      closeTransactions: accountsUIContext.closeTransactonsButtonClick,
      showClosedTransactions: accountsUIContext.showClosedTransactionsButtonClick,
    };
  }, [accountsUIContext]);

  // Getting curret state of products list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.accounts }),
    shallowEqual
  );
  const { 
    accountForEdit: account,
    transactionTotalCount: totalCount, 
    transactionEntities: entities, 
    closedTransactionEntities: closedTransactionEntities,
    closedTransactionForEdit: closedTransactionForEdit,
  } = currentState;
  const [tableData, setTableDate] = useState([]);

  // Products Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    if(account_id) {
      dispatch(actions.fetchTransactionsInAccount(account_id));
      dispatch(actions.fetchAccount(account_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account_id, dispatch]);

  useEffect(()=>{
    setTableDate(cloneDeep(closedTransactionEntities ? closedTransactionEntities : entities))
  },[entities, closedTransactionEntities])
  
  // Table columns
  const columns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <span>
          {moment(row.date).format("DD/MM/yyyy")}
        </span>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if(!closedTransactionForEdit) accountsUIProps.openEditTransactionDialog(account_id, row.id);
        }
      }
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      sortCaret: sortCaret,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if(!closedTransactionForEdit) accountsUIProps.openEditTransactionDialog(account_id, row.id);
        }
      }
    },
    {
      dataField: "rate",
      text: "Rate",
      sort: true,
      sortCaret: sortCaret,
      classes: "text-right",
      headerClasses: "text-right",
      formatter: (cellContent, row) => (
        <span>
          <NumberFormat 
            thousandSeparator={true} 
            decimalScale={2} 
            fixedDecimalScale 
            value={row?.rate} 
            displayType='text' >
          </NumberFormat>
        </span>
      ), 
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if(!closedTransactionForEdit) accountsUIProps.openEditTransactionDialog(account_id, row.id);
        }
      }
    },
    {
      dataField: "transfer",
      text: "Transfer",
      sort: true,
      sortCaret: sortCaret,
      classes: "text-right",
      headerClasses: "text-right",
      formatter: (cellContent, row) => (
        <span>
          <NumberFormat 
            thousandSeparator={true} 
            decimalScale={2} 
            fixedDecimalScale
            value={row?.transfer} 
            displayType='text' >
          </NumberFormat>
        </span>
      ), 
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if(!closedTransactionForEdit) accountsUIProps.openEditTransactionDialog(account_id, row.id);
        }
      }
    },
    {
      dataField: "payment",
      text: "Payment",
      sort: true,
      sortCaret: sortCaret,
      classes: "text-right",
      headerClasses: "text-right",
      formatter: (cellContent, row) => (
        <span>
          <NumberFormat 
            thousandSeparator={true} 
            decimalScale={2} 
            fixedDecimalScale 
            value={row?.payment} 
            displayType='text' >
          </NumberFormat>
        </span>
      ), 
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if(!closedTransactionForEdit) accountsUIProps.openEditTransactionDialog(account_id, row.id);
        }
      }
    },
    {
      dataField: "",
      text: "Birr Amt.",
      sort: true,
      sortCaret: sortCaret,
      classes: "text-right",
      headerClasses: "text-right",
      formatter: (cellContent, row) => {
        const val = row?.transfer || row?.payment
        return (
          <span className="text-muted">
            <NumberFormat 
              thousandSeparator={true} 
              decimalScale={2} 
              fixedDecimalScale 
              value={val * row?.rate} 
              displayType='text' >
            </NumberFormat>
          </span>
        )
      }, 
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if(!closedTransactionForEdit) accountsUIProps.openEditTransactionDialog(account_id, row.id);
        }
      }
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditTransactionDialog: accountsUIProps.openEditTransactionDialog,
        openDeleteTransactionDialog: accountsUIProps.openDeleteTransactionDialog,
      },
      style: {
        minWidth: "100px",
      },
      headerClasses: `${closedTransactionForEdit ? 'd-none' : '' }`,
      classes: `${closedTransactionForEdit ? 'd-none' : '' }`
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: accountsUIProps.queryParams.pageSize,
    page: accountsUIProps.queryParams.pageNumber,
  };

  function pageHandler (paginationProps){
    accountsUIProps.setQueryParams(
      (prev) =>
      "pagination" === "pagination"
        ? { ...prev, pageNumber:paginationProps?.page || 1, pageSize: paginationProps?.sizePerPage }
        : prev
    ) 
  }

  return (
    <>
    <Card className="transactions-list">
      <CardHeader>
        <h3 className={`card-title ${closedTransactionForEdit ? 'text-warning' : ''}`}>
          {closedTransactionForEdit ? `Transactions closed on ${moment(closedTransactionForEdit?.closed_date).format("DD/MM/yyyy")}` : 'Transactions List'}
        </h3>
        <CardHeaderToolbar className="d-flex">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => accountsUIProps.newTransactionButtonClick(account_id)}
            disabled={closedTransactionForEdit}
          >
            New Transaction
          </button>
          <div className="dropdown dropdown-inline show">
            <a href="#" className="btn btn-clean btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              <i className="ki ki-bold-more-hor"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right" x-placement="bottom-end" style={{width: '175px'}}>
              <ul className="navi navi-hover">
                {entities?.length > 0 && (
                <li className="navi-item">
                  <a href="#" className={`d-block p-4 ${closedTransactionForEdit ? 'disabled text-muted' : ''}`} onClick={() => accountsUIProps.closeTransactions(account_id)}>
                    Close Transactions
                  </a>
                </li>
                )}
                <li className="navi-item">
                  <a href="#" className="d-block p-4" onClick={() => accountsUIProps.showClosedTransactions(account_id)}>
                    Show Closed
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </CardHeaderToolbar>
        <div className="d-flex justify-content-between h5 mt-5 break">
          <div className="text-dark-75 font-weight-bolder">
            {account?.name}
          </div>
          <div>
            <span className="text-muted">Balance:</span> &nbsp;
            <span className="text-dark-75 font-weight-bolder mr-2">
              {account?.currency} &nbsp;
              <NumberFormat 
                thousandSeparator={true} 
                decimalScale={2} 
                fixedDecimalScale
                value={closedTransactionForEdit ? closedTransactionForEdit?.balance : account?.balance} 
                displayType='text' ></NumberFormat>
            </span>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  keyField="id"
                  data={tableData === null ? [] : tableData}
                  columns={columns}
                  defaultSorted={uiHelpers.defaultSorted}
                  onTableChange={pageHandler(paginationProps)}
                  rowClasses={`${!closedTransactionForEdit ? 'cursor-pointer' : ''}`}
                  {...paginationTableProps}
                >
                  <PleaseWaitMessage entities={tableData} />
                  <NoRecordsFoundMessage entities={tableData} />
                </BootstrapTable>
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <PaginationLinks paginationProps={paginationProps} />
                  <div className="ml-lg-auto">
                    <SizePerPageDropdownStandalone
                      { ...paginationProps }
                    />
                    <PaginationTotalStandalone
                      { ...paginationProps }
                    />
                  </div>
                </div>
              </>
            );
          }}
        </PaginationProvider>
      </CardBody>
    </Card>

    {closedTransactionForEdit ? (
      <span 
        className="btn btn-secondary btn-sm font-weight-bold px-10 py-3" 
        style={{borderRadius: '0.42rem'}}
        onClick={async () => {
          await dispatch(actions.clearClosedTransaction())
          dispatch(actions.fetchTransactionsInAccount(account_id))
        }}
      >Back to Active</span>
      ) : (
      <Link 
        to="/account" 
        className="btn btn-secondary btn-sm font-weight-bold px-10 py-3" 
        style={{borderRadius: '0.42rem'}}
        onClick={() => dispatch(actions.clearTransaction())}
      >Back</Link>
      )}
    </>
  );
}
