import {createSlice} from "@reduxjs/toolkit";

const initialAuthsState = {
  roles:null,
  group:null,
  token:null // if wanted
};


export const authsSlice = createSlice({
  name: "auth",
  initialState: initialAuthsState,
  reducers: {
    
    authFetched: (state, action) => {
      state.roles = action.payload.roles;
      state.group = action.payload.group;
    },
    
  }
});
