import {createSlice} from "@reduxjs/toolkit";

const initialColorsState = {
  colorListLoading: false,
  colorActionsLoading: false,
  colorTotalCount: 0,
  colorEntities: null,
  colorForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const colorsSlice = createSlice({
  name: "colors",
  initialState: initialColorsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.colorListLoading = false;
      } else {
        state.colorActionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.colorListLoading = true;
      } else {
        state.colorActionsLoading = true;
      }
    },
    // getColorById
    colorFetched: (state, action) => {
      state.colorActionsLoading = false;
      state.colorForEdit = action.payload.colorForEdit;
      state.error = null;
    },
    // findColors
    colorsFetched: (state, action) => {
      const { totalCount, colorEntities } = action.payload;
      state.colorListLoading = false;
      state.error = null;
      state.colorEntities = colorEntities;
      state.totalCount = totalCount;
    },
    // createColor
    colorCreated: (state, action) => {
      state.colorActionsLoading = false;
      state.error = null;
      state.colorEntities.push(action.payload.color);
    },
    // updateColor
    colorUpdated: (state, action) => {
      state.error = null;
      state.colorActionsLoading = false;
      state.colorEntities = state.colorEntities.map(entity => {
        if (entity.id === action.payload.color.id) {
          return action.payload.color;
        }
        return entity;
      });
    },
    // deleteColor
    colorDeleted: (state, action) => {
      state.error = null;
      state.colorActionsLoading = false;
      state.colorEntities = state.colorEntities.filter(el => el.id !== action.payload.id);
    },
    // deleteColors
    colorsDeleted: (state, action) => {
      state.error = null;
      state.colorActionsLoading = false;
      state.colorEntities = state.colorEntities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
