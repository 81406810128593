import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ProformasUIProvider } from "../../../pages/cars/car-proformas/ProformasUIContext";
import { SalesUIProvider } from "../../../pages/cars/car-sales/SalesUIContext";
import { CarsUIProvider } from "../../../pages/cars/CarsUIContext";
import { getWarehouse } from "../../../_redux/cars/carsActions";
import NotificationItem from "../NotificationItem";
import AlertNotificationsList from "./AlertNotificationsList";

export default function AlertNotifications({ setUnseenNotifications }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const warehouse = useSelector((state) => state.cars.warehouse);

  useEffect(() => {
    dispatch(getWarehouse());
  }, []);

  const carsUIEvents = {
    openEditCarPage: (id) => {
      history.push(`/cars/${id}/edit`);
    },
  };

  return (
    <div>
      <div className="d-flex flex-column pt-12 bg-dark-o-5 rounded-top">
        <ul className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary mt-3 px-8" role="tablist">
          <li className="nav-item">
            <a className="nav-link active show" data-toggle="tab" href="#topbar_notifications_notifications">
              Alerts
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#topbar_notifications_events">
              Warehouse
              {Array.isArray(warehouse) && warehouse.length > 0 && (
                <span className="label pulse pulse-success mr-10 top-0 end-0">
                  <span className="position-relative">{warehouse?.filter((w) => moment(w.warehouse_expiration_date).diff(moment().format("YYYY-MM-DD"), "M", true) <= 2).length}</span>
                  <span className="pulse-ring"></span>
                </span>
              )}
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-content">
        <CarsUIProvider>
          <ProformasUIProvider proformaFromOutside={true}>
            <SalesUIProvider saleFromOutside={true}>
              <AlertNotificationsList setUnseenNotifications={setUnseenNotifications} includeSeen={false} fromModal={false} />
            </SalesUIProvider>
          </ProformasUIProvider>
        </CarsUIProvider>

        <div className="tab-pane" id="topbar_notifications_events" role="tabpanel">
          <div className="navi navi-hover scroll my-4 ps p-3" data-scroll="true" data-height="500" data-mobile-height="400" style={{ height: "400px", overflow: "hidden" }}>
            <CarsUIProvider carsUIEvents={carsUIEvents}>
              <NotificationItem subNotification={warehouse} />
            </CarsUIProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
