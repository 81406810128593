import React from "react";
import {
    ProformaStatusTitles,
    ProformaStatusCssClasses, 
    ProformaStatuses
  } from "../ProformasUIHelper";
import moment from 'moment';

export const ProformaStatusColumnFormatter = (cellContent, row) => {
  const today = moment()
  const proforma_issued = moment(row.proforma_date)
  const proforma_due = moment(proforma_issued).add(row.expire, 'days')
  const over_due = today.isAfter(proforma_due);

  let status = cellContent;
  // Overdue active proformas must be shown with different flag
  if(status === ProformaStatuses.find(p => p.name === "Active").id && over_due && row.expire) {
    status =  ProformaStatuses.find(p => p.name === "Overdue").id
  }
  
  return (
    <span
      className={`label label-lg label-light-${
        ProformaStatusCssClasses[status]
      } label-inline`}
    >
      {ProformaStatusTitles[status]}
    </span>
  );
}