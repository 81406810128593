import React, {useEffect, useState} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { authenticationService } from "../../modules/Auth_LocalStorage/_services";
import * as userActions from "../../components/_redux/user/usersActions";
import axios from 'axios'
import { Formik, Form, Field } from "formik";
import { Input } from "../../../_metronic/_partials/controls";
import * as Yup from "yup"

export function QuickUser() {
  const dispatch = useDispatch();

  const [statusMsg, setStatusMsg] = useState();

  const {users} = useSelector((state) => ({ 
		users: state.users,
	}), shallowEqual);
	const {loggedUser} = users;

  useEffect(async () => {
		if(!loggedUser) {
      try {
          if(localStorage.getItem('currentUserEmail') && localStorage.group != 'ADMIN') {
            const res = await axios({
              method: 'get',
              url: `${process.env.REACT_APP_AUTO_DEALER_API}users/name/${localStorage.getItem('currentUserEmail')}`,
            });
            const user = res.data
            dispatch(userActions.setLoggedUser(user))
          }
      } catch (err) {
          console.log(err)
      }
    }
	}, [dispatch, loggedUser])

  const ChangePasswordSchema = Yup.object().shape({
    newPassword: Yup
      .string()
      .required('Password  is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})/,
        "Must Contain 7 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: Yup
      .string()
      .test('passwords-match', 'Passwords must match', function (value){return this.parent.newPassword === value})
  })

  const changePassword = (value) => {
    dispatch(userActions.changePassword(loggedUser?.id, value))
      .then((res) => {
        setStatusMsg({msg: "Password changed", type: "success"});
        setTimeout(() => {
          setStatusMsg();
        }, 3000);
        document.querySelector("#headingOne7 [data-target='#collapseOne7']").click();
      })
  };

  return (
    <>
      <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">
            User Profile
          </h3>
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted" />
          </a>
        </div>
        <div className="offcanvas-content pr-5 mr-n5">
          <div className="d-flex align-items-center mt-5">
            <div className="symbol symbol-100 mr-5">
                <div className="symbol-label" style={{backgroundImage: "url('/media/users/default.jpg')"}}></div>
                <i className="symbol-badge bg-success"></i>
            </div>
            <div className="d-flex flex-column">
              <span className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">{localStorage?.currentUserEmail}</span>
              <div className="navi mt-2">
                  <span className="navi-item">
                      <span className="navi-link p-0 pb-2">
                          <span className="navi-text text-muted text-hover-primary">{loggedUser?.full_name}</span>
                      </span>
                  </span>
              </div>
              <button className="btn btn-light-primary btn-bold" onClick={()=>{authenticationService.logout()}}>Sign out</button>
            </div>
          </div>
          <div className="separator separator-dashed mt-8" />
          <div className="navi navi-spacer-x-0 p-0">
            <div className="accordion accordion-light accordion-light-borderless accordion-svg-toggle" id="accordionExample7">
              {statusMsg &&
                <div className={`mb-0 alert alert-${statusMsg?.type}`} style={{fontSize: '1rem', opacity: 0.8}}>{statusMsg?.msg}</div>
              }
              <div className="card">
                <Formik
                    initialValues={{password:'', newPassword:'', confirmPassword:''}}
                    validationSchema={ChangePasswordSchema}
                    onSubmit={(values) => {
                        changePassword(values)
                    }}
                >
                  {({ handleSubmit, resetForm }) => (
                    <>
                      <div className="card-header" id="headingOne7">
                        <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseOne7" aria-expanded="false" role="button" onClick={() => resetForm()}>
                          <span  role={"button"} className="navi-item card-label text-dark pl-4" >
                            <div className="navi-link">
                              <div className="symbol symbol-40 bg-light mr-3">
                                <div className="symbol-label">
                                  <i className="fa fa-lock" style={{color: '#1BC5BD'}}></i>
                                </div>
                              </div>
                              <div className="navi-text">
                                <div className="font-weight-bolder">Change Password</div>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div id="collapseOne7" className="collapse" aria-labelledby="headingOne7" data-parent="#accordionExample7">
                        <div className="card-body text-dark-50 font-size-lg pl-12">
                          <Form className="form form-label-right">
                            <div className="form-group row">
                              <div className="col-lg-10 pl-0">
                                <Field
                                  autoComplete="new-password"
                                  name="newPassword"
                                  component={Input}
                                  placeholder="Password"
                                  label="New Password"
                                  type="password"
                                  withFeedbackLabel= {true} 
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-lg-10 pl-0">
                                <Field
                                  autoComplete="new-password"
                                  name="confirmPassword"
                                  component={Input}
                                  placeholder="Password"
                                  label="Confirm Password"
                                  type="password"
                                  withFeedbackLabel= {true} 
                                />
                              </div>
                            </div>
                            <div className="form-group row mb-0">
                              <div className="pl-0">
                                <button
                                  type="button"
                                  onClick={() => {
                                    resetForm();
                                    // document.querySelector("#headingOne7 [data-target='#collapseOne7']").click();
                                  }}
                                  className="btn btn-light btn-elevate mr-1"
                                >
                                  Clear
                                </button>
                                <> </>
                                <button
                                  type="submit"
                                  onClick={() => handleSubmit()}
                                  className="btn btn-primary btn-elevate"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </Form> 
                        </div>
                      </div>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="separator separator-dashed my-7"></div>
        </div>
      </div>
    </>
  );
}
