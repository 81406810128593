import * as requestFromServer from "./proformasCrud";
import {proformasSlice, callTypes} from "./proformasSlice";

const {actions} = proformasSlice;

export const fetchProformas = (queryParams={}) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  
  return requestFromServer
    .getAllProformas(queryParams, localStorage.currentUserEmail)
    .then(response => {
      const entities  = response.data;
      const totalCount = response.data.length;
      dispatch(actions.proformasFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find proformas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchProforma = (id,carId) => dispatch => {
  if (id==0) {
    if (carId) {
      return requestFromServer
      .getNewProformaByCarId(carId)
      .then(response => {
        let proforma = response.data;
        proforma.proforma_date = new Date(proforma?.proforma_date)
        dispatch(
          actions.proformaFetched({ proformaForEdit: proforma })
        );
      })
      .catch(error => {
        error.clientMessage = "Can't find proforma";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
    }
    else {
      return requestFromServer
      .getNewProforma()
      .then(response => {
        let proforma = response.data;
        proforma.proforma_date = new Date(proforma?.proforma_date)
        dispatch(
          actions.proformaFetched({ proformaForEdit: proforma })
        );
      })
      .catch(error => {
        error.clientMessage = "Can't find proforma";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
    }
  }
  
  if (!id) {
    return dispatch(
      actions.proformaFetched({ proformaForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProformaById(id)
    .then(response => {
      let proforma = response.data;
      proforma.proforma_date = new Date(proforma?.proforma_date)
      dispatch(
        actions.proformaFetched({ proformaForEdit: proforma })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find proforma";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchValidProforma = (carId) => dispatch => {
  if (carId) {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
    .findValidProformaItem(carId)
    .then(response => {
      dispatch(actions.validProformaFetched({ validProforma: response.data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find proforma item";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
  }
}

export const fetchProformaItem = (id,carId,from) => dispatch => {
  
  if(id==0 && carId && from!='fromPrint'){
    const proformaItems = []
    return requestFromServer
    .getProformaItemsByCarId(carId)
    .then(response => {
      // const proformaItems = [];
      proformaItems.push(response.data);
      dispatch(
        actions.proformaItemsFetched({ proformaItems })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find proforma";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(actions.proformaItemsFetched({ proformaItems }));
    });
  }

  if (!id) {
    return dispatch(
      actions.proformaFetched({ undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProformaItemsById(id)
    .then(response => {
      const proformaItems = response.data;
      dispatch(
        actions.proformaItemsFetched({ proformaItems })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find proforma";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteProforma = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProforma(id)
    .then(response => {
      dispatch(actions.proformaDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete proforma";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSales = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSales(id)
    .catch(error => {
      error.clientMessage = "Can't delete proforma";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createProforma = proformaForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createProforma(proformaForCreation)
    .then(response => {
      const proforma = response?.data;
      dispatch(actions.proformaCreated({ proforma: proforma }))
      return proforma;
    })
    .catch(error => {
      error.clientMessage = "Can't create proforma";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateProforma = (proforma,id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateProforma(proforma,id)
    .then((res) => {
      const updatedProforma = res?.data
      dispatch(actions.proformaUpdated({proforma: proforma}))
      // dispatch(actions.proformaUpdated({ proforma: updatedProforma }));
      return updatedProforma
    })
    .catch(error => {
      error.clientMessage = "Can't update proforma";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteProformas = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProformas(ids)
    .then(() => {
      dispatch(actions.proformasDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete proformas";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchProformasInCar = id => dispatch => {
  if(!id){
    return dispatch(actions.proformasInCarFetched({proformas: undefined}))
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
  .findProformasInCar(id)
  .then(response => {
    const entities  = response.data;
    const totalCount = response.data.length;
    dispatch(actions.proformasInCarFetched({ proformas: entities, proformasTotalCount: totalCount }));
  })
  .catch(error => {
    error.clientMessage = "Can't delete car";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  });
};

// export const createProformaInCar = ProformaForCreation => dispatch =>{
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   dispatch(actions.proformaInCarCreated({Proforma: ProformaForCreation}));

// };

// export const updateproformaInCar = proformaForUpdate => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   dispatch(actions.proformaInCarUpdated({Proforma: proformaForUpdate}))
// };

export const addProformaItems = (newProformaItems, values) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  if(newProformaItems.uuid==undefined && newProformaItems.proforma_id !== null){
    return requestFromServer
    .updateProformaItem({...newProformaItems, proforma: values})
    .then(response => {
      dispatch(actions.proformaItemsUpdated({newProformaItems: response.data, values}))
    })
    .catch(error => {
      error.clientMessage = "Can't delete car";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
  }else{
    // Save proforma item only if it has a parent proforma
    if(newProformaItems.proforma_id != 0 && newProformaItems.proforma_id !== null) {
      return requestFromServer
      .createProformaItem(newProformaItems)
      .then(response => {
        dispatch(actions.proformaItemCreated({newProformaItems: response.data, values}))
      })
      .catch(error => {
        error.clientMessage = "Can't delete car";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
    }
    else {
      dispatch(actions.proformaItemCreated({ newProformaItems, values }));
    }
  }
  return newProformaItems
};

export const proformaItemsDelete = newProformaItems => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  if(newProformaItems.uuid==undefined && newProformaItems.id !=0 ){
    dispatch(actions.proformaItemsDelete({ newProformaItems }));
  }else{
    dispatch(actions.proformaItemsDeleteForNew({ newProformaItems }));
  }
  return newProformaItems
};
export const deleteValidation = (id,setValidation) => dispatch => {
  if (!id) {
    return false;
    
  }
  return requestFromServer
    .getDeleteValidationProformaById(id)
    .then(response => {
      setValidation(response.data)
    })
    .catch(error => {
      error.clientMessage = "Can't request validation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
  };

export const deleteValidationProforamItem = (id,setValidation) => dispatch => {
  if (!id) {
    return false;
    
  }
  return requestFromServer
    .getDeleteValidationProformaItemById(id)
    .then(response => {
      setValidation(response.data)
    })
    .catch(error => {
      error.clientMessage = "Can't request validation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
  };

export const fetchBanks = (setBanks) => dispatch => {
    return requestFromServer
      .getBanks()
      .then(response => {
        setBanks(response.data)
      })
      .catch(error => {
        error.clientMessage = "Can't requerst validation";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
    };
export const updateCustomer = (customer,values) => dispatch => {
  dispatch(actions.updateCustomer({ customer,values }));
};

export const changeProformaStatus = (proformaId, statusId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
  .changeStatus(proformaId, statusId)
  .then(response => {
    dispatch(actions.proformaStatusChanged({ proformaId, statusId }));
  })
  .catch(error => {
    error.clientMessage = "Can't change proforma status";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  });
}

export const resetProformasSlice = () => dispatch => {
  dispatch(actions.proformaEntitiesReset())
}
