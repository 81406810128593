export function handleResponse(response) {
   if(response.status == 200){
       if(response.data.token ){
        localStorage.setItem('currentUser','Bearer '+  response.data.token);
        localStorage.setItem('currentUserEmail',response.data.userName);
        localStorage.setItem('group',response.data.group);
        localStorage.setItem('date',response.data.date)
        return {login:'successfull',response:response};
        }
        return {login:'undefined token',response:response}
        
    }else if (response.status == undefined || response.status != 200){
        let error;
       
        if(response.response.status == 401){
            error = 'Invalid email or password!';
            return Promise.reject(error);
        }else if(response.response.status == 400){
            error = response.response.data.errorMessage
            return Promise.reject(error);
        }else if (response.response.status == 403){
                    const error = 'Forbidden request';
                 return Promise.reject(error);
        }else if (response.response.status == 409){
            const error = response.response.data.errorMessage
            return Promise.reject(error);
        }
        error = response.response.data.error+' : '+response.response.data.message;
        return Promise.reject(error);
    }   
}