import React from "react";
import { Route } from "react-router-dom";
import { ColorsLoadingDialog } from "./colors-loading-dialog/ColorsLoadingDialog";
import { ColorEditDialog } from "./color-edit-dialog/ColorEditDialog";
import { ColorDeleteDialog } from "./color-delete-dialog/ColorDeleteDialog";
import { ColorsFetchDialog } from "./colors-fetch-dialog/ColorsFetchDialog";
import { ColorsUIProvider } from "./ColorsUIContext";
import { ColorsCard } from "./ColorsCard";

export function ColorsPage({ history }) {
  const colorsUIEvents = {
    newColorButtonClick: () => {
      history.push("/colors/new");
    },
    openEditColorDialog: (id) => {
      history.push(`/colors/${id}/edit`);
    },
    openDeleteColorDialog: (id) => {
      history.push(`/colors/${id}/delete`);
    },
    openFetchColorsDialog: () => {
      history.push(`/colors/fetch`);
    }
  }

  return (
    <ColorsUIProvider colorsUIEvents={colorsUIEvents}>
      <ColorsLoadingDialog />
      <Route path="/colors/new">
        {({ history, match }) => (
          <ColorEditDialog
            show={match != null}
            onHide={() => {
              history.push("/colors");
            }}
          />
        )}
      </Route>
      <Route path="/colors/:id/edit">
        {({ history, match }) => (
          <ColorEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/colors");
            }}
          />
        )}
      </Route>
      
      <Route path="/colors/:id/delete">
        {({ history, match }) => (
          <ColorDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/colors");
            }}
          />
        )}
      </Route>
      <Route path="/colors/fetch">
        {({ history, match }) => (
          <ColorsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/colors");
            }}
          />
        )}
      </Route>
      
      <ColorsCard />
    </ColorsUIProvider>
  );
}
