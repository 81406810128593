import { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import { useAccountsUIContext } from "../AccountsUIContext";
import * as actions from "../../../_redux/accounts/accountsActions";
import { Link } from "react-router-dom";

export const AccountsList = () => {
  // Accounts UI Context
  const accountsUIContext = useAccountsUIContext();
  const accountsUIProps = useMemo(() => {
    return {
      newAccountButtonClick: accountsUIContext.newAccountButtonClick,
      editAccountButtonClick: accountsUIContext.editAccountButtonClick, 
      deleteAccountButtonClick: accountsUIContext.deleteAccountButtonClick
    };
  }, [accountsUIContext]);

  const { accounts } = useSelector(
    (state) => ({
      accounts: state.accounts.accountEntities
    }),
    shallowEqual
  );

  // Accounts Redux state
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchAccounts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <div className="mb-5 d-flex justify-content-between align-items-center">
        <h3 className="ml-1">Accounts</h3>
        <button
            type="button"
            className="btn btn-primary"
            onClick={accountsUIProps.newAccountButtonClick}
          >
            New Account
          </button>
      </div>
    
      <div className="row">
        {accounts && accounts.map(a => (
          <div key={a.id} className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div className="card card-custom gutter-b card-stretch w-100 h-100" >
              <div className="card-body pt-5 pr-4 d-flex flex-column justify-content-between ">
                <div className="mb-7">
                  <div className=" row">
                    <h5 className="text-dark-70 font-weight-bolder col-7"  >{a.name}</h5>
                    <div className="col-5 d-flex justify-content-end">
                      <button className="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                        onClick={() => accountsUIProps.editAccountButtonClick(a.id)}  
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary"><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title></title><desc>Created with Sketch.</desc><defs></defs><g id="Stockholm-icons-/-Communication-/-Write" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect id="bound" x="0" y="0" width="24" height="24"></rect><path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" id="Path-11" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "></path><path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" id="Path-57" fill="#000000" fillRule="nonzero" opacity="0.3"></path></g></svg></span>
                      </button> 
                      <button className="btn btn-icon btn-light btn-hover-primary btn-sm" 
                        onClick={() => accountsUIProps.deleteAccountButtonClick(a.id)} 
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary"><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title></title><desc>Created with Sketch.</desc><defs></defs><g id="Stockholm-icons-/-General-/-Trash" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect id="bound" x="0" y="0" width="24" height="24"></rect><path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" id="round" fill="#000000" fillRule="nonzero"></path><path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" id="Shape" fill="#000000" opacity="0.3"></path></g></svg></span>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="text-dark-75 font-weight-bolder">
                      {a.currency} &nbsp;
                      <NumberFormat 
                        thousandSeparator={true} 
                        decimalScale={2} 
                        fixedDecimalScale
                        value={a.balance} 
                        displayType='text'></NumberFormat>
                    </span>
                  </div>
                </div>
                <Link to={`/account/${a.id}/transaction`} className="btn btn-block btn-sm btn-light-success font-weight-bolder text-uppercase py-4 pb-4">View Transactions</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
