import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux_admin/clients_adm/clientsActions";
import { TenantEditDialogHeader } from "./TenantEditDialogHeader";
import { TenantEditForm } from "./TenantEditForm";
import { useTenantsUIContext } from "../TenantsUIContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function TenantEditDialog({ id, show, onHide,initalData = null,fromDashboard=false }) {
  // Tenants UI Context
  const tenantsUIContext = useTenantsUIContext();
  //const names = [{name:'Betselot'},{name:'betse'},{name:'betse'}];
  const tenantsUIProps = useMemo(() => {
    return {
      initTenant: tenantsUIContext.initTenant,
      openDeleteTenantDialog: tenantsUIContext.openDeleteTenantDialog,
      openDelete: tenantsUIContext.openDelete,
    };
  }, [tenantsUIContext]);

  // Tenants Redux state
  const dispatch = useDispatch();
  const { actionsLoading, tenantForEdit,tenants,paymentMethod,subscriptionPlans } = useSelector(
    (state) => ({
      actionsLoading: state.tenants.tenantActionsLoading,
      tenantForEdit: state.tenants.tenantForEdit,
      tenants:state.tenants.tenantEntities,
      paymentMethod:state.settingsAdmin.paymentMethod.entities,
      subscriptionPlans:state.settingsAdmin.subscriptionPlan.entities,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Tenant by id
    dispatch(actions.fetchTenant(id));
    //dispatch(actions.fetchTenantNames())
  }, [id, dispatch]);
  
  // server request for saving tenant
  const saveTenant = (tenant) => {
    if (!tenant.id) {
      // server request for creating tenant
      dispatch(actions.createTenant(tenant))
      .then(() =>  { toast.success("Subscription Added Successfully!");
                    dispatch(actions.fetchTenant(id));
                    dispatch(actions.fetchTenants());});
    } else {
      // server request for updating tenant
      dispatch(actions.updateTenant(tenant))
      .then(() =>  { toast.success("Subscription Updated Successfully!");
                    dispatch(actions.fetchTenant(id));
                    dispatch(actions.fetchSubscriptions());
                    dispatch(actions.fetchTenants());});
    }
  };
  const tenantFor = initalData != null ? {...initalData,tenants}:tenantForEdit==undefined ?  {...tenantsUIProps.initTenant,tenants}: {...tenantForEdit,tenants}
  return (
    <>
    <ToastContainer position="top-center" autoClose={1000}/>
    <Modal
      size="lg"
      show={show}
      // onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <TenantEditDialogHeader id={id} onHide={onHide} />
      <TenantEditForm
        saveTenant={saveTenant}
        actionsLoading={actionsLoading}
        tenant={tenantFor}
        onHide={onHide}
        openDeleteTenantDialog={ tenantsUIProps.openDeleteTenantDialog}
        paymentMethod={paymentMethod}
        subscriptionPlans={subscriptionPlans}
        fromDashboard = {fromDashboard}
        openDelete={tenantsUIProps.openDelete}
      />
    </Modal>
    </>
  );
}
