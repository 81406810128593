// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input as customInput,
} from "../../../../../../_metronic/_partials/controls";
import userGenerator from "../../../shared/UserNameGenerator"
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
// Validation schema
const UserEditSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .matches(
      /^((?!@).)*$/,
      "Name should not contain @"
    )
    .required("Name is required")
    ,
  userName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .required("Username is required")
    ,
  password: Yup
    .string()
    .required('Password  is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})/,
      "Must Contain 7 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
   
});

export function UserEditForm({
  saveUser,
  user,
  actionsLoading,
  onHide,
  openDeleteUserDialog,
  openChangePasswordDialog,
  initUser
}) {
 
  
  function setUserName(e,value,setFieldValue){
    //console.log(value)
    let userName = value?.fullName
    if(userName!='' || userName!= null){
    var newUserName = userGenerator(userName,value?.users)
    //console.log(newUserName)
    setFieldValue('userName',newUserName)
    }else{
      return null;
    }
  }
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={user}
        validationSchema={UserEditSchema}
        onSubmit={(values) => {
          saveUser(values);
          
        }}
      >
        {({ handleSubmit,values,initialValues ,setFieldValue,setFieldTouched,errors,touched,isSubmitting}) => (
          <BlockUi blocking={isSubmitting}>
            <Modal.Body className="overlay  cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Name */}
                  
                  <div className="col-lg-10">
                    <Field
                      name="fullName"
                      component={customInput}
                      placeholder="Name"
                      label="Name"
                      withFeedbackLabel= {true}
                      required
                      onKeyUp={(e)=>{if(initialValues.id == undefined){
                                      setUserName(e,values,setFieldValue)}}}
                    />
                  </div>
                  </div>
                  {/* UserName */}
                  <div className="form-group row">
                  <div className="col-lg-10">
                    <Field
                      name="userName"
                      component={customInput}
                      placeholder="Username"
                      label="Username"
                      withFeedbackLabel= {true}
                      required
                      disabled={true}
                      //style={{color:'#0096fb'}}
                    />
                  </div>
                  </div>
                  
                  { /* Password */}
                  {initialValues.id == undefined && <div className="form-group row">
                  <div className="col-lg-10">
                    <Field
                      name="password"
                      component={customInput}
                      placeholder="Password"
                      label="Password"
                      withFeedbackLabel= {true}
                      required
                      type="password"
                      autoComplete="new-password"
                    />
                  </div>
                  </div>}

                  

              </Form>
            </Modal.Body>
            <Modal.Footer>
            <button
                type="submit"
                onClick={() =>handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate mr-auto"
              >
                Cancel
              </button>
              { values.id != undefined &&<>
              <button
              type="button"
              onClick={()=>{openChangePasswordDialog(values.id)}}
              className="btn btn-light btn-elevate"
              >
              Change Password
              </button>
              { localStorage.currentUserEmail != values.userName && 
              (values.status ? 
              <button
                type="button"
                onClick={()=>{openDeleteUserDialog(values.id,values.status)}}
                className="btn btn-danger btn-elevate"
              >
                Deactivate
              </button> : 
               <button
               type="button"
               onClick={()=>{openDeleteUserDialog(values.id,values.status)}}
               className="btn btn-elevate btn-primary"
             >
               Activate
             </button>
             )}
              </>
              }

              <> </>
              
            </Modal.Footer>
          </BlockUi>
        )}
      </Formik>
    </>
  );
}
