import * as requestFromServer from "./banksCrud";
import {banksSlice, callTypes} from "./banksSlice";

const {actions} = banksSlice;

export const fetchBanks = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  
  return requestFromServer
    .getAllBanks()
    .then(response => {
      const bankEntities  = response.data;
      const bankTotalCount = response.data.length;
      dispatch(actions.banksFetched({ bankTotalCount, bankEntities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find banks";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchBank = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.bankFetched({ bankForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBankById(id)
    .then(response => {
      const bank = response.data;
      dispatch(
        actions.bankFetched({ bankForEdit: bank })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find bank";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteBank = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteBank(id)
    .then(response => {
      dispatch(actions.bankDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete bank";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createBank = bankForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createBank(bankForCreation)
    .then(response => {
      const { data:bank } = response;
      dispatch(actions.bankCreated({ bank }));
    })
    .catch(error => {
      error.clientMessage = "Can't create bank";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateBank = bank => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBank(bank)
    .then(() => {
      dispatch(actions.bankUpdated({ bank }));
    })
    .catch(error => {
      error.clientMessage = "Can't update bank";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteBanks = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteBanks(ids)
    .then(() => {
      dispatch(actions.banksDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete banks";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteValidation = (id,setValidation) => dispatch => {
  if (!id) {
    return false;
    
  }
  return requestFromServer
    .getDeleteValidationById(id)
    .then(response => {
      setValidation(response.data)
    })
    .catch(error => {
      error.clientMessage = "Can't request validation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
  };