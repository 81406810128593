// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useMemo, useState, useRef } from "react";
import { Modal, Nav} from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { DatePicker } from '@mantine/dates';
import * as Yup from "yup";
import {
  Input,
  TextArea
} from "../../../../../../_metronic/_partials/controls";
import { ProformaStatuses } from "../ProformasUIHelper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as customersActions from '../../../../_redux/customers/customersActions';
import * as proformaActions from '../../../../_redux/proformas/proformasActions';
import * as saleActions from '../../../../_redux/sales/salesActions';
import * as bankActions from '../../../../_redux/banks/banksActions'
import { useProformasUIContext } from '../ProformasUIContext'
import { useSalesUIContext } from "../../car-sales/SalesUIContext";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import AsyncSearch from './AsyncSelectBar';
import uuid from 'react-uuid';
import DownShiftAutoComplete from './DownShiftAutoComplete';
import NumberFormat from 'react-number-format';
import AsyncSearchBar from "../../../shared/AsyncSelectBar";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { CustomersUIProvider } from "../../../settings/settings-customers/CustomersUIContext";
import { CustomerEditDialog } from "../../../settings/settings-customers/customer-edit-dialog/CustomerEditDialog";
import {useHistory, useLocation} from "react-router-dom"
import { SaleEditDialog_1 } from "../../car-sales/sale-edit-dialog/SaleEditDialog_1";
import * as settingActions from "../../../../_redux/settings/settingsActions";
// import { SalesTable_1 } from "../../car-sales/SalesTable_1";
// import { SaleEditDialog } from "../../car-sales/sale-edit-dialog/SaleEditDialog";
// Exclude overdue option from status
const statuses = ProformaStatuses.filter(p => p.id !== ProformaStatuses.find(p => p.name === "Overdue").id)

// Validation schema


export function ProformaEditForm({
  saveProforma,
  proforma,
  actionsLoading,
  onHide,
  newButton,
  proformas,
  proformaSubmitted,
  setProformaSubmitted
}) {
  const formRef = useRef();
  const history = useHistory();
  const proformasUIContext = useProformasUIContext();
  const proformasUIProps = useMemo(() => {
    return {
      ids: proformasUIContext.ids,
      selectedId: proformasUIContext.selectedId,
      carId: proformasUIContext.carId,
      showDelete:proformasUIContext.showDeleteProformaDialog,
      openDeleteProformaDialog:
      proformasUIContext.openDeleteProformaInsideDialog,
      proformaFromOutside: proformasUIContext.proformaFromOutside,
      openContractOfSale:proformasUIContext.openProformaConstractOfSale,
      openBankReleaseLetter:proformasUIContext.openProformaBankReleaseLetter,
      openCashContractOfSale:proformasUIContext.openProformaCashConstractOfSale,
      openPrintMenu: proformasUIContext.openPrintMenuDialog,
      setCurrentProforma: proformasUIContext.setCurrentProforma
    };
  }, [proformasUIContext]);

  const salesUIContext = useSalesUIContext();
  const salesUIProps = useMemo(() => {
    return {
      setFinishSale: salesUIContext.setFinishSale
    };
  }, [salesUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state }),
    shallowEqual
  );

  const duplicateActiveProforma = (proformaEntity, {status_id}) => {
    if (status_id) {
      if(proformaEntity.id !== proforma.id) {
        if( proformaEntity.status_id === statuses.filter(item=>item.name === 'Active')[0].id && status_id === statuses.filter(item=>item.name === 'Active')[0].id) {
          return true;
        }
      }
    }
    return false;
  };

  const ProformaEditSchema = Yup.object().shape({
    isBank: Yup.boolean(),
    proforma_date: Yup.date().nullable().required("Proforma Date is required"),
    status_id:Yup.number().min(1).required("Status is required"),
    bank_id: Yup.string().when('isBank', {
      is: true,
      then: Yup.string().required("Bank is required"),
      otherwise: Yup.string().notRequired().nullable()
    }),
    expire: Yup.number().when('isBank', {
      is: true,
      then: Yup.number().typeError('Field must be a number').min(1, "Number cannot be less than 1").required("Expire Date is required"),
      otherwise: Yup.number().notRequired().nullable()
    }),
    employee_id:Yup.number().min(1).required("Sales Person is required"),
    customer:Yup.object().shape({ name:Yup.string().required("Customer is required")}).nullable().required("Customer is required"),
    // proformaItems:Yup.object().nullable().required("proformaItems is required")
    proformaItems: Yup.array().min(1).required("Proforma must contain at least 1 car"),
    customField: Yup.string().when(["status_id"], {
      is: (status_id) => proformas?.some(p => duplicateActiveProforma(p, { status_id})),
      then: Yup.string().required('There is already an active proforma with selected cars')})
  });
    const buttonRef = useRef(null);
    const [deleteValidation,setValidation] = useState([])
   const {entities} = currentState.customers;
   const {proformaForEdit,proformaItems} = currentState.proformas;
   const {employeeEntities} = currentState.employees
   const {clientEntities} = currentState.client;
   const {ethiopianBanksEntities:banks} = currentState.setting;
  //  const { salesTotalCount, saleEntities, saleLoading, error:saleError, apiErrorData:apiErrorDataSales } = currentState.sales;
   const { salesTotalCount, saleEntities, saleLoading, error:saleError, apiErrorData:apiErrorDataSales, salesInProforma } = currentState.sales;
   const[newProformaItems,setNewProformaItems] = useState({
            id:0,
            year:"",
            proforma_amount:0,
            invoice_amount:0,
            sales_amount:0,
            gps_amount:0,
            proforma_id:0,
            remark:"",
            commission_amount:0,
            newOrUsed:"",
            product_id:0,
            status_id :'',
            color :'',
            modelYear:'',
            motorNo:'',
            chassis:'',
            cc:'',
            uuid:0
    })
    const customersUIEvents = {
      newCustomerButtonClick: () => {
        history.push("/customers/new");
      },
      openEditCustomerDialog: (id) => {
        history.push(`/customers/${id}/edit`);
      },
      openDeleteCustomerDialog: (id) => {
        history.push(`/customers/${id}/delete`);
      },
      openDeleteCustomersDialog: () => {
        history.push(`/customers/deleteCustomers`);
      },
      openFetchCustomersDialog: () => {
        history.push(`/customers/fetch`);
      },
      openUpdateCustomersStatusDialog: () => {
        history.push("/customers/updateStatus");
      }
    }
   const [toggleModal,setToggle] = useState(false)
   const [carName,setCarName] = useState("")
   const [carIdForEdit,setCarIdForEdit] = useState("")
   const [intialValue] = useState({...newProformaItems})
   const [showItemModal, setShowItemModal] = useState(false);
   const [totalAmount,setAmount] = useState(0);
   const [proTotalAmount,setProTotalAmount] = useState(0);
   //const [banks,setBanks] = useState([])
   const [customer,setCustomerName] = useState({})
   const [selectedCustomer,setSelectedCustomer] = useState({});
   const [selectedCars,setSelectedCars] = useState([]);
   const [validationSchemaForProformaItem,setValidationSchemaForProformaItem] = useState({
    car:false,
    sales_amount:false,
    proforma_amount:false
    });

   const [proformaValues, setProformaValues] = useState({...proforma, remark: (proforma?.remark || "")})
   const [togglePrint,setTogglePrint] = useState(true)

   const [finish, setFinish] = useState(false)
   const [selectedTopTab, setSelectedTopTab] = useState("order");
   const [disablePayment, setDisablePayment] = useState(false)

   const setTopTab = _tabName => {
     setSelectedTopTab(_tabName);
    };

    const orderPage = "/orders"
    const location = useLocation()
   const dispatch = useDispatch();

    useEffect(()=>{
      dispatch(proformaActions.updateCustomer(customer,{id:undefined}))
      dispatch(customersActions.fetchCustomers());
    },[customer]);

    useEffect(()=>{
      setSelectedCustomer(proformaForEdit == undefined ? "this":
      proformaForEdit.customer)
    },[proformaForEdit]);

    useEffect(()=>{
      dispatch(bankActions.fetchBanks())
      dispatch(settingActions.fetchEthioBanks())
    },[])

    useEffect(() => {
      dispatch(proformaActions.fetchProformaItem(proformasUIProps.selectedId,proformasUIProps.carId,"fromEdit"))
      dispatch(proformaActions.deleteValidationProforamItem(proformasUIProps.selectedId,setValidation));
    }, [dispatch,proformasUIProps.selectedId]);

    useEffect(()=>{
      let amou = 0;
      let proamou = 0;
      setSelectedCars(proformaItems?.map((item)=>(item.product_id)));
      proformaItems?.map((item)=>{ amou = amou+parseInt(item.sales_amount)
                                   proamou = proamou+parseInt(item.proforma_amount)
        });
        setAmount(amou);
        setProTotalAmount(proamou);
    },[proformaItems]);

    // useEffect(() => {
    //   proformasUIProps?.carId && dispatch(saleActions.fetchSalesInCar(proformasUIProps.carId));
    // }, [proformasUIProps.carId, dispatch]);

    useEffect(() => {
      proformaForEdit?.id && !(salesInProforma?.length > 0) && dispatch(saleActions.fetchSalesInProforma(proformaForEdit?.id))
    }, [proformaForEdit, dispatch])

  function editItems(items){
    console.log('You clicked edit.'+items.color);
    setNewProformaItems(items)
    setCarName(items.productName)
    setCarIdForEdit(items.product_id)
    //console.log(row.original)
    setShowItemModal(true)
  }
  function deleteItems(items,deleteValidation){
    if(deleteValidation){
      dispatch(proformaActions.proformaItemsDelete(items))
    }
  }

  const handleProformaItemChange = (event,name) =>{
    if(name=="sales_amount"){
    hideValidationMessage();
    }
    const fieldName = name;
    const fieldValue = event.floatValue;
    const newFormData = {...newProformaItems}
    newFormData[fieldName] = fieldValue
    setNewProformaItems(newFormData)
  };

  function hideValidationMessage(){
    setValidationSchemaForProformaItem({car:false,sales_amount:false,proforma_amount:false})
  }
  const [activeVal,setActiveValidation] = useState(false);

  async function beforeSubmit(handleSubmit,values){
    const url = `${process.env.REACT_APP_AUTO_DEALER_API}proforma/validation/proformaActive/${values?.proformaItems?.map((item)=>item.product_id).join(',')}/${values.id}`
				const token = localStorage.currentUser
				let response = await fetch(url,{
					headers: {Authorization: token}
				  })
				response = await response.json();
    if(response)
    handleSubmit();
    else{
    dispatch(proformaActions.fetchProformasInCar(proformasUIProps.carId))
    setActiveValidation(true);
    }
  }

  function handleProformaItemSubmit(values){
    const newFormData = {...newProformaItems};
    if(newFormData.product_id <= 0 || newFormData.sales_amount <= 0||newFormData.proforma_amount <= 0
       ||newFormData.product_id==undefined || newFormData.sales_amount==undefined||newFormData.proforma_amount==undefined){
      let carb = false
      let sale_amountb = false
      let proforma_anountb = false
      if(newFormData.product_id <= 0||newFormData.product_id==undefined){
        carb = true
      }
      if(newFormData.sales_amount <= 0|| newFormData.sales_amount==undefined){
        sale_amountb = true
      }
      if(newFormData.proforma_amount <= 0||newFormData.proforma_amount==undefined){
        proforma_anountb = true
      }
      setValidationSchemaForProformaItem({car:carb,sales_amount:sale_amountb,proforma_amount:proforma_anountb})
    }else{
      dispatch(proformaActions.addProformaItems(newFormData,values))
      setShowItemModal(false)
    }
  }
  const hideSmallModal = ()=>{
    setShowItemModal(false)
    hideValidationMessage();
  }
  const togglePrintFun = ()=>{
    setTogglePrint(!togglePrint)
  }

  const proceedToPayment = async () => {
    const {isValid, setTouched, validateForm, values} = formRef?.current
    setTouched({
      bank_id: true,
      customer: true,
      employee_id: true,
      expire: true,
      id: true,
      isBank: true,
      location_id: true,
      proformaItems: true,
      proforma_date: true,
      remark: true,
      status_id: true,
      user_name: true,
    })
    await validateForm(values)
    if(isValid) {
      salesUIProps.setFinishSale(true)
      proformasUIProps.setCurrentProforma(proformaValues)
      setTopTab("payment")
    }
  }

  return (
    <>
    {proformasUIProps.selectedId>0 &&
      <div className="subheader subheader-transparent pt-1" id="kt_subheader">
        <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap px-4">
          <div className="d-flex align-items-center flex-wrap mr-1"></div>
          <div className="d-flex align-items-center">
            <div className="dropdown dropdown-inline mt-1 mr-sm-1 mr-md-3" data-toggle="tooltip" title="" data-placement="left" data-original-title="Quick actions">
              <button
                onClick={() => {
                    proformasUIProps.openPrintMenu();
                }}
                className="btn btn-light-primary font-weight-bolder btn-sm"
              >
                <i className="la la-print"></i> Print
              </button>

            </div>
          </div>
        </div>
      </div>
    }

    <div className="wizard wizard-3" id="kt_wizard_v3" data-wizard-state="first" data-wizard-clickable="true">
      <div className="wizard-nav">
        <div className="wizard-steps px-6 px-lg-6 pb-sm-2 pb-md-3 pt-0">
          <div className="wizard-step" data-wizard-type="step" data-wizard-state={selectedTopTab === "order" ? `current` : `pending`}
            onClick={() => {
              setProformaSubmitted(false)
              setTopTab("order")
            }}
          >
            <div className="wizard-label pb-4 pt-0">
              <h3 className="wizard-title">
              <span>1.</span>Order</h3>
              <div className="wizard-bar"></div>
            </div>
          </div>
          <div className="wizard-step" data-wizard-type="step" data-wizard-state={selectedTopTab === "payment" ? `current` : `pending`}
            onClick={() => {
                if (buttonRef.current) {
                    buttonRef.current.click();
                }
            }}
          >
            <div className="wizard-label pb-4 pt-0">
              <h3 className="wizard-title">
              <span>2.</span>Payment</h3>
              <div className="wizard-bar"></div>
            </div>
          </div>
        </div>
      </div>

      <div data-wizard-type="step-content" data-wizard-state={selectedTopTab === "order" ? "current" : ""}>
      <Formik
        enableReinitialize={true}
        initialValues={{...proforma, remark: (proforma?.remark || ""), isBank: (proforma?.bank_id != null || proforma?.expire != null), status_id: (proforma?.status_id || ProformaStatuses.find(p => p.name == "Active")?.id)}}
        validationSchema={ProformaEditSchema}
        onSubmit={async (values, { setStatus, setSubmitting }) => {
          try {
            values = {...values, proformaItems: values.proformaItems.map(pi => ({...pi, status_id: values.status_id}))}
            const valuesToSend = {...values}
            await saveProforma(valuesToSend);
            setSubmitting(false)
            salesUIProps.setFinishSale(true)
            setTopTab('payment')
            finish && onHide()
          } catch(err) {
            onHide()
          }
        }}
        innerRef={formRef}
      >
        {({ handleSubmit,values,errors,initialValues, setFieldValue, setFieldTouched, touched,isSubmitting, setTouched }) => (
        <BlockUi blocking={isSubmitting}>
            <Modal.Body className="overlay cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                {setProformaValues(values)}
                <div className="form-group row">
                  <div className="col-lg-4">
                  <label  htmlFor="customer_id">
                             Customer <span className="text-danger">*</span>
                          </label>
                    <CustomersUIProvider customersUIEvents={customersUIEvents}>
                      <CustomerEditDialog show={toggleModal} initalData={selectedCustomer?.id ==0 ? selectedCustomer:null} onHide={()=>{setToggle(false)}} ></CustomerEditDialog>
                      <DownShiftAutoComplete
                        customer={entities}
                        selectedCustomer={selectedCustomer}
                        setCustomerName={setCustomerName}
                        values={values}
                        setTouchedItem={setFieldTouched}
                        focusAuto={true}
                        setToggle={setToggle}/>
                    </CustomersUIProvider>
                    {selectedCustomer != undefined && selectedCustomer.id == 0 && <p style={{fontStyle: 'oblique',fontSize: '9px'}}>New customer</p> }
                    {errors["customer"] && touched["customer"] && !errors["customer"]?.name && <div className="invalid-feedback d-block">{errors["customer"]}</div>}
                    {errors["customer"]?.name && <div className="invalid-feedback d-block">{errors["customer"].name}</div>}
                  </div>  {/* Uncontroll Error*/}
                    <div className="col-lg-4">
                    <label  htmlFor="proforma_date">
                        Date <span className="text-danger">*</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <div className="d-flex flex-column">

                                  <DatePicker name="proforma_date"
                                            inputFormat="DD/MM/YYYY"
                                            zIndex={10000}
                                             onChange={(val) => {
                                                setFieldValue("proforma_date", val);
                                              }}
                                              value={values.proforma_date}
                                              required
                                              />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label  htmlFor="status_id">
                      Status <span className="text-danger">*</span>
                    </label>

                      <AsyncSearchBar
                        selectedItem={{id: values.status_id, name: statuses.find(p => p.id == values.status_id)?.name}}
                        setSelectedItem={setFieldValue}
                        setTouchedItem={setFieldTouched}
                        defaultItem={statuses.find(p => p.id == initialValues.status_id)?.name}
                        error={errors.status_id}
                        touched={touched.status_id}
                        item_id="status_id"
                        item_name="name"
                        options={statuses.filter(ps => (newButton?.newButton!=0 || (initialValues?.status_id != statuses[1].id &&  initialValues?.status_id != statuses[3].id)))}
                        isSearchable={false}
                        // {...(values?.status_id == 1 || values?.status_id == 3) ? setDisablePayment(false) : saleEntities?.length == 0 && setDisablePayment(true)}
                        {...(values?.status_id == 1 || values?.status_id == 3) ? setDisablePayment(false) : salesInProforma?.length == 0 && setDisablePayment(true)}
                      />

                    {errors["status_id"] && touched["status_id"] && <div className="invalid-feedback d-block">Status is required</div>}
                    {(values?.status_id === statuses[2].id) && (
                      <div><small className="text-warning">Car status is changed to Sold</small></div>
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-4">
                    <div className="radio-inline">
                      <label className="radio mr-7">
                        <input type="radio" name="isBank" value="cash"
                          onChange={()=>{
                            setFieldValue('isBank', false, true);
                            setFieldValue('expire', "", false);
                            setFieldValue('bank_id', "", false);
                          }}
                          checked={!values.isBank ? "checked" : ""}
                        />
                        <span></span>Cash
                      </label>
                      <label className="radio">
                        <input type="radio" name="isBank" value="bank"
                          onChange={()=> {
                            setFieldValue('isBank', true, true);
                            setFieldValue('expire', values.expire, true);
                            setFieldValue('bank_id', values.bank_id, true);
                          }}
                          checked={values.isBank ? "checked" : ""}
                        />
                        <span></span>Bank
                      </label>
                    </div>
                    <>
                      <AsyncSearchBar
                        selectedItem={{id: values.bank_id}}
                        setSelectedItem={setFieldValue}
                        setTouchedItem={setFieldTouched}
                        defaultItem={values.bank_id === "" ? "" : banks?.find(e => e.id == initialValues.bank_id)?.name}
                        error={errors.bank_id}
                        touched={touched.bank_id}
                        item_id="bank_id"
                        item_name="bankName"
                        // options={banks}
                        url={`${process.env.REACT_APP_AUTO_DEALER_API}banks/ethioBanks`}
                        isDisabled={!values.isBank}
                        clear={values.bank_id == ""}
                      />
                      {errors["bank_id"] && touched["bank_id"] && <div className="invalid-feedback d-block">Bank is required</div>}
                    </>
                  </div>

                  <div className="col-lg-4 mb-8">
                    <Field
                      name="expire"
                      component={Input}
                      placeholder="Expires in"
                      label="Expires in (days) "
                      type="number"
                      // required
                      key="expire"
                      withFeedbackLabel = {true}
                      min={1}
                      disabled={!values.isBank}
                    />
                  </div>

                  <div className="col-lg-4 mb-8">
                  <label  htmlFor="employee_id">
                  Sales Person <span className="text-danger">*</span>
                  </label>
                  {(initialValues && values) && (
                    <AsyncSearchBar
                      selectedItem={{id: values.employee_id}}
                      setSelectedItem={setFieldValue}
                      setTouchedItem={setFieldTouched}
                      defaultItem={employeeEntities?.find(e => e.id === initialValues.employee_id)?.name}
                      error={errors.employee_id}
                      touched={touched.employee_id}
                      item_id="employee_id"
                      item_name="name"
                      url={`${process.env.REACT_APP_AUTO_DEALER_API}employees/search`}
                    />
                  )}
                  {errors["employee_id"] && touched["employee_id"] && <div className="invalid-feedback d-block">Sales Person is required</div>}
                  </div>
                </div>

                {errors["proformaItems"] && touched["proformaItems"] && <div className="invalid-feedback d-block">Proforma must have at least 1 car</div>}
                <div className="form-group row" style={proformaItems?.length > 2 ?{paddingLeft:'1%',height:'350px',overflow:'auto'}:{paddingLeft:'1%'}}>
                  <table  className="table table-head-custom table-vertical-center overflow-hidden"style={{borderBottom:'1px ridge'}}>
                    <thead>
                    <tr>
                      <th>
                          Description
                      </th>

                      <th className="text-right">
                          Sales Amount
                      </th>
                      <th className="text-right">
                          Proforma Amount
                      </th>
                      <th className="">
                          Actions
                      </th>
                    </tr>
                    </thead>
                    <tbody >
                      {proformaItems && proformaItems.map((items)=>
                        (
                          <tr id={items.id} key={items.id}>
                            <td><b>{items.productName}</b>
                                    <br/>
                                    Condition : {items.newOrUsed}
                                     <br/>
                                     Color : {items.color}
                                     <br/>
                                     Model Year : {items.modelYear}
                                     <br/>
                                     CC : {items.cc}
                                     <br/>
                                     Chassis : {items.chassis}
                                     <br/>
                                     {items?.motorNo && `Engine No:  ${items.motorNo}`}
                                    </td>
                            <td className="text-right"><NumberFormat
                                                      value={items.sales_amount}
                                                      className="foo"
                                                      displayType={'text'}
                                                      thousandSeparator={true}

                                                    /></td>
                            <td className="text-right"><NumberFormat
                                                      value={items.proforma_amount}
                                                      className="foo"
                                                      displayType={'text'}
                                                      thousandSeparator={true}
                                                    /></td>
                            <td className=""><a
                                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                onClick={async () => {
                                  await setTouched({
                                    bank_id: true,
                                    customer: true,
                                    employee_id: true,
                                    expire: true,
                                    id: true,
                                    isBank: true,
                                    location_id: true,
                                    proforma_date: true,
                                    remark: true,
                                    status_id: true,
                                    user_name: true,
                                  })
                                  if(Object.keys(errors).length === 0) {
                                    editItems(items)
                                  }
                                }}
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-primary">
                                  <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                  />
                                </span>
                                </a>
                                {items.product_id != proformasUIContext.carId && <>

                                   <a
                                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                onClick={() => deleteItems(items,!deleteValidation.includes(items.id))}
                                >
                                  <span className={deleteValidation.includes(items.id)  ? "svg-icon svg-icon-md svg-icon-default":"svg-icon svg-icon-md svg-icon-primary"}>
                                  <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                                  />
                                </span>
                                </a>

                                </> }
                              </td>
                          </tr>

                          ))}
                          {proformaItems?.length > 1 &&<tr>
                              <td>Total Amount</td>
                              <td className="text-right"><NumberFormat value={totalAmount}
                                displayType={'text'}
                                thousandSeparator={true}/></td>
                              <td className="text-right"><NumberFormat value={proTotalAmount}
                                displayType={'text'}
                                thousandSeparator={true}/></td>
                              <td></td>
                          </tr>}
                      </tbody>

                  </table>
                </div>
                { initialValues.status_id != 3 && (
                  <div className="form-group row">
                    <div className="col-md-12 text-right margin-Top-10-mobile">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {setShowItemModal(true);
                          intialValue.uuid = uuid();
                          setNewProformaItems(intialValue);
                          setCarName("")
                          setCarIdForEdit(0)}}
                      >
                        Add Car
                      </button>
                    </div>
                  </div>
                )}
                <div className="form-group row">
                  <div className="col-lg-6">
                      <Field
                        name="remark"
                        component={TextArea}
                        placeholder="Remark"
                        label="Remark"
                        height='60px'
                        col = 'col-md-6'
                        key="remark"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between px-1 px-sm-6">
              <div className="mr-2">
                <button type="button" className="btn btn-light" onClick={onHide}>Cancel</button>
              </div>
              <div>
                {errors.customField /*&& touched.account_no*/ && (
                  <div style={{fontSize: '0.9rem',color: '#F64E60'}}>{errors.customField}</div>
                )}
                {activeVal && (<div style={{fontSize: '0.9rem',color: '#F64E60'}}>There is already an active proforma with selected cars</div>)}
                {proformasUIProps.selectedId>0 && initialValues.status_id != 3 && !proformasUIProps.proformaFromOutside &&
                <button
                  type="button"
                  onClick={proformasUIProps.openDeleteProformaDialog}
                  className="btn btn-danger btn-elevate ml-auto mr-2"
                >Delete</button>}
                {/* {(saleEntities?.length != 0 || (values.status_id == 1 || values.status_id == 3)) && ( */}
                {(salesInProforma?.length != 0 || (values.status_id == 1 || values.status_id == 3)) && (
                  <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    data-wizard-type="action-next"
                    disabled={Number(values?.proformaItems?.length || 0) === 0} //Pervent user from clicking the payment before cars are fetched.
                    // onClick={() => beforeSubmit(handleSubmit,values)}
                    onClick={() => {
                        if(location.pathname === orderPage) {
                            handleSubmit()
                        }
                        else{
                            beforeSubmit(handleSubmit, values)
                        }
                      proceedToPayment();
                    }}
                    ref={buttonRef}

                  >Payment</button>
                )}
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-wizard-type="action-next"
                  onClick={() => {
                    setFinish(true)
                    if(location.pathname === orderPage) {
                      handleSubmit()
                    }
                    else{
                      beforeSubmit(handleSubmit, values)
                    }
                  }}
                >Finish</button>
              </div>
            </Modal.Footer>
            <Modal
                show={showItemModal}
                hide={showItemModal}
                style={{top:'25px'}}
                    aria-labelledby="example-modal-sizes-title-lg">
                     <Modal.Header closeButton><Modal.Title id="example-modal-sizes-title-lg">New/Edit Car</Modal.Title>
                        <Nav className="ml-auto" onClick={hideSmallModal}>
                        <Nav.Link className="p-0 text-dark">
                          <span className="nav-icon">
                            <span className="svg-icon svg-icon-2x">
                              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                  <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </span>
                        </Nav.Link>
                      </Nav>
                     </Modal.Header>
                     <Modal.Body>
                     <Form className="form form-label-right" id="proformaItemsForm">
                        <div className="form-group">
                          <label  id="aria-label" htmlFor="aria-example-input">
                            Select a car <span className="text-danger">*</span>
                          </label>
                          <AsyncSearch  selectedCar={newProformaItems} setSelectedCar={setNewProformaItems}

                          carName = {carName} selectedCars={selectedCars}
                          enableEdit = {proformasUIProps.carId == carIdForEdit && carIdForEdit!=0 && carIdForEdit!="" ? true : false}/>
                         {validationSchemaForProformaItem.car && <div className="invalid-feedback d-block">Car is required</div>}
                        </div>
                        <input name="uuid" type="hidden" value={newProformaItems.uuid ? newProformaItems.uuid:""}/>
                        <div className="form-group">
                          <label  htmlFor="sales_amount">
                           Sales Amount <span className="text-danger">*</span>
                          </label>
                            <NumberFormat
                                                      id="sales_amount"
                                                      onValueChange={(e)=>handleProformaItemChange(e,"sales_amount")}
                                                      value = {newProformaItems.sales_amount}
                                                      className="form-control"
                                                      thousandSeparator={true}
                                                    />

                            {validationSchemaForProformaItem.sales_amount && <div className="invalid-feedback d-block"> Sales Amount is required</div>}
                        </div>
                        <div className="form-group">
                           <label  htmlFor="proforma_amount">
                            Proforma Amount <span className="text-danger">*</span>
                          </label>
                            <NumberFormat
                                                      id="proforma_amount"
                                                      onValueChange={(e)=>handleProformaItemChange(e,"proforma_amount")}
                                                      value = {newProformaItems.proforma_amount}
                                                      className="form-control"
                                                      thousandSeparator={true}
                                                    />
                            {validationSchemaForProformaItem.proforma_amount && <div className="invalid-feedback d-block">Proforma Amount is required</div>}
                            </div>
                        <div className="form-group">

                            <label  htmlFor="proforma_amount">
                            Invoice Amount
                          </label>
                          <NumberFormat
                                                      id="invoice_amount"
                                                      onValueChange={(e)=>handleProformaItemChange(e,"invoice_amount")}
                                                      defaultValue = {newProformaItems.invoice_amount || 0}
                                                      className="form-control"
                                                      thousandSeparator={true}
                                                    />
                            </div>

                            <div className="form-group">
                              <label  htmlFor="gps_amount">
                                Speed Meter Amount
                                 </label>
                              <NumberFormat
                                                        id="gps_amount"
                                                        onValueChange={(e)=>handleProformaItemChange(e,"gps_amount")}
                                                        defaultValue = {newProformaItems.gps_amount || 0}
                                                        className="form-control"
                                                        thousandSeparator={true}
                                                      />

                            </div>

                            <div className="form-group">

                            <label  htmlFor="commission_amount">
                              Commission Amount
                            </label>
                            <NumberFormat
                                                      id="commission_amount"
                                                      onValueChange={(e)=>handleProformaItemChange(e,"commission_amount")}
                                                      defaultValue = {newProformaItems.commission_amount || 0}
                                                      className="form-control"
                                                      thousandSeparator={true}
                                                    />

                            </div>
                        </Form>
											</Modal.Body>
                     <Modal.Footer>
                       <>
                       <button
                          onClick={() => handleProformaItemSubmit(values)}
                          className="btn btn-primary btn-elevate "
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          onClick={()=>{setShowItemModal(false);hideValidationMessage()}}
                          className="btn btn-light btn-elevate mr-auto"
                        >
                          Cancel
                        </button>

                        </>
                        </Modal.Footer>
                </Modal>
          </BlockUi>
        )}
      </Formik>
      </div>

      <div data-wizard-type="step-content" data-wizard-state={selectedTopTab === "payment" ? "current" : ""}>
        {/* <SaleEditDialog_1 saleEntities={saleEntities} selectedTopTab={selectedTopTab} setTopTab={setTopTab} /> */}
        <SaleEditDialog_1 salesInProforma={salesInProforma} selectedTopTab={selectedTopTab} setTopTab={setTopTab} />
      </div>
    </div>
    </>
  );
}
