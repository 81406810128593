import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { DatePicker } from '@mantine/dates';
import NumberFormat from "react-number-format";
import * as Yup from "yup";
import { TextArea, } from "../../../../../_metronic/_partials/controls";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
// Validation schema
const TransactionEditSchema = Yup.object().shape({
  isTransfer: Yup.boolean(),
  date: Yup.date().nullable().required("Date is required"),
  rate: Yup.number().required("Rate is required"), 
  amount: Yup.number().required("Amount is required").typeError('Amount must be a number')
});

export function TransactionEditForm({
  account_id,
  saveTransaction,
  transaction,
  actionsLoading,
  onHide,
  openDeleteTransactionDialog
}) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{...transaction, isTransfer: (transaction?.payment ? false : true), amount: transaction?.transfer || transaction?.payment}}
        validationSchema={TransactionEditSchema}
        onSubmit={(values) => {
          const valuesToSend = {
            ...values, 
            account_id: account_id, 
            closed_date: null, 
            registered_by: localStorage.getItem('currentUserEmail')
          }
          saveTransaction(valuesToSend);
        }}
      >
        {({ handleSubmit,values,isSubmitting, setFieldValue, errors, touched, setFieldTouched }) => (
          <BlockUi blocking={isSubmitting}>
            <Modal.Body className="overlay  cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-10">
                    <div className="radio-inline mb-3">
                      <label className="radio mr-7">
                        <input type="radio" name="isTransfer" value="transfer" 
                          onChange={()=>{
                            setFieldValue('isTransfer', true, true);
                            setFieldValue('transfer', values.amount, true);
                            setFieldValue('payment', null, true);
                          }} 
                          checked={values.isTransfer ? "checked" : ""} 
                        />
                        <span></span>Transfer
                      </label>
                      <label className="radio">
                        <input type="radio" name="isTransfer" value="bank" 
                          onChange={()=> {
                            setFieldValue('isTransfer', false, true);
                            setFieldValue('transfer', null, true);
                            setFieldValue('payment', values.amount, true);
                          }} 
                          checked={!values.isTransfer ? "checked" : ""} 
                        />
                        <span></span>Payment
                      </label>
                    </div>
                    <>
                      <label  htmlFor="amount">
                          Amount <span className="text-danger">*</span>
                      </label>
                      <NumberFormat
                        id="amount"
                        name="amount"
                        onValueChange={val => {
                          setFieldValue("amount", val.value);
                          if(values.isTransfer) {
                            setFieldValue('transfer', val.value, true);
                            setFieldValue('payment', null, true)
                          }
                          else {
                            setFieldValue('payment', val.value, true);
                            setFieldValue('transfer', null, true)
                          }
                        }}
                        onBlur={() => {
                          setFieldTouched("amount", true, true)
                        }}
                        value = {values.amount}
                        className={(
                          errors.amount && touched.amount
                          ) ? "form-control is-invalid" : "form-control"
                        }
                        thousandSeparator={true}
                        allowEmptyFormatting ={false}
                        placeholder={values.isTransfer ? 'Transfter' : 'Payment'}
                      />
                      {
                        errors.amount && touched.amount && (
                          <div className="invalid-feedback d-block">{errors.amount}</div>
                        )
                      }
                    </>
                  </div>
                </div>
                
                <div className="form-group row">
                  <div className="col-lg-10">
                    <label  htmlFor="rate">
                        Rate <span className="text-danger">*</span>
                    </label>
                    <NumberFormat
                      id="rate"
                      name="rate"
                      onValueChange={val => {
                        setFieldValue("rate", val.value);
                      }}
                      onBlur={() => {
                        setFieldTouched("rate", true, true)
                      }}
                      value = {values.rate}
                      className={(
                        errors.rate && touched.rate
                        ) ? "form-control is-invalid" : "form-control"
                      }
                      thousandSeparator={true}
                    />
                    {
                      errors.rate && touched.rate && (
                        <div className="invalid-feedback d-block">{errors.rate}</div>
                      )
                    }
                  </div>
                </div>

                  <div className="form-group row">
                  
                  <div className="col-lg-10">
                  <label  htmlFor="date">
                        Date <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex flex-column">
                      <DatePicker 
                        name="date" 
                        inputFormat="DD/MM/YYYY" 
                        required
                        zIndex={10000}
                        value={values.date}
                        invalid={touched.date && (errors.date != null)}
                        onChange={(val) => {
                          setFieldValue("date", val);
                        }}
                      />
                      {errors.date && touched.date && (
                        <div className="invalid-feedback d-block">{errors.date}</div>
                      )}
                    </div>
                  </div>
                  </div>

                  <div className="form-group row">
                  <div className="col-lg-10">
                    <Field
                      name="description"
                      component={TextArea}
                      placeholder="Description"
                      label="Description"
                      withFeedbackLabel= {true}
                    />
                  </div>
                  </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
            <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate mr-auto"
              >
                Cancel
              </button>
              { values.id != undefined &&<button
                type="button"
                onClick={() => openDeleteTransactionDialog(account_id, values.id)}
                className="btn btn-danger btn-elevate ml-auto"
              >
                Delete
              </button>}
            </Modal.Footer>
          </BlockUi>
        )}
      </Formik>
    </>
  );
}
