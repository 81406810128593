import React, { useEffect, useMemo, useState } from "react";
import { Formik, Field } from "formik";
import { isEqual } from "lodash";
import { useSalesUIContext } from "../SalesUIContext";
import AsyncSearchBar from "../../../shared/AsyncSelectBar";
import { initialFilter } from "../SalesUIHelpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import { setSalesParams } from "../../../../_redux/reports/reportsActions";
import { DatePicker } from '@mantine/dates';

export function SalesFilter({ collapsed, setCollapsed, listLoading }) {
  // Sales UI Context
  const salesUIContext = useSalesUIContext();
  const salesUIProps = useMemo(() => {
    return {
      queryParams: salesUIContext.queryParams,
      setQueryParams: salesUIContext.setQueryParams,
    };
  }, [salesUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(salesUIProps.queryParams, values);
    if (!isEqual(newQueryParams, salesUIProps.queryParams)) {
      salesUIProps.setQueryParams(newQueryParams);
    }
  };

  const dispatch = useDispatch();
  const { currentState } = useSelector(
    (state) => ({ currentState: state }),
    shallowEqual
  );
  let { salesForReport: entities } = currentState.sales;
  const { batchEntities } = currentState.batch
  const { supplierEntities } = currentState.suppliers;

  const [initDate, setInitDate] = useState()

  useEffect(() => {
    var date = new Date();
      setInitDate([
        new Date(date.getFullYear(), date.getMonth(), 1),
        new Date(), 
      ])
    
  }, [entities])

  useEffect(() => {
    if (initDate) {
      initialFilter.filter.start_date = initDate[0]
      initialFilter.filter.end_date = initDate[1]
    }
  }, [initDate])
  const [toggleSearch,setToggleSearch] = useState(window.screen.width > 500 ? false:false);

  const prepareFilter = (queryParams, values) => {
  const { category, batch_id, supplier_id, employee_id, start_date, end_date, inProcess, processed, year, color, group_batch } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  const hidden = {};
  filter.category = (!category || category == "-- Select --") ? "" : category;
  filter.batch = (!batch_id || batch_id == "-- Select --") ? "" : `${batch_id}`;
  filter.supplier = (!supplier_id || supplier_id == "-- Select --") ? "" : `${supplier_id}`;
  filter.employee = (!employee_id || employee_id == "-- Select --") ? "" : `${employee_id}`;
  filter.inProcess = inProcess ? "1" : "";
  filter.processed = processed ? "1" : "";
  // filter.start_date = start_date ? moment(start_date).format("YYYY-MM-DD") : ""
  // filter.end_date = end_date ? moment(end_date).format("YYYY-MM-DD") : ""
  filter.start_date = start_date ? moment(start_date).format("YYYY-MM-DD") : ""
  filter.end_date = end_date ? moment(end_date).format("YYYY-MM-DD") : ""
  filter.year = year ? "1" : "";
  filter.color = color ? "1" : "";
  filter.group_batch = group_batch ? "1" : "";
  hidden.year = year ? false : true;
  hidden.color = color ? false : true;
  hidden.batch = group_batch ? false : true;
  dispatch(setSalesParams({...queryParams, filter, hidden}))
  newQueryParams.filter = filter;
  newQueryParams.hidden = hidden;
  return newQueryParams;
};

  return (
    <div className={`${collapsed ? `d-lg-none` : `d-block`} w-100 w-lg-275px w-xl-300px`}>
      {initDate && (
        <>
          <div className="d-flex justify-content-between card-header border-0 pb-0">
            <h4 className="card-title align-items-start flex-column">
              <span className="card-label text-dark">Filter</span>
            </h4>
            <div className={"d-lg-none"}>
              <div className="row px-5">
                <a className="ml-auto" onClick={()=>{setToggleSearch(!toggleSearch)}}>
                <span className="svg-icon svg-icon-primary svg-icon-2x">
                  <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24"/>
                          <path d="M8.2928955,3.20710089 C7.90237121,2.8165766 7.90237121,2.18341162 8.2928955,1.79288733 C8.6834198,1.40236304 9.31658478,1.40236304 9.70710907,1.79288733 L15.7071091,7.79288733 C16.085688,8.17146626 16.0989336,8.7810527 15.7371564,9.17571874 L10.2371564,15.1757187 C9.86396402,15.5828377 9.23139665,15.6103407 8.82427766,15.2371482 C8.41715867,14.8639558 8.38965574,14.2313885 8.76284815,13.8242695 L13.6158645,8.53006986 L8.2928955,3.20710089 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 8.499997) scale(-1, -1) rotate(-90.000000) translate(-12.000003, -8.499997) "/>
                          <path d="M6.70710678,19.2071045 C6.31658249,19.5976288 5.68341751,19.5976288 5.29289322,19.2071045 C4.90236893,18.8165802 4.90236893,18.1834152 5.29289322,17.7928909 L11.2928932,11.7928909 C11.6714722,11.414312 12.2810586,11.4010664 12.6757246,11.7628436 L18.6757246,17.2628436 C19.0828436,17.636036 19.1103465,18.2686034 18.7371541,18.6757223 C18.3639617,19.0828413 17.7313944,19.1103443 17.3242754,18.7371519 L12.0300757,13.8841355 L6.70710678,19.2071045 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(12.000003, 15.499997) scale(-1, -1) rotate(-360.000000) translate(-12.000003, -15.499997) "/>
                      </g>
                  </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="card-body pt-0 pr-lg-0" style={{ display: !toggleSearch && window.screen.width <= 990 ? 'none' : '' }}>
            <Formik
              // initialValues={{...initialFilter.filter,  'start_date': moment(initDate[0], "DD/MM/YYYY"), 'end_date': moment(initDate[1], "DD/MM/YYYY")}}
              initialValues={
                {
                  ...salesUIProps.queryParams.filter, 
                  // 'start_date' : moment(salesUIProps.queryParams.filter.start_date, "DD/MM/YYYY") || moment(initDate[0], "DD/MM/YYYY"),
                  // 'end_date' : moment(salesUIProps.queryParams.filter.end_date, "DD/MM/YYYY") || moment(initDate[1], "DD/MM/YYYY")
                  'start_date': moment(salesUIProps.queryParams.filter.start_date)?._d || moment(initDate[0]),
                  'end_date': moment(salesUIProps.queryParams.filter.end_date)?._d || moment(initDate[1]),
                }}
              onSubmit={(values) => {
                applyFilter(values);
              }}
            >
              {({
              initialValues, 
              values,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              resetForm
              }) => (
                <form onSubmit={handleSubmit} className="form form-label-right">
                  <div className={`${toggleSearch ? `` : `d-none`} d-lg-block`}>
                    <div className="form-group mb-4">
                      <div className="d-flex flex-column">
                        <DatePicker 
                          name="start_date"  
                          label="Start Date"
                          inputFormat="DD/MM/YYYY"
                          onChange={(val) => {
                            setFieldValue("start_date", val);
                          }}
                          value={values.start_date}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-6">
                      <div className="d-flex flex-column">
                        <DatePicker 
                          name="end_date"  
                          label="End Date"
                          inputFormat="DD/MM/YYYY"
                          onChange={(val) => {
                            setFieldValue("end_date", val);
                          }}
                          value={values.end_date}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label>Make/Brand</label>
                      <AsyncSearchBar 
                        selectedItem={{id: values.category}} 
                        setSelectedItem={setFieldValue}
                        setTouchedItem={setFieldTouched}
                        defaultItem={salesUIProps.queryParams.filter.category}
                        item_id="category"
                        item_name="categoryName"
                        url={`${process.env.REACT_APP_AUTO_DEALER_API}product/getBrandAndModel`}
                        pk="categoryName"
                      />
                    </div>
                    <div className="form-group mb-6">
                      <label>Batch</label>
                      <AsyncSearchBar 
                        selectedItem={{id: values.batch_id}} 
                        setSelectedItem={setFieldValue}
                        setTouchedItem={setFieldTouched}
                        defaultItem={batchEntities?.find(b => salesUIProps.queryParams.filter.batch == b.id)?.name}
                        touched={touched.batch_id}
                        item_id="batch_id"
                        item_name="name"
                        url={`${process.env.REACT_APP_AUTO_DEALER_API}batches/search`}
                      />
                    </div>
                    <div className="form-group mb-6">
                      <label>Supplier</label> 
                      <AsyncSearchBar 
                        selectedItem={{id: values.supplier_id}} 
                        setSelectedItem={setFieldValue}
                        setTouchedItem={setFieldTouched}
                        defaultItem={supplierEntities?.find(s => salesUIProps.queryParams.filter.supplier_id == s.id)?.name}
                        touched={touched.supplier_id}
                        item_id="supplier_id"
                        item_name="name"
                        url={`${process.env.REACT_APP_AUTO_DEALER_API}suppliers/search`}
                      />
                    </div>
                    <div className="form-group mb-6">
                      <label>Sales Person</label> 
                      <AsyncSearchBar 
                        selectedItem={{id: values.employee_id}} 
                        setSelectedItem={setFieldValue}
                        setTouchedItem={setFieldTouched}
                        defaultItem={supplierEntities?.find(e => salesUIProps.queryParams.filter.employee_id == e.id)?.name}
                        touched={touched.employee_id}
                        item_id="employee_id"
                        item_name="name"
                        url={`${process.env.REACT_APP_AUTO_DEALER_API}employees/search`}
                      />
                    </div>
                    <div className="form-group mb-4">
                      <div className="d-flex">
                        <span className="mr-5">
                          <Field 
                            type="checkbox" 
                            name="inProcess" 
                            onChange={(e) => {
                              setFieldValue("inProcess", e.target.checked ? '1' : '');
                            }}
                          />
                          <label className="ml-1">In-Process</label>
                        </span>
                        <span className="mr-5">
                          <Field 
                            type="checkbox" 
                            name="processed" 
                            onChange={(e) => {
                              setFieldValue("processed", e.target.checked ? '1' : '');
                            }}
                          />
                          <label className="ml-1">Processed</label>
                        </span>
                      </div>
                    </div>
                    <div className="form-group mb-4 d-none d-lg-block">
                      <label htmlFor="group_by">Group By</label>
                      <br/>
                      <div className="d-flex">
                        <span className="mr-5">
                        <Field 
                          type="checkbox" 
                          name="year" 
                          onChange={(e) => {
                            setFieldValue("year", e.target.checked ? '1' : '');
                          }}
                        />
                        <label className="ml-1">Year</label>
                        </span>
                        <span className="mr-5">
                        <Field 
                          type="checkbox" 
                          name="color" 
                          onChange={(e) => {
                            setFieldValue("color", e.target.checked ? '1' : '');
                          }}
                        />
                        <label className="ml-1">Color</label>
                        </span>
                        <span>
                          <Field 
                            type="checkbox" 
                            name="group_batch" 
                            onChange={(e) => {
                              setFieldValue("group_batch", e.target.checked ? '1' : '');
                            }}
                          />
                          <label className="ml-1">Batch</label>
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <button 
                        type="submit" 
                        className="btn btn-primary px-8" 
                        onClick={()=>{
                          handleSubmit();
                          setCollapsed(false)
                          setToggleSearch(false);
                        }}>
                          Apply
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </>
      )}		
    </div>
  );
}
