import React, { useLayoutEffect } from 'react';
import {  useHistory } from "react-router-dom"
import { authenticationService } from '../_services/index';
import '../../../assets/css/pages/login/login-2.css'
import '../../../assets/plugins/global/plugins.bundle.css'
import '../../../assets/plugins/custom/prismjs/prismjs.bundle.css'
import '../../../assets/css/style.bundle.css'
import FindUserForm from './FindUserForm/FindUserForm';


const ForgotPasswordModal = () => {

	const historyUSE = useHistory()
	useLayoutEffect(() => {
		if (authenticationService.currentUserValue) {
			historyUSE.push('/dashboard');
		} else {
			if (!(window.location.href).indexOf('/login'))
				historyUSE.push('/login');
		}
	}, [historyUSE]);


	return (
		<div className="login login-2 login-signin-on  flex-lg-row flex-column-fluid bg-white" >
		<FindUserForm />
		</div>

	)
}

export default ForgotPasswordModal;