/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./SalesUIHelper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as carActions from "../../../_redux/cars/carsActions";
import * as proformaActions from "../../../_redux/proformas/proformasActions";
import { resetSalesSlice } from "../../../_redux/sales/salesActions";

const SalesUIContext = createContext();

export function useSalesUIContext() {
  return useContext(SalesUIContext);
}

export const SalesUIConsumer = SalesUIContext.Consumer;

export function SalesUIProvider({ currentProductId, children, saleFromOutside=false }) {
  const [saleId, setSaleId] = useState();
  const [proformaId, setProformaId] = useState()
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [productId, setProductId] = useState(currentProductId);
  const [values, setValues] = useState()
  const [isLoading, setIsLoading] = useState()
  const [currentSale, setCurrentSale] = useState()
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const [oldUuid,setOldUidd] = useState(null);
  const initSale = {
    id: undefined,
    payment_type_id: "",
    amount: "",
    bank_id: "",
    payment_date: new Date(),
    remark: "",
    user_name:"",
    last_updated:""
  };

  const dispatch = useDispatch();

  const { carForEdit } = useSelector(
    (state) => ({
      carForEdit: state.cars.carForEdit
    }),
    shallowEqual
  );

  useEffect(() => {
    initSale.productId = currentProductId;
    setProductId(currentProductId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProductId]);
  const [
    showEditSaleDialog,
    setShowEditSaleDialog,
  ] = useState(false);
  const openNewSaleDialog = () => {
    carForEdit && dispatch(carActions.selectCurrentCarId(carForEdit?.id))
    setSelectedId(undefined);
    setShowEditSaleDialog(true);
  };
  const openEditSaleDialog = (id) => {
    dispatch(carActions.selectCurrentCarId(carForEdit?.id))
    setSelectedId(id);
    setShowEditSaleDialog(true);
  };
  const closeEditSaleDialog = () => {
    dispatch(carActions.selectCurrentCarId())
    saleFromOutside && setProductId(undefined)
    saleFromOutside && dispatch(resetSalesSlice())
    saleFromOutside && dispatch(proformaActions.resetProformasSlice())
    setSelectedId(undefined);
    setShowEditSaleDialog(false);
  };
  const [
    showDeleteSaleDialog,
    setShowDeleteSaleDialog,
  ] = useState(false);
  const openDeleteSaleDialog = (id,uuid=null) => {
    setSelectedId(id);
    if(uuid)
      setOldUidd(uuid)
    setShowDeleteSaleDialog(true);
  };
  const closeDeleteSaleDialog = () => {
    // setSelectedId(undefined);
    setShowDeleteSaleDialog(false);
  };

  const [
    showCompleteSaleDialog,
    setShowCompleteSaleDialog,
  ] = useState(false);
  const openCompleteSaleDialog = (id) => {
    // setSelectedId(id);
    setProformaId(id)
    setShowCompleteSaleDialog(true);
  };
  const closeCompleteSaleDialog = (removeProd=true) => {
    // setSelectedId(undefined);
    setShowCompleteSaleDialog(false);
    removeProd && saleFromOutside && setProductId(undefined)
  };

  const [
    showDeleteChangeProformaDialog,
    setShowDeleteChangeProformaDialog,
  ] = useState(false);
  const openDeleteChangeProformaDialog = (id) => {
    // setSelectedId(id);
    setProformaId(id)
    setShowDeleteChangeProformaDialog(true);
  };
  const closeDeleteChangeProformaDialog = () => {
    // setSelectedId(undefined);
    setShowDeleteChangeProformaDialog(false);
    //setProductId(undefined)
  };


  const [
    showContinueSaleDialog,
    setShowContinueSaleDialog,
  ] = useState(false);
  const openContinueSaleDialog = () => {
    setShowContinueSaleDialog(true);
  };
  const closeContinueSaleDialog = (continueSale=false) => {
    !continueSale && dispatch(carActions.selectCurrentCarId())
    setShowContinueSaleDialog(false);
    setShowEditSaleDialog(false)
    !continueSale && saleFromOutside && setProductId(undefined)
    !continueSale && saleFromOutside && dispatch(proformaActions.resetProformasSlice())
  };

  const [
    showDeleteSalesDialog,
    setShowDeleteSalesDialog,
  ] = useState(false);
  const openDeleteSalesDialog = () => {
    setShowDeleteSalesDialog(true);
  };
  const closeDeleteSalesDialog = () => {
    setShowDeleteSalesDialog(false);
  };
  const [
    showFetchSalesDialog,
    setShowFetchSalesDialog,
  ] = useState(false);
  const openFetchSalesDialog = () => {
    setShowFetchSalesDialog(true);
  };
  const closeFetchSalesDialog = () => {
    setShowFetchSalesDialog(false);
  };

  const [
    showViewSalesDialog,
    setShowViewSalesDialog,
  ] = useState(false);
  const openViewSalesDialog = () => {
    setShowViewSalesDialog(true);
  };
  const closeViewSalesDialog = () => {
    dispatch(carActions.selectCurrentCarId())
    saleFromOutside && setProductId(undefined)
    saleFromOutside && dispatch(resetSalesSlice())
    saleFromOutside && dispatch(proformaActions.resetProformasSlice())
    setSelectedId(undefined);
    setShowViewSalesDialog(false);
  };

  const [finishSale, setFinishSale] = useState(true)

  const value = {
    ids,
    setIds,
    saleId,
    values,
    setValues,
    productId,
    proformaId,
    setProformaId,
    setSaleId,
    queryParams,
    setQueryParams,
    initSale,
    selectedId,
    showEditSaleDialog,
    openEditSaleDialog,
    openNewSaleDialog,
    closeEditSaleDialog,
    showDeleteSaleDialog,
    openDeleteSaleDialog,
    closeDeleteSaleDialog,
    showCompleteSaleDialog,
    openCompleteSaleDialog,
    closeCompleteSaleDialog,
    showDeleteChangeProformaDialog,
    openDeleteChangeProformaDialog,
    closeDeleteChangeProformaDialog,
    showContinueSaleDialog,
    openContinueSaleDialog,
    closeContinueSaleDialog,
    showDeleteSalesDialog,
    openDeleteSalesDialog,
    closeDeleteSalesDialog,
    showFetchSalesDialog,
    openFetchSalesDialog,
    closeFetchSalesDialog,
    saleFromOutside,
    isLoading,
    setIsLoading,
    currentSale,
    setCurrentSale,
    showViewSalesDialog,
    openViewSalesDialog,
    closeViewSalesDialog,
    finishSale,
    setFinishSale,
    oldUuid
  };

  return (
    <SalesUIContext.Provider value={value}>
      {children}
    </SalesUIContext.Provider>
  );
}
