import {createSlice} from "@reduxjs/toolkit";

const initialSettingsState = {
  paymentMethod:{
    entities:null,
    totalCount:0,
    listLoading:false
  },
  subscriptionPlan:{
    entities:null,
    totalCount:0,
    listLoading:false
  },
  lastError: null
};
export const callTypes = {
  paymentMethod:"paymentMethod",
  subscriptionPlan:"subscriptionPlan"
};

export const settingsAdminSlice = createSlice({
  name: "setting_admin",
  initialState: initialSettingsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.paymentMethod) {
        state.paymentMethod.listLoading = false;
      } 
      if (action.payload.callType === callTypes.subscriptionPlan) {
        state.subscriptionPlan.listLoading = false;
      } 
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.paymentMethod) {
        state.paymentMethod.listLoading = true;
      } 
      if (action.payload.callType === callTypes.subscriptionPlan) {
        state.subscriptionPlan.listLoading = true;
      } 
    },
    
    paymentTypesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.paymentMethod.listLoading = false;
      state.error = null;
      state.paymentMethod.entities = entities;
      state.paymentMethod.totalCount = totalCount;
    },
    subscriptionTypesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.subscriptionPlan.listLoading = false;
      state.error = null;
      state.subscriptionPlan.entities = entities;
      state.subscriptionPlan.totalCount = totalCount;
    }
   
  }
});
