// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useState,useLayoutEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from '../../../../_redux/client/clientsActions'
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,


} from "./../../../../../../_metronic/_partials/controls";
import BlockUi from 'react-block-ui';
import { DatePicker } from '@mantine/dates';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";

export function Profile() {
 
  const { currentState } = useSelector(
    (state) => ({ currentState: state.client }),
    shallowEqual
  );
  const { clientEntities,error,apiErrorData } = currentState;

  const ProfileSchema = Yup.object().shape({
		name: Yup.string()
			.required("Name is required"), 
    header: Yup.string()
			.required("Short company name is required").nullable()
	});

  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }
    }
  },[error])
  const [sumbitting,setSumbitting] = useState(true)
  const dispatch = useDispatch();
  const saveProfile = (values) => {
    dispatch(actions.updateClient(values)).then(()=>{
      setSumbitting(false)
      toast.success("Profile Saved Successfully!")
    })
  }
  
  return (
    <>
    <ToastContainer autoClose={1000}/>
    <Formik
        enableReinitialize={true}
        initialValues={clientEntities}
        validationSchema={ProfileSchema}
        onSubmit={(values) => {
          setSumbitting(true)
          saveProfile(values);
        }}
      >
        {({ handleSubmit,values,errors,isSubmitting,setFieldValue }) => (
          <BlockUi blocking={isSubmitting && sumbitting}>
            
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Name */}
                  
                  <div className="col-lg-4">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Company Name"
                      label="Company Name"
                      withFeedbackLabel= {true}
                      required
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="header"
                      component={Input}
                      placeholder="Short Company Name"
                      label="Short Company Name"
                      withFeedbackLabel= {true}
                      required
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="contact_person"
                      component={Input}
                      placeholder="Contact Person"
                      label="Contact Person"
                      withFeedbackLabel= {false}

                    />
                  </div>
                  </div>
                  {/* Telephone */}
                  <div className="form-group row">
                  <div className="col-lg-4">
                    <Field
                      
                      name="address"
                      component={Input}
                      placeholder="Address"
                      label="Address"
                      withFeedbackLabel= {false}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="email"
                      component={Input}
                      placeholder="Email"
                      label="Email"
                      withFeedbackLabel= {false}

                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="telephone"
                      component={Input}
                      placeholder="Telephone"
                      label="Telephone"
                      withFeedbackLabel= {false}
                    />
                  </div>
                  </div>
                  
                <div className="form-group row">
                  
                  
                  <div className="col-lg-4">
                    <Field
                      name="po_box"
                      component={Input}
                      placeholder="Po-box"
                      label="Po-box"
                      withFeedbackLabel= {false}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="web_site"
                      component={Input}
                      placeholder="Web Site"
                      label="Web Site"
                      withFeedbackLabel= {false}
                    />
                  </div>
                  <div className="col-lg-4">
                  <DatePicker 
                        name="startYearDate" 
                        label="Start Year Date"
                        inputFormat="DD/MM/YYYY"
                        zIndex={10000}
                        autoComplete="off"
                        onChange={(val) => {
                          setFieldValue("startYearDate", moment(val).format("YYYY/MM/DD"));
                        }}
                        placeholder="Start Year Date"
                        value={new Date(values.startYearDate)}
                        />
                  </div>
                  
                </div>
                
              </Form>
            
           
            <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              
              
            
          </BlockUi>
        )}
      </Formik>
      
    </>
  );
}
