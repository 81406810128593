
export default function userGenerator(fullName,users){

    let spaceIndex = fullName.indexOf(' ') > 0 ? fullName.indexOf(' ')+1 : fullName.length;
    let nameWithoutSpace = fullName.replace(/\s/g, '');
    let potentialUserName = nameWithoutSpace.substring(0,spaceIndex)
    let dbExtenstion = localStorage.currentUserEmail.substring(localStorage.currentUserEmail.indexOf('@'))
    let j=1;

    while(true){
        if((spaceIndex+j)>nameWithoutSpace.length){
            spaceIndex=0;
            
        }
       if(users.map((item)=>{return item.userName}).includes(potentialUserName + dbExtenstion)){
            potentialUserName = potentialUserName + nameWithoutSpace.substring(spaceIndex,(spaceIndex+j))
            spaceIndex++;
            continue
       }else{
           return potentialUserName + dbExtenstion 
       }
       
      }

}