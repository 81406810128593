import React from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import store, { persistor } from "./redux/store";
import './index.css';
import App from './app/components/App';
import reportWebVitals from './reportWebVitals';
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter as Router } from 'react-router-dom';

const { PUBLIC_URL } = process.env;
//Axios common JWT Token
axios.defaults.headers.common['Authorization'] = localStorage.currentUser

/* const mock = */ 
//_redux.mockAxios(axios);
//_redux.setupAxios(axios, store);
ReactDOM.render(
 /*  <React.StrictMode> */
   <Router>
      <App  store={store} persistor={persistor} basename={PUBLIC_URL}/>
   </Router>
 /*  </React.StrictMode> */,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
