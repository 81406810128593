import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditTransactionDialog, openDeleteTransactionDialog }
) {
  return (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip id="spec-delete-tooltip">Edit Bank</Tooltip>
        }
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
          onClick={() => openEditTransactionDialog(row.account_id, row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} title=" " />
          </span>
        </a>
      </OverlayTrigger>
      <> </>
      <OverlayTrigger
        overlay={
          <Tooltip id="spec-delete-tooltip">Delete Bank</Tooltip>
        }
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={() => openDeleteTransactionDialog(row.account_id, row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} title=" " />
          </span>
        </a>
      </OverlayTrigger>
    </>
  );
}
