// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo,useState,useLayoutEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
}  from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/customers/customersActions";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "./../../../../../../_metronic/_helpers";
import * as uiHelpers from "../CustomersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { PaginationLinks } from "./../../../../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../CustomersUIContext";
import cloneDeep from 'lodash/cloneDeep';
import NotFoundPage from "../../../shared/NotFoundPage";
import $ from 'jquery';

export function CustomersTable() {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
    };
  }, [customersUIContext]);

  // Getting curret state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.customers }),
    shallowEqual
  );
  const { totalCount, entities, listLoading,error,apiErrorData } = currentState;
  const [tableData,setTableDate] = useState([]);
  const [notFound,setNotFound] = useState(false);
  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }else if(error 
          && apiErrorData?.errorCode==="NOT_FOUND"){
            setNotFound(true);
        }
    }
  },[error])
  useEffect(()=>{
    if(notFound){
      $("#kt_content > div > div > div.card-header > div.card-toolbar > button").hide();
    }
  },[notFound])
  
  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    customersUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchCustomers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    
  }, [ dispatch]);
  
  useEffect(()=>{
    setTableDate(cloneDeep(entities))
  },[entities])

  // Table columns
  const columns = [
    
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          customersUIProps.openEditCustomerDialog(row.id);
        }
      }
    },
    {
      dataField: "tin",
      text: "Tin",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events: { 
        onClick: (e, column, columnIndex, row, rowIndex) => {
          customersUIProps.openEditCustomerDialog(row.id);
        }
      }
    },
    
    {
      dataField: "telephone",
      text: "Telephone",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          customersUIProps.openEditCustomerDialog(row.id);
        }
      }
    },
    
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
        openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog,
      },
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: tableData?.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: (customersUIProps.queryParams.pageNumber - 1) < (tableData?.length/customersUIProps.queryParams.pageSize) ? customersUIProps.queryParams.pageNumber : 
    customersUIProps.queryParams.pageNumber == 1 ?  customersUIProps.queryParams.pageNumber:customersUIProps.queryParams.pageNumber - 1  ,
  };

  function filter(e){
    const filter = entities?.filter(item => item.name.toLowerCase().indexOf(e.toLowerCase()) > -1 || item.telephone?.indexOf(e.toLowerCase()) > -1 || item.tin?.indexOf(e.toLowerCase()) > -1  )
    setTableDate(filter)
  }

  function pageHandler (paginationProps){
    
      const pagenumber = paginationProps?.page || 1
      const sizePerPage = paginationProps?.sizePerPage
      customersUIProps.setQueryParams(
        (prev) =>
        "pagination" === "pagination"
          ? { ...prev, pageNumber:pagenumber, pageSize: sizePerPage }
          : prev
      )
      
  }

  return (
    <>
    {!notFound ?
    <>
    <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                   onChange={(e) => {
                    filter(e.target.value.toLocaleLowerCase().trim());
                   }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          
          return (
            <>
              
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                keyField="id"
                data={tableData === null ? [] : tableData}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={pageHandler(paginationProps)
                 }
                rowStyle={{ cursor: 'pointer' }}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={tableData} />
                <NoRecordsFoundMessage entities={tableData} />
              </BootstrapTable>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
              <PaginationLinks paginationProps={paginationProps} />
              <div className="ml-lg-auto">
              <SizePerPageDropdownStandalone
                  { ...paginationProps }
                />
                <PaginationTotalStandalone
                  { ...paginationProps }
                />
              </div>
              </div>
              
              </>
          );
        }}
      </PaginationProvider>
    </>
     :<NotFoundPage/>}
    </>
  );
}
