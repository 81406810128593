// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  

} from "../../../../../../_metronic/_partials/controls";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { FormControlLabel, Checkbox} from "@material-ui/core";

export function BankEditForm({
  saveBank,
  banks,
  bank,
  actionsLoading,
  onHide,
  openDeleteBankDialog
}) {

  const duplicateAccount = (bankEntity, {name, account_no}) => {
    if (name && account_no) {
      if(bankEntity.id !== bank.id) {
        if(bankEntity.name.toLowerCase().trim() === name.toLowerCase().trim() && bankEntity.account_no.toLowerCase().trim() === account_no.toLowerCase().trim()) {
          return true;
        }
      }
    }
    return false;
  };

  // Validation schema
  const BankEditSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Name is required")
      ,
    account_no: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Account number is required"), 
    customField: Yup.string().when(["name", "account_no"], {
      is: (name, account_no) => banks.some(b => duplicateAccount(b, {name, account_no})),
      then: Yup.string().required('An account already exists with the given name and number')
    })
  })
  // .test({
  //   test: function(values) {
  //     const account_exists = banks.some(bank => duplicateAccount(bank, values))
  //     if(!account_exists) return true;
  //     return this.createError({
  //       path: "account_no",
  //       message: "An account already exists with the given name and number",
  //     })
  //   }
  // });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={bank}
        validationSchema={BankEditSchema}
        onSubmit={(values) => {
          saveBank(values);
        }}
      >
        {({ handleSubmit,values,isSubmitting,setFieldValue,errors,touched }) => (
          <BlockUi blocking={isSubmitting}>
            <Modal.Body className="overlay  cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Name */}
                  
                  <div className="col-lg-10">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      withFeedbackLabel= {true}
                      required
                    />
                  </div>
                  </div>
                  <div className="form-group row">
                  <div className="col-lg-10">
                    <Field
                      name="account_no"
                      component={Input}
                      placeholder="Account Number"
                      label="Account Number"
                      withFeedbackLabel= {true}
                      required
                    />
                    {errors.customField /*&& touched.account_no*/ && (
											<div className="invalid-feedback d-block">{errors.customField}</div>
										)}
                  </div>
                  </div>
                  <div className="form-group row">
                  {/* Name */}
                  
                  <div className="col-lg-10 checkbox-inline">
                    <label className="pr-3 pt-3">Is non company account? </label>
                    
                    <FormControlLabel
                      checked={values.nonCompany}
                      onChange={() => setFieldValue("nonCompany", !values.nonCompany)}
                      control={<Checkbox style={{color:'#0BB783'}}/>}
                      
                    />
                  </div>
                  </div>
                  {/* Telephone */}
                  
                  
                
              </Form>
            </Modal.Body>
            <Modal.Footer>
            <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate mr-auto"
              >
                Cancel
              </button>
              { values.id != undefined &&<button
                type="button"
                onClick={()=>{openDeleteBankDialog(values.id)}}
                className="btn btn-danger btn-elevate ml-auto"
              >
                Delete
              </button>}

              <> </>
              
            </Modal.Footer>
          </BlockUi>
        )}
      </Formik>
    </>
  );
}
