import React, { useEffect, useMemo, useState } from "react";
import { Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useProformasUIContext } from "../ProformasUIContext";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import NumberFormat from 'react-number-format';
import Moment from "react-moment";
import { SalesTable_1 } from "../../car-sales/SalesTable_1"
import * as proformaActions from '../../../../_redux/proformas/proformasActions';
import * as saleActions from "../../../../_redux/sales/salesActions"
import * as employeeActions from "../../../../_redux/employees/employeesActions"

const dueDate = (profrormaDate, expireDays) => {
    let dueDate = new Date(profrormaDate);
    dueDate.setDate(dueDate.getDate()+parseInt(expireDays))
    let year = dueDate.getFullYear();
    let month = (dueDate.getMonth() + 1) < 10 ? '0'+(dueDate.getMonth() + 1) : (dueDate.getMonth() + 1);
    let day = (dueDate.getDate() < 10) ? '0'+ dueDate.getDate() :  dueDate.getDate()
    return  day + '/'+ month + '/'+ year  ;
}

export const ProformaPrintModal = () => {
    const specsUIContext = useProformasUIContext();
    const specsUIProps = useMemo(() => {
        return {
            id: specsUIContext.selectedId,
            show: specsUIContext.showProformaPrintDialog,
            onHide: specsUIContext.closeProformaPrintDialog,
            openEditProformaDialog: specsUIContext.openEditProformaDialog,
            openContractOfSale:specsUIContext.openProformaConstractOfSale,
            openBankReleaseLetter:specsUIContext.openProformaBankReleaseLetter,
            openContractOfSaleCash:specsUIContext.openProformaCashConstractOfSale,
            openPrintMenu: specsUIContext.openPrintMenuDialog,
            carId: specsUIContext.carId,
            setCurrentProforma: specsUIContext.setCurrentProforma
        };
    }, [specsUIContext]);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(proformaActions.fetchProformaItem(specsUIProps.id, specsUIProps.carId, "fromPrint"))
        dispatch(employeeActions.getAllEmployees())
    }, [dispatch, specsUIProps.id]);

    useEffect(() => {
        specsUIProps?.carId && dispatch(saleActions.fetchSalesInCar(specsUIProps.carId));
    }, [specsUIProps.carId, dispatch]);

    const { actionsLoading, proformaForEdit, proformaItems, clientEntities, employeeEntities, /*saleEntities*/ salesInProforma } = useSelector(
        (state) => ({
            actionsLoading: state.proformas.actionsLoading,
            proformaForEdit: state.proformas.proformaForEdit,
            proformaItems: state.proformas.proformaItems,
            clientEntities: state.client.clientEntities, 
            employeeEntities: state.employees.employeeEntities,
            // saleEntities: state.sales.saleEntities
            salesInProforma: state.sales.salesInProforma
        }),
        shallowEqual
    );

    useEffect(() => {
        // specsUIProps.show && proformaForEdit?.id && dispatch(saleActions.fetchSalesInProforma(proformaForEdit?.id))
        specsUIProps.show && dispatch(saleActions.fetchSalesInProforma(proformaForEdit?.id))
        proformaForEdit?.id && specsUIProps.setCurrentProforma(proformaForEdit)
    }, [proformaForEdit, dispatch])

    const [proTotalAmount, setProTotalAmount] = useState(0);

    // const [selectedTopTab, setSelectedTopTab] = useState("order");
   
    // const setTopTab = _tabName => { 
    //     setSelectedTopTab(_tabName);
    // };

    useEffect(()=>{
        let proamou = 0;
        proformaItems?.map((item)=> { 
            proamou = proamou+parseInt(item.proforma_amount)
        });
        setProTotalAmount(proamou);
    }, [proformaItems]);

    return (
        proformaForEdit ? (
            <Modal
                size="xl"
                show={specsUIProps.show}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                {actionsLoading && <ModalProgressBar />}
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Proforma Invoice</Modal.Title>
                    <Nav className="ml-auto" onClick={() => {
                        // setTopTab('order')
                        specsUIProps.onHide()
                    }}>
                        <Nav.Link className="p-0 text-dark">
                            <span className="nav-icon">
                                <span className="svg-icon svg-icon-2x">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect x="0" y="7" width="16" height="2" rx="1"/>
                                            <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                                        </g>
                                    </g>
                                    </svg>
                                </span>
                            </span>
                        </Nav.Link>
                    </Nav>
                </Modal.Header>
                <Modal.Body>
                    <div className="row flex-column-reverse flex-lg-row">
                        {/* <div className="wizard-nav">
                            <div className="wizard-steps px-6 px-lg-6 pb-sm-2 pb-md-3 pt-0">
                                <div className="wizard-step" data-wizard-type="step" data-wizard-state={selectedTopTab === "order" ? `current` : `pending`} 
                                    onClick={() => setTopTab("order")}
                                >
                                    <div className="wizard-label pb-4 pt-0">
                                    <h3 className="wizard-title text-center">Order</h3>
                                    <div className="wizard-bar"></div>
                                    </div>
                                </div>
                                <div className="wizard-step" data-wizard-type="step" data-wizard-state={selectedTopTab === "payment" ? `current` : `pending`} 
                                    onClick={() => setTopTab("payment")}
                                >
                                    <div className="wizard-label pb-4 pt-0">
                                    <h3 className="wizard-title text-center">Payment</h3>
                                    <div className="wizard-bar"></div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-lg-7">
                            <div className="row justify-content-center py-6 px-8 py-md-6 px-md-0">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between ">
                                        <div className="d-flex flex-column flex-root">
                                            <span className="mb-2" >To : <span className="opacity-70">{proformaForEdit.customer?.name}</span></span>
                                        </div>
                                        <div className="d-flex flex-column ">
                                            <span className=" mb-2 text-right">Date : <span className="opacity-70"><Moment format="DD/MM/YYYY">{proformaForEdit.proforma_date}</Moment></span></span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between pt-3">
                                        <div className="d-flex flex-column  flex-root ">
                                            <span className="mb-2">TIN No : <span className="opacity-70">{proformaForEdit.customer?.tin}</span></span>
                                        </div>
                                        <div className="d-flex flex-column ">
                                            <span className=" mb-2 text-right">Order No : <span className="opacity-70">P {proformaForEdit.id}</span></span>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center py-0 px-8 px-md-0">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="pl-0 font-weight-bold text-muted text-uppercase">Description</th>
                                                    <th className="text-right font-weight-bold text-muted text-uppercase">Qty</th>
                                                    <th className="text-right font-weight-bold text-muted text-uppercase">Proforma Amount</th>
                                                    <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">Total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {proformaItems?.map((items)=> (
                                                    <tr key={items.id} className="">
                                                        <td style={{paddingLeft:0}}>
                                                            {items.productName}
                                                            <br/>
                                                            <>Chassis: {items.chassis} <br/></>
                                                            <>Model Year: {items.modelYear} <br/></>
                                                            <>Engine No: {items.motorNo} <br/></>
                                                            <>Condition: {items.newOrUsed} <br/></>
                                                            <>Color: {items.color} <br/></>
                                                            <>CC: {items.cc} <br/></>
                                                            <>Country of Origin: {items.country} <br/></>
                                                            <br/>
                                                        </td>
                                                        <td className="align-bottom text-right">1</td>
                                                        <td  className="text-right align-bottom">
                                                            <NumberFormat
                                                                value={items.proforma_amount+'.00'}
                                                                className="foo"
                                                                displayType={'text'}
                                                                thousandSeparator={true} 
                                                            />
                                                        </td>
                                                        <td className="align-bottom text-right">
                                                            <NumberFormat
                                                                value={items.proforma_amount+'.00'}
                                                                className="foo"
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                                {proTotalAmount ? 
                                                    <tr className="lastRow">
                                                        <td className='bottomNone'></td>
                                                        <td className='bottomNone'></td>
                                                        <td className="font-weight-boldest text-right bottomNone">Total Amount</td>
                                                        <td className="font-weight-boldest text-right bottomNone">
                                                            <NumberFormat 
                                                                value={proTotalAmount+'.00'}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                    : <tr></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>     
                            <div className="row justify-content-center pt-0 pb-6 px-8 px-md-0">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between">
                                        <p>
                                            <span className="font-weight-boldest text-muted" >TERMS OF SALE</span><br/>
                                            <span> Price includes 15% VAT</span><br/>
                                            <span>Price Validity : {proformaForEdit?.expire && proformaForEdit?.bank_id && proformaForEdit.expire}</span> {proformaForEdit?.expire && proformaForEdit?.bank_id && 'Days'}<br/>
                                            <span>Due Date : {proformaForEdit?.expire && proformaForEdit?.bank_id && dueDate(proformaForEdit.proforma_date, proformaForEdit.expire)} </span>
                                        </p>
                                    </div>
                                </div>
                            </div> 
                            <div className="row justify-content-center pt-0 pb-6 px-8 px-md-0">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between">
                                        <span className="col-md-6" style={{paddingLeft:'0px'}}>
                                            Prepared by : { employeeEntities?.find(e => e.id == proformaForEdit.employee_id)?.name }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-4 flex-column">
                            <div className="card card-custom gutter-b" style={{backgroundColor: '#F5F3F8'}}>
                                <div className="card-body p-1">
                                    {/* <SalesTable_1 saleEntities={saleEntities} list_only={true} /> */}
                                    <SalesTable_1 salesInProforma={salesInProforma} list_only={true} />
                                </div>		
                            </div>
                        </div>
                        {/* <div className="col-lg-4">
                            <SalesTable_1 saleEntities={saleEntities} list_only={true} />
                        </div> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="submit"
                        onClick={async () => {
                            // setTopTab('order')
                            await specsUIProps.onHide()
                            specsUIProps.openEditProformaDialog(proformaForEdit.id)
                        }}
                        className="btn btn-primary btn-elevate "
                    >
                        Edit
                    </button>
                    {specsUIProps.id > 0 &&
                        <>
                            <div className="dropdown dropdown-inline ">
                                <button 
                                    onClick={() => {
                                        specsUIProps.openPrintMenu();
                                    }}
                                    type="button" 
                                    className="btn btn-light-primary"
                                >
                                    <span className="svg-icon svg-icon-md">							
                                        <i className="la la-print"></i>
                                    </span>
                                    Print
                                </button>
                            </div>
                            {/* <button
                                type="submit"
                                onClick={() => {
                                    specsUIProps.openPrintMenu();
                                }}
                                className="btn btn-primary btn-elevate "
                            >
                                Print
                            </button> */}
                            {/* <div className="dropdown dropdown-inline ">
                                <button type="button" className="btn btn-light-primary font-weight-bolder dropdown-toggle btn-elevate  " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="svg-icon svg-icon-md">							
                                        <i className="la la-print"></i>
                                    </span>
                                    Print
                                </button>
                                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right" >
                                    <ul className="navi flex-column navi-hover py-2">
                                        <li className="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">Choose an option:</li>
                                        <li className="navi-item">
                                            <PrintTrigger 
                                                values={proformaForEdit} 
                                                proformaItems={proformaItems} 
                                                proformaItemTotalAmount={proTotalAmount}
                                                header={true}
                                                client={clientEntities}
                                            />
                                        </li>
                                        <li className="navi-item">
                                            <PrintTrigger 
                                                values={proformaForEdit} 
                                                proformaItems={proformaItems} 
                                                proformaItemTotalAmount={proTotalAmount}
                                                header={false}
                                                client={clientEntities}
                                            />
                                        </li>
                                        <li className="navi-item">
                                            <a className="navi-link"
                                            onClick={()=>specsUIProps.openContractOfSale()}><span className="navi-icon">
                                                 <i className="flaticon2-contract"></i>
                                            </span>
                                            <span className="navi-text">Contract Of Sale(Bank)</span></a>
                                            
                                        </li>
                                        <li className="navi-item">
                                            <a className="navi-link"
                                            onClick={()=>specsUIProps.openContractOfSaleCash()}><span className="navi-icon">
                                                <i className="flaticon2-contract"></i>
                                                </span>
                                                <span className="navi-text">Contract Of Sale(Cash)</span></a>
                                            
                                        </li>
                                        <li className="navi-item">
                                            <a className="navi-link"
                                            onClick={()=>specsUIProps.openBankReleaseLetter()}><span className="navi-icon">
                                                <i className="flaticon2-hospital"></i>
                                                </span>
                                                <span className="navi-text">Bank Release Letter</span></a>
                                            
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div> */}
                        </>
                    }
                    <button
                        type="button"
                        onClick={() => {
                            // setTopTab('order')
                            specsUIProps.onHide()
                        }}
                        className="btn btn-light btn-elevate mr-auto "
                    >
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        ) : (<></>)
    );
}
