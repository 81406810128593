import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ProformaMenuModal } from "../../../pages/cars/car-proformas/print-proforma/ProformaMenuModal";
import { ProformaPrintModal } from "../../../pages/cars/car-proformas/print-proforma/ProformaPrintModal";
import { ProformaDeleteSales } from "../../../pages/cars/car-proformas/Proforma-edit-dialog/ProformaDeleteSalesModal";
import { ProformaEditDialog } from "../../../pages/cars/car-proformas/Proforma-edit-dialog/ProformaEditDialog";
import ProformaNotificationType from "./notification-types/ProformaNotificationType";
import NotificationsApi from "./NotificationsApi";

const AlertNotificationsList = ({ setUnseenNotifications }) => {
  const [notifications, setNotifications] = useState({
    loading: true,
    data: { number: -1 },
  });
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const getData = async (sign) => {
    setNotifications({ ...notifications, loading: true });
    const api = new NotificationsApi();
    const data = await api.getNotificationsByUsername(false, "alert", notifications.data.number + sign, 20);
    setNotifications({ loading: false, data });
  };

  useEffect(() => {
    getData(1);
  }, []);

  const handleClearAll = () => {
    const api = new NotificationsApi();
    api.markAllSeen();
    setNotifications({loading: false, data: {content:[], number: -1}})
    setUnseenNotifications(0);
  }

    return (
      <>
        <div className="tab-pane show p-0 active" id="topbar_notifications_notifications" role="tabpanel">
          {notifications.loading ? <div className="d-flex flex-center text-center text-muted min-h-200px">Loading . . .</div> :
           notifications.data.content.length === 0 ? (
            <div className="d-flex flex-center text-center text-muted min-h-200px">
              All caught up!
              <br />
              No new notifications
            </div>
          ) : (
            <div className="scroll pr-7 mr-n7 ps ps--active-y" data-scroll="true" data-height="300" data-mobile-height="200" style={{ height: "300px", overflow: "hidden" }}>
              <div className="navi navi-hover scroll my-4 ps" data-scroll="true" data-height="300" data-mobile-height="200" style={{ height: "300px", overflow: "hidden" }}>
                {notifications.data.content.map((notification) => (
                  <ProformaNotificationType key={notification.id} notification={notification} setNotifications={setNotifications} setUnseenNotifications={setUnseenNotifications} now={notifications.data.now} />
                ))}
                <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
                  <div className="ps__thumb-x" tabindex="0" style={{ left: "0px", width: "0px" }}></div>
                </div>
                <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}>
                  <div className="ps__thumb-y" tabindex="0" style={{ top: "0px", height: "0px" }}></div>
                </div>
              </div>

              <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
                <div className="ps__thumb-x" tabIndex="0" style={{ left: "0px", width: "0px" }}></div>
              </div>
              <div className="ps__rail-y" style={{ top: "0px", right: "0px", height: "300px" }}>
                <div className="ps__thumb-y" tabIndex="0" style={{ top: "0px", height: "203px" }}></div>
              </div>
            </div>
          )}

          <Modal show={showModal} scrollable={true} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>All Notifications</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AllAlertNotification setUnseenNotifications={setUnseenNotifications} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                close
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="d-flex flex-center pt-7 pb-7 ">
            <a href="#" onClick={() => setShowModal(true)} className="btn btn-light-primary font-weight-bold text-center">
              See More
            </a>
            <a href="#" onClick={handleClearAll} className="ml-7 btn btn-warning">
              Clear All 
            </a>
          </div>
        </div>
        <>
          <ProformaPrintModal />
          <ProformaMenuModal />
          <ProformaEditDialog newButton={false} />
          <ProformaDeleteSales />
        </>
      </>
    );
};

const AllAlertNotification = ({ setUnseenNotifications }) => {
  const [notifications, setNotifications] = useState({
    loading: true,
    data: { number: -1 },
  });
  const getData = async (sign) => {
    setNotifications({ ...notifications, loading: true });
    const api = new NotificationsApi();
    const data = await api.getNotificationsByUsername(true, "alert", notifications.data.number + sign, 20);
    setNotifications({ loading: false, data });
  };

  useEffect(() => {
     getData(1);
  }, []);

  if (notifications.loading) return <div className="d-flex flex-center h-100"><div className="spinner"></div></div>;

  if (notifications.data.content.length === 0) return <div className="d-flex flex-center text-center text-muted h-100"> You have no notification! </div>;

  return (
    <>
      <div className="navi navi-hover my-4">
        {notifications.data.content.map((notification) => (
          <ProformaNotificationType key={notification.id} notification={notification} setNotifications={setNotifications} setUnseenNotifications={setUnseenNotifications} shouldDecrement={false} />
        ))}
      </div>

      <div className="d-flex flex-left pt-7">
        {!notifications.data.first && (
          <a href="#" className="btn" onClick={() => getData(-1)}>
            <span class="svg-icon svg-icon-primary svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M11.0879549,18.2771971 L17.8286578,12.3976203 C18.0367595,12.2161036 18.0583109,11.9002555 17.8767943,11.6921539 C17.8622027,11.6754252 17.8465132,11.6596867 17.8298301,11.6450431 L11.0891271,5.72838979 C10.8815919,5.54622572 10.5656782,5.56679309 10.3835141,5.7743283 C10.3034433,5.86555116 10.2592899,5.98278612 10.2592899,6.10416552 L10.2592899,17.9003957 C10.2592899,18.1765381 10.4831475,18.4003957 10.7592899,18.4003957 C10.8801329,18.4003957 10.9968872,18.3566309 11.0879549,18.2771971 Z"
                    fill="#000000"
                    transform="translate(14.129645, 12.002277) scale(-1, 1) translate(-14.129645, -12.002277) "
                  />
                  <rect fill="#000000" opacity="0.3" x="6" y="6" width="3" height="12" rx="1" />
                </g>
              </svg>
            </span>
          </a>
        )}
        {!notifications.data.last && (
          <a href="#" className="btn" onClick={() => getData(1)}>
            <span class="svg-icon svg-icon-primary svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M6.82866499,18.2771971 L13.5693679,12.3976203 C13.7774696,12.2161036 13.7990211,11.9002555 13.6175044,11.6921539 C13.6029128,11.6754252 13.5872233,11.6596867 13.5705402,11.6450431 L6.82983723,5.72838979 C6.62230202,5.54622572 6.30638833,5.56679309 6.12422426,5.7743283 C6.04415337,5.86555116 6,5.98278612 6,6.10416552 L6,17.9003957 C6,18.1765381 6.22385763,18.4003957 6.5,18.4003957 C6.62084305,18.4003957 6.73759731,18.3566309 6.82866499,18.2771971 Z"
                    fill="#000000"
                  />
                  <rect fill="#000000" opacity="0.3" transform="translate(16.500000, 12.000000) scale(-1, 1) translate(-16.500000, -12.000000) " x="15" y="6" width="3" height="12" rx="1" />
                </g>
              </svg>
            </span>
          </a>
        )}
      </div>
    </>
  );
};

export default AlertNotificationsList;
