import React, { useMemo } from "react";
import { isEqual } from "lodash";
import { useProformasUIContext } from "./ProformasUIContext";
import { shallowEqual, useSelector } from "react-redux";

const prepareFilter = (queryParams, values) => {
  const { searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.value = searchText;
  if (searchText) {
    filter.name = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function ProformasFilter(newButton) {
  // Specs UI Context
  const specsUIContext = useProformasUIContext();
  const specsUIProps = useMemo(() => {
    return {
      openNewProformaDialog: specsUIContext.openNewProformaDialog,
      setQueryParams: specsUIContext.setQueryParams,
      queryParams: specsUIContext.queryParams,
      carId:specsUIContext.carId
    };
  }, [specsUIContext]);

  const { currentState } = useSelector(
		(state) => ({ currentState: state }),
		shallowEqual
	);

  const {carForEdit} = currentState.cars

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(specsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, specsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      specsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <div className="form-filtration">
        <div className="row align-items-center">
          <div className="col-md-2 margin-bottom-10-mobile">
            {/* <Formik
              initialValues={{
                searchText: "",
              }}
              onSubmit={(values) => {
                applyFilter(values);
              }}
            >
              {({
                values,
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="searchText"
                      placeholder="Search"
                      onBlur={handleBlur}
                      value={values.searchText}
                      onChange={(e) => {
                        setFieldValue("searchText", e.target.value);
                        handleSubmit();
                      }}
                    />
                    <small className="form-text text-muted">
                      <b>Search</b> in all fields
                    </small>
                  </div>
                </form>
              )}
            </Formik> */}
          </div>
         <div className="col-md-7 margin-bottom-10-mobile"></div>
          <div className="col-md-3 text-right margin-bottom-10-mobile mb-3">
            {carForEdit!=null && Object.keys(carForEdit).length === 0 ? <></> : <button
              type="button"
              className={`btn ${newButton?.newButton!=0 ? "btn-primary" : "btn-secondary"}`}
              disabled = {newButton?.newButton!=0 ? '':'disabled'}
              onClick={() => specsUIProps.openNewProformaDialog()}
            >
            New Proforma
          </button>}
       </div>
        </div>
      </div>
    </>
  );
}
