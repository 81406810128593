import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/banks/banksActions";
import {BankEditDialogHeader} from "./BankEditDialogHeader";
import { BankEditForm } from "./BankEditForm";
import { useBanksUIContext } from "../BanksUIContext";

export function BankEditDialog({ id, show, onHide }) {
  // Banks UI Context
  const banksUIContext = useBanksUIContext();
  const banksUIProps = useMemo(() => {
    return {
      initBank: banksUIContext.initBank,
      openDeleteBankDialog: banksUIContext.openDeleteBankDialog,
    };
  }, [banksUIContext]);

  // Banks Redux state
  const dispatch = useDispatch();
  const { actionsLoading, bankForEdit ,banks} = useSelector(
    (state) => ({
      actionsLoading: state.banks.bankLoading,
      bankForEdit: state.banks.bankForEdit,
      banks:state.banks.bankEntities
    }),
    shallowEqual
  );
  const bankForValidation = bankForEdit==undefined ?  {...banksUIProps.initBank,banks}: {...bankForEdit,banks}
  useEffect(() => {
    // server call for getting Bank by id
    dispatch(actions.fetchBank(id));
  }, [id, dispatch]);

  // server request for saving bank
  const saveBank = (bank) => {
    if (!id) {
      // server request for creating bank
      dispatch(actions.createBank(bank)).then(() => onHide());
    } else {
      // server request for updating bank
      dispatch(actions.updateBank(bank)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="md"
      show={show}
      // onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <BankEditDialogHeader id={id} onHide={onHide} />
      <BankEditForm
        saveBank={saveBank}
        actionsLoading={actionsLoading}
        banks={banks}
        bank={bankForValidation}
        onHide={onHide}
        openDeleteBankDialog={banksUIProps.openDeleteBankDialog}
      />
    </Modal>
  );
}
