import React, { useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { SubscriptionsFilter } from "./subscriptions-filter/SubscriptionsFilter";
import  SubscriptionsTable  from "./subscriptions-table/SubscriptionsTable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function SubscriptionsCard() {
  const exportCSV = useRef();
  const exportPdf = useRef();
  const handlerCsv = () => {
    exportCSV.current.click();
  };
  const handlerPdf = () => {
    exportPdf.current.click();
  };

  const [collapsed, setCollapsed] = useState(true)

  return (
    <div className="content d-flex flex-column-fluid" id="kt_content">
        <div className="container d-flex flex-column flex-lg-row">
        <div id="kt_profile_aside">
          <div className="d-flex flex-row card card-custom gutter-b">
            <SubscriptionsFilter collapsed={collapsed} setCollapsed={setCollapsed} />
            <div className="p-5 py-7 bg-white d-none d-lg-block">
              <OverlayTrigger
                overlay={
                  <Tooltip id="spec-edit-tooltip" className="m-1">{collapsed ? 'Expand' : 'Collapse'}</Tooltip>
                }
                placement={collapsed ? 'left-end' : 'right-end'}
              >
                <a onClick={()=>{setCollapsed(!collapsed)}} style={{cursor: 'pointer'}}>
                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                    {collapsed ? (
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24"/>
                            <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fillRule="nonzero"/>
                            <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "/>
                        </g>
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24"/>
                            <path d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z" fill="#000000" fillRule="nonzero" transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) "/>
                            <path d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) "/>
                        </g>
                      </svg>
                    )}
                  </span>
                </a>
              </OverlayTrigger>
            </div>
          </div>
        </div>
            <div className="flex-row-fluid ml-lg-8">
                <Card>
                    <CardHeader title="Subscription List">
                        <CardHeaderToolbar>
                        </CardHeaderToolbar>
                        <div className="d-flex justify-content-end">
                        
                         <button className="bg-transparent border-0 px-0 mr-3 text-primary"  onClick={handlerCsv}>
                            <img src="/export-excel.png"/>
                        </button>
                        <button className="react-bs-table-csv-btn btn btn-default bg-transparent border-0 px-0 text-danger"  onClick={handlerPdf}>
                            <img src="/export-pdf.png"/>
                        </button>
                         </div>
                    </CardHeader>
                    <CardBody>
                        <SubscriptionsTable ref={{
                                          ref1: exportCSV,
                                          ref2: exportPdf
                                          }} />
                    </CardBody>
                </Card>
            </div>
        </div>
    </div>
  );
}
