export const CarModelStatusCssClasses = ["danger", "success", "info", ""];
export const CarModelStatusTitles = ["Suspended", "Active", "Pending", ""];
export const CarModelTypeCssClasses = ["success", "primary", ""];
export const CarModelTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 }, 
  { text: "25", value: 10 }, 
  { text: "50", value: 50 }, 
  { text: "100", value: 100 }
];
export const initialFilter = {
  filter: {
    make: undefined,
    model: undefined,
    body: undefined
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};
