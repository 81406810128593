// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, {useEffect} from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../../_metronic/_partials/controls";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import * as settingActions from "../../../../_redux/settings/settingsActions"
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { LocationTypes } from "../LocationsUIHelpers";

// Validation schema
const LocationEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Name is required")
    .test(
      'duplicateName',
      'Duplicate Location Name',
      function(value){
        return this.parent.locations?.filter(
          (item)=>{
            return item.name == value && item.id != this.parent.id
          }
        ).length == 0
      }
    ),
  location_type_id: Yup.string().required("Location type is required"),
});

export function LocationEditForm({
  saveLocation,
  location,
  actionsLoading,
  onHide,
  openDeleteLocationDialog
}) {

  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(settingActions.fetchLocationTypes())
  }, [dispatch])

  const { locationTypeEntities } = useSelector(
    (state) => ({ locationTypeEntities: state.setting.locationTypeEntities }),
    shallowEqual
  );

  const LOCAL_LOCATION_ID = locationTypeEntities?.find(l => l.value === LocationTypes?.find(lt => lt.value==='Local')?.name)?.id;
  const ABROAD_LOCATION_ID = locationTypeEntities?.find(l => l.value === LocationTypes?.find(lt => lt.value==='Abroad')?.name)?.id;

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{...location, location_type_id: location.location_type_id ? location.location_type_id : LOCAL_LOCATION_ID}}
        validationSchema={LocationEditSchema}
        onSubmit={(values) => {
          saveLocation(values);
        }}
      >
        {({ handleSubmit, values, isSubmitting, setFieldValue, errors }) => (
          <BlockUi blocking={isSubmitting}>
            <Modal.Body className="overlay  cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-10">
                    <label>Location Type <span className="text-danger">*</span></label>
                    <div className="radio-inline">
                      {/* <div className="custom-control custom-radio custom-control-inline pl-0 pr-10">
                        <label className="mb-0">
                          <Field type="radio" name="location_type_id" value={values.location_type_id} onChange={()=>{setFieldValue('location_type_id', LOCAL_LOCATION_ID, true)}} checked={values.location_type_id==LOCAL_LOCATION_ID ? "checked" : ""} className="mr-1" />
                          Local
                        </label>
                      </div> */}
                      <label className="radio mr-7">
                        <input type="radio" name="location_type_id" value={values.location_type_id} 
                          onChange={()=>{setFieldValue('location_type_id', LOCAL_LOCATION_ID, true)}}
                          checked={values.location_type_id==LOCAL_LOCATION_ID ? "checked" : ""} 
                        />
                        <span></span> {LocationTypes?.find(l => l.name === 'Location_0')?.value}
                      </label>
                      <label className="radio">
                        <input type="radio" name="location_type_id" value={values.location_type_id} 
                          onChange={()=>{setFieldValue('location_type_id', ABROAD_LOCATION_ID, true)}}
                          checked={values.location_type_id==ABROAD_LOCATION_ID ? "checked" : ""} 
                        />
                        <span></span> {LocationTypes?.find(l => l.name === 'Location_1')?.value}
                      </label>
                      {errors?.location_type_id && <div className="invalid-feedback d-block">{errors?.location_type_id}</div>}
                    </div> 
                  </div>   
                </div>
                <div className="form-group row">   
                  <div className="col-lg-10">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      withFeedbackLabel= {true}
                      required
                    />
                  </div>
                </div>  
                <div className="form-group row">
                  <div className="col-lg-10">
                    <Field
                      name="address"
                      component={Input}
                      placeholder="Address"
                      label="Address"
                      withFeedbackLabel= {false}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
            <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate mr-auto"
              >
                Cancel
              </button>
              { values.id != undefined &&<button
                type="button"
                onClick={()=>{openDeleteLocationDialog(values.id)}}
                className="btn btn-danger btn-elevate ml-auto"
              >
                Delete
              </button>}

              <> </>
              
            </Modal.Footer>
          </BlockUi>
        )}
      </Formik>
    </>
  );
}
