import axios from "axios";

export const URL = process.env.REACT_APP_AUTO_DEALER_API;

export function getPaymentTypes(){
  return axios.get(URL+'client/paymentMethodTypes')
}
export function getSubscriptionTypes(){
  return axios.get(URL+'client/subscriptionPlanTypes')
}
