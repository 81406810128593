import React, { PureComponent } from 'react'
import { Document
  , Packer
  , Paragraph
  , TextRun
  , AlignmentType
  , UnderlineType

   } from "docx";
import { saveAs } from "file-saver";
import CurrencyToWords from '../../../../shared/CurrencyToWords/currencyToWords';
import axios from 'axios';
import moment from 'moment';
export default class BankReleaseLetterWord extends PureComponent {
    constructor(props){
        super(props);
        this.state = {docCount:1}
        this.generate = this.generate.bind(this);
    }

    // async getDocNumber(){
    //     var url =  process.env.REACT_APP_AUTO_DEALER_API+"logger/proformaLoggerDoc";
    //     const token = localStorage.currentUser
				
    //         let response = await fetch(url,{
	// 				headers: {Authorization: token}
	// 			  })
	// 			response = await response.json()
    //             if(response?.value!=undefined && response?.value < 9999)
    //              this.setState({docCount:response.data?.value+1})
    //         //  axios.get(url).then((response)=>{
    //         //     if(response.data?.value!=undefined && response.data?.value < 9999)
    //         //     this.setState({docCount:response.data?.value+1})})

    //     return this.state.docCount > 1000 ? this.state.docCount : this.state.docCount > 100 ? '0'+this.state.docCount : this.state.docCount > 10 ? '00'+this.state.docCount : '000'+this.state.docCount;
         
    // }

    // async componentWillMount () {
    //     var url =  process.env.REACT_APP_AUTO_DEALER_API+"logger/proformaLoggerDoc";
    //     axios.get(url).then((response)=>{
    //         if(response.data?.value!=undefined && response.data?.value < 9999)
    //         this.setState({docCount:response.data?.value+1})
    //       })
    // }


    generate (){
        //let docText = this.getDocNumber();
        const doc = new Document({
          creator: "Betselot",
          title: "Bank Release Letter",
          description: "Bank Release Letter docx",
          
          styles: {
            default: {
                heading6: {
                    run: {
                        size: 24,
                        bold: true,
                        font:"Geez Able",
                    },
                    paragraph: {
                        spacing: {
                            after: 120,
                        },
                    },
                },
                heading2: {
                    run: {
                        size: 44,
                        bold: true,
                        font:"Geez Able",
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 120,
                        },
                    },
                },
                listParagraph: {
                    run: {
                        color: "#FF0000",
                    },
                },
            },
            paragraphStyles: [
                {
                    id: "aside",
                    name: "Aside",
                    basedOn: "Normal",
                    next: "Normal",
                    run: {
                        size:24,
                        font:"Geez Able",
                        color: "000000",
                    },
                    paragraph: {
                        // indent: {
                        //     left: convertInchesToTwip(0.5),
                        // },
                        spacing: { line: 400, before: 20 * 72 * 0.1, after: 20 * 72 * 0.1 },
                    },
                },
                
            ],
        },
            sections: [{
                properties: {},
                children: [
                    new Paragraph({
                        //text: "ቀን "+localStorage.date,
                        //style: "aside",
                        children:[
                            new TextRun({
                                text:"ቀን፡ "+localStorage.date,
                                break:1,
                                size:24,
                                font:"Geez Able",
                                //color: "000000",
                            }),
                            new TextRun({
                                text:"ቁጥር፡ "+localStorage.date.replaceAll("/","").substring(0,4)+localStorage.date.replaceAll("/","").substring(localStorage.date.replaceAll("/","").length - 1) + '/',
                                break:1,
                                size:24,
                                font:"Geez Able",
                            }),
                            new TextRun({
                                text:(new Date).getMinutes() <= 9 ? (new Date).getHours()+'0'+(new Date).getMinutes() : (new Date).getHours()+''+(new Date).getMinutes(),
                                size:24,
                                font:"Geez Able",
                            })
                        ],
                    alignment:AlignmentType.RIGHT,
                    spacing: {
                      before: 1900,
                      after: 100,
                  },
                  
                  }),
                  new Paragraph({
                    //text: "ቀን "+localStorage.date,
                    style: "aside",
                    children:[
                        new TextRun({
                            text:"ለ "+this.props.content?.banks?.name,
                            break:1,
                        }),
                        new TextRun({
                            text:"አዲስ አበባ",
                            break:1,
                        })
                    ],
                    alignment:AlignmentType.LEFT,
                    spacing: {
                    //before: 2350,
                    after: 200,
                },
                }),
                  new Paragraph({
                    //text: "የተሽከርካሪ ሽያጭ ውል ስምምነት",
                    children:[
                        new TextRun({
                            text:"ጉዳዩ:- ",
                            bold:true
                        }),
                        new TextRun({
                            text:" ክፍያን በተመለከተ",
                            underline: {
                                type: UnderlineType.SINGLE,
                            }
                        })
                    ],
                    alignment:AlignmentType.LEFT,
                    style: "aside",
                    spacing: {
                      after: 200,
                  },
                  }),
                  new Paragraph({
                    text: "የባንካችሁ ደንበኛ የሆኑት "+this.props.content?.customerName+" ከድርጅታችን "+this.props.content?.companyName+" አስመጪ "+ this.props.content?.cars?.productName +" ሞዴል  "+this.props.content?.cars?.modelYear+" የሻንሲ ቁጥር  "+this.props.content?.cars?.chassis+" የሆነውን ተሽከርካሪ ለመግዛት በተስማሙት መሰረት በደብዳቤ የጠየቃችሁንን መስፈርቶች አሟልተን የጨረስን በመሆኑ የሽያጭ ክፍያ ብር "+this.props.content?.priceFmt+".00 ("+CurrencyToWords(this.props.content?.price,'ብር')+") በድርጅታችን ስም በሚገኘው "+this.props.content?.banks?.name+" ቁጥር "+this.props.content?.banks?.account_no+" ገቢ እንዲደረግልን እየጠየቅን ክፍያው እንደተፈጸመ ተሽከርካሪውን የምናስረክባቸው መሆኑን እገልጻለን።" ,
                    
                    style: "aside",
                    alignment:AlignmentType.JUSTIFIED,
                    spacing: {
                      after: 350,
                  },
                  }),
                 
                  new Paragraph({
                    text: "ከምስጋና ጋር",
                    alignment:AlignmentType.RIGHT,
                    style:"aside",
                    spacing: {
                      after: 1000,
                  },
                  }),
                  
                  new Paragraph({
                    //text: "ዮፍታሄ ፍስሃ",
                    children:[
                        new TextRun({
                            text:this.props.content?.supplierName,
                            break:1,
                        }),
                        new TextRun({
                            text:"ዋና ስራ አስኪያጅ",
                            break:1,
                        })
                    ],
                    alignment:AlignmentType.RIGHT,
                    style: "aside",
                    spacing: {
                      after: 1000,
                  },
                  }),
                ],
            }],
        });
          Packer.toBlob(doc).then(blob => {
            //console.log(blob);
            saveAs(blob, this.props.content?.customerName+" (Bank Release Letter).docx");
            //console.log("Document created successfully");
            var body = {
                id:0,
                name:"Bank Release Letter",
                date:moment(),
                value:this.state.docCount,
                type:"Docx",
                param:JSON.stringify(this.props.content)
            }
            var url =  process.env.REACT_APP_AUTO_DEALER_API+"logger/proformaLoggerDoc";
            axios.post(url, body ).then((response)=>{
                if(response.data?.value!=undefined && response.data?.value < 9999){
                this.setState({docCount:response.data?.value+1})}
                else if (response.data?.value >= 9999) {
                    this.setState({docCount:1})
                }
              });
            
          });
    }



  render() {
    return (
        <button onClick={this.generate} hidden id="docxButtonBRL">Generate docx!</button>
    )
  }
}
