import React, { useMemo } from "react";
import { InspectionsFilter } from "./InspectionsFilter";
import { InspectionsTable } from "./InspectionsTable";
import { InspectionsLoadingDialog } from "./InspectionsLoadingDialog";
import { InspectionsDeleteDialog } from "./InspectionsDeleteDialog";
import { InspectionDeleteDialog } from "./InspectionDeleteDialog";
import { InspectionsFetchDialog } from "./InspectionsFetchDialog";
import { InspectionsGrouping } from "./InspectionsGrouping";
import { InspectionEditDialog } from "./inspection-edit-dialog/InspectionEditDialog";
import { useInspectionsUIContext } from "./InspectionsUIContext";

export function Inspections({ inspectionsTotalCount, inspectionEntities, listLoading}) {
  // Inspections UI Context
  const specsUIContext = useInspectionsUIContext();
  const specsUIProps = useMemo(() => {
    return { ids: specsUIContext.ids };
  }, [specsUIContext]);

  return (
    <>
      <InspectionsLoadingDialog />
      <InspectionEditDialog />
      <InspectionDeleteDialog />
      <InspectionsDeleteDialog />
      <InspectionsFetchDialog />
      <div className="form margin-b-30">
        <InspectionsFilter  />
        {specsUIProps.ids.length > 0 && (
          <>
            <InspectionsGrouping />
            <br />
          </>
        )}
      </div>
      <InspectionsTable inspectionsTotalCount={inspectionsTotalCount} inspectionEntities={inspectionEntities} listLoading={listLoading} />
    </>
  );
}
