import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import $ from 'jquery';
import { AdminSubscription } from './AdminSubscription';
import { TenantsUIProvider } from '../admin-pages/tenants/TenantsUIContext';
const HomeAdmin = () => {
	const { currentState } = useSelector(
		(state) => ({ currentState: state }),
		shallowEqual
	);
	const clientPageClick = ()=>{
		$("#kt_header_menu > ul > li:nth-child(2) > a > span").trigger('click');
	}
	
	const adminUserPageClick = ()=>{
		$("#kt_header_menu > ul > li:nth-child(3) > a > span").trigger('click');
	}
	
	const LogsPageClick = ()=>{
		$("#kt_header_menu > ul > li:nth-child(4) > a > span").trigger('click');
	}
    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
			<div className="d-flex flex-column-fluid">
							
							<div className="container">
								<div className="row mt-0 mt-lg-3">	
								<div className="col-xl-4">
									<div className="card card-custom card-stretch gutter-b" >			
								 <div className="scrollable">
											<div className="card-body pt-0 notificatonContainer" style={{ maxHeight:'750px', cursor:"pointer"}}>
													<TenantsUIProvider tenantsUIEvents={{}}>
																<AdminSubscription subTypeId="Basic"></AdminSubscription>
													</TenantsUIProvider>	
												</div>
											</div>   
											
										</div>
										
									</div>	
									<div className="col-xl-4">
									<div className="card card-custom card-stretch gutter-b" >			
								 	<div className="scrollable">
											<div className="card-body pt-0 notificatonContainer" style={{ maxHeight:'750px', cursor:"pointer"}}>
											<TenantsUIProvider tenantsUIEvents={{}}>
																<AdminSubscription subTypeId="Trial"></AdminSubscription>
																</TenantsUIProvider>
														
												</div>
											</div>   
											
										</div>
										
									</div>
									<div className="col-xl-4">
										<div className="card card-custom gutter-b card-stretch bg-primary">
											<div className="card-body d-flex p-0">
												<div className="flex-grow-1 bg-successs p-12 pb-40 card-rounded flex-grow-1 bgi-no-repeat" style={{backgroundPosition: "right bottom", backgroundSize: "55% auto", backgroundImage: "url(media/svg/humans/custom-3.svg)"}}>
													<h3 className="text-inverse-info pb-5 font-weight-bolder">Clients</h3>
													<p className="text-inverse-info pb-5 font-size-h6">Manage clients subscriptions and 
													<br/>other clients settings.</p>
													<a onClick={clientPageClick} className="btn btn-success font-weight-bold py-2 px-6">GO</a>
												</div>
											</div>
										</div>
									</div>	
									
							</div>
						</div>
						</div>
					</div>
        </>
    )
}

export default HomeAdmin
