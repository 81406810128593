import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/accounts/accountsActions";
import { TransactionEditDialogHeader } from "./TransactionEditDialogHeader";
import { TransactionEditForm } from "./TransactionEditForm";
import { useAccountsUIContext } from "../AccountsUIContext";
import moment from "moment";
import { initTransaction } from "../AccountUIHelpers";

export function TransactionEditDialog({ account_id, id, show, onHide }) {
  // Transaction UI Context
  const accountsUIContext = useAccountsUIContext();
  const accountsUIProps = useMemo(() => {
    return {
      initTransaction: accountsUIContext.initTransaction,
      openDeleteTransactionDialog: accountsUIContext.deleteTransactionButtonClick,
    };
  }, [accountsUIContext]);

  // Transaction Redux state
  const dispatch = useDispatch();
  const { actionsLoading, transactionForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.accounts.transactionLoading,
      transactionForEdit: state.accounts.transactionForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Batch by id
    dispatch(actions.fetchTransaction(id));
  }, [id, dispatch]);

  const hideModal = () => {
    dispatch(actions.clearOption('transaction'));
    onHide();
  }

  // server request for saving transaction
  const saveTransaction = (transaction) => {
    transaction.date = moment(transaction.date).format('YYYY-MM-DD')
    if (!id) {
      // server request for creating transaction
      // dispatch(actions.createTransaction(transaction)).then(() => onHide());
      dispatch(actions.createTransaction(transaction)).then(() => {
        dispatch(actions.fetchAccount(account_id)).then(() => {
          dispatch(actions.clearClosedTransaction());
          onHide();
        });
      });
    } else {
      // server request for updating transaction
      // dispatch(actions.updateTransaction(transaction)).then(() => hideModal());
      dispatch(actions.updateTransaction(transaction)).then(() => {
        dispatch(actions.fetchAccount(account_id)).then(() => hideModal());
      });
    }
    // dispatch(actions.fetchAccount(account_id));
  };

  return (
    <Modal
      size="md"
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <TransactionEditDialogHeader id={id} onHide={hideModal} />
      <TransactionEditForm
        account_id = {account_id}
        saveTransaction={saveTransaction}
        actionsLoading={actionsLoading}
        transaction={id ? transactionForEdit : initTransaction}
        onHide={hideModal}
        openDeleteTransactionDialog={accountsUIProps.openDeleteTransactionDialog}
      />
    </Modal>
  );
}
