import React, { useLayoutEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom"
import { shallowEqual, useSelector } from "react-redux";
import { authenticationService } from '../../_services/index';
import '../../../../assets/css/pages/login/login-2.css'
import '../../../../assets/plugins/global/plugins.bundle.css'
import '../../../../assets/plugins/custom/prismjs/prismjs.bundle.css'
import '../../../../assets/css/style.bundle.css'
import SendOTPForm from '../SendOTPForm/SendOTPForm';



const FindUserForm = () => {

	const historyUSE = useHistory()
	useLayoutEffect(() => {
		if (authenticationService.currentUserValue) {
			historyUSE.push('/dashboard');
		} else {
			if (!(window.location.href).indexOf('/login'))
				historyUSE.push('/login');
		}
	}, []);

	const { clientRegistered, clientVerified } = useSelector(
		(state) => ({
			clientRegistered: state.client.clientRegistered,
			clientVerified: state.client.clientVerified
		}),
		shallowEqual
	);

	const [clientUsername, setClientUsername] = useState('');
	const [clientEmail, setClientEmail] = useState('');


	const forgotPasswordSchema = Yup.object().shape({
		username: Yup.string().min(5).nullable().required('Username is required')
			.matches(
				/^[^@]{3,}@[^@]{3,}$/,
				"Username incorrect. Please use the format `name@company`"
			)
			.min(7,"Username must be atleast 7 characters long")
			
	
	})

	return (

			<Formik
				enableReinitialize={true}
				initialValues={{
					username: clientVerified || '',
				}}
				
				validationSchema={forgotPasswordSchema}
				onSubmit={({ username }, {setStatus, setSubmitting }) => {
					setStatus();
					authenticationService.findUser(username)
					.then(
							response => {
							setSubmitting(false);
							setClientEmail(response.response.data.hiddenEmail);
							setClientUsername(response.response.data.username);
						},
						error => {
							setSubmitting(false);
							setStatus(error);
						}
					);
					
				
				}}

				render={({ errors, status, touched, isSubmitting }) => (
					
					<>
					{clientUsername === '' ?
			
					<Form>
					<div className="form-group">
						<label className="font-size-h6 font-weight-bolder text-dark">User Name</label>
						<Field name="username" className={'form-control form-control-solid h-auto py-7 px-6 rounded-lg' + (errors.username && touched.username ? ' is-invalid' : '')} type="text" placeholder="name@company" autoComplete="off" />
						<ErrorMessage name="username" component="div" className="invalid-feedback" />
					</div>
					<div className="text-center pt-2">
						<button type="submit" disabled={isSubmitting} className={isSubmitting ? "btn btn-primary spinner spinner-white spinner-right btn-lg" : "btn btn-primary btn-lg"}>
							Find Account
						</button>

					</div>
					{status &&
						<div className={'alert alert-danger'}>{status}</div>
					}
					</Form>
						:

						<SendOTPForm username={clientUsername}  email={clientEmail}/>

					}
					</>
							
				)}
			/>



	)
}

export default FindUserForm;