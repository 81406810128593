export default function TableDataRowFormatter(data){
    if(data != undefined &&data!=null && data.length > 0) {
        var objectTest = [];
        for(var i=0;i<data.length;i++) {
            var att = {}
            if(data[i].count > 1) {  
                att = {
                    'car':{'id':data[i].id, 'category':data[i].category},
                    'detail':{
                        'year': data[i].year, 
                        'color': data[i].color,
                        'batch': data[i].batch, 
                        'quantity': data[i].quantity,
                        'value': data[i].value,
                        'location': data[i].location,
                    },
                    'span':data[i].count
                } 
                objectTest.push(att)
                att = {}
                for(var j=1;j<=data[i].count;j++) {
                    if(j==data[i].count) {
                        att = {
                            'car': {'id': data[i].id, 'category': "Total"},
                            'detail':{
                                'year': "", 
                                'color': "",
                                'batch': "", 
                                'quantity': data[i].totalCategoryQuantity,
                                'value': data[i].totalCategoryValue,
                                'location': "",
                            } ,
                            'span':-2
                        } 
                    }
                    else {
                        att = {
                            'detail':{
                                'year': data[i+j].year, 
                                'color': data[i+j].color,
                                'batch': data[i+j].batch, 
                                'quantity': data[i+j].quantity,
                                'value': data[i+j].value,
                                'location': data[i+j].location,
                            } ,
                            'span':0
                        } 
                    }
                    objectTest.push(att)
                    att = {}
                }
                i=i+data[i].count-1
            }
            else {
                att = {
                    'car':{'id':data[i].id, 'category':data[i].category},
                    'detail':{
                        'year': data[i].year, 
                        'color': data[i].color,
                        'batch': data[i].batch, 
                        'quantity': data[i].quantity,
                        'value': data[i].value,
                        'location': data[i].location,
                    },
                    'span':data[i].category === 'Total' ? -2 : -1
                }
                objectTest.push(att)
            } 
        }
        return objectTest;
    }
}
