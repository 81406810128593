import axios from "axios";

export const URL = process.env.REACT_APP_AUTO_DEALER_API+'carmodels' //AUTO_DEALER_API+'product';

// CREATE =>  POST: add a new carModel to the server
export function createCarModel(carModel) {
  return axios.post(URL, carModel, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
}

// READ
export function getAllCarModels() {
  return axios.get(URL);
}

export function getCarModelById(carModelId) {
  return axios.get(`${URL}/${carModelId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
// export function findCarModels() {
//   return axios.get(`${URL}`);
// }

export function findCarModels(queryParams={}) {
  return axios.post(`${URL}/find`, {...queryParams});
}

// UPDATE => PUT: update the carModel on the server
export function updateCarModel(carModel) {
  // return axios.put(`${URL}/${carModel.id}`, { carModel });
  return axios.put(`${URL}/${carModel.get('id')}`, carModel, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
}

// UPDATE Status
export function updateStatusForCarModels(ids, status) {
  return axios.post(`${URL}/updateStatusForCarModels`, {
    ids,
    status
  });
}

// Check if carmodel is used by product
export function carModelIsUsed(carModelId) {
  return axios.get(`${URL}/${carModelId}/delete_validate`);
}

// DELETE => delete the carModel from the server
export function deleteCarModel(carModelId) {
  return axios.delete(`${URL}/${carModelId}`);
}

// DELETE CarModels by ids
export function deleteCarModels(ids) {
  return axios.post(`${URL}/deleteCarModels`, { ids });
}

// GET CarModel Picture by id
export function getCarModelImage(carModelId) {
  return axios.get(`${URL}/${carModelId}/image`, {
    responseType: 'blob',
    // headers: {
    //   "Accept": "image/*",
    // }
  });
}
