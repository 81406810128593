import axios from "axios";

export const SPECIFICATIONS_URL = "api/suppliers";
export const URL = "http://localhost:8080/api/suppliers";
export const SUPPLIER_URL = process.env.REACT_APP_AUTO_DEALER_API+'suppliers';

export function getAllSuppliers(){
  return axios.get(SUPPLIER_URL)
}
// CREATE =>  POST: add a new suppliers to the server
export function createSupplier(supplier) {
  return axios.post(SUPPLIER_URL,  supplier );
}

// READ
// Server should return filtered suppliers by supplierId
export function getAllProductSuppliersBySupplierId(supplierId) {
  return axios.get(`${SUPPLIER_URL}?supplierId=${supplierId}`);
}

export function getSupplierById(supplierId) {
  return axios.get(`${SUPPLIER_URL}/${supplierId}`);
}

// Server should return sorted/filtered suppliers and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/suppliersfind/{supplierId}'. Should be 'api/suppliers/find/{supplierId}'!!!
export function findSuppliers(queryParams, supplierId) {
  return axios.post(`${SUPPLIER_URL}find/${supplierId}`, { queryParams });
}

// UPDATE => PUT: update the supplier
export function updateSupplier(supplier) {
  return axios.put(`${SUPPLIER_URL}/${supplier.id}`, 
    supplier
  );
}

// DELETE => delete the supplier
export function deleteSupplier(supplierId) {
  return axios.delete(`${SUPPLIER_URL}/${supplierId}`);
}

// DELETE suppliers by ids
export function deleteSuppliers(ids) {
  return axios.post(`${SUPPLIER_URL}/deleteSuppliers`, { ids });
}

export function getDeleteValidationId(id){
  return axios.get(`${SUPPLIER_URL}/${id}/delete_validate`);
}

// GET: update the supplier
export function getMainSupplier() {
  return axios.get(`${SUPPLIER_URL}/mainSupplier`);
}
