export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "1", value: 1 },
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10  },
];
export const initialFilter = {
  filter: {
    value: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "name",
  pageNumber: 1,
  pageSize: 5,
};

export const SALES_DICTIONARY = [
  { id: 0, name: "Seats" },
  { id: 1, name: "Fuel Type" },
  { id: 2, name: "Stock" },
  { id: 3, name: "Door count" },
  { id: 4, name: "Engine" },
  { id: 5, name: "Transmission" },
  { id: 6, name: "Drivetrain" },
  { id: 7, name: "Combined MPG" },
  { id: 8, name: "Warranty" },
  { id: 9, name: "Wheels" },
];

export const PaymentTypes = [{id: 1, name: 'Deposit'}, {id: 3, name: 'Refund'}];

export const ProformaStatusTitles = [{id: 1, name: "Active"}, {id: 2, name: "Expired"}, {id: 3, name: "Processed"}, {id: 4, name: "Cancelled"}];

export const PaymentTypeCssClasses = {1: "success", 2: "info", 3: "danger"};

export const initProforma = {
  id: 0,
  customer: null,
  status_id: '',
  proforma_date: "",
  expire: 0,
  proformaItems:[{
    sales_amount: 0, 
    invoice_amount: 0,
    gps_amount: 0,
    commission_amount: 0
  }],
  remark:"",
  employee_id: '',
  location_id: '',
  bank_id: '',
  customerName:''
};
