import {createSlice} from "@reduxjs/toolkit";

const initialProformasState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  proformaForEdit: undefined,
  lastError: null,
  proformaItems:null,
  newButton:1,
  validProforma: null,
  allEntities: [], 
  allTotalCount: 0,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const proformasSlice = createSlice({
  name: "proformas",
  initialState: initialProformasState,
  reducers: {
    catchError: (state, action) => {
      state.lastError = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProformaById
    proformaFetched: (state, action) => {
      state.actionsLoading = false;
      state.proformaForEdit = action.payload.proformaForEdit;
      state.proformaItems = state.proformaForEdit == undefined ? undefined:state.proformaItems;
      state.lastError = null;
    },
    // getValidProforma (i.e. Proforma that is not expired, since only 1 exists)
    validProformaFetched: (state, action) => {
      state.actionsLoading = false;
      state.validProforma = action.payload.validProforma;
      state.lastError = null;
    },
    // getProformaitemsById
    proformaItemsFetched: (state, action) => {
      state.actionsLoading = false;
      state.proformaItems = action.payload.proformaItems;
      state.lastError = null;
    },
    
    // findProformas
    proformasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.lastError = null;
      // state.entities = entities;
      state.allEntities = entities;
      // state.totalCount = totalCount;
      state.allTotalCount = totalCount;
    },
    // createProforma
    proformaCreated: (state, action) => {
      state.actionsLoading = false;
      state.lastError = null;
      if(state.entities?.length > 0) {
        state.entities.push(action.payload.proforma)
      }
      else {
        state.entities = [action.payload.proforma]
      }
      state.allEntities?.push(action.payload.proforma)
      // state.entities.push(action.payload.proforma)
      state.newButton = state.entities?.filter((items)=> {return items?.status_id=="1" || items?.status_id=="3"}).length == 0 ? 1 : 0 ;
      state.proformaForEdit = action.payload.proforma
      state.proformaItems = action.payload.proforma?.proformaItems
    },
    // updateProforma
    proformaUpdated: (state, action) => {
      state.lastError = null;
      state.actionsLoading = false;
      state.listLoading = false;
      state.proformaForEdit = action.payload.proforma;
      if(state.entities != undefined && state.entities != null){
        state.entities = state.entities.map(entity => {
          if (entity.id === action.payload.proforma.id) {
            return action.payload.proforma;
          }
          return entity;
        });
      }
      state.allEntities = state.allEntities?.map(entity => {
        if (entity.id === action.payload.proforma.id) {
          return action.payload.proforma;
        }
        return entity;
      });
      state.newButton = state.entities?.filter((items)=> {return items.status_id=="1" || items.status_id=="3"}).length == 0 ? 1 : 0 ;
    },
    // deleteProforma
    proformaDeleted: (state, action) => {
      state.entities = state.entities?.filter(el => el.id !== action.payload.id);
      state.allEntities = state.allEntities?.filter(el => el.id !== action.payload.id);
      state.lastError = null;
      state.actionsLoading = false;
    },
    // deleteProformas
    proformasDeleted: (state, action) => {
      state.lastError = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
     // ProformasinCars
     proformasInCarFetched: (state, action) => {
      const { proformas, proformasTotalCount } = action.payload;
      state.entities = proformas;
      state.totalCount = proformasTotalCount;
      state.listLoading = false;
      state.newButton = proformas?.filter((items)=> {return items.newButton==0}).length == 0 ? 1 : 0 ;
    },
    proformaInCarCreated:(state, action) =>{
      state.lastError = null;
      state.listLoading = false;
      if(state.entities == undefined || state.entities == null){
        state.entities =  [];
        state.entities.push(action.payload.proforma);
      }else{
        state.entities.push(action.payload.proforma);
      }
      
    },
    proformaInCarUpdated:(state, action) => {
      state.lastError = null;
      state.listLoading = false;
      state.proformaForEdit.proformas = state.proformaForEdit.proformas.map(element => {
        if(element.id == action.payload.proforma.id){
          return action.payload.proforma;
        }else{
          return element;
        }
      })
    },proformaItemCreated:(state, action) =>{
      state.lastError = null;
      state.listLoading = false;
      state.actionsLoading = false
      
      if(state.proformaItems != undefined || state.proformaItems != null){
      state.proformaItems = state.proformaItems.filter(el => el.uuid ==undefined || el.uuid !== action.payload.newProformaItems.uuid) //Delete if created ----- For editing new Data
      state.proformaForEdit.proformaItems = state.proformaForEdit.proformaItems.filter(el => el.uuid ==undefined || el.uuid !== action.payload.newProformaItems.uuid) //Delete if created ----- For editing new Data
      }
      if(state.proformaItems == undefined || state.proformaItems == null){
        state.proformaItems =  [];
        state.proformaItems.push(action.payload.newProformaItems);
        
      }else{
        // state.proformaItems.push(action.payload.newProformaItems);

        // Replace proforma item if it already exists
        if(!state.proformaItems.find(pi => pi.product_id === action.payload.newProformaItems.product_id)) {
          state.proformaItems.push(action.payload.newProformaItems)
        }
        else {
          // state.proformaItems = state?.proformaItems?.map(pi => {
          //   if(pi?.product_id === action.payload.newProformaItems.product_id) {
          //     return action.payload.newProformaItems
          //   }
          // })
          const indexToRemove = state.proformaItems.findIndex(item => item.product_id === action.payload.newProformaItems.product_id);

          if (indexToRemove !== -1) {
            state.proformaItems.splice(indexToRemove,1);
            state.proformaItems.push(action.payload.newProformaItems)
          }
        }
      }
      if(state.proformaForEdit.proformaItems == undefined || state.proformaForEdit.proformaItems == null){
        state.proformaForEdit.proformaItems =  [];
        state.proformaForEdit.proformaItems.push(action.payload.newProformaItems);
        
      }else{ 
        // state.proformaForEdit.proformaItems.push(action.payload.newProformaItems);

        // Replace proforma item if it already exists
        if(!state.proformaForEdit.proformaItems.find(pi => pi.product_id === action.payload.newProformaItems.product_id)) {
          state.proformaForEdit.proformaItems.push(action.payload.newProformaItems)
        }
        else {
          // state.proformaForEdit.proformaItems = state.proformaForEdit.proformaItems?.map(pi => {
          //   if(pi?.product_id === action.payload.newProformaItems.product_id) {
          //     return action.payload.newProformaItems
          //   }
          // })
          const indexToRemove = state.proformaForEdit.proformaItems.findIndex(item => item.product_id === action.payload.newProformaItems.product_id);

          if (indexToRemove !== -1) {
            state.proformaForEdit.proformaItems.splice(indexToRemove,1);
            state.proformaForEdit.proformaItems.push(action.payload.newProformaItems)
          }
        }
      }
      //if(action.payload.values.id==0){
        let editValue = {...action.payload.values}
        editValue.proformaItems = state.proformaForEdit.proformaItems
        state.proformaForEdit=editValue
      //}
      
    },proformaItemsUpdated:(state, action) => {
      state.lastError = null;
      state.listLoading = false;
      state.actionsLoading = false;
      state.proformaItems = state.proformaItems.map(element => {
        if(element.id == action.payload.newProformaItems.id){
          return action.payload.newProformaItems;
        }else{
          return element;
        }
      })
      state.proformaForEdit.proformaItems = state.proformaForEdit.proformaItems.map(element => {
        if(element.id == action.payload.newProformaItems.id){
          return action.payload.newProformaItems;
        }else{
          return element;
        }
      })
      //if(action.payload.values.id==0){
        let editValue = {...action.payload.values}
        editValue.proformaItems = state.proformaForEdit.proformaItems
        state.proformaForEdit=editValue
      //}
    },proformaItemsDelete:(state, action) => {
      state.lastError = null;
      state.listLoading = false;
      state.actionsLoading = false;

      state.proformaItems = state.proformaItems.filter(el => 
        el.id !== action.payload.newProformaItems.id ) 
      state.proformaForEdit.proformaItems = state.proformaForEdit.proformaItems.filter(el =>
         el.id !== action.payload.newProformaItems.id ) 
    },proformaItemsDeleteForNew:(state, action) => {
      state.lastError = null;
      state.listLoading = false;
      state.actionsLoading = false;

      state.proformaItems = state.proformaItems.filter(el => 
         el.uuid !== action.payload.newProformaItems.uuid) 
      state.proformaForEdit.proformaItems = state.proformaForEdit.proformaItems.filter(el =>
          el.uuid !== action.payload.newProformaItems.uuid) 
    },
    updateCustomer:(state,action) => {
      if (action.payload.values.nestedField) {
        if(action.payload.customer) {
          state.proformaForEdit = {...action.payload.values, customer: action.payload.customer}
        }
        else {
          state.proformaForEdit = {...action.payload.values, customer: {}}
        }
       return
      }
  
      if(state.proformaForEdit != undefined || state.proformaForEdit != null ){
        if(state.proformaForEdit.customer == undefined || state.proformaForEdit.customer == null){
          state.proformaForEdit.customer =  {};
          state.proformaForEdit.customer = action.payload.customer;
          
        }else{
          if(action.payload.customer && Object.keys(action.payload.customer).length !== 0) {
            state.proformaForEdit.customer = action.payload.customer;
          }
        }
       }
       
      if(action.payload.values.id!=undefined) {
        let editValue = {...action.payload.values}
        editValue.proformaItems = state.proformaForEdit.proformaItems
        state.proformaForEdit=editValue
      }
      
    }, 
    // clear proforma entities
    proformaEntitiesReset: (state, action) => {
      state.entities = null
      state.validProforma = null
    },
    ProfromaEditUpdate: (state, action) => {
      if(state.proformaForEdit != undefined || state.proformaForEdit != null ){
        if(state.proformaForEdit.customer == undefined || state.proformaForEdit.customer == null){
          state.proformaForEdit.customer =  {};
          state.proformaForEdit.customer = action.payload.customer;
          
        }else{
          state.proformaForEdit.customer = action.payload.customer;
        }
      }
    },
    notificationsFetched: (state, action) => {
      state.actionsLoading = false;
      state.lastError = null;

      if(state.notifications == undefined || state.notifications == null){
        state.notifications =  {};
        state.notifications = action.payload.notifications;
      }else{
        state.notifications = action.payload.notifications;
      }
    },
    proformaStatusChanged: (state, action) => {
      state.actionsLoading = false;
      state.lastError = null;

      if(state.proformaForEdit?.id == action.payload?.proformaId) {
        state.proformaForEdit = {...state.proformaForEdit, status_id: action.payload.statusId}
      }
    }
  }
});
