export const defaultSorted = [{ dataField: "id", order: "desc" }];
export const sizePerPageList = [
  { text: "1", value: 1 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }, 
  { text: "25", value: 25 }, 
  { text: "50", value: 50 }, 
];
export const ProformaStatusTitles = {1: "Active", 2: "Expired", 3:"Processed", 4:"Cancelled", 5: "Overdue"};
export const ProformaStatuses = [{id: 1, name: 'Active'}, {id: 2, name: 'Expired'}, {id: 3, name: 'Processed'}, {id: 4, name: 'Cancelled'}, {id: 5, name: 'Overdue'}];
export const ProformaStatusCssClasses = {1: "default", 2: "danger",3:"success", 4:"warning", 5:"info"};
export const initialFilter = {
  filter: {
    searchText: "", 
    status_id: ""
  },
  sortOrder: "asc", // asc||desc
  sortField: "name",
  pageNumber: 1,
  pageSize: 10,
};
