import * as requestFromServer from "./carsCrud";
import {carsSlice, callTypes} from "./carsSlice";

const {actions} = carsSlice;

export const fetchCars = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCars(queryParams,localStorage.currentUserEmail)
    .then(response => {
      // const { totalCount, entities } = response.data;
      const entities = response.data.content;
      const totalCount = response.data.totalElements;
      const fetchAll = queryParams === undefined
      dispatch(actions.carsFetched({ totalCount, entities, fetchAll }));
    })
    .catch(error => {
      error.clientMessage = "Can't find cars";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStock = queryParams => dispatch => {
  // queryParams.filter.user_name = localStorage.currentUserEmail;
  queryParams = {...queryParams, filter: {...queryParams.filter, user_name: localStorage.currentUserEmail}}
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findStock(queryParams)
    .then(response => {
      dispatch(actions.stockFetched({ stock: response.data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find stock";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStockMobile = queryParams => dispatch => {
  queryParams = {...queryParams, filter: {...queryParams.filter, user_name: localStorage.currentUserEmail}}
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findStock(queryParams)
    .then(response => {
      dispatch(actions.stockFetchedMobile({ stockMobile: response.data , child: queryParams.child}));
    })
    .catch(error => {
      error.clientMessage = "Can't find stock";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDashboardStockReport = queryParams => dispatch => {
  queryParams.filter.user_name = localStorage.currentUserEmail;
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDashboardStockReport(queryParams)
    .then(response => {
      dispatch(actions.dashboardStockReportFetched({ dashboardStockReport: response.data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find stock";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDashboardStockReportDetail = queryParams => dispatch => {
  queryParams.filter.user_name = localStorage.currentUserEmail;
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDashboardStockReport(queryParams)
    .then(res => {
      dispatch(actions.dashboardStockReportDetailFetched({ dashboardStockReportDetail: res.data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find stock";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCar = id => dispatch => {
  if (!id) {
    return dispatch(actions.carFetched({ carForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCarById(id)
    .then(response => {
      let car = response.data;
      car.import_date = new Date(car?.import_date)
      dispatch(actions.carFetched({ carForEdit: car }));
    })
    .catch(error => {
      error.clientMessage = "Can't find car";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCar = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCar(id)
    .then(response => {
      dispatch(actions.carDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete car";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCar = carForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCar(carForCreation)
    .then(response => {
      const { car } = response.data;
      dispatch(actions.carCreated({ car }));
    })
    .catch(error => {
      error.clientMessage = "Can't create car";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCar = car => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCar(car)
    .then(() => {
      dispatch(actions.carUpdated({ car }));
    })
    .catch(error => {
      error.clientMessage = "Can't update car";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCarsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCars(ids, status)
    .then(() => {
      dispatch(actions.carsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update cars status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const updateEditCarsStatus = () => dispatch => {
  dispatch(actions.carEditStatusUpdate());
    
};

export const deleteCars = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCars(ids)
    .then(() => {
      dispatch(actions.carsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete cars";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchMaxPrice = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMaxPrice()
    .then(response => {
      const maxPrice = response.data;
      dispatch(actions.maxPriceFetched({ maxPrice }));
    })
    .catch(error => {
      error.clientMessage = "Can't find maximum car price";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const selectCurrentCarId = (id=null) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  dispatch(actions.currentCarIdSelected({id}))
}

// get warehouse cars
export const getWarehouse = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  if(localStorage.group != 'ADMIN'){
  return requestFromServer
    .getWarehouse()
    .then(response => {
      dispatch(actions.warehouseFetched({ warehouse: response.data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find stock";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}}


export const openNewOrderModal = (newOrder) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  dispatch(actions.addNewOrder(newOrder))
}
