import axios from "axios";

export const URL = process.env.REACT_APP_AUTO_DEALER_API+'bodyStyles'


// CREATE =>  POST: add a new bodyStyle to the server
export function createBodyStyle(bodyStyle) {
  return axios.post(URL, { bodyStyle });
}

// READ
export function getAllBodyStyles() {
  return axios.get(URL);
}

export function getBodyStyleById(bodyStyleId) {
  return axios.get(`${URL}/${bodyStyleId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findBodyStyles(queryParams) {
  return axios.post(`${URL}/find`, { queryParams });
}

// UPDATE => PUT: update the bodyStyle on the server
export function updateBodyStyle(bodyStyle) {
  return axios.put(`${URL}/${bodyStyle.id}`, { bodyStyle });
}

// UPDATE Status
export function updateStatusForBodyStyles(ids, status) {
  return axios.post(`${URL}/updateStatusForBodyStyles`, {
    ids,
    status
  });
}

// DELETE => delete the bodyStyle from the server
export function deleteBodyStyle(bodyStyleId) {
  return axios.delete(`${URL}/${bodyStyleId}`);
}

// DELETE BodyStyles by ids
export function deleteBodyStyles(ids) {
  return axios.post(`${URL}/deleteBodyStyles`, { ids });
}
