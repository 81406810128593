import {createSlice} from "@reduxjs/toolkit";

const initialCarsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  carForEdit: {},
  lastError: null,
  maxCarPrice: null,
  stock: [], 
  stockMobile: [],
  stockMobile1: [], 
  stockMobile2: [],
  dashboardStockReport: [],
  dashboardStockReportDetail: [],
  warehouse: [],
  newOrder:false,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const carsSlice = createSlice({
  name: "cars",
  initialState: initialCarsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCarById
    carFetched: (state, action) => {
      state.actionsLoading = false;
      state.carForEdit = action.payload.carForEdit;
      state.error = null;
      state.success = null;
    },
    // findCars
    carsFetched: (state, action) => {
      const { totalCount, entities, fetchAll } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      if(!fetchAll) {
        state.carForEdit = null;
      }
    },

    // findStock
    stockFetched: (state, action) => {
      const { stock } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.stock = stock;
    },

    // findStock Mobile
    stockFetchedMobile: (state, action) => {
      const { stockMobile, child } = action.payload;
      state.listLoading = false;
      state.error = null;
      if(child === 'year') state.stockMobile1 = stockMobile
      else if(child === 'color') state.stockMobile2 = stockMobile;
      else state.stockMobile = stockMobile;
    },

    // find stock report for dashboard
    dashboardStockReportFetched: (state, action) => {
      const { dashboardStockReport } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.dashboardStockReport = dashboardStockReport;
    },

     // find stock report for dashboard
     dashboardStockReportDetailFetched: (state, action) => {
      const { dashboardStockReportDetail } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.dashboardStockReportDetail = dashboardStockReportDetail;
    },

    // createCar
    carCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.car);
      state.success = {type: 'create'};
    },
    // updateCar
    carUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.car.id) {
          return action.payload.car;
        }
        return entity;
      });
      state.success = {type: 'update'};
    },
    // deleteCar
    carDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
      state.success = null;
    },
    // deleteCars
    carsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // carsUpdateState
    carsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    carEditStatusUpdate:(state, action)=>{
     if(state.carForEdit != undefined){
        state.carForEdit.saleStatus = "In-Process"
      }
    },
    // get max car price
    maxPriceFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.maxCarPrice = action.payload.maxPrice;
    },
    // get current car id
    currentCarIdSelected: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.currentId = action.payload.id;
      if(action.payload?.id === null) {
        state.carForEdit = null;
      }
    },
    // findWarehouse
    warehouseFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.warehouse = action.payload.warehouse;
    },
    // shortcut for new order on dashboard page
    addNewOrder: (state, action) => {
      state.newOrder = action.payload.newOrder;
    },
  }
});
