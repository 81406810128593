import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/user/usersActions";
import {UserEditDialogHeader} from "./UserEditDialogHeader";
import { UserEditForm } from "./UserEditForm";
import { useUsersUIContext } from "../UsersUIContext";

export function UserEditDialog({ id, show, onHide }) {
  // Users UI Context
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      initUser: usersUIContext.initUser,
      openDeleteUserDialog: usersUIContext.openDeleteUserDialog,
      openChangePasswordDialog:usersUIContext.openChangePasswordDialog
    };
  }, [usersUIContext]);

  // Users Redux state
  const dispatch = useDispatch();
  const { actionsLoading, userForEdit ,users , locations,groups} = useSelector(
    (state) => ({
      actionsLoading: state.users.userListLoading,
      userForEdit: state.users.userForEdit,
      users:state.users.userEntities,
      locations:state.locations.locationEntities,
      groups:state.groups.groupEntities

    }),
    shallowEqual
  );
  const userForValidation = userForEdit==undefined ?  {...usersUIProps.initUser,users}: {...userForEdit,users}
  useEffect(() => {
    // server call for getting User by id
    dispatch(actions.fetchUser(id));
  }, [id, dispatch]);

  // server request for saving user
  const saveUser = (user) => {
    if (!id) {
      // server request for creating user
      dispatch(actions.createUser(user)).then(() => onHide());
    } else {
      // server request for updating user
      dispatch(actions.updateUser(user)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="md"
      show={show}
      // onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <UserEditDialogHeader id={id} onHide={onHide} />
      <UserEditForm
        saveUser={saveUser}
        actionsLoading={actionsLoading}
        user={userForValidation}
        onHide={onHide}
        openDeleteUserDialog={usersUIProps.openDeleteUserDialog}
        locations={locations}
        groups={groups}
        selectedLocation={userForValidation?.locations}
        selectedGroup={userForValidation?.group}
        openChangePasswordDialog = {usersUIProps.openChangePasswordDialog}
        initUser = {usersUIProps.initUser}

      />
    </Modal>
  );
}
