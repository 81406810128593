import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";

export const CUSTOMERS_URL = process.env.REACT_APP_AUTO_DEALER_API+'product'

const jwtToken = localStorage.getItem("currentUser")

const AsyncSearchBar = ({ selectedCar,setSelectedCar,carName,selectedCars=[],enableEdit = true }) => {
  //set default query terms
  const [query, setQuery] = useState("");
  
  //get animated components wrapper
  const animatedComponents = makeAnimated();
  const listOfCarSelected = selectedCars
 
  const loadOptions = async (query) => {

    const response = await fetch(
      `${CUSTOMERS_URL}/getCarForSelect?searchTxt=${query}&user_name=${localStorage.currentUserEmail}`, {
        headers: {Authorization: jwtToken}
      }
    );
    const responseJSON = await response.json();

    return responseJSON.filter((item)=>{return !listOfCarSelected.includes(item.product_id)})
  };

  return (
    <>
      {enableEdit ? 
      
      <AsyncSelect
      inputValue={carName}
      menuIsOpen={false}
      />
      
      :
      <AsyncSelect
        
        noOptionsMessage={() => 'Please write a word or Try a different word'}
        cacheOptions
        defaultOptions
        defaultInputValue={ carName}
        components={animatedComponents}
        getOptionLabel={(e) => e.productName}
        getOptionValue={(e) => e.product_id}
        loadOptions={loadOptions}
        onInputChange={(value) => setQuery(value)}
        onChange={(value) => {
          const newFormData = {...selectedCar}
          for (var key in value) {
            if(value[key]!=null){

            newFormData[key]=value[key];
            
          }
            
        }
        setSelectedCar(newFormData);
        }}
        aria-labelledby="aria-label"
        inputId="aria-example-input"
        
        
      />}
    </>
  );
};

export default AsyncSearchBar;