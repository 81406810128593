import React from "react";
import { StockLoadingDialog } from "./stock-loading-dialog/StockLoadingDialog";
import { StockUIProvider } from "./StockUIContext";
import { StockCard } from "./StockCard";

export function StockPage() {
  return (
    <StockUIProvider>
      <StockLoadingDialog />
      <StockCard />
    </StockUIProvider>
  );
}
