/* eslint-disable no-restricted-imports */
import React, { useMemo } from "react";
import { Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as proformaActions from "../../../../_redux/proformas/proformasActions"
import * as salesActions from "../../../../_redux/sales/salesActions"
import { useProformasUIContext } from "../ProformasUIContext";

export function ProformaDeleteSales() {
  // Sales UI Context
  const specsUIContext = useProformasUIContext();
  const specsUIProps = useMemo(() => {
    return {
      show: specsUIContext.showDeleteSaleProformaDialog,
      onHide: specsUIContext.closeDeleteSaleProformaDialog, 
      onHideEdit: specsUIContext.closeEditProformaDialog,
      carId:specsUIContext.carId,
      proformaId:specsUIContext.proformaId
    };
  }, [specsUIContext]);

  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.sales.actionsLoading,
     
    }),
    shallowEqual
  );
 
  const dispatch = useDispatch();
  const deleteSales = () => {
     dispatch(proformaActions.deleteSales(specsUIProps.proformaId)).then(() => {
      // refresh list after deletion
     dispatch(salesActions.fetchSalesInCar(specsUIProps.carId))
      specsUIProps.onHideEdit();
      specsUIProps.onHide();
    });
  };

  

  return (
    
      <Modal
      show={specsUIProps.show}
      aria-labelledby="example-modal-sizes-title-lg"
      >
        {isLoading && <ModalProgressBar variant="query" />}
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Change Proforma
          </Modal.Title>
          <Nav className="ml-auto" onClick={() => specsUIProps.onHide(false)}>
          <Nav.Link className="p-0 text-dark">
            <span className="nav-icon">
              <span className="svg-icon svg-icon-2x">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                        <rect x="0" y="7" width="16" height="2" rx="1"/>
                        <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </span>
          </Nav.Link>
        </Nav>
        </Modal.Header>
        <Modal.Body>
          {!isLoading && (
            <p>Do you also want to delete all payment made with this proforma?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="mr-auto">
          <button
              type="button"
              onClick={deleteSales}
              className="btn btn-primary btn-elevate"
            >
              Yes
            </button>
            <> </>
            <button
              type="button"
              onClick={() => specsUIProps.onHide(false)}
              className="btn btn-light btn-elevate"
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    
  );
}
