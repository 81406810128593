import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from 'moment'

import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone, 
  PaginationTotalStandalone
} from "react-bootstrap-table2-paginator";
import { ActionsColumnFormatter } from "./column-formatters/ActionsColumnFormatter";
import * as uiHelpers from "./InspectionsUIHelper";
import { PaginationLinks } from "../../../../../_metronic/_partials/controls";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../_metronic/_helpers";
import { useInspectionsUIContext } from "./InspectionsUIContext";
import cloneDeep from 'lodash/cloneDeep';


export function InspectionsTable({ inspectionsTotalCount, inspectionEntities, listLoading}) {
  // Specs UI Context
  const specsUIContext = useInspectionsUIContext();
  const specsUIProps = useMemo(() => {
    return {
      queryParams: specsUIContext.queryParams,
      setQueryParams: specsUIContext.setQueryParams,
      openEditInspectionDialog: specsUIContext.openEditInspectionDialog,
      openDeleteInspectionDialog:
        specsUIContext.openDeleteInspectionDialog,
      ids: specsUIContext.ids,
      setIds: specsUIContext.setIds,
      productId: specsUIContext.productId,
    };
  }, [specsUIContext]);

  const [tableData,setTableData] = useState(null)

  useEffect(()=>{
    setTableData(cloneDeep(inspectionEntities) || [])
  },[inspectionEntities])

  const columns = [
    {
      dataField: "inspection_date",
      text: "Inspection Date",
      sort: true,
      sortCaret: sortCaret,
      footerClasses: "bottomNone",
      formatter: (cellContent, row) => (
        <span>
          {moment(row.inspection_date).format("DD/MM/yyyy")}
        </span>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          specsUIProps.openEditInspectionDialog(row.id);
        }
      }
    },
    {
      dataField: "employee",
      text: "Inspected By",
      sort: true,
      sortCaret: sortCaret,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          specsUIProps.openEditInspectionDialog(row.id);
        }
      }
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditInspectionDialog: specsUIProps.openEditInspectionDialog,
        openDeleteInspectionDialog: specsUIProps.openDeleteInspectionDialog,
      },
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: inspectionsTotalCount != undefined ? inspectionsTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: specsUIProps.queryParams.pageSize,
    page: (specsUIProps.queryParams.pageNumber - 1) < (tableData?.length/specsUIProps.queryParams.pageSize) ? specsUIProps.queryParams.pageNumber : 
    specsUIProps.queryParams.pageNumber == 1 ?  specsUIProps.queryParams.pageNumber:specsUIProps.queryParams.pageNumber - 1  ,
  };

  function pageHandler (paginationProps){
    const pagenumber = paginationProps?.page || 1
    const sizePerPage = paginationProps?.sizePerPage
    specsUIProps.setQueryParams(
      (prev) =>
      "pagination" === "pagination"
        ? { ...prev, pageNumber:pagenumber, pageSize: sizePerPage }
        : prev
    )    
  }

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bordered={false}
                bootstrap4
                // remote
                keyField="id"
                data={tableData === null ? [] : tableData}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={pageHandler(paginationProps)}
                rowStyle={{ cursor: 'pointer' }}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={tableData} />
                <NoRecordsFoundMessage entities={tableData} />
              </BootstrapTable>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <PaginationLinks paginationProps={paginationProps} />
                <div className="ml-auto">
                <SizePerPageDropdownStandalone
                    { ...paginationProps }
                  />
                  <PaginationTotalStandalone
                    { ...paginationProps }
                  />
                </div>
              </div>
            </>
          );
        }}
      </PaginationProvider>
    </>
  );
}
