import React from "react";
import { ReportsUIProvider } from "./ReportsUIContext";

import { SubscriptionsPage } from "./reports-subscriptions/SubscriptionsPage"
import { ContentRoute } from "../../../../../_metronic/layout";

export function ReportsPageAdmin({ history }) {

  // const {auth} = useSelector((state) => ({ auth: state.auth }),
  //   shallowEqual)
    
  // const {roles} = auth;

  const reportsUIEvents = {
    
    openSubscriptionReportPage: () => {
      history.push("/reportsAdmin/subscription");
    }
  }

  return (
    <ReportsUIProvider reportsUIEvents={reportsUIEvents}>
      <ContentRoute path="/reportsAdmin/subscription" component={SubscriptionsPage} />
    </ReportsUIProvider>
  );
}
