// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo,useState,useLayoutEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
}  from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/batch/batchActions";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../BatchUIHelpers";
import * as columnFormatters from "./column-formatters";
import { PaginationLinks } from "../../../../../../_metronic/_partials/controls";
import { useBatchUIContext } from "../BatchUIContext";
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment'
import NotFoundPage from "../../../shared/NotFoundPage";
import $ from 'jquery';

export function BatchTable() {
  // Batch UI Context
  const batchUIContext = useBatchUIContext();
  const batchUIProps = useMemo(() => {
    return {
      ids: batchUIContext.ids,
      setIds: batchUIContext.setIds,
      queryParams: batchUIContext.queryParams,
      setQueryParams: batchUIContext.setQueryParams,
      openEditBatchDialog: batchUIContext.openEditBatchDialog,
      openDeleteBatchDialog: batchUIContext.openDeleteBatchDialog,
    };
  }, [batchUIContext]);

  // Getting curret state of batch list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.batch }),
    shallowEqual
  );
  const { batchTotalCount:totalCount, batchEntities:entities, batchListLoading:listLoading,error,apiErrorData } = currentState;
  const [tableData,setTableDate] = useState([])
  const [notFound,setNotFound] = useState(false)
  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }else if(error 
          && apiErrorData?.errorCode==="NOT_FOUND"){
            setNotFound(true);
        }
    }
  },[error])
  useEffect(()=>{
    if(notFound){
      $("#kt_content > div > div > div.card-header > div.card-toolbar > button").hide();
    }
  },[notFound])

  // Batch Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    batchUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchBatch(batchUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dispatch]);

  useEffect(()=>{
    setTableDate(cloneDeep(entities))
  },[entities])

  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          batchUIProps.openEditBatchDialog(row.id);
        }
      }
    },
    
    {
      dataField: "date",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      footerClasses: "bottomNone",
      formatter: (cellContent, row) => (
        <span>
          {moment(row.date).format("DD/MM/yyyy")}
        </span>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          batchUIProps.openEditBatchDialog(row.id);
        }
      }
    },
    
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditBatchDialog: batchUIProps.openEditBatchDialog,
        openDeleteBatchDialog: batchUIProps.openDeleteBatchDialog,
      },
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: tableData?.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: batchUIProps.queryParams.pageSize,
    page: (batchUIProps.queryParams.pageNumber - 1) < (tableData?.length/batchUIProps.queryParams.pageSize) ? batchUIProps.queryParams.pageNumber : 
    batchUIProps.queryParams.pageNumber == 1 ?  batchUIProps.queryParams.pageNumber:batchUIProps.queryParams.pageNumber - 1  ,
  };

  function filter(e){
    //console.log(e)
    const filter = entities?.filter(item => item.name.toLowerCase().indexOf(e.toLowerCase()) > -1 || item.date?.toLowerCase().indexOf(e.toLowerCase()) > -1 )
    setTableDate(filter)
  }
  
  function pageHandler (paginationProps){
    
    batchUIProps.setQueryParams(
      (prev) =>
      "pagination" === "pagination"
        ? { ...prev, pageNumber:paginationProps?.page || 1, pageSize: paginationProps?.sizePerPage }
        : prev
    )
    
}
  return (
    <>
    {!notFound ?
    <>
      <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  // onBlur={handleBlur}
                  // value={values.searchText}
                   onChange={(e) => {
                    filter(e.target.value);
                   }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                keyField="id"
                data={tableData === null ? [] : tableData}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={pageHandler(paginationProps)}
                rowStyle={{ cursor: 'pointer' }}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={tableData} />
                <NoRecordsFoundMessage entities={tableData} />
              </BootstrapTable>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
            <PaginationLinks paginationProps={paginationProps} />
            <div className="ml-lg-auto">
            <SizePerPageDropdownStandalone
                { ...paginationProps }
              />
              <PaginationTotalStandalone
                { ...paginationProps }
              />
            </div>
            </div>
            </>
          );
        }}
      </PaginationProvider>
    </>
     :<NotFoundPage/>}
    </>
  );
}
