import React from "react";
import { useSelector, shallowEqual } from 'react-redux'
import { OrdersUIProvider } from "./OrdersUIContext";
import { OrdersCard } from "./OrdersCard";
import { SalesUIProvider } from "../cars/car-sales/SalesUIContext";
import { ProformasUIProvider } from "../cars/car-proformas/ProformasUIContext";
import { ProformaEditDialog } from "../cars/car-proformas/Proforma-edit-dialog/ProformaEditDialog";
import { ProformaDeleteDialog } from "../cars/car-proformas/ProformaDeleteDialog";
import { ProformaPrintModal } from "../cars/car-proformas/print-proforma/ProformaPrintModal";
import { ProformasLoadingDialog } from "../cars/car-proformas/ProformasLoadingDialog";
import { Sales_1 } from "../cars/car-sales/Sales_1";
import { CarsUIProvider } from "../cars/CarsUIContext";
import { ProformaDeleteSales } from "../cars/car-proformas/Proforma-edit-dialog/ProformaDeleteSalesModal";
import { ProformaMenuModal } from "../cars/car-proformas/print-proforma/ProformaMenuModal";

export function OrdersPage({ history }) {
  const { currentState } = useSelector(
		(state) => ({ currentState: state }),
		shallowEqual
	);
	const { currentId } = currentState.cars;

  return (
    <CarsUIProvider>
      <ProformasUIProvider currentProductId={currentId} proformaFromOutside={true}>
        <SalesUIProvider currentProductId={currentId} saleFromOutside={true}>  
          <OrdersUIProvider>
            <ProformasLoadingDialog />
            <ProformaDeleteDialog />
            <ProformaEditDialog />
            <ProformaPrintModal />
            <ProformaMenuModal />
            {/* <ContractOfSaleModal/> */}
     	      {/* <ProformaBankReleaseModal/> */}
      	    {/* <CashContractOfSaleModal/> */}
            <ProformaDeleteSales />
            {/* <SaleEditDialog /> */}
            <Sales_1 />
            <OrdersCard />
          </OrdersUIProvider>
        </SalesUIProvider>
      </ProformasUIProvider>
    </CarsUIProvider>
  );
}
