import { createSlice } from "@reduxjs/toolkit";

const initialSearchState={
    searchEntities:{searchText:"",
    make: "",
    model: "",
    status: "",
    batch: "", 
    color: "",
    type:""},
    lastError:null,
    pageName:null
}

export const callTypes={
    list : "list",
    action:"action"

}

export const searchSlice = createSlice({
    name:"search",
    initialState:initialSearchState,
    reducers:{
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if(state.apiErrorData === undefined){
                state.apiErrorData = {}
                 state.apiErrorData = action.payload.error?.response?.data;
              }else{
                state.apiErrorData = action.payload.error?.response?.data;
              }
        },
        searchFetched:(state,action) => {
            const{page,searchEntities} = action.payload
            state.searchEntities = searchEntities;
            state.pageName = page
        },
        searchReset:(state,action)=>{
            state.searchEntities = {searchText:"",
            make: "",
            model: "",
            status: "",
            batch: "", 
            color: ""};
            state.pageName = "";
        }
    }
})