import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./TenantsUIHelpers";

const TenantsUIContext = createContext();

export function useTenantsUIContext() {
  return useContext(TenantsUIContext);
}

export const TenantsUIConsumer = TenantsUIContext.Consumer;

export function TenantsUIProvider({tenantsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [id,setId] = useState();
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initTenant = {
    id: undefined,
    name: "",
    telephone:"",
    tin:"",
    subcity:"",
    city :""
    //names :[{id:0,name:'Betselot'},{id:1,name:'betse'},{id:2,name:'betse'}]
  };
  const[active,setActive] = useState()
  const [showTSD,setShowTSD] = useState(false)
  const [showEdit,setShowEdit] = useState(false)
  const [showDelete,setShowDelete] = useState()
  const [tenantId,setTenantId] = useState()
  const [subId,setSubId] = useState()
  const showTenantStatusDialog = (id,active) =>{
    setId(id);
    setShowTSD(true);
    setActive(active);
  }
  const hideTenantStatusDialog = () =>{
    setId(undefined);
    setShowTSD(false);
  }
  const openEdit = (clientId,subId) => {
    setShowEdit(true)
    setTenantId(clientId)
    setSubId(subId)
  }
  const hideEdit = () => {
    setShowEdit(false)
    setTenantId(undefined)
    setSubId(undefined)
  }
  const openDelete = (subId,clientId) => {
    setShowDelete(true)
    setTenantId(clientId)
    setSubId(subId)
  }
  const hideDelete = () => {
    setShowDelete(false)
    setTenantId(undefined)
    setSubId(undefined)
  }

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initTenant,
    newTenantButtonClick: tenantsUIEvents.newTenantButtonClick,
    openEditTenantDialog: tenantsUIEvents.openEditTenantDialog,
    openDeleteTenantDialog: tenantsUIEvents.openDeleteTenantDialog,
    openDeleteTenantsDialog: tenantsUIEvents.openDeleteTenantsDialog,
    openFetchTenantsDialog: tenantsUIEvents.openFetchTenantsDialog,
    openUpdateTenantsStatusDialog: tenantsUIEvents.openUpdateTenantsStatusDialog,
    showTSD,
    showTenantStatusDialog,
    hideTenantStatusDialog,
    id,
    active,
    showEdit,
    showDelete,
    tenantId,
    subId,
    openEdit,
    hideEdit,
    openDelete,
    hideDelete
  };

  return <TenantsUIContext.Provider value={value}>{children}</TenantsUIContext.Provider>;
}