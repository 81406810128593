import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { EmployeesTable } from "./employees-table/EmployeesTable";
import { EmployeesGrouping } from "./employees-grouping/EmployeesGrouping";
import { useEmployeesUIContext } from "./EmployeesUIContext";

export function EmployeesCard() {
  const employeesUIContext = useEmployeesUIContext();
  const employeesUIProps = useMemo(() => {
    return {
      ids: employeesUIContext.ids,
      newEmployeeButtonClick: employeesUIContext.newEmployeeButtonClick,
    };
  }, [employeesUIContext]);

  return (
    <div>
           
    <div className="content d-flex flex-column-fluid" id="kt_content">
      
      <div className="container">
     
     
                    {/* <div className="flex-row-fluid ml-lg-8">	
							
		</div> */}
    <Card>
      <CardHeader title="Sales Person list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={employeesUIProps.newEmployeeButtonClick}
          >
            New Sales Person
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {employeesUIProps.ids.length > 0 && <EmployeesGrouping />}
        <EmployeesTable />
      </CardBody>
    </Card>
    
    </div>
    </div>
    </div>
   
  );
}
