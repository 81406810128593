import {combineReducers} from "redux";


import { authsSlice } from "../app/components/_redux/auth/authsSlice";
import {customersSlice} from "./../app/components/_redux/customers/customersSlice";
import {carsSlice} from "./../app/components/_redux/cars/carsSlice";
import {salesSlice} from "../app/components/_redux/sales/salesSlice";
import {inspectionsSlice} from "../app/components/_redux/inspections/inspectionsSlice";
import { proformasSlice } from "./../app/components/_redux/proformas/proformasSlice";
import { paymentTypesSlice } from "./../app/components/_redux/payment-type/paymentTypesSlice";
import {colorsSlice} from "./../app/components/_redux/colors/colorsSlice";
import {categoriesSlice} from "./../app/components/_redux/categories/categoriesSlice";
import {locationsSlice} from "./../app/components/_redux/locations/locationsSlice";
import {suppliersSlice} from "./../app/components/_redux/suppliers/suppliersSlice"
import { carModelsSlice } from "./../app/components/_redux/car-models/carModelsSlice"
import { bodyStylesSlice } from "./../app/components/_redux/body-style/bodyStylesSlice"
import { employeesSlice } from "../app/components/_redux/employees/employeesSlice";
import { usersSlice } from "../app/components/_redux/user/usersSlice";
import { clientsSlice } from "../app/components/_redux/client/clientsSlice";
import { groupsSlice } from "../app/components/_redux/groups/groupsSlice";
import { banksSlice } from "../app/components/_redux/banks/banksSlice";
import { batchSlice } from "../app/components/_redux/batch/batchSlice";
import {searchSlice} from "../app/components/_redux/search/searchSlice"
import { tabsSlice } from "../app/components/_redux/tabs/tabsSlice";
import { reportsSlice } from "../app/components/_redux/reports/reportsSlice";
import { settingSlice } from "../app/components/_redux/settings/settingSlice";
import { tenantsSlice } from "../app/components/_redux_admin/clients_adm/clientsSlice";
import { usersAdminSlice } from "../app/components/_redux_admin/user/usersSlice";
import { settingsAdminSlice } from "../app/components/_redux_admin/settings_data/settingsSlice";
import { accountsSlice } from "../app/components/_redux/accounts/accountsSlice"

export const rootReducer = combineReducers({
  auth: authsSlice.reducer,
  users: usersSlice.reducer,
  client: clientsSlice.reducer,
  cars: carsSlice.reducer,
  carModels: carModelsSlice.reducer,
  customers: customersSlice.reducer,
   /* products: productsSlice.reducer,
  remarks: remarksSlice.reducer, */
  proformas: proformasSlice.reducer,
  paymentTypes: paymentTypesSlice.reducer,
  inspections: inspectionsSlice.reducer,
  sales: salesSlice.reducer,
  colors: colorsSlice.reducer,
  categories: categoriesSlice.reducer,
  locations: locationsSlice.reducer,
  employees: employeesSlice.reducer,
  suppliers: suppliersSlice.reducer,
  bodyStyles: bodyStylesSlice.reducer,
  groups:groupsSlice.reducer,
  banks:banksSlice.reducer,
  batch:batchSlice.reducer,
  search:searchSlice.reducer,
  tab: tabsSlice.reducer, 
  reports: reportsSlice.reducer,
  setting:settingSlice.reducer,
  tenants:tenantsSlice.reducer,
  userAdmin:usersAdminSlice.reducer,
  settingsAdmin:settingsAdminSlice.reducer,
  accounts: accountsSlice.reducer
});

export function* rootSaga() {
  //yield all([auth.saga()]);
}
