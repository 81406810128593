import React, { useEffect, useMemo, useState, useRef, forwardRef,useLayoutEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/cars/carsActions";
import * as clientActions from '../../../../_redux/client/clientsActions';
import * as batchActions from "../../../../_redux/batch/batchActions"
import { useStockUIContext } from "../StockUIContext";
import cloneDeep from 'lodash/cloneDeep';
import StockAGGrid from "./Ag_grid_for_stock";
import TableDataRowFormatter from "./TableDataRowFormatter";
import $ from 'jquery';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import { Loader} from 'react-loaders';


const StockTable = forwardRef((props, ref)  => {
  const { ref1, ref2 } = ref;
  const exportInsideCSV = useRef();
  const exportInsidePrint = useRef();
  const stockUIContext = useStockUIContext();
  const stockUIProps = useMemo(() => {
    return {
      ids: stockUIContext.ids,
      setIds: stockUIContext.setIds,
      queryParams: stockUIContext.queryParams,
      setQueryParams: stockUIContext.setQueryParams,
    };
  }, [stockUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state }),
    shallowEqual
  );
  let { stock: entities,error:errorCar,apiErrorData:apiErrorDataCars,listLoading } = currentState.cars;
  const {clientEntities} = currentState.client;
  const {batchEntities,error:errorBatch,apiErrorDataBatch} = currentState.batch;
  const [tableData,setTableData] = useState(null)

  useLayoutEffect(()=>{
    if(errorCar && apiErrorDataCars === undefined){
      throw new Error(errorCar);
    }else{
        if(errorCar 
          && apiErrorDataCars?.errorCode!="NOT_FOUND"
          && apiErrorDataCars?.errorCode!="CONFLICT"){
          throw new Error(errorCar);
        }
    }
    if(errorBatch && apiErrorDataBatch === undefined){
      throw new Error(errorBatch);
    }else{
        if(errorBatch 
          && apiErrorDataBatch?.errorCode!="NOT_FOUND"
          && apiErrorDataBatch?.errorCode!="CONFLICT"){
          throw new Error(errorBatch);
        }
    }
    
  },[errorCar,errorBatch])

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchStock(stockUIProps.queryParams))
    dispatch(clientActions.fetchClientbyUserEmail(localStorage.currentUserEmail.substring(localStorage.currentUserEmail.indexOf('@')+1)))
    dispatch(batchActions.fetchBatch())
  }, [stockUIProps.queryParams, dispatch]);

  useEffect(()=>{
    entities = entities.map(e => ({
      ...e,
      value: e.value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      totalCategoryValue: e.totalCategoryValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      batch: (e.batch || ""),
      color: (e.color || "")
    }))
    setTableData(cloneDeep(entities))
  },[entities])

  const columns = [
    {
      headerName: "MAKE/BRAND",
      field: 'car.category',
      cellClassRules: {
        'cell-span': ({ data }) => data && data.span > 0,
        'cell-notSpan':({ data }) => data && data.span === -1
      },
      cellRenderer: ({ data }) => {
        if (data?.car?.category=== undefined ||data.span === 0) {
          return null;
        }
        if (data.span === -1) {
          return `${data.car.category}`;
        }
        return `${data.car.category}`;
      },
      rowSpan: ({ data }) => data.span > 1 ? data.span+1:data.span,
      // width: 200,
      minWidth: 192,
      Width: 350,
      flex: 3,
    },
    {
      headerName: 'YEAR',
      field: 'detail.year',
      hide: stockUIProps.queryParams.hidden.year,
      // width: 100
      minWidth: 85,
      Width: 85,
      flex: 2,
    },
    {
      headerName: 'COLOR',
      field: 'detail.color',
      hide: stockUIProps.queryParams.hidden.color,
      // width: 115
      minWidth: 100,
      Width: 105,
      flex: 2,
    },
    {
      headerName: 'BATCH',
      field: 'detail.batch',
      hide: stockUIProps.queryParams.hidden.batch,
      // width: 115
      minWidth: 95,
      Width: 105,
      flex: 2,
    },
    {
      headerName: 'LOCATION',
      field: 'detail.location', 
      // width: 150
      minWidth: 120,
      Width: 130,
      flex: 1,
    },
    {
      headerName: 'QTY',
      field: 'detail.quantity',
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      cellClassRules: {
        'cell-totalSpan': ({ data }) => data && data.span === -2
      }, 
      // width: 90
      minWidth: 80,
      Width: 130,
      flex: 2,
    },
    {
      headerName: 'VALUE',
      field: 'detail.value',
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      headerClass: "ag-right-aligned-header",
      cellClassRules: {
        'cell-totalSpan': ({ data }) => data && data.span === -2
      }, 
      // width: 150
      minWidth: 150,
      Width: 230,
      flex: 3,
    },
  ]

  const [columnDefs, setColumnDefs] = useState(columns);

  useEffect(() => {
    setColumnDefs(columns)
  }, [stockUIProps.queryParams])

  const handlerCsv = () => {
    exportInsideCSV.current.click();
  };

  const handlerPrint = () => {
    $('#bankContainer').css('visibility','hidden');
    $('#blockConstiner').show()
    exportInsidePrint.current.click();
  };

  const componentRef = useRef(null);

  return (
    <>
      <div className="d-flex justify-content-end">
        <OverlayTrigger
          overlay={
            <Tooltip id="spec-edit-tooltip">Export to Excel</Tooltip>
          }
        >
          <input
            ref={ref1}
            hidden
            className="btn btn-success"
            onClick={handlerCsv}
          />
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip id="spec-edit-tooltip">Export to PDF</Tooltip>
          }
        >
          <input
            ref={ref2}
            hidden
            className="btn btn-success"
            onClick={handlerPrint}
          />
        </OverlayTrigger>
      </div>

      <BlockUi  blocking={listLoading} loader={<Loader active type={'ball-triangle-path'} color="#9d9dad" />} tag="div" >
      <div className="report-content" ref={componentRef} style={{height: '100%'}}>
        <div id="blockConstiner" style={{display:'none',height: '100%',width:'100%' }} ><BlockUi style={{height:'50px'}} blocking={true} loader={<Loader active type={'ball-triangle-path'} color="#9d9dad" />} tag="div" message="Loading" ></BlockUi></div>
        <div id="bankContainer">
          <div style={{ height: '100%',width:'100%' }}>
            <StockAGGrid 
              ref={{ref1: exportInsideCSV, ref2: exportInsidePrint}} 
              tableData={TableDataRowFormatter(tableData)} 
              columnDefs={columnDefs} 
              setColumnDefs={setColumnDefs}
              stockUIProps={stockUIProps}
              clientEntities={clientEntities}
              batchEntities={batchEntities}
              entities={entities}
            />
          </div>
        </div>
      </div>
      </BlockUi>
    </>
  );
});

export default StockTable;
