import React, { useEffect, useMemo,useState } from "react";
import { Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../../_redux/suppliers/suppliersActions";
import {useSuppliersUIContext} from "../SuppliersUIContext";

export function SupplierDeleteDialog({ id, show, onHide }) {
  // Suppliers UI Context
  const suppliersUIContext = useSuppliersUIContext();
  const suppliersUIProps = useMemo(() => {
    return {
      setIds: suppliersUIContext.setIds,
      queryParams: suppliersUIContext.queryParams
    };
  }, [suppliersUIContext]);
  const [validation,setValidation] = useState()
  // Suppliers Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.suppliers.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => { 
    dispatch(actions.deleteValidation(id,setValidation));
  }, [isLoading, dispatch,id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteSupplier = () => {
    // server request for deleting supplier by id
    dispatch(actions.deleteSupplier(id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchSuppliers(suppliersUIProps.queryParams));
      // clear selections list
      suppliersUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      // onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Supplier Delete
        </Modal.Title>
        <Nav className="ml-auto" onClick={() => onHide()}>
        <Nav.Link className="p-0 text-dark">
          <span className="nav-icon">
            <span className="svg-icon svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                  </g>
                </g>
              </svg>
            </span>
          </span>
        </Nav.Link>
      </Nav>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && validation && (
          <span>Are you sure to permanently delete this supplier?</span>
        )}
        {!validation && <span>You can't delete this supplier. There are Cars supplied by this supplier.</span>}
        {isLoading && validation && <span>Supplier is deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div className="mr-auto">
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          {validation && <button
            type="button"
            onClick={deleteSupplier}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
