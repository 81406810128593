import moment from 'moment'


export const monthSaleFilter = (inProcess,process,duration,startDate,endDate) =>{
    const dates = {
        startDate:duration === "month" ? moment().startOf('month').format():moment(startDate).format(),
        endDate:duration === "month" ? moment().endOf('month').format():moment(endDate).format()
    }
    return {
        filter: {
        category: "",
        batch: "",
        start_date: dates.startDate,
        end_date: dates.endDate,
        inProcess: inProcess,
        processed: process,
        year: "1",
        color: "1",
        group_batch: "",
    },
    sortOrder: "asc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 10, 
    hidden: {
        year: false, 
        color: false, 
        batch: true,
    }}
};

// export const stockFilter = {
//     filter: {
//         category: "",
//         batch: "",
//         location: "",
//         group_batch: "1",
//         year: "1",
//         color: "1"
//     },
//     sortOrder: "asc", // asc||desc
//     sortField: "category",
//     pageNumber: 1,
//     pageSize: 10, 
//     hidden: {
//         year: false, 
//         color: false,
//         category: false, 
//         quantity: false,
//         batch: false,
//     }
// }