import {createSlice} from "@reduxjs/toolkit";

const initialBanksState = {
  bankListLoading: false,
  bankActionsLoading: false,
  bankTotalCount: 0,
  bankEntities: null,
  bankForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const banksSlice = createSlice({
  name: "banks",
  initialState: initialBanksState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.bankListLoading = false;
      } else {
        state.bankActionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.bankListLoading = true;
      } else {
        state.bankActionsLoading = true;
      }
    },
    // getBankById
    bankFetched: (state, action) => {
      state.bankActionsLoading = false;
      state.bankForEdit = action.payload.bankForEdit;
      state.error = null;
    },
    // findBanks
    banksFetched: (state, action) => {
      const { totalCount, bankEntities } = action.payload;
      state.bankListLoading = false;
      state.error = null;
      state.bankEntities = bankEntities;
      state.totalCount = totalCount;
    },
    // createBank
    bankCreated: (state, action) => {
      state.bankActionsLoading = false;
      state.error = null;
      state.bankEntities.push(action.payload.bank);
    },
    // updateBank
    bankUpdated: (state, action) => {
      state.error = null;
      state.bankActionsLoading = false;
      state.bankEntities = state.bankEntities.map(entity => {
        if (entity.id === action.payload.bank.id) {
          return action.payload.bank;
        }
        return entity;
      });
    },
    // deleteBank
    bankDeleted: (state, action) => {
      state.error = null;
      state.bankActionsLoading = false;
      state.bankEntities = state.bankEntities.filter(el => el.id !== action.payload.id);
    },
    // deleteBanks
    banksDeleted: (state, action) => {
      state.error = null;
      state.bankActionsLoading = false;
      state.bankEntities = state.bankEntities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
