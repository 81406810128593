import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./SubscriptionsUIHelpers";

const SubscriptionsUIContext = createContext();

export function useSubscriptionsUIContext() {
  return useContext(SubscriptionsUIContext);
}

export const SubscriptionsUIConsumer = SubscriptionsUIContext.Consumer;

export function SubscriptionsUIProvider({subscriptionsUIEvents, children}) {
  // const { bank_deposit_params } = useSelector(
  //   (state) => ({ bank_deposit_params: state.reports.bank_deposit_params }),
  //   shallowEqual
  // );
  const [queryParams, setQueryParamsBase] = useState( initialFilter);

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return <SubscriptionsUIContext.Provider value={value}>{children}</SubscriptionsUIContext.Provider>;
}