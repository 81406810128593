import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./BankDepositUIHelpers";
import { shallowEqual, useSelector } from "react-redux";

const BankDepositUIContext = createContext();

export function useBankDepositUIContext() {
  return useContext(BankDepositUIContext);
}

export const BankDepositUIConsumer = BankDepositUIContext.Consumer;

export function BankDepositUIProvider({bankDepositUIEvents, children}) {
  const { bank_deposit_params } = useSelector(
    (state) => ({ bank_deposit_params: state.reports.bank_deposit_params }),
    shallowEqual
  );
  const [queryParams, setQueryParamsBase] = useState(bank_deposit_params || initialFilter);

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return <BankDepositUIContext.Provider value={value}>{children}</BankDepositUIContext.Provider>;
}