import axios from "axios";

class Config {
  AUTO_DEALER_API = process.env.REACT_APP_AUTO_DEALER_API;
  ACCESS_TOKEN = "notificationAccessToken";
  EXPIRATION = "notificationTokenExpiration";
  COUNT = `${process.env.REACT_APP_NOTIFICATIONS_API}/count`;
  SEEN = `${process.env.REACT_APP_NOTIFICATIONS_API}/seenByNotificationId`;
  BY_USER_NAME = `${process.env.REACT_APP_NOTIFICATIONS_API}/byUsername`;
  SEENALL = `${process.env.REACT_APP_NOTIFICATIONS_API}/seenByUsername`;

  constructor() {
    if (this.tokenExpired() || this.accessTokenValue() == null) this.getAccessToken();
  }

  headersWithAuthorization() {
    return {
      headers: {
        Authorization: localStorage.getItem(this.ACCESS_TOKEN),
      },
    };
  }

  async getAccessToken() {
    const response = await axios.get(this.AUTO_DEALER_API + "authorization/access-token");
    this.storeAccessToken(response.data);
  }

  async checkExpirationOfToken() {
    if (this.tokenExpired()) await this.getAccessToken();
  }

  tokenExpired() {
    const expDate = Number(localStorage.getItem(this.EXPIRATION));
    if (expDate > Date.now()) {
      return false;
    }
    return true;
  }
  accessTokenValue() {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }

  storeAccessToken(token) {
    localStorage.setItem(this.ACCESS_TOKEN, token);
    localStorage.setItem(this.EXPIRATION, this.getExpiration(token));
  }

  getExpiration(token) {
    let encodedPayload = token ? token.split(".")[1] : null;
    if (encodedPayload) {
      encodedPayload = encodedPayload.replace(/-/g, "+").replace(/_/g, "/");
      const payload = JSON.parse(window.atob(encodedPayload));
      return payload?.exp ? payload?.exp * 1000 : 0;
    }
    return 0;
  }
}

export default Config;
