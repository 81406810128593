// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo,useLayoutEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/cars/carsActions";
import * as tabActions from "../../../_redux/tabs/tabsActions"
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "./../../../../../_metronic/_helpers";
import * as uiHelpers from "../CarsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "./../../../../../_metronic/_partials/controls";
import { useCarsUIContext } from "../CarsUIContext";
import { useSalesUIContext } from "../car-sales/SalesUIContext";
import { isEqual } from "lodash";
import { useProformasUIContext } from "../car-proformas/ProformasUIContext";

const prepareFilter = (queryParams, values) => {
	const { searchText, supplier_id, batch_id, make, model, status, /*price,*/ color } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};
  filter.supplier = supplier_id !== "" ? `${supplier_id}` : undefined;
	filter.batch = batch_id !== "" ? `${batch_id}` : undefined;
	// Filter by make
	filter.make = make !== "" ? make : undefined;
	// Filter by model
	filter.model = model !== "" ? model : undefined;
	// Filter by status
	filter.status = status !== "" ? status : undefined;
	// Filter by price
	// filter.price = price !== "" ? price : undefined;
	// Filter by color
	filter.color = color !== "" ? +color : undefined;
	// Filter by all fields
	if (searchText) {
		filter.searchText = searchText.trim();
	}
	newQueryParams.filter = filter;
	return newQueryParams;
};

export function CarsTable({selectProduct}) {
  // Cars UI Context
  const carsUIContext = useCarsUIContext();
  const carsUIProps = useMemo(() => {
    return {
      ids: carsUIContext.ids,
      setIds: carsUIContext.setIds,
      queryParams: carsUIContext.queryParams,
      setQueryParams: carsUIContext.setQueryParams,
      openEditCarPage: carsUIContext.openEditCarPage,
      openDeleteCarDialog: carsUIContext.openDeleteCarDialog,
    };
  }, [carsUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(carsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, carsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      carsUIProps.setQueryParams(newQueryParams);
    }
  };

  // Proformas UI Context
  const proformasUIContext = useProformasUIContext();
  const proformasUIProps = useMemo(() => {
    return {
      openProformaPrintDialog: proformasUIContext.openProformaPrintDialog,
      openNewProformaDialog: proformasUIContext.openNewProformaDialog,
    };
  }, [proformasUIContext]);

  // Sales UI Context
  const salesUIContext = useSalesUIContext();
  const salesUIProps = useMemo(() => {
    return {
      openNewSaleDialog: salesUIContext.openNewSaleDialog,
      // openContinueSaleDialog: salesUIContext.openContinueSaleDialog
    };
  }, [salesUIContext]);

  // Getting curret state of cars list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state }),
    shallowEqual
  );
  const { totalCount, entities, listLoading,error,apiErrorData} = currentState.cars;
  const {pageName,searchEntities} = currentState.search;
  const {roles} = currentState.auth;
  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }
    }
  },[error])

  useEffect(()=>{
      if(pageName==='car'){
        applyFilter(searchEntities);
      }
  },[])
  // Cars Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    carsUIProps.setIds([]);
    // server call by queryParams
    if(carsUIProps.queryParams?.filter?.initialval)
    {
    
    }else{
    dispatch(actions.fetchCars(carsUIProps.queryParams));
    }
     
    dispatch(tabActions.resetTopTab())
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carsUIProps.queryParams, dispatch]);

  // Table columns
  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    // {
    //   dataField: "location",
    //   text: "Location",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    // {
    //   dataField: "supplier",
    //   text: "Supplier",
    //   sort: false,
    //   sortCaret: sortCaret,
    // },
    // {
    //   dataField: "serial_number",
    //   text: "#",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    
    {
      dataField: 'sl.no',
      text: '#',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      sort: true,
    },
    {
      dataField: 'lable',
      text: '',
      formatter: columnFormatters.ColorColumnFormatter,
      sort: true,
    },
    {
      dataField: "make",
      text: "Make/Brand",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.BrandColumnFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // if(row.saleStatus && row.saleStatus.toLowerCase() !== 'sold') {
            carsUIProps.openEditCarPage(row.id);
          // }
        }
      },
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "color",
      text: "Color",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      // formatter: columnFormatters.ColorColumnFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // if(row.saleStatus && row.saleStatus.toLowerCase() !== 'sold') {
            carsUIProps.openEditCarPage(row.id);
          // }
        }
      },
    },
    {
      dataField: "batch",
      text: "Batch",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // if(row.saleStatus && row.saleStatus.toLowerCase() !== 'sold') {
            carsUIProps.openEditCarPage(row.id);
          // }
        }
      },
    },
    // {
    //   dataField: "import_date",
    //   text: "Import Date",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   classes: "font-weight-bolder text-primary mb-0"
    // },
    // {
    //   dataField: "price",
    //   text: "Price",
    //   sort: true,
    //   sortCaret: sortCaret,
    // },
    {
      dataField: "saleStatus",
      text: "Sale Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.SaleCustomerFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // if(row.saleStatus && row.saleStatus.toLowerCase() !== 'sold') {
            carsUIProps.openEditCarPage(row.id);
          // }
        }
      },
      style: {
        width: '0px'
      }
    },
    // {
    //   dataField: "remark",
    //   text: "Remark",
    //   sort: false,
    //   sortCaret: sortCaret,
    // },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCarPage: carsUIProps.openEditCarPage,
        openDeleteCarDialog: carsUIProps.openDeleteCarDialog,
        openNewSaleDialog: salesUIProps.openNewSaleDialog, 
        // openContinueSaleDialog: salesUIProps.openContinueSaleDialog,
        openProformaPrintDialog: proformasUIProps.openProformaPrintDialog,
        openNewProformaDialog: proformasUIProps.openNewProformaDialog,
        selectProduct: selectProduct, 
        selectTopTab: tabActions.selectTopTab,
        dispatch: dispatch,
        roles:roles
      },
      // classes: "text-right",
      // headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ]; 

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: carsUIProps.queryParams.pageSize,
    page: carsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
           <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden text-nowrap"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  carsUIProps.setQueryParams
                )}
                rowStyle={{ cursor: 'pointer' }}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: carsUIProps.ids,
                //   setIds: carsUIProps.setIds,
                // })}
                {...paginationTableProps}
                noDataIndication={ <NoRecordsFoundMessage entities={entities} /> }
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
           </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
