import React from 'react'
import {CurrencyToWords} from '../../../shared/CurrencyToWords/currencyToWords'

export class ProformaBankReleaseLetterPDF extends React.PureComponent {
    render() {
      return (
    <div className='bodyLetter'>

    <div className="return-address"> 
      
      <time >ቀን {localStorage.date}</time> <br></br>
      <span style={{paddingRight:"5px"}}>ቁጥር፡{localStorage.date.replaceAll("/","").substring(0,4)+localStorage.date.replaceAll("/","").substring(localStorage.date.replaceAll("/","").length - 1) + '/'}{(new Date).getMinutes() <= 9 ? (new Date).getHours()+'0'+(new Date).getMinutes() : (new Date).getHours()+''+(new Date).getMinutes()}</span>
    </div>
    

    <div className='subject'>
    <span className ="font-size-h2" >ለ: {this.props.content?.banks?.name} </span> <br></br> 
    <span className ="font-size-h2">አዲስ አበባ</span> <br></br>
    <span className ="font-size-h2"> <b>ጉዳዩ:-</b> <u>ክፍያን በተመለከተ</u></span>
    </div>
    
    <div className="content text-dark font-weight-bold text-hover-primary font-size-h2"> 
      <p>
        {"የባንካችሁ ደንበኛ የሆኑት "+this.props.content?.customerName+" ከድርጅታችን " + this.props.content?.companyName + " አስመጪ "+ this.props.content?.cars?.productName +" ሞዴል  "+this.props.content?.cars?.modelYear+" የሻንሲ ቁጥር  "+this.props.content?.cars?.chassis+" የሆነውን ተሽከርካሪ ለመግዛት በተስማሙት መሰረት በደብዳቤ የጠየቃችሁንን መስፈርቶች አሟልተን የጨረስን በመሆኑ የሽያጭ ክፍያ ብር "+this.props.content?.priceFmt+".00 ("+CurrencyToWords(this.props.content?.price,'ብር')+") በድርጅታችን ስም በሚገኘው "+this.props.content?.banks?.name+" ቁጥር "+this.props.content?.banks?.account_no+" ገቢ እንዲደረግልን እየጠየቅን ክፍያው እንደተፈጸመ ተሽከርካሪውን የምናስረክባቸው መሆኑን እገልጻለን።"}
      </p>
    </div>

      <p className="greetFoot text-dark font-weight-bold text-hover-primary font-size-h2">
      ከምስጋና ጋር
        <br className="footergreet" /> <br></br>
        <span className ="font-size-h3">{this.props.content?.supplierName} </span> <br></br>
        ዋና ስራ አስኪያጅ
      </p>

  </div>
   );
}
}
