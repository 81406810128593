import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./ColorsUIHelpers";

const ColorsUIContext = createContext();

export function useColorsUIContext() {
  return useContext(ColorsUIContext);
}

export const ColorsUIConsumer = ColorsUIContext.Consumer;

export function ColorsUIProvider({colorsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initColor = {
    id: undefined,
    name: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initColor,
    newColorButtonClick: colorsUIEvents.newColorButtonClick,
    openEditColorDialog: colorsUIEvents.openEditColorDialog,
    openDeleteColorDialog: colorsUIEvents.openDeleteColorDialog,
    openFetchColorsDialog: colorsUIEvents.openFetchColorsDialog,
  };

  return <ColorsUIContext.Provider value={value}>{children}</ColorsUIContext.Provider>;
}