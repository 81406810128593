export default function TableDataRowFormatter(data){
    if(data != undefined &&data!=null && data.length > 0) {
        var objectTest = [];
        for(var i=0;i<data.length;i++) {
            var att = {
                'detail':{
                    "id": data[i].id,
                    'make': data[i].make,
                    'model': data[i].model,
                    'year': data[i].year,
                    'vin': data[i].vin,
                    'customer': data[i].customer,
                    'expected': data[i].expected,
                    'collected': data[i].collected,
                    'remaining': data[i].remaining,
                },
            }
            objectTest.push(att)
        }
        return objectTest;
    }
}
