import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from "react-router-dom";
import $ from 'jquery';
import { useSelector,shallowEqual } from "react-redux";
import { HeaderBottom } from './HeaderBottom';
import { HeaderBottomAdmin } from './HeaderBottomAdmin';
import SearchResult from './SearchResult';
import axios from 'axios';
import { groupArrayByObjKey } from '../pages/shared/util';
import moment from 'moment';
import { getWarehouse } from '../_redux/cars/carsActions';
import { useDispatch } from 'react-redux';
import AlertNotifications from "./notification/alert/AlertNotifications";
import NotificationsApi from './notification/alert/NotificationsApi';

const Header = () => {
	const [searchTxt, setSearchTxt] = useState('')
	const [searchLoading, setSearchLoading] = useState(false)
	const [searchResult, setSearchResult] = useState({})
	const [unseenNotifications, setUnseenNotifications] = useState(-1);

	const handleSearchTxtChange = async (query) => {
		setSearchTxt(query)
		if(query.trim() !== "") {
			setSearchLoading(true)
			const URL = process.env.REACT_APP_AUTO_DEALER_API+'home'
			try {
				const res= await axios.get(
					`${URL}/getSearchData?searchTxt=${query.trim()}&user_name=${localStorage.currentUserEmail}`, {
					headers: {Authorization: jwtToken}
					}
				);
				const searchRes = await res.data || []
				const groupedRes = groupArrayByObjKey(searchRes, searchRes => searchRes?.group)
				setSearchResult(groupedRes)
				setSearchLoading(false)
			} catch (error) {
				setSearchResult({})
				setSearchLoading(false)
			}
		}
		else {
			setSearchResult([])
		}
	}

	const jwtToken = localStorage.getItem("currentUser")
	const customStyles = {
		option: (provided, state) => ({
		  ...provided,
		  borderBottom: '1px dotted pink',
		  color: 'blue',
		  padding: 20,

		}),
		control: () => ({
		  width: 250,
		}),
		singleValue: (provided, state) => {
		  const opacity = state.isDisabled ? 0.5 : 1;
		  const transition = 'opacity 300ms';

		  return { ...provided, opacity, transition };
		},
		indicatorsContainer: (prevStyle, state) =>  ({
			...prevStyle,
			display: 'none'
		}) ,
	}

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getWarehouse());
	}, [])

	// getting unseen notification count
	useEffect(() => {
		async function getData(){
			const notificationApi = new NotificationsApi();
			const count = await notificationApi.getNotificationCount("alert",false);
			setUnseenNotifications(count);
		}
		getData();
	},[])

	const {auth, clientSubscription, warehouse} = useSelector((state) => ({ auth: state.auth, clientSubscription: state.client.clientSubscription, warehouse: state.cars.warehouse}), shallowEqual)
	const {roles} = auth;
	$(window).on("scroll",function() {
		if ($(this).scrollTop() > 200) {
			$('.topbar').css({
				'display': 'none'
			});
		}else{
			$('.topbar').css({
				'display': ''
			});
		}
	});

	const history = useHistory();

	
    return (
		<div id="kt_header" className="header flex-column header-fixed">
			<div className="header-top d-lg-flex fixed-top">
				<div className="container">
					<div className="d-none d-lg-flex align-items-center mr-3">
						<NavLink exact to="/" className="mr-20">
							<img alt="Logo" src="/media/logos/logo-letter-9.png" className="max-h-35px" />
						</NavLink>
						<div className="quick-search quick-search-inline ml-4 w-300px position-relative" id="kt_quick_search_inline">
							<form method="get" className="quick-search-form">
								<div className="input-group rounded bg-light">
									<div className="input-group-prepend">
										<span className="input-group-text">
											<span className="svg-icon svg-icon-lg">
												<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
														<rect x="0" y="0" width="24" height="24" />
														<path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
														<path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
													</g>
												</svg>
											</span>
										</span>
									</div>
									<input
										type="text"
										name="search"
										value={searchTxt}
										onChange={e=>handleSearchTxtChange(e.target.value)}
										id="desktop-search"
										className="form-control h-45px"
										placeholder="Search..."
										autoComplete='off'
									/>
									<div className={`input-group-append ${searchLoading ? 'spinner spinner-sm spinner-primary' : ''}`} id="desktop-spinner">
										<span className="input-group-text">
											<i className={`quick-search-close ki ki-close icon-sm ${(searchTxt && !searchLoading) ? 'd-flex' : 'd-none'}`}
												onClick={() => handleSearchTxtChange("")}
											></i>
										</span>
									</div>
								</div>
							</form>
							<div id="kt_quick_search_toggle" data-toggle="dropdown" data-offset="0px,1px"></div>
							<div className={`dropdown-menu dropdown-menu-left dropdown-menu-lg dropdown-menu-anim-up ${(Object.keys(searchResult)?.length > 0 && !searchLoading) ? 'show' : ''}`} id="desktop-dropdown">
								<div className="quick-search-wrapper scrollable d-block" data-scroll="true" data-height="350" data-mobile-height="200">
									<SearchResult searchResults={searchResult} handleSearchTxtChange={handleSearchTxtChange} />
								</div>
							</div>
						</div>
					</div>

					<div className="topbar d-flex justify-content-between">
						<div className="topbar-item d-block d-lg-none">
							<div className="btn btn-icon btn-hover-transparent-white w-auto d-flex align-items-center btn-lg px-2">
								<div className="d-flex flex-column text-right pr-3">
									<NavLink exact to="/" className="mr-20">
										<img alt="Logo" src="/media/logos/logo-letter-9.png" className="max-h-20px" />
									</NavLink>
								</div>
							</div>
						</div>
						<div className="d-flex">
							<div className="dropdown d-flex d-lg-none">
								<div className="topbar-item" data-toggle="dropdown" data-offset="10px,0px">
									<div className="btn btn-icon btn-hover-transparent-white btn-lg btn-dropdown mr-1">
										<span className="svg-icon svg-icon-xl">
											<svg  xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
												<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
													<rect x="0" y="0" width="24" height="24" />
													<path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
													<path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
												</g>
											</svg>

										</span>
									</div>
								</div>
								<div className={`dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg`} id="mobile-dropdown">
									<div className={`quick-search quick-search-dropdown ${(Object.keys(searchResult)?.length > 0 && !searchLoading) ? 'quick-search-has-result' : ''}`} id="kt_quick_search_dropdown">
										<form method="get" className="quick-search-form">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text">
														<span className="svg-icon svg-icon-lg">
															<svg  xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																	<rect x="0" y="0" width="24" height="24" />
																	<path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
																	<path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
																</g>
															</svg>

														</span>
													</span>
												</div>
												<input
													type="text"
													name="search"
													value={searchTxt}
													onChange={e=>handleSearchTxtChange(e.target.value)}
													id="mobile-search"
													className="form-control"
													placeholder="Search..."
													autoComplete='off'
												/>
												{/* <div className="input-group-append" id="mobile-spinner">
													<span className="input-group-text justify-content-center">
														<i className="quick-search-close ki ki-close icon-sm text-muted"
															onClick={() => handleSearchTxtChange("")}
														></i>
													</span>
												</div> */}
												<div className={`input-group-append ${searchLoading ? 'spinner spinner-sm spinner-primary' : ''}`} id="mobile-spinner">
													<span className="input-group-text">
														<i className={`quick-search-close ki ki-close icon-sm ${(searchTxt && !searchLoading) ? 'd-flex' : 'd-none'}`}
															onClick={() => handleSearchTxtChange("")}
														></i>
													</span>
												</div>
											</div>
										</form>
										<div className={`quick-search-wrapper scrollable ${(Object.keys(searchResult)?.length > 0 && !searchLoading) ? 'd-block' : ''}`} data-scroll="true" data-height="325" data-mobile-height="200">
											<SearchResult searchResults={searchResult} handleSearchTxtChange={handleSearchTxtChange} />
										</div>
									</div>
								</div>
							</div>

							<div className="dropdown" id="header-notification-dropdown">
								<div className="topbar-item" data-toggle="dropdown" data-offset="10px,0px" aria-expanded="true">
									<div id="header-notification-trigger" className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-white">
										<span className="svg-icon svg-icon-xl">
											<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
												<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
													<path d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z" fill="#000000"/>
													<rect fill="#000000" opacity="0.3" x="10" y="16" width="4" height="4" rx="2"/>
												</g>
											</svg>
										</span>
										<span className={(unseenNotifications > 0 || warehouse?.some((w) => moment(w.warehouse_expiration_date).diff(moment().format("YYYY-MM-DD"), "M", true) <= 2)) && "pulse-ring"}></span>
											{unseenNotifications > 0 && <span className="label label-sm label-danger ml-2" style={{position: 'absolute', top: '1px', right: '0px', backgroundColor: '#d54b5a', width: '14.5px', height: '14.5px'}}>{unseenNotifications}</span>}
									</div>
								</div>
								<div id="header-notification" className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
									<AlertNotifications setUnseenNotifications={setUnseenNotifications}/>
								</div>
							</div>

							<div className="topbar-item">
								<div className="btn btn-icon btn-hover-transparent-white w-auto d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
									<div className="d-flex flex-column text-right pr-3">
										<span className="text-white font-weight-bolder font-size-sm  d-md-inline">{localStorage.currentUserEmail}</span>
									</div>
								</div>
							</div> 
						</div>
						{/* <div className="topbar-item d-none d-lg-flex">
							<button type="submit" className="btn btn-primary" onClick={()=>{authenticationService.logout()}}>Log Out</button>
						</div> */}
					</div>
				</div>
			</div>

			<div className="header-bottom">
				<div className="container">
					{roles && roles.includes("ADMINUSERROLE") ? 
						<HeaderBottomAdmin /> : 
						<HeaderBottom/>
					}
				</div>
			</div>

			{clientSubscription && (clientSubscription?.remaining_days > 0 ? (
				<div className='text-center bg-white'>
					{clientSubscription?.remaining_days <= 5 ? (
						<div className="my-1 py-2 text-danger subscription-alert">
							<div className="container">
								{clientSubscription?.sub_plan_id === 2 ? (
									<div>Your subscription ends in {clientSubscription?.remaining_days} days. Please renew to continue using the system. Call support +251 116662057/58/59.</div>
								) : (
									<div>Your trial plan expires in {clientSubscription?.remaining_days} days. Please subscribe to continue using the system. Call support +251 116662057/58/59.</div>
								)}
							</div>
						</div>
					) : (
						<div className="my-1 text-warning">
							<div className="container">
								{clientSubscription?.sub_plan_id === 1 && (
									<div className='bg-white subscription-alert'>Trial version - {clientSubscription?.remaining_days} days remaining.</div>
								)}
							</div>
						</div>
					)}
				</div>
			) : (
				<div className='text-center bg-white'>
					<div className="my-1 text-danger">
						<div className="container">
							<div className='bg-white subscription-alert'>Your {clientSubscription?.sub_plan_id === 1 ? 'Trial' : 'Subscription'} has expired.</div>
						</div>
					</div>
				</div>
			))}				
		</div>
    )
}

export default Header
