import moment from "moment";

export const defaultSorted = [{ dataField: "id", order: "asc" }];
const date = new Date();
// let startDate = new Date(date.getFullYear(), date.getMonth(), 1)
// startDate = (startDate instanceof Date) ? `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate() < 10 ? '0'+startDate.getDate():startDate.getDate()}` : startDate;
const endDate = moment()
const startDate = moment().startOf('month')

export const initialFilter = {
    filter: {
        start_date: startDate,
        end_date: endDate, 
        active: true, 
        sub_plan_id: 0,
        
    },
    sortOrder: "asc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 10
};
