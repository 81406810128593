/* eslint-disable no-restricted-imports */
import React, { useMemo } from "react";
import { Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux_admin/clients_adm/clientsActions";
import { useTenantsUIContext } from "./TenantsUIContext";

export function TenantStatusModal() {
  // Sales UI Context
  const specsUIContext = useTenantsUIContext();
  const specsUIProps = useMemo(() => {
    return {
      show: specsUIContext.showTSD,
      onHide: specsUIContext.hideTenantStatusDialog, 
      id:specsUIContext.id,
      active:specsUIContext.active
    };
  }, [specsUIContext]);

  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.tenants.tenantActionsLoading,
     
    }),
    shallowEqual
  );
 
  const dispatch = useDispatch();
  const activateDeactivateTenant = () => {
     dispatch(actions.activateDeactivateTenant(specsUIProps.id)).then(() => {
      dispatch(actions.fetchTenants());
      specsUIProps.onHide();
    });
  };

  

  return (
    
      <Modal
      show={specsUIProps.show}
      aria-labelledby="example-modal-sizes-title-lg"
      >
        {isLoading && <ModalProgressBar variant="query" />}
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Activate / Deactivate
          </Modal.Title>
          <Nav className="ml-auto" onClick={() => specsUIProps.onHide()}>
          <Nav.Link className="p-0 text-dark">
            <span className="nav-icon">
              <span className="svg-icon svg-icon-2x">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                        <rect x="0" y="7" width="16" height="2" rx="1"/>
                        <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </span>
          </Nav.Link>
        </Nav>
        </Modal.Header>
        <Modal.Body>
          {!isLoading && !specsUIProps.active &&  (
            <p>Do you want activate this client?</p>
          )}
          {!isLoading && specsUIProps.active &&  (
            <p>Do you want deactivate this client?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="mr-auto">
          <button
              type="button"
              onClick={activateDeactivateTenant}
              className="btn btn-primary btn-elevate"
            >
              Yes
            </button>
            <> </>
            <button
              type="button"
              onClick={() => specsUIProps.onHide()}
              className="btn btn-light btn-elevate"
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    
  );
}
