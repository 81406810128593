// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,


} from "../../../../../../_metronic/_partials/controls";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
// import Multiselect from "multiselect-react-dropdown";
import AsyncMultiSearchBar from "../../../shared/AsyncMultiSelectBar";

// Validation schema
const GroupEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Name is required")
    .test(
      'duplicateName',
      'Duplicate Role Name',
      function(value){
            return this.parent.groups?.filter(
              (item)=>{
                return item.name == value && item.id != this.parent.id
              }
            ).length == 0
        }
      ),
  
});

export function GroupEditForm({
  saveGroup,
  group,
  actionsLoading,
  onHide,
  openDeleteGroupDialog,
  roles,
  selectedRoles
}) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{...group, role_id_name: selectedRoles, role_id: selectedRoles?.map((r) => r.id)}}
        validationSchema={GroupEditSchema}
        onSubmit={(values) => {
          saveGroup(values);
        }}
      >
        {({ handleSubmit,values,isSubmitting,setFieldValue, setFieldTouched, touched }) => (
          <BlockUi blocking={isSubmitting}>
            <Modal.Body className="overlay  cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Name */}
                  
                  <div className="col-lg-10">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      withFeedbackLabel= {true}
                      required
                      disabled={values.name==='Admin_Group' ? true:false}
                    />
                  </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-10">
                      <label  htmlFor="roles">Roles </label>
                      {/* <Multiselect
                        id="roles"
                        options={roles}
                        displayValue="name"
                        selectedValues={selectedRoles}
                        hidePlaceholder = {true}
                        onSelect={(e)=>setFieldValue('roles',e)}
                        onRemove = {(e)=>setFieldValue('roles',e)}
                        emptyRecordMsg='No Roles Avaliable'
                        disablePreSelectedValues = {values.name==='Admin_Group' ? true : false}
                        //style={locationValidation &&  {searchBox : {borderColor: 'red'}}}
                          /> */}
                        {/* {locationValidation && <div className="invalid-feedback d-block">Location is Required</div>} */} 
                      <AsyncMultiSearchBar
                        isMulti={true}
                        selectedItem={{id: values.roles}} 
                        setSelectedItem={setFieldValue}
                        setTouchedItem={setFieldTouched}
                        touched={touched.role_id}
                        item_id="role_id"
                        item_name="name"
                        options={roles}
                        clear={values.roles === []}
                        catchDefaultName={true}
                        val={values?.role_id_name}
                      />
                    </div>
                  </div>
                  
                
              </Form>
            </Modal.Body>
            <Modal.Footer>
            { values.name != 'Admin_Group' &&
            <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              }
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate mr-auto"
              >
                Cancel
              </button>
              { values.id != undefined && values.name != 'Admin_Group' && 
              <button
                type="button"
                onClick={()=>{openDeleteGroupDialog(values.id)}}
                className="btn btn-danger btn-elevate ml-auto"
              >
                Delete
              </button>}

              <> </>
              
            </Modal.Footer>
          </BlockUi>
        )}
      </Formik>
    </>
  );
}
