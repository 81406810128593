import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import $ from 'jquery';
import { useSelector,shallowEqual } from "react-redux";

export const HeaderBottomAdmin = () => {
    const {auth} = useSelector((state) => ({ auth: state.auth }),
		shallowEqual)
	const {roles} = auth;	
	
	useEffect(()=>{
		$('.menu-link.menu-child').off("click").on("click",function(){
			$('.menu-parent-child').removeClass('active')
			$(this).parents('.menu-parent').children('.menu-parent-child').addClass('active')
		})
		$('.menu-link.menu-remove-active').off("click").on("click",function(){
			$('.menu-parent-child').removeClass('active')
		})

	

	},[])
  return (
    <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
									
									<div id="kt_header_menu" className="header-menu header-menu-left header-menu-mobile header-menu-layout-default">
										<div className="d-flex justify-content-end d-lg-none">
											<div className="menu-item menu-item-submenu menu-item-rel py-0 px-3 pt-3" data-menu-toggle="hover" aria-haspopup="true">
												<NavLink exact to="#" className="menu-link">
													<span className="nav-icon">
														<span className="svg-icon svg-icon-2x">
															<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
															<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
																	<rect x="0" y="7" width="16" height="2" rx="1"/>
																	<rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
																</g>
															</g>
															</svg>
														</span>
													</span>
												</NavLink>
											</div>
										</div>
										<ul className="menu-nav pt-0">
											<li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="hover" aria-haspopup="true">
												<NavLink exact to="/dashboard" className="menu-link menu-remove-active">
													<span className="menu-text">Admin Dashboard</span> 
													{/* <span className="menu-desc">Recent Updates &amp; Reports</span>
													<i className="menu-arrow menu-toggle"></i>  */}
												</NavLink>
											</li>
											<li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="hover" aria-haspopup="true">
												<NavLink exact to="/clients" className="menu-link menu-remove-active">
													<span className="menu-text">Clients</span> 
													{/* <span className="menu-desc">Recent Updates &amp; Reports</span>
													<i className="menu-arrow menu-toggle"></i>  */}
												</NavLink>
											</li>
											<li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="hover" aria-haspopup="true">
												<NavLink exact to="/usersAdmin" className="menu-link menu-remove-active">
													<span className="menu-text">Admin Users</span> 
													{/* <span className="menu-desc">Recent Updates &amp; Reports</span>
													<i className="menu-arrow menu-toggle"></i>  */}
												</NavLink>
											</li>
											<li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="hover" aria-haspopup="true">
												<NavLink exact to="#"  activeClassName='' className="menu-link menu-remove-active">
													<span className="menu-text">Reports</span> 
													{/* <span className="menu-desc">Recent Updates &amp; Reports</span>
													<i className="menu-arrow menu-toggle"></i>  */}
												</NavLink>
												<div className="menu-submenu menu-submenu-classic menu-submenu-left">
													<ul className="menu-subnav">
														
														<li className="menu-item menu-item-submenu"  data-menu-toggle="hover" aria-haspopup="true">
															<NavLink exact to="/reportsAdmin/subscription" id="subscriptions" className="menu-link menu-child">
																<span className="menu-text">Subscription</span>														
															</NavLink>
														</li>
													</ul>
												</div>
											</li>
											<li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="hover" aria-haspopup="true">
												<NavLink exact to="/logAdmin" className="menu-link menu-remove-active">
													<span className="menu-text">Logs</span> 
													
												</NavLink>
											</li>
										</ul>
										
									</div>
									
								</div>
  )
}
