import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./LocationsUIHelpers";

const LocationsUIContext = createContext();

export function useLocationsUIContext() {
  return useContext(LocationsUIContext);
}

export const LocationsUIConsumer = LocationsUIContext.Consumer;

export function LocationsUIProvider({locationsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initLocation = {
    id: undefined,
    name: "",
    telephone: "",
    location_type_id: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initLocation,
    newLocationButtonClick: locationsUIEvents.newLocationButtonClick,
    openEditLocationDialog: locationsUIEvents.openEditLocationDialog,
    openDeleteLocationDialog: locationsUIEvents.openDeleteLocationDialog,
    openFetchLocationsDialog: locationsUIEvents.openFetchLocationsDialog,
  };

  return <LocationsUIContext.Provider value={value}>{children}</LocationsUIContext.Provider>;
}