import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./GroupsUIHelpers";

const GroupsUIContext = createContext();

export function useGroupsUIContext() {
  return useContext(GroupsUIContext);
}

export const GroupsUIConsumer = GroupsUIContext.Consumer;

export function GroupsUIProvider({groupsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initGroup = {
    id: undefined,
    name: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initGroup,
    newGroupButtonClick: groupsUIEvents.newGroupButtonClick,
    openEditGroupDialog: groupsUIEvents.openEditGroupDialog,
    openDeleteGroupDialog: groupsUIEvents.openDeleteGroupDialog,
    openFetchGroupsDialog: groupsUIEvents.openFetchGroupsDialog,
  };

  return <GroupsUIContext.Provider value={value}>{children}</GroupsUIContext.Provider>;
}