import React,{useMemo,useRef,useState,useEffect} from 'react'
import { useProformasUIContext } from '../ProformasUIContext'
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import NumberFormat from 'react-number-format';
import { useSelector,shallowEqual, useDispatch } from 'react-redux';
import * as bankActions from '../../../../_redux/banks/banksActions'
import * as actions from '../../../../_redux/proformas/proformasActions'
import {
    Input
  } from "../../../../../../_metronic/_partials/controls";
import AsyncSearchBar from '../../../shared/AsyncSelectBar';
import * as Yup from "yup";
import ReactToPrint from 'react-to-print';
import {ProformaContractOfSale} from './ProformaContractOfSale';
import ConstractOfSalesWord from './profroma-MSword/ContractOfSales'
import $ from 'jquery'

export default function ContractOfSaleModal() {

const ContractOfSaleSchema = Yup.object().shape({
  companyName: Yup.string().required("Company is required"),
  supplierName: Yup.string().required("Seller is required"),
  customerName: Yup.string().required("Customer is required"),
  price:Yup.string().required("Price is required"),
  advance:Yup.string().required("Advance is required"),
  cars: Yup.object().nullable().required("car is required").test(
    'carSelected',
    'Car is required',
    function(value){
          return value?.id != undefined && value?.id > 0
      }
    ),
  banks: Yup.object().nullable().required("bank is required").test(
    'bankSelected',
    'bank is required',
    function(value){
          return value?.id != undefined && value?.id > -2
      }
    )
});
const proformasUIContext = useProformasUIContext();
  const proformasUIProps = useMemo(() => {
    return {
        ids: proformasUIContext.ids,
        selectedId: proformasUIContext.selectedId,
        carId: proformasUIContext.carId,
        show:proformasUIContext.showProformaConstractOfSale,
        onHide: proformasUIContext.closePrintMenuDialog,
        showEditProformaDialog:proformasUIContext.showEditProformaDialog,
        showProformaPrintDialog:proformasUIContext.showProformaPrintDialog, 
        setActiveTab: proformasUIContext.setActiveMenuTab, 
        closeBankRelease: proformasUIContext.closeProformaBankReleaseLetter,
        closeCashContractOfSale: proformasUIContext.closeProformaCashConstractOfSale,
        closeContractOfSale: proformasUIContext.closeProformaConstractOfSale,
    };
  }, [proformasUIContext]);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(bankActions.fetchBanks())
  },[])
  useEffect(()=>{
    if(!proformasUIProps.showEditProformaDialog && !proformasUIProps.showProformaPrintDialog)
    dispatch(actions.fetchProforma(proformasUIProps.selectedId,proformasUIProps.carId));
  },[proformasUIProps.selectedId])
  
  const { currentState } = useSelector(
    (state) => ({ currentState: state }),
    shallowEqual
  );
 
  
 const [valuesToGo,setValuesToGo] = useState(null);
 const {proformaItems,proformaForEdit} = currentState.proformas;
 const {clientEntities} = currentState.client;
 const {bankEntities:banks} = currentState.banks
 const componentRef = useRef();
 const handleOnBeforeGetContent = (values) => {
  setValuesToGo(values)
  return new Promise((resolve) => {});
  };
  const handlePdf = (values) => {
    if(Object.keys(values.cars).length > 0 &&
      values.advance !== "" && values.price !== "" &&
      values.customerName !== "" && values.supplierName !== ""
      && values.companyName !== "")
    $('#printButton').trigger('click')
  }
  const handleDocx = (values) =>{
    if(Object.keys(values.cars).length > 0 &&
      values.advance !== "" && values.price !== "" &&
      values.customerName !== "" && values.supplierName !== ""
      && values.companyName !== "")
    $('#docxButton').trigger('click')
  }

  return (
    <>
    {/* <Modal
      show={proformasUIProps.show}
      // onHide={specsUIProps.onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    > */}
      
      <Modal.Header closeButton  className='px-0'>
        <Modal.Title id="example-modal-sizes-title-lg">
          Contract Of Sale (Bank)
        </Modal.Title>
        {/* <Nav className="ml-auto" onClick={() => proformasUIProps.onHide()}>
        <Nav.Link className="p-0 text-dark">
          <span className="nav-icon">
            <span className="svg-icon svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                  </g>
                </g>
              </svg>
            </span>
          </span>
        </Nav.Link>
      </Nav> */}
      </Modal.Header>
      
      <Formik
        enableReinitialize={true}
        initialValues={{companyName:clientEntities?.shortName,
                        supplierName:clientEntities?.contact_person,
                        customerName:proformaForEdit?.customer?.name,
                        cars: proformaItems === null || proformaItems === undefined ? {} : proformaItems[0],
                        banks:banks === null || banks === undefined ? {} : banks[0],
                        price: proformaItems === null || proformaItems === undefined ? "" :proformaItems[0]?.sales_amount,
                        priceFmt: (proformaItems === null || proformaItems === undefined ? "" :proformaItems[0]?.sales_amount)?.toLocaleString(),
                        advance:0, 
                        advanceFmt: (0)?.toLocaleString()
                      }}
        validationSchema={ContractOfSaleSchema}
        
      >
        {({ handleSubmit,values,isSubmitting, setFieldValue,setFieldTouched,errors,touched }) => (
          <>
            <Modal.Body className="overlay cursor-default px-0 pb-0">
              
              <Form className="form form-label-right">
                <div className="form-group row d-flex align-items-center">
                  <label htmlFor="companyName" className="col-3 col-md-2 col-form-label">Company's Name <span className="text-danger">*</span></label>
                  <div className="col-8 col-md-9 offset-1">
                    <Field
                      name="companyName"
                      component={Input}
                      placeholder="Company's Name"
                      // label="Company's Name"
                      withFeedbackLabel= {true}
                      required
                    />
                  </div>
                </div>

                <div className="form-group row d-flex align-items-center">
                  <label htmlFor="supplierName" className="col-3 col-md-2 col-form-label">Seller's Name <span className="text-danger">*</span></label>
                  <div className="col-8 col-md-9 offset-1">
                    <Field
                      name="supplierName"
                      component={Input}
                      placeholder="Seller's Name"
                      // label="Seller's Name"
                      withFeedbackLabel= {true}
                      required
                    />
                  </div>
                </div>

                <div className="form-group row d-flex align-items-center">
                  <label htmlFor="customerName" className="col-3 col-md-2 col-form-label">Customer's Name <span className="text-danger">*</span></label>
                  <div className="col-8 col-md-9 offset-1">
                    <Field
                      name="customerName"
                      component={Input}
                      placeholder="Customer's Name"
                      // label="Customer's Name"
                      withFeedbackLabel= {true}
                      required
                    />
                  </div>
                </div>

                <div className="form-group row d-flex align-items-center">
                  <label htmlFor="car" className="col-3 col-md-2 col-form-label">Car <span className="text-danger">*</span></label>
                  <div className="col-8 col-md-9 offset-1">
                    {proformaItems && 
                    <AsyncSearchBar
                      //selectedItem={{id: values.status_id, name: statuses.find(p => p.id == values.status_id)?.name}} 
                      setSelectedItem={setFieldValue}
                      setTouchedItem={setFieldTouched}
                      defaultItem={proformaItems[0]?.productName}
                      error={errors.cars}
                      touched={touched.cars}
                      item_id="cars"
                      item_name="productName"
                      options={proformaItems}
                      isSearchable={false}
                      isObject={true}
                    />}
                    {errors.cars && touched.cars && (
                      <div className="invalid-feedback d-block">{errors.cars}</div>
                    )}
                  </div>
                </div>

                <div className="form-group row d-flex align-items-center">
                  <label htmlFor="bank" className="col-3 col-md-2 col-form-label">Bank <span className="text-danger">*</span></label>
                  <div className="col-8 col-md-9 offset-1">
                    {banks && 
                    <AsyncSearchBar 
                      //selectedItem={{id: values.bank_id}} 
                      setSelectedItem={setFieldValue}
                      setTouchedItem={setFieldTouched}
                      defaultItem={banks[0]?.bankName}
                      error={errors.banks}
                      touched={touched.banks}
                      item_id="banks"
                      item_name="bankName"
                      options={banks}
                      isObject={true}
                    />}
                    {errors.banks && touched.banks && (
                      <div className="invalid-feedback d-block">{errors.banks}</div>
                    )}
                  </div>
                </div>

                <div className="form-group row d-flex align-items-center">
                  <label htmlFor="price" className="col-3 col-md-2 col-form-label">Price <span className="text-danger">*</span></label>
                  <div className="col-8 col-md-9 offset-1">
                    <NumberFormat
                      id="price"
                      onValueChange={val => {
                        setFieldValue("price", val.value)
                        setFieldValue("priceFmt", val.formattedValue)
                      }}
                      onBlur={() => {
                        setFieldTouched("price", true, true)
                      }}
                      value = {values.price}
                      className={(
                        errors.price && touched.price
                        ) ? "form-control is-invalid" : "form-control"
                      }
                      thousandSeparator={true}
                    />
                    {errors.price && touched.price && (
                      <div className="invalid-feedback d-block">{errors.price}</div>
                    )}
                  </div>
                </div>     

                <div className="form-group row d-flex align-items-center">
                  <label htmlFor="advance" className="col-3 col-md-2 col-form-label">Advance</label>
                  <div className="col-8 col-md-9 offset-1">
                    <NumberFormat
                      id="advance"
                      onValueChange={val => {
                        setFieldValue("advance", val.value)
                        setFieldValue("advanceFmt", val.formattedValue)
                      }}
                      onBlur={() => {
                        setFieldTouched("advance", true, true)
                      }}
                      value = {values.advance}
                      className={(
                        errors.advance && touched.advance
                        ) ? "form-control is-invalid" : "form-control"
                      }
                      thousandSeparator={true}
                    />
                    {errors.advance && touched.advance && (
                      <div className="invalid-feedback d-block">{errors.advance}</div>
                    )}
                  </div>
                </div>    
              </Form>
            </Modal.Body>
      <Modal.Footer className='px-0'>
      <>
      <div className="dropdown dropdown-inline ">
                <button type="button" className="btn btn-light-primary font-weight-bolder dropdown-toggle btn-elevate  " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="svg-icon svg-icon-md">							
                        <i className="flaticon-download"></i>
                    </span>
                    Export
                </button>
                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right" >
                    <ul className="navi flex-column navi-hover py-2">
                        <li className="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">Choose an option:</li>
                        <li className="navi-item">
                            <a className="navi-link"
                            onClick={()=>{handleSubmit();handleDocx(values)}}><span className="navi-icon">
                                <i className="flaticon2-document"></i>
                            </span>
                            <span className="navi-text">Docx</span></a>
                            
                        </li>
                        <li className="navi-item">
                            <a className="navi-link"
                            onClick={()=>{handleSubmit();handlePdf(values)}}><span className="navi-icon">
                                <i className="la la-print"></i>
                            </span>
                            <span className="navi-text">Pdf</span></a>
                            
                        </li>
                    </ul>
                </div>
        </div>
        {/* <button type="submit"  className="bg-transparent border-0 px-0 mr-3 text-primary" onClick={()=>
          {handleSubmit();handleDocx(values)}} >
            <img src="/export-excel.png"/>
        </button>
        <button className=" btn btn-default bg-transparent border-0  text-danger " onClick={()=>
          {handleSubmit();handlePdf(values)}} >
            <img src="/export-pdf.png"/>
        </button> */}
        <ConstractOfSalesWord  content={values} proforma={proformaForEdit} />
        <ReactToPrint
          onBeforeGetContent={
            ()=>{
              handleOnBeforeGetContent(values)
            }
          }
          trigger={() => {
           
          return <button
          type="submit"
          id="printButton"
          hidden
          className="btn btn-primary btn-elevate "
          >
          Print
        </button>
          }}
          
          content={() => componentRef.current}
        />
        
        <button
            type="button"
            onClick={() => {
              proformasUIProps.setActiveTab("proforma");
              proformasUIProps.closeBankRelease();
              proformasUIProps.closeContractOfSale();
              proformasUIProps.closeCashContractOfSale();
              proformasUIProps.onHide();
            }}
            className="btn btn-light btn-elevate mr-auto"
            
          >
            Cancel
          </button>
        <div style={{ display: "none" }}>
          
          <ProformaContractOfSale ref={componentRef} content={values} proforma={proformaForEdit} />
        </div>
      </>
          
          {/* <ProformaPrintContractTrigger submitEvent={handleSubmit} cancleEvent={proformasUIProps.onHide}></ProformaPrintContractTrigger>
           */}
       
      </Modal.Footer>  
      </>  
       )}
      </Formik>
    {/* </Modal> */}
    </>
  )
}
