import React from 'react'

export default function NotFoundPage() {
  return (
    <div class="d-flex flex-column flex-root">
       
        <div class="d-flex flex-row-fluid bgi-size-cover bgi-position-center" style={{backgroundImage: "url('/media/error/bg5.jpg') "}}>
          
        <div class="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
					<h1 class="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12">Oops!</h1>
					<p class="font-weight-boldest display-4">Page can not be found.</p>
					
				</div>
         
        </div>
       
      </div>
  )
}
