import React, {createContext, useContext} from "react";

const ReportsUIContext = createContext();

export function useCarsUIContext() {
  return useContext(ReportsUIContext);
}

export const ReportsUIConsumer = ReportsUIContext.Consumer;

export function ReportsUIProvider({reportsUIEvents, children}) {
  const value = {
    openStockReportPage: reportsUIEvents.openSubscriptionReportPage,
  };

  return <ReportsUIContext.Provider value={value}>{children}</ReportsUIContext.Provider>;
}