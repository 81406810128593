import {createSlice} from "@reduxjs/toolkit";

const initialBatchState = {
  batchListLoading: false,
  batchActionsLoading: false,
  batchTotalCount: 0,
  batchEntities: null,
  batchForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const batchSlice = createSlice({
  name: "batch",
  initialState: initialBatchState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.batchListLoading = false;
      } else {
        state.batchActionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.batchListLoading = true;
      } else {
        state.batchActionsLoading = true;
      }
    },
    // getBatchById
    batchFetchedById: (state, action) => {
      state.batchActionsLoading = false;
      state.batchForEdit = action.payload.batchForEdit;
      state.error = null;
    },
    // findBatch
    batchFetched: (state, action) => {
      const { totalCount, batchEntities } = action.payload;
      state.batchListLoading = false;
      state.error = null;
      state.batchEntities = batchEntities;
      state.totalCount = totalCount;
    },
    // createBatch
    batchCreated: (state, action) => {
      state.batchActionsLoading = false;
      state.error = null;
      state.batchEntities.push(action.payload.batch);
    },
    // updateBatch
    batchUpdated: (state, action) => {
      state.error = null;
      state.batchActionsLoading = false;
      state.batchEntities = state.batchEntities.map(entity => {
        if (entity.id === action.payload.batch.id) {
          return action.payload.batch;
        }
        return entity;
      });
    },
    // deleteBatch
    batchDeleted: (state, action) => {
      state.error = null;
      state.batchActionsLoading = false;
      state.batchEntities = state.batchEntities.filter(el => el.id !== action.payload.id);
    },
    
  }
});
