// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone, 
  PaginationTotalStandalone
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "./SalesUIHelper";
import { PaginationLinks } from "./../../../../../_metronic/_partials/controls";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "./../../../../../_metronic/_helpers";
import { useSalesUIContext } from "./SalesUIContext";
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment'


export function SalesTable({ salesTotalCount, saleEntities, listLoading}) {
  // Sales UI Context
  const salesUIContext = useSalesUIContext();
  const salesUIProps = useMemo(() => {
    return {
      queryParams: salesUIContext.queryParams,
      setQueryParams: salesUIContext.setQueryParams,
      openEditSaleDialog: salesUIContext.openEditSaleDialog,
      openDeleteSaleDialog:
        salesUIContext.openDeleteSaleDialog,
      ids: salesUIContext.ids,
      setIds: salesUIContext.setIds,
      productId: salesUIContext.productId,
    };
  }, [salesUIContext]);

  const [tableData,setTableData] = useState(null)

/* 
  const dispatch = useDispatch();
  useEffect(() => {
    if(carId != undefined && carId != null){
      dispatch(actions.fetchSalesInCar(carId));
    }
    
  }, [salesUIContext.queryParams, dispatch]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.cars.carForEdit}),
    shallowEqual
  );
*/

  useEffect(()=>{
    setTableData(cloneDeep(saleEntities) || [])
  },[saleEntities])

  const columns = [
    
    
    {
      dataField: "payment_date",
      text: "Date",
      sort: true,
      classes: "text-nowrap",
      sortCaret: sortCaret,
      footerClasses: "bottomNone",
      formatter: (cellContent, row) => (
        <span>
          {moment(row.payment_date).format("DD/MM/yyyy")}
        </span>
      ),
    },
    {
      dataField: "payment_type",
      text: "Payment Type",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>{Math.abs(row.amount).toLocaleString()}</>
      )
    },
    {
      dataField: "bank",
      text: "Bank",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "user_name",
      text: "User",
      sort: true,
      sortCaret: sortCaret,
    },
    // {
    //   dataField: "remark",
    //   text: "Remark",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: RemarkColumnFormatter,
    // },
    // {
    //   dataField: "action",
    //   text: "Actions",
    //   formatter: ActionsColumnFormatter,
    //   formatExtraData: {
    //     openEditSaleDialog: salesUIProps.openEditSaleDialog,
    //     openDeleteSaleDialog: salesUIProps.openDeleteSaleDialog,
    //   },
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    // totalSize: salesTotalCount != undefined ? salesTotalCount : 0,
    totalSize: saleEntities != undefined ? saleEntities?.length : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: salesUIProps.queryParams.pageSize,
    // page: salesUIProps.queryParams.pageNumber,
    page: (salesUIProps.queryParams.pageNumber - 1) < (tableData?.length/salesUIProps.queryParams.pageSize) ? salesUIProps.queryParams.pageNumber : 
    salesUIProps.queryParams.pageNumber == 1 ?  salesUIProps.queryParams.pageNumber:salesUIProps.queryParams.pageNumber - 1  ,
  };

  function pageHandler (paginationProps){
    const pagenumber = paginationProps?.page || 1
    const sizePerPage = paginationProps?.sizePerPage
    salesUIProps.setQueryParams(
      (prev) =>
      "pagination" === "pagination"
        ? { ...prev, pageNumber:pagenumber, pageSize: sizePerPage }
        : prev
    )    
  }

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            // <Pagination>
            <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bordered={false}
                bootstrap4
                // remote
                keyField="id"
                // data={saleEntities === null || saleEntities == undefined || saleEntities.length == 0 ? [] : saleEntities}
                data={tableData === null ? [] : tableData}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                // onTableChange={getHandlerTableChange(
                //   salesUIProps.setQueryParams
                // )}
                onTableChange={pageHandler(paginationProps)
                }
                // rowStyle={{ cursor: 'pointer' }}
                /* selectRow={getSelectRow({
                  saleEntities,
                  ids: salesUIProps.ids,
                  setIds: salesUIProps.setIds,
                })} */
                {...paginationTableProps}
              >
                {/* <PleaseWaitMessage entities={saleEntities} />
                <NoRecordsFoundMessage entities={saleEntities} /> */}
                <PleaseWaitMessage entities={tableData} />
                <NoRecordsFoundMessage entities={tableData} />
              </BootstrapTable>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <PaginationLinks paginationProps={paginationProps} />
                <div className="ml-auto">
                <SizePerPageDropdownStandalone
                    { ...paginationProps }
                  />
                  <PaginationTotalStandalone
                    { ...paginationProps }
                  />
                </div>
              </div>
            </>
            // </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
