import {createSlice} from "@reduxjs/toolkit";

const initialUsersState = {
  userListLoading: false,
  userActionsLoading: false,
  userTotalCount: 0,
  userEntities: null,
  userForEdit: undefined,
  loggedUser: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const usersSlice = createSlice({
  name: "users",
  initialState: initialUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.userListLoading = false;
      } else {
        state.userActionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.userListLoading = true;
      } else {
        state.userActionsLoading = true;
      }
    },
    // getUserById
    userFetched: (state, action) => {
      state.userActionsLoading = false;
      state.userForEdit = action.payload.userForEdit;
      state.error = null;
    },
    // findUsers
    usersFetched: (state, action) => {
      const { totalCount, userEntities } = action.payload;
      state.userListLoading = false;
      state.error = null;
      state.userEntities = userEntities;
      state.totalCount = totalCount;
    },
    // createUser
    userCreated: (state, action) => {
      state.userActionsLoading = false;
      state.error = null;
      state.userEntities.push(action.payload.user);
    },
    // updateUser
    userUpdated: (state, action) => {
      state.error = null;
      state.userActionsLoading = false;
      state.userEntities = state.userEntities.map(entity => {
        if (entity.id === action.payload.user.id) {
          return action.payload.user;
        }
        return entity;
      });
    },
    // deleteUser
    userDeleted: (state, action) => {
      state.error = null;
      state.userActionsLoading = false;
      state.userEntities = state.userEntities.filter(el => el.id !== action.payload.id);
    },
    // deleteUsers
    usersDeleted: (state, action) => {
      state.error = null;
      state.userActionsLoading = false;
      state.userEntities = state.userEntities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    updateLocations:(state,actions) =>{
      state.error = null;
      state.userActionsLoading = false;
      if(state.userForEdit != undefined && state.userForEdit != null){
        if(state.userForEdit.locations == undefined ||state.userForEdit.locations == null ){
           state.userForEdit.locations = [];
           state.userForEdit.locations = actions.payload.values
        }else{
           state.userForEdit.locations = actions.payload.values
        }
      }
    },
    loggedUserSet: (state, actions) => {
      state.loggedUser = actions.payload.loggedUser;
    },
  }
});
