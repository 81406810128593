import moment from "moment";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useProformasUIContext } from "../../../../pages/cars/car-proformas/ProformasUIContext";
import { selectCurrentCarId } from "../../../../_redux/cars/carsActions";
import NotificationsApi from "../NotificationsApi";

const ProformaNotificationType = ({ notification, setNotifications,setUnseenNotifications, shouldDecrement=true, now }) => {
    const dispatch = useDispatch();
    const specsUIContext = useProformasUIContext();
	const specsUIProps = useMemo(() => {
        return {
          openProformaPrintDialog: specsUIContext.openProformaPrintDialog
        };
      }, [specsUIContext]);

    const handleClick = async () => {
        const source = notification.notificationMessages[0].source.split("~");
        
        if(notification.notificationType.type.startsWith("PAYMENT"))
            source.shift(); //we don't need the first element in the array
        
        //If it is proforma delete don't open the proforma UI
        if(!notification.notificationType.type.startsWith("PROFORMA_DELETED")){
            dispatch(selectCurrentCarId(source[1]));
            specsUIProps.openProformaPrintDialog(source[0]);
        }
        
        //If notification is inside the alert decrement the count. else if the notification is inside the modal and not inside the alert drop down don't do anything
        if(shouldDecrement)
            setNotifications(prev => {
               setUnseenNotifications(prev => --prev)
               return ({...prev, data:{...prev.data, content: prev.data.content.filter(not=>not.id !== notification.id)}})
             })

        const api = new NotificationsApi();
        api.setSeenNotification(notification.id);

    }

    return (
    <a href="#" class="navi-item" onClick={handleClick}>
        <div class="navi-link">
            <div class="navi-icon mr-2">
                <i class={notification.notificationType.icon}></i>
            </div>
            <div class="navi-text">
                <div class="font-weight-bold">{notification.notificationMessages[0].text}</div>
                <div class="text-muted">{moment.parseZone(notification.dateTimeStamp).from(now)}</div>
            </div>
        </div>
    </a>
    )
}

export default ProformaNotificationType;