import React, {useEffect, useState} from "react";
import { Route } from "react-router-dom";
import { LocationsLoadingDialog } from "./locations-loading-dialog/LocationsLoadingDialog";
import { LocationEditDialog } from "./location-edit-dialog/LocationEditDialog";
import { LocationDeleteDialog } from "./location-delete-dialog/LocationDeleteDialog";
import { LocationsFetchDialog } from "./locations-fetch-dialog/LocationsFetchDialog";
import { LocationsUIProvider } from "./LocationsUIContext";
import { LocationsCard } from "./LocationsCard";
import { shallowEqual, useSelector } from "react-redux";

export function LocationsPage({ history }) {
  const locationsUIEvents = {
    newLocationButtonClick: () => {
      history.push("/locations/new");
    },
    openEditLocationDialog: (id) => {
      history.push(`/locations/${id}/edit`);
    },
    openDeleteLocationDialog: (id) => {
      history.push(`/locations/${id}/delete`);
    },
    openFetchLocationsDialog: () => {
      history.push(`/locations/fetch`);
    }
  }

  const { listLoading, locations} = useSelector(
    (state) => ({
      listLoading: state.locations.locationListLoading,
      locations:state.locations.locationEntities
    }),
    shallowEqual
  );

  const [locationsEmpty, setLocationsEmpty] = useState(false)

  useEffect(() => {
    if(locations !== null && listLoading === false) {
      locations.length < 2 ? setLocationsEmpty(true) : setLocationsEmpty(false)
    }
  }, [locations, listLoading])

  return (
    <LocationsUIProvider locationsUIEvents={locationsUIEvents}>
      <LocationsLoadingDialog />
      <Route path="/locations/new">
        {({ history, match }) => (
          <LocationEditDialog
            show={match != null || locationsEmpty}
            emptyLocation = {locationsEmpty}
            onHide={() => {
              history.push("/locations");
            }}
          />
        )}
      </Route>
      <Route path="/locations/:id/edit">
        {({ history, match }) => (
          <LocationEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/locations");
            }}
          />
        )}
      </Route>
      
      <Route path="/locations/:id/delete">
        {({ history, match }) => (
          <LocationDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/locations");
            }}
          />
        )}
      </Route>
      <Route path="/locations/fetch">
        {({ history, match }) => (
          <LocationsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/locations");
            }}
          />
        )}
      </Route>
      
      <LocationsCard />
    </LocationsUIProvider>
  );
}
