import React from "react";
import { SubscriptionsLoadingDialog } from "./subscriptions-loading-dialog/SubscriptionsLoadingDialog";
import { SubscriptionsUIProvider } from "./SubscriptionsUIContext";
import { SubscriptionsCard } from "./SubscriptionsCard";


export function SubscriptionsPage() {
  return (
    <SubscriptionsUIProvider>
      <SubscriptionsLoadingDialog />
      <SubscriptionsCard />
      
    </SubscriptionsUIProvider>
  );
}
