// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input as customInput,
} from "../../../../../../_metronic/_partials/controls";
// import { Multiselect } from "multiselect-react-dropdown";
import { useDispatch } from "react-redux";
import AsyncSearchBar from "../../../shared/AsyncSelectBar";
import userGenerator from "../../../shared/UserNameGenerator"
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import AsyncMultiSearchBar from "../../../shared/AsyncMultiSelectBar";
// Validation schema
const UserEditSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .matches(
      /^((?!@).)*$/,
      "Name should not contain @"
    )
    .required("Name is required")
    ,
  userName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .required("Username is required")
    ,
  password: Yup
    .string()
    .required('Password  is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})/,
      "Must Contain 7 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    ,
  group: Yup.object().nullable().required("Group is required").test(
    'GroupSelected',
    'Group is required',
    function(value){
          return value?.id != undefined && value?.id > 0
      }
    ),
    location_id: Yup.array().min(1),

    email: Yup.string().trim().required("Email is required")
    .matches(
      // /^[^@]*@[^@]*$/,
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Invalid email format"
    ),
      phoneNumber: Yup.string().trim().required("Phone Number is required")
      .min(10,"Minimum is 10 digits")
      .max(15, "Maximum is 15 digits")
  // locations: Yup.object().nullable().test(
  //   'LocationSelected',
  //   'Location is required',
  //   function(value){
  //         return this.parent?.locations?.length > 0 && this.parent?.locations != null && this.parent?.locations != undefined
  //     }
  //   ),
});

export function UserEditForm({
  saveUser,
  user,
  actionsLoading,
  onHide,
  openDeleteUserDialog,
  locations,
  groups,
  selectedLocation,
  selectedGroup,
  openChangePasswordDialog,
  initUser
}) {
  const dispatch = useDispatch();
  const [locationValidation,setLV] = useState(false)
  function handleChange(value,setFieldValue,setFieldTouched) {
    //dispatch(actions.updateLocation(value))
    //initUser?.locations.push(value)
    setFieldValue('locations',value)
    if(value.length > 0){
      setLV(false)
    }else{
      setLV(true)
    }
    
  }
  
  function setUserName(e,value,setFieldValue){
    //console.log(value)
    let userName = value?.fullName
    if(userName!='' || userName!= null){
    var newUserName = userGenerator(userName,value?.users)
    //console.log(newUserName)
    setFieldValue('userName',newUserName)
    }else{
      return null;
    }
  }
  const [locationSelectToggle,setLocaSelTog] = useState(false)
  
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{...user, location_id: user?.locations?.map((r) => r.id), location_id_name: user?.locations}}
        validationSchema={UserEditSchema}
        onSubmit={(values) => {
          values.locations = values.location_id_name;
          if(values.locations.length>0){
          saveUser(values);
          }else{
            setLV(true);
          }
        }}
      >
        {({ handleSubmit,values,initialValues ,setFieldValue,setFieldTouched,errors,touched,isSubmitting}) => (
          <BlockUi blocking={isSubmitting}>
            <Modal.Body className="overlay  cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Name */}
                  
                  <div className="col-lg-10">
                    <Field
                      name="fullName"
                      component={customInput}
                      placeholder="Name"
                      label="Name"
                      withFeedbackLabel= {true}
                      required
                      onKeyUp={(e)=>{if(initialValues.id == undefined){
                                      setUserName(e,values,setFieldValue)}}}
                    />
                  </div>
                  </div>
                  {/* UserName */}
                  <div className="form-group row">
                  <div className="col-lg-10">
                    <Field
                      name="userName"
                      component={customInput}
                      placeholder="Username"
                      label="Username"
                      withFeedbackLabel= {true}
                      required
                      disabled={true}
                      //style={{color:'#0096fb'}}
                    />
                  </div>
                  </div>
                  
                  { /* Password */}
                  {initialValues.id == undefined && <div className="form-group row">
                  <div className="col-lg-10">
                    <Field
                      name="password"
                      component={customInput}
                      placeholder="Password"
                      label="Password"
                      withFeedbackLabel= {true}
                      required
                      type="password"
                      autoComplete="new-password"
                    />
                  </div>
                  </div>}

                  { /* Email */}
                   <div className="form-group row">
                  <div className="col-lg-10">
                    <Field
                      name="email"
                      component={customInput}
                      placeholder="name@email.com"
                      label="Email"
                      withFeedbackLabel= {true}
                      required
                      type="email"
                      autocomplete="off"
                    />
                  </div>
                  </div>


                  { /* PhoneNumber */}

                  <div className="form-group row">
                  <div className="col-lg-10">
                    <Field
                      name="phoneNumber"
                      component={customInput}
                      placeholder="+251901020304"
                      label="Telephone"
                      withFeedbackLabel= {true}
                      required
                      type="text"
                      autocomplete="off"
                    />
                  </div>
                  </div>


                  {/* Role */}
                  <div className="form-group row">
                  <div className="col-lg-10">
                  <label  htmlFor="group">
                      Group <span className="text-danger">*</span>
                    </label>
                      <AsyncSearchBar 
                          setSelectedItem={setFieldValue}
                          setTouchedItem={setFieldTouched}
                          defaultItem={selectedGroup?.name}
                          error={errors.group}
                          touched={touched.group}
                          item_id="group"
                          item_name="name"
                          options={groups}
                          isSearchable={false}
                          isObject={true}
                          queryOnChange={{values,locations,selectedLocation,setFieldValue,initialValues,setLocaSelTog}}
                        />
                        {errors["group"] && <div className="invalid-feedback d-block">{errors["group"]}</div>}
                  </div>
                  </div>

                  {/* Location */}
                  <div className="form-group row">
                  <div className="col-lg-10">
                  <label  htmlFor="location">
                      Locations <span className="text-danger">*</span>
                    </label>
                        {/* <Multiselect
                          id="locations"
                          options={locations}
                          displayValue="name"
                          selectedValues={locationSelectToggle? locations: []}
                          hidePlaceholder = {true}
                          onSelect={(e)=>handleChange(e,setFieldValue,setFieldTouched)}
                          onRemove = {(e)=>handleChange(e,setFieldValue,setFieldTouched)}
                          emptyRecordMsg='No Location Avaliable'
                          style={locationValidation &&  {searchBox : {borderColor: 'red'}}}
                        /> */}
                        <AsyncMultiSearchBar
                          isMulti={true}
                          selectedItem={{id: values.locations_id}} 
                          setSelectedItem={setFieldValue}
                          setTouchedItem={setFieldTouched}
                          touched={touched.location_id}
                          item_id="location_id"
                          item_name="name"
                          url={`${process.env.REACT_APP_AUTO_DEALER_API}locations/search`}
                          userName={true}
                          clear={values.locations === []}
                          catchDefaultName={true}
                          val={values?.location_id_name}
                        />
                        {errors["location_id"] && <div className="invalid-feedback d-block">Location is Required</div>}
                  </div>
                  </div>

              </Form>
            </Modal.Body>
            <Modal.Footer>
            <button
                type="submit"
                onClick={() =>{ handleChange(values?.locations,setFieldValue,setFieldTouched);handleSubmit()}}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate mr-auto"
              >
                Cancel
              </button>
              { values.id != undefined &&<>
              <button
              type="button"
              onClick={()=>{openChangePasswordDialog(values.id)}}
              className="btn btn-light btn-elevate"
              >
              Change Password
              </button>

              { localStorage.currentUserEmail != values.userName &&  
              (values.status ? 
              <button
                type="button"
                onClick={()=>{openDeleteUserDialog(values.id,values.status)}}
                className="btn btn-danger btn-elevate"
              >
                Deactivate
              </button> : 
               <button
               type="button"
               onClick={()=>{openDeleteUserDialog(values.id,values.status)}}
               className="btn btn-elevate btn-primary"
             >
               Activate
             </button>)}
              </>
              }

              <> </>
              
            </Modal.Footer>
          </BlockUi>
        )}
      </Formik>
    </>
  );
}
