// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo,useState,useLayoutEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
}  from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/locations/locationsActions";
import * as settingActions from "../../../../_redux/settings/settingsActions";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../LocationsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { PaginationLinks } from "./../../../../../../_metronic/_partials/controls";
import { useLocationsUIContext } from "../LocationsUIContext";
import cloneDeep from 'lodash/cloneDeep';
import NotFoundPage from "../../../shared/NotFoundPage";
import $ from 'jquery'
import { LocationTypes } from "../LocationsUIHelpers";

export function LocationsTable() {
  // Locations UI Context
  const locationsUIContext = useLocationsUIContext();
  const locationsUIProps = useMemo(() => {
    return {
      ids: locationsUIContext.ids,
      setIds: locationsUIContext.setIds,
      queryParams: locationsUIContext.queryParams,
      setQueryParams: locationsUIContext.setQueryParams,
      openEditLocationDialog: locationsUIContext.openEditLocationDialog,
      openDeleteLocationDialog: locationsUIContext.openDeleteLocationDialog,
    };
  }, [locationsUIContext]);

  // Getting curret state of locations list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.locations }),
    shallowEqual
  );
  const { locationTotalCount:totalCount, locationEntities:entities, locationListLoading:listLoading,error,apiErrorData } = currentState;
  const [tableData,setTableDate] = useState([]);
  const [notFound,setNotFound] = useState(false);
  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }else if(error 
          && apiErrorData?.errorCode==="NOT_FOUND"){
            setNotFound(true);
        }
    }
  },[error])
  useEffect(()=>{
    if(notFound){
      $("#kt_content > div > div > div.card-header > div.card-toolbar > button").hide();
    }
  },[notFound])

  // Locations Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    locationsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchLocations(locationsUIProps.queryParams));
    dispatch(settingActions.fetchLocationTypes())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dispatch]);

  useEffect(()=>{
    setTableDate(cloneDeep(entities))
  },[entities])

  const { locationTypeEntities } = useSelector(
    (state) => ({ locationTypeEntities: state.setting.locationTypeEntities }),
    shallowEqual
  );

  const LOCAL_LOCATION_ID = locationTypeEntities?.find(l => l.value === LocationTypes?.find(lt => lt.value==='Local')?.name)?.id;

  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          locationsUIProps.openEditLocationDialog(row.id);
        }
      }
    },
    
    {
      dataField: "address",
      text: "Address",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          locationsUIProps.openEditLocationDialog(row.id);
        }
      }
    },
    
    {
      dataField: "location_type_id",
      text: "Location Type",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <span>
          {row?.location_type_id === LOCAL_LOCATION_ID ? LocationTypes?.find(l => l.name === 'Location_0')?.value : LocationTypes?.find(l => l.name === 'Location_1')?.value}
        </span>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          locationsUIProps.openEditLocationDialog(row.id);
        }
      }
    },

    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditLocationDialog: locationsUIProps.openEditLocationDialog,
        openDeleteLocationDialog: locationsUIProps.openDeleteLocationDialog,
      },
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: tableData?.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: locationsUIProps.queryParams.pageSize,
    page: (locationsUIProps.queryParams.pageNumber - 1) < (tableData?.length/locationsUIProps.queryParams.pageSize) ? locationsUIProps.queryParams.pageNumber : 
    locationsUIProps.queryParams.pageNumber == 1 ?  locationsUIProps.queryParams.pageNumber:locationsUIProps.queryParams.pageNumber - 1  ,
  };

  function filter(e){
    //console.log(e)
    const filter = entities?.filter(item => item.name.toLowerCase().indexOf(e.toLowerCase()) > -1 || item.address?.toLowerCase().indexOf(e.toLowerCase()) > -1 )
    setTableDate(filter)
  }
  
  function pageHandler (paginationProps){
    
    locationsUIProps.setQueryParams(
      (prev) =>
      "pagination" === "pagination"
        ? { ...prev, pageNumber:paginationProps?.page || 1, pageSize: paginationProps?.sizePerPage }
        : prev
    )
    
}
  return (
    <>
    {!notFound ?
    <>
      <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  // onBlur={handleBlur}
                  // value={values.searchText}
                   onChange={(e) => {
                    filter(e.target.value);
                   }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                keyField="id"
                data={tableData === null ? [] : tableData}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={pageHandler(paginationProps)}
                rowStyle={{ cursor: 'pointer' }}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={tableData} />
                <NoRecordsFoundMessage entities={tableData} />
              </BootstrapTable>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
            <PaginationLinks paginationProps={paginationProps} />
            <div className="ml-lg-auto">
            <SizePerPageDropdownStandalone
                { ...paginationProps }
              />
              <PaginationTotalStandalone
                { ...paginationProps }
              />
            </div>
            </div>
            </>
          );
        }}
      </PaginationProvider>
    </>
     :<NotFoundPage/>}
    </>
  );
}
