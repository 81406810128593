import moment from 'moment'

export const defaultSorted = [{ dataField: "category_id", order: "asc" }];
// const date = new Date();
// const startdate = new Date(date.getFullYear(), date.getMonth(), 1)
const enddate = moment()
const startdate = moment().startOf('month')

export const initialFilter = {
    filter: {
        category: "",
        batch: "",
        supplier_id: "",
        employee_id: "",
        start_date: startdate,
        end_date: enddate,
        inProcess: "1",
        processed: "1",
        year: "",
        color: "",
        group_batch: "",
    },
    sortOrder: "asc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 10, 
    hidden: {
        year: true, 
        color: true, 
        batch: true,
    }
};
