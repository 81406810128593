import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/customers/customersActions";
import { CustomerEditDialogHeader } from "./CustomerEditDialogHeader";
import { CustomerEditForm } from "./CustomerEditForm";
import { useCustomersUIContext } from "../CustomersUIContext";

export function CustomerEditDialog({ id, show, onHide,initalData = null }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  //const names = [{name:'Betselot'},{name:'betse'},{name:'betse'}];
  const customersUIProps = useMemo(() => {
    return {
      initCustomer: customersUIContext.initCustomer,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, customerForEdit,customers } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      customerForEdit: state.customers.customerForEdit,
      customers:state.customers.entities
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Customer by id
    dispatch(actions.fetchCustomer(id));
    //dispatch(actions.fetchCustomerNames())
  }, [id, dispatch]);
  
  // server request for saving customer
  const saveCustomer = (customer) => {
    if (!id) {
      // server request for creating customer
      dispatch(actions.createCustomer(customer)).then(() => onHide());
    } else {
      // server request for updating customer
      dispatch(actions.updateCustomer(customer)).then(() => onHide());
    }
  };
  const customerFor = initalData != null ? {...initalData,customers}:customerForEdit==undefined ?  {...customersUIProps.initCustomer,customers}: {...customerForEdit,customers}
  return (
    <Modal
      size="md"
      show={show}
      // onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <CustomerEditDialogHeader id={id} onHide={onHide} />
      <CustomerEditForm
        saveCustomer={saveCustomer}
        actionsLoading={actionsLoading}
        customer={customerFor}
        onHide={onHide}
        openDeleteCustomerDialog={customersUIProps.openDeleteCustomerDialog}
      />
    </Modal>
  );
}
