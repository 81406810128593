import React, { useState, useRef } from "react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { useEffect } from "react";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: '#F64E60'
  })
}

const jwtToken = localStorage.getItem("currentUser")

const AsyncSearchBar = ({ selectedItem, setSelectedItem, setTouchedItem, defaultItem, error, touched, item_id, item_name, url="", options=[], isSearchable=true, onChange=null, pk='id',isObject=false
                          , submit=null, clear=null,queryOnChange=null, isMulti=false,userName=null
                          ,catchDefaultName=false, noSelectOption=false
                          , isDisabled=false ,id="",onChangeEnt= null
                          ,values=null,val=null}) => {
  const [query, setQuery] = useState("");

  //get animated components wrapper
  const animatedComponents = makeAnimated();
  let listOfItemsSelected = []

  const loadOptions = async () => {
    if(url) {
      const response = await fetch(
        `${url}?searchTxt=${query}&user_name=${userName ? localStorage.currentUserEmail : ""}`, {
          headers: {Authorization: jwtToken}
        }
      );
      const responseJSON = await response.json();
      !noSelectOption && !isMulti && responseJSON.unshift({id: '', [item_name]: "-- Select --"})
      if(item_id == 'batch_id'){sessionStorage.setItem("batch_for_filter_params", JSON.stringify(responseJSON))};
      if(item_id == 'location_id'){sessionStorage.setItem("location_for_filter_params", JSON.stringify(responseJSON))};
      if(item_id == 'supplier_id'){sessionStorage.setItem("supplier_for_filter_params", JSON.stringify(responseJSON))};
      if(item_id == 'category'){sessionStorage.setItem("make_for_filter_params", JSON.stringify(responseJSON))};
      if(item_id == 'employee_id'){sessionStorage.setItem("employee_for_filter_params", JSON.stringify(responseJSON))};
      if(item_id == 'bank_acc'){sessionStorage.setItem("bankacc_for_filter_params", JSON.stringify(responseJSON))};
      if(item_id == 'accountType'){sessionStorage.setItem("account_type_for_filter_params", JSON.stringify(responseJSON))};
      return responseJSON.filter((item)=>{return !listOfItemsSelected.includes(item.id)})
    }
    else {
      listOfItemsSelected = [...options]
      !noSelectOption && !isMulti && listOfItemsSelected.unshift({id: '', [item_name]: "-- Select --"})
      return listOfItemsSelected
    }
  };

  const selectInputRef = useRef();
  const onClear = () => {
    selectInputRef.current.clearValue();
  };

  useEffect(() => {
    if(clear) {
      onClear()
    }
  }, [clear])

  return (
    <>
      <AsyncSelect

        isMulti={isMulti}
        ref={selectInputRef}
        isSearchable={isSearchable.toString()}
        isDisabled={isDisabled}
        noOptionsMessage={() => 'Please try a different word'}
        cacheOptions
        // defaultInputValue={defaultItem}
        // value={val}
        id={id}
        defaultOptions
        components={animatedComponents}
        getOptionLabel={e => e[item_name]}
        getOptionValue={e => e.id}
        loadOptions={loadOptions}
        onInputChange={value => setQuery(value)}
        onFocus={() => {
          setTouchedItem(item_id, true, true)
        }}
        onChange={value => {
          if(value && value?.id != "" && value?.id > 0)
            onChangeEnt && onChangeEnt(value,values,setSelectedItem)
          if(!isObject){
            let n = [], newFormData = {};
            if(isMulti) {
              value.forEach(v => {
                for (var key in v) {
                  if(v[key]!=null){
                    newFormData[key]=v[key];
                  }
                }
                n.push(v)
              })
            } else {
              newFormData = {...selectedItem}
              for (var key in value) {
                if(value[key]!=null){
                newFormData[key]=value[key];
              }
            }}
            if(onChange !== null && value.id) {
              onChange(value[item_name])
            }
          setTouchedItem(item_id, true, true)
          setSelectedItem(item_id, !isMulti ? newFormData[pk] : n.map(m => m.id))
          if(catchDefaultName){
          setSelectedItem(item_id+'_name', !isMulti ? newFormData : n)
          }
        }else{
          setSelectedItem(item_id, value)
        }

        if(queryOnChange!=null && queryOnChange !=undefined){
          const{locations,selectedLocation,setFieldValue,setLocaSelTog} = queryOnChange
          if(value?.name==='Standard_Group'  ){
            setFieldValue('location_id_name', []);
            setFieldValue('location_id', [])
            // setFieldValue('locations',selectedLocation)
            // setLocaSelTog(false);

          }else if (value?.name==='Admin_Group' ){
            setFieldValue('location_id_name',locations)
            setFieldValue('location_id',locations.map((l) => l.id))
            // setLocaSelTog(true);
          }
        }

        submit && submit()

        }}
        aria-labelledby="aria-label"
        inputId="aria-example-input"
        styles={error && touched && customStyles}
        placeholder={defaultItem || "Select..."}
      />
    </>
  );
};

export default AsyncSearchBar;
