import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCarModelsUIContext } from "../CarModelsUIContext";
import * as categoryActions from "../../../_redux/categories/categoriesActions";

const prepareFilter = (queryParams, values) => {
  const { make, model, body, searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by make
  filter.make = make !== "" ? make : undefined;
  // Filter by model
  filter.model = model !== "" ? model : undefined;
  // Filter by body
  filter.body = body !== "" ? body : undefined;
  // Filter by all fields
  if (searchText) {
    filter.searchText = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function CarModelsFilter({ listLoading }) {
  // CarModels UI Context
  const carModelsUIContext = useCarModelsUIContext();
  const carModelsUIProps = useMemo(() => {
    return {
      queryParams: carModelsUIContext.queryParams,
      setQueryParams: carModelsUIContext.setQueryParams,
    };
  }, [carModelsUIContext]);

  const [make, setMake] = useState(null);
  const [models, setModels] = useState([]);

	const jwtToken = localStorage.getItem("currentUser")
	const url = `${process.env.REACT_APP_AUTO_DEALER_API}carmodels/searchModels`

  const dispatch = useDispatch();

	const { currentState } = useSelector(
		(state) => ({ currentState: state }),
		shallowEqual
	);

	const { categoryEntities } = currentState.categories;
  const makeOptions = categoryEntities && [... new Set(categoryEntities.map(({make}) => make))];
	const modelOptions = categoryEntities && [... new Set(categoryEntities.map(({model}) => model))];

	useEffect(() => {
		!categoryEntities && dispatch(categoryActions.fetchCategories());
  }, [dispatch]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(carModelsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, carModelsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      carModelsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          make: "", // values => All=""/Susspended=0/Active=1/Pending=2
          model: "", // values => All=""/Business=0/Individual=1
          body: "",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <div className="d-lg-none">
            <form onSubmit={handleSubmit} className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-3">
                  <div className="input-icon">
                    <input
                      type="text"
                      className="form-control"
                      name="searchText"
                      placeholder="Search"
                      onBlur={handleBlur}
                      value={values.searchText}
                      onChange={(e) => {
                        setFieldValue("searchText", e.target.value);
                        handleSubmit();
                      }}
                    />
                    <span>
                      <i className="flaticon2-search-1 text-muted"></i>
                    </span>
                  </div>
                  <small className="form-text text-muted">
                    <b>Search</b> in all fields
                  </small>
                </div>
                <div className="col-lg-2">
                  <select
                    className="form-control"
                    name="make"
                    placeholder="Filter by Make"
                    // TODO: Change this code
                    onChange={async (e) => {
                      const m = e.target.value;
                      setMake(m);
                      setFieldValue("make", m);
                      if(m != null) {
                        const res = await fetch(
                          `${url}?make=${m}`, {
                          headers: {Authorization: jwtToken}
                        });
                        const modelEntities = await res.json()
                        setModels(modelEntities?.map(mE => mE?.model));
                        if(!modelEntities?.map(mE => mE?.model).includes(values.model)) setFieldValue("model", "");
                      } 
                      handleSubmit();
                    }}
                    onBlur={handleBlur}
                    value={values.make}
                  >
                    <option hidden value="">Make</option>
                    <option value="">All</option>
                    {makeOptions && makeOptions.map((element, i) => (
                      <option key={i} value={element}>
                        {element}
                      </option>
                    ))}
                  </select>
                  <small className="form-text text-muted">
                    <b>Filter</b> by Make
                  </small>
                </div>
                <div className="col-lg-2">
                  <select
                    className="form-control"
                    placeholder="Filter by Model"
                    name="model"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("model", e.target.value);
                      handleSubmit();
                    }}
                    value={values.model}
                  >
                    <option hidden value="">Model</option>
                    <option value="">All</option>
                    {!make ? (
                      modelOptions && modelOptions.map((e, i) => (
                        <option key={i} value={e}>
                          {e}
                        </option>
                      ))): (
                      models && models.map((element, i) => (
                        <option key={i} value={element}>
                          {element}
                        </option>
                      )))
                    }
                  </select>
                  <small className="form-text text-muted">
                    <b>Filter</b> by Model
                  </small>
                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </>
  );
}
