import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import AsyncSearchBar from "../../shared/AsyncSelectBar";
import { useOrdersUIContext } from "../OrdersUIContext";
import * as uiHelpers from "../OrdersUIHelper";

const prepareFilter = (queryParams, values) => {
  const { status_id, searchText } = values;
  return {...queryParams, filter: {searchText: searchText.trim(), status_id: `${status_id}`}};
};

export function OrdersFilter() {
  // Cars UI Context
  const ordersUIContext = useOrdersUIContext();
  const ordersUIProps = useMemo(() => {
    return {
      queryParams: ordersUIContext.queryParams,
      setQueryParams: ordersUIContext.setQueryParams,
    };
  }, [ordersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(ordersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, ordersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      ordersUIProps.setQueryParams(newQueryParams);
    }
  };
 
  return (
    <>
      <Formik
        initialValues={ordersUIProps.queryParams.filter}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          touched,
          handleSubmit,
          handleBlur,
          setFieldValue,
          setFieldTouched
        }) => (
          <>
            <form onSubmit={handleSubmit} className="form form-label-right">
                <div className="form-group row">
                    <div className="col-lg-12">
                        <small className="form-text text-muted"><b>Search</b></small>
                        <div className="input-icon">
                            <input
                                type="text"
                                className="form-control"
                                name="searchText"
                                placeholder="Search"
                                onBlur={handleBlur}
                                value={values.searchText}
                                onChange={(e) => {
                                    setFieldValue("searchText", e.target.value);
                                    handleSubmit();
                                }}
                            />
                        <span><i className="flaticon2-search-1 text-muted"></i></span>
                        </div> 
                    </div>
                </div>
              
                <div className="form-group row">
                    <div className="col-lg-12">
                        <small className="form-text text-muted"><b>Sale Status</b></small>
                        <AsyncSearchBar
                            selectedItem={{id: values.status_id}} 
                            setSelectedItem={setFieldValue}
                            setTouchedItem={setFieldTouched}
                            touched={touched.status_id}
                            // defaultItem={searchValues?.status_id_name?.name}
                            item_id="status_id"
                            item_name="name"
                            options={uiHelpers.ProformaStatuses.filter(p => p.name != 'Overdue')}
                            isSearchable={false}
                            submit={handleSubmit}
                            clear={values.status_id == ""}
                            catchDefaultName={true}
                        /> 
                    </div>
                </div>

                <div className="text-right">
                    <button 
                        type="reset" 
                        className="btn btn-primary font-weight-bolder" 
                        onClick={()=>{
                          setFieldValue('status_id', '')
                          setFieldValue('searchText', '')
                          handleSubmit();
                        }}>
                        Reset
                    </button>
                </div>
            </form>
          </>
        )}
      </Formik>
    </>
  );
}
