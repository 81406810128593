import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import {
  TenantStatusCssClasses,
  TenantStatusTitles,
} from "../TenantsUIHelpers";
import { useTenantsUIContext } from "../TenantsUIContext";

const selectedTenants = (entities, ids) => {
  const _tenants = [];
  ids.forEach((id) => {
    const tenant = entities.find((el) => el.id === id);
    if (tenant) {
      _tenants.push(tenant);
    }
  });
  return _tenants;
};

export function TenantsFetchDialog({ show, onHide }) {
  // Tenants UI Context
  const tenantsUIContext = useTenantsUIContext();
  const tenantsUIProps = useMemo(() => {
    return {
      ids: tenantsUIContext.ids,
    };
  }, [tenantsUIContext]);

  // Tenants Redux state
  const { tenants } = useSelector(
    (state) => ({
      tenants: selectedTenants(
        state.tenants.tenantEntities,
        tenantsUIProps.ids
      ),
    }),
    shallowEqual
  );

  // if tenants weren't selected we should close modal
  useEffect(() => {
    if (!tenantsUIProps.ids || tenantsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantsUIProps.ids]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fetch selected elements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table table-head-custom table-vertical-center overflow-hidden">
          <thead>
            <tr>
              <th>ID</th>
              <th>STATUS</th>
              <th>CUSTOMER</th>
            </tr>
          </thead>
          <tbody>
            {tenants.map((tenant) => (
              <tr key={`id${tenant.id}`}>
                <td>{tenant.id}</td>
                <td>
                  <span
                    className={`label label-lg label-light-${
                      TenantStatusCssClasses[tenant.status]
                    } label-inline`}
                  >
                    {" "}
                    {TenantStatusTitles[tenant.status]}
                  </span>
                </td>
                <td>
                  <span className="ml-3">
                    {tenant.lastName}, {tenant.firstName}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-primary btn-elevate"
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
