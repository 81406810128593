import {createSlice} from "@reduxjs/toolkit";

const initialClientsState = {
  clientListLoading: false,
  clientActionsLoading: false,
  clientTotalCount: 0,
  clientEntities: null,
  clientForEdit: undefined,
  lastError: null, 
  clientRegistered: null,
  clientVerified: null,
  clientSubscription: null,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const clientsSlice = createSlice({
  name: "clients",
  initialState: initialClientsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.clientListLoading = false;
      } else {
        state.clientActionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.clientListLoading = true;
      } else {
        state.clientActionsLoading = true;
      }
    },
    // getClientById
    clientFetched: (state, action) => {
      state.clientActionsLoading = false;
      state.clientForEdit = action.payload.clientForEdit;
      state.error = null;
    },
    // findClients
    clientsFetched: (state, action) => {
      const { totalCount, clientEntities } = action.payload;
      state.clientListLoading = false;
      state.error = null;
      state.clientEntities = clientEntities;
      state.totalCount = totalCount;
      localStorage.setItem('clientStartYear',clientEntities?.startYearDate);
    },
    // createClient
    clientCreated: (state, action) => {
      state.clientActionsLoading = false;
      state.error = null;
      state.clientEntities.push(action.payload.client);
    },
    // updateClient
    clientUpdated: (state, action) => {
      state.error = null;
      state.clientActionsLoading = false;
      state.clientEntities = action.payload.client;
      localStorage.setItem('clientStartYear',action.payload.client?.startYearDate);
    },
    // deleteClient
    clientDeleted: (state, action) => {
      state.error = null;
      state.clientActionsLoading = false;
      state.clientEntities = state.clientEntities.filter(el => el.id !== action.payload.id);
    },
    // deleteClients
    clientsDeleted: (state, action) => {
      state.error = null;
      state.clientActionsLoading = false;
      state.clientEntities = state.clientEntities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }, 
    // set client registered
    setClientRegistered: (state, action) => {
      state.clientRegistered = action.payload.clientRegistered
    },
    // set client verified
    setClientVerified: (state, action) => {
      state.clientVerified = action.payload.verifiedClient
    },
    // show subscription msg to client
    showClientSubscriptionMsg: (state, action) => {
      state.clientSubscription = action.payload.clientSubscription
    },
  }
});
