
export default function TableDataRowFormatter(data){
if(data != undefined &&data!=null && data.length > 0){
var objectValue = [];
var rowNumber = 0 ;
for(var i=0;i<data.length;i++){
    var att = {}
    if(data[i].count > 1){
        
      att={'car':{'id':++rowNumber
      ,'name':data[i].productName
      ,'year':data[i].productYear
      ,'vin':data[i].vin
      ,'unitPrice':data[i].unit_price
      ,'invoiceAmount':data[i].invoice_amount
      ,'totalAmountNum':data[i].totalAmountNum
      ,'totalAmountByComBankNum':data[i].totalAmountByComBankNum
      ,'unit_priceNum':data[i].unit_priceNum
      ,'invoice_amountNum':data[i].invoice_amountNum },
          'detail':{
                            'bank_acc': '',
                            'date': '',
                            'non_company_amount': data[i].totalAmountByNonBank,
                            'company_amount': data[i].totalAmountByComBank,
                            "totalAmount": data[i].totalAmount
                        } ,
                'span':-2,
                'showDetail':data[0].showDetail
            } 
        objectValue.push(att)
        if(data[0].showDetail === "1" ){
        att={'car':{'id':'','name':null,'year':null,'vin':null},
            'detail':{
                                'bank_acc': data[i].bank_acc,
                                'date': data[i].date,
                                'non_company_amount': data[i].non_company_amount,
                                'company_amount': data[i].company_amount} ,
                    'span':0,
                    } 
        objectValue.push(att)
        }
        att = {}
        
        
        for(var j=1;j<data[i].count;j++){
            
            if(data[0].showDetail === "1" ){
            att={'car':{'id':'','name':null,'year':null,'vin':null},
            'detail':{
                                'bank_acc': data[i+j].bank_acc,
                                'date': data[i+j].date,
                                'non_company_amount': data[i+j].non_company_amount,
                                'company_amount': data[i+j].company_amount} ,
                    'span':0,
                    } 
                //}
            
                objectValue.push(att)
                att = {}
            }
            }
     i=i+data[i].count-1
    }else{
        att = {'car':{'id':++rowNumber,'name':data[i].productName,'year':data[i].productYear,'vin':data[i].vin
        ,'unitPrice':data[i].unit_price,'invoiceAmount':data[i].invoice_amount
        ,'totalAmountNum':data[i].totalAmountNum
        ,'totalAmountByComBankNum':data[i].totalAmountByComBankNum
        ,'unit_priceNum':data[i].unit_priceNum
        ,'invoice_amountNum':data[i].invoice_amountNum},
                'detail':{
                            'bank_acc': "",
                            'date': "",
                            'non_company_amount': data[i].totalAmountByNonBank,
                            'company_amount': data[i].totalAmountByComBank,
                            "totalAmount": data[i].totalAmount
                         } ,
                'span': -2,
                'showDetail':data[0].showDetail
        }
        objectValue.push(att)
        if(data[0].showDetail === "1" ){
        att={'car':{'id':'','name':null,'year':null,'vin':null},
        'detail':{
                            'bank_acc': data[i].bank_acc,
                            'date': data[i].date,
                            'non_company_amount': data[i].non_company_amount,
                            'company_amount': data[i].company_amount} ,
                'span':0,
                } 
            objectValue.push(att)
        }
    } 
  
}
return objectValue;
}
}