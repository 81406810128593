import React, { useEffect, useMemo, useState,useLayoutEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone, 
  PaginationTotalStandalone
} from "react-bootstrap-table2-paginator";
import * as actions from "../../../_redux/proformas/proformasActions";
import * as columnFormatters from "./column-formatters";
import * as uiHelpers from "../OrdersUIHelper";
import { PaginationLinks } from "./../../../../../_metronic/_partials/controls";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "./../../../../../_metronic/_helpers";
import { useOrdersUIContext } from "../OrdersUIContext";
import { useProformasUIContext } from "../../cars/car-proformas/ProformasUIContext";
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment'


export function OrdersTable() {
  // Orders UI Context
  const ordersUIContext = useOrdersUIContext();
  const ordersUIProps = useMemo(() => {
    return {
      queryParams: ordersUIContext.queryParams,
      setQueryParams: ordersUIContext.setQueryParams,
      openEditOrderDialog: ordersUIContext.openEditOrderDialog,
      openDeleteOrderDialog:
        ordersUIContext.openDeleteOrderDialog,
      ids: ordersUIContext.ids,
      setIds: ordersUIContext.setIds,
      productId: ordersUIContext.productId,
      
    };
  }, [ordersUIContext]);

  const proformasUIContext = useProformasUIContext();
  const proformasUIProps = useMemo(() => {
    return {
      openEditProformaDialog: proformasUIContext.openEditProformaDialog,
      openDeleteProformaDialog: proformasUIContext.openDeleteProformaDialog,
      openProformaPrintDialog: proformasUIContext.openProformaPrintDialog,
      openContractOfSale:proformasUIContext.openProformaConstractOfSale,
      openBankReleaseLetter:proformasUIContext.openProformaBankReleaseLetter,
      openCashContractOfSale:proformasUIContext.openProformaCashConstractOfSale,
      openPrintMenu: proformasUIContext.openPrintMenuDialog,
    };
  }, [proformasUIContext]);

  const [tableData,setTableData] = useState(null)

  const { currentState } = useSelector(
    (state) => ({ currentState: state }),
    shallowEqual
  );
  const { allEntities, allTotalCount, /*totalCount, entities,*/ listLoading, error, apiErrorData, proformaForEdit } = currentState.proformas;

  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }
    }
  },[error])

  useEffect(()=>{
    setTableData(cloneDeep(allEntities) || [])
    //throw new Error('Error This')
  },[allEntities])

  // Proformas Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchProformas(ordersUIProps.queryParams.filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, proformaForEdit, ordersUIProps.queryParams.filter]);

  const columns = [
    {
      dataField: "id",
      text: "Order No.",
      sort: true,
      sortCaret: sortCaret,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          proformasUIProps.openProformaPrintDialog(row.id);
        }
      }
    },
    {
      dataField: "customer.name",
      text: "Customer",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => (row.customer == undefined ) ? row.customerName: row.customer.name,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          proformasUIProps.openProformaPrintDialog(row.id);
        }
      }
    },
    {
      dataField: "status_id",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.ProformaStatusColumnFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          proformasUIProps.openProformaPrintDialog(row.id);
        }
      }
    },
    {
      dataField: "proforma_date",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      classes: "text-nowrap",
      footerClasses: "bottomNone",
      formatter: (cellContent, row) => (
        <span>
          {moment(row.proforma_date).format("DD/MM/yyyy")}
        </span>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          proformasUIProps.openProformaPrintDialog(row.id);
        }
      }
    },
    {
      dataField: "expire",
      text: " Expires after (days)",
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "",
      classes: "text-right pr-10",
      headerStyle: (colum, colIndex) => {
        return { width: '95px' };
      },
      formatter: (cellContent, row) => (
          <span>
            {(row.status_id != uiHelpers.ProformaStatuses.find(p => p.name === "Cancelled")?.id && row.status_id != uiHelpers.ProformaStatuses.find(p => p.name === "Processed")?.id && row.expire) && (moment(row.proforma_date).add(row.expire+1, 'days').diff(moment(), 'days') <=0 ? moment(row.proforma_date).add(row.expire-1, 'days').diff(moment(), 'days') : moment(row.proforma_date).add(row.expire+1, 'days').diff(moment(), 'days')) }
          </span>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          proformasUIProps.openProformaPrintDialog(row.id);
        }
      }
    },
    {
      dataField: "user_name",
      text: "User",
      sort: true,
      sortCaret: sortCaret,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          proformasUIProps.openProformaPrintDialog(row.id);
        }
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditProformaDialog: proformasUIProps.openEditProformaDialog,
        openDeleteProformaDialog: proformasUIProps.openDeleteProformaDialog,
        // openContractOfSale: proformasUIProps.openContractOfSale,
        // openBankReleaseLetter: proformasUIProps.openBankReleaseLetter,
        // openCashContractOfSale: proformasUIProps.openCashContractOfSale
        openPrintMenu: proformasUIProps.openPrintMenu,
      },
      style: {
        minWidth: "125px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: allTotalCount != undefined ? allTotalCount : 0,
    // totalSize: totalCount != undefined ? totalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: ordersUIProps.queryParams.pageSize,
    page: (ordersUIProps.queryParams.pageNumber - 1) < (tableData?.length/ordersUIProps.queryParams.pageSize) ? ordersUIProps.queryParams.pageNumber : 
    ordersUIProps.queryParams.pageNumber == 1 ?  ordersUIProps.queryParams.pageNumber:ordersUIProps.queryParams.pageNumber - 1  ,
  };

  function pageHandler (paginationProps){
    
    const pagenumber = paginationProps?.page || 1
    const sizePerPage = paginationProps?.sizePerPage
    ordersUIProps.setQueryParams(
      (prev) =>
      "pagination" === "pagination"
        ? { ...prev, pageNumber:pagenumber, pageSize: sizePerPage }
        : prev
    )    
  }

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bordered={false}
                bootstrap4
                keyField="id"
                data={tableData === null ? [] : tableData}
                columns={columns}
                // defaultSorted={uiHelpers.defaultSorted}
                onTableChange={pageHandler(paginationProps)
                }
                rowStyle={{ cursor: 'pointer' }}
                rowClasses={row => `bg-light-${uiHelpers.ProformaStatusCssClasses[row.status_id]}`}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={tableData} />
                <NoRecordsFoundMessage entities={tableData} />
              </BootstrapTable>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <PaginationLinks paginationProps={paginationProps} />
                <div className="ml-auto">
                <SizePerPageDropdownStandalone
                    { ...paginationProps }
                  />
                  <PaginationTotalStandalone
                    { ...paginationProps }
                  />
                </div>
              </div>
            </>
          );
        }}
      </PaginationProvider>
    </>
  );
}
