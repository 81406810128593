import {createSlice} from "@reduxjs/toolkit";

const initialPaymentTypesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  paymentTypeForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const paymentTypesSlice = createSlice({
  name: "paymentTypes",
  initialState: initialPaymentTypesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getPaymentTypeById
    paymentTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.paymentTypeForEdit = action.payload.paymentTypeForEdit;
      state.error = null;
    },
    // findPaymentTypes
    paymentTypesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.paymentListLoading = false;
      state.paymenterror = null;
      state.paymentEntities = entities;
      state.paymentTotalCount = totalCount;
    },
    // createPaymentType
    paymentTypeCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.paymentType);
    },
    // updatePaymentType
    paymentTypeUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.paymentType.id) {
          return action.payload.paymentType;
        }
        return entity;
      });
    },
    // deletePaymentType
    paymentTypeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deletePaymentTypes
    paymentTypesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
