import React, { useEffect, useMemo, useState } from "react";
import {Modal, Nav} from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {Input, ModalProgressBar, TextArea} from "./../../../../../../_metronic/_partials/controls";
import {PaymentTypes, initProforma, ProformaStatusTitles} from "../SalesUIHelper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as proformaActions from './../../../../_redux/proformas/proformasActions';
import { useSalesUIContext } from './..//SalesUIContext'
import { useProformasUIContext } from "../../car-proformas/ProformasUIContext";
import AsyncSearchBar from "../../../shared/AsyncSelectBar";
import NumberFormat from 'react-number-format';
import { clearSalesForm } from "../../../../_redux/sales/salesActions";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { DatePicker } from '@mantine/dates';
import {useHistory} from "react-router-dom"
import moment from "moment";
import { SalesTable_1 } from "../SalesTable_1"
import { FormControlLabel, Checkbox} from "@material-ui/core";
import uuid from 'react-uuid';
// Validation schema
const SaleEditSchema = Yup.object().shape({
    //   proforma: Yup.object().shape({
    //     customer:Yup.object().shape({ name:Yup.string().required("Customer is required")}).nullable().required("Customer is required"),
    //     employee_id: Yup.string().required("Sales person is required")
    //     .test('Is not zero', 'Sales person is required', (value) => value != 0),
    //     proformaItems: Yup.array().of(
    //       Yup.object().shape({
    //         sales_amount: Yup.number().required("Sales amount is required")
    //       })
    //     )
    //   }),
    sale: Yup.object().shape({
        clearForm: Yup.boolean(),
        bank_id: Yup.string().when('clearForm', {
            is: false,
            then: Yup.string().required("Bank is required"),
            otherwise: Yup.string().notRequired().nullable()
        }),
        payment_type_id: Yup.string().when('clearForm', {
            is: false,
            then: Yup.string().required("Payment type is required"),
            otherwise: Yup.string().notRequired().nullable()
        }),
        // bank_id: Yup.string().required("Bank is required"),
        // payment_type_id: Yup.string().required("Payment type is required"),
        // amount: Yup.number().required("Amount is required"),
        amount: Yup.number().when('clearForm', {
            is: false,
            then: Yup.number().required("Amount is required"),
            otherwise: Yup.number().notRequired().nullable()
          }),
        payment_date: Yup.date().nullable().required("Payment date is required"),
    }),
});

export function SaleEditForm_1({
  saveSale,
  sale, salesInProforma,
  setTopTab,
   saveListPayment
}) {
    const [finish, setFinish] = useState(true)
    const [paymentConfirm,setPaymentConfirm]  = useState(false)
    const history = useHistory();
    const salesUIContext = useSalesUIContext();
    const [totalSumError,setTSE] = useState(false)
    const specsUIContext = useSalesUIContext();
    const salesUIProps = useMemo(() => {
    return {
      ids: salesUIContext.ids,
      setIds: salesUIContext.setIds,
      queryParams: salesUIContext.queryParams,
      setQueryParams: salesUIContext.setQueryParams,
      openEditSaleDialog: salesUIContext.openEditSaleDialog,
      openDeleteSaleDialog: salesUIContext.openDeleteSaleDialog,
      initSale: salesUIContext.initSale,
      productId: salesUIContext.productId,
      finishSale: salesUIContext.finishSale,
      setFinishSale: salesUIContext.setFinishSale,
        openCompleteSaleDialog: specsUIContext.openCompleteSaleDialog,

    };
  }, [salesUIContext]);

  const proformasUIContext = useProformasUIContext();
  const proformasUIProps = useMemo(() => {
    return {
      onHide: proformasUIContext.closeEditProformaDialog,
      currentProforma: proformasUIContext.currentProforma,
      setCurrentProforma: proformasUIContext.setCurrentProforma,
    };
  }, [proformasUIContext]);

  const { proformaForEdit, banks, customerEntities, saleEntities, validProforma, carForEdit, currentProductId } = useSelector(
    (state) => ({
      carForEdit: state.cars.carForEdit,
      proformaForEdit: state.proformas.proformaForEdit || initProforma,
      proformaLoading: state.proformas.actionsLoading,
      saleEntities: state.sales.saleEntities,
      banks: state.banks.bankEntities,
      validProforma: state.proformas.validProforma,
      currentProductId: state.cars.currentId
    }),
    shallowEqual
  );
    const { currentState } = useSelector(
        (state) => ({ currentState: state }),
        shallowEqual
    );


    const productId = salesUIProps.productId

  const dispatch = useDispatch();
  const {proformaItems} = currentState.proformas;
  const [pI,setPI] = useState(null);
  const [totalAmount,setTA] = useState(0);
  const [totalPayment,setP] = useState(0);
  const [oldUUID,setOldUUID] = useState(0)
  const [valueToSendC,setValueToSendC] = useState(null)
  const [saleListModal,setSaleListModal] = useState(false)
    useEffect(() => {
        let amou = 0;
        proformaItems?.map((item)=>{
            amou  = amou+item.sales_amount+item.gps_amount
        })
        setPI(proformaItems)
        setTA(amou)
    }, [proformaItems]);

  useEffect(() => {
    dispatch(proformaActions.fetchProformasInCar(productId))
  }, [dispatch]);

  useEffect(() => {
    if(proformasUIProps.currentProforma?.id == 0 && proformaForEdit?.id != 0) {
        proformasUIProps.setCurrentProforma(proformaForEdit)
    }
  }, [proformaForEdit])


    const proValues = (values) =>{
        let proItem = [...pI];
        let amt = values?.sale?.amount;
        setP(amt)
        let allSales
        if(salesInProforma && salesInProforma.length > 0)
            allSales = [...salesInProforma]
        if (values.sale.id){
            let currentSales = salesInProforma.filter((item)=>item.id === values.sale.id)
            if(currentSales[0]?.common_payment){
                currentSales = salesInProforma.filter((item)=>item.common_payment === currentSales[0]?.common_payment)
            }
            const updatedProItem = proItem.map((item, index) => {
                const paidAmount = allSales && allSales
                    .filter((c) => c.proforma_item_id === item.id)
                    .reduce((total, sale) => total + sale.amount, 0);

                return {
                    ...item,
                    new_amount: currentSales.filter((c) => c.proforma_item_id === item.id)[0]?.amount || 0,
                    paidAmount: paidAmount || 0
                };
            });

            setPI(updatedProItem);
        }
        else{

            const updatedProItem = proItem.map((item,index) => {
                const paidAmount = allSales && allSales
                    .filter((c) => c.proforma_item_id === item.id)
                    .reduce((total, sale) => total + sale.amount, 0);

                //if (amt >= 0) {
                    // let amount = 0
                    // if(index === proItem.length - 1)
                    //     amount = amt
                    // else if(amt >= (item.sales_amount + item.gps_amount))
                    //     amount = item.sales_amount + item.gps_amount
                    // else
                    //     amount = amt
                    // amt = amt - (item.sales_amount + item.gps_amount);
                    return {
                        ...item,
                        new_amount : 0,
                        paidAmount: paidAmount || 0
                    };
               // }
                return item;
            });
            setPI(updatedProItem);
        }
    }

    const handleAllocate = (id,value) =>{
        setTSE(false);
        setPI(pI.map((item)=>{
              if(item.product_id === id){
                return {...item,
                    new_amount:parseInt(value)
                }
              }
              return item;
              })

          )

    }
    const REFUND_ID = PaymentTypes.find(p => p.name.toLocaleLowerCase() === 'refund').id
    const collectedAmount = salesInProforma && salesInProforma.reduce((accumulator, current) => accumulator + current.amount, 0) || 0
    const handleS = (values) =>{
        const proItem = [...pI.filter((item)=>item.new_amount !== 0 && item.new_amount !== undefined && item.new_amount !== null)]
        let amt = proItem.reduce((sum,entry)=>sum + parseInt((entry.new_amount || 0)),0)
        amt = amt < 0 ? amt*-1:amt
        if(parseInt(amt) !== parseInt(totalPayment)){
            setTSE(true)
            return false;
        }else{
        setPaymentConfirm(false)
        const paymentUUID = uuid();
        let oldPaymentUUID = values?.sale.common_payment
        const sales = proItem.map((item)=>{
            return {...values.sale,
                amount:(values.sale.payment_type_id === 3) ? -Math.abs(item.new_amount):item.new_amount,
                id:undefined,
                common_payment:paymentUUID,
                proforma_id: item.proforma_id,
                proforma_item_id: item.id,
                payment_date: moment(values.sale.payment_date).format('YYYY-MM-DD'),
                last_updated: values.sale.last_updated ? moment(values.sale.last_updated).format('YYYY-MM-DD') : "",
            }
        })
        const value = {...values}
        value.sale = {
            ...value.sale,
            common_payment:paymentUUID,
            amount: (value.sale.payment_type_id === 3) ? -value.sale.amount : value.sale.amount,
            payment_date: moment(value.sale.payment_date).format('YYYY-MM-DD'),
            last_updated: value.sale.last_updated ? moment(value.sale.last_updated).format('YYYY-MM-DD') : "",
        }
        const valuesToSend = {...value,sales};
        let collected = collectedAmount;
        const expected = parseInt(totalAmount);
        let paid = parseInt(totalPayment);
        if(valuesToSend.sale.payment_type_id === REFUND_ID) {
            paid *= -1
        }

        const collectedNew = salesInProforma && salesInProforma.reduce((accumulator, current) => {
            // if (current.id === valuesToSend.sale.id) {
            //     return accumulator + paid;
            // } else
            if(current.common_payment != oldPaymentUUID) {
                return accumulator + current.amount;
            }else{
                return accumulator;
            }
        }, 0);
        const totalPaid = paid + collectedNew
        const excessFound = totalPaid > expected

        // Check if excess is found only if proforma is not expired or cancelled
        if((valuesToSend.proforma.status_id == 1 || valuesToSend.proforma.status_id == 3) && excessFound) {
            setValueToSendC(valuesToSend);
            setOldUUID(oldPaymentUUID);
            setSaleListModal(true);
            return true
        }

        !valuesToSend.proforma.location_id && (valuesToSend.proforma.location_id = valuesToSend.proforma.proformaItems[0].location_id)
        saveListPayment(valuesToSend,oldPaymentUUID)
        finish && proformasUIProps.onHide()
        if((valuesToSend.proforma.status_id == 1 || valuesToSend.proforma.status_id == 3)) {
            if (totalPaid >= expected) {
                valuesToSend && salesUIProps.openCompleteSaleDialog(valuesToSend?.proforma?.id)
            }
        }
        }
    }

    const continueSaleList = () =>{
        saveListPayment(valueToSendC,oldUUID)
        salesUIProps.openCompleteSaleDialog(valueToSendC?.proforma.id)
        setSaleListModal(false);
        finish && proformasUIProps.onHide()
    }

  const proformaItemIndex = proformaForEdit.proformaItems.findIndex(p => p.product_id == productId)

  return (
    <div className="container">
        <Formik
            enableReinitialize={true}
            // initialValues={{sale: sale, proforma: {...proformaForEdit, nestedField: true}}}
            initialValues={{sale: sale, proforma: {...proformasUIProps.currentProforma, nestedField: true}}}
            validationSchema={SaleEditSchema}
            onSubmit={async (values, {setSubmitting}) => {
                await salesUIProps.setFinishSale(finish)
                values = {
                    ...values,
                    proforma: {
                    ...values.proforma,
                    // proformaItems: values.proforma.proformaItems.map(pi => ({...pi, status_id: ProformaStatusTitles[0].id})),
                    proformaItems: values.proforma.proformaItems.map(pi => ({...pi, status_id: values.proforma.status_id})),
                    amount: values.proforma.proformaItems[proformaItemIndex]?.sales_amount
                    }
                }
                const continue_sale = await saveSale(values);
                await setSubmitting(true)
                await !continue_sale && finish && proformasUIProps.onHide()
                // await !continue_sale && salesUIProps.finishSale && proformasUIProps.onHide()
                // setFinish(true)
            }}
        >
            {({ handleSubmit, initialValues, values, errors, touched, setFieldTouched, setFieldValue,isSubmitting,validateForm }) => (
            <BlockUi blocking={isSubmitting}>
                <div className="row flex-column-reverse flex-lg-row">
                    <div className="col-lg-7">
                        <div className="d-flex justify-content-between pb-15">
                            <div className="d-flex flex-column flex-root">
                                <span className="font-weight-bolder mb-2">ORDER DATE</span>
                                {/* <span className="opacity-70">{moment(proformaForEdit?.proforma_date).format('DD/MM/yyyy')}</span> */}
                                <span className="opacity-70">{moment(proformasUIProps.currentProforma?.proforma_date).format('DD/MM/yyyy')}</span>
                            </div>
                            <div className="d-flex flex-column flex-root">
                                <span className="font-weight-bolder mb-2">ORDER NO.</span>
                                {/* <span className="opacity-70">P {proformaForEdit?.id}</span> */}
                                <span className="opacity-70">{proformasUIProps.currentProforma?.id != 0 && proformasUIProps.currentProforma?.id}</span>
                            </div>
                            <div className="d-flex flex-column flex-root">
                                <span className="font-weight-bolder mb-2">CUSTOMER</span>
                                {/* <span className="opacity-70">{proformaForEdit?.customer?.name} */}
                                <span className="opacity-70">{proformasUIProps.currentProforma?.customer?.name}
                                {/* <br/>{proformaForEdit?.customer?.tin}</span> */}
                                <br/>{proformasUIProps.currentProforma?.customer?.tin}</span>
                            </div>
                        </div>
                        <Form className="form form-label-right">
                            <div className="form-group row">
                                <div className="col-lg-6">
                                    <div className="d-flex flex-column">
                                        <label  htmlFor="payment_date">
                                            Payment Date <span className="text-danger">*</span>
                                        </label>
                                        <DatePicker
                                            name="sale.payment_date"
                                            inputFormat="DD/MM/YYYY"
                                            zIndex={10000}
                                            onChange={(val) => {
                                                setFieldValue("sale.payment_date", val);
                                            }}
                                            value={values.sale.payment_date}
                                            touched={{payment_date: touched.sale?.payment_date}}
                                            errors={{payment_date: errors.sale?.payment_date}}
                                            required
                                        />
                                        {errors.sale?.payment_date && touched.sale?.payment_date && (
                                            <div className="invalid-feedback d-block">{errors.sale?.payment_date}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <label  htmlFor="proforma_amount">
                                        Payment Amount <span className="text-danger">*</span>
                                    </label>
                                    <NumberFormat
                                        allowNegative={false}
                                        id="amount"
                                        onValueChange={values => {
                                        setFieldValue('sale.amount', values.value)
                                        }}
                                        onBlur={() => {
                                        setFieldTouched('sale.amount', true, true)
                                        }}
                                        value = {values.sale.amount}
                                        className={(errors.sale?.amount && touched.sale?.amount) ? "form-control is-invalid" : "form-control"}
                                        thousandSeparator={true}
                                    />
                                    {errors.sale?.amount && touched.sale?.amount && (
                                        <div className="invalid-feedback d-block">{errors.sale?.amount}</div>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-6">
                                    <label  htmlFor="bank_id">Bank <span className="text-danger">*</span></label>
                                    <AsyncSearchBar
                                        selectedItem={{id: values.sale.bank_id}}
                                        setSelectedItem={setFieldValue}
                                        setTouchedItem={setFieldTouched}
                                        defaultItem={banks?.find(e => e.id == initialValues.sale.bank_id)?.bankName}
                                        error={errors.sale?.bank_id}
                                        touched={touched.sale?.bank_id}
                                        item_id="sale.bank_id"
                                        item_name="bankName"
                                        url={`${process.env.REACT_APP_AUTO_DEALER_API}proforma/searchBanks`}
                                        clear={values.sale.bank_id == ""}
                                    />
                                    {(errors.sale?.bank_id && touched.sale?.bank_id) && (
                                        <div className="invalid-feedback d-block">Bank is required</div>
                                    )}
                                </div>
                                <div className="col-lg-6">
                                    <label  id="aria-label" htmlFor="aria-example-input">
                                        Payment Type <span className="text-danger">*</span>
                                    </label>
                                    <AsyncSearchBar
                                        selectedItem={{id: values.sale.payment_type_id}}
                                        setSelectedItem={setFieldValue}
                                        setTouchedItem={setFieldTouched}
                                        defaultItem={PaymentTypes.find(p => p.id == initialValues.sale.payment_type_id)?.name}
                                        error={errors.sale?.payment_type_id}
                                        touched={touched.sale?.payment_type_id}
                                        item_id="sale.payment_type_id"
                                        item_name="name"
                                        options={PaymentTypes}
                                        isSearchable={false}
                                        clear={values.sale.payment_type_id == ""}
                                    />
                                    {errors.sale?.payment_type_id && touched.sale?.payment_type_id && (
                                        <div className="invalid-feedback d-block">{errors.sale?.payment_type_id}</div>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-6">
                                    <Field
                                        name="sale.remark"
                                        component={TextArea}
                                        placeholder="remark"
                                        label="Remark"
                                    />
                                </div>
                            </div>
                            <button
                                type="button"
                                onClick={() => {
                                    setFieldValue('sale.clearForm', true, false)
                                    setFieldValue('sale.payment_date', new Date(), false)
                                    setFieldValue('sale.amount', '', false)
                                    setFieldValue('sale.bank_id', "", false)
                                    setFieldValue('sale.payment_type_id', "", false)
                                    setFieldValue('sale.remark', "", false)
                                    dispatch(clearSalesForm())
                                }}
                                className="btn btn-light btn-elevate mb-2"
                            >Clear</button>
                            {/* <div className="col-lg-10 checkbox-inline">
                                <label className="pr-3 pt-3">Add another payment? </label>
                                <FormControlLabel
                                    checked={!finish}
                                    onChange={() => setFinish(!finish)}
                                    control={<Checkbox style={{color:'#0BB783'}}/>}
                                />
                            </div> */}
                        </Form>
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-4 flex-column">
                        <div className="card card-custom gutter-b" style={{backgroundColor: '#F5F3F8'}}>
                            <div className="card-body p-1">
                                {/* <SalesTable_1 saleEntities={sales} /> */}
                                <SalesTable_1 salesInProforma={salesInProforma} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex border-top pt-4 mx-2">
                    <div className="ml-md-auto checkbox-inline">
                        <label className="pr-3 pt-3 mb-0">Add another payment? </label>
                        <FormControlLabel
                            checked={!finish}
                            // checked={!salesUIProps.finishSale}
                            onChange={() => setFinish(!finish)}
                            // onChange={() => salesUIProps.setFinishSale(!salesUIProps.finishSale)}
                            control={<Checkbox style={{color:'#0BB783'}}/>}
                            className="mr-0 mb-0"
                        />
                    </div>
                </div>
                <Modal.Footer className="d-flex justify-content-between px-0 border-0 pt-0">
                    <div className="mr-2">
                        <button type="button" className="btn btn-light-primary btn-elevate" onClick={()=> setTopTab('order')}>Previous</button>
                    </div>
                    <div>
                        {sale.id &&
                        <button
                            type="button"
                            onClick={() => salesUIProps.openDeleteSaleDialog(sale.id,sale?.common_payment)}
                            className="btn btn-danger btn-elevate ml-auto mr-2"
                        >Delete</button>}
                        <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            data-wizard-type="action-next"
                            onClick={async () => {

                                await setFieldValue('sale.clearForm', false, false)
                                if(proformaItems.length > 1) {
                                    if( !values?.sale?.amount
                                        || !values?.sale?.bank_id
                                        || !values?.sale?.payment_type_id
                                    ){
                                        handleSubmit()
                                    }else{
                                    proValues(values);
                                    setPaymentConfirm(true);}
                                }else
                                handleSubmit()
                            }}
                        >Add</button>
                        {/* <button
                            type="submit"
                            className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                            data-wizard-type="action-next"
                            // onClick={async () => {
                            //     await setFinish(true)
                            //     await setFieldValue('sale.clearForm', false, false)
                            //     handleSubmit()
                            // }}
                            onClick={()=> proformasUIProps.onHide()}
                        >Finish</button> */}
                    </div>
                </Modal.Footer>
                <Modal
                    show={paymentConfirm}
                    hide={paymentConfirm}
                    size="lg"
                    aria-labelledby="example-modal-sizes-title-lg">
                    <Modal.Header closeButton><Modal.Title id="example-modal-sizes-title-lg">Allocate Payment</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        <table className="table  table-vertical-center ">
                            <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>Amount</td>
                                    <td>Paid</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody >
                            {pI && pI.map((item) => {
                                    return (
                                        <tr>
                                            <td>{item?.productName}</td>
                                            <td>
                                                <NumberFormat
                                                    id={item?.productName}
                                                    defaultValue= {item?.sales_amount + item?.gps_amount}
                                                    displayType={"text"}
                                                    thousandSeparator={true}

                                                /></td>
                                            <td>
                                                <NumberFormat
                                                    id={item?.productName}
                                                    defaultValue= {item?.paidAmount}
                                                    displayType={"text"}
                                                    thousandSeparator={true}

                                                /></td>
                                            <td><NumberFormat
                                                allowNegative={false}
                                                id={item?.productName}
                                                onValueChange={values => {
                                                    handleAllocate(item?.product_id,values.value)
                                                }}
                                                defaultValue= {item?.new_amount}
                                                className={ "form-control"}
                                                displayType={"input"}
                                                thousandSeparator={true}

                                            /></td>

                                        </tr>)
                                }
                            )
                            }
                            <tr>
                                <td>Total</td>
                                <td>
                                {/*    <NumberFormat*/}



                                {/*    defaultValue= {totalAmount}*/}
                                {/*    displayType={"text"}*/}
                                {/*    thousandSeparator={true}*/}

                                {/*/>*/}
                                </td>
                                <td></td>
                                <td>
                                    <NumberFormat



                                    defaultValue= {totalPayment}
                                    displayType={"text"}
                                    thousandSeparator={true}

                                />

                                </td>
                            </tr>

                            </tbody>
                        </table>
                        {totalSumError  && (
                            <div className="text-danger" style={{display:"inline !important"}}>{' '} The Sum Must match {' '}
                                <NumberFormat

                                    defaultValue= {totalPayment}
                                    displayType={"text"}
                                    thousandSeparator={true}

                                /></div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <>
                            <button
                                onClick={() =>handleS(values)}
                                className="btn btn-primary btn-elevate "
                            >
                                Add
                            </button>
                            <button
                                type="button"
                                onClick={()=>{setPaymentConfirm(false);//hideValidationMessage()
                                }}
                                className="btn btn-light btn-elevate mr-auto"
                            >
                                Cancel
                            </button>

                        </>
                    </Modal.Footer>
                </Modal>
            </BlockUi>
            )}

        </Formik>
        <Modal
            show={saleListModal}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <ModalProgressBar variant="query" />
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Sale Continue
                </Modal.Title>
                <Nav className="ml-auto" onClick={() => {setSaleListModal(false);
                    setValueToSendC(null);
                    setOldUUID(false);} }>
                    <Nav.Link className="p-0 text-dark">
            <span className="nav-icon">
              <span className="svg-icon svg-icon-2x">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                        <rect x="0" y="7" width="16" height="2" rx="1"/>
                        <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </span>
                    </Nav.Link>
                </Nav>
            </Modal.Header>
            <Modal.Body>

                    <p>Collected Amount has exceeded the Sales Amount. <br/>Are you sure you want to continue?</p>

            </Modal.Body>
            <Modal.Footer>
                <div className="mr-auto">
                    <button
                        type="button"
                        onClick={continueSaleList}
                        className="btn btn-primary btn-elevate"
                    >
                        Yes
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={() => {
                            setSaleListModal(false);
                            setValueToSendC(null);
                            setOldUUID(false);
                        }}
                        className="btn btn-light btn-elevate"
                    >
                        No
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    </div>
  );
}
