import {createSlice} from "@reduxjs/toolkit";

const initialTenantsState = {
  tenantListLoading: false,
  tenantActionsLoading: false,
  tenantTotalCount: 0,
  tenantEntities: null,
  tenantForEdit: undefined,
  lastError: null,
  subscriptionsEntities:null,
  subscriptionsListLoading:false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const tenantsSlice = createSlice({
  name: "tenants",
  initialState: initialTenantsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.tenantListLoading = false;
      } else {
        state.tenantActionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.tenantListLoading = true;
      } else {
        state.tenantActionsLoading = true;
      }
    },
    endCall:(state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.tenantListLoading = false;
      } else {
        state.tenantActionsLoading = false;
      }
    },
    
    // getTenantById
    tenantFetched: (state, action) => {
      state.tenantActionsLoading = false;
      state.tenantForEdit = action.payload.tenantForEdit;
      state.error = null;
    },
    // findTenants
    tenantsFetched: (state, action) => {
      const { totalCount, tenantEntities } = action.payload;
      state.tenantListLoading = false;
      state.error = null;
      state.tenantEntities = tenantEntities;
      state.totalCount = totalCount;
    },
    // createTenant
    tenantCreated: (state, action) => {
      state.tenantActionsLoading = false;
      state.error = null;
      state.tenantEntities.push(action.payload.tenant);
    },
    // updateTenant
    tenantUpdated: (state, action) => {
      state.error = null;
      // .then(() => {
    //   dispatch(actions.tenantUpdated({ subscribtion:tenant,tenantClientId:tenant?.client_id }));
    // })
      state.tenantEntities = state.tenantEntities.map((e)=>{
        if(e.tenantClientId === action.payload.tenantClientId){
          //let updatedTenant = e;   
          e.subscription = e.subscription.map((s)=>{
            if(s.id === action.payload.subscription.id)
              return action.payload.subscription
          })
          return e;
        }
          
        return e;
      }) 
    },
    // deleteTenant
    tenantDeleted: (state, action) => {
      state.error = null;
      state.tenantActionsLoading = false;
      state.tenantEntities = state.tenantEntities.filter(el => el.id !== action.payload.id);
    },
    fetchedSubscription:(state,action) =>{
      state.error = null;
      state.tenantListLoading = false;
      state.subscriptionsEntities = action.payload.subscriptions;
    },

    fetchedSubscriptionForReport:(state,action) =>{
      state.error = null;
      state.tenantListLoading = false;
      state.subscriptionsForReport = action.payload.subscriptionsForReport;
    }
    
  }
});
