import React from "react";
import { Route } from "react-router-dom";
import { BatchLoadingDialog } from "./batch-loading-dialog/BatchLoadingDialog";
import { BatchEditDialog } from "./batch-edit-dialog/BatchEditDialog";
import { BatchDeleteDialog } from "./batch-delete-dialog/BatchDeleteDialog";
import { BatchFetchDialog } from "./batch-fetch-dialog/BatchFetchDialog";
import { BatchUIProvider } from "./BatchUIContext";
import { BatchCard } from "./BatchCard";

export function BatchPage({ history }) {
  const batchUIEvents = {
    newBatchButtonClick: () => {
      history.push("/batch/new");
    },
    openEditBatchDialog: (id) => {
      history.push(`/batch/${id}/edit`);
    },
    openDeleteBatchDialog: (id) => {
      history.push(`/batch/${id}/delete`);
    },
    openFetchBatchDialog: () => {
      history.push(`/batch/fetch`);
    }
  }

  return (
    <BatchUIProvider batchUIEvents={batchUIEvents}>
      <BatchLoadingDialog />
      <Route path="/batch/new">
        {({ history, match }) => (
          <BatchEditDialog
            show={match != null}
            onHide={() => {
              history.push("/batch");
            }}
          />
        )}
      </Route>
      <Route path="/batch/:id/edit">
        {({ history, match }) => (
          <BatchEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/batch");
            }}
          />
        )}
      </Route>
      
      <Route path="/batch/:id/delete">
        {({ history, match }) => (
          <BatchDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/batch");
            }}
          />
        )}
      </Route>
      <Route path="/batch/fetch">
        {({ history, match }) => (
          <BatchFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/batch");
            }}
          />
        )}
      </Route>
      
      <BatchCard />
    </BatchUIProvider>
  );
}
