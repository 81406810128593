import * as requestFromServer from "./accountsCrud";
import {accountsSlice, callTypes, options} from "./accountsSlice";

const {actions} = accountsSlice;

// fetch accounts
export const fetchAccounts = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  
  return requestFromServer
    .getAllAccounts()
    .then(response => {
      const accountEntities  = response.data;
      const accountTotalCount = response.data.length;
      dispatch(actions.accountsFetched({ accountTotalCount, accountEntities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find accounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

// create account
export const createAccount = accountForCreation => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .createAccount(accountForCreation)
      .then(response => {
        const { data: account } = response;
        dispatch(actions.accountCreated({ account }));
      })
      .catch(error => {
        error.clientMessage = "Can't create account";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// update account
export const updateAccount = accountForUpdate => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .updateAccount(accountForUpdate)
      .then(response => {
        const { data: account } = response;
        dispatch(actions.accountUpdated({ account }));
      })
      .catch(error => {
        error.clientMessage = "Can't create account";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// delete account validation
export const deleteValidation = (id, setValidation) => dispatch => {
  if (!id) return false; 

  return requestFromServer
    .getDeleteValidationById(id)
    .then(response => {
      setValidation(response.data)
    })
    .catch(error => {
      error.clientMessage = "Can't request validation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// delete account
export const deleteAccount = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAccount(id)
    .then(response => {
      dispatch(actions.accountDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete account";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// fetch account
export const fetchAccount = (id) => dispatch => {
  if (!id) {
    return dispatch(
      actions.accountFetched({ accountForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  
  return requestFromServer
    .getAccount(id)
    .then(response => {
      const accountForEdit  = response.data;
      dispatch(actions.accountFetched({ accountForEdit}));
    })
    .catch(error => {
      error.clientMessage = "Can't find accounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
  });
};

// fetch transactions in account
export const fetchTransactionsInAccount = (id) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list, option: options.transaction}));
    
    return requestFromServer
      .getAllTransactionsInAccount(id)
      .then(response => {
        const transactionEntities  = response.data;
        const transactionTotalCount = response.data.length;
        dispatch(actions.transactionsInAccountFetched({ transactionTotalCount, transactionEntities }));
      })
      .catch(error => {
        error.clientMessage = "Can't find accounts";
        dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
};

// create transaction
export const createTransaction = transactionForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action, option: options.transaction }));
  return requestFromServer
    .createTransaction(transactionForCreation)
    .then(response => {
      const { data: transaction } = response;
      dispatch(actions.transactionCreated({ transaction }));
    })
    .catch(error => {
      error.clientMessage = "Can't create transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// update transaction
export const updateTransaction = transactionForUpdate => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action, option: options.transaction }));
  return requestFromServer
    .updateTransaction(transactionForUpdate)
    .then(response => {
      const { data: transaction } = response;
      dispatch(actions.transactionUpdated({ transaction }));
    })
    .catch(error => {
      error.clientMessage = "Can't create transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// fetch transaction
export const fetchTransaction = (id) => dispatch => {
  if (!id) {
    return dispatch(
      actions.transactionFetched({ transactionForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action, option: options.transaction }));
  
  return requestFromServer
    .getTransaction(id)
    .then(response => {
      let transactionForEdit  = response.data;
      transactionForEdit.date = new Date(transactionForEdit?.date)
      dispatch(actions.transactionFetched({ transactionForEdit}));
    })
    .catch(error => {
      error.clientMessage = "Can't find accounts";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// delete transaction
export const deleteTransaction = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTransaction(id)
    .then(response => {
      dispatch(actions.transactionDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete account";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// close transactions
export const closeTransactions = account_id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .closeTransactions(account_id)
    .then(response => {
      dispatch(actions.transactionsClosed());
    })
    .catch(error => {
      error.clientMessage = "Can't close transactions";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// clear option
export const clearOption = (optionType) => dispatch => {
  if(optionType === options.account) {
    dispatch(
      actions.accountFetched({ accountForEdit: undefined })
    );
  } 
  if(optionType === options.transaction) {
    dispatch(
      actions.transactionFetched({ transactionForEdit: undefined })
    );
  } 
}

// fetch closed transaction groups in account
export const fetchClosedTransactionGroupsInAccount = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list, option: options.transaction}));
  
  return requestFromServer
    .getAllClosedTransactionGroupsInAccount(id)
    .then(response => {
      const closedTransactionEntities_grouped  = response.data;
      dispatch(actions.closedTransactionGroupsInAccountFetched({ closedTransactionEntities_grouped }));
    })
    .catch(error => {
      error.clientMessage = "Can't find accounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

// fetch closed transactions in account by closed date
export const fetchClosedTransactionsInAccountByClosedDate = (id, closed_date, closedTransaction) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list, option: options.transaction}));
  
  dispatch(actions.closedTransactionGroupInAccountFetched({closedTransactionForEdit: closedTransaction}))

  return requestFromServer
    .getAllClosedTransactionsInAccountByClosedDate(id, closed_date)
    .then(response => {
      const closedTransactionEntities  = response.data;
      const transactionTotalCount = response.data.length;
      dispatch(actions.closedTransactionsInAccountByClosedDateFetched({ closedTransactionEntities, transactionTotalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find accounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

// clear closed transaction entities
export const clearClosedTransaction = () => dispatch => {
  dispatch(actions.closedTransactionsCleared())
}

// clear transaction entities
export const clearTransaction = () => dispatch => {
  dispatch(actions.transactionsCleared())
}
