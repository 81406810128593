import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { styledColorPickerElements } from "../../CarsUIHelpers";

const { Label, Span } = styledColorPickerElements;

export const ColorColumnFormatter = (cellContent, row) => (
    <Label className= {row.saleStatus == 'Sold' ? "radio radio-accent mr-0 label label-success label-dot mr-2":  row.saleStatus == 'In-Process' ? row.expireDate <= 0 ? "radio radio-accent mr-0 label label-warning label-dot mr-2": "radio radio-accent mr-0 label label-danger label-dot mr-2" : ""} bg={row.saleStatus}>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip>
            {row.saleStatus == 'In-Process' && row.expireDate > 0 ? 'Overdue by '+ row.expireDate +' days':row.saleStatus}
            
          </Tooltip>
        }
      >
          <Span bg={row.saleStatus}></Span>
      </OverlayTrigger>
    </Label>
);
