import React, { useEffect } from "react";
import { Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/accounts/accountsActions";

export function TransactionCloseDialog({ account_id, show, onHide }) {

  // Accounts Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.accounts.accountLoading }),
    shallowEqual
  );

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const closeTransaction = () => {
    // server request for closing transactions
    dispatch(actions.closeTransactions(account_id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchTransactionsInAccount(account_id));
      dispatch(actions.fetchAccount(account_id));
      dispatch(actions.clearClosedTransaction());
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      // onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Transactions Close
        </Modal.Title>
        <Nav className="ml-auto" onClick={() => onHide()}>
          <Nav.Link className="p-0 text-dark">
            <span className="nav-icon">
              <span className="svg-icon svg-icon-2x">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                        <rect x="0" y="7" width="16" height="2" rx="1"/>
                        <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </span>
          </Nav.Link>
        </Nav>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to close transactions?</span>
        )}
        {isLoading && <span>Transactions are closing...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div className="mr-auto">
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={closeTransaction}
            className="btn btn-primary btn-elevate"
          >
            Confirm
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
