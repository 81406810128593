import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/sales/salesActions";
import {createProforma, updateProforma, fetchProformasInCar} from "../../../../_redux/proformas/proformasActions";
import * as carActions from "../../../../_redux/cars/carsActions";
import { fetchCar } from "../../../../_redux/cars/carsActions";
import { SaleEditForm_1 } from "./SaleEditForm_1";
import { ProformaStatusTitles, PaymentTypes } from "../SalesUIHelper";
import { useSalesUIContext,  } from "../SalesUIContext";
import { useCarsUIContext } from "../../CarsUIContext";
import moment from "moment";

export function SaleEditDialog_1({/*saleEntities: sales,*/ salesInProforma, selectedTopTab, setTopTab}) {
  // Sales UI Context
  const salesUIContext = useSalesUIContext();
  const salesUIProps = useMemo(() => {
    return {
      id: salesUIContext.selectedId,
      show: salesUIContext.showEditSaleDialog,
      setValues: salesUIContext.setValues,
      onHide: salesUIContext.closeEditSaleDialog,
      productId: salesUIContext.productId,
      queryParams: salesUIContext.queryParams,
      initSale: salesUIContext.initSale,
      openCompleteSaleDialog: salesUIContext.openCompleteSaleDialog,
      saleFromOutside: salesUIContext.saleFromOutside,
      openContinueSaleDialog: salesUIContext.openContinueSaleDialog,
      isLoading: salesUIContext.isLoading,
      setIsLoading: salesUIContext.setIsLoading,
      currentSale: salesUIContext.currentSale,
      setCurrentSale: salesUIContext.setCurrentSale
    };
  }, [salesUIContext]);

  const carsUIContext = useCarsUIContext();
  const carsUIProps = useMemo(() => {
    return {
      queryParams: carsUIContext.queryParams,
    }
  }, [carsUIContext])

  // Sales Redux state
  const dispatch = useDispatch();
  const { proforma, carForEdit, saleForEdit, actionsLoading, proformaForEdit, /*salesInProforma,*/ validProforma, /*saleEntities*/ } = useSelector(
    (state) => ({
      actionsLoading: state.sales.saleLoading,
      saleForEdit: state.sales.saleForEdit,
      carForEdit: state.cars.carForEdit,
      proforma: (state.proformas.entities && state.proformas.entities.find(p => (p.status_id != ProformaStatusTitles[1].id && p.status_id != ProformaStatusTitles[3].id ))),        /* Select the valid proforma (i.e. not expired, not cancelled) */
      proformaForEdit: state.proformas.proformaForEdit,
      // salesInProforma: state.sales.salesInProforma,
      validProforma: state.proformas.validProforma,
      // saleEntities: state.sales.saleEntities,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server request for getting spec by seleted id
    dispatch(fetchCar(salesUIProps.productId))
    dispatch(fetchProformasInCar(salesUIProps.productId))
    // dispatch(actions.fetchSale(salesUIProps.id));
    // salesUIProps?.productId && dispatch(fetchValidProforma(salesUIProps.productId))
  }, [salesUIProps.id, dispatch, salesUIProps.productId]);

//   useEffect(() => {
//     if (salesUIProps.isLoading == false) {
//       const proformaItemId = proforma.proformaItems?.find(p => p.product_id == salesUIProps.productId)?.id
//       const newSale = {...salesUIProps.currentSale, proforma_id: proforma.id, proforma_item_id: proformaItemId}
//       dispatch(actions.createSaleInCar(newSale)).then(() => {
//         dispatch(actions.fetchSalesInProforma(proforma.id))
//         dispatch(actions.fetchSalesInCar(salesUIProps.productId))
//       })
//     }
//   }, [salesUIProps.isLoading])

  // useEffect(() => {
  //   const totalPaid = salesInProforma && salesInProforma.reduce((accumulator, current) => accumulator + current.amount, 0)
  //   const totalSaleAmount = proformaForEdit && proformaForEdit.proformaItems.reduce(
  //     (accumulator, current) => accumulator + current.sales_amount + current.gps_amount, 0)

  //   salesUIProps.saleFromOutside &&
  //   !carsUIProps.queryParams.filter.initialval  &&
  //   dispatch(carActions.fetchCars(carsUIProps.queryParams));
  //   // salesUIProps.onHide()

  //   // If proforma is not yet processed ask to process
  //   if(proforma?.status_id != ProformaStatusTitles[2].id) {
  //     if (totalPaid >= totalSaleAmount) {
  //       selectedTopTab === 'payment' && proforma && salesUIProps.openCompleteSaleDialog(proforma.id)
  //     }
  //   }
  // }, [salesInProforma])

  // Changes status of car in car list
  useEffect(() => {
    salesUIProps.saleFromOutside &&
    !carsUIProps.queryParams.filter.initialval  &&
    dispatch(carActions.fetchCars(carsUIProps.queryParams));
  }, [salesInProforma])

  const REFUND_ID = PaymentTypes.find(p => p.name.toLocaleLowerCase() === 'refund').id
  // const collectedAmount = saleEntities && saleEntities.reduce((accumulator, current) => accumulator + current.amount, 0) || 0
  const collectedAmount = salesInProforma && salesInProforma.reduce((accumulator, current) => accumulator + current.amount, 0) || 0

  const saveSale = async (value) => {
    const expected = Number(value.proforma.proformaItems[0].sales_amount) + Number(value.proforma.proformaItems[0].gps_amount)
    let collected = collectedAmount;
    if (value.sale.id) {
        // collected = saleEntities && saleEntities.reduce((accumulator, current) => {
        collected = salesInProforma && salesInProforma.reduce((accumulator, current) => {
          if(current.id !== value.sale.id) {
            accumulator = accumulator + current.amount
          }
          return accumulator
        }, 0)
    }
    let paid = Number(value.sale.amount)
    if(value.sale.payment_type_id === REFUND_ID) {
        paid *= -1
    }
    const totalPaid = paid + collected
    const excessFound = totalPaid > expected

    // Check if excess is found only if proforma is not expired or cancelled
    if((value.proforma.status_id == 1 || value.proforma.status_id == 3) && excessFound) {
        salesUIProps.setValues(value)
        salesUIProps.openContinueSaleDialog()
        return true
    }

    value.sale = {
        ...value.sale,
        amount: (value.sale.payment_type_id === 3) ? -value.sale.amount : value.sale.amount,
        payment_date: moment(value.sale.payment_date).format('YYYY-MM-DD'),
        last_updated: value.sale.last_updated ? moment(value.sale.last_updated).format('YYYY-MM-DD') : "",
    }

    !value.proforma.location_id && (value.proforma.location_id = value.proforma.proformaItems[0].location_id)
    // value.proforma.proforma_date = moment(value.proforma.proforma_date).format('YYYY-MM-DD')
    if(value.proforma.id == 0) {
       salesUIProps.setIsLoading(true)
        await dispatch(createProforma(value.proforma)).then(async (res) => {
            const currentProforma = res
            salesUIProps.setCurrentSale(value.sale)
            salesUIProps.setIsLoading(false)
            const proformaItemId = currentProforma.proformaItems?.find(p => p.product_id == salesUIProps.productId)?.id
            const newSale = {...value.sale, proforma_id: currentProforma.id, proforma_item_id: proformaItemId}
            await dispatch(actions.createSaleInCar(newSale)).then(async () => {
                // dispatch(actions.fetchSalesInProforma(proforma.id))
                await dispatch(actions.fetchSalesInProforma(currentProforma?.id)).then(async () => {
                  await dispatch(actions.fetchSalesInCar(salesUIProps.productId)).then(() => {
                    return
                  })
                })
            })
        })
    } else {
        salesUIProps.setIsLoading(true)
        await dispatch(updateProforma(value.proforma, value.proforma.id)).then(async (res) => {
            const currentProforma = res
            salesUIProps.setCurrentSale(value.sale)
            salesUIProps.setIsLoading(false)
            const proformaItemId = currentProforma.proformaItems?.find(p => p.product_id == salesUIProps.productId)?.id
            const newSale = {...value.sale, proforma_id: currentProforma.id, proforma_item_id: proformaItemId}
            if(newSale.id) {
              await dispatch(actions.updateSaleInCar(newSale)).then(async () => {
                // dispatch(actions.fetchSalesInProforma(proforma.id))
                await dispatch(actions.fetchSalesInProforma(currentProforma.id)).then(async () => {
                  await dispatch(actions.fetchSalesInCar(salesUIProps.productId)).then(() => {
                    return
                  })
                })

              })
            }
            else {
              await dispatch(actions.createSaleInCar(newSale)).then(async () => {
                // dispatch(actions.fetchSalesInProforma(proforma.id))
                await dispatch(actions.fetchSalesInProforma(currentProforma.id)).then(async () => {
                  await dispatch(actions.fetchSalesInCar(salesUIProps.productId)).then(() => {
                    return
                  })
                })
              })
            }
        })
    }

    dispatch(fetchProformasInCar(salesUIProps.productId))

    // Ask to complete sale only if proforma is active
    if(value.proforma.status_id == ProformaStatusTitles.find(p => p.name == 'Active')?.id && totalPaid === expected) {
      salesUIProps.openCompleteSaleDialog(value.proforma.id)
      return true
    }

    return false
  };
  const saveListPayment= async (value,oldPaymentUUID)=>{

      if(value.proforma.id == 0) {
          salesUIProps.setIsLoading(true)
          await dispatch(createProforma(value.proforma)).then(async (res) => {
              const currentProforma = res
              salesUIProps.setCurrentSale(value.sale)
              salesUIProps.setIsLoading(false)
              const valueToSend = value.sales.map((item)=>{
                  return{...item,
                        proforma_id:res.id}
              })
              await dispatch(actions.createListSaleInCar(valueToSend,oldPaymentUUID)).then(async () => {
                  // dispatch(actions.fetchSalesInProforma(proforma.id))
                  await dispatch(actions.fetchSalesInProforma(currentProforma?.id)).then(async () => {
                      await dispatch(actions.fetchSalesInCar(salesUIProps.productId)).then(() => {
                          return
                      })
                  })
              })
          })
      }else{
          await dispatch(updateProforma(value.proforma, value.proforma.id)).then(async (res) => {
              const currentProforma = res
              salesUIProps.setCurrentSale(value.sale)
              salesUIProps.setIsLoading(false)


                  await dispatch(actions.createListSaleInCar(value.sales,oldPaymentUUID)).then(async () => {
                      // dispatch(actions.fetchSalesInProforma(proforma.id))
                      await dispatch(actions.fetchSalesInProforma(currentProforma.id)).then(async () => {
                          await dispatch(actions.fetchSalesInCar(salesUIProps.productId)).then(() => {
                              return
                          })
                      })
                  })
                  dispatch(fetchProformasInCar(salesUIProps.productId))
          })
      }
  }

  return (
    // <Modal
    //   size="lg"
    //   show={salesUIProps.show}
    //   // onHide={salesUIProps.onHide}
    //   aria-labelledby="example-modal-sizes-title-lg"
    // >
    <div className="content d-flex flex-column-fluid" id="kt_content">
        <SaleEditForm_1
            saveSale={saveSale}
            saleLoading={actionsLoading}
            sale={saleForEdit || salesUIProps.initSale}
            onHide={salesUIProps.onHide}
            proforma={proforma}
            // saleEntities={sales}
            salesInProforma={salesInProforma}
            setTopTab={setTopTab}
            saveListPayment={saveListPayment}
        />
    </div>
    // </Modal>
  );
}
