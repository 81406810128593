import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { useInspectionsUIContext } from "./InspectionsUIContext";

const prepareFilter = (queryParams, values) => {
  const { searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.value = searchText;
  if (searchText) {
    filter.name = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function InspectionsFilter() {
  // Specs UI Context
  const specsUIContext = useInspectionsUIContext();
  const specsUIProps = useMemo(() => {
    return {
      openNewInspectionDialog: specsUIContext.openNewInspectionDialog,
      setQueryParams: specsUIContext.setQueryParams,
      queryParams: specsUIContext.queryParams,
      carId:specsUIContext.carId
    };
  }, [specsUIContext]);

  const { carForEdit } = useSelector(
    (state) => ({
      carForEdit: state.cars.carForEdit
    }),
    shallowEqual
  );

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(specsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, specsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      specsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <div className="form-filtration">
        <div className="row align-items-center">
          {carForEdit && (
            <>
              <div className="col-md-2 margin-bottom-10-mobile"></div>
                <div className="col-md-7 margin-bottom-10-mobile"></div>
                <div className="col-md-3 text-right margin-bottom-10-mobile mb-3">
                {carForEdit!=null && Object.keys(carForEdit).length === 0 ? <></> :<button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => specsUIProps.openNewInspectionDialog()}
                  >
                    New Inspection
                  </button>}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
