import axios from "axios";

export const SPECIFICATIONS_URL = "api/categories";
export const URL = "http://localhost:8080/api/carmodels";
export const CUSTOMERS_URL = process.env.REACT_APP_AUTO_DEALER_API+'carmodels';

export function getAllCategories(){
  return axios.get(CUSTOMERS_URL)
}
// CREATE =>  POST: add a new categories to the server
export function createCategory(category) {
  return axios.post(CUSTOMERS_URL, { category });
}

// READ
// Server should return filtered categories by categoryId
export function getAllProductCategoriesByCategoryId(categoryId) {
  return axios.get(`${CUSTOMERS_URL}?categoryId=${categoryId}`);
}

export function getCategoryById(categoryId) {
  return axios.get(`${CUSTOMERS_URL}/${categoryId}`);
}

// Server should return sorted/filtered categories and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/categoriesfind/{categoryId}'. Should be 'api/categories/find/{categoryId}'!!!
export function findCategories(queryParams, categoryId) {
  return axios.post(`${CUSTOMERS_URL}find/${categoryId}`, { queryParams });
}

// UPDATE => PUT: update the category
export function updateCategory(category) {
  return axios.put(`${CUSTOMERS_URL}/${category.id}`, {
    category
  });
}

// DELETE => delete the category
export function deleteCategory(categoryId) {
  return axios.delete(`${CUSTOMERS_URL}/${categoryId}`);
}

// DELETE categories by ids
export function deleteCategories(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteCategories`, { ids });
}
