import React from "react";
import { BankDepositLoadingDialog } from "./bank-deposit-loading-dialog/BankDepositLoadingDialog";
import { BankDepositUIProvider } from "./BankDepositUIContext";
import { BankDepositCard } from "./BankDepositCard";


export function BankDepositPage() {
  return (
    <BankDepositUIProvider>
      <BankDepositLoadingDialog />
      <BankDepositCard />
      
    </BankDepositUIProvider>
  );
}
