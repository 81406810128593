import {createSlice} from "@reduxjs/toolkit";

const initialLocationsState = {
  locationListLoading: false,
  locationLoading: false,
  locationTotalCount: 0,
  locationEntities: null,
  locationForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState: initialLocationsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.locationListLoading = false;
      } else {
        state.locationLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.locationListLoading = true;
      } else {
        state.locationLoading = true;
      }
    },
    // getLocationById
    locationFetched: (state, action) => {
      state.locationLoading = false;
      state.locationForEdit = action.payload.locationForEdit;
      state.error = null;
    },
    // findLocations
    locationsFetched: (state, action) => {
      const { locationTotalCount, locationEntities } = action.payload;
      state.locationListLoading = false;
      state.error = null;
      state.locationEntities = locationEntities;
      state.locationTotalCount = locationTotalCount;
    },
    // createLocation
    locationCreated: (state, action) => {
      state.locationLoading = false;
      state.error = null;
      state.locationEntities.push(action.payload.location);
    },
    // updateLocation
    locationUpdated: (state, action) => {
      state.error = null;
      state.locationLoading = false;
      state.locationEntities = state.locationEntities.map(entity => {
        if (entity.id === action.payload.location.id) {
          return action.payload.location;
        }
        return entity;
      });
    },
    // deleteLocation
    locationDeleted: (state, action) => {
      state.error = null;
      state.locationLoading = false;
      state.locationEntities = state.locationEntities.filter(el => el.id !== action.payload.id);
    },
    // deleteLocations
    locationsDeleted: (state, action) => {
      state.error = null;
      state.locationLoading = false;
      state.locationEntities = state.locationEntities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
