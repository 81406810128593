import React, { useMemo } from "react";
import { SalesLoadingDialog } from "./SalesLoadingDialog";
import { SalesDeleteDialog } from "./SalesDeleteDialog";
import { SaleDeleteDialog } from "./SaleDeleteDialog";
import { SaleCompleteDialog } from "./sale-edit-dialog/SaleCompleteDialog";
import { SaleContinueDialog } from "./sale-edit-dialog/SaleContinueDialog";
import {SaleDeleteChangeProforma} from "./sale-edit-dialog/SaleDeleteChangeProforma";
import { useSalesUIContext } from "./SalesUIContext";

export function Sales_1({}) {
  // Sales UI Context
  const specsUIContext = useSalesUIContext();
  const specsUIProps = useMemo(() => {
    return { ids: specsUIContext.ids };
  }, [specsUIContext]);

  return (
    <>
      <SalesLoadingDialog />
      {/* <SaleEditDialog /> */}
      <SaleDeleteDialog />
      <SaleDeleteChangeProforma/>
      <SaleCompleteDialog />
      <SaleContinueDialog />
      <SalesDeleteDialog />
    </>
  );
}
