import * as requestFromServer from "./batchCrud";
import {batchSlice, callTypes} from "./batchSlice";

const {actions} = batchSlice;

export const fetchBatch = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  
  return requestFromServer
    .getAllBatch()
    .then(response => {
      const batchEntities  = response.data;
      const batchTotalCount = response.data.length;
      dispatch(actions.batchFetched({ batchTotalCount, batchEntities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find batch";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchBatchById = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.batchFetchedById({ batchForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBatchById(id)
    .then(response => {
      let batch = response.data;
      batch.date = new Date(batch?.date)
      dispatch(
        actions.batchFetchedById({ batchForEdit: batch })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find batch";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteBatch = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteBatch(id)
    .then(response => {
      dispatch(actions.batchDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete batch";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createBatch = batchForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createBatch(batchForCreation)
    .then(response => {
      const { data:batch } = response;
      dispatch(actions.batchCreated({ batch }));
    })
    .catch(error => {
      error.clientMessage = "Can't create batch";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateBatch = batch => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBatch(batch)
    .then(() => {
      dispatch(actions.batchUpdated({ batch }));
    })
    .catch(error => {
      error.clientMessage = "Can't update batch";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};



export const deleteValidation = (id,setValidation) => dispatch => {
  if (!id) {
    return false;
    
  }
  return requestFromServer
    .getDeleteValidationById(id)
    .then(response => {
      setValidation(response.data)
    })
    .catch(error => {
      error.clientMessage = "Can't request validation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
  };