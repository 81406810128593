import React from 'react';
import { Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import { Formik, Form, Field } from "formik";
import {
    Input 
  } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../../_redux_admin/user/usersActions"
import * as Yup from "yup"

export function UserChangePasswordDialog ({ id, show, onHide }) {

  const ChangePasswordSchema = Yup.object().shape({
    newPassword: Yup
      .string()
      .required('Password  is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})/,
        "Must Contain 7 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      ,
    confirmPassword:Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
  });

  const { isLoading, loggedUser } = useSelector(
    (state) => ({ 
      isLoading: state.userAdmin.userActionsLoading, 
      loggedUser: state.userAdmin.loggedUser
     }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const changePassword = (value) => {
    
    dispatch(actions.changePassword(id,value)).then((res) => {
     
        onHide()
     
    });
  };

  return (
    <Modal
    show={show}
    // onHide={onHide}
    aria-labelledby="example-modal-sizes-title-lg"
  >
    {/*begin::Loading*/}
    {isLoading && <ModalProgressBar />}
    {/*end::Loading*/}
    <Modal.Header closeButton>
      <Modal.Title id="example-modal-sizes-title-lg">
        Change Password
      </Modal.Title>
      <Nav className="ml-auto" onClick={() => onHide()}>
        <Nav.Link className="p-0 text-dark">
          <span className="nav-icon">
            <span className="svg-icon svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                  </g>
                </g>
              </svg>
            </span>
          </span>
        </Nav.Link>
      </Nav>
    </Modal.Header>
    <div className="container text-danger mt-2">{loggedUser?.change_pwd && "You need to set a new password"}</div>
    <Formik
        initialValues={{password:'',newPassword:'',confirmPassword:''}}
        validationSchema={ChangePasswordSchema}
        onSubmit={(values) => {
            changePassword(values)
        }}
     >
        {({ handleSubmit}) => (
          <>
    <Modal.Body>
    <Form className="form form-label-right">
        
                <div className="form-group row">
                <div className="col-lg-10">
                    <Field
                      autoComplete="new-password"
                      name="newPassword"
                      component={Input}
                      placeholder="Password"
                      label="New Password"
                      type="password"
                      withFeedbackLabel= {true}
                      
                    />
                    </div></div>
                    <div className="form-group row">
                    <div className="col-lg-10">
                    <Field
                      autoComplete="new-password"
                      name="confirmPassword"
                      component={Input}
                      placeholder="Password"
                      label="Confirm Password"
                      type="password"
                      withFeedbackLabel= {true}
                     
                    />
                     </div></div>
            </Form>  
    </Modal.Body>
    
    
    <Modal.Footer>
      <div className="mr-auto">
        <button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </button>
        <> </>
        <button
          type="submit"
          onClick={() => handleSubmit()}
          className="btn btn-primary btn-elevate"
        >
          Save
        </button>
      </div>
    </Modal.Footer>
    </>)}
    </Formik>
  </Modal>
  );
};
