import React from 'react'
import {CurrencyToWords} from '../../../shared/CurrencyToWords/currencyToWords'
import NumberFormat from 'react-number-format';
export class ProformaCashContractOfSale extends React.PureComponent {
    render() {
      return (
    <div className='bodyLetter1'>

    <div className="return-address"> 
      
      <time >ቀን {localStorage.date}</time>
    </div>

    <div className='letterSubject1 text-dark font-weight-boldest text-hover-primary display-2'>
    የመኪና ሽያጭ ውል ስምምነት
    </div>
    <div className="sales-intro">    
      <span className ="font-size-h2" >ሻጭ:-  {this.props.content?.supplierName} </span> <br></br>  
      <span className ="font-size-h2" >አድራሻ:-{this.props.content?.supplierAddress} </span><br></br> 
      <span className ="font-size-h2" >ገዢ:- {this.props.content?.customerName} </span> <br></br>
      <span className ="font-size-h2" >አድራሻ:-{this.props.content?.customerAddress} </span><br></br>  
    </div>
    
    
    <div className="content text-dark font-weight-bold text-hover-primary font-size-h2 mt-7"> 
    <p>እኔ ሻጭ በስሜ የገባውን ገና ሰሌዳ ያለጠፈውን  {this.props.content?.cars?.productName} የሆነውን መኪና ሞዴል {this.props.content?.cars?.modelYear} የሻንሲ ቁጥር {this.props.content?.cars?.chassis} &nbsp; የሆነውን የነዳጅ አይነት ቤንዚን የሆነውን መኪና ለገዢ &nbsp; 
    <NumberFormat value={this.props.content?.price-'.00'} thousandSeparator="," displayType='text'></NumberFormat> ( {CurrencyToWords(this.props.content?.price,'ብር')} ) ሸጬላቸዋለሁ በዛሬው እለት የተከፈለ <NumberFormat value={(this.props.content?.advance || 0)+'.00'} thousandSeparator="," displayType='text'></NumberFormat>( {CurrencyToWords(this.props.content?.advance,'ብር')} )
    በባንክ አካውንት ገቢ አድርገዋል።   
     </p>
     <p>
     እኔ {this.props.content?.customerName}ከዚህ በላይ በተፈፀመው ውል መሰረት ተስማምቼ መኪናውን ስገዛ በዛሬው እለት   <NumberFormat value={this.props.content?.price+'.00'} thousandSeparator="," displayType='text'></NumberFormat> ( {CurrencyToWords(this.props.content?.price,'ብር')} ) 
     ያስገባሁ ሲሆን ቀሪውን ብር  <NumberFormat value={(this.props.content?.price-this.props.content?.advance)+'.00'} thousandSeparator="," displayType='text'></NumberFormat> ( {CurrencyToWords((this.props.content?.price-this.props.content?.advance),'ብር')} )
     {this.props.proforma?.expire ? ` በ${CurrencyToWords(this.props.proforma?.expire,'')} (${this.props.proforma?.expire}) ቀን ውስጥ` : ""} ለመክፈል እና መኪናውን ባለበት ሁኔታ ልረከበው ተስማምቼ የገዛሁ መሆኔን በተለመደው ፊርማዬ አረጋግጣለሁ።
    </p>
    <p>
    ይህ ውል በፍ/ብ/ሕግ ቁ፡731 ፣ 2005 እና 226 መሰረት በሕግ ፊት የፀና ሲሆን ከዚህ ውል ውጪ የሚወጣ ወገን ቢኖር ውል ለፈረሰበት ወገን 50,000 ብር (ሃምሳ ሺ ብር) ከፍሎ ውሉ በፍ/ብ/ሕግ ፊት ቀርቦ የፀና ሊሆን ተስማምተናል።
    </p>
    <p>
    {this.props.proforma?.expire ? `ይህ የሽያጭ ስምምነት በ${CurrencyToWords(this.props.proforma?.expire,'')} (${this.props.proforma?.expire}) ቀናት ውስጥ ካልተጠናቀቀ ውሉ እንደፈርሰ ይቆጠራል።` : ''} 
    </p>
    <p>
    ይህ መኪና ሽያጭ ውል ስምምነት ሲፈፀም የነበሩ የሰው ምስክሮች ስምና ፊርማ
    <br></br><br></br>
    1. <br></br><br></br>
    2. <br></br>
    </p>
    <p>
    እኛም ምስክሮች ይህ የመኪና ሽያጭ ስምምነት ሲፈፅሙ ተመልክተን እኛም በምስክርነት ፈርመናል
    </p>

    <div id="textbox">
  <p className="alignleft">የሻጭ ስምና ፊርማ <br></br><br></br>
  <input type="text" className="input-lg" style={{border:'none',
                                                        borderBottom:'1px solid rgb(102, 101, 101)',width: '50%'}}/></p>
  <p className="alignright">የገዢ ስምና ፊርማ <br></br><br></br>
  
  <input type="text" className="input-lg" style={{border:'none',
                                                        borderBottom:'1px solid rgb(102, 101, 101)',width: '50%'}}/> </p>
</div>

    </div>
  </div>
   );
}
}