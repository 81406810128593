import * as requestFromServer from "./bodyStylesCrud";
import {bodyStylesSlice, callTypes} from "./bodyStylesSlice";

const {actions} = bodyStylesSlice;

export const fetchBodyStyles = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllBodyStyles()
    // .findBodyStyles(queryParams)
    .then(response => {
      // const { totalCount, entities } = response.data;
      const entities = response.data;
      const totalCount = entities.length;
      dispatch(actions.bodyStylesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find bodyStyles";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchBodyStyle = id => dispatch => {
  if (!id) {
    return dispatch(actions.bodyStyleFetched({ bodyStyleForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBodyStyleById(id)
    .then(response => {
      const bodyStyle = response.data;
      dispatch(actions.bodyStyleFetched({ bodyStyleForEdit: bodyStyle }));
    })
    .catch(error => {
      error.clientMessage = "Can't find bodyStyle";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteBodyStyle = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteBodyStyle(id)
    .then(response => {
      dispatch(actions.bodyStyleDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete bodyStyle";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createBodyStyle = bodyStyleForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createBodyStyle(bodyStyleForCreation)
    .then(response => {
      const { bodyStyle } = response.data;
      dispatch(actions.bodyStyleCreated({ bodyStyle }));
    })
    .catch(error => {
      error.clientMessage = "Can't create bodyStyle";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateBodyStyle = bodyStyle => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBodyStyle(bodyStyle)
    .then(() => {
      dispatch(actions.bodyStyleUpdated({ bodyStyle }));
    })
    .catch(error => {
      error.clientMessage = "Can't update bodyStyle";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateBodyStylesStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForBodyStyles(ids, status)
    .then(() => {
      dispatch(actions.bodyStylesStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update bodyStyles status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteBodyStyles = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteBodyStyles(ids)
    .then(() => {
      dispatch(actions.bodyStylesDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete bodyStyles";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
