import Chart from "react-apexcharts";

export const Charts = ({data}) => {
  const series = [
    {
      name: "Sales",
      data: data,
    }
  ];
  const options = {
    chart: {
      toolbar: {
          show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: ["#0BB783"],
    },
    xaxis: {
      categories: ["Jan Sales", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      labels: {
        show: false,
      }, 
      crosshairs: {
        show: false,
      }
    },
    yaxis: {
      labels: {
        show: false,
      }
    },
    grid: {
      show: false,
      padding: {
        top: 0,
        bottom: -15,
        left: -10,
        right: 0
      }
    }, 
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: "Poppins, Helvetica, sans-serif",
      },
      y: {
        formatter: function(val) {
          return `ETB ${val.toLocaleString(undefined, {minimumFractionDigits: 2})} `;
        },
      },
    },
    colors: ['#0BCF83'],
  };

  return (
    <Chart
      options={options}
      series={series}
      type="area"
      height={200}
    />
  )
}
