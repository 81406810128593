import React, { useEffect, useMemo, useState } from "react";
import { Form, Modal, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useProformasUIContext } from "../ProformasUIContext";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as proformaActions from '../../../../_redux/proformas/proformasActions';
import * as saleActions from "../../../../_redux/sales/salesActions"
import * as employeeActions from "../../../../_redux/employees/employeesActions"
import PrintTrigger from "./PrintTriggerProforma";
import ProformaBankReleaseModal from "./ProformaBankReleaseModal";
import CashContractOfSaleModal from "./CashContractOfSaleModal";
import ContractOfSaleModal from "./ContractOfSaleModal";
import { Checkbox, FormControlLabel } from "@material-ui/core";

export const ProformaMenuModal = () => {
    const specsUIContext = useProformasUIContext();
    const specsUIProps = useMemo(() => {
        return {
            id: specsUIContext.selectedId,
            show: specsUIContext.showPrintMenuDialog,
            showBankRelease: specsUIContext.showProformaBankReleaseLetter,
            closeBankRelease: specsUIContext.closeProformaBankReleaseLetter,
            showCashContractOfSale: specsUIContext.showProformaCashConstractOfSale,
            closeCashContractOfSale: specsUIContext.closeProformaCashConstractOfSale,
            showContractOfSale: specsUIContext.showProformaConstractOfSale,
            closeContractOfSale: specsUIContext.closeProformaConstractOfSale,
            onHide: specsUIContext.closePrintMenuDialog,
            openEditProformaDialog: specsUIContext.openEditProformaDialog,
            openContractOfSale:specsUIContext.openProformaConstractOfSale,
            openBankReleaseLetter:specsUIContext.openProformaBankReleaseLetter,
            openContractOfSaleCash:specsUIContext.openProformaCashConstractOfSale,
            openPrintMenu: specsUIContext.openPrintMenuDialog,
            carId: specsUIContext.carId,
            setCurrentProforma: specsUIContext.setCurrentProforma,
            activeTab: specsUIContext.activeMenuTab, 
            setActiveTab: specsUIContext.setActiveMenuTab
        };
    }, [specsUIContext]);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(proformaActions.fetchProformaItem(specsUIProps.id, specsUIProps.carId, "fromPrint"))
        dispatch(employeeActions.getAllEmployees())
    }, [dispatch, specsUIProps.id]);

    useEffect(() => {
        specsUIProps?.carId && dispatch(saleActions.fetchSalesInCar(specsUIProps.carId));
    }, [specsUIProps.carId, dispatch]);

    const { actionsLoading, proformaForEdit, proformaItems, clientEntities } = useSelector(
        (state) => ({
            actionsLoading: state.proformas.actionsLoading,
            proformaForEdit: state.proformas.proformaForEdit,
            proformaItems: state.proformas.proformaItems,
            clientEntities: state.client.clientEntities, 
        }),
        shallowEqual
    );

    useEffect(() => {
        proformaForEdit?.id && specsUIProps.setCurrentProforma(proformaForEdit)
    }, [proformaForEdit, dispatch])

    const initialPrintOptions = {
        'chassis': true,
        'modelYear': true,
        'engineNo': false,
        'condition': false,
        'color': false,
        'cc': false,
        'countryOfOrigin': false,
    }
    const [printOptions, setPrintOptions] = useState({...initialPrintOptions});

    const [proTotalAmount, setProTotalAmount] = useState(0);

    // const [activeTab, setActiveTab] = useState('proforma');

    useEffect(()=>{
        let proamou = 0;
        proformaItems?.map((item)=> { 
            proamou = proamou+parseInt(item.proforma_amount)
        });
        setProTotalAmount(proamou);
    }, [proformaItems]);

    return (
        proformaForEdit ? (
            <Modal
                // size="xl"
                show={specsUIProps.show}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                {actionsLoading && <ModalProgressBar />}
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Print Menu</Modal.Title>
                    <Nav className="ml-auto" onClick={() => {
                        setPrintOptions({...initialPrintOptions});
                        specsUIProps.closeBankRelease();
                        specsUIProps.closeContractOfSale();
                        specsUIProps.closeCashContractOfSale();
                        specsUIProps.onHide();
                        specsUIProps.setActiveTab('proforma');
                    }}>
                        <Nav.Link className="p-0 text-dark">
                            <span className="nav-icon">
                                <span className="svg-icon svg-icon-2x">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect x="0" y="7" width="16" height="2" rx="1"/>
                                            <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                                        </g>
                                    </g>
                                    </svg>
                                </span>
                            </span>
                        </Nav.Link>
                    </Nav>
                </Modal.Header>
                <Modal.Body>
                    <div className="row flex-column-reverse flex-lg-row">
                        <div className="col-lg">
                            <div className="row d-flex align-items-center pb-2 border-bottom">
                                <div className="col-3">
                                    <a className={`navi-link d-flex align-items-center cursor-pointer ${specsUIProps.activeTab === 'proforma' ? "" : "text-dark"}`}
                                        onClick={() => {
                                            specsUIProps.closeBankRelease();
                                            specsUIProps.closeContractOfSale();
                                            specsUIProps.closeCashContractOfSale();
                                            specsUIProps.setActiveTab('proforma');
                                    }}>
                                        <span className="navi-icon"><i className="la la-print mr-2"></i></span>
                                        <span className="navi-text">Proforma</span>
                                    </a>
                                </div>
                                <div className="col-3">
                                    <a className={`navi-link d-flex align-items-center cursor-pointer ${specsUIProps.activeTab === 'cos-bank' ? "" : "text-dark"}`}
                                        onClick={() => { 
                                            specsUIProps.closeBankRelease();
                                            specsUIProps.closeCashContractOfSale();
                                            specsUIProps.openContractOfSale();
                                            specsUIProps.setActiveTab('cos-bank');
                                    }}>
                                        <span className="navi-icon ml-3"><i className="flaticon2-contract mr-2"></i></span>
                                        <span className="navi-text">Contract (Bank)</span>
                                    </a>
                                </div>
                                <div className="col-3">
                                    <a className={`navi-link d-flex align-items-center cursor-pointer ${specsUIProps.activeTab === 'cos-cash' ? "" : "text-dark"}`}
                                        onClick={ () => {
                                            specsUIProps.closeBankRelease();
                                            specsUIProps.closeContractOfSale();
                                            specsUIProps.openContractOfSaleCash(); 
                                            specsUIProps.setActiveTab('cos-cash');
                                    }}>
                                        <span className="navi-icon ml-3"><i className="flaticon2-contract mr-2"></i></span>
                                        <span className="navi-text">Contract (Cash)</span>
                                    </a>
                                </div>
                                <div className="col-3">
                                    <a className={`navi-link d-flex align-items-center cursor-pointer ${specsUIProps.activeTab === 'bank-release' ? "" : "text-dark"}`}
                                        onClick={() => {
                                            specsUIProps.closeContractOfSale();
                                            specsUIProps.closeCashContractOfSale();
                                            specsUIProps.openBankReleaseLetter();
                                            specsUIProps.setActiveTab('bank-release');
                                    }}>
                                        <span className="navi-icon ml-3"><i className="flaticon2-hospital mr-2"></i></span>
                                        <span className="navi-text">Bank Release</span>
                                    </a>
                                </div>
                            </div>
                            { (!specsUIProps.showBankRelease && !specsUIProps.showCashContractOfSale && ! specsUIProps.showContractOfSale) && (
                            <>
                                <Modal.Header closeButton className='px-0'>
                                    <Modal.Title id="example-modal-sizes-title-lg">Print Proforma</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="overlay cursor-default px-0 pb-0">
                                    <b>Choose options to display on proforma</b>
                                    <Form className="form form-label-right mt-3">   
                                        <div className="form-group row mb-0">
                                            <div className="col-lg-10 d-flex align-items-center">
                                                <FormControlLabel
                                                    checked={printOptions?.chassis}
                                                    onChange={() => setPrintOptions({...printOptions, 'chassis': !printOptions.chassis})}
                                                    control={<Checkbox style={{color:'#0BB783'}}/>}  
                                                    className="mr-0"
                                                />
                                                <label>Chassis</label>
                                            </div>
                                            <div className="col-lg-10 d-flex align-items-center">
                                                <FormControlLabel
                                                    checked={printOptions?.modelYear}
                                                    onChange={() => setPrintOptions({...printOptions, 'modelYear': !printOptions.modelYear})}
                                                    control={<Checkbox style={{color:'#0BB783'}}/>}  
                                                    className="mr-0"
                                                />
                                                <label>Model Year</label>
                                            </div>
                                            <div className="col-lg-10 d-flex align-items-center">
                                                <FormControlLabel
                                                    checked={printOptions?.engineNo}
                                                    onChange={() => setPrintOptions({...printOptions, 'engineNo': !printOptions.engineNo})}
                                                    control={<Checkbox style={{color:'#0BB783'}}/>}  
                                                    className="mr-0"
                                                />
                                                <label>Engine Number</label>
                                            </div>
                                            <div className="col-lg-10 d-flex align-items-center">
                                                <FormControlLabel
                                                    checked={printOptions?.condition}
                                                    onChange={() => setPrintOptions({...printOptions, 'condition': !printOptions.condition})}
                                                    control={<Checkbox style={{color:'#0BB783'}}/>}  
                                                    className="mr-0"
                                                />
                                                <label>Condition</label>
                                            </div>
                                            <div className="col-lg-10 d-flex align-items-center">
                                                <FormControlLabel
                                                    checked={printOptions?.color}
                                                    onChange={() => setPrintOptions({...printOptions, 'color': !printOptions.color})}
                                                    control={<Checkbox style={{color:'#0BB783'}}/>}  
                                                    className="mr-0"
                                                />
                                                <label>Color</label>
                                            </div>
                                            <div className="col-lg-10 d-flex align-items-center">
                                                <FormControlLabel
                                                    checked={printOptions?.cc}
                                                    onChange={() => setPrintOptions({...printOptions, 'cc': !printOptions.cc})}
                                                    control={<Checkbox style={{color:'#0BB783'}}/>}  
                                                    className="mr-0"
                                                />
                                                <label>CC</label>
                                            </div>
                                            <div className="col-lg-10 d-flex align-items-center">
                                                <FormControlLabel
                                                    checked={printOptions?.countryOfOrigin}
                                                    onChange={() => setPrintOptions({...printOptions, 'countryOfOrigin': !printOptions.countryOfOrigin})}
                                                    control={<Checkbox style={{color:'#0BB783'}}/>}  
                                                    className="mr-0"
                                                />
                                                <label>Country of Origin</label>
                                            </div>
                                        </div>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer className='px-0 justify-content-start'>
                                    <button type="button" className="btn btn-light btn-elevate mr-3">
                                        <PrintTrigger 
                                            values={proformaForEdit} 
                                            proformaItems={proformaItems} 
                                            proformaItemTotalAmount={proTotalAmount}
                                            header={true}
                                            client={clientEntities}
                                            printOptions={printOptions}
                                        />
                                    </button>
                                    <button type="button" className="btn btn-light btn-elevate mr-3">
                                        <PrintTrigger 
                                            values={proformaForEdit} 
                                            proformaItems={proformaItems} 
                                            proformaItemTotalAmount={proTotalAmount}
                                            header={false}
                                            client={clientEntities}
                                            printOptions={printOptions}
                                        />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setPrintOptions({...initialPrintOptions});
                                            specsUIProps.onHide();
                                        }}
                                        className="btn btn-light btn-elevate"
                                    >
                                        Cancel
                                    </button>
                                </Modal.Footer> 
                            </>
                            ) }
                            { specsUIProps.showBankRelease && <ProformaBankReleaseModal /> }
                            { specsUIProps.showCashContractOfSale && <CashContractOfSaleModal/> }
                            { specsUIProps.showContractOfSale && <ContractOfSaleModal/> }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        ) : (<></>)
    );
}
