import React from "react";
import { Route } from "react-router-dom";
import { UsersLoadingDialog } from "./users-loading-dialog/UsersLoadingDialog";
import { UserEditDialog } from "./user-edit-dialog/UserEditDialog";
import { UserDeleteDialog } from "./user-delete-dialog/UserDeleteDialog";
import { UsersFetchDialog } from "./users-fetch-dialog/UsersFetchDialog";
import { UsersUIProvider } from "./UsersUIContext";
import { UsersCard } from "./UsersCard";
import { UserChangePasswordDialog } from "./user-change-password-dialog/UserChangePasswordDialog";

export function UsersAdminPage({ history }) {
  const usersUIEvents = {
    newUserButtonClick: () => {
      history.push("/usersAdmin/new");
    },
    openEditUserDialog: (id) => {
      history.push(`/usersAdmin/${id}/edit`);
    },
    openDeleteUserDialog: (id,status) => {
      history.push(`/usersAdmin/${id}/${status}/delete`);
    },
    openFetchUsersDialog: () => {
      history.push(`/usersAdmin/fetch`);
    },
    openChangePasswordDialog: (id) => {
      history.push(`/usersAdmin/${id}/changePassword`);
    }
  }

  return (
    <UsersUIProvider usersUIEvents={usersUIEvents}>
      <UsersLoadingDialog />
      <Route path="/usersAdmin/new">
        {({ history, match }) => (
          <UserEditDialog
            show={match != null}
            id={0}
            onHide={() => {
              history.push("/usersAdmin");
            }}
          />
        )}
      </Route>
      <Route path="/usersAdmin/:id/edit">
        {({ history, match }) => (
          <UserEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/usersAdmin");
            }}
          />
        )}
      </Route>
      
      <Route path="/usersAdmin/:id/:status/delete">
        {({ history, match }) => (
          <UserDeleteDialog
            show={match != null}
            id={match && match.params.id}
            status={match && match.params.status === "true" ? true : false}
            onHide={() => {
              history.push("/usersAdmin");
            }}
          />
        )}
      </Route>
      <Route path="/usersAdmin/fetch">
        {({ history, match }) => (
          <UsersFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/usersAdmin");
            }}
          />
        )}
      </Route>
      <Route path="/usersAdmin/:id/changePassword">
        {({ history, match }) => (
          <UserChangePasswordDialog
            show={match != null }
            id={match && match.params.id}
            onHide={() => {
              history.push("/usersAdmin");///users/:id/edit
            }}
          />
        )}
      </Route>
      
      <UsersCard />
    </UsersUIProvider>
  );
}
