import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/batch/batchActions";
import {BatchEditDialogHeader} from "./BatchEditDialogHeader";
import { BatchEditForm } from "./BatchEditForm";
import { useBatchUIContext } from "../BatchUIContext";
import moment from "moment";

export function BatchEditDialog({ id, show, onHide }) {
  // Batch UI Context
  const batchUIContext = useBatchUIContext();
  const batchUIProps = useMemo(() => {
    return {
      initBatch: batchUIContext.initBatch,
      openDeleteBatchDialog: batchUIContext.openDeleteBatchDialog,
    };
  }, [batchUIContext]);

  // Batch Redux state
  const dispatch = useDispatch();
  const { actionsLoading, batchForEdit ,batch} = useSelector(
    (state) => ({
      actionsLoading: state.batch.batchLoading,
      batchForEdit: state.batch.batchForEdit,
      batch:state.batch.batchEntities
    }),
    shallowEqual
  );
  const batchForValidation = batchForEdit==undefined ?  {...batchUIProps.initBatch,batch}: {...batchForEdit,batch}
  useEffect(() => {
    // server call for getting Batch by id
    dispatch(actions.fetchBatchById(id));
  }, [id, dispatch]);

  // server request for saving batch
  const saveBatch = (batch) => {
    batch.date = moment(batch.date).format('YYYY-MM-DD')
    if (!id) {
      // server request for creating batch
      dispatch(actions.createBatch(batch)).then(() => onHide());
    } else {
      // server request for updating batch
      dispatch(actions.updateBatch(batch)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="md"
      show={show}
      // onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <BatchEditDialogHeader id={id} onHide={onHide} />
      <BatchEditForm
        saveBatch={saveBatch}
        actionsLoading={actionsLoading}
        batch={batchForValidation}
        onHide={onHide}
        openDeleteBatchDialog={batchUIProps.openDeleteBatchDialog}
      />
    </Modal>
  );
}
