import React from "react";

export const BrandColumnFormatter = (cellContent, row) => {
    let chassis = row?.vin
    let year = row?.year

    return (
    
    <span>
        <div className="d-flex align-items-center">								
            {/* <div className="symbol symbol-40 symbol-sm flex-shrink-0">								
                <div className="symbol-label" style={{backgroundImage: row.picture}}></div>							
            </div>								 */}
            <div className="text-dark-75 font-size-lg mb-0 font-weight-bolder">{row.make} {row.model}</div><br></br>
            																
        </div>
        <div className="">{chassis+' - '+year}</div>	
    </span>
);
}
