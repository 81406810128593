import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/locations/locationsActions";
import {LocationEditDialogHeader} from "./LocationEditDialogHeader";
import { LocationEditForm } from "./LocationEditForm";
import { useLocationsUIContext } from "../LocationsUIContext";

export function LocationEditDialog({ id, show, onHide, emptyLocation=false }) {
  // Locations UI Context
  const locationsUIContext = useLocationsUIContext();
  const locationsUIProps = useMemo(() => {
    return {
      initLocation: locationsUIContext.initLocation,
      openDeleteLocationDialog: locationsUIContext.openDeleteLocationDialog,
    };
  }, [locationsUIContext]);

  // Locations Redux state
  const dispatch = useDispatch();
  const { actionsLoading, locationForEdit ,locations} = useSelector(
    (state) => ({
      actionsLoading: state.locations.locationLoading,
      locationForEdit: state.locations.locationForEdit,
      locations:state.locations.locationEntities
    }),
    shallowEqual
  );
  const locationForValidation = locationForEdit==undefined ?  {...locationsUIProps.initLocation,locations}: {...locationForEdit,locations}
  useEffect(() => {
    // server call for getting Location by id
    dispatch(actions.fetchLocation(id));
  }, [id, dispatch]);

  // server request for saving location
  const saveLocation = (location) => {
    if (!id) {
      // server request for creating location
      dispatch(actions.createLocation(location)).then(() => onHide());
    } else {
      // server request for updating location
      dispatch(actions.updateLocation(location)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="md"
      show={show}
      // onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <LocationEditDialogHeader id={id} onHide={onHide} />
      <div className="container text-danger mt-2">{emptyLocation && "You need to add at least 1 location"}</div>
      <LocationEditForm
        saveLocation={saveLocation}
        actionsLoading={actionsLoading}
        location={locationForValidation}
        onHide={onHide}
        openDeleteLocationDialog={locationsUIProps.openDeleteLocationDialog}
      />
    </Modal>
  );
}
