import React from 'react'
import { NavLink } from 'react-router-dom';

const SearchResult = ({searchResults, handleSearchTxtChange}) => {
    const results = Object.entries(searchResults || {});

    return (
    <div className="quick-search-result">
        <div className="text-muted d-none">No record found</div>

        {results?.map(data => (
            <div key={data[0]} className="mb-8">
                <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
                    {data[0]}
                </div>
                {data[1]?.map(d => (
                    <div className="d-flex align-items-center flex-grow-1 mb-2" key={d?.value}>
                        <div className="d-flex flex-column mt-1 mb-1">
                            <NavLink to={d?.value} className="text-dark text-hover-primary" onClick={() => handleSearchTxtChange("")}>{d?.name}</NavLink>
                        </div>
                    </div>
                ))}
            </div>
        ))}
    </div>
  )
}

export default SearchResult