import React, { useMemo } from "react";
import { ProformasFilter } from "./ProformasFilter";
import { ProformasTable } from "./ProformasTable";
import { ProformasLoadingDialog } from "./ProformasLoadingDialog";
import { ProformaDeleteDialog } from "./ProformaDeleteDialog";
import { ProformasFetchDialog } from "./ProformasFetchDialog";
import { ProformasGrouping } from "./ProformasGrouping";
import { ProformaEditDialog } from "./Proforma-edit-dialog/ProformaEditDialog";
import { useProformasUIContext } from "./ProformasUIContext";
import { ProformaPrintModal } from "./print-proforma/ProformaPrintModal";
import {ProformaDeleteSales} from "./Proforma-edit-dialog/ProformaDeleteSalesModal"
import { ProformaMenuModal } from "./print-proforma/ProformaMenuModal";

export function Proformas({ proformasTotalCount, proformaEntities, listLoading,newButton}) {
  // Proformas UI Context
  const specsUIContext = useProformasUIContext();
  const specsUIProps = useMemo(() => {
    return { ids: specsUIContext.ids };
  }, [specsUIContext]);

  return (
    <>
      <ProformasLoadingDialog />
      <ProformaEditDialog  newButton={newButton}/>
      <ProformaDeleteDialog />
      <ProformasFetchDialog />
      <div className="form margin-b-30">
        <ProformasFilter newButton={newButton} />
        {specsUIProps.ids.length > 0 && (
          <>
            <ProformasGrouping />
            <br />
          </>
        )}
      </div>
      <ProformasTable proformasTotalCount={proformasTotalCount} proformaEntities={proformaEntities} listLoading={listLoading} />
      <ProformaPrintModal />
      {/* <ContractOfSaleModal/>
      <ProformaBankReleaseModal/>
      <CashContractOfSaleModal/> */}
      <ProformaMenuModal />
      <ProformaDeleteSales/>
    </>
  );
}
