import {createSlice} from "@reduxjs/toolkit";

const initialSuppliersState = {
  supplierListLoading: false,
  supplierLoading: false,
  supplierTotalCount: 0,
  supplierEntities: null,
  supplierForEdit: undefined,
  lastError: null, 
  mainSupplier: null,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState: initialSuppliersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if(state.apiErrorData === undefined){
        state.apiErrorData = {}
         state.apiErrorData = action.payload.error?.response?.data;
      }else{
        state.apiErrorData = action.payload.error?.response?.data;
      }
      if (action.payload.callType === callTypes.list) {
        state.supplierListLoading = false;
      } else {
        state.supplierLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.supplierListLoading = true;
      } else {
        state.supplierLoading = true;
      }
    },
    // getSupplierById
    supplierFetched: (state, action) => {
      state.supplierLoading = false;
      state.supplierForEdit = action.payload.supplierForEdit;
      state.error = null;
    },
    // findSuppliers
    suppliersFetched: (state, action) => {
      const { supplierTotalCount, supplierEntities } = action.payload;
      state.supplierListLoading = false;
      state.error = null;
      state.supplierEntities = supplierEntities;
      state.supplierTotalCount = supplierTotalCount;
    },
    // createSupplier
    supplierCreated: (state, action) => {
      state.supplierLoading = false;
      state.error = null;
      state.supplierEntities.push(action.payload.supplier);
    },
    // updateSupplier
    supplierUpdated: (state, action) => {
      state.error = null;
      state.supplierLoading = false;
      state.supplierEntities = state.supplierEntities.map(entity => {
        if (entity.id === action.payload.supplier.id) {
          return action.payload.supplier;
        }
        return entity;
      });
    },
    // deleteSupplier
    supplierDeleted: (state, action) => {
      state.error = null;
      state.supplierLoading = false;
      state.supplierEntities = state.supplierEntities.filter(el => el.id !== action.payload.id);
    },
    // deleteSuppliers
    suppliersDeleted: (state, action) => {
      state.error = null;
      state.supplierLoading = false;
      state.supplierEntities = state.supplierEntities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // fetchMainSupplier
    mainSupplierFetched: (state, action) => {
      state.error = null;
      state.supplierLoading = false;
      state.mainSupplier = action.payload.mainSupplier;
    },
  }
});
