/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./ProformasUIHelper";
import { useDispatch } from "react-redux";
import * as carActions from "../../../_redux/cars/carsActions";
import * as proformaActions from '../../../_redux/proformas/proformasActions'
import { clearSalesInProforma, resetSalesSlice } from "../../../_redux/sales/salesActions";
const ProformasUIContext = createContext();

export function useProformasUIContext() {
  return useContext(ProformasUIContext);
}

export const ProformasUIConsumer = ProformasUIContext.Consumer;

export function ProformasUIProvider({ currentProductId, children, proformaFromOutside=false }) {
  const dispatch = useDispatch();

  const [carId, setcarId] = useState(currentProductId);
  const [proformaId, setproformaId] = useState();
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const initProforma = {
    id: 0,
    customer:{},
    status_id: 0,
    proforma_date: "",
    expire: -1,
    proformaItems:[],
    remark:"",
    employee_id:0,
    location_id:null,
    bank_id:0,
    customerName:''
  };
  useEffect(() => {
    setcarId(currentProductId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProductId]);
  const [
    showEditProformaDialog,
    setshowEditProformaDialog,
  ] = useState(false);
  const openNewProformaDialog = () => {
    initProforma.id = 0;
    setSelectedId(0);
    setshowEditProformaDialog(true);
  };
  const openEditProformaDialog = (id) => {
    setSelectedId(id);
    setshowEditProformaDialog(true);
  };
  const closeEditProformaDialog = async () => {
    dispatch(carActions.selectCurrentCarId())
    await proformaFromOutside && setcarId(undefined)
    proformaFromOutside && dispatch(proformaActions.resetProformasSlice())
    proformaFromOutside && dispatch(resetSalesSlice())
    dispatch(clearSalesInProforma());
    setSelectedId(undefined);
    setshowEditProformaDialog(false);
    setCurrentProforma(null)
  };
  const [
    showDeleteProformaDialog,
    setShowDeleteProformaDialog,
  ] = useState(false);
  const openDeleteProformaDialog = (id) => {
    setSelectedId(id);
    setShowDeleteProformaDialog(true);
  };
  const openDeleteProformaInsideDialog = () => {
    setproformaId(selectedId);
    setShowDeleteProformaDialog(true);
  };
  const closeDeleteProformaDialog = () => {
    //setSelectedId(undefined);
    setShowDeleteProformaDialog(false);
  };
  const [
    showFetchProformasDialog,
    setShowFetchProformasDialog,
  ] = useState(false);
  const openFetchProformasDialog = () => {
    setShowFetchProformasDialog(true);
  };
  const closeFetchProformasDialog = () => {
    setShowFetchProformasDialog(false);
  };
  const [
    showProformaPrintDialog,
    setShowProformaPrintDialog,
  ] = useState(false);
  const openProformaPrintDialog = (id) => {
    setSelectedId(id)
    setShowProformaPrintDialog(true);
  };
  const closeProformaPrintDialog = () => {
    dispatch(carActions.selectCurrentCarId())
    proformaFromOutside && dispatch(proformaActions.resetProformasSlice())
    proformaFromOutside && dispatch(resetSalesSlice())
    setSelectedId(undefined);
    setShowProformaPrintDialog(false);
  };
  const [
    showProformaConstractOfSale,
    setShowProformaConstractOfSale,
  ] = useState(false);
  const openProformaConstractOfSale = (id) => {
    if(id!=undefined){
      setSelectedId(id)
    }
    setShowProformaConstractOfSale(true);
  };
  const closeProformaConstractOfSale = () => {
  //   if(!showEditProformaDialog && !showProformaPrintDialog){
  //   setSelectedId(undefined);
  // }
    setShowProformaConstractOfSale(false); 
  };
  const [
    showProformaBankReleaseLetter,
    setShowProformaBankReleaseLetter,
  ] = useState(false);
  const openProformaBankReleaseLetter = (id) => {
    if(id!=undefined){
      setSelectedId(id)
    }
    setShowProformaBankReleaseLetter(true);
  };
  const closeProformaBankReleaseLetter = () => {
    // if(!showEditProformaDialog && !showProformaPrintDialog){
    //   setSelectedId(undefined);
    // }
    setShowProformaBankReleaseLetter(false); 
  };

  const [
    showProformaCashConstractOfSale,
    setShowProformaCashConstractOfSale,
  ] = useState(false);
  const openProformaCashConstractOfSale = (id) => {
    if(id!=undefined){
      setSelectedId(id)
    }
    setShowProformaCashConstractOfSale(true);
  };
  const closeProformaCashConstractOfSale = () => {
    // if(!showEditProformaDialog && !showProformaPrintDialog){
    //   setSelectedId(undefined);
    // }
    setShowProformaCashConstractOfSale(false); 
  };

  const [
    showDeleteSaleProformaDialog,
    setShowDeleteSaleProformaDialog,
  ] = useState(false);
  const [currentProforma, setCurrentProforma] = useState(null)
  const openDeleteSaleProformaDialog = (id,profromaId) => {
    if(id!=undefined){
      setSelectedId(id)
    }
    setproformaId(profromaId)
    setShowDeleteSaleProformaDialog(true);
  };
  const closeDeleteSaleProformaDialog = () => {
    
    setShowDeleteSaleProformaDialog(false); 
  };

  const [
    showPrintMenuDialog,
    setShowPrintMenuDialog,
  ] = useState(false);
  const openPrintMenuDialog = (id=null) => {
    if(id) setSelectedId(id);
    setShowPrintMenuDialog(true);
  };
  const closePrintMenuDialog = () => {
    setShowPrintMenuDialog(false);
  };

  const [activeMenuTab, setActiveMenuTab] = useState("proforma");

  const value = {
    ids,
    setIds,
    carId,
    proformaId,
    setproformaId,
    queryParams,
    setQueryParams,
    initProforma,
    selectedId,
    currentProforma, 
    setCurrentProforma,
    showEditProformaDialog,
    openEditProformaDialog,
    openNewProformaDialog,
    closeEditProformaDialog,
    showDeleteProformaDialog,
    openDeleteProformaDialog,
    closeDeleteProformaDialog,
    showFetchProformasDialog,
    openFetchProformasDialog,
    closeFetchProformasDialog,
    openDeleteProformaInsideDialog, 
    showProformaPrintDialog, 
    openProformaPrintDialog,
    closeProformaPrintDialog,
    proformaFromOutside,
    showProformaConstractOfSale,
    openProformaConstractOfSale,
    closeProformaConstractOfSale,
    showProformaBankReleaseLetter,
    openProformaBankReleaseLetter,
    closeProformaBankReleaseLetter,
    showProformaCashConstractOfSale,
    openProformaCashConstractOfSale,
    closeProformaCashConstractOfSale,
    showDeleteSaleProformaDialog,
    openDeleteSaleProformaDialog,
    closeDeleteSaleProformaDialog, 
    showPrintMenuDialog, 
    openPrintMenuDialog,
    closePrintMenuDialog, 
    activeMenuTab, 
    setActiveMenuTab
  };

  return (
    <ProformasUIContext.Provider value={value}>
      {children}
    </ProformasUIContext.Provider>
  );
}
