import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export const CarsRegisterSuccessDialog = ({ showFlash, onHide, type }) => {

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showFlash)
  }, [showFlash])

  return (
    <Modal
      show={show}
      // onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title>Car {type === 'create' ? `Created` : `Updated`}</Modal.Title>
          <button type="button" className="btn btn-elevate btn-light" onClick={onHide}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
      </Modal.Header>
    </Modal>
  );
}
