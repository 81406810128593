// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { DatePicker } from '@mantine/dates';
import * as Yup from "yup";
import {
  Input,

} from "../../../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from '../../../../_redux/inspections/inspectionsActions';
import * as employeeActions from '../../../../_redux/employees/employeesActions';
import { useInspectionsUIContext } from './..//InspectionsUIContext'
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import AsyncSearchBar from "../../../shared/AsyncSelectBar";


// Validation schema
const InspectionEditSchema = Yup.object().shape({
  inspection_date: Yup.date()
    .nullable()
    .default(() => new Date())
    .required("Inspection date is required"),
  employee_id: Yup.string()
    .required("Sales Person is required"),
});

export function InspectionEditForm({
  saveInspection,
  inspection,
  actionsLoading,
  listLoading,
  onHide,
}) {

  const inspectionsUIContext = useInspectionsUIContext();
  const inspectionsUIProps = useMemo(() => {
    return {
      initInspection: inspectionsUIContext.initInspection,
      initProductInspectionItem: inspectionsUIContext.initProductInspectionItem,
      ids: inspectionsUIContext.ids,
      setIds: inspectionsUIContext.setIds,
      queryParams: inspectionsUIContext.queryParams,
      setQueryParams: inspectionsUIContext.setQueryParams,
      openEditInspectionDialog: inspectionsUIContext.openEditInspectionDialog,
      openDeleteInspectionDialog: inspectionsUIContext.openDeleteInspectionDialog,
    };
  }, [inspectionsUIContext]);
  
  const { currentState } = useSelector(
    (state) => ({ currentState: state }),
    shallowEqual
  );

  const { inspectionItems } = currentState.inspections;
  const { employeeEntities } = currentState.employees;

  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(actions.getAllInspectionItems())
      dispatch(employeeActions.getAllEmployees())
  }, [dispatch]);

  const [addNew, setAddNew] = useState(false)
  const [inspectionItemsLeft, setInspectionItemsLeft] = useState([])
  const [currentInspectionOptions, setCurrentInspectionOptions] = useState([])

  // const addNewInspectionItem = (e) => {
  //   const inspectionItem = e.target.value;
  //   setCurrentInspectionOptions([...currentInspectionOptions, {...inspectionsUIProps.initProductInspectionItem, inspection_id: inspectionItemsLeft.find(i => i.name === inspectionItem).id}])
  //   setInspectionItemsLeft(inspectionItemsLeft.filter(i => i.name !== inspectionItem))
  //   setAddNew(false)
  // }

  const addNewInspectionItem = (inspectionItem) => {
    setCurrentInspectionOptions([...currentInspectionOptions, {...inspectionsUIProps.initProductInspectionItem, inspection_id: inspectionItemsLeft.find(i => i.name === inspectionItem).id}])
    setInspectionItemsLeft(inspectionItemsLeft.filter(i => i.name !== inspectionItem))
    setAddNew(false)
  }

  useEffect(() => {
    if(inspection && inspectionItems) {
      if(inspection.id) {
        if(inspection.productInspectionItems.length > 0) {
          let currentOptions = []
          let remainingOptions = []
          for(let i in inspectionItems) {
            let found = false;
            for(let j in inspection.productInspectionItems) {
              if(inspectionItems[i].id === inspection.productInspectionItems[j].inspection_id) {
                currentOptions.push(inspection.productInspectionItems[j])
                found = true
              }
            }
            if (!found) {
              remainingOptions.push(inspectionItems[i])
            }
          }
          setInspectionItemsLeft(remainingOptions)
          setCurrentInspectionOptions(currentOptions)
        }
      }
      else {
        setInspectionItemsLeft(inspectionItems.filter(i => !i.show_default))
        const default_inspection_items = inspectionItems.filter(i => i.show_default)
        setCurrentInspectionOptions(default_inspection_items.map(i => ({...(inspectionsUIProps.initProductInspectionItem), inspection_id: i.id})))
      }
    }
  }, [inspection, inspectionItems])

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={inspection}
        validationSchema={InspectionEditSchema}
        onSubmit={(values) => {
          let productInspectionItems = values.productInspectionItems
          if(productInspectionItems.length !== currentInspectionOptions.length) {
            for(let i in currentInspectionOptions) {
              let found = false;
              for(let j in inspection.productInspectionItems) {
                if(currentInspectionOptions[i].inspection_id === productInspectionItems[j].inspection_id) {
                  found = true
                }
              }
              if (!found) {
                if(!productInspectionItems.find(p => p.inspection_id === currentInspectionOptions[i].inspection_id)) {
                  if(inspectionItems.find(ins => ins.id === currentInspectionOptions[i].inspection_id).datatype.toLowerCase() === "boolean") {
                    productInspectionItems = [...productInspectionItems, currentInspectionOptions[i]]
                    values = {...values, productInspectionItems: productInspectionItems} 
                  }
                }
              }
            }
          }
          saveInspection(values);
        }}
      >
        {({ handleSubmit, setFieldValue, setFieldTouched, values, errors, touched, initialValues,isSubmitting }) => (
          <BlockUi blocking={isSubmitting}>
            {/* <Modal.Body className="overlay overlay-block cursor-default"> */}
            <Modal.Body className="overlay cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <div className="d-flex flex-column">
                      {/* <DatePickerField 
                        name="inspection_date" 
                        label="Inspection Date"
                        maxDate={new Date()} 
                        dateFormat="dd/MM/yyyy" 
                        customFeedbackLabel=" "
                        required
                      /> */}
                      <DatePicker 
																		  name="inspection_date" 
                                      label="Inspection Date"
																		//  maxDate={new Date()} 
																		 /*disableClose={false}*/
																		 //closeCalendarOnChange={false}
																		 inputFormat="DD/MM/YYYY"
																		 //customFeedbackLabel=" "
																     	// icon={<Calendar size={25} /> }
                                    zIndex={10000}
																		 onChange={(val) => {
																			setFieldValue("inspection_date", val);
																		  }}
																		  value={values.inspection_date}
																		  required
																		  />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    {/* <Select name="employee_id" label="Employee" required>
                      <option value=''>-- Select --</option>
                      {employeeEntities && employeeEntities.map((emp) => (
                        <option key={emp.id} value={emp.id} >
                          {emp.name}
                        </option>
                      ))}
                    </Select> */}
                    <label  id="aria-label" htmlFor="aria-example-input">
                    Sales Person <span className="text-danger">*</span>
                    </label> 
                    <AsyncSearchBar 
                      selectedItem={{id: values.employee_id}} 
                      setSelectedItem={setFieldValue}
                      setTouchedItem={setFieldTouched}
                      defaultItem={employeeEntities.find(e => e.id === initialValues.employee_id)?.name}
                      error={errors.employee_id}
                      touched={touched.employee_id}
                      item_id="employee_id"
                      item_name="name"
                      url={`${process.env.REACT_APP_AUTO_DEALER_API}employees/search`}
                    />
                    {errors.employee_id && touched.employee_id && (
                      <div className="invalid-feedback d-block">{errors.employee_id}</div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label>Remark</label>
                    <Field
                      name="remark"
                      as="textarea"
                      placeholder="Enter remark"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-6">
                    <h6>Inspection Item</h6>
                  </div>
                  <div className="col-6">
                    <h6>Value</h6>
                  </div>
                </div>
              
                {inspectionItems && currentInspectionOptions && currentInspectionOptions.map((inspectionItem, key) => {
                  let index = -1;
                  if (values.productInspectionItems.length > 0) {
                    index = values.productInspectionItems.findIndex(i => i?.inspection_id === inspectionItem.inspection_id)
                  }
                  return (
                    <div className="form-group row" key={key}>
                    <div className="col-6">
                      {inspectionItems.find(i => i.id === inspectionItem.inspection_id).name} {/*<span className="text-danger">*</span>*/}    
                    </div>
                    <div className="col-3">
                      <Field
                        component={Input}
                        type={
                          inspectionItems.find(i => i.id === inspectionItem.inspection_id).datatype.toLowerCase() === "boolean" ? "checkbox" : 
                          (inspectionItems.find(i => i.id === inspectionItem.inspection_id).datatype.toLowerCase() === "number" ? "number" : "" )
                        }
                        name={`productInspectionItems[${index !== -1 ? index : key}].value`}
                        checked={values.productInspectionItems[index !== -1 ? index : key]?.value === '1' ? true : false}
                        min={inspectionItems.find(i => i.id === inspectionItem.inspection_id).datatype.toLowerCase() === "number" ? 0 : ""}
                        onChange={(e) => {
                          if(inspectionItem.id) {
                            if(inspectionItems.find(i => i.id === inspectionItem.inspection_id).datatype.toLowerCase() === "boolean") {
                              setFieldValue(`productInspectionItems[${index !== -1 ? index : key}].value`, (e.target.checked ? "1" : "0"))
                            }
                            else {
                              setFieldValue(`productInspectionItems[${index !== -1 ? index : key}].value`, e.target.value)
                            }
                          }
                          else {
                            if(inspectionItems.find(i => i.id === inspectionItem.inspection_id).datatype.toLowerCase() === "boolean") {
                              setFieldValue(`productInspectionItems[${index !== -1 ? index : key}]`, {...inspectionItem, value: (e.target.checked ? "1" : "0")})
                            }
                            else {
                              setFieldValue(`productInspectionItems[${index !== -1 ? index : key}]`, {...inspectionItem, value: e.target.value})
                            }
                          }
                        }}
                        customFeedbackLabel=" "
                        className={inspectionItems.find(i => i.id === inspectionItem.inspection_id).datatype.toLowerCase() === "boolean" ? "form-control-sm" : "form-control form-control-sm"}
                      />
                    </div>
                  </div> 
                  )
                })}

                {addNew && (
                    <div className="form-group row">
                      <div className="col-6">
                        {/* <Select 
                          name="add_inspection_item"
                          onChange={addNewInspectionItem}
                          >
                          <option value=''>-- Inspection Item --</option>
                          {inspectionItemsLeft && inspectionItemsLeft.map((inspectionItem) => (
                            <option key={inspectionItem.id} value={inspectionItem.name} >
                              {inspectionItem.name}
                            </option>
                          ))}
                        </Select>    */}
                        <AsyncSearchBar 
                          selectedItem=""
                          setSelectedItem={() => {}}
                          setTouchedItem={() => {}}
                          defaultItem=""
                          item_id=""
                          item_name="name"
                          options={inspectionItemsLeft}
                          onChange={addNewInspectionItem}
                        />          
                      </div>
                    </div>   
                )}

                {inspectionItemsLeft.length > 0 && (
                  <button 
                    type="button"
                    onClick={() => {setAddNew(!addNew)}}
                    className="btn btn-success btn-elevate"
                  >
                    Add
                  </button>
                )}

              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate mr-2"
              >
                Save
              </button>
              <> </>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate mr-auto"
              >
                Cancel
              </button>
              {inspection.id && (
                <button 
                onClick={() => inspectionsUIProps.openDeleteInspectionDialog(inspection.id)}
                type="button" className="btn btn-danger">
                  Delete
                </button>
              )}
            </Modal.Footer>
          </BlockUi>
        )}
      </Formik>
    </>
  );
}
