// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { DatePicker } from '@mantine/dates';
import * as Yup from "yup";
import {
  Input,
  TextArea
} from "../../../../../../_metronic/_partials/controls";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
// Validation schema
const BatchEditSchema = Yup.object().shape({
  name: Yup.string()
  .min(3, "Minimum 3 symbols")
  .max(50, "Maximum 50 symbols")
  .required("Name is required")
  .test(
    'duplicateName',
    'Duplicate Batch Name',
    function(value){
          return this.parent.batch?.filter(
            (item)=>{
              return item.name == value && item.id != this.parent.id
            }
          ).length == 0
      }
    ),
    date: Yup.date().nullable()
    .required("Date is required")
    ,
  
});

export function BatchEditForm({
  saveBatch,
  batch,
  actionsLoading,
  onHide,
  openDeleteBatchDialog
}) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={batch}
        validationSchema={BatchEditSchema}
        onSubmit={(values) => {
          const valuesToSend = {...values}
          // if (valuesToSend.date instanceof Date){
          //   const d = new Date(values.date);
          //   valuesToSend.date = (d instanceof Date) ? `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate() < 10 ? '0'+d.getDate():d.getDate()}` : d;
          // }
          saveBatch(valuesToSend);
        }}
      >
        {({ handleSubmit,values,isSubmitting, setFieldValue, errors, touched }) => (
          <BlockUi blocking={isSubmitting}>
            <Modal.Body className="overlay  cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Name */}
                  
                  <div className="col-lg-10">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      withFeedbackLabel= {true}
                      required
                    />
                  </div>
                  </div>
                  {/* Date */}
                  <div className="form-group row">
                  
                  <div className="col-lg-10">
                  <label  htmlFor="date">
                        Date <span className="text-danger">*</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                    </label>
                    <div className="d-flex flex-column">
                    {/* <DatePickerField name="date" 
                        dateFormat="dd/MM/yyyy" 
                        customFeedbackLabel=" "
                        required
                      /> */}
                      <DatePicker 
                        name="date" 
                        inputFormat="DD/MM/YYYY" 
                        required
                        zIndex={10000}
                        value={values.date}
                        invalid={touched.date && (errors.date != null)}
                        onChange={(val) => {
                          setFieldValue("date", val);
                          }}
                      />
                      {errors.date && touched.date && (
                        <div className="invalid-feedback d-block">{errors.date}</div>
                      )}
                    </div>
                  </div>
                  </div>
                 

                  {/* description */}
                  <div className="form-group row">
                  <div className="col-lg-10">
                    <Field
                      name="description"
                      component={TextArea}
                      placeholder="Description"
                      label="Description"
                      withFeedbackLabel= {true}
                    />
                  </div>
                  </div>
                  
                
              </Form>
            </Modal.Body>
            <Modal.Footer>
            <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate mr-auto"
              >
                Cancel
              </button>
              { values.id != undefined &&<button
                type="button"
                onClick={()=>{openDeleteBatchDialog(values.id)}}
                className="btn btn-danger btn-elevate ml-auto"
              >
                Delete
              </button>}

              <> </>
              
            </Modal.Footer>
          </BlockUi>
        )}
      </Formik>
    </>
  );
}
