import NumberFormat from 'react-number-format'

export const formatMoney = (m) => {
    if(m >= 1000000 && m < 1000000000){
        return (m/1000000).toFixed(1) + 'M';
    }else if(m >= 1000000000){
        return (m/1000000000).toFixed(1) + 'B';
    }
    return (
        <NumberFormat 
            value={m}
            displayType={'text'}
            thousandSeparator={true}
        />
    )
}


// Difference between two dates in years, months, days
export const dateDiff = (startingDate, endingDate) => {
    var startDate = new Date(new Date(startingDate).toISOString().substring(0, 10));
    if (!endingDate) {
      endingDate = new Date().toISOString().substring(0, 10);
    }
    var endDate = new Date(endingDate);
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }

    var startYear = startDate.getFullYear();
    var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  
    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }
  
    // return yearDiff + 'Y ' + monthDiff + 'M ' + dayDiff + 'D';
    if(monthDiff===1 && dayDiff===1) {
        return `${monthDiff} Month, ${dayDiff} Day`;
    }
    else if(monthDiff===1 && dayDiff>1) {
        return `${monthDiff} Month, ${dayDiff} Days`;
    }
    else if(monthDiff===1 && dayDiff===0) {
        return `${monthDiff} Month`
    }
    else if(monthDiff > 1 && dayDiff===0) {
        return `${monthDiff} Months`;
    }
    else if(monthDiff > 1 && dayDiff===1) {
        return `${monthDiff} Months, ${dayDiff} Day`;
    }
    else if(monthDiff > 1 && dayDiff>1) {
        return `${monthDiff} Months, ${dayDiff} Days`;
    }
    return
}



// Group an array of objects by key
export const groupArrayByObjKey = (xs, f) => {
	return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}
