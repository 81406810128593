import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { ColorsTable } from "./colors-table/ColorsTable";
import { ColorsGrouping } from "./colors-grouping/ColorsGrouping";
import { useColorsUIContext } from "./ColorsUIContext";

export function ColorsCard() {
  const colorsUIContext = useColorsUIContext();
  const colorsUIProps = useMemo(() => {
    return {
      ids: colorsUIContext.ids,
      newColorButtonClick: colorsUIContext.newColorButtonClick,
    };
  }, [colorsUIContext]);

  return (
    <div>
           
    <div className="content d-flex flex-column-fluid" id="kt_content">
      
      <div className="container">
     
     
                    {/* <div className="flex-row-fluid ml-lg-8">	
							
		</div> */}
    <Card>
      <CardHeader title="Colors list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={colorsUIProps.newColorButtonClick}
          >
            New Color
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {colorsUIProps.ids.length > 0 && <ColorsGrouping />}
        <ColorsTable />
      </CardBody>
    </Card>
    
    </div>
    </div>
    </div>
   
  );
}
