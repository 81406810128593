import React, { PureComponent } from 'react'
import { Document
  , Packer
  , Paragraph
  , TextRun
  , HeadingLevel
  , AlignmentType


   } from "docx";
import { saveAs } from "file-saver";
import CurrencyToWords from '../../../../shared/CurrencyToWords/currencyToWords';
import moment from 'moment';
import axios from 'axios';

export default class CashConstractOfSalesWord extends PureComponent {
    constructor(props){
        super(props)
        this.state = {docCount : 1}
        this.generate = this.generate.bind(this);
    }

    generate (){
        const doc = new Document({
          creator: "Betselot",
          title: "Contract Of Sales Cash",
          description: "Contract Of Sales Cash docx",
          
          styles: {
            default: {
                heading6: {
                    run: {
                        size: 24,
                        bold: true,
                        font:"Nyala",
                    },
                    paragraph: {
                        spacing: {
                            after: 120,
                        },
                    },
                },
                heading2: {
                    run: {
                        size: 32,
                        bold: true,
                        font:"Nyala",
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 120,
                        },
                    },
                },
                listParagraph: {
                    run: {
                        color: "#FF0000",
                    },
                },
            },
            paragraphStyles: [
                {
                    id: "aside",
                    name: "Aside",
                    basedOn: "Normal",
                    next: "Normal",
                    run: {
                        size:24,
                        font:"Nyala",
                        color: "000000",
                    },
                    paragraph: {
                        // indent: {
                        //     left: convertInchesToTwip(0.5),
                        // },
                        spacing: { line: 400, before: 20 * 72 * 0.1, after: 20 * 72 * 0.1 },
                    },
                },
                
            ],
        },
            sections: [{
                properties: {},
                children: [
                  new Paragraph({
                    text: "ቀን "+localStorage.date,
                    style: "aside",
                    alignment:AlignmentType.RIGHT,
                    spacing: {
                      before: 200,
                      after: 100,
                  },
                  }),
                  new Paragraph({
                    text: "የመኪና ሽያጭ ውል ስምምነት",
                    heading: HeadingLevel.HEADING_2,
                    alignment:AlignmentType.CENTER,
                    spacing: {
                      after: 150,
                  },
                  }),
                  new Paragraph({
                    //text: "ቀን "+localStorage.date,
                    style: "aside",
                    children:[
                        new TextRun({
                            text:"ሻጭ:- "+this.props.content?.supplierName,
                            break:1,
                        }),
                        new TextRun({
                            text:"አድራሻ- "+this.props.content?.supplierAddress,
                            break:1,
                        }),
                        new TextRun({
                            text:"ገዢ:- "+this.props.content?.customerName,
                            break:1,
                        }),
                        new TextRun({
                            text:"አድራሻ- "+this.props.content?.customerAddress,
                            break:1,
                        })
                    ],
                    alignment:AlignmentType.LEFT,
                    spacing: {
                    //before: 2350,
                    after: 200,
                },
                }),
                  new Paragraph({
                    //text: this.props.content?.customerName+" "+this.props.content?.cars?.productName+"  የሻንሲ ቁጥር "+this.props.content?.cars?.chassis+"፣ ሞዴል "+ this.props.content?.cars?.modelYear +"፣ ቀለም "+this.props.content?.cars?.color+"፣ የተመረተበት ሀገር TODO የሆነውን ተሽከርካሪ ከአስመጪው ከ"+this.props.content?.supplierName+" በብር "+this.props.content?.priceFmt+".00 ("+CurrencyToWords(this.props.content?.price,'ብር')+") ለመግዛት በተስማሙት መሰረት የቅድሚያ ክፍያ ብር "+this.props.content?.advanceFmt+".00 ("+CurrencyToWords(this.props.content?.advance,'ብር')+") በዛሬው እለት የከፈሉ ሲሆን ቀሪውን ክፍያ ደግሞ በድርጅችቱ የባንክ ሂሳብ ገቢ ለማድረግ ተስማምተዋል።",
                    children: [
                    new TextRun("እኔ ሻጭ በስሜ የገባውን ገና ሰሌዳ ያለጠፈውን "+this.props.content?.cars?.productName+" የሻንሲ ቁጥር "+this.props.content?.cars?.chassis+"፣ ሞዴል "+ this.props.content?.cars?.modelYear +"፣ ቀለም "+this.props.content?.cars?.color+"፣ የተመረተበት ሀገር "+this.props.content?.cars?.country+" የሆነውን ተሽከርካሪ ለገዢ ")
                    ,new TextRun({text:this.props.content?.priceFmt+".00 ("+CurrencyToWords(this.props.content?.price,'ብር')+")",
                      }
                      )
                    ,new TextRun(" ሸጬላቸዋለሁ በዛሬው እለት የተከፈለ ")
                    ,new TextRun({text:this.props.content?.advanceFmt+".00 ("+CurrencyToWords(this.props.content?.advance,'ብር')+")",
                      }
                      )
                    ,new TextRun(" በባንክ አካውንት ገቢ አድርገዋል።")
                    ],
                    style: "aside",
                    alignment:AlignmentType.JUSTIFIED,
                    spacing: {
                      after: 350,
                  },
                  }),
                  new Paragraph({
                    //text: this.props.content?.supplierName+" ከላይ ለተገለጸው መኪና ግዢ ቀብድ ክፍያ ብር "+this.props.content?.advanceFmt+".00 ("+CurrencyToWords(this.props.content?.advance,'ብር')+") በዛሬው እለት "+this.props.content?.customerName+" የተቀበሉ ሲሆን ቀሪውን ክፍያ ደግሞ በድርጅታችን ስም በሚገኘው በ"+this.props.bankEntities?.name+" ሂሳብ ቁጥር "+this.props.bankEntities?.account_no+" ገቢ እንዲደረግ ተስማምተናል።",
                    children:[
                        new TextRun("እኔ "+this.props.content?.customerName+" ከዚህ በላይ በተፈፀመው ውል መሰረት ተስማምቼ መኪናውን ስገዛ በዛሬው እለት ብር "),
                        new TextRun({
                            text:this.props.content?.advanceFmt+".00 ("+CurrencyToWords(this.props.content?.advance,'ብር'),
                            
                        }),
                        new TextRun(") ያስገባሁ ሲሆን ቀሪውን ብር "),
                        new TextRun({text:this.props.content?.changeFmt+".00 ("+CurrencyToWords(this.props.content?.change,'ብር')+")",
                        }
                        )
                        ,
                        new TextRun((this.props.proforma?.expire ? (" በ"+CurrencyToWords(this.props.proforma?.expire,'') +"("+ this.props.proforma?.expire+") ቀን ውስጥ") : "") + " ለመክፈል እና መኪናውን ባለበት ሁኔታ ልረከበው ተስማምቼ የገዛሁ መሆኔን በተለመደው ፊርማዬ አረጋግጣለሁ።"),
                        
                    ],
                    style: "aside",
                    alignment:AlignmentType.JUSTIFIED,
                    spacing: {
                      after: 350,
                  },
                  }),
                  new Paragraph({
                    text: "ይህ ውል በፍ/ብ/ሕግ ቁ፡731 ፣ 2005 እና 226 መሰረት በሕግ ፊት የፀና ሲሆን ከዚህ ውል ውጪ የሚወጣ ወገን ቢኖር ውል ለፈረሰበት ወገን 50,000 ብር (ሃምሳ ሺ ብር)  ከፍሎ ውሉ በፍ/ብ/ሕግ ፊት ቀርቦ የፀና ሊሆን ተስማምተናል።",
                    alignment:AlignmentType.JUSTIFIED,
                    style: "aside",
                    spacing: {
                      after: 350,
                  },
                  }),
                  new Paragraph(this.props.proforma?.expire ? {
                    text: this.props.proforma?.expire ? ("ይህ የሽያጭ ስምምነት በ"+CurrencyToWords(this.props.proforma?.expire,'') +"("+ this.props.proforma?.expire+") ቀናት ውስጥ ካልተጠናቀቀ ውሉ እንደፈርሰ ይቆጠራል ቀብድም ተመላሽ አይሆንም። ") : "",
                    alignment:AlignmentType.JUSTIFIED,
                    style: "aside",
                    spacing: {
                      after: 350,
                  },
                  } : {})
                  ,
                  new Paragraph({
                    text: "ይህ መኪና ሽያጭ ውል ስምምነት ሲፈፀም የነበሩ የሰው ምስክሮች ስምና ፊርማ",
                    children:[
                        new TextRun({
                            text:"      1.",
                            break:1,
                        }),
                        new TextRun({
                            text:"      2.",
                            break:1,
                        }),
                        new TextRun({
                          text:"እኛም ምስክሮች ይህ የመኪና ሽያጭ ስምምነት ሲፈፅሙ ተመልክተን እኛም በምስክርነት ፈርመናል",
                          break:1,
                      }),
                    ],
                    alignment:AlignmentType.LEFT,
                    style: "aside",
                    spacing: {
                      after: 350,
                  },
                  }),
                  new Paragraph({
                    text: "የሻጭ ስምና ፊርማ                                                                      የገዢ ስምና ፊርማ",
                    alignment:AlignmentType.LEFT,
                    heading: HeadingLevel.HEADING_6,
                    spacing: {
                      after: 700,
                  },
                  }),
                  new Paragraph({
                    text: "__________________                                                                          __________________",
                    alignment:AlignmentType.LEFT,   
                    heading: HeadingLevel.HEADING_6,
                    spacing: {
                      after: 0,
                  },
                  }),
                  
                ],
            }],
        });
          Packer.toBlob(doc).then(blob => {
            saveAs(blob, this.props.content?.customerName+" (Cash Sales - Contract with Advance).docx");
            var body = {
              id:0,
              name:"Cash Contract Of Sales",
              date:moment(),
              value:this.state.docCount,
              type:"Docx",
              param:JSON.stringify(this.props.content)
            }
            var url =  process.env.REACT_APP_AUTO_DEALER_API+"logger/proformaLoggerDoc";
            axios.post(url, body ).then((response)=>{
                if(response.data?.value!=undefined && response.data?.value < 9999)
                this.setState({docCount:response.data?.value+1})
              });
            });
    }



  render() {
    return (
        <button onClick={this.generate} hidden id="docxButtonCash">Generate docx!</button>
    )
  }
}
