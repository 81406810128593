import * as requestFromServer from "./settingsCrud";
import {settingsAdminSlice, callTypes} from "./settingsSlice";

const {actions} = settingsAdminSlice;

export const fetchPaymentTypes = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.paymentMethod }));
  
  return requestFromServer
    .getPaymentTypes()
    .then(response => {
      const entities  = response.data;
      const totalCount = response.data.length;
      dispatch(actions.paymentTypesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.tenantMessage = "Can't find payment Types";
      dispatch(actions.catchError({ error, callType: callTypes.paymentMethod }));
    });
};

export const fetchSubscriptionPlan = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.subscriptionPlan }));
  
  return requestFromServer
    .getSubscriptionTypes()
    .then(response => {
      const entities  = response.data;
      const totalCount = response.data.length;
      dispatch(actions.subscriptionTypesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.tenantMessage = "Can't find subscription plan Types";
      dispatch(actions.catchError({ error, callType: callTypes.subscriptionPlan }));
    });
};




