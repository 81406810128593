import React from 'react'
import {CurrencyToWords} from '../../../shared/CurrencyToWords/currencyToWords'
import NumberFormat from 'react-number-format';
export class ProformaContractOfSale extends React.PureComponent {
    render() {
      return (
    <div className='bodyLetter'>

    <div className="return-address"> 
      
      <time >ቀን {localStorage.date}</time>
    </div>

    <div className='letterSubject text-dark font-weight-boldest text-hover-primary display-3'>
    የተሽከርካሪ ሽያጭ ውል ስምምነት
    </div>
    
    <div className="content text-dark font-weight-bold text-hover-primary font-size-h2"> 
    <p><u>{this.props.content?.customerName}</u> {this.props.content?.cars?.productName} {this.props.content?.cars?.chassis ? 'የሻንሲ ቁጥር '+ this.props.content?.cars?.chassis + ' ፣ ' : ''} 
    {this.props.content?.cars?.modelYear ? 'ሞዴል '+ this.props.content?.cars?.modelYear + ' ፣ ':''}  {this.props.content?.cars?.color ? 'ቀለም '+this.props.content?.cars?.color+' ፣ ':''}
    {this.props.content?.cars?.country ? 'የተመረተበት ሀገር '+this.props.content?.cars?.country+' ':''} የሆነውን ተሽከርካሪ ከአስመጪው ከ{this.props.content?.supplierName}&nbsp;
    <u>በብር <NumberFormat value={this.props.content?.price+'.00'} thousandSeparator="," displayType='text'></NumberFormat> ( {CurrencyToWords(this.props.content?.price,'ብር')} ) </u>
    ለመግዛት በተስማሙት መሰረት የቅድሚያ ክፍያ ብር <u><NumberFormat value={this.props.content?.advance+'.00'} thousandSeparator="," displayType='text'></NumberFormat> ( {CurrencyToWords(this.props.content?.advance,'ብር')} ) </u>በዛሬው እለት የከፈሉ ሲሆን 
    ቀሪውን ክፍያ ደግሞ በድርጅችቱ የባንክ ሂሳብ ገቢ ለማድረግ ተስማምተዋል።</p>
    <p><u>{this.props.content?.supplierName} </u>ከላይ ለተገለጸው መኪና ግዢ ቀብድ ክፍያ ብር <u><NumberFormat value={this.props.content?.advance+'.00'} thousandSeparator="," displayType='text'></NumberFormat> ( {CurrencyToWords(this.props.content?.advance,'ብር')} )</u> በዛሬው እለት ከ <u>{this.props.content?.customerName} </u>የተቀበሉ ሲሆን ቀሪውን ክፍያ 
    ደግሞ በድርጅታችን ስም በሚገኘው በ <u>{this.props.content?.banks?.name} ባንክ  ሂሳብ ቁጥር {this.props.content?.banks?.account_no} </u>ገቢ እንዲደረግ 
    ተስማምተናል።</p>
    <p>{this.props.proforma?.expire ? `ይህ የሽያጭ ስምምነት በ${CurrencyToWords(this.props.proforma?.expire,' ')}(${this.props.proforma?.expire}) ቀናት ውስጥ ካልተጠናቀቀ ውሉ እንደፈርሰ ይቆጠራል ቀብድም 
    ተመላሽ አይሆንም።` : ``} </p>
    </div>
      <p className="greetFoot text-dark font-weight-bold text-hover-primary font-size-h2">
      የሻጭ ስምና ፊርማ
      <br></br>
      <br></br>
        <p className="greetFoot font-size-h4">{this.props.content?.supplierName}</p>
      </p>

  </div>
   );
}
}