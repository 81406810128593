import { useState, useEffect,useLayoutEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import * as carActions from "../../_redux/cars/carsActions";
import * as supplierActions from "../../_redux/suppliers/suppliersActions";

let initialFilter = {
  filter: {
      category: "",
      year: "",
      color: "",
  }
};

const ChildTable = ({data}) => {
  const childTableColumns = [
    {
      dataField: 'id', 
      text: 'ID', 
      hidden: true, 
      classes: "border-0",
      headerClasses: "border-0"
    },
    {
      dataField: 'color',
      text: 'Color', 
      classes: "border-0",
      headerClasses: "border-0", 
      formatter: (cellContent, row) => (
        <span>{ row.color ? row.color : '(No color)'}</span>
      ),
    }, 
    {
      dataField: 'year',
      text: 'Year',
      classes: "border-0",
      headerClasses: "border-0"
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
      classes: "text-right border-0",
      headerClasses: "text-right border-0",
    }, 
  ];

  return data ? (
    <BootstrapTable 
        classes="table table-head-custom table-vertical-center"
        headerClasses='nested'
        rowStyle={{ cursor: 'pointer' }}
        bordered={false}
        bootstrap4
        keyField='id'
        data={ data }
        columns={ childTableColumns }
    /> ) : ( <></>)
}

const StockReportDashboard = () => {
  const [tableData, setTableData] = useState([]);

  const columns = [
    {
      dataField: 'id', 
      text: 'ID', 
      hidden: true, 
      classes: "border-0",
      headerClasses: "border-0"
    },
    {
      dataField: 'category',
      text: 'Brand', 
      classes: "border-0", 
      headerClasses: "border-0"
    }, 
    {
      dataField: 'quantity',
      text: 'Quantity', 
      classes: "text-right border-0",
      headerClasses: "text-right border-0",
    }, 
  ];

  const { dashboardStockReport: entities, dashboardStockReportDetail: detailEntities ,error,apiErrorData, mainSupplier} = useSelector(
      (state) => ({ 
        dashboardStockReport: state.cars.dashboardStockReport,
        dashboardStockReportDetail: state.cars.dashboardStockReportDetail,
        error:state.cars.error,
        apiErrorData:state.cars.apiErrorData, 
        mainSupplier: state.suppliers.mainSupplier
      }),
      shallowEqual
  );

  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }
    }
  },[error])

  const dispatch = useDispatch();

  useEffect(() => {
		dispatch(supplierActions.getMainSupplier())
	}, [dispatch])
  
  useEffect(() => {
    if(mainSupplier) {
      dispatch(carActions.fetchDashboardStockReport({...initialFilter, filter: {...initialFilter.filter, supplier: `${mainSupplier?.id}`}}))
      dispatch(carActions.fetchDashboardStockReportDetail({...initialFilter, filter: {...initialFilter.filter, category: "", year: "1", color: "1", supplier: `${mainSupplier?.id}`}}))
    }
  }, [dispatch, mainSupplier]);
  
  useEffect(() => {
      if(entities.length > 0 && detailEntities.length > 0) {
          const stockList = []
          entities.forEach(i => {
            detailEntities.forEach(j => {
              if(i.category === j.category) {
                i = {...i, expand: [...(i?.expand || []), {id: j.id, color: j.color, year: j.year, quantity: j.quantity}]}
              }
            })
            stockList.push(i)
          });
          setTableData(stockList)
      }
  }, [entities, detailEntities])

  const [expanded, setExpanded] = useState([])

  const handleExpand = (row, isExpand, rowIndex, e) => {
    isExpand ? setExpanded([...expanded, row.id]) : setExpanded(expanded.filter(ex => ex !== row.id))
  }

  const getNonExpandables = (rows) => {
    const unExpandables = []
    rows.forEach((row, i) => {
      if(row?.expand?.length === 0 || row?.expand === undefined) {
        unExpandables.push(i)
      }
    })
    return unExpandables
  }

  const expandRow = {
    renderer: row => (
        <ChildTable data={row.expand} />
    ),
    expanded: expanded,
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => <></>,
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      return (    
          expandable && (
            expanded ? (
                <i className="fa fa-caret-down" style={{width: "16px", height: "16px", color: "#0BB783"}}></i>
              ) : (
                <i className="fa fa-caret-right" style={{width: "16px", height: "16px", color: "#0BB783"}}></i>
              )
          )
      )
    },
    expandByColumnOnly: true, 
    onExpand: handleExpand,
    nonExpandable: getNonExpandables(tableData)
  };

  const rowStyle = (row, rowIndex) => {
    const style = {cursor: 'pointer'};
    if (expanded.find(id => row.id === id)) {
      style.backgroundColor = '#F3F6F9';
    }
    return style;
  };

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      classes="table table-head-custom table-vertical-center"
      rowStyle = {rowStyle}
      bordered={false}
      bootstrap4
      keyField='id'
      data={ tableData }
      columns={ columns }
      expandRow={ expandRow }
    />
  )
}

export default StockReportDashboard
