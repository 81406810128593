import React, { useEffect, useMemo, useState, useRef, forwardRef,useLayoutEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/sales/salesActions";
import * as clientActions from '../../../../_redux/client/clientsActions';
import { useLoanSalesUIContext } from "../LoanSalesUIContext";
import cloneDeep from 'lodash/cloneDeep';
import LoanSalesAGGrid from "./Ag_grid_for_loan_sales";
import TableDataRowFormatter from "./TableDataRowFormatter";
import $ from 'jquery';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import { Loader} from 'react-loaders';

const LoanSalesTable = forwardRef((props, ref)  => {
  const { ref1, ref2 } = ref;
  const exportInsideCSV = useRef();
  const exportInsidePrint = useRef();
  const loanSalesUIContext = useLoanSalesUIContext();
  const loanSalesUIProps = useMemo(() => {
    return {
      ids: loanSalesUIContext.ids,
      setIds: loanSalesUIContext.setIds,
      queryParams: loanSalesUIContext.queryParams,
      setQueryParams: loanSalesUIContext.setQueryParams,
    };
  }, [loanSalesUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state }),
    shallowEqual
  );
  let { loanSalesForReport: entities,error,apiErrorData,saleListLoading } = currentState.sales;

  useLayoutEffect(()=>{
    
    if(error && apiErrorData === undefined){
        throw new Error(error);
    }else{
        if(error 
          && apiErrorData?.errorCode!="NOT_FOUND"
          && apiErrorData?.errorCode!="CONFLICT"){
          throw new Error(error);
        }
    }
  },[error])
  const {clientEntities} = currentState.client;

  const [tableData,setTableData] = useState(null)

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchLoanSalesForReport(loanSalesUIProps.queryParams))
    dispatch(clientActions.fetchClientbyUserEmail(localStorage.currentUserEmail.substring(localStorage.currentUserEmail.indexOf('@')+1)))
  }, [loanSalesUIProps.queryParams, dispatch]);
  
  useEffect(()=>{
    entities = entities.map(e => ({...e, 
      expected: e.expected.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }), 
      collected: e.collected.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      remaining: e.remaining.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }))
    setTableData(cloneDeep(entities))
  },[entities])

  const columns = [
    {
      headerName: "#",
      cellClassRules: {
        'cell-notSpan': ({ data }) => data, 
        'cell-totalSpan': ({data}) => !data.detail.id
      }, 
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      cellRenderer: (params) => (
        params.data.detail.id ? (
          <div>{params.rowIndex + 1}</div>
        ) : (
          <></>
        )
      ),
      width: 60
    },
    {
      headerName: 'BRAND', 
      field: 'detail.model',
      cellClassRules: {
        'cell-notSpan': ({ data }) => data, 
        'cell-totalSpan': ({data}) => !data.detail.id
      }, 
      cellStyle: {'whiteSpace': 'normal'},
      cellRenderer: (params) => (
        <div>{params.data.detail.model} {params.data.detail.year}</div>
      ),
      autoHeight: true,
      width: 200,
    },
    {
      headerName: 'VIN', 
      field: 'detail.vin',
      cellStyle: {'whiteSpace': 'normal'},
      autoHeight: true,
      width: 175,
    },
    {
      headerName: 'CUSTOMER', 
      field: 'detail.customer', 
      width: 175
    },
    {
      headerName: 'EXPECTED', 
      field: 'detail.expected', 
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      cellClassRules: {
        'cell-totalSpan': ({data}) => !data.detail.id
      }, 
      width: 175
    },
    {
      headerName: 'COLLECTED', 
      field: 'detail.collected', 
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header",
      cellClassRules: {
        'cell-totalSpan': ({data}) => !data.detail.id
      }, 
      width: 150,
    },
    {
      headerName: 'REMAINING', 
      field: 'detail.remaining', 
      type: 'rightAligned',
      headerClass: "ag-right-aligned-header", 
      cellClassRules: {
        'cell-totalSpan': ({data}) => !data.detail.id
      }, 
      width: 150,
    },
  ]

  const [columnDefs, setColumnDefs] = useState(columns);

  useEffect(() => {
    setColumnDefs(columns)
  }, [loanSalesUIProps.queryParams])

  const handlerCsv = () => {
    exportInsideCSV.current.click();
  };

  const handlerPrint = () => {
    $('#bankContainer').css('visibility','hidden');
    $('#blockConstiner').show()
    exportInsidePrint.current.click();
  };

  const componentRef = useRef(null);

  return (
    <>
      <div className="d-flex justify-content-end">
        <OverlayTrigger
          overlay={
            <Tooltip id="spec-edit-tooltip">Export to Excel</Tooltip>
          }
        >
          <input
            ref={ref1}
            hidden
            className="btn btn-success"
            onClick={handlerCsv}
          />
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip id="spec-edit-tooltip">Export to PDF</Tooltip>
          }
        >
          <input
          ref={ref2}
          hidden
          className="btn btn-success"
          onClick={handlerPrint}
        ></input>    
        </OverlayTrigger>
      </div>
      <BlockUi  blocking={saleListLoading} loader={<Loader active type={'ball-triangle-path'} color="#9d9dad" />} tag="div"  >
        <div className="report-content" ref={componentRef} style={{height: '100%'}}>
          <div id="blockConstiner" style={{display:'none',height: '100%',width:'100%' }} ><BlockUi style={{height:'50px'}} blocking={true} loader={<Loader active type={'ball-triangle-path'} color="#9d9dad" />} tag="div" message="Loading" ></BlockUi></div>
          <div id="bankContainer">
            <div style={{ height: '100%',width:'100%' }}>
              <LoanSalesAGGrid 
                ref={{ref1:exportInsideCSV,ref2:exportInsidePrint}} 
                tableData={TableDataRowFormatter(tableData)} 
                columnDefs={columnDefs} 
                setColumnDefs={setColumnDefs}
                loanSalesUIProps={loanSalesUIProps}
                clientEntities={clientEntities}
                entities={entities}
              />
            </div>
          </div>
        </div>
      </BlockUi>
    </>
  );
});
export default LoanSalesTable;
