import * as requestFromServer from "./colorsCrud";
import {colorsSlice, callTypes} from "./colorsSlice";

const {actions} = colorsSlice;

export const fetchColors = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  
  return requestFromServer
    .getAllColors()
    .then(response => {
      const colorEntities  = response.data;
      const colorTotalCount = response.data.length;
      dispatch(actions.colorsFetched({ colorTotalCount, colorEntities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find colors";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchColor = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.colorFetched({ colorForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getColorById(id)
    .then(response => {
      const color = response.data;
      dispatch(
        actions.colorFetched({ colorForEdit: color })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find color";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteColor = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteColor(id)
    .then(response => {
      dispatch(actions.colorDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete color";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createColor = colorForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createColor(colorForCreation)
    .then(response => {
      const { data:color } = response;
      dispatch(actions.colorCreated({ color }));
    })
    .catch(error => {
      error.clientMessage = "Can't create color";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateColor = color => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateColor(color)
    .then(() => {
      dispatch(actions.colorUpdated({ color }));
    })
    .catch(error => {
      error.clientMessage = "Can't update color";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteColors = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteColors(ids)
    .then(() => {
      dispatch(actions.colorsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete colors";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteValidation = (id,setValidation) => dispatch => {
  if (!id) {
    return false;
    
  }
  return requestFromServer
    .getDeleteValidationById(id)
    .then(response => {
      setValidation(response.data)
    })
    .catch(error => {
      error.clientMessage = "Can't request validation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
  };