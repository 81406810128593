import React from 'react'

export const NumOfDaysColumnFormatter = (params) => {
    const status = params?.data?.detail?.status
    const issuedDay = params?.data?.detail?.issuedDay > 0 ? params?.data?.detail?.issuedDay : null;
    const overDueDay = params?.data?.detail?.overDueDay > 0 ? params?.data?.detail?.overDueDay : null;

  return (
    
    <div>{issuedDay && status === 'Active' &&
        issuedDay
    }
    {overDueDay && status === 'Active' && '('+overDueDay+')'}
    </div>
  )
}
