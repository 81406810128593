import * as requestFromServer from "./suppliersCrud";
import {suppliersSlice, callTypes} from "./suppliersSlice";

const {actions} = suppliersSlice;

export const fetchSuppliers = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  
  return requestFromServer
    .getAllSuppliers()
    .then(response => {
      const supplierEntities  = response.data;
      const supplierTotalCount = response.data.length;
      dispatch(actions.suppliersFetched({ supplierTotalCount, supplierEntities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find suppliers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSupplier = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.supplierFetched({ supplierForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSupplierById(id)
    .then(response => {
      const supplier = response.data;
      dispatch(
        actions.supplierFetched({ supplierForEdit: supplier })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find supplier";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSupplier = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSupplier(id)
    .then(response => {
      dispatch(actions.supplierDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete supplier";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSupplier = supplierForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSupplier(supplierForCreation)
    .then(response => {
      const { data:supplier } = response;
      dispatch(actions.supplierCreated({ supplier }));
    })
    .catch(error => {
      error.clientMessage = "Can't create supplier";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSupplier = supplier => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSupplier(supplier)
    .then(() => {
      dispatch(actions.supplierUpdated({ supplier }));
    })
    .catch(error => {
      error.clientMessage = "Can't update supplier";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSuppliers = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSuppliers(ids)
    .then(() => {
      dispatch(actions.suppliersDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete suppliers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteValidation = (id,setValidation) => dispatch => {
  if (!id) {
    return false;
    
  }
  return requestFromServer
    .getDeleteValidationId(id)
    .then(response => {
      setValidation(response.data)
    })
    .catch(error => {
      error.clientMessage = "Can't request validation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
  };

export const getMainSupplier = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMainSupplier()
    .then((res) => {
      dispatch(actions.mainSupplierFetched({ mainSupplier: res.data }));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch main supplier";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
