// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  Input
} from "../../../../../../_metronic/_partials/controls";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import AsyncSearchBar from "../../../shared/AsyncSelectBar";
import { FormControlLabel, Checkbox} from "@material-ui/core";
import { DatePicker } from '@mantine/dates';
import moment from "moment";
import $ from 'jquery'
import NumberFormat from "react-number-format";
export function TenantEditForm({
  saveTenant,
  tenant,
  actionsLoading,
  onHide,
  openDeleteTenantDialog,
  paymentMethod,
  subscriptionPlans,
  fromDashboard,
  openDelete
}) {
  
  Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
    return this.test("unique", message, function (list) {
      return list.length === new Set(list.map(mapper)).size
    })
  })
  // Validation schema
  const TenantEditSchema = Yup.object().shape({
    sub_plan_id:Yup.string()
    .required("Subscription plan is required"),
    date:Yup.string()
    .required("Start date is required"),
    period:Yup.number().min(1).required("Period is required")
  });

  const {subscriptions} = tenant;

  const activeSubscription =  subscriptions && subscriptions.find(item=> item.active===true);

  const tableEvent = (values,setFieldValue) =>{
      setFieldValue("id",values.id);
      setFieldValue("sub_plan_id",values.sub_plan_id);
      $('#subscriptionPlan').children().find('[id$="-placeholder"]').text(values?.sub_plan_id != "" ? subscriptionPlans && subscriptionPlans.filter(pm=>{
        return pm.id === values.sub_plan_id
          })[0]?.name : '-- select --')
      $('#subscriptionPlan').children().find('[class$="-ValueContainer"]').find('[class$="-singleValue"]').text(values?.sub_plan_id != "" ? subscriptionPlans && subscriptionPlans.filter(pm=>{
            return pm.id === values.sub_plan_id
              })[0]?.name : '-- select --')
      setFieldValue("date",values.date);
      setFieldValue("period",values.period);
      setFieldValue("valid_until",values.valid_until);
      setFieldValue("amount",values.amount);
      setFieldValue("active",values.active);
      setFieldValue("amountToShow",values.amount*values.period)
      setFieldValue("payment_method_id",values.payment_method_id);
      $('#paymentType').children().find('[id$="-placeholder"]').text(values?.payment_method_id != ""  ? paymentMethod && paymentMethod.filter(pm=>{
        return pm.id === values.payment_method_id
          })[0]?.name : 'Select...')
  }
  const initalData = {
    client_id : tenant.tenantClientId,
    sub_plan_id:"",
    date:new Date(),
    period:1,
    valid_until:moment(new Date()).add(1, 'M').subtract(1,'d'),
    amount : '',
    active:true,
    amountToShow:''
  }
  const periodChange = (e,setFieldValue,values)=>{
    setFieldValue('period',e.target.value)
    if(values?.date){
      setFieldValue("valid_until",moment(values?.date).add(e.target.value, 'M').subtract(1,'d'))
    }
    if(values?.sub_plan_id > 0){
      setFieldValue("amount",(subscriptionPlans.find(p => p.id === values.sub_plan_id)?.value))
      setFieldValue("amountToShow",(subscriptionPlans.find(p => p.id === values.sub_plan_id)?.value)*e.target.value)
    }
  }

  function subscriptionChange(value,values,setFieldValue){
    if(value.id>0){
      setFieldValue("amount",value?.value)
      setFieldValue("amountToShow",value?.value*values?.period)
    }
  }

  return (
    
    <>
    
      <Formik
        enableReinitialize={true}
        initialValues={activeSubscription ? {...activeSubscription,amountToShow:activeSubscription?.amount*activeSubscription?.period} : initalData }
        validationSchema={TenantEditSchema}
        onSubmit={(values) => {
          let valuesToSend = {...values}
          valuesToSend.date = moment(valuesToSend?.date).format("YYYY-MM-DD")
          valuesToSend.valid_until = moment(valuesToSend?.valid_until).format("YYYY-MM-DD")
          saveTenant(valuesToSend);
        }}
      >
       {({ handleSubmit,values,errors,initialValues, setFieldValue, setFieldTouched, touched,isSubmitting }) => (
          <BlockUi blocking={false}>
            <Modal.Body className="overlay  cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              { subscriptions && (
               <div className="form-group row " >
                <div className="col-lg-12 scrollable"  style={subscriptions?.length > 3 ?{paddingLeft:'1%',height:'170px'}:{paddingLeft:'1%'}}> 
                  <table  className="table table-head-custom table-vertical-center overflow-hidden"style={{borderBottom:'1px ridge'}}>
                    <thead>
                    <tr>
                      <th>
                          Plan
                      </th>
                     
                      <th>
                         Date
                      </th>
                      <th>
                          Valid Until
                      </th>
                      <th>
                          Payment Type
                      </th>
                      <th>
                         Period
                      </th>
                      <th>
                         Amount/sub
                      </th>
                      <th>
                         Total Amount
                      </th>
                    </tr>
                    </thead>
                    <tbody >
                       {subscriptions && subscriptions.map((items)=>
                        (  
                           <tr  id={items.id} key={items.id} onClick={()=>tableEvent(items,setFieldValue)} 
                           style={items.id === values.id  ? {backgroundColor:'#d2f7de',cursor: "pointer"}:{cursor: "pointer"}}> 
                            <td>
                                {subscriptionPlans && subscriptionPlans.filter(pm=>{
                            return pm.id === items.sub_plan_id
                              })[0]?.name
                          }</td>
                            <td> {moment(items.date).format("DD/MM/YYYY")}</td>
                            <td> {moment(items.valid_until).format("DD/MM/YYYY")} </td>
                            <td>  {paymentMethod && paymentMethod.filter(pm=>{
                            return pm.id === items.payment_method_id
                              })[0]?.name} </td>
                            <td>{items.period} </td>
                            <td> <NumberFormat value={items.amount} thousandSeparator="," displayType="text"></NumberFormat> 
                                </td>
                          <td> <NumberFormat value={items.amount * items.period} thousandSeparator="," displayType="text"></NumberFormat> 
                          {items.active && <span
                            className="label label-dot label-primary ml-2"
                          ></span> } </td>
                          </tr>
                          
                        ))}
                      </tbody>

                  </table>
                  </div>
                  
                </div>
              )}
                <div className="form-group row">
                  
                  <div className="col-lg-3">
                  <label  id="aria-label" htmlFor="aria-example-input">
                          Subscription Plan <span className="text-danger">*</span>
                      </label> 
                      <AsyncSearchBar
                          //selectedItem={{id: values.sale.payment_type_id}} 
                          setSelectedItem={setFieldValue}
                          setTouchedItem={setFieldTouched}
                          defaultItem={subscriptionPlans && subscriptionPlans.find(p => p.id == initialValues.sub_plan_id)?.name}
                          error={errors?.sub_plan_id}
                          touched={touched?.sub_plan_id}
                          item_id="sub_plan_id"
                          item_name="name"
                          options={subscriptionPlans}
                          isSearchable={false}
                          //clear={values.sale.payment_type_id == ""}
                          onChangeEnt={subscriptionChange}
                          values={values}
                          id="subscriptionPlan"
                      />
                       {errors.sub_plan_id && touched.sub_plan_id && (
                  <div className="invalid-feedback d-block">{errors.sub_plan_id}</div>
                )}	
                  </div>

                  <div className="col-lg-3">
                  <DatePicker 
                          name="date"  
                          label="Start Date"
                          inputFormat="DD/MM/YYYY"
                          onChange={(val) => {
                            setFieldValue("date", val);
                            if(values?.period && values?.period > 0)
                            setFieldValue("valid_until",moment(val).add(values?.period, 'M').subtract(1,'d'))
                          }}
                          zIndex={10000}
                          value={values?.date ? new Date(values.date) : new Date()}
                          required
                        />
                  </div>
                  
                  
                  </div>
                  <div className="form-group row">
                  <div className="col-lg-3">
                    
                    <Field
                      name="period"
                      type="number"
                      component={Input}
                      placeholder="period"
                      label="Period"
                      withFeedbackLabel= {true}
                      required
                      onChange={(e)=>periodChange(e,setFieldValue,values)}
                    />
                  </div>
                  <div className="col-lg-3">
                  <label  id="aria-label" htmlFor="aria-example-input">
                          Payment Type 
                      </label> 
                      <AsyncSearchBar
                          //selectedItem={{id: values.sale.payment_type_id}} 
                          setSelectedItem={setFieldValue}
                          setTouchedItem={setFieldTouched}
                          defaultItem={paymentMethod && paymentMethod.find(p => p.id == values.payment_method_id)?.name}
                          //error={errors.sale?.payment_type_id}
                          //touched={touched.sale?.payment_type_id}
                          item_id="payment_method_id"
                          item_name="name"
                          options={paymentMethod}
                          isSearchable={false}
                          //clear={values.sale.payment_type_id == ""}
                          id="paymentType"
                      />
                  </div>
                  
                  </div>
                  <div className="form-group row">
                  <div className="col-lg-3">
                  <DatePicker 
                          name="valid_until"  
                          label="Valid Until"
                          inputFormat="DD/MM/YYYY"
                          onChange={(val) => {
                            setFieldValue("valid_until", val);
                          }}
                          zIndex={10000}
                          value={values?.valid_until ? new Date(values.valid_until) : new Date()}
                          required
                          disabled={true}
                        />
                    
                   
                  </div>
                  <div className="col-lg-3">
                  <label  id="aria-label" htmlFor="aria-example-input">
                          Total Amount <span className="text-danger">*</span>
                      </label>
                  <NumberFormat
                    value={values?.amountToShow}
                    displayType="input"
                    disabled
                    thousandSeparator=","
                    className="form-control"
                    ></NumberFormat>
                    
                  </div>
                  </div>
                  <div className="form-group row">
                  <div className="col-lg-3 checkbox-inline">
                    <label className="pr-3 pt-3">Active? </label>
                    
                    <FormControlLabel
                      checked={values.active}
                      onChange={() => setFieldValue("active", !values.active)}
                      control={<Checkbox style={{color:'#0BB783'}}/>}
                      
                    />
                  </div>
                  <div className="col-md-2 pt-0">
                  <button
                                type="button"
                                onClick={() => {
                                  tableEvent(initalData,setFieldValue) 
                                }}
                                className="btn btn-light btn-elevate mb-2"
                            >Clear</button>
                  </div>
                  </div>
            </Modal.Body>
            <Modal.Footer>
            <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate mr-auto"
              >
                Close
              </button>
              { values?.id != undefined &&
              <button
                type="button"
                onClick={() => fromDashboard ? openDelete(values?.id,tenant.tenantClientId):openDeleteTenantDialog(values?.id,tenant.tenantClientId)}
                className="btn btn-danger btn-elevate ml-auto"
              >
                Delete
              </button>}
              <> </>
            
            </Modal.Footer>
          </BlockUi>
        )}
      </Formik>
      
    </>
    
  );
}
