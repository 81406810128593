import {createSlice} from "@reduxjs/toolkit";

const initialTabsState = {
  topTab: "GeneralInfo",
};

export const callTypes = {
    action: "action"
};

export const tabsSlice = createSlice({
  name: "tabs",
  initialState: initialTabsState,
  reducers: {
    // set top tab
    setTopTab: (state, action) => {
      state.topTab = action.payload.topTab
    },
    // reset top tab
    resetTopTab: (state, action) => {
      state.topTab = initialTabsState.topTab
    },
  }
});
