import {reportsSlice} from "./reportsSlice";

const {actions} = reportsSlice;

export const setStockParams = params => dispatch => {
    dispatch(actions.setStockParams({params}))
};

export const setSalesParams = params => dispatch => {
    dispatch(actions.setSalesParams({params}))
};

export const setLoanSalesParams = params => dispatch => {
    dispatch(actions.setLoanSalesParams({params}))
};

export const setBankDepositParams = params => dispatch => {
    dispatch(actions.setBankDepositParams({params}))
};
