import axios from "axios";

export const SPECIFICATIONS_URL = "api/groups";
export const URL = "http://localhost:8080/api/groups";
export const GROUPS_URL = process.env.REACT_APP_AUTO_DEALER_API+'groups';


export function getAllGroups(){
  return axios.get(GROUPS_URL)
}

export function getAllRoles(){
  return axios.get(process.env.REACT_APP_AUTO_DEALER_API+'roles')
}
// CREATE =>  POST: add a new groups to the server
export function createGroup(group) {
  return axios.post(GROUPS_URL,group);
}

// READ
// Server should return filtered groups by groupId
export function getAllProductGroupsByGroupId(groupId) {
  return axios.get(`${GROUPS_URL}?groupId=${groupId}`);
}

export function getGroupById(groupId) {
  return axios.get(`${GROUPS_URL}/${groupId}`);
}

// Server should return sorted/filtered groups and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/groupsfind/{groupId}'. Should be 'api/groups/find/{groupId}'!!!
export function findGroups(queryParams, groupId) {
  return axios.post(`${GROUPS_URL}find/${groupId}`, { queryParams });
}

// UPDATE => PUT: update the group
export function updateGroup(group) {
  return axios.put(`${GROUPS_URL}/${group.id}`, 
    group
  );
}

// DELETE => delete the group
export function deleteGroup(groupId) {
  return axios.delete(`${GROUPS_URL}/${groupId}`);
}

// DELETE groups by ids
export function deleteGroups(ids) {
  return axios.post(`${GROUPS_URL}/deleteGroups`, { ids });
}

export function getDeleteValidationById(id){
  return axios.get(`${GROUPS_URL}/${id}/delete_validate`);
}

