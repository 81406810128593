import React from 'react'

export const CarColumnFormatter = (params) => {
    const{data} = params;
    let chassis =data?.car?.name=== undefined ||data.span === 0 ? null : data.car.vin; 
    let year = data?.car?.name=== undefined ||data.span === 0 ? null : data.car.year;
    let name = data?.car?.name=== undefined ||data.span === 0 ? null : data.car.name;
    let unitPrice = data?.car?.id === 0 ? data.car.name:null;
    let invoicePrice = data?.car?.id === 0 ? data.car.year:null;
    
    
    //console.log(params);

  return (
    <>	
       
        {name && year && chassis ? 
        <span  style={{whiteSpace: "normal"}}>
        {name} {' - '+year+' - '+chassis}

        </span>
        :
        <span  style={{whiteSpace: "normal"}}>
        {name} 

        </span>
        }
        </>
  )
}
