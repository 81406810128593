import * as requestFromServer from './homeCrud'

import {proformasSlice} from "../proformas/proformasSlice";

const {actions} = proformasSlice;

export const getStat = (setStat, supplier) => dispatch => {
    return requestFromServer
      .getDashboardStat(localStorage.currentUserEmail, supplier)
      .then(response => {
         const statData  = response.data;
         setStat(
            {
                carsInStock:statData.carsInStock,
                amountInStock: statData.amountInStock,
                carInProgress:statData.carInProgress,
                amountInProgress: statData.amountInProgress,
                saleByThisWeek:statData.saleByThisWeek,
                quantityByThisWeek:statData.quantityByThisWeek,
                uncollectedSale:statData.uncollectedSale,
                uncollectedQuantity:statData.uncollectedQuantity,
                saleByMonth:statData.saleByMonth,
                quantityByMonth:statData.quantityByMonth,
                saleByYear:statData.saleByYear,
                quantityByYear:statData.quantityByYear, 
                quantityThisWeek: statData.quantityThisWeek,
                quantityToday: statData.quantityToday,
                processedAndActiveByMonth: statData.processedAndActiveByMonth
            }
         )
         
        // const employeeTotalCount = response.data.length;
        // dispatch(actions.employeesFetched({ employeeTotalCount, employeeEntities }));
      })
      .catch(error => {
        // error.clientMessage = "Can't find dashboard Stat";
        // dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
}

export const getDashboardLineQuantity = (setLineQuantity, supplier) => dispatch => {
    return requestFromServer
      .getDashboardLineQuantity(localStorage.currentUserEmail, supplier)
      .then(response => {
         const lineData  = response.data;
         setLineQuantity(
            { data:lineData.map((item)=>{return [item.categories,item.data]})})
        // const employeeTotalCount = response.data.length;
        // dispatch(actions.employeesFetched({ employeeTotalCount, employeeEntities }));
      })
      .catch(error => {
        // error.clientMessage = "Can't find dashboard Stat";
        // dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
}

export const getDashboardLineAmount = (setLineAmount, supplier) => dispatch => {
  return requestFromServer
    .getDashboardLineAmount(localStorage.currentUserEmail, supplier)
    .then(response => {
       const lineData  = response.data;
       setLineAmount(
          { data:lineData.map((item)=>{return [item.categories,item.data]})})
      // const employeeTotalCount = response.data.length;
      // dispatch(actions.employeesFetched({ employeeTotalCount, employeeEntities }));
    })
    .catch(error => {
      // error.clientMessage = "Can't find dashboard Stat";
      // dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const getDashboardPie = (setPie,setPieWeek,setPieMon,setPieYear, supplier) => dispatch => {
    return requestFromServer
      .getDashboardPie(localStorage.currentUserEmail, supplier)
      .then(response => {
        const pieData  = response.data;
        const weeklyData = pieData.filter((items)=>{return items.date =='weekly'});
        const monthlyData = pieData.filter((items)=>{return items.date =='monthly'||items.date =='weekly'});
        const yearlyData = pieData.filter((items)=>{return items.date =='yearly'||items.date =='monthly'||items.date =='weekly'})
        
        let top7Year;
        let notTopYear;
        let top7Month;
        let notTopMonth; 
        let top7Week;
        let notTopWeek;  
        if(yearlyData.length <= 7){
         top7Year = yearlyData.slice(0)
        }else{
          top7Year = yearlyData.slice(0,6)
          notTopYear = yearlyData.slice(6).reduce((acc, curr) => acc + curr.series, 0);
          let notTopYearNames = yearlyData.slice(6).map((items)=>{return items.label});
          // top7Year.push({label:"Others",series:notTopYear,insideName:notTopYearNames});
          top7Year.push({label:"Others",series:notTopYear,insideName:"Others"});
        }
        if(monthlyData.length <= 7){
          top7Month = monthlyData.slice(0)
         }else{
          top7Month = monthlyData.slice(0,6)
          notTopMonth = monthlyData.slice(6).reduce((acc, curr) => acc + curr.series, 0);
          let notTopMonthNames = monthlyData.slice(6).map((items)=>{return items.label});
          //  top7Month.push({label:"Others",series:notTopMonth,insideName:notTopMonthNames});
          top7Month.push({label:"Others",series:notTopMonth,insideName:"Others"});
         }
         if(weeklyData.length <= 7){
          top7Week = weeklyData.slice(0)
         }else{
            top7Week = weeklyData.slice(0,6)
            notTopWeek = weeklyData.slice(6).reduce((acc, curr) => acc + curr.series, 0);
            let notTopWeekNames = weeklyData.slice(6).map((items)=>{return items.label});
          //  top7Week.push({label:"Others",series:notTopWeek,insideName:notTopWeekNames});
          top7Week.push({label:"Others",series:notTopWeek,insideName:"Others"});
         }

        // let yearlyDataWithPercentage = yearlyData.filter((item)=> {return item.yearlyPer >= 7});
        // let yearlyDataLessThan7 = yearlyData.filter((item)=> {return item.yearlyPer < 7}).reduce((acc, curr) => acc + curr.series, 0);
        // let nameofLessThan7 = yearlyData.filter((item)=> {return item.yearlyPer < 7}).map((items)=>{return items.label});
        // if(yearlyDataLessThan7>0)
        // yearlyDataWithPercentage.push({label:"Others",series:yearlyDataLessThan7,insideName:nameofLessThan7});

        // let monthlyDataWithPercentage = monthlyData.filter((item)=> {return item.monthlyPer >= 7});
        // let monthlyDataLessThan7 = monthlyData.filter((item)=> {return item.monthlyPer < 7}).reduce((acc, curr) => acc + curr.series, 0);
        // let nameofLessThan7monthly = monthlyData.filter((item)=> {return item.monthlyPer < 7}).map((items)=>{return items.label});
        // if(monthlyDataLessThan7>0)
        // monthlyDataWithPercentage.push({label:"Others",series:monthlyDataLessThan7,insideName:nameofLessThan7monthly});

        // let weeklyDataWithPercentage = weeklyData.filter((item)=> {return item.weeklyPer >= 7});
        // let weeklyDataLessThan7 = weeklyData.filter((item)=> {return item.weeklyPer < 7}).reduce((acc, curr) => acc + curr.series, 0);
        // let nameofLessThan7weekly = weeklyData.filter((item)=> {return item.weeklyPer < 7}).map((items)=>{return items.label});
        // if(weeklyDataLessThan7>0)
        // weeklyDataWithPercentage.push({label:"Others",series:weeklyDataLessThan7,insideName:nameofLessThan7weekly});

        
        setPieWeek({
            lables:top7Week.map((items)=>{return items.label}),
            series:top7Week.map((items)=>{return items.series}),
            insideName:top7Week.map((items)=>{return items.insideName})
        })
        setPieMon({
            lables:top7Month.map((items)=>{return items.label}),
            series:top7Month.map((items)=>{return items.series}),
            insideName:top7Month.map((items)=>{return items.insideName})
        })
        setPieYear({
            lables:top7Year.map((items)=>{return items.label}),
            series:top7Year.map((items)=>{return items.series}),
            insideName:top7Year.map((items)=>{return items.insideName})
        })
        // const employeeTotalCount = response.data.length;
        // dispatch(actions.employeesFetched({ employeeTotalCount, employeeEntities }));
      })
      .catch(error => {
        // error.clientMessage = "Can't find dashboard Stat";
        // dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
}

export const getDashboardColumn = (setColumn, supplier) => dispatch => {
    return requestFromServer
      .getDashboardColumn(localStorage.currentUserEmail, supplier)
      .then(response => {
        const columnData  = response.data;
        setColumn({
            categories:columnData.map((items)=>{return items.categories}),
            data:columnData.map((items)=>{return items.data})
        })
        // const employeeTotalCount = response.data.length;
        // dispatch(actions.employeesFetched({ employeeTotalCount, employeeEntities }));
      })
      .catch(error => {
        // error.clientMessage = "Can't find dashboard Stat";
        // dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
}

export const getNotification = () => dispatch => {
  return requestFromServer
    .getDashboardNotification(localStorage.currentUserEmail)
    .then(response => {
      const {data:notifications}  = response;
      //setNotification(columnData)
      // const employeeTotalCount = response.data.length;
      dispatch(actions.notificationsFetched({ notifications }));
    })
    .catch(error => {
      // error.clientMessage = "Can't find dashboard Stat";
      // dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}
export const getstartEndDate = (setStartDate,setEndDate) => dispatch => {
  return requestFromServer
    .getDates(localStorage.currentUserEmail)
    .then(response => {
      const {data}  = response;
      setStartDate(data[0])
      setEndDate(data[1])
    })
    .catch(error => {
      // error.clientMessage = "Can't find dashboard Stat";
      // dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const getYearlySalesTrend = (setYearlySalesTrend, supplier) => dispatch => {
  return requestFromServer
    .getYearlySalesTrend(localStorage.currentUserEmail, supplier)
    .then(response => {
      const {data}  = response;
      setYearlySalesTrend(data);
    })
    .catch(error => {});
}
