import axios from "axios";

export const SPECIFICATIONS_URL = "api/colors";
export const URL = "http://localhost:8080/api/colors";
export const CUSTOMERS_URL = process.env.REACT_APP_AUTO_DEALER_API+'colors';



export function getAllColors(){
  return axios.get(CUSTOMERS_URL)
}
// CREATE =>  POST: add a new colors to the server
export function createColor(color) {
  return axios.post(CUSTOMERS_URL,color);
}

// READ
// Server should return filtered colors by colorId
export function getAllProductColorsByColorId(colorId) {
  return axios.get(`${CUSTOMERS_URL}?colorId=${colorId}`);
}

export function getColorById(colorId) {
  return axios.get(`${CUSTOMERS_URL}/${colorId}`);
}

// Server should return sorted/filtered colors and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/colorsfind/{colorId}'. Should be 'api/colors/find/{colorId}'!!!
export function findColors(queryParams, colorId) {
  return axios.post(`${CUSTOMERS_URL}find/${colorId}`, { queryParams });
}

// UPDATE => PUT: update the color
export function updateColor(color) {
  return axios.put(`${CUSTOMERS_URL}/${color.id}`, 
    color
  );
}

// DELETE => delete the color
export function deleteColor(colorId) {
  return axios.delete(`${CUSTOMERS_URL}/${colorId}`);
}

// DELETE colors by ids
export function deleteColors(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteColors`, { ids });
}

export function getDeleteValidationById(id){
  return axios.get(`${CUSTOMERS_URL}/${id}/delete_validate`);
}