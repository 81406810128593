import axios from "axios";

export const CARS_URL = process.env.REACT_APP_AUTO_DEALER_API+'product' //AUTO_DEALER_API+'product';

// CREATE =>  POST: add a new car to the server
export function createCar(car) {
  return axios.post(CARS_URL, car);
}

// READ
export function getAllCars() {
  return axios.get(CARS_URL,localStorage.currentUserEmail);
}

export function getCarById(carId) {
  
  return axios.get(`${CARS_URL}/${carId}/${localStorage.currentUserEmail}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCars(queryParams={},user_name) {
  return axios.post(`${CARS_URL}/find`, { ...queryParams ,user_name});
}

export function findStock(queryParams={}) {
  return axios.post(`${CARS_URL}/find/stock`, { ...queryParams });
}

export function findDashboardStockReport(queryParams={}) {
  return axios.post(`${CARS_URL}/find/dashboardStockReport`, { ...queryParams });
}

// UPDATE => PUT: update the car on the server
export function updateCar(car) {
  return axios.put(`${CARS_URL}/${car.id}`, car);
}

// UPDATE Status
export function updateStatusForCars(ids, status) {
  return axios.post(`${CARS_URL}/updateStatusForCars`, {
    ids,
    status
  });
}

// DELETE => delete the car from the server
export function deleteCar(carId) {
  return axios.delete(`${CARS_URL}/${carId}`);
}

// DELETE Cars by ids
export function deleteCars(ids) {
  return axios.post(`${CARS_URL}/deleteCars`, { ids });
}

// GET max car price
export function getMaxPrice() {
  return axios.get(`${CARS_URL}/maxPrice`);
}

// GET warehouse cars
export function getWarehouse() {
  return axios.get(`${CARS_URL}/warehouse`, {params: {userName: localStorage.currentUserEmail}})
}
