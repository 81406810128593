import React,{useEffect,useState} from 'react'
import Line from '../../Chart_Types/Line'
import Pie from '../../Chart_Types/Pie'
import Column from '../../Chart_Types/Column'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import * as actions from '../../_redux/home/homeActions'
import * as supplierActions from "../../_redux/suppliers/suppliersActions"
import NumberFormat from 'react-number-format'
import { ProformasUIProvider } from "../cars/car-proformas/ProformasUIContext";
import Notification from './Notification'
import { formatMoney } from '../shared/util'
import RecentSalesDashboard from './RecentSalesDashboard'
import StockReportDashboard from './StockReportDashboard'
import * as reportsAction from '../../_redux/reports/reportsActions' 
import {useHistory} from "react-router-dom";
import * as uiHelper from './HomeUiHelper'
import { SalesUIProvider } from '../cars/car-sales/SalesUIContext'
import { CarsUIProvider } from '../cars/CarsUIContext'
import { Link, NavLink } from "react-router-dom";
import { Charts } from '../../Layout/Charts'
import { openNewOrderModal } from "../../_redux/cars/carsActions";



const Home = () => {
	const { currentState } = useSelector(
		(state) => ({ currentState: state }),
		shallowEqual
	);
	
	const { currentId,dashboardStockReport } = currentState.cars;
	const {roles} = currentState.auth
	const { clientSubscription } = currentState.client

	const { mainSupplier } = currentState.suppliers;

	const[stat,setStat] = useState({
		carsInStock:0,
		carInProgress:0,
		saleByThisWeek:0,
		quantityByThisWeek:0,
		uncollectedSale:0,
		uncollectedQuantity:0,
		saleByMonth:0,
		quantityByMonth:0,
		saleByYear:0,
		quantityByYear:0, 
		quantityThisWeek: 0, 
		quantityToday: 0,
		processedAndActiveByMonth: 0
	})
	const [lineQuantity,setLineQuantity] = useState({
		data:[]
	})
	const [lineAmount,setLineAmount] = useState({
		data:[]
	})
	const [pie,setPie] = useState({
		lables:[],
		series:[],
		insideName:[]
	})
	const [pieWeek,setPieWeek] = useState({
		lables:[],
		series:[],
		insideName:[]
	})
	const [pieMon,setPieMon] = useState({
		lables:[],
		series:[],
		insideName:[]
	})
	const [pieYear,setPieYear] = useState({
		lables:[],
		series:[],
		insideName:[]
	})
	
	const [column,setColumn] = useState({
		categories:[],
		data:[]
	})
	
	const [yearlySalesTrend, setYearlySalesTrend] = useState([]);

	const [toggleSales,setToggleSales] = useState(true);
	const [toggleWeekly,setToggleWeekly] = useState(false);
	const [toggleMonthly,setToggleMonthly] = useState(false);
	const [toggleYearly,setToggleYearly] = useState(true);

	const [toggleMonthlyRecentSales,setToggleMonthlyRecentSales] = useState(true);
	const [toggleWeeklyRecentSales,setToggleWeeklyRecentSales] = useState(false);
	const [toggleDailyRecentSales,setToggleDailyRecentSales] = useState(false);

	const [toggleLine,setToggleLine] = useState(false);
	const [togglePie,setTogglePie] = useState(true);
	const [toggleBar,setToggleBar] = useState(false);
	const [startDate,setStartDate] = useState(null);
	const [endDate,setEndDate] = useState(null);

	const dispatch = useDispatch();
	const toggleQuality = ()=>{
		setToggleSales(true)
	}
	const toggleAmount = ()=>{
		setToggleSales(false)
	}

	const history = useHistory()

	useEffect(()=>
	{
		if(mainSupplier) {
			dispatch(actions.getStat(setStat, mainSupplier?.id))
			dispatch(actions.getDashboardLineQuantity(setLineQuantity, mainSupplier?.id))
			dispatch(actions.getDashboardLineAmount(setLineAmount, mainSupplier?.id))
			dispatch(actions.getDashboardPie(setPie,setPieWeek,setPieMon,setPieYear, mainSupplier?.id))
			dispatch(actions.getDashboardColumn(setColumn, mainSupplier?.id))
			dispatch(actions.getstartEndDate(setStartDate,setEndDate))
			dispatch(actions.getYearlySalesTrend(setYearlySalesTrend, mainSupplier?.id))
		}
		
	},[dispatch, mainSupplier])

	useEffect(() => {
		dispatch(supplierActions.getMainSupplier())
	}, [dispatch])
	
	const handleClick = (clickedValue) =>{
		if(clickedValue === "salesByMonth"){
			
			dispatch(reportsAction.setSalesParams(uiHelper.monthSaleFilter("","1","month","","")));
			history.push("/reports/sales");
		}
		if(clickedValue === "inProcessByMonth"){
			
			dispatch(reportsAction.setSalesParams(uiHelper.monthSaleFilter("1","","",startDate,endDate)));
			history.push("/reports/sales");
		}
		if(clickedValue === "stock"){
			
			//dispatch(reportsAction.setStockParams(uiHelper.stockFilter));
			history.push("/reports/stock");
		}
		
	}
 
	const carsUIEvents = {
		openEditCarPage: (id) => {
			// history.push(`/cars/${id}/edit`, { from: 'dashboard'});
			history.push(`/cars/${id}/edit`);
		},
	} 
 
	const addNewOrder = () =>{
		dispatch(openNewOrderModal({newOrder:true})) 
		history.push(`/orders`);
	}
    return (
        <div>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

						<div className="container floating-background d-flex justify-content-center shortcuts">
						<div className="row pl-4 pr-4  shortcuts align-items-center">							
						</div>    
						</div>   

						<div className="floating-button-group">
							<Link to="/cars/new" className=" floating-button btn btn-sm btn-icon btn-bg-light btn-icon-success btn-hover-success"  title="Add new car" data-placement="top">
							<i className="fas fa-car font-size-h1 "></i>
							<span className="pulse-ring"></span>
							</Link>
							{ roles && roles.includes("Proforma")  && roles.includes("Sales") &&
							<a  onClick={addNewOrder} className="  floating-button btn btn-sm btn-icon btn-bg-light btn-icon-warning btn-hover-warning "  title="Create a new order" data-placement="top">
							<i className="flaticon-cart font-size-h1"></i>
							<span className="pulse-ring"></span>
							</a>
							}
							{ roles && roles.includes("TransactionReport")  &&
							<Link to="/account" className=" floating-button btn btn-sm btn-icon btn-bg-light btn-icon-info btn-hover-info " title="Add new Transaction" data-placement="top">
							<i className="far fa-money-bill-alt font-size-h1"></i> 
							<span className="pulse-ring"></span>
							</Link>}	
						</div>
						
						<div className="d-flex flex-column-fluid">
							
							<div className="container">
								<div className="row">

									{roles && (roles.includes("StockReport") || roles.includes("SaleReport")) &&
									 <div className="col-lg-4">
										<div className="card card-custom card-stretch gutter-b">
											<div className="card-header border-0 pt-5">
												<div className="card-title">
													<div className="card-label">
														<div className="font-weight-bolder">Basic Stats</div>
														<div className="font-size-sm text-muted mt-2">
															<NumberFormat 
																value={stat.saleByYear}
																displayType={'text'}
																thousandSeparator={true}
															/> Sales
														</div>
													</div>
												</div>
											</div>
											<div className="card-body d-flex flex-column pb-0">
												<div className="mb-5">
												{ roles.includes("SaleReport") && <>
													<div className="row row-paddingless mb-10">{/* #2 */}
														
														<div className="col" onClick={()=>handleClick("salesByMonth")} style={{cursor:'pointer'}}>
															<div className="d-flex align-items-center mr-2">
																<div className="symbol symbol-45 symbol-light-success mr-4 flex-shrink-0">
																	<div className="symbol-label">
																	<span className="svg-icon svg-icon-lg svg-icon-success">
																		<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																				<rect x="0" y="0" width="24" height="24"></rect>
																				<path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
																				<path d="M3.5,9 L20.5,9 C21.0522847,9 21.5,9.44771525 21.5,10 C21.5,10.132026 21.4738562,10.2627452 21.4230769,10.3846154 L17.7692308,19.1538462 C17.3034221,20.271787 16.2111026,21 15,21 L9,21 C7.78889745,21 6.6965779,20.271787 6.23076923,19.1538462 L2.57692308,10.3846154 C2.36450587,9.87481408 2.60558331,9.28934029 3.11538462,9.07692308 C3.23725479,9.02614384 3.36797398,9 3.5,9 Z M12,17 C13.1045695,17 14,16.1045695 14,15 C14,13.8954305 13.1045695,13 12,13 C10.8954305,13 10,13.8954305 10,15 C10,16.1045695 10.8954305,17 12,17 Z" fill="#000000"></path>
																			</g>
																		</svg>
																	</span>
																	</div>
																</div>
																<div>
																	<div className="font-size-h4 text-dark-75 font-weight-bolder"><NumberFormat 
																		value={stat.quantityByMonth}
																		displayType={'text'}
																		thousandSeparator={true}
																	></NumberFormat></div>
																	<div className="font-size-sm text-muted font-weight-bold mt-1">This month sales</div>
																</div>
															</div>
														</div>
														
														<div className="col" onClick={()=>handleClick("salesByMonth")} style={{cursor:'pointer'}}>
															<div className="d-flex align-items-center mr-2">
																<div className="symbol symbol-45 symbol-light-success mr-4 flex-shrink-0">
																	<div className="symbol-label">
																		<span className="svg-icon svg-icon-lg svg-icon-success">
																			<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																				<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																					<rect x="0" y="0" width="24" height="24"></rect>
																					<path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
																					<path d="M3.5,9 L20.5,9 C21.0522847,9 21.5,9.44771525 21.5,10 C21.5,10.132026 21.4738562,10.2627452 21.4230769,10.3846154 L17.7692308,19.1538462 C17.3034221,20.271787 16.2111026,21 15,21 L9,21 C7.78889745,21 6.6965779,20.271787 6.23076923,19.1538462 L2.57692308,10.3846154 C2.36450587,9.87481408 2.60558331,9.28934029 3.11538462,9.07692308 C3.23725479,9.02614384 3.36797398,9 3.5,9 Z M12,17 C13.1045695,17 14,16.1045695 14,15 C14,13.8954305 13.1045695,13 12,13 C10.8954305,13 10,13.8954305 10,15 C10,16.1045695 10.8954305,17 12,17 Z" fill="#000000"></path>
																				</g>
																			</svg>
																		</span>
																	</div>
																</div>
																<div>
																	<div className="font-size-h4 text-dark-75 font-weight-bolder">
																		{/* <NumberFormat 
																		value={stat.saleByMonth}
																		displayType={'text'}
																		thousandSeparator={true}
																		/> */}
																		{formatMoney(stat.saleByMonth)}
																	</div>
																	<div className="font-size-sm text-muted font-weight-bold mt-1">This month sales</div>
																</div>
															</div>
														</div>
													</div>
												
													<div className="row row-paddingless mb-10"> {/* #4 */}
														<div className="col" onClick={()=>handleClick("inProcessByMonth")} style={{cursor:'pointer'}}>
															<div className="d-flex align-items-center mr-2">
																<div className="symbol symbol-45 symbol-light-warning mr-4 flex-shrink-0">
																	<div className="symbol-label">
																	<span className="svg-icon svg-icon-lg svg-icon-warning">
																		<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																				<rect x="0" y="0" width="24" height="24"></rect>
																				<path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000"></path>
																				<rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)" x="16.3255682" y="2.94551858" width="3" height="18" rx="1"></rect>
																			</g>
																		</svg>
																	</span>
																	</div>
																</div>
																<div>
																	<div className="font-size-h4 text-dark-75 font-weight-bolder"><NumberFormat 
																		value={stat.carInProgress}
																		displayType={'text'}
																		thousandSeparator={true}
																	></NumberFormat></div>
																	<div className="font-size-sm text-muted font-weight-bold mt-1">In-Process</div>
																</div>
															</div>
														</div>
														<div className="col" onClick={()=>handleClick("inProcessByMonth")} style={{cursor:'pointer'}}>
															<div className="d-flex align-items-center mr-2">
																<div className="symbol symbol-45 symbol-light-warning mr-4 flex-shrink-0">
																	<div className="symbol-label">
																	<span className="svg-icon svg-icon-lg svg-icon-warning">
																		<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																				<rect x="0" y="0" width="24" height="24"></rect>
																				<path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000"></path>
																				<rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)" x="16.3255682" y="2.94551858" width="3" height="18" rx="1"></rect>
																			</g>
																		</svg>
																	</span>
																	</div>
																</div>
																<div>
																	<div className="font-size-h4 text-dark-75 font-weight-bolder">
																		{/* <NumberFormat 
																			value={0}
																			displayType={'text'}
																			thousandSeparator={true}
																		/> */}
																		{formatMoney(stat.amountInProgress)}
																	</div>
																	<div className="font-size-sm text-muted font-weight-bold mt-1">In-Process</div>
																</div>
															</div>
														</div>
													</div>
													</>}
													{ roles.includes("StockReport") &&
													<div className="row row-paddingless mb-10"> {/* #5 */}
														<div className="col" onClick={()=>handleClick("stock")} style={{cursor:'pointer'}}>
															<div className="d-flex align-items-center mr-2">
																<div className="symbol symbol-45 symbol-light-danger mr-4 flex-shrink-0">
																	<div className="symbol-label">
																		<span className="svg-icon svg-icon-danger svg-icon-2x">
																			<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																				<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																					<rect x="0" y="0" width="24" height="24"/>
																					<rect fill="#000000" opacity="0.3" x="4" y="4" width="8" height="16"/>
																					<path d="M6,18 L9,18 C9.66666667,18.1143819 10,18.4477153 10,19 C10,19.5522847 9.66666667,19.8856181 9,20 L4,20 L4,15 C4,14.3333333 4.33333333,14 5,14 C5.66666667,14 6,14.3333333 6,15 L6,18 Z M18,18 L18,15 C18.1143819,14.3333333 18.4477153,14 19,14 C19.5522847,14 19.8856181,14.3333333 20,15 L20,20 L15,20 C14.3333333,20 14,19.6666667 14,19 C14,18.3333333 14.3333333,18 15,18 L18,18 Z M18,6 L15,6 C14.3333333,5.88561808 14,5.55228475 14,5 C14,4.44771525 14.3333333,4.11438192 15,4 L20,4 L20,9 C20,9.66666667 19.6666667,10 19,10 C18.3333333,10 18,9.66666667 18,9 L18,6 Z M6,6 L6,9 C5.88561808,9.66666667 5.55228475,10 5,10 C4.44771525,10 4.11438192,9.66666667 4,9 L4,4 L9,4 C9.66666667,4 10,4.33333333 10,5 C10,5.66666667 9.66666667,6 9,6 L6,6 Z" fill="#000000" fillRule="nonzero"/>
																				</g>
																			</svg>
																		</span>
																	</div>
																</div>
																<div>
																	<div className="font-size-h4 text-dark-75 font-weight-bolder">
																		<NumberFormat 
																			value={stat.carsInStock}
																			displayType={'text'}
																			thousandSeparator={true}
																		></NumberFormat>
																	</div>
																	<div className="font-size-sm text-muted font-weight-bold mt-1">In Stock</div>
																</div>
															</div>
														</div>
														<div className="col" onClick={()=>handleClick("stock")} style={{cursor:'pointer'}}> 
															<div className="d-flex align-items-center mr-2">
																<div className="symbol symbol-45 symbol-light-danger mr-4 flex-shrink-0">
																	<div className="symbol-label">
																	<span className="svg-icon svg-icon-danger svg-icon-2x">
																		<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																				<rect x="0" y="0" width="24" height="24"/>
																				<rect fill="#000000" opacity="0.3" x="4" y="4" width="8" height="16"/>
																				<path d="M6,18 L9,18 C9.66666667,18.1143819 10,18.4477153 10,19 C10,19.5522847 9.66666667,19.8856181 9,20 L4,20 L4,15 C4,14.3333333 4.33333333,14 5,14 C5.66666667,14 6,14.3333333 6,15 L6,18 Z M18,18 L18,15 C18.1143819,14.3333333 18.4477153,14 19,14 C19.5522847,14 19.8856181,14.3333333 20,15 L20,20 L15,20 C14.3333333,20 14,19.6666667 14,19 C14,18.3333333 14.3333333,18 15,18 L18,18 Z M18,6 L15,6 C14.3333333,5.88561808 14,5.55228475 14,5 C14,4.44771525 14.3333333,4.11438192 15,4 L20,4 L20,9 C20,9.66666667 19.6666667,10 19,10 C18.3333333,10 18,9.66666667 18,9 L18,6 Z M6,6 L6,9 C5.88561808,9.66666667 5.55228475,10 5,10 C4.44771525,10 4.11438192,9.66666667 4,9 L4,4 L9,4 C9.66666667,4 10,4.33333333 10,5 C10,5.66666667 9.66666667,6 9,6 L6,6 Z" fill="#000000" fillRule="nonzero"/>
																			</g>
																		</svg>
																	</span>
																	</div>
																</div>
																<div>
																	<div className="font-size-h4 text-dark-75 font-weight-bolder">
																		{/* <NumberFormat 
																			value={0}
																			displayType={'text'}
																			thousandSeparator={true}
																		/> */}
																		{formatMoney(stat.amountInStock)}
																	</div>
																	<div className="font-size-sm text-muted font-weight-bold mt-1">In Stock</div>
																</div>
															</div>
														</div>
													</div>}
												</div>
											</div>
											<div className="card-footer card-custom gutter-b border-top-0 px-0 pt-0 mt-0">
												<div
													id="apex-charts-1"
													className="card-rounded-bottom m-0"
													style={{ height: "150px" }}
												>
													<Charts data={yearlySalesTrend?.map(y => y.month_sales)} />
												</div>
											</div>
										</div>
									</div>}
									{roles &&  roles.includes("SaleReport") &&
									<div className="col-lg-4">
										<div className="card card-custom card-stretch gutter-b">
											<div className="card-header border-0 pt-5">
												<div className="d-flex align-items-center justify-content-between flex-grow-1">
													<div className="d-flex flex-column mr-2">
														<NavLink exact to="/reports/sales" id="stock" className="card-title font-weight-bolder">
																<span className="menu-text">Recent Sales</span>
															</NavLink>
														<div className="font-size-sm text-muted">
															<NumberFormat 
																value={toggleMonthlyRecentSales ? stat.processedAndActiveByMonth : toggleWeeklyRecentSales ? stat.quantityThisWeek : stat.quantityToday}
																displayType={'text'}
																thousandSeparator={true} 
															/> Total Sales
														</div>
													</div>
													<div className="card-toolbar mt-0">
														<ul className="dashboard-tabs nav nav-pills nav-pills-sm nav-dark-75"style={{cursor:"pointer"}}>
															<li className="nav-item">
																<a 
																	className={toggleMonthlyRecentSales? "nav-link py-2 px-4 active": "nav-link py-2 px-4"} 
																	data-toggle="tab" 
																	onClick={()=>{
																		setToggleMonthlyRecentSales(true)
																		setToggleWeeklyRecentSales(false)
																		setToggleDailyRecentSales(false)
																	}} 
																>Month</a>
															</li>
															<li className="nav-item">
																<a 
																	className={toggleWeeklyRecentSales? "nav-link py-2 px-4 active": "nav-link py-2 px-4"} 
																	data-toggle="tab" 
																	onClick={()=>{
																		setToggleMonthlyRecentSales(false)
																		setToggleWeeklyRecentSales(true)
																		setToggleDailyRecentSales(false)
																	}} 
																>Week</a>
															</li>
															<li className="nav-item">
																<a 
																	className={toggleDailyRecentSales? "nav-link py-2 px-4 active": "nav-link py-2 px-4"} 
																	data-toggle="tab" 
																	onClick={()=>{
																		setToggleMonthlyRecentSales(false)
																		setToggleWeeklyRecentSales(false)
																		setToggleDailyRecentSales(true)
																	}}  
																>Day</a>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div className="card-body py-1 d-flex flex-column">
												{/* <div className="card-body pt-0 px-0 notificatonContainer" style={{maxHeight:'326.63px',overflow:'auto',scrollBarWidth:'none'}}> */}
												<div className="scrollable">
													<div className="dashboard-report mb-5">
														<div className="card-body pt-0 px-0">
															{toggleMonthlyRecentSales && 
																<RecentSalesDashboard period='month'/>
															}
															{toggleWeeklyRecentSales && 
																<RecentSalesDashboard period='week'/>
															}
															{toggleDailyRecentSales && 
																<RecentSalesDashboard period='day'/>
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>}
								{roles && roles.includes("StockReport") &&
									<div className="col-lg-4">
									<div className="card card-custom card-stretch gutter-b">
											<div className="card-header border-0 pt-0">
												
											<div className="card-label mt-5">
												<NavLink exact to="/reports/stock" id="stock" className="card-title font-weight-bolder">
																<span className="menu-text">Stock</span>
															</NavLink>
														<div className="font-size-sm text-muted mt-2">
														<NumberFormat 
													value={dashboardStockReport && dashboardStockReport.map(item => {return item.quantity}).reduce((a, b) => a + b, 0)}
													displayType={'text'}
													thousandSeparator={true}
												/> Total Stock
														</div>
													</div>
												
												
											</div>
											<div className="card-body py-1 d-flex flex-column">
												{/* <div className="card-body pt-0 px-0 notificatonContainer" style={{maxHeight:'326.63px',overflow:'auto',scrollBarWidth:'none'}}> */}
												<div className="scrollable">
													<div className="dashboard-report mb-5">
														<div className="card-body pt-0 px-0">
															<StockReportDashboard />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>}
								</div>
								
								
								<div className="row mt-0 mt-lg-3">
									<div className="col-xl-4">
									<div className="card card-custom card-stretch gutter-b pb-7" >
								 <div className="scrollable">
											<div className="card-body pt-0 notificatonContainer" style={{ maxHeight:'750px', cursor:"pointer",}}>
													<CarsUIProvider carsUIEvents={carsUIEvents}>
														<ProformasUIProvider currentProductId={currentId} proformaFromOutside={true}>
															<SalesUIProvider currentProductId={currentId} saleFromOutside={true}>
																<Notification ></Notification>
															</SalesUIProvider>
														</ProformasUIProvider>
													</CarsUIProvider>
												</div>
											</div>   
											
										</div>
										
									</div>
								{roles && (roles.includes("StockReport") || roles.includes("SaleReport")) &&
									<div className="col-xl-8"> 
										
										<div className="card card-custom gutter-b card-stretch"  >
											
											<div className="card-header align-items-center border-0 mt-4">
											{toggleLine && <h3 className="card-title align-items-start flex-column">
													<span className="card-label font-weight-bolder text-dark mt-3">{toggleSales ?'Sales Trend':'Sales Amount'}</span>
												</h3>}
												{(togglePie && roles && roles.includes("SaleReport")) &&<h3 className="card-title align-items-start flex-column">
													<span className="card-label font-weight-bolder text-dark mt-3">Sales Percentage</span>
												</h3>}
												{(toggleBar ||  (roles && !roles.includes("SaleReport") && roles.includes("StockReport")))  &&<h3 className="card-title align-items-start flex-column">
													<span className="font-weight-bolder text-dark"> Stock</span>
												</h3>}
												<div className="card-toolbar">
													{toggleLine && <ul className="nav nav-pills nav-pills-sm nav-dark-75"style={{cursor:"pointer"}}>
														<li className="nav-item">
															<a className={toggleSales ? "nav-link py-2 px-4 active": "nav-link py-2 px-4 "} data-toggle="tab" onClick={toggleQuality}>Quantity</a>
														</li>
														<li className="nav-item">
															<a className={!toggleSales ? "nav-link py-2 px-4 active": "nav-link py-2 px-4 "} data-toggle="tab" onClick={toggleAmount} >Amount</a>
														</li>
														
													</ul>}
													{(togglePie && roles && roles.includes("SaleReport")) && <ul className="nav nav-pills nav-pills-sm nav-dark-75"style={{cursor:"pointer"}}>
														<li className="nav-item">
															<a className={toggleWeekly ? "nav-link py-2 px-4 active": "nav-link py-2 px-4 "} data-toggle="tab" onClick={()=>{setToggleWeekly(true)
																																	 setToggleMonthly(false)
																																	 setToggleYearly(false)}}>Weekly</a>
														</li>
														<li className="nav-item">
															<a className={toggleMonthly? "nav-link py-2 px-4 active": "nav-link py-2 px-4 "} data-toggle="tab" onClick={()=>{setToggleWeekly(false)
																																	 setToggleMonthly(true)
																																	 setToggleYearly(false)}} >Monthly</a>
														</li>
														<li className="nav-item">
															<a className={toggleYearly? "nav-link py-2 px-4 active": "nav-link py-2 px-4 "} data-toggle="tab" onClick={()=>{setToggleWeekly(false)
																																	 setToggleMonthly(false)
																																	 setToggleYearly(true)}} >Yearly</a>
														</li>

													</ul>}
												<div className="dropdown dropdown-inline">
														<a  className="btn btn-text-white btn-hover-white btn-sm btn-icon border-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
															<i className="ki ki-bold-more-hor"></i>
														</a>
													
														<div className="dropdown-menu dropdown-menu-sm dropdown-menu-right"style={{cursor:"pointer"}}>
															
															<ul className="navi navi-hover">
																<li className="navi-header pb-1">
																	<span className="text-primary text-uppercase font-weight-bold font-size-sm">Graphs:</span>
																</li>
																{roles &&  roles.includes("SaleReport") &&
																<li className="navi-item">
																	<a className={togglePie? "navi-link active": "navi-link"} onClick={()=>{setToggleLine(false)
																																	 setTogglePie(true)
																																	 setToggleBar(false)}}>
																		<span className="navi-icon">
																			<i className="flaticon2-pie-chart-4"></i>
																		</span>
																		<span className="navi-text">Sales Percentage</span>
																	</a>
																</li>}
																{roles &&  roles.includes("SaleReport") &&
																<li className="navi-item">
																	<a  className={toggleLine ? "navi-link active": "navi-link"} onClick={()=>{setToggleLine(true)
																																	 setTogglePie(false)
																																	 setToggleBar(false)}}>
																		<span className="navi-icon">
																				<i className="flaticon-line-graph"></i>
																		</span>
																		<span className="navi-text">Sales Trend</span>
																	</a>
																</li>}
																{roles &&  roles.includes("StockReport") &&
																<li className="navi-item">
																	<a className={toggleBar? "navi-link active": "navi-link"} onClick={()=>{setToggleLine(false)
																																	 setTogglePie(false)
																																	 setToggleBar(true)}}>
																		<span className="navi-icon">
																			<i className="flaticon2-graph-1"></i>
																		</span>
																		<span className="navi-text">Stock</span>
																	</a>
																</li>}
																
															</ul>
															
														</div>
													</div>
												</div>
											</div>
											
											
											<div className="card-body pt-4" id="mainColumn">

											{toggleLine &&
													<>
													{toggleSales ?<div className="tab-pane">
													<div className="tab-content " id="myTabTables11">
														{lineQuantity.data.length > 0 ? <Line data={lineQuantity.data}></Line> : <div className="pt-10 px-10"> No Sale</div>}
													</div>
													</div> :
													<div className="tab-pane " >
														{lineAmount.data.length > 0 ? <Line data={lineAmount.data}></Line> : <div className="pt-10 px-10"> No Sale</div>}
													</div>
													}</>
											}
											{(togglePie && roles && roles.includes("SaleReport"))  && 
												<>
												{toggleWeekly &&
													<div className="tab-pane">
													<div className="tab-content " >
													{pieWeek.series.length > 0 ? <Pie labels={pieWeek.lables} insideName={pieWeek.insideName} series={pieWeek.series}></Pie>: <div className="pt-10 px-10"> No Sale</div>}
													</div>
												</div>}
												{toggleMonthly && <div className="tab-pane " >
													<div className="tab-content ">
													{pieMon.series.length > 0 ? <Pie labels={pieMon.lables} insideName={pieMon.insideName} series={pieMon.series}></Pie>: <div className="pt-10 px-10"> No Sale</div>}
													</div>
												</div>
												}
												{toggleYearly && <div className="tab-pane " >
													<div className="tab-content " >
													{pieYear.series.length > 0 ? <Pie labels={pieYear.lables} insideName={pieYear.insideName} series={pieYear.series}></Pie>: <div className="pt-10 px-10" > No Sale</div>}
													</div>
												</div>
												}
												</>
											}
											{(toggleBar ||  (roles && !roles.includes("SaleReport") && roles.includes("StockReport")))  && <div className="tab-pane " >
													<div className="tab-content " >
													{column.data.length > 0 ? <Column categories={column.categories} setColumn={setColumn} data={column.data}></Column>:<div className="mt-40 px-40 text-center"> No Data</div>}
													</div>
												</div>
											}

											
											</div>
											
										</div>
										
									</div>}
									
								</div>
								
								<div className="row"> 
								
								</div>
								
							</div>
							
						</div>
						
					</div>
        </div>
    )
}

export default Home
