import React, { useCallback, useMemo, useRef, useState ,useEffect,forwardRef} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { useReactToPrint } from "react-to-print";
import moment from 'moment'
import $ from 'jquery';
import Moment from 'react-moment'

const LoanSalesAGGrid = forwardRef((props, ref)  => {
  const gridRef = useRef();
  const {ref1,ref2} = ref;
  const {tableData,columnDefs,clientEntities,loanSalesUIProps,entities} = props;


  const containerStyle = useMemo(() => ({height: (window.screen.height * 49/100) }), []);
  const gridStyle = useMemo(() => ({ height: '100%' }), []);
  const [rowData, setRowData] = useState([]);
  const [pinnedRowData,setPinnedRowData] = useState([])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      suppressSizeToFit: true 
    };
  }, []);

  useEffect(()=>{
    setRowData(tableData);
  },[tableData])

  useEffect(()=>{
    setPinnedRowData([
      {
        "detail":{
          "id": "", 
          "model": "GRAND TOTAL",
          "vin": "",
          'customer': "", 
          "expected": `${entities.reduce((accumulator, current) => accumulator + current.expected, 0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`, 
          "collected": `${entities.reduce((accumulator, current) => accumulator + current.collected, 0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`, 
          "remaining": `${entities.reduce((accumulator, current) => accumulator + current.remaining, 0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`,
        },
      }
    ]);   
  },[entities])

  const onGridReady = useCallback((params) => {
    setRowData(tableData)
  }, []);
  
  const onBtnExport = useCallback(() => {
    var params = {
      fileName: 'loan_sales_report.csv',
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const componentRef = useRef(null);
  const today = moment().format("DD-MM-YYYY")
  const handlePrint = useReactToPrint({
    documentTitle: `Loan Sales Report _ ${today}`,
    content: () => {
      const contentToPrint = componentRef.current.cloneNode(true)
      contentToPrint.querySelectorAll('span.svg-icon').forEach(icon => icon.remove())
      return contentToPrint
    },
  });
  const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.height = '';
    eGridDiv.style.width ='1300px'
    api.setDomLayout('print');
  };
  
  const setNormal = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.height = '100%';
    eGridDiv.style.width = '100%';
    api.setDomLayout();
  };
  const onBtPrint = useCallback(() => {
    const api = gridRef.current.api;
    setPrinterFriendly(api);
    setTimeout(function () {
      handlePrint();
      setNormal(api);
      $('#bankContainer').removeAttr("style");
      $('#blockConstiner').hide()
    },3000);
  }, []);

  return (
    <div style={containerStyle} ref={componentRef}>
      <div>
        <input
          ref={ref1}
          hidden
          className="btn btn-success"
          onClick={onBtnExport}
        />
        <button ref ={ref2} hidden onClick={onBtPrint}>Print</button>
      </div>

      <div className="header mb-5">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="flex-grow-1 text-center">Uncollected Payments Report</h1>
          <div className="d-flex justify-content-between">
        <p className="flex-grow-1 text-right mr-37">{clientEntities?.shortName}</p>
        </div>  
        </div>
        <div>
          {loanSalesUIProps.queryParams.filter.category && <div>Category: &nbsp; {loanSalesUIProps.queryParams.filter.category}</div>}
          {loanSalesUIProps.queryParams.filter.start_date ? (
            <span><Moment format="DD/MM/YYYY">{loanSalesUIProps.queryParams.filter.start_date}</Moment></span>
          ) : (
            <span>{moment(new Date(Math.min(...entities.map(e => new Date(e.date))))).format("DD/MM/YYYY")}</span>
          )
          }
          { ' - '}
          {loanSalesUIProps.queryParams.filter.end_date ? (
            <span><Moment format="DD/MM/YYYY">{loanSalesUIProps.queryParams.filter.end_date}</Moment></span>
          ) : (
            <span>{moment(new Date(Math.max(...entities.map(e => new Date(e.date))))).format("DD/MM/YYYY")}</span>
          )
          }
        </div>
        <div className="d-flex justify-content-between pr-15" style={{position:'fixed',bottom:0,width:'100%',color:'#8d8c8c'}}>                                     
          <span className="col-md-6 font-size-h4" style={{color:'#8d8c8c',paddingLeft:'38%'}}>Powered by AutoDealer <br></br>
          <span className="col-md-6 font-size-h5" style={{color:'#8d8c8c'}}>&nbsp;&nbsp;&nbsp;www.autodll.com </span></span>
        </div>
      </div>

      <div id="myGrid" style={gridStyle}  className="ag-theme-material ">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          ref={gridRef}
          pinnedBottomRowData={pinnedRowData}
        />
      </div>
    </div>
  );
});

export default LoanSalesAGGrid;
