import * as requestFromServer from "./clientsCrud";
import {clientsSlice, callTypes} from "./clientsSlice";

const {actions} = clientsSlice;

export const fetchClients = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  
  return requestFromServer
    .getAllClients()
    .then(response => {
      const clientEntities  = response.data;
      const clientTotalCount = response.data.length;
      dispatch(actions.clientsFetched({ clientTotalCount, clientEntities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find clients";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchClient = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.clientFetched({ clientForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getClientById(id)
    .then(response => {
      const client = response.data;
      dispatch(
        actions.clientFetched({ clientForEdit: client })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find client";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchClientbyUserEmail = email => dispatch => {
  if (!email) {
    return dispatch(
      actions.clientsFetched({ clientEntities: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getClientByUserEmail(email)
    .then(response => {
      const client = response.data;
      dispatch(
        actions.clientsFetched({ clientEntities: client })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find client";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteClient = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteClient(id)
    .then(response => {
      dispatch(actions.clientDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete client";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createClient = clientForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createClient(clientForCreation)
    .then(response => {
      const { client } = response.data;
      dispatch(actions.clientCreated({ client }));
    })
    .catch(error => {
      error.clientMessage = "Can't create client";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateClient = client => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateClient(client)
    .then(() => {
      dispatch(actions.clientUpdated({ client }));
    })
    .catch(error => {
      error.clientMessage = "Can't update client";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteClients = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteClients(ids)
    .then(() => {
      dispatch(actions.clientsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete clients";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setClientRegistration = (clientRegistered) => dispatch => {
  dispatch(actions.setClientRegistered({clientRegistered}))
};

export const setVerifiedClient = (verifiedClient) => dispatch => {
  dispatch(actions.setClientVerified({verifiedClient}))
};

export const showSubscriptionMsg = (clientSubscription) => dispatch => {
  dispatch(actions.showClientSubscriptionMsg({clientSubscription: clientSubscription}))
};
