import React, { useMemo, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as proformaActions from "../../../_redux/proformas/proformasActions";
import * as saleActions from "../../../_redux/sales/salesActions";
import * as carActions from "../../../_redux/cars/carsActions";
import { useSalesUIContext } from "./SalesUIContext";
import { useProformasUIContext } from "../car-proformas/ProformasUIContext"
import moment from "moment";
import {groupAndSumSales,sumProformaAmount} from "../../shared/GroupAndSumSales";

export function SalesTable_1({ /*saleEntities: sales,*/ salesInProforma, list_only }) {
  // Specs UI Context
  const specsUIContext = useSalesUIContext();
  const formatedSales = groupAndSumSales(salesInProforma)
  const specsUIProps = useMemo(() => {
    return {
      openNewSaleDialog: specsUIContext.openNewSaleDialog,
      setQueryParams: specsUIContext.setQueryParams,
      queryParams: specsUIContext.queryParams,
      productId: specsUIContext.productId,
    };
  }, [specsUIContext]);

  const proformasUIContext = useProformasUIContext();
  const proformasUIProps = useMemo(() => {
    return {
      currentProforma: proformasUIContext.currentProforma
    };
  }, [proformasUIContext]);

  const dispatch = useDispatch();
  const { saleListLoading, /*saleEntities, salesInProforma,*/ carForEdit, validProforma, proformaEntities } = useSelector(
    (state) => ({
      carForEdit: state.cars.carForEdit,
      saleListLoading: state.sales.saleListLoading,
      proformaEntities: state.proformas.entities,
      validProforma: state.proformas.validProforma
    }),
    shallowEqual
  );


  const salesAmount = proformasUIProps.currentProforma?.proformaItems?.length > 1 ?
      sumProformaAmount(proformasUIProps.currentProforma,true):
      proformasUIProps.currentProforma?.proformaItems?.length > 0 ?
      proformasUIProps.currentProforma?.proformaItems[0].sales_amount
      : 0
  const gpsAmount =  proformasUIProps.currentProforma?.proformaItems?.length > 1 ?
      sumProformaAmount(proformasUIProps.currentProforma,false):
      proformasUIProps.currentProforma?.proformaItems?.length > 0 ? proformasUIProps.currentProforma?.proformaItems[0].gps_amount : 0

  const expectedAmount = (Number(salesAmount) + Number(gpsAmount)) || carForEdit?.price
  const collectedAmount = formatedSales && formatedSales.reduce((accumulator, current) => accumulator + current.amount, 0) || 0
  const remainingAmount = expectedAmount - collectedAmount

  useEffect(() => {
    // !validProforma && specsUIProps?.productId && dispatch(proformaActions.fetchValidProforma(specsUIProps.productId));
    specsUIProps?.productId && dispatch(proformaActions.fetchValidProforma(specsUIProps.productId));
    !carForEdit && specsUIProps?.productId && dispatch(carActions.fetchCar(specsUIProps.productId));
  }, [dispatch, proformaEntities, specsUIProps.productId])



  return (

    carForEdit != null && Object.keys(carForEdit).length === 0 ? <></> :
    <>
        <div className="d-flex">
            <div className="flex-grow-1">
                <div className="d-flex flex-column flex-wrap p-3">
                    <div className="d-flex justify-content-between text-dark-75 mb-3">
                        <span className="font-weight-bolder">Sales Amount</span>
                        <span className="font-weight-bolder">{expectedAmount?.toLocaleString()}</span>
                    </div>
                    <div className="my-3">
                        <div className="font-weight-bolder mb-2">Payments</div>
                        <div className="scrollable" style={{maxHeight: '300px'}}>
                          {/* {!saleListLoading && sales?.map((s, key) => ( */}
                          {!saleListLoading && formatedSales?.map((s, key) => (
                              <div key={key}>
                                  <div className={`d-flex justify-content-between mb-1 mx-3 py-1 border-bottom ${!list_only && 'text-primary text-underline-hover cursor-pointer'}`}
                                    onClick={() => s.common_payment === null ? !list_only && dispatch(saleActions.fetchSale(s.id)): !list_only && dispatch(saleActions.fetchSale(s.id,s.amount))}
                                  >
                                      <div>{moment(s.payment_date).format("DD/MM/yyyy")}</div>
                                      <div>{s.amount.toLocaleString()}</div>
                                  </div>
                              </div>
                          ))}
                          {saleListLoading && <div className="spinner spinner-primary spinner-lg spinner-center" style={{minHeight: '25px'}}></div>}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between text-dark-75 mb-3">
                        <span className="font-weight-bolder">Total Paid</span>
                        {/* <span className="font-weight-bolder">{!saleListLoading && sales?.reduce((accumulator, current) => accumulator + current.amount, 0)?.toLocaleString()}</span> */}
                        <span className="font-weight-bolder">{!saleListLoading && formatedSales?.reduce((accumulator, current) => accumulator + current.amount, 0)?.toLocaleString()}</span>
                    </div>
                    <div className="d-flex justify-content-between text-dark-75 my-3">
                        <span className="font-weight-bolder">Remaining Amount</span>
                        <span className="font-weight-bolder">{!saleListLoading && remainingAmount >= 0 ? <b>{remainingAmount?.toLocaleString()}</b> : 0}</span>
                    </div>
                    { remainingAmount < 0 &&
                    <div className="d-flex justify-content-between text-dark-75 mt-3">
                        <span className="font-weight-bolder">Overpaid Amount</span>
                        <span className="font-weight-bolder">{!saleListLoading && (-remainingAmount).toLocaleString()}</span>
                    </div>}
                </div>
            </div>
        </div>
    </>
  )
}
