export const oneDigit = (digit) => {
    switch (digit) {
      case 1:
        return "አንድ";
      case 2:
        return "ሁለት";
      case 3:
        return "ሶስት";
      case 4:
        return "አራት";
      case 5:
        return "አምስት";
      case 6:
        return "ስድስት";
      case 7:
        return "ሰባት";
      case 8:
        return "ስምንት";
      case 9:
        return "ዘጠኝ";
      default:
        return "";
    }
  };
  
  export const twoDigits = (twoDigitNumber) => {
    switch (twoDigitNumber) {
      case 10:
        return "አስር";
      case 11:
        return "አስራ አንድ";
      case 12:
        return "አስራ ሁለት";
      case 13:
        return "አስራ ሶስት";
      case 14:
        return "አስራ አራት";
      case 15:
        return "አስራ አምስት";
      case 16:
        return "አስራ ስድስት";
      case 17:
        return "አስራ ሰባት";
      case 18:
        return "አስራ ስምንት";
      case 19:
        return "አስራ ዘጠኝ";
      case 20:
        return "ሃያ";
      case 30:
        return "ሰላሳ";
      case 40:
        return "አርባ";
      case 50:
        return "አምሳ";
      case 60:
        return "ስልሳ";
      case 70:
        return "ሰባ";
      case 80:
        return "ሰማንያ";
      case 90:
        return "ዘጠና";
      default:
        //for numbers like 21
        const firstPart = twoDigitNumber % 10;
        const secondPart = twoDigitNumber - firstPart;
  
        return `${twoDigits(secondPart)} ${oneDigit(firstPart)}`;
    }
  };
  
  export const threeDigits = (threeDigits) => {
    const firstPart = oneDigit(Math.floor(threeDigits / 100)) + " መቶ ";
    const secondPart = threeDigits % 100;
    return (
      firstPart + (secondPart > 9 ? twoDigits(secondPart) : oneDigit(secondPart))
    ); //for numbers like 101
  };
  
  export const GetNumericGroupTitle = (numericGroup) => {
    switch (numericGroup) {
      default:
      case 1: //hundreds doesn't need
        return "";
      case 2:
        return "ሺ";
      case 3:
        return "ሚሊዮን";
    }
  };
  
  export default {
    oneDigit,
    twoDigits,
    threeDigits,
    GetNumericGroupTitle
  };