export const defaultSorted = [{ dataField: "category_id", order: "asc" }];

export const initialFilter = {
    filter: {
        category: "",
        batch: "",
        location: "",
        supplier_id: "",
        group_batch: "",
        year: "",
        color: ""
    },
    sortOrder: "asc", // asc||desc
    sortField: "category",
    pageNumber: 1,
    pageSize: 10, 
    hidden: {
        year: true, 
        color: true,
        category: false, 
        quantity: false,
        batch: true,
    }
};
