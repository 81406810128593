import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import $ from 'jquery';
import { useSelector,shallowEqual } from "react-redux";

export const HeaderBottom = () => {
    const {auth} = useSelector((state) => ({ auth: state.auth }),
		shallowEqual)
	const {roles} = auth;	
	
	useEffect(()=>{
		$('.menu-link.menu-child').off("click").on("click",function(){
			$('.menu-parent-child').removeClass('active')
			$(this).parents('.menu-parent').children('.menu-parent-child').addClass('active')
		})
		$('.menu-link.menu-remove-active').off("click").on("click",function(){
			$('.menu-parent-child').removeClass('active')
		})
	},[])
  return (
    <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
									
									<div id="kt_header_menu" className="header-menu header-menu-left header-menu-mobile header-menu-layout-default">
										<div className="d-flex justify-content-end d-lg-none">
											<div className="menu-item menu-item-submenu menu-item-rel py-0 px-3 pt-3" data-menu-toggle="hover" aria-haspopup="true">
												<NavLink exact to="#" className="menu-link">
													<span className="nav-icon">
														<span className="svg-icon svg-icon-2x">
															<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
															<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
																	<rect x="0" y="7" width="16" height="2" rx="1"/>
																	<rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
																</g>
															</g>
															</svg>
														</span>
													</span>
												</NavLink>
											</div>
										</div>
										<ul className="menu-nav pt-0">
											<li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="hover" aria-haspopup="true">
												<NavLink exact to="/dashboard" className="menu-link menu-remove-active">
													<span className="menu-text">Dashboard</span> 
													{/* <span className="menu-desc">Recent Updates &amp; Reports</span>
													<i className="menu-arrow menu-toggle"></i>  */}
												</NavLink>
											</li>
											<li className="menu-item menu-item-submenu menu-item-rel menu-item-open" data-menu-toggle="hover" aria-haspopup="true">
												<NavLink exact to="/cars" className="menu-link menu-remove-active">
													<span className="menu-text">Cars</span> 
												</NavLink>
												<div className="menu-submenu menu-submenu-classNameic menu-submenu-left">
													<ul className="menu-subnav">
														{/* <li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
															<NavLink exact to="/categories" id="carmodels" className="menu-link">
																<span className="svg-icon menu-icon">
																	<svg  xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<path d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
																			<path d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
																		</g>
																	</svg>
																	
																</span>
																<span className="menu-text">Categories</span>
																<i className="menu-arrow"></i>
															</NavLink>
														</li> */}
													</ul>
												</div>
											</li>
											{/* {roles && roles.includes("Sales") && roles.includes("Proforma") && <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="hover" aria-haspopup="true">
												<NavLink exact to='/orders' className="menu-link menu-remove-active">
													<span className="menu-text">Orders</span> 
												</NavLink>
											</li>} */}
											{roles && ((roles.includes("Sales") && roles.includes("Proforma")) || roles.includes("TransactionReport")) &&
											<li className="menu-item menu-item-submenu  menu-item-rel menu-item-open menu-parent " data-menu-toggle="hover" aria-haspopup="true">
												<NavLink exact to="#" activeClassName='' className="menu-link menu-toggle menu-parent-child">
													<span className="menu-text">Transactions</span> 
												</NavLink>
												<div className="sub-menu  menu-submenu menu-submenu-classic menu-submenu-left">
													<ul className="menu-subnav">
														{roles.includes("Sales") && roles.includes("Proforma") &&
														<li className="menu-item menu-item-submenu"  data-menu-toggle="hover" aria-haspopup="true">
															<NavLink exact to="/orders" id="bank-deposit" className="menu-link menu-child">
																<span className="menu-text">Orders/Sales</span>														
															</NavLink>
														</li>}
														{roles.includes("TransactionReport") &&
														<li className="menu-item menu-item-submenu"  data-menu-toggle="hover" aria-haspopup="true">
															<NavLink exact to="/account" id="accounts" className="menu-link menu-child">
																<span className="menu-text">Cash Transfers</span>														
															</NavLink>
														</li>}
													</ul>
												</div>
											</li>}
											{roles 
											&& (roles.includes("StockReport") || roles.includes("SaleReport") || roles.includes("UncollectedReport") || roles.includes("BankReport")) 
											&& <li className="menu-item menu-item-submenu  menu-item-rel menu-item-open menu-parent " data-menu-toggle="hover" aria-haspopup="true">
												{/* <NavLink exact to="#" className="menu-link ">
													<span className="menu-text">Reports</span> 
												</NavLink> */}
												<NavLink exact to="#" activeClassName='' className="menu-link menu-toggle menu-parent-child">
													<span className="menu-text">Reports</span> 
												</NavLink>
												<div className="sub-menu  menu-submenu menu-submenu-classic menu-submenu-left">
													<ul className="menu-subnav">
														{roles && roles.includes("StockReport") && <li className="menu-item" data-menu-toggle="hover" aria-haspopup="true">
															{/* <NavLink exact to="/reports/stock" id="stock" className="menu-link ">
																<span className="menu-text">Stock</span>
															</NavLink> */}
															<NavLink exact to="/reports/stock" id="stock" className="menu-link menu-child">
																<span className="menu-text">Stock</span>
															</NavLink>
														</li>}
														{roles && roles.includes("SaleReport") &&<li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
															<NavLink exact to="/reports/sales" id="sales" className="menu-link menu-child">
																<span className="menu-text">Sales</span>														
															</NavLink>
														</li>}
														{roles && roles.includes("UncollectedReport") &&<li className="menu-item menu-item-submenu"  data-menu-toggle="hover" aria-haspopup="true">
															<NavLink exact to="/reports/uncollected_payments" id="loan-sales" className="menu-link menu-child">
																<span className="menu-text">Uncollected Payments </span>														
															</NavLink>
														</li>}
														{roles && roles.includes("BankReport") &&<li className="menu-item menu-item-submenu"  data-menu-toggle="hover" aria-haspopup="true">
															<NavLink exact to="/reports/bank_deposit" id="bank-deposit" className="menu-link menu-child">
																<span className="menu-text">Bank Deposits</span>														
															</NavLink>
														</li>}
													</ul>
												</div>
											</li>}
											<li className="menu-item menu-item-submenu menu-item-rel menu-item-open menu-parent " data-menu-toggle="hover" aria-haspopup="true">
												<NavLink exact to="#" activeClassName='' className="menu-link menu-toggle menu-parent-child">
													<span  className="menu-text">Settings</span> 
												</NavLink>
												<div className="menu-submenu menu-submenu-classNameic menu-submenu-left">
													<ul className="menu-subnav">
														<li className="menu-item menu-item-open menu-item-here menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
															<NavLink exact to="#"  activeClassName='' className="menu-link menu-toggle">
																<span className="menu-text">Setup</span>
																<i className="menu-arrow"></i>
															</NavLink>
															<div className="menu-submenu menu-submenu-classic menu-submenu-right" data-hor-direction="menu-submenu-right">
																<ul className="menu-subnav">
																	<li className="menu-item"  aria-haspopup="true">
																		<NavLink exact to="/banks" id="banks" className="menu-link menu-child">
																			<span className="menu-text">Banks</span>
																		</NavLink>
																	</li>
																	<li className="menu-item" aria-haspopup="true">
																		<NavLink exact to="/batch" id="batch" className="menu-link menu-child">
																			<span className="menu-text">Batch</span>
																		</NavLink>
																	</li>
																	<li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
																		<NavLink exact to="/categories"  id="carmodels" className="menu-link menu-child">
																			<span className="menu-text">Car Brand</span>
																		</NavLink>
																	</li>
																	<li className="menu-item" aria-haspopup="true">
																		<NavLink exact to="/colors"  id="colors" className="menu-link menu-child">
																			<span className="menu-text">Colors</span>
																		</NavLink>
																	</li>
																	<li className="menu-item" aria-haspopup="true">
																		<NavLink exact to="/customers"  id="customers" className="menu-link menu-child">
																			<span className="menu-text">Customers</span>
																		</NavLink>
																	</li>
																	<li className="menu-item" aria-haspopup="true">
																		<NavLink exact to="/employees"  id="employees" className="menu-link menu-child">
																			<span className="menu-text">Sales Person</span>
																		</NavLink>
																	</li>
																	<li className="menu-item" aria-haspopup="true">
																		<NavLink exact to="/locations"  id="locations" className="menu-link menu-child">
																			<span className="menu-text">Locations</span>
																		</NavLink>
																	</li>
																	<li className="menu-item" aria-haspopup="true">
																		<NavLink exact to="/suppliers"  id="suppliers" className="menu-link menu-child">
																			<span className="menu-text">Suppliers</span>
																		</NavLink>
																	</li>
																</ul>
																</div>
															
														</li>
														{roles && roles.includes("AdminRole")&& <li className="menu-item menu-item-open menu-item-here menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
															<NavLink exact to="#" activeClassName='' className="menu-link menu-toggle ">
																<span className="menu-text">Admin Functions</span>
																<i className="menu-arrow"></i>
															</NavLink>
															<div className="menu-submenu menu-submenu-classic menu-submenu-right" data-hor-direction="menu-submenu-right">
																<ul className="menu-subnav">
																
																<li className="menu-item" aria-haspopup="true">
																	<NavLink exact to="/groups" id="groups" className="menu-link menu-child">
																		<span className="menu-text">Groups</span>																
																	</NavLink>
																	</li>
																
																	<li className="menu-item" aria-haspopup="true">
																	<NavLink exact to="/users" id="users" className="menu-link menu-child">
																		<span className="menu-text">Users</span>																
																	</NavLink>
																	</li>
																	

																	<li className="menu-item" aria-haspopup="true">
																		<NavLink exact to="/profile" id="profile" className="menu-link menu-child">
																			<span className="menu-text">Profile</span>																
																		</NavLink>
																	</li>
																	
																	{/* <li className="menu-item" aria-haspopup="true">
																	<NavLink exact to="/logs" id="logs" className="menu-link menu-child">
																		<span className="menu-text">Logs</span>																
																	</NavLink>
																	</li> */}
																</ul>
															</div>
														</li>}
													</ul>
												</div>
											</li>
											<li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
												<NavLink exact to='/support' className="menu-link menu-remove-active">
													<span className="menu-text">Support</span> 
												</NavLink>
											</li>
										</ul>
										
									</div>
									
								</div>
  )
}
