import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/inspections/inspectionsActions";
import { InspectionEditDialogHeader } from "./InspectionEditDialogHeader";
import { InspectionEditForm } from "./InspectionEditForm";
import { useInspectionsUIContext } from "../InspectionsUIContext";
import moment from "moment";

export function InspectionEditDialog() {
  // Inspections UI Context
  const specsUIContext = useInspectionsUIContext();
  const specsUIProps = useMemo(() => {
    return {
      id: specsUIContext.selectedId,
      show: specsUIContext.showEditInspectionDialog,
      onHide: specsUIContext.closeEditInspectionDialog,
      productId: specsUIContext.productId,
      queryParams: specsUIContext.queryParams,
      initInspection: specsUIContext.initInspection,
    };
  }, [specsUIContext]);

  // Inspections Redux state
  const dispatch = useDispatch();
  const { actionsLoading, listLoading, inspectionForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.inspections.inspectionLoading,
      listLoading: state.inspections.inspectionListLoading,
      inspectionForEdit: state.inspections.inspectionForEdit != undefined || state.inspections.inspectionsEntities == undefined ? state.inspections.inspectionForEdit : state.inspections.inspectionsEntities.filter(x => x.id == specsUIProps.id)[0],
    }),
    shallowEqual
  );

  useEffect(() => {
    // server request for getting spec by seleted id
    dispatch(actions.fetchInspection(specsUIProps.id));
    
  }, [specsUIProps.id, dispatch]);

  const saveInspection = (inspection) => {
    // const d = new Date(inspection.inspection_date);
    inspection = {
      ...inspection, 
      product_id: specsUIProps.productId, 
      inspection_date: moment(inspection.inspection_date).format("YYYY-MM-DD")
    };
		// inspection.inspection_date = (d instanceof Date) ? `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}` : d;

    if (!specsUIProps.id) {
      // inspection.id = uuid();
      dispatch(actions.createInspectionInCar(inspection)).then(() => {
        dispatch(actions.fetchInspectionsInCar(specsUIProps.productId))
        .then(() => specsUIProps.onHide());
      })
      // specsUIProps.onHide()
    } else {
      dispatch(actions.updateInspectionInCar(inspection)).then(() => {
        dispatch(actions.fetchInspectionsInCar(specsUIProps.productId))
        .then(() => specsUIProps.onHide());
      });
      // specsUIProps.onHide()
    }
  };

  return (
    <Modal
      show={specsUIProps.show}
      // onHide={specsUIProps.onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <InspectionEditDialogHeader id={specsUIProps.id} onHide={specsUIProps.onHide} />
      <InspectionEditForm
        saveInspection={saveInspection}
        actionsLoading={actionsLoading}
        listLoading={listLoading}
        inspection={inspectionForEdit || specsUIProps.initInspection}
        onHide={specsUIProps.onHide}
      />
    </Modal>
  );
}
