import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/proformas/proformasActions";
import * as carActions from "../../../../_redux/cars/carsActions"
import { ProformaEditDialogHeader } from "./ProformaEditDialogHeader";
import { ProformaEditForm } from "./ProformaEditForm";
import { useProformasUIContext } from "../ProformasUIContext";
import { useCarsUIContext } from "../../CarsUIContext"
import * as homeActions from "../../../../_redux/home/homeActions"
import moment from "moment";

export function ProformaEditDialog(newButton) {
  // Profromas UI Context
  const specsUIContext = useProformasUIContext();
  const specsUIProps = useMemo(() => {
    return {
      id: specsUIContext.selectedId,
      show: specsUIContext.showEditProformaDialog,
      onHide: specsUIContext.closeEditProformaDialog,
      productId: specsUIContext.productId,
      queryParams: specsUIContext.queryParams,
      initProforma: specsUIContext.initProforma,
      carId:specsUIContext.carId,
      proformaFromOutside: specsUIContext.proformaFromOutside,
      openSaleDeleteDialog:specsUIContext.openDeleteSaleProformaDialog,
      showValidation: specsUIContext.showValidation,
    };
  }, [specsUIContext]);

  const carsUIContext = useCarsUIContext();
  const carsUIProps = useMemo(() => {
    return {
      queryParams: carsUIContext?.queryParams,
    }
  }, [carsUIContext])

  // Profromas Redux state
  const dispatch = useDispatch();
  const { actionsLoading, proformaForEdit ,proformas, sales } = useSelector(
    (state) => ({
      actionsLoading: state.proformas.actionsLoading,
      proformaForEdit: state.proformas.proformaForEdit != undefined || state.proformas.entities == undefined ? state.proformas.proformaForEdit : state.proformas.entities.filter(x => x.id == specsUIProps.id)[0],
      proformas: state.proformas.entities, 
      // sales: state.sales.saleEntities
      sales: state.sales.salesInProforma
    }),
    shallowEqual
  );

  useEffect(() => {
    // server request for getting spec by seleted id
    dispatch(actions.fetchProforma(specsUIProps.id,specsUIProps.carId));
    
  }, [specsUIProps.id, dispatch]);

  useEffect(() => {
    if(proformaForEdit?.proformaItems?.length > 0) {
      dispatch(carActions.selectCurrentCarId(proformaForEdit?.proformaItems[0]?.product_id))
    }
  }, [proformaForEdit])

  const[proformaSubmitted, setProformaSubmitted] = useState(false)

  const saveProforma = (proforma) => {
    return new Promise((resolve, reject) => {
      // !proforma.location_id && (proforma.location_id = specsUIProps.initProforma.location_id)
      // get location for proforma from the 1st proforma item (may need some fixing for multiple proforma items)
      !proforma.location_id && (proforma.location_id = proforma.proformaItems[0].location_id)
      proforma.proforma_date = moment(proforma.proforma_date).format('YYYY-MM-DD')
      
      if (!specsUIProps.id) {
        dispatch(actions.createProforma(proforma)).then(() => {
          if(specsUIProps.carId) {
            dispatch(actions.fetchProformasInCar(specsUIProps.carId)).then(() => {
              setProformaSubmitted(true)
              resolve()
              // specsUIProps.onHide()
            })
          }
          else {
            dispatch(actions.fetchProformas()).then(() => {
              setProformaSubmitted(true)
              resolve()
              // specsUIProps.onHide()
            })
          }
        });
        // specsUIProps.onHide()
      } else {
        dispatch(actions.updateProforma(proforma,proforma.id)).then((res) => {   
          if(specsUIProps.carId) {
            dispatch(actions.fetchProformasInCar(specsUIProps.carId)).then(() => {
              setProformaSubmitted(true)
              resolve()
              // specsUIProps.onHide()
            })
          }
          else {
            dispatch(actions.fetchProformas()).then(() => {
              setProformaSubmitted(true)
              resolve()
            //  specsUIProps.onHide()
            })
          }
          // If proforma is expired or canceled
          if(proforma.status_id === 2) {
            if(!sales && sales?.length == 0) {
              reject()
              return
            }
            resolve()
          }
          if(proforma.status_id === 4 ){
            specsUIProps.openSaleDeleteDialog(specsUIProps.carId,proforma.id);
            // reject()
            if(!sales && sales?.length == 0) {
              reject()
              return
            }
            resolve()
          }
          dispatch(homeActions.getNotification())
          /* dispatch(
            actions.fetchSales(
              specsUIProps.queryParams,
              specsUIProps.productId
            )
          ).then(() => specsUIProps.onHide()); */
        });
      }

      specsUIProps.proformaFromOutside && 
      !carsUIProps.queryParams.filter.initialval  && 
      dispatch(carActions.fetchCars(carsUIProps.queryParams));
    })
  };

  return (
    <Modal
      size="xl"
      show={specsUIProps.show}
      // onHide={specsUIProps.onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      
    >
      <ProformaEditDialogHeader id={specsUIProps.id} onhide={specsUIProps.onHide} />
      <ProformaEditForm
        saveProforma={saveProforma}
        actionsLoading={actionsLoading}
        proforma={proformaForEdit}
        onHide={specsUIProps.onHide}
        newButton={newButton}
        proformas={proformas}
        proformaSubmitted={proformaSubmitted}
        setProformaSubmitted={setProformaSubmitted}
      />
    </Modal>
  );
}
