import React from "react";
import { Route } from "react-router-dom";
import { EmployeesLoadingDialog } from "./employees-loading-dialog/EmployeesLoadingDialog";
import { EmployeeEditDialog } from "./employee-edit-dialog/EmployeeEditDialog";
import { EmployeeDeleteDialog } from "./employee-delete-dialog/EmployeeDeleteDialog";
import { EmployeesDeleteDialog } from "./employees-delete-dialog/EmployeesDeleteDialog";
import { EmployeesFetchDialog } from "./employees-fetch-dialog/EmployeesFetchDialog";
import { EmployeesUpdateStateDialog } from "./employees-update-status-dialog/EmployeesUpdateStateDialog";
import { EmployeesUIProvider } from "./EmployeesUIContext";
import { EmployeesCard } from "./EmployeesCard";

export function EmployeesPage({ history }) {
  const employeesUIEvents = {
    newEmployeeButtonClick: () => {
      history.push("/employees/new");
    },
    openEditEmployeeDialog: (id) => {
      history.push(`/employees/${id}/edit`);
    },
    openDeleteEmployeeDialog: (id) => {
      history.push(`/employees/${id}/delete`);
    },
    openDeleteEmployeesDialog: () => {
      history.push(`/employees/deleteEmployees`);
    },
    openFetchEmployeesDialog: () => {
      history.push(`/employees/fetch`);
    },
    openUpdateEmployeesStatusDialog: () => {
      history.push("/employees/updateStatus");
    }
  }

  return (
    <EmployeesUIProvider employeesUIEvents={employeesUIEvents}>
      <EmployeesLoadingDialog />
      <Route path="/employees/new">
        {({ history, match }) => (
          <EmployeeEditDialog
            show={match != null}
            onHide={() => {
              history.push("/employees");
            }}
          />
        )}
      </Route>
      <Route path="/employees/:id/edit">
        {({ history, match }) => (
          <EmployeeEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/employees");
            }}
          />
        )}
      </Route>
      <Route path="/employees/deleteEmployees">
        {({ history, match }) => (
          <EmployeesDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/employees");
            }}
          />
        )}
      </Route>
      <Route path="/employees/:id/delete">
        {({ history, match }) => (
          <EmployeeDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/employees");
            }}
          />
        )}
      </Route>
      <Route path="/employees/fetch">
        {({ history, match }) => (
          <EmployeesFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/employees");
            }}
          />
        )}
      </Route>
      <Route path="/employees/updateStatus">
        {({ history, match }) => (
          <EmployeesUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/employees");
            }}
          />
        )}
      </Route>
      <EmployeesCard />
    </EmployeesUIProvider>
  );
}
