import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./StockUIHelpers";
import { shallowEqual, useSelector } from "react-redux";

const StockUIContext = createContext();

export function useStockUIContext() {
  return useContext(StockUIContext);
}

export const StockUIConsumer = StockUIContext.Consumer;

export function StockUIProvider({stockUIEvents, children}) {
  const { stock_params } = useSelector(
    (state) => ({ stock_params: state.reports.stock_params }),
    shallowEqual
  );
  const [queryParams, setQueryParamsBase] = useState(stock_params || initialFilter);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return <StockUIContext.Provider value={value}>{children}</StockUIContext.Provider>;
}