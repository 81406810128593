import { BehaviorSubject } from 'rxjs';
import axios from "axios";
import { handleResponse } from '../_helpers/index';
import {decode} from 'jsonwebtoken'

const currentUserSubject = new BehaviorSubject(localStorage.getItem('currentUser'));
const url = process.env.REACT_APP_AUTO_DEALER_API+"auth/login"
const signup_url = process.env.REACT_APP_AUTO_DEALER_API+"auth/register"
const super_admin_registration_url = process.env.REACT_APP_AUTO_DEALER_API+"auth/super_admin/register"
const delete_client_url = process.env.REACT_APP_AUTO_DEALER_API+"auth/delete_client/"
const find_user_url = process.env.REACT_APP_AUTO_DEALER_API+"auth/find_user/"
const send_otp_url = process.env.REACT_APP_AUTO_DEALER_API+"auth/sendForgotPasswordOTP/"
const verify_otp_url = process.env.REACT_APP_AUTO_DEALER_API+"auth/verifyOTP/"
const change_password_url = process.env.REACT_APP_AUTO_DEALER_API+"auth/changePwd/"

export const authenticationService = {
    register,
    login,
    superAdminRegister,
    logout,
    deleteClient,
    findUser,
    sendOtp,
    verifyOTP,
    changePassword,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { 
        if (!currentUserSubject.value)
         return false
        else {
            const { exp } = decode(currentUserSubject.value.substring(7));
            if (Date.now() >= exp * 1000) {
                return false;
              }else{
                  return true;
              }
        }}
};

async function register(value) {
    try {
        const res = await axios({
            method: 'post',
            url: signup_url,
            data: value
        });
        return handleResponse(res);
    } catch (err) {
        return handleResponse(err)
    }
}

function login(username, password) {
    if(username.indexOf('@')>0){
    var body = {
        userName: username.indexOf('.com') > 0 ? username.substring(0,username.indexOf('.com')):username,
        password: password,
        clientUniqueName:username.indexOf('.com') > 0 ? username.substring(username.indexOf('@')+1,username.indexOf('.com')) 
                        :username.substring(username.indexOf('@')+1),
        mobile:false

    }
    return axios({
        method: 'post',
        url: url,
        data: body
    })
    .then(function (response) {
        return handleResponse(response);
    })
    .catch(function (error) {
        return handleResponse(error);
    });
    }
  }

async function superAdminRegister(value) {
    try {
        const res = await axios({
            method: 'post',
            url: super_admin_registration_url,
            data: value
        });
        return handleResponse(res);
    } catch (err) {
        return handleResponse(err)
    }
}


async function findUser(value) {
    try {
        const res = await axios({
            method: 'post',
            url: find_user_url,
            data:{username: value}
            
        });
        return handleResponse(res);
    } catch (err) {
        return handleResponse(err)
    }
    
}


async function sendOtp(value) {
    try {
        const res = await axios({
            method: 'post',
            url: send_otp_url,
            data:{username: value}
            
        });
        return handleResponse(res);
    } catch (err) {
        return handleResponse(err)
    }
    
}



async function changePassword(value) {
    try {
        const res = await axios({
            method: 'post',
            url: change_password_url,
            data: value
        });
        return handleResponse(res);
    } catch (err) {
        return handleResponse(err)
    }
    
}


async function verifyOTP(value) {
    try {
        const res = await axios({
            method: 'post',
            url: verify_otp_url,
            data: value
        });
        return handleResponse(res);
    } catch (err) {
        return handleResponse(err)
    }
    
}



function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('date');
    localStorage.removeItem('currentUserEmail');
    localStorage.removeItem('group');
    // localStorage.removeItem('currentUserEmail');
    currentUserSubject.next(null);
    window.location.href = window.location.origin;
}

async function deleteClient (email) {
    const url = `${delete_client_url}?email=${email}`
    try {
        const res = await axios({
            method: 'get',
            url: url
        });
        return handleResponse(res);
    } catch (err) {
        return handleResponse(err)
    }
}
