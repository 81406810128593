import React from 'react'

const Setting = () => {
    return (
        <div>
              {/* <ProformaContractOfSale/>    */}
            setting works
        </div>
    )
}

export default Setting
